import { BUSCAR_VESTINGS, AGREGAR_VESTING, EDITAR_VESTING, DETALLES_VESTING, ELIMINAR_VESTING } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarVestings = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/vestings`, config);
        dispatch({
            type: BUSCAR_VESTINGS,
            payload: res.data.vestings
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarVesting = (vesting) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(vesting);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/vestings/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_VESTING,
            payload: res.data.vesting
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarVesting = (vesting) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(vesting);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/vestings/editar`, body, config);
        dispatch({
            type: EDITAR_VESTING,
            payload: res.data.vesting
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesVesting = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/vestings/detalles`, body, config);

        dispatch({
            type: DETALLES_VESTING,
            payload: res.data.vesting
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarVesting = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/vestings/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_VESTING,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}