import React, { Fragment, useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

//Actions
import { buscarNominas, reportesNomina, filtrosNomina, otroGastoNomina, basuraNomina, gasolinaNomina, eliminarNomina, externaNomina } from '../../actions/nominas';
import { listarProyectos } from '../../actions/proyectos';
import { buscarEmpleados } from '../../actions/empleados';
import { setAlert } from '../../actions/alert';

const Nominas = ({history}) => {

    const dispatch = useDispatch();

    const nominas = useSelector(state => state.nominas);
    const proyectos = useSelector(state => state.proyectos);
    const empleados = useSelector(state => state.empleados);
    const { permisos_payrolls } = useSelector(state => state.auth.user);

    const [dataTabla, setDataTabla] = useState({
        nominasTabla: null
    });

    const [idDeleteNomina, setIdDeleteNomina] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const [fechasReporteNomina, setFechasReporteNomina] = useState({
        id_empleado: null,
        nombre_empleado: null,
        id_proyecto: null,
        nombre_proyecto: null,
        fechas_totales: true,
        fecha_inicio: null,
        fecha_fin: null
    });
    const [reporteModal, setReporteModal] = useState(false);
    const [empleadosFilter, setEmpleadosFilter] = useState([]);
    const [proyectosFilter, setProyectosFilter] = useState([]);
    const [empleadoData, setEmpleadoData] = useState(null);
    const [proyectoReporteData, setProyectoReporteData] = useState(null);

    const [idGastoNomina, setIdGastoNomina] = useState(null);
    const [datosGasto, setDatosGasto] = useState({
        id_proyecto: null,
        tipo: 0,
        monto: null,
        observaciones: '',
        file: null,
        extension: null
    });
    const [gastoModal, setGastoModal] = useState(false);
    const [proyectoGastoData, setProyectoGastoData] = useState(null);
    const [botonGasto, setBotonGasto] = useState(false);

    const { nominasTabla } = dataTabla;

    let gasto_ref = createRef();

    //Reporte
    const handleOpenReporteNomina = () => {
        setReporteModal(true);
    }

    const handleSelectReporte = (option, tipo) => {
        if(tipo === 1 || tipo === '1'){
            setEmpleadoData(option);
            setFechasReporteNomina({
                ...fechasReporteNomina,
                id_empleado: option !== null ? option.value : null,
                nombre_empleado: option !== null ? option.label : null
            });
        }

        if(tipo === 2 || tipo === '2'){
            setProyectoReporteData(option);
            setFechasReporteNomina({
                ...fechasReporteNomina,
                id_proyecto: option !== null ? option.value : null,
                nombre_proyecto: option !== null ? option.label : null
            });
        }
    };

    const handleCheckbox = ({ target }) => {
        if(target.checked){
            setFechasReporteNomina({
                ...fechasReporteNomina,
                fecha_inicio: null,
                fecha_fin: null,
                fechas_totales: true
            });
        }else{
            setFechasReporteNomina({
                ...fechasReporteNomina,
                fechas_totales: false
            });
        }
    };

    const handleCloseReporteNomina = () => {
        setReporteModal(false);

        setEmpleadoData(null);
        setProyectoReporteData(null);
    }

    const reporteNomina = async () => {
        if(fechasReporteNomina.id_empleado !== null){
            if(fechasReporteNomina.id_proyecto !== null){
                if(fechasReporteNomina.fechas_totales === true){
                    await dispatch(filtrosNomina(fechasReporteNomina));
                    await dispatch(reportesNomina(fechasReporteNomina));
                    await handleCloseReporteNomina();
                    await history.push('/payments/report');
                }else{
                    if(fechasReporteNomina.fecha_inicio !== null){
                        if(fechasReporteNomina.fecha_fin !== null){
                            await dispatch(filtrosNomina(fechasReporteNomina));
                            await dispatch(reportesNomina(fechasReporteNomina));
                            await handleCloseReporteNomina();
                            await history.push('/payments/report');
                        }else{
                            dispatch(setAlert('First select a valid end date', 'danger'));
                        }
                    }else{
                        dispatch(setAlert('First select a valid start date', 'danger'));
                    }
                }
            }else{
                dispatch(setAlert('First select a project', 'danger'));
            }
        }else{
            dispatch(setAlert('First select an employee', 'danger'));
        }
    }

    //Gasto
    const handleGastoNomina = id => {
        setIdGastoNomina(id);
    }

    const handleOpenGastoNomina = () => {
        setGastoModal(true);
    }

    const handleCloseGastoNomina = () => {
        setIdGastoNomina(null);
        setDatosGasto({
            ...datosGasto,
            monto: null,
            observaciones: ''
        });
        setGastoModal(false);
        setProyectoGastoData(null);
    }

    const onNumberChangeGasto = (name, value) => {
        setDatosGasto({
            ...datosGasto, [name]: value
        });
    };

    const handleSelectGasto = (option) => {
        setProyectoGastoData(option);
        setDatosGasto({
            ...datosGasto,
            id_proyecto: option !== null ? option.value : null
        });
    };

    const onChangeGasto = ({ target }) => {
        setDatosGasto({
            ...datosGasto,
            [target.name]: target.value
        });
    }

    const onChangeFileGasto = () => {
        let nombre_separado = gasto_ref.current.files[0].name.split('.');

        setDatosGasto({
            ...datosGasto,
            file: gasto_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const gastoNomina = async () => {
        if(datosGasto.id_proyecto !== null){
            if(datosGasto.monto !== null && datosGasto.monto !== '' && datosGasto.monto > 0){
                await setBotonGasto(true);

                let formData = new FormData();
                formData.append('id', idGastoNomina);
                formData.append('id_proyecto', datosGasto.id_proyecto);
                formData.append('monto', datosGasto.monto);
                formData.append('observaciones', datosGasto.observaciones);
                formData.append('file', datosGasto.file);
                formData.append('extension', datosGasto.extension);

                if(datosGasto.tipo === 0 || datosGasto.tipo === '0'){
                    await dispatch(otroGastoNomina(formData));
                }

                if(datosGasto.tipo === 1 || datosGasto.tipo === '1'){
                    await dispatch(basuraNomina(formData));
                }

                if(datosGasto.tipo === 2 || datosGasto.tipo === '2'){
                    await dispatch(gasolinaNomina(formData));
                }

                await handleCloseGastoNomina();

                await setBotonGasto(false);
            }else{
                dispatch(setAlert('First enter a valid amount', 'danger'));
            }
        }else{
            dispatch(setAlert('First select a project', 'danger'));
        }
    }

    //Detalles
    const detailsNomina = id => {
        history.push('/payments/details/' + id);
    }

    //Agrear
    const openNewPayroll = () => {
        dispatch(externaNomina(true));
    }

    //Editar
    const editNomina = id => {
        history.push('/payments/edit/' + id);
    }

    //Eliminar
    const handleDeleteNomina = id => {
        setIdDeleteNomina(id);
    }

    const handleOpenDeleteNomina = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteNomina = () => {
        setIdDeleteNomina(null);
        setDeleteModal(false);
    }

    const deleteNomina = async () => {
        await dispatch(eliminarNomina(idDeleteNomina));
        await handleCloseDeleteNomina();
    }

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const handleViewFile = (file) => {
        if(file !== null){
            const url = URL.createObjectURL(file);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columns = [
        {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Employees',
            selector: 'numero_empleados',
            sortable: true,
            cell: item => `${item.numero_empleados !== null ? item.numero_empleados : '-'}`,
        }, {
            name: 'Salaries',
            selector: 'monto_salarios',
            sortable: true,
            cell: item => `${item.monto_salarios !== null ? numberToDecimal(item.monto_salarios) : '-'}`,
        }, {
            name: 'Dumps trips',
            selector: 'monto_basura',
            sortable: true,
            cell: item => `${item.monto_basura !== null ? numberToDecimal(item.monto_basura) : '-'}`,
        }, {
            name: 'Gas bills',
            selector: 'monto_gasolina',
            sortable: true,
            cell: item => `${item.monto_gasolina !== null ? numberToDecimal(item.monto_gasolina) : '-'}`,
        }, {
            name: 'Other bills',
            selector: 'monto_otro',
            sortable: true,
            cell: item => `${item.monto_otro !== null ? numberToDecimal(item.monto_otro) : '-'}`,
        }, {
            name: 'Total',
            selector: 'monto_total',
            sortable: true,
            cell: item => `${item.monto_total !== null ? numberToDecimal(item.monto_total) : '-'}`,
        },
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-success" onClick={() => handleGastoNomina(item._id)}>
                    <i className="fa fa-dollar-sign" />
                </button>,
        },
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-info" onClick={() => detailsNomina(item._id)}>
                    <i className="fa fa-info-circle" />
                </button>,
        },
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-info" onClick={() => editNomina(item._id)}>
                    <i className="fa fa-edit" />
                </button>,
        },
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-danger" onClick={() => handleDeleteNomina(item._id)}>
                    <i className="fa fa-times" />
                </button>,
        }
    ];

    useEffect(() => {
        dispatch(buscarNominas());
        dispatch(listarProyectos());
        dispatch(buscarEmpleados());
    }, []);

    useEffect(() => {
        setDataTabla({
            nominasTabla: nominas.nominas
        });
    }, [nominas]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < empleados.empleados?.length; i++) {
            options.push({ value: empleados.empleados[i]._id, label: empleados.empleados[i].nombre });
        }

        setEmpleadosFilter(options);
    }, [empleados]);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < proyectos.listado?.length; i++) {
            options.push({ value: proyectos.listado[i]._id, label: proyectos.listado[i].direccion });
        }

        setProyectosFilter(options);

    }, [proyectos]);

    useEffect(() => {
        if(idGastoNomina !== null){
            handleOpenGastoNomina();
        }
    }, [idGastoNomina]);

    useEffect(() => {
        if(idDeleteNomina !== null){
            handleOpenDeleteNomina();
        }
    }, [idDeleteNomina]);

    return ( 
            <Fragment>

                {/* Modal de filtros */}
                <Modal show={reporteModal} size="lg" onHide={() => {
                    handleCloseReporteNomina();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Report generation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-4">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Employee:</label>
                                <Select name="id_empleado" isClearable={true} options={empleadosFilter} value={empleadoData} onChange={(value) => handleSelectReporte(value, 1)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <Select name="id_proyecto" isClearable={true} options={proyectosFilter} value={proyectoReporteData} onChange={(value) => handleSelectReporte(value, 2)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                            </div>

                            <div className="col-md-4 form-group">
                                <label className="font-weight-bold">All time:</label>
                                <div className="contenedor_datepicker">
                                    <label className="container text-center">
                                        <input type="checkbox" name="fechas_totales" checked={fechasReporteNomina.fechas_totales} onChange={ (e) => handleCheckbox(e) }/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-group">
                                <label className="font-weight-bold">Start date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker name="fecha_inicio" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ fechasReporteNomina.fecha_inicio !== null ? new Date(fechasReporteNomina.fecha_inicio) : '' } onChange={ date => setFechasReporteNomina({ ...fechasReporteNomina, fecha_inicio: date }) } disabled={fechasReporteNomina.fechas_totales} />
                                </div>
                            </div>

                            <div className="col-md-4 form-group">
                                <label className="font-weight-bold">End date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker name="fecha_fin" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ fechasReporteNomina.fecha_fin !== null ? new Date(fechasReporteNomina.fecha_fin) : '' } onChange={ date => setFechasReporteNomina({ ...fechasReporteNomina, fecha_fin: date }) } disabled={fechasReporteNomina.fechas_totales} />
                                </div>
                            </div>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseReporteNomina()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={reporteNomina}><i className="fas fa-check fa-sm"></i> Generate</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de gastos */}
                <Modal show={gastoModal} onHide={() => {
                    handleCloseGastoNomina();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bills and expenses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <Select name="id_proyecto" isClearable={true} options={proyectosFilter} value={proyectoGastoData} onChange={(value) => handleSelectGasto(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Type:</label>
                                <select className="form-control" value={datosGasto.tipo} name="tipo" onChange={(e) => onChangeGasto(e)}>
                                    <option value="0">General expense</option> 
                                    <option value="1">Dump trip</option> 
                                    <option value="2">Gas bill</option> 
                                </select>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Amount:</label>
                                <CurrencyInput
                                  name="monto"
                                  className="form-control text-center"
                                  value={datosGasto.monto || ''}
                                  decimalsLimit={2}
                                  groupSeparator=","
                                  decimalSeparator="."
                                  onValueChange={(value, name) => onNumberChangeGasto(name, value)}
                                />
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">File:</label>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input form-control" name="gastoFiles" id="gastoFiles" ref={gasto_ref} onChange={onChangeFileGasto} aria-describedby="view_gastoFiles"/>
                                        <label className="custom-file-label">{datosGasto.file ? 'File Selected' : 'Choose file'}</label>
                                    </div>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosGasto.file)}><i class="fas fa-eye"></i> View File</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Observations:</label>
                                <textarea className="form-control" value={datosGasto.observaciones || ''} name="observaciones" onChange={e => onChangeGasto(e)}></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseGastoNomina()} disabled={botonGasto}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={gastoNomina} disabled={botonGasto}><i className="fas fa-check fa-sm"></i> Save</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de eliminacion */}
                <Modal show={deleteModal} onHide={() => {
                    handleCloseDeleteNomina();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Payroll</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to delete this payroll?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseDeleteNomina()}><i className="fas fa-times fa-sm"></i> No</button>
                        <button className="btn btn-success" onClick={deleteNomina}><i className="fas fa-check fa-sm"></i> Yes</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-8 text-left">
                            <h3>Payroll payments</h3>
                            <p className="text-muted">{nominas.nominas ? nominas.nominas.length : null} registered payments</p>
                        </div>

                        <div className="col-md-4 text-right">
                        {
                            !permisos_payrolls?.report ?
                            null
                            :
                            <button className="btn btn-primary mr-3" type="button" onClick={() => handleOpenReporteNomina()}><i className="fas fa-chart-bar fa-sm"></i> Report</button>
                        }
                        {
                            !permisos_payrolls?.new_single_payroll ?
                            null
                            :
                            <button className="btn btn-success text-right mr-3" onClick={() => openNewPayroll()}><i className="fas fa-hand-holding-usd"></i> Add single payroll</button>
                        }
                        {
                            !permisos_payrolls?.new_multiple_payroll ?
                            null
                            :
                            <Link to="/payments/new" className="btn btn-success text-right"><i className="fas fa-file-invoice-dollar"></i> Add multiple payroll</Link>
                        }
                        </div>
                    </div>


                    <div className="table-responsive pl-2">
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columns}
                                    data={nominasTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </Fragment>
    );
}
 
export default Nominas;
