import { OBTENER_DATOS_DEUDAS, OBTENER_PROVEEDORES, DETALLES_PROVEEDOR, ELIMINAR_PROVEEDOR, AGREGAR_PAGO_PROVEEDOR, EDITAR_PAGO_PROVEEDOR, DETALLES_PAGO_PROVEEDOR, DETALLES_ARCHIVO_PAGO_PROVEEDOR, ELIMINAR_PAGO_PROVEEDOR, AGREGAR_DEUDA_PROVEEDOR, EDITAR_DEUDA_PROVEEDOR, DETALLES_DEUDA_PROVEEDOR, DETALLES_ARCHIVO_DEUDA_PROVEEDOR, ELIMINAR_DEUDA_PROVEEDOR } from '../actions/types';

const initialState = {
    datos: null, 
    proveedores_deuda: [],
    proveedores_libres: [],
    detalles_proveedor: null,
    detalles_deuda_proveedor: null, 
    detalles_pago_proveedor: null, 
    detalles_archivo: null
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {

        case OBTENER_DATOS_DEUDAS: 
            return {
                ...state,
                datos: payload
            };

        case OBTENER_PROVEEDORES:
            return {
                ...state,
                proveedores_deuda: payload.proveedores_deuda,
                proveedores_libres: payload.proveedores_libres
            };

        case DETALLES_PROVEEDOR: 
            return {
                ...state,
                detalles_proveedor: payload
            };

        case ELIMINAR_PROVEEDOR: 
            return {
                ...state,
                proveedores_deuda: state.proveedores_deuda.filter( proveedor => proveedor._id !== payload.id ),
                proveedores_libres: state.proveedores_libres.filter( proveedor => proveedor._id !== payload.id )
            };

        case AGREGAR_DEUDA_PROVEEDOR:
            let detalles_agregar_deuda_proveedor = state.detalles_proveedor;

            let deudas_agregar_proveedor = [
                ...detalles_agregar_deuda_proveedor.deudas, payload
            ];

            deudas_agregar_proveedor.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_agregar_deuda_proveedor.deudas = deudas_agregar_proveedor;

            return {
                ...state,
                detalles_proveedor: detalles_agregar_deuda_proveedor
            };

        case EDITAR_DEUDA_PROVEEDOR:
            let detalles_editar_deuda_proveedor = state.detalles_proveedor;

            let deudas_editar_proveedor = detalles_editar_deuda_proveedor.deudas.map( deuda => String(deuda._id) === String(payload._id) ? payload : deuda );

            deudas_editar_proveedor.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_editar_deuda_proveedor.deudas = deudas_editar_proveedor;

            return {
                ...state,
                detalles_proveedor: detalles_editar_deuda_proveedor
            };

        case DETALLES_DEUDA_PROVEEDOR: 
            return {
                ...state,
                detalles_deuda_proveedor: payload
            };

        case DETALLES_ARCHIVO_DEUDA_PROVEEDOR: 
            return {
                ...state,
                detalles_archivo: payload
            };

        case ELIMINAR_DEUDA_PROVEEDOR:
            let detalles_eliminar_deuda_proveedor = state.detalles_proveedor;

            detalles_eliminar_deuda_proveedor.deudas = detalles_eliminar_deuda_proveedor.deudas.filter( deuda => deuda._id !== payload.id );

            return {
                ...state,
                detalles_proveedor: detalles_eliminar_deuda_proveedor
            };
        
        case AGREGAR_PAGO_PROVEEDOR:
            let detalles_agregar_pago_proveedor = state.detalles_proveedor;

            let pagos_agregar_proveedor = [
                ...detalles_agregar_pago_proveedor.pagos, payload
            ];

            pagos_agregar_proveedor.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_agregar_pago_proveedor.pagos = pagos_agregar_proveedor;

            return {
                ...state,
                detalles_proveedor: detalles_agregar_pago_proveedor
            };

        case EDITAR_PAGO_PROVEEDOR:
            let detalles_editar_pago_proveedor = state.detalles_proveedor;

            let pagos_editar_proveedor = detalles_editar_pago_proveedor.pagos.map( pago => String(pago._id) === String(payload._id) ? payload : pago );

            pagos_editar_proveedor.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_editar_pago_proveedor.pagos = pagos_editar_proveedor;

            return {
                ...state,
                detalles_proveedor: detalles_editar_pago_proveedor
            };

        case DETALLES_PAGO_PROVEEDOR: 
            return {
                ...state,
                detalles_pago_proveedor: payload
            };

        case DETALLES_ARCHIVO_PAGO_PROVEEDOR: 
            return {
                ...state,
                detalles_archivo: payload
            };

        case ELIMINAR_PAGO_PROVEEDOR:
            let detalles_eliminar_pago_proveedor = state.detalles_proveedor;

            detalles_eliminar_pago_proveedor.pagos = detalles_eliminar_pago_proveedor.pagos.filter( pago => pago._id !== payload.id );

            return {
                ...state,
                detalles_proveedor: detalles_eliminar_pago_proveedor
            };
        
        default: 
            return state;
    }

}