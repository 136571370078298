import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { v4 as uuid } from 'uuid';
import { ObjectID } from 'bson';
import CurrencyInput from 'react-currency-input-field';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { filterProyectos } from '../../actions/dashboard';
import { buscarBuyers, agregarBuyer } from '../../actions/buyers';
import { buscarFinancingTypes, agregarFinancingType } from '../../actions/financing_types';
import { validarProyecto, agregarOferta, buscarTiposProyectos, detallesPreProyecto, externoNota, eliminarNotasProyecto } from '../../actions/proyectos';

const ContainerStyle = {
    width: '100%',
    height: '50vh'
};

const NuevaOferta = ({ history }) => {
    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const tipos = useSelector(state => state.tipos);
    const buyers = useSelector(state => state.buyers);
    const financing_types = useSelector(state => state.financing_types);
    const usuarios = useSelector(state => state.usuarios);
    let fileOffer = createRef();

    const proyectoDataInit = {
        _id: null,
        analisis: null,
        id_buyer_company: null,
        tipo_proyecto: null,
        tipo_construccion: null,
        multi_unidades: true,
        tiene_edificios: true,
        direccion: null,
        latitud: null,
        longitud: null,
        multi_apn: false,
        apn: [''],
        terreno: [''],
        terreno_unidad: [0],
        direcciones: [''],
        purchase_price: null,
        asking_price: null,
        listing_id: null,
        id_tipo_financiamiento: null,
        tiene_down_payment: false,
        down_payment: null,
        tipo_down_payment: 0,
        id_usuario_responsable: null,
        url_offer_file: null,
        url_screenshot_file: null,
    }

    const [proyectoData, setProyectoData] = useState(proyectoDataInit);

    const [screenshotFile, setScreenshotFile] = useState({
        file: null,
        url: ''
    });

    const [offerFile, setOfferFile] = useState({
        file: undefined,
        archivo: ''
    });

    const [coincidenciaProyecto, setCoincidenciaProyecto] = useState({
        direccion: '',
        proyecto: '',
        construccion: ''
    });

    const [isBackButtonClicked, setBackbuttonPress] = useState(false);    
    const [spinner, setSpinner] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [sectionInformation, setSectionInformation] = useState(0);
    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });
    
    const [screenshotModal, setScreenshotModal] = useState(false);
    const [viewScreenshotModal, setViewScreenshotModal] = useState(false);
    const [coincidenciaModal, setCoincidenciaModal] = useState(false);
    const [buyerCompanyModal, setBuyerCompanyModal] = useState(false);
    const [financingTypeModal, setFinancingTypeModal] = useState(false);

    /* Buyer company data */
    const [buyerCompanySelectData, setBuyerCompanySelectData] = useState(null);

    const [buyersFilter, setBuyersFilter] = useState([]);

    const [addBuyerCompanyData, setAddBuyerCompanyData] = useState({
        _id: null,
        nombre: ''
    });

    /* Type of financing */
    const [financingTypeSelectData, setFinancingTypeSelectData] = useState(null);

    const [financingTypesFilter, setFinancingTypesFilter] = useState([]);

    const [addFinancingTypeData, setAddFinancingTypeData] = useState({
        _id: null,
        nombre: '',
        down_payment: false
    });

    /* User Assigned */
    const [userResponsableSelectData, setUserResponsableSelectData] = useState(null);

    const [usersFilter, setUsersFilter] = useState([]);

    /* APN funciones */
    const handleAddApn = async () => {
        let apns = proyectoData.apn;
        let lotes = proyectoData.terreno;
        let unidades = proyectoData.terreno_unidad;

        apns.push('');
        lotes.push('');
        unidades.push(0);

        setProyectoData({ 
            ...proyectoData, 
            apn: apns,
            terreno: lotes,
            terreno_unidad: unidades
        });
    }

    const onChangeAPN = ({ target }) => {

        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let apns = proyectoData.apn;

        apns[posicion] = target.value || '';

        setProyectoData({
            ...proyectoData,
            apn: apns
        });
    }

    const onNumberChangeLot = (value, name) => {

        let nombre_separado = name.split('_');

        let posicion = nombre_separado[1];
        
        let terrenos = proyectoData.terreno;

        terrenos[posicion] = value || '';

        setProyectoData({
            ...proyectoData,
            terreno: terrenos
        });
    }

    const onChangeAddressApn = ({ target }) => {

        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let direcciones = proyectoData.direcciones;

        direcciones[posicion] = target.value || '';

        setProyectoData({
            ...proyectoData,
            direcciones
        });
    }

    const onChangeUnitsLot = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[2];

        let unidades = proyectoData.terreno_unidad;

        unidades[posicion] = target.value;

        setProyectoData({ 
            ...proyectoData,
            [target.name]: unidades
        });
    }

    const onChangeRadioButtonFinancingType = ({ target }) => {      
        if(target.checked){
            setAddFinancingTypeData({
                ...addFinancingTypeData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            });
        }
    }

    const handleDeleteApn = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');    

        let apns = proyectoData.apn;
        let lotes = proyectoData.terreno;
        let unidades = proyectoData.terreno_unidad;

        apns.splice(id_separado[1], 1);
        lotes.splice(id_separado[1], 1);
        unidades.splice(id_separado[1], 1);

        setProyectoData({ 
            ...proyectoData, 
            apn: apns,
            terreno: lotes,
            terreno_unidad: unidades
        });
    }

    const apnsRows = (apns, lotes, unidades, direcciones) => {

        let apns_lots = [];

        if(apns){
            for (var x = 0; x < apns?.length; x++) {
                apns_lots.push(
                    <div className="col-md-6 row">
                        <div className="col-md-3 form-group campo_numero_xl">
                            <label className="font-weight-bold">APN:</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button className="btn btn-danger" type="button" id={'apnLote_' + x} onClick={e => handleDeleteApn(e)}>Delete</button>
                                </div>
                                <input className="form-control" type="text" name={"apn_" + x} onChange={ e => onChangeAPN(e) } value={apns[x] || ''} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)){ event.preventDefault(); }}} disabled={formDisabled ? true : false} />
                            </div>
                        </div>

                        <div className="col-md-3 form-group campo_numero_lg">
                            <label className="font-weight-bold">LOT:</label>
                            <div className="input-group">
                                <CurrencyInput
                                    name={'terreno_' + x}
                                    className="form-control text-center"
                                    value={lotes[x] || ''}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChangeLot(value, name)}
                                    disabled={formDisabled ? true : false}
                                />
                                <div className="input-group-append">
                                    <select className="browser-default custom-select" name={"terreno_unidad_" + x} value={unidades[x]} onChange={ e => onChangeUnitsLot(e) }>
                                        <option value="0">FT</option>
                                        <option value="1">AC</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Address:</label>
                            <input className="form-control" type="text" name={'direccion_' + x} onChange={ e => onChangeAddressApn(e) } value={direcciones[x] || ''} disabled={formDisabled ? true : false} />
                        </div>
                    </div>                
                );
            }
        }

        return apns_lots;
    }

    const handleToggleFileOffer = () => {
        return fileOffer.current?.click();
    }
    
    const handleOpenOfferFile = (url) => {
        window.open(url);
    }

    const onChangeFileOffer = () => {
        if(fileOffer.current.files.length > 0){
            setOfferFile({
                ...offerFile,
                file: fileOffer.current.files[0],
                archivo: URL.createObjectURL(fileOffer.current.files[0])
            });
        } else {
            setOfferFile({
                ...offerFile,
                file: undefined,
                archivo: ''
            })
        }          
    }

    /* Buyer company functions */
    const handleBuyerCompany = () => {
        setBuyerCompanyModal(true);

        let id_buyer_company = new ObjectID().toString();

        setAddBuyerCompanyData({
            ...addBuyerCompanyData,
            _id: id_buyer_company
        });
    }

    const handleInputsAddBuyerCompany = ({ target }) => {
        setAddBuyerCompanyData({
            ...addBuyerCompanyData,
            [target.name]: target.value
        });
    }

    const handleCloseModalBuyerCompany = () => {
        setBuyerCompanyModal(false);

        setAddBuyerCompanyData({
            _id: null,
            nombre: ''
        })
    }

    const handleAddBuyerCompany = async (e) => {
        e.preventDefault();
        if(addBuyerCompanyData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarBuyer(addBuyerCompanyData));

            await setProyectoData({
                ...proyectoData,
                id_buyer_company: addBuyerCompanyData._id
            });

            await setBuyerCompanySelectData({
                value: addBuyerCompanyData._id,
                label: addBuyerCompanyData.nombre
            });

            await setAddBuyerCompanyData({
                ...addBuyerCompanyData,
                _id: null,
                nombre: ''
            });

            await setBuyerCompanyModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }
    
    const handleViewBuyerCompany = async () => {

        let datos_buyer_select = null;

        if(proyectoData.id_buyer_company !== null){

            let buyer_company_elegido = await buyers.buyers?.filter(buyer => String(buyer._id) === String(proyectoData.id_buyer_company));

            if(buyer_company_elegido.length > 0){
                datos_buyer_select = {
                    value: buyer_company_elegido[0]._id,
                    label: buyer_company_elegido[0].nombre
                };
            }
        }

        await setBuyerCompanySelectData(datos_buyer_select);
    }

    /* Financing types functions */
    const handleFinancingType = () => {
        setFinancingTypeModal(true);

        let id_financing_type = new ObjectID().toString();

        setAddFinancingTypeData({
            ...addFinancingTypeData,
            _id: id_financing_type
        });
    }

    const handleInputsAddFinancingType = ({ target }) => {
        setAddFinancingTypeData({
            ...addFinancingTypeData,
            [target.name]: target.value
        });
    }

    const handleCloseModalFinancingType = () => {
        setFinancingTypeModal(false);

        setAddFinancingTypeData({
            _id: null,
            nombre: '',
            down_payment: false
        })
    }

    const handleAddFinancingType = async (e) => {
        e.preventDefault();
        if(addFinancingTypeData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarFinancingType(addFinancingTypeData));

            await setProyectoData({
                ...proyectoData,
                id_tipo_financiamiento: addFinancingTypeData._id
            });

            await setFinancingTypeSelectData({
                value: addFinancingTypeData._id,
                label: addFinancingTypeData.nombre,
                down_payment: addFinancingTypeData.down_payment
            });

            await setAddFinancingTypeData({
                ...addFinancingTypeData,
                _id: null,
                nombre: '',
                down_payment: false
            });

            await setFinancingTypeModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleViewFinancingType = async () => {

        let datos_financing_type_select = null;

        if(proyectoData.id_tipo_financiamiento !== null){

            let financing_type_elegido = await financing_types.financing_types?.filter(financing_type => String(financing_type._id) === String(proyectoData.id_tipo_financiamiento));

            if(financing_type_elegido.length > 0){
                datos_financing_type_select = {
                    value: financing_type_elegido[0]._id,
                    label: financing_type_elegido[0].nombre,
                    down_payment: financing_type_elegido[0].down_payment
                };
            }
        }

        await setFinancingTypeSelectData(datos_financing_type_select);
    }

    /* ScreenShoot functions */
    const onPasteScreenShotFile = (e) => {
        if(!e?.clipboardData?.files[0]){
            setScreenshotFile({
                ...screenshotFile,
                file: undefined,
                archivo: ''
            });
            
            alert('No file selected, try again');
            setScreenshotModal(false);
            
            
        }else{
            let file = e.clipboardData.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setScreenshotFile({
                    ...screenshotFile,
                    file: file,
                    url: reader.result
                });
            }
        }
    }

    const onDeleteScreenshot = () => {
        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }

    const handleCloseModalScreenShoot = () => {
        setScreenshotModal(false);

        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }


    /* Funciones generales */
    const onChange = ({ target }) => {

        setProyectoData({ 
            ...proyectoData,
            [target.name]: target.value
        });
    };    

    const onNumberChange = (name, value) => {

        setProyectoData({
            ...proyectoData, [name]: value || ''
        });
    };

    const onChangeRadioButtonApn = ({ target }) => {

        let apns = proyectoData.apn;
        let terrenos = proyectoData.terreno;
        let terrenos_unidades = proyectoData.terreno_unidad;

        if(target.value === 1 || target.value === '1'){
            setSectionInformation(10);
        }else{

            setSectionInformation(0);

            if(apns.length > 1){
                apns = [apns[0]];
                terrenos = [apns[0]];
                terrenos_unidades = [apns[0]];
            }
        }        

        if(target.checked){

            setProyectoData({
                ...proyectoData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false,
                apn: apns,
                terreno: terrenos,
                terreno_unidad: terrenos_unidades
            });
        }
    };

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let { value } = option;

            switch(origin){

                case 'id_buyer_company':
                    await setBuyerCompanySelectData(option);
                    break;

                case 'id_tipo_financiamiento':
                    await setFinancingTypeSelectData(option);
                    break;

                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(option);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value
                });
            }            
        }else{
            switch(origin){

                case 'id_buyer_company':
                    await setBuyerCompanySelectData(null);
                    break;

                case 'id_tipo_financiamiento':
                    await setFinancingTypeSelectData(null);
                    break;

                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(null);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null
                });
            }
        }
    };

    const handleSelectAddress = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setCoord({
                    lat: latLng.lat, lng: latLng.lng
                }),
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChangeAddress = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });
    };

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    }

    const handleTypeProject = (e) => {
        let valor = document.getElementById('p-proyecto');

        let id_elegido = (valor.value).split('/')[0];
        let asignar = (valor.value).split('/')[1];

        if(asignar === 'true'){
            asignar = true;
        }else{
            asignar = false;
        }

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: id_elegido,
                tipo_construccion: null
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: null,
                tipo_construccion: null
            });
        }
    }

    const handleTypeConstruction = (e) => {
        let valor = document.getElementById('t-construccion');

        let id_elegido = (valor.value).split('/')[0];
        let multi = (valor.value).split('/')[1];
        let edificios = (valor.value).split('/')[2];

        if(multi === 'true'){
            multi = true;
        }else{
            multi = false;
        }

        if(edificios === 'true'){
            edificios = true;
        }else{
            edificios = false;
        }

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: id_elegido,
                multi_unidades: multi,
                tiene_edificios: edificios,
                multi_apn: false
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: null,
                multi_unidades: true,
                tiene_edificios: true,
                multi_apn: false
            });
        }
    }

    const handleCoincidenciaModal = () => {
        setCoincidenciaModal(true);
    }

    const handleCloseCoincidenciaModal = () => {
        setCoincidenciaModal(false);
    }

    const newNote = async (campo) => {
        await dispatch(detallesPreProyecto(proyectoData));

        await dispatch(externoNota(true, campo));
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    /* Funciones de guardado */
    const handleCancel = async () => {
        await eliminarNotasProyecto(proyectoData._id);
        await localStorage.removeItem('datos');
        await history.push('/dashboard');
    }

    const handleValidate = async () => {
        if(proyectoData.direccion !== null && proyectoData.direccion !== ''){
            if (proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.purchase_price !== null  && proyectoData.id_usuario_responsable !== null) {
                await dispatch(validarProyecto(null, proyectoData.direccion));
            } else{
                await dispatch(setAlert('Missing information', 'danger'));
            }
        }else{
            await handleSave();
        }
    }

    const handleSave = async () => {
        if (proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.purchase_price !== null && proyectoData.id_usuario_responsable !== null) {

            let formData = new FormData();
            formData.append('analisis', proyectoData.analisis);
            formData.append('id_buyer_company', proyectoData.id_buyer_company);
            formData.append('tipo_proyecto', proyectoData.tipo_proyecto);
            formData.append('tipo_construccion', proyectoData.tipo_construccion);
            formData.append('direccion', proyectoData.direccion);
            formData.append('latitud', proyectoData.latitud);
            formData.append('longitud', proyectoData.longitud);
            formData.append('apn', JSON.stringify(proyectoData.apn));
            formData.append('terreno', JSON.stringify(proyectoData.terreno));
            formData.append('terreno_unidad', JSON.stringify(proyectoData.terreno_unidad));
            formData.append('direcciones', JSON.stringify(proyectoData.direcciones));
            formData.append('purchase_price', proyectoData.purchase_price);
            formData.append('asking_price', proyectoData.asking_price);
            formData.append('listing_id', proyectoData.listing_id);
            formData.append('screenshot_file', screenshotFile.file);
            formData.append('id_tipo_financiamiento', proyectoData.id_tipo_financiamiento);
            formData.append('down_payment', proyectoData.down_payment);
            formData.append('tipo_down_payment', proyectoData.tipo_down_payment);
            formData.append('offer_file', offerFile.file);
            formData.append('id_usuario_responsable', proyectoData.id_usuario_responsable);
            
            setSpinner(true);
            setFormDisabled(true);
            setTimeout(async () => {
                await dispatch(agregarOferta(formData));

                await dispatch(filterProyectos({
                    project_id: proyectoData.tipo_proyecto,
                    construction_id: proyectoData.tipo_construccion,
                    status_id: [null], 
                    active: true
                }));

                await localStorage.removeItem('datos');

                setSpinner(false);
                setFormDisabled(false);
                history.push('/projects/offers');
            }, 0);
            
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    useEffect(() => {
        if(JSON.stringify(proyectoData) !== JSON.stringify(proyectoDataInit)){
            localStorage.setItem('datos', JSON.stringify(proyectoData));
        }

        handleViewBuyerCompany();
    }, [proyectoData]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < buyers.buyers?.length; i++) {
            options.push({ value: buyers.buyers[i]._id, label: buyers.buyers[i].nombre });
        }

        setBuyersFilter(options);

        handleViewBuyerCompany();
    }, [buyers]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < financing_types.financing_types?.length; i++) {
            options.push({ value: financing_types.financing_types[i]._id, label: financing_types.financing_types[i].nombre, down_payment: financing_types.financing_types[i].down_payment });
        }

        setFinancingTypesFilter(options);

        handleViewFinancingType();
    }, [financing_types]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {
        if(proyectos.proyecto_coincidencia !== null){
            if(proyectos.proyecto_coincidencia !== ''){
                setCoincidenciaProyecto({
                    ...coincidenciaProyecto,
                    direccion: proyectos.proyecto_coincidencia?.direccion,
                    proyecto: proyectos.proyecto_coincidencia?.proyecto,
                    construccion: proyectos.proyecto_coincidencia?.construccion
                });
            }else{
                handleSave();
            }
        }
    }, [proyectos]);

    useEffect(() => {
        if(proyectoData.tipo_proyecto === null){
            dispatch(buscarTiposProyectos(0));
        }else{
            dispatch(buscarTiposProyectos(proyectoData.tipo_proyecto));
        }
    }, [proyectoData.tipo_proyecto]);

    useEffect(() => {
        if(proyectoData.multi_apn){
            setSectionInformation(10);
        }else{
            setSectionInformation(0);
        }
    }, [proyectoData.multi_apn]);

    useEffect(() => {
        if(proyectoData.id_tipo_financiamiento === null){
            setFinancingTypeSelectData(null);

            setProyectoData({
                ...proyectoData,
                tiene_down_payment: false,
                down_payment: null,
                tipo_down_payment: 0
            });
        }else{

            let tiene_down_payment = false;

            if(financingTypesFilter.length > 0){

                let financiamiento_asignado = financingTypesFilter.find( tipo => String(tipo.value) === String(proyectoData.id_tipo_financiamiento) );

                if(financiamiento_asignado){
                    setFinancingTypeSelectData({value: financiamiento_asignado.value, label: financiamiento_asignado.label});

                    tiene_down_payment = financiamiento_asignado.down_payment;
                }
            }

            setProyectoData({
                ...proyectoData,
                tiene_down_payment,
                down_payment: null,
                tipo_down_payment: 0
            });
        }
    }, [proyectoData.id_tipo_financiamiento, financingTypesFilter]);

    useEffect(() => {
        if(proyectoData.id_usuario_responsable === null){
            setUserResponsableSelectData(null);
        }else{
            if(usersFilter.length > 0){

                let usuario_asignado = usersFilter.filter( usuario => String(usuario.value) === String(proyectoData.id_usuario_responsable) );
                
                if(usuario_asignado.length > 0){
                    setUserResponsableSelectData(usuario_asignado);
                }
            }
        }
    }, [usersFilter]);

    useEffect(() => {
        if(coincidenciaProyecto.direccion !== '' && coincidenciaProyecto.proyecto !== '' && coincidenciaProyecto.construccion !== ''){
            handleCoincidenciaModal();
        }
    }, [coincidenciaProyecto]);

    useEffect(() => {
        dispatch(buscarTiposProyectos(0));
        dispatch(buscarBuyers());
        dispatch(buscarFinancingTypes());
        dispatch(buscarUsuarios());

        if(localStorage.getItem('datos')){
            setProyectoData(JSON.parse(localStorage.getItem('datos')));
        }else{
            setProyectoData({
                ...proyectoData,
                _id: new ObjectID().toString()
            });
        }

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {        
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    return (
        <Fragment>
            {/* Modal de agregar buyer */}
            <Modal backdrop="static" show={buyerCompanyModal} size="md" centered onHide={() => {
                handleCloseModalBuyerCompany()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Buyer company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addBuyerCompanyData.nombre || ''} onChange={e => handleInputsAddBuyerCompany(e)}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBuyerCompany()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBuyerCompany}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de agregar financing type */}
            <Modal backdrop="static" show={financingTypeModal} size="md" centered onHide={() => {
                handleCloseModalFinancingType()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Financing type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addFinancingTypeData.nombre || ''} onChange={e => handleInputsAddFinancingType(e)}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group campo_radio_lg">
                            <label className="font-weight-bold">Has a down payment?:</label><br/>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="down_payment" value="1" onChange={e => onChangeRadioButtonFinancingType(e)} checked={addFinancingTypeData.down_payment ? true : false} />
                                <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="down_payment" value="2" onChange={e => onChangeRadioButtonFinancingType(e)} checked={addFinancingTypeData.down_payment ? false : true} />
                                <label className="form-check-label">No</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalFinancingType()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddFinancingType}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de coincidencia */}
            <Modal backdrop="static" show={coincidenciaModal} centered onHide={() => {
                setCoincidenciaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Similarity in projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>The project with the address "<b>{coincidenciaProyecto.direccion}</b>" (<b>{coincidenciaProyecto.proyecto}</b> - <b>{coincidenciaProyecto.construccion}</b>) has some similarity. Do you want to continue saving anyway?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseCoincidenciaModal()}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => {setCoincidenciaModal(false); handleSave();}}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal para agregar Screenshot File */}
            <Modal backdrop="static" show={screenshotModal} centered onHide={() => {
                setScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Pasting Screenshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12" onPaste={(e)=> onPasteScreenShotFile(e)} style={{height: '100%', width: '100%', 'border-style': 'dashed', 'border-color': '#6c757d' }}>
                            {
                                screenshotFile.url !== '' ?
                                <>
                                    <button className="btn btn-small btn-danger py-1 px-2" onClick={() => onDeleteScreenshot()}><i className='fas fa-times'></i></button>
                                    <img id='pasteScreenshot' src={screenshotFile.url} style={{height: '100%', width: '100%', padding: '8px'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}>Paste Here</h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalScreenShoot()}>Cancel</button>
                    <button className="btn btn-success" onClick={() => setScreenshotModal(false)}>Save</button>
                </Modal.Footer>
            </Modal>
            
            {/* Modal para ver Screenshot File */}
            <Modal backdrop="static" show={viewScreenshotModal} size="lg" centered onHide={() => {
                setViewScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>View Screenshot file</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="row">
                        <div className="col-12">
                            {
                                screenshotFile.file !== null ?
                                <>
                                    <img id='screenshotFile' src={screenshotFile.url} style={{width: '100%'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}> No image Found </h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => setViewScreenshotModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <form id="contender_form" className="col-md-12" autoComplete="off" onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}}>
                    <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />

                    <div className="row d-flex">
                        <h3 className="col-md mb-3 mt-5">Generate Pre-offer</h3>

                        <div className="col-md d-flex justify-content-end mb-3 mt-5">

                            <button type="button" className="btn btn-primary mr-2" onClick={() => setScreenshotModal(true)}><i className="fas fa-file-medical fa-sm"></i> {screenshotFile.file !== null ? 'Update' : 'Add'} Screenshot file</button>
                            {
                                screenshotFile.file !== null ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => setViewScreenshotModal(true)}><i className="fas fa-eye fa-sm"></i> View Screenshoot file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => handleToggleFileOffer()}><i className="fas fa-file-medical fa-sm"></i> {proyectoData.url_offer_file || offerFile.file ? 'Update' : 'Add'} offer file</button>
                            {
                                proyectoData.url_offer_file || offerFile.file ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => handleOpenOfferFile(offerFile.file ? offerFile.archivo : proyectoData.url_offer_file)}><i className="fas fa-file fa-sm"></i> View offer file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setCollapse(!collapse)} data-toggle="collapse" data-target="#contenedor_mapa" aria-expanded="true" aria-controls="contenedor_mapa"><i className="fas fa-map"></i> {collapse ? 'Hide Map' : 'View Map'}</button>
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleCancel()} disabled={formDisabled ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                            <button type="button" className="btn btn-success" onClick={() => handleValidate()} disabled={formDisabled ? true : false}><i className="fas fa-check fa-sm"></i> Save and continue</button>
                        </div>
                    </div>

                    {spinner ?  
                            <div className="d-flex justify-content-center">
                                <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="row mt-5">    
                        <div className="col-md-12 row">
                            <div className="col-md-2 form-group">
                                <input type="file" name="fileOffer" id="fileOffer" ref={fileOffer} onChange={onChangeFileOffer} className="d-none" />
                                <label className="font-weight-bold" onClick={() => newNote('Buyer')}>Buyer:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_buyer_company"
                                        className="select-group"
                                        aria-describedby="button-buyer-company"
                                        isClearable={true}
                                        options={buyersFilter}
                                        value={buyerCompanySelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_buyer_company', '')}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" id="button-buyer-company" onClick={() => handleBuyerCompany()}>Create</button>
                                    </div>
                                </div>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_proyecto !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Project Type')}>Project Type: *</label> <br></br>
                                <select name="tipo_proyecto" id="p-proyecto" className="form-control" onChange={e => {handleTypeProject(e)}} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.proyectos?.length > 0 ?
                                            tipos.proyectos?.map(tipo => {
                                                return(
                                                    <option key={tipo._id} value={ tipo._id + '/' + tipo.asignado } selected={(proyectoData.tipo_proyecto === tipo._id) ? true : null}>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_construccion !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Property Type')}>Property Type: *</label> <br></br>
                                <select name="tipo_construccion" id="t-construccion" className="form-control" onChange={e => {handleTypeConstruction(e)}} disabled={formDisabled ? true : false} >
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.construcciones?.length > 0 ?
                                            tipos.construcciones?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={ tipo._id + '/' + tipo.multi_unidades + '/' + tipo.tiene_edificios } selected={(proyectoData.tipo_construccion === tipo._id) ? true : null}>
                                                        { tipo.nombre }
                                                    </option>
                                                )
                                            })
                                        :
                                            ''
                                    }
                                </select>
                            </div>
                            
                            {
                                proyectoData.tiene_edificios ?
                                    null   
                                :
                                    <div className="col-md-2 form-group campo_radio_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('Will there be multiple APNs?')}>Will there be multiple APNs?</label><br/>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="multi_apn" value="1" onChange={e => onChangeRadioButtonApn(e)} checked={proyectoData.multi_apn ? true : false} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="multi_apn" value="2" onChange={e => onChangeRadioButtonApn(e)} checked={proyectoData.multi_apn ? false : true} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                            }

                            <PlacesAutocomplete
                                value={proyectoData.direccion}
                                onChange={handleChangeAddress}
                                onSelect={handleSelectAddress}
                            >

                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className={'col-md form-group' + (proyectoData.direccion !== null && proyectoData.direccion !== '' ? '' : (proyectoData.tiene_edificios === false ? '' : ' campo-recordatorio'))}>
                                        <label className="font-weight-bold" onClick={() => newNote('General address')}>General address: {proyectoData.tiene_edificios === false ? '' : '*'}</label>
                                        <input
                                            {...getInputProps({
                                                className: 'location-search-input form-control',
                                                id: 'i-direccion'
                                            })}
                                            disabled={formDisabled ? true : false}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const id = uuid();
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                        })}
                                                        key={id}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            
                            {
                                proyectoData.multi_apn ?
                                    null   
                                :
                                    <div className="col-md form-group campo_numero_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('APN')}>APN:</label>
                                        <input className="form-control" type="text" name={"apn_" + 0} onChange={ e => onChangeAPN(e) } value={proyectoData.apn ? proyectoData.apn[0] : ''} 
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            disabled={formDisabled ? true : false}
                                        ></input>
                                    </div>
                            }
                        </div>
                        
                        <div className="col-md-12 row">

                            <div className={'col-md-2 form-group campo_dinero' + (proyectoData.purchase_price !== null && proyectoData.purchase_price !== '' ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Offer price')}>Offer price $: *</label>
                                <CurrencyInput
                                    name="purchase_price"
                                    className="form-control text-center"
                                    value={proyectoData.purchase_price || ''}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                    disabled={formDisabled ? true : false}
                                />
                            </div>

                            <div className="col-md-2 form-group campo_dinero">
                                <label className="font-weight-bold" onClick={() => newNote('Asking price')}>Asking price $:</label>
                                <CurrencyInput
                                    name="asking_price"
                                    className="form-control text-center"
                                    value={proyectoData.asking_price || ''}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                    disabled={formDisabled ? true : false}
                                />
                            </div>

                            <div className="col-md-2 form-group campo_dinero">
                                <label className="font-weight-bold" onClick={() => newNote('Listing ID')}>Listing ID:</label>
                                <input type="text" name="listing_id" className="form-control text-center" onChange={ e => onChange(e) } value={proyectoData.listing_id} />
                            </div>

                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Financing type')}>Financing type:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_tipo_financiamiento"
                                        className="select-group"
                                        isClearable={true}
                                        options={financingTypesFilter}
                                        value={financingTypeSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_tipo_financiamiento', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" id="button-financing-type" onClick={() => handleFinancingType()}>Create</button>
                                    </div>
                                </div>
                            </div>

                            {
                                proyectoData.tiene_down_payment ? 
                                    <div className="col-md-2 form-group campo_dinero">
                                        <label className="font-weight-bold" onClick={() => newNote('Down payment')}>Down payment {proyectoData.tipo_down_payment === 0 || proyectoData.tipo_down_payment === '0' ? '%' : '$'}:</label>
                                        <div className="input-group">
                                            <CurrencyInput
                                                name="down_payment"
                                                className="form-control text-center"
                                                value={proyectoData.down_payment || ''}
                                                decimalsLimit={2}
                                                groupSeparator=","
                                                decimalSeparator="."
                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                disabled={formDisabled ? true : false}
                                            />
                                            <div className="input-group-append">
                                                <select className="browser-default custom-select" name="tipo_down_payment" value={proyectoData.tipo_down_payment} onChange={ e => onChange(e) }>
                                                    <option value="0">%</option>
                                                    <option value="1">$</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                            
                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.id_usuario_responsable !== null && proyectoData.id_usuario_responsable !== '' ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Who will be the responsible user?')}>Who will be the responsible user?:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_usuario_responsable"
                                        className="select-group"
                                        isClearable={true}
                                        options={usersFilter}
                                        value={userResponsableSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_usuario_responsable', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'row mt-2 p-3 mx-0 seccion_information' + ((sectionInformation === 10 || sectionInformation === '10') && proyectoData.multi_apn === true ? '' : ' d-none')}>
                        
                        <div className="col-md-12 form-group text-right">
                            <button type="button" className="btn btn-primary" onClick={() => handleAddApn()}><i className="fas fa-map fa-sm"></i> Add APN</button>
                        </div>

                        { apnsRows(proyectoData.apn, proyectoData.terreno, proyectoData.terreno_unidad, proyectoData.direcciones) }
                    </div>

                    <div id="contenedor_mapa" className="row mt-4 collapse" aria-labelledby="headingOne" data-parent="#contender_form">
                        <div className="col-md-8 offset-md-2 form-group">
                            <div id="mapa" className="mapa">
                                <GoogleMap
                                    mapContainerStyle={ContainerStyle}
                                    center={coord}
                                    zoom={16}
                                    onRightClick={e => { handleMapClick(e) }}
                                >
                                    <Marker key={coord.lat} position={coord} />
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default NuevaOferta;