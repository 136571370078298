import React, { Fragment } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

import NavbarPublic from './NavbarPublic';
import { useSelector } from 'react-redux';

const LayoutPublic = () => {

    const { isPublic } = useSelector(state => state.auth);

    const layoutGuest = (
        <Fragment>
            <NavbarPublic></NavbarPublic>
        </Fragment>
    );

    const layoutAdmin = (<Fragment></Fragment>);

    if(isPublic){
        return layoutGuest;
    } else{
        return layoutAdmin;
    }
}

export default LayoutPublic;