import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { buscarUsuarios, eliminarUsuario, editandoUsuario, validarUsuario } from '../../actions/usuarios';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setAlert } from '../../actions/alert';

const Usuarios = ({ history }) => {

    const matches = useMediaQuery('(min-width:768px)');

    const user = useSelector(state => state.auth.user);
    const usuarios = useSelector(state => state.usuarios);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    const handleEnviarManual = item => {

        let url = 'admin.uberousinc.com';

        let tasks_word = 'task';

        if(item.tasks > 1){
            tasks_word = 'tasks';
        }

        let texto_enviar = 'Hello ' + item.nombre + ', you have ' + item.tasks + ' ' + tasks_word + ' to do. Access in the system in the following URL: ' + url;

        if(item.telefono !== '' && item.telefono !== null){
            let numero_enviar = '';

            if(item.telefono.length > 10){
                numero_enviar = item.telefono.substr(0, item.telefono.length - 10) + '1' + item.telefono.substr(item.telefono.length - 10);
            }else{
                numero_enviar = '521' + item.telefono.substr(item.telefono.length - 10);
            }

            let win = window.open('https://api.whatsapp.com/send?text=' + texto_enviar + '&phone=+' + numero_enviar, '_blank');
            win.focus();
        }else{
            navigator.clipboard.writeText(texto_enviar);
            dispatch(setAlert('Messagge sended', 'success'));
        }
    }

    useEffect(() => {
        dispatch(buscarUsuarios());
    }, []);

    const tableRow = (items) => {
        if(items){
            if(items.length > 0){

                return (
                    <tbody>
                        {
                            items.map( (item) => {
                                let fecha = new Date(item.date);
                                if(user && user._id !== item._id){
                                    return (
                                        <Fragment key={item._id}>
                                            <tr>
                                                <td> {item.nombre} </td>
                                                <td> {item.usuario} </td>
                                                <td> {item.email} </td>
                                                <td> {fecha.getDate()}/{fecha.getMonth() + 1}/{fecha.getFullYear()} </td>
                                                <td>
                                                    <button type="button" className="btn text-success" onClick={() => handleEnviarManual(item)} disabled={item.tasks > 0 ? false : true}>Send <i className="fab fa-whatsapp"></i></button>
                                                </td>
                                                <td>
                                                    {
                                                        !permisos_configuration?.disable_user?
                                                        null
                                                        :
                                                        <button className="btn btn-link" onClick={() => status(item._id, item.validado)}>{(item.validado === true) ? <span className="text-danger">Disable</span> : <span className="text-primary">Enable</span>}</button>
                                                    }
                                                </td> 
                                                <td>
                                                    {
                                                        !permisos_configuration?.edit_user ?
                                                        null
                                                        :
                                                        <button className="btn btn-link text-danger" onClick={() => editar(item._id)}>Edit</button>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        !permisos_configuration?.delete_user ?
                                                        null
                                                        :
                                                        <button id={'eliminar_'+item._id} className="btn btn-link text-danger" onClick={() => confirmar(item._id, 1)}>Delete</button>
                                                    }
                                                    
                                                    <div id={'confirmar_'+item._id} className="text-center" style={{display: 'none'}}>
                                                        <label>Continue?</label>
                                                        <div style={{display: 'flex'}}>
                                                            <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}><i className="fas fa-check fa-sm"></i> Yes</button>
                                                            <button className="btn btn-link text-danger" onClick={() => confirmar(item._id, 2)}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                } else{
                                    return (
                                        <Fragment key={item._id}>
                                            <tr className="text-muted">
                                                <td>{item.nombre}</td>
                                                <td>{item.usuario}</td>
                                                <td>{item.email}</td>
                                                <td>{fecha.getDay()}/{fecha.getMonth() + 1}/{fecha.getFullYear()}</td>
                                                <td colSpan="4" className="text-center">
                                                    <Link to="/profile" className="btn btn-link text-secondary">View Profile</Link>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                }
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">No users registred.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">No users registred.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    const confirmar = (id, type) => {
        if (type === 1) {
            document.getElementById('eliminar_'+id).style.display = 'none';
            document.getElementById('confirmar_'+id).style.display = 'unset';
        } else {
            document.getElementById('eliminar_'+id).style.display = 'unset';
            document.getElementById('confirmar_'+id).style.display = 'none';
        }
    }

    const status = (id, validado) => {
        (validado === true) ? dispatch(validarUsuario(id, false)) : dispatch(validarUsuario(id,true));
    }
 
    const eliminar = id => {
        dispatch(eliminarUsuario(id));
    }

    const editar = id => {
        dispatch(editandoUsuario(id));
        history.push('/users/edit');
    }

    return (
        <div className="row">
            <div className="col-md-8 offset-md-2" style={ matches ? {} : { marginLeft: '3%' } }>
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Users</h3>
                        <p className="text-muted"> {usuarios.length} registered users</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            !permisos_configuration?.add_user ? 
                            null
                            :
                            <Link to="/users/new" className="btn btn-success text-right"><i className="fas fa-plus fa-sm"></i> Add user</Link>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">User</th>
                                <th scope="col">Email</th>
                                <th scope="col">Registration date</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {tableRow(usuarios)}
                    </table>
                </div>
            </div>
        </div>
    );    
}

export default Usuarios;