import { OBTENER_DATOS_CONTABILIDAD, OBTENER_TARJETAS_CREDITO, DETALLES_TARJETA_CREDITO, STATUS_TARJETA_CREDITO, ELIMINAR_TARJETA_CREDITO, AGREGAR_CREDITO_TARJETA, EDITAR_CREDITO_TARJETA, DETALLES_CREDITO_TARJETA, ELIMINAR_CREDITO_TARJETA, AGREGAR_PAGO_TARJETA, EDITAR_PAGO_TARJETA, DETALLES_PAGO_TARJETA, ELIMINAR_PAGO_TARJETA, AGREGAR_ESTADO_TARJETA, EDITAR_ESTADO_TARJETA, DETALLES_ESTADO_TARJETA, DETALLES_ARCHIVO_ESTADO_TARJETA, ELIMINAR_ESTADO_TARJETA } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const obtenerDatos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/accounting`, config);

        dispatch({
            type: OBTENER_DATOS_CONTABILIDAD,
            payload: res.data.datos
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerEstadosTarjeta = (id, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/estados-tarjeta`, body, config);

        dispatch({
            type: OBTENER_DATOS_CONTABILIDAD,
            payload: {
                ...datos, 
                estados_tarjeta: res.data.estados_tarjeta
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerBalancesTarjeta = (periodo, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ periodo });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/balances-tarjeta`, body, config);

        dispatch({
            type: OBTENER_DATOS_CONTABILIDAD,
            payload: {
                ...datos, 
                balances_tarjeta: res.data.tarjetas
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerPeriodoMovimientosTarjeta = (periodo, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ periodo });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/periodo-movimientos-tarjeta`, body, config);

        dispatch({
            type: OBTENER_DATOS_CONTABILIDAD,
            payload: {
                ...datos, 
                periodo_movimientos_tarjeta: res.data.periodos
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarTarjetasCredito = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/accounting/tarjetas`, config);

        dispatch({
            type: OBTENER_TARJETAS_CREDITO,
            payload: {
                tarjetas_deuda: res.data.tarjetas_deuda, 
                tarjetas_libres: res.data.tarjetas_libres
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTarjetaCredito = (tarjeta) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(tarjeta);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/nueva-tarjeta`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesTarjetaCredito = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ id });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/detalles-tarjeta`, body, config);

            dispatch({
                type: DETALLES_TARJETA_CREDITO,
                payload: res.data.tarjeta
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_TARJETA_CREDITO,
            payload: null
        });
    }
}

export const editarTarjetaCredito = (tarjeta) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(tarjeta);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/editar-tarjeta`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const statusTarjetaCredito = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/status-tarjeta`, body, config);

        dispatch({
            type: STATUS_TARJETA_CREDITO,
            payload: res.data.status
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTarjetaCredito = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/eliminar-tarjeta`, body, config);
        dispatch({
            type: ELIMINAR_TARJETA_CREDITO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Estados de tarjeta */

export const agregarEstadoTarjetaCredito = (estado) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/nuevo-estado`, estado, config);

        dispatch({
            type: AGREGAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesEstadoTarjetaCredito = (estado) => async dispatch => {
    if(estado){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(estado);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/detalles-estado`, body, config);

            dispatch({
                type: DETALLES_ESTADO_TARJETA,
                payload: res.data.estado
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_ESTADO_TARJETA,
            payload: null
        });
    }
}

export const detallesFileEstadoTarjetaCredito = (id_tarjeta, id_estado) => async dispatch => {
    if(id_tarjeta){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id_tarjeta, 
            id_estado
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/detalles-archivo`, body, config);

            dispatch({
                type: DETALLES_ARCHIVO_ESTADO_TARJETA,
                payload: res.data.archivo
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_ARCHIVO_ESTADO_TARJETA,
            payload: null
        });
    }
}

export const editarEstadoTarjetaCredito = (estado) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/editar-estado`, estado, config);

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarEstadoTarjetaCredito = (estado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(estado);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/eliminar-estado`, body, config);

        dispatch({
            type: ELIMINAR_ESTADO_TARJETA,
            payload:{
                id: estado.id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Pagos de tarjeta */

export const agregarPagoTarjetaCredito = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/nuevo-pago`, body, config);

        dispatch({
            type: AGREGAR_PAGO_TARJETA,
            payload: res.data.pago
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesPagoTarjetaCredito = (pago) => async dispatch => {
    if(pago){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(pago);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/detalles-pago`, body, config);

            dispatch({
                type: DETALLES_PAGO_TARJETA,
                payload: res.data.pago
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_PAGO_TARJETA,
            payload: null
        });
    }
}

export const editarPagoTarjetaCredito = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/editar-pago`, body, config);

        dispatch({
            type: EDITAR_PAGO_TARJETA,
            payload: res.data.pago
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarPagoTarjetaCredito = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/eliminar-pago`, body, config);

        dispatch({
            type: ELIMINAR_PAGO_TARJETA,
            payload:{
                id: pago.id
            }
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Creditos de tarjeta */

export const agregarCreditoTarjetaCredito = (credito) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(credito);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/nuevo-credito`, body, config);

        dispatch({
            type: AGREGAR_CREDITO_TARJETA,
            payload: res.data.credito
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesCreditoTarjetaCredito = (credito) => async dispatch => {
    if(credito){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(credito);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/detalles-credito`, body, config);

            dispatch({
                type: DETALLES_CREDITO_TARJETA,
                payload: res.data.credito
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_CREDITO_TARJETA,
            payload: null
        });
    }
}

export const editarCreditoTarjetaCredito = (credito) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(credito);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/editar-credito`, body, config);

        dispatch({
            type: EDITAR_CREDITO_TARJETA,
            payload: res.data.credito
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarCreditoTarjetaCredito = (credito) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(credito);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/accounting/eliminar-credito`, body, config);

        dispatch({
            type: ELIMINAR_CREDITO_TARJETA,
            payload:{
                id: credito.id
            }
        });

        dispatch({
            type: EDITAR_ESTADO_TARJETA,
            payload: res.data.estado
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}