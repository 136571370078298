import { BUSCAR_SEPTICS, AGREGAR_SEPTIC, EDITAR_SEPTIC, DETALLES_SEPTIC, ELIMINAR_SEPTIC, BUSCAR_SEPTICS_AGENTES, AGREGAR_SEPTIC_AGENTE, AGREGAR_SEPTIC_AGENTE_DIRECTO, EDITAR_SEPTIC_AGENTE, ELIMINAR_SEPTIC_AGENTE } from '../actions/types';

const initialState = {
    septics: [],
    agents: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_SEPTIC:
            return {
                ...state,
                septics: [
                    ...state.septics, payload
                ]
            };
        case EDITAR_SEPTIC:
            return {
                ...state,
                septics: state.septics.map(
                    septic => septic._id === payload._id ? payload : septic
                )
            };
        case DETALLES_SEPTIC:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_SEPTIC:
            return {
                ...state,
                septics: state.septics.filter( septic => septic._id !== payload.id )
            };
        case BUSCAR_SEPTICS:
            return {
                ...state,
                septics: payload
            };
        case BUSCAR_SEPTICS_AGENTES:
            return {
                ...state,
                agents: payload
            };
        case AGREGAR_SEPTIC_AGENTE:
            return {
                ...state,
                agents: [
                    ...state.agents, payload
                ]
            };
        case AGREGAR_SEPTIC_AGENTE_DIRECTO:

            const septic_elegido = state.septics.find(septic => septic._id === payload.id_septic);
            
            if(septic_elegido){
                septic_elegido.agentes = [...septic_elegido.agentes, payload];
            }

            return {
                ...state,
                septics: state.septics.map( 
                    septic => septic._id === payload.id_septic ? septic_elegido : septic
                )
            };
        case EDITAR_SEPTIC_AGENTE:
            return {
                ...state,
                agents: state.agents.map(
                    agent => agent._id === payload._id ? payload : agent
                )
            };
        case ELIMINAR_SEPTIC_AGENTE:
            return {
                ...state,
                agents: state.agents.filter( agent => agent._id !== payload.id )
            };
        default:
            return state;
    }
}