import React, { useState, useEffect, useRef, createRef, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Modal, Badge } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { setAlert } from '../../actions/alert';
import { listarProyectos, seleccionarProyecto } from '../../actions/proyectos';
import { buscarSecciones } from '../../actions/secciones_todolist';
import { buscarUsuarios } from '../../actions/usuarios';
import { buscarFechas } from '../../actions/recordatorios';
import { buscarTareas, notaTarea, detallesTarea, detallesArchivo, externaTarea } from '../../actions/todolist';

const styles = {
    buttonIcon: {
        fontSize: 18,
    }
}

const Agenda = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const { user } = useSelector(state => state.auth);
    const { listado } = useSelector(state => state.proyectos);
    const { recordatorios } = useSelector(state => state.recordatorios);
    const { detalles_tarea, detalles_archivo, secciones } = useSelector(state => state.todolist);
    const { permisos_reminders, permisos_todolist } = useSelector(state => state.auth.user);

    const [dateActual, setDateActual] = useState(moment().toDate());
    const [viewActual, setViewActual] = useState('month');
    const [eventosCalendario, setEventosCalendario] = useState([]);

    const [flag, setFlag] = useState(false);
    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [detailsReminderModal, setDetailsReminderModal] = useState(false);
    const [detailsReminderManualModal, setDetailsReminderManualModal] = useState(false);
    const [detailsTaskModal, setDetailsTaskModal] = useState(false);
    const [filtersModal, setFiltersModal] = useState(false);
    const [projectFilterInformationData, setProjectFilterInformationData] = useState(null);
    const [proyectosFilter, setProyectosFilter] = useState([]);

    const [reminderDetailsData, setReminderDetailsData] = useState({
        tipo: null,
        contenido: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const [taskDetailsData, setTaskDetailsData] = useState({
        id: null,
        nombre_proyecto: null,
        nombre_seccion: null,
        nombre_creador: null,
        nombre_responsable: null,
        colaboradores: [],
        nombre: null,
        descripcion: null,
        fecha_entrega: null,
        tipo_prioridad: null,
        status: [],
        notas: []
    });

    const [taskNoteData, setTaskNoteData] = useState({
        id: null,
        nota: null,
        archivos: null
    });
    
    const [filtersData, setFiltersData] = useState({
        id_proyecto: null,
        id_seccion: null,
        prioridad: null,
        status: null,
        finalizada: false,
        creada: true,
        id_responsable: null,
        ids_colaboradores: [],
        fecha_inicial: null,
        fecha_final: null
    });

    const localizer = momentLocalizer(moment);
    const clickRef = useRef(null);

    const DnDCalendar = withDragAndDrop(Calendar);

    const tiempo = Date.now();
    const hoy = new Date(tiempo);
    const myRef = useRef(null);

    let files_ref = createRef();

    const scrollBottom = () => myRef.current.scrollIntoView({behavior: 'smooth'});

    //Detalles
    const handleOpenDetailsReminder = (recordatorio, tipo) => {
        switch(tipo){
            case 1:
            case '1':
                setDetailsReminderModal(true);

                setReminderDetailsData({
                    ...reminderDetailsData,
                    tipo: tipo,
                    contenido: recordatorio.mensaje,
                    fecha_inicio: recordatorio.fecha_inicio,
                    fecha_fin: recordatorio.fecha_fin
                });
                break;

            case 2:
            case '2':
                setDetailsReminderManualModal(true);

                setReminderDetailsData({
                    ...reminderDetailsData,
                    tipo: tipo,
                    contenido: recordatorio.mensaje,
                    fecha_inicio: recordatorio.fecha_inicio,
                    fecha_fin: recordatorio.fecha_fin
                });
                break;

            case 3:
            case '3':
                setDetailsTaskModal(true);

                setTaskNoteData({
                    ...taskNoteData,
                    id: recordatorio._id,
                });

                setTaskDetailsData({
                    ...taskDetailsData,
                    id: recordatorio._id,
                    nombre_proyecto: recordatorio.nombre_proyecto,
                    nombre_seccion: recordatorio.nombre_seccion,
                    nombre_creador: recordatorio.nombre_creador,
                    nombre_responsable: recordatorio.nombre_responsable,
                    colaboradores: recordatorio.colaboradores,
                    nombre: recordatorio.nombre,
                    descripcion: recordatorio.descripcion,
                    fecha_entrega: recordatorio.fecha_entrega,
                    tipo_prioridad: recordatorio.tipo_prioridad,
                    notas: recordatorio.notas,
                    status: recordatorio.status
                });
                break;

            default:
                break;
        }
    }

    const handleCloseDetailsGasto = (tipo) => {
        switch(tipo){
            case 1:
            case '1':
                setDetailsReminderModal(false);

                setReminderDetailsData({
                    ...reminderDetailsData,
                    tipo: null,
                    contenido: null,
                    fecha_inicio: null,
                    fecha_fin: null
                });
                break;

            case 2:
            case '2':
                setDetailsReminderManualModal(false);

                setReminderDetailsData({
                    ...reminderDetailsData,
                    tipo: null,
                    contenido: null,
                    fecha_inicio: null,
                    fecha_fin: null
                });
                break;

            case 3:
            case '3':
                setDetailsTaskModal(false);

                setTaskDetailsData({
                    ...taskDetailsData,
                    id: null,
                    nombre_proyecto: null,
                    nombre_seccion: null,
                    nombre_creador: null,
                    nombre_responsable: null,
                    colaboradores: [],
                    nombre: null,
                    descripcion: null,
                    fecha_entrega: null,
                    tipo_prioridad: null,
                    notas: [],
                    status: []
                });

                dispatch(detallesTarea(null));
                break;
                
            default:
                break;
        }
    }

    const prioridadTask = (prioridad) => {

        switch(prioridad){
            case 0:
                return <h5><Badge variant="success">Low</Badge></h5>;
                break;

            case 1:
                return <h5><Badge variant="primary">Medium</Badge></h5>;
                break;

            case 2:
                return <h5><Badge variant="warning">High</Badge></h5>;
                break;

            case 3:
                return <h5><Badge variant="danger">Urgent</Badge></h5>;
                break;

            default:
                return <h5><Badge variant="secondary">Not defined</Badge></h5>;
                break;
        }
    }

    const statusTask = (status) => {

        switch(status){
            case 0:
                return  <h5>
                            <span className="badge badge_new_task">Need attention</span>
                        </h5>;
                break;

            case 1:
                return  <h5>
                            <span className="badge badge_in_progress">In process</span>
                        </h5>;
                break;

            case 2:
                return <h5><Badge variant="warning">Waiting</Badge></h5>;
                break;

            case 3:
                return <h5><Badge variant="success">Finished</Badge></h5>;
                break;

            case 4:
                return <h5><Badge variant="danger">Cancelled</Badge></h5>;
                break;

            default:
                break;
        }
    }

    const botonesArchivos = (id_nota, archivos) => {

        let botones_archivos = [];

        for (var i = 0; i < archivos.length; i++) {

            let extension = 'file';

            switch(archivos[i].extension){
                case '.pdf':
                    extension = 'file-pdf';
                    break;
    
                case '.jpeg':
                case '.jpg':
                case '.png':
                case '.gif':
                    extension = 'file-image';
                    break;
    
                case '.mp4':
                case '.wmv':
                case '.mov':
                    extension = 'file-video';
                    break;
    
                case '.mp3':
                case '.m4a':
                case '.aac':
                    extension = 'file-audio';
                    break;
    
                case '.doc':
                case '.docx':
                    extension = 'file-word';
                    break;
    
                case '.xls':
                case '.xlsx':
                    extension = 'file-excel';
                    break;
    
                default:
                    extension = 'file';
                    break;
            }

            let datos = {
                id_nota,
                id_archivo: archivos[i]._id
            }

            botones_archivos.push(
                <button className="btn btn_link_color" onClick={() => handleIconoArchivo(datos)}>
                    <i className={'fa fa-' + extension} style={styles.buttonIcon} /> {archivos[i].nombre + archivos[i].extension} 
                </button>
            );
        }

        return botones_archivos;
    }

    const onChangeFiles = () => {
        setTaskNoteData({
            ...taskNoteData,
            archivos: files_ref.current.files
        });
    }

    const handleAgregarArchivosTask = () => {
        files_ref.current.click();
    }

    const handleNoteTask = () => {
        if(taskNoteData.nota !== null && taskNoteData.nota !== ''){

            let formData = new FormData();
            formData.append('id', taskNoteData.id);
            formData.append('nota', taskNoteData.nota);

            if(taskNoteData.archivos !== null){
                for (var i = 0; i < taskNoteData.archivos.length; i++) {
                    formData.append('archivos', taskNoteData.archivos[i]);
                }
            }

            dispatch(notaTarea(formData, scrollBottom));

            setTaskNoteData({
                ...taskNoteData,
                nota: null,
                archivos: null
            });
        }else{
            dispatch(setAlert('Add text to the note', 'danger'));
        }
    }

    const handleIconoArchivo = async (datos) => {
        let {id_nota, id_archivo} = datos;

        await setFlag(true);
        await dispatch(detallesArchivo(taskDetailsData.id, id_nota, id_archivo));
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    //Notas de tarea
    const notasRow = (items) => {
        if(items.length > 0){

            let notas = [];

            items.map((nota, key_nota) => {
                if('tipo_status' in nota){
                    notas.push(
                        <div id={'nota_' + key_nota} className="col-md-12 card contenedor_nota_individual px-0" key={'nota_' + key_nota}>
                            <div className="card-body p-2 px-3">
                                <h5 className="card-title">{nota.creador}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{nota.fecha_creacion !== null ? new Date(nota.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(nota.fecha_creacion).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</h6>
                                <p>Change the status to <b>{nota.nombre_status}</b></p>
                            </div>
                        </div>
                    );
                }else{
                    notas.push(
                        <div id={'nota_' + key_nota} className="col-md-12 card contenedor_nota_individual px-0" key={'nota_' + key_nota}>
                            <div className="card-body py-2 px-3">
                                <h5 className="card-title">{nota.creador}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{nota.fecha_creacion !== null ? new Date(nota.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(nota.fecha_creacion).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</h6>
                                <p>{nota.nota}</p>
                                {
                                    nota.archivos.length > 0 ?
                                        <p>{botonesArchivos(nota._id, nota.archivos)}</p>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    );
                }
                
            });

            return notas;
        }else{
            return (
                <div className="col-md-10 offset-md-1 text-center">
                    <h4 className="mensaje_contenedor_notas">There are no notes or comments yet.</h4>
                </div>
            );
        }
    }

    //Calendario
    const handleOnEventResize = (data) => {
        console.log(data);
    }

    const handleOnNavigate = (data) => {
        setDateActual(data);
    }

    const handleOnView = (data) => {
        setViewActual(data);
    }

    const handleOnSelectEvent = useCallback((data) => {
        window.clearTimeout(clickRef?.current);

        if(data.tipo === 1 || data.tipo === '1' || data.tipo === 2 || data.tipo === '2'){
            handleOpenDetailsReminder(data.recordatorio, data.tipo);
        }else{
            dispatch(detallesTarea(data.tarea?._id));
        }
    }, []);
    
    const handleOnEventDrop = (data) => {
        console.log(data);
    }

    //Filtros
    const handleFiltersModal = () => {
        setFiltersModal(true);
    }

    const handleSelectFilterTask = (option) => {
        setProjectFilterInformationData(option);
        setFiltersData({
            ...filtersData,
            id_proyecto: option !== null ? option.value : null
        });
    }

    const handleFilter = () => {
        dispatch(buscarFechas(filtersData.id_proyecto));
        dispatch(buscarTareas({
            id_proyecto: filtersData.id_proyecto,
            id_seccion: null,
            prioridad: null,
            status: null,
            id_responsable: user?._id || null,
            ids_colaboradores: [user?._id],
            fecha_inicial: filtersData.fecha_inicial,
            fecha_final: filtersData.fecha_final
        }));

        setFiltersModal(false);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    //Otros
    const handleNewTask = () => {
        dispatch(buscarSecciones());
        dispatch(buscarUsuarios());
        dispatch(listarProyectos());

        dispatch(externaTarea(true));
    }

    const handleRecordatorios = () => {
        history.push('/reminders/' + (id || ''));
    }

    const handleTodolist = () => {
        history.push('/todolist/listado/' + (id || ''));
    }

    const handleGoBack = () => {
        if(id){
            history.push('/projects/buySale/' + (id || ''));
        }else{
            history.goBack();
        }
    }

    useEffect(() => {
        dispatch(listarProyectos());

        return () => {
            dispatch(buscarFechas(''));
            dispatch(buscarTareas(null));
            dispatch(detallesTarea(null));
            window.clearTimeout(clickRef?.current);
        }
    }, []);

    useEffect(() => {
        let proyecto_elegido = null;

        if(proyectosFilter.length > 0 && id){
            proyecto_elegido = proyectosFilter?.find(proyecto => String(proyecto.value) === String(id));
        }
        
        setProjectFilterInformationData(proyecto_elegido);

        setFiltersData({
            ...filtersData,
            id_proyecto: id
        });
    }, [id, proyectosFilter]);

    useEffect(() => {        
        if(user){
            dispatch(seleccionarProyecto(id));
            dispatch(buscarFechas(id || null));
            dispatch(buscarTareas({
                id_proyecto: id || null,
                id_seccion: null,
                prioridad: null,
                status: null,
                id_responsable: user?._id || null,
                ids_colaboradores: user?._id ? [user?._id] : [],
                fecha_inicial: null,
                fecha_final: null
            }));
        }
    }, [id, user]);

    useEffect(() => {
        if(detalles_tarea !== null){
            handleOpenDetailsReminder(detalles_tarea, 3);
        }
    }, [detalles_tarea]);

    useEffect(() => {
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    useEffect(() => {
        let eventos = [];

        for (var i = 0; i < recordatorios?.length; i++) {
            eventos.push({
                recordatorio: recordatorios[i],
                tipo: recordatorios[i].tipo,
                start: moment(recordatorios[i].fecha_inicio).toDate(),
                end: moment(recordatorios[i].fecha_fin).toDate(),
                title: recordatorios[i].mensaje,
            });
        }

        let eventos_actuales = eventosCalendario.filter( evento => evento.tipo !== 1 && evento.tipo !== 2 && evento.tipo !== '1' && evento.tipo !== '2' );

        setEventosCalendario([...eventos_actuales, ...eventos]);
    }, [recordatorios]);

    useEffect(() => {
        let eventos = [];

        for (var i = 0; i < secciones?.length; i++) {
            for (var x = 0; x < secciones[i]?.tareas?.length; x++) {
                eventos.push({
                    tarea: secciones[i]?.tareas[x],
                    tipo: 3,
                    start: moment(secciones[i]?.tareas[x]?.fecha_entrega).toDate(),
                    end: moment(secciones[i]?.tareas[x]?.fecha_entrega).toDate(),
                    title: secciones[i]?.tareas[x]?.nombre
                });
            }
        }

        let eventos_actuales = eventosCalendario.filter( evento => evento.tipo !== 3 && evento.tipo !== '3' );

        setEventosCalendario([...eventos_actuales, ...eventos]);
    }, [secciones]);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < listado.length; i++) {
            options.push({ value: listado[i]._id, label: listado[i].direccion });
        }

        setProyectosFilter(options);

    }, [listado]);

    return (
        <Fragment>
            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}
            
            {/* Modal de detalles de reminder */}
            <Modal show={detailsReminderModal} size="lg" onHide={() => {
                handleCloseDetailsGasto(1);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Details of the reminder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">Reminder:</label>
                            <label>{reminderDetailsData.contenido}</label>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">Start date:</label>
                            <label>{reminderDetailsData.fecha_inicio !== null ? new Date(reminderDetailsData.fecha_inicio).toLocaleDateString('en-US') + ' at ' + new Date(reminderDetailsData.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</label>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">End date:</label>
                            <label>{reminderDetailsData.fecha_fin !== null ? new Date(reminderDetailsData.fecha_fin).toLocaleDateString('en-US') + ' at ' + new Date(reminderDetailsData.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseDetailsGasto(1)}><i className="fas fa-times fa-sm"></i> Close</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de detalles de reminder manual */}
            <Modal show={detailsReminderManualModal} size="lg" onHide={() => {
                handleCloseDetailsGasto(2);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Details of the reminder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">Reminder:</label>
                            <label>{reminderDetailsData.contenido}</label>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">Start date:</label>
                            <label>{reminderDetailsData.fecha_inicio !== null ? new Date(reminderDetailsData.fecha_inicio).toLocaleDateString('en-US') + ' at ' + new Date(reminderDetailsData.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</label>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold mr-2">End date:</label>
                            <label>{reminderDetailsData.fecha_fin !== null ? new Date(reminderDetailsData.fecha_fin).toLocaleDateString('en-US') + ' at ' + new Date(reminderDetailsData.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseDetailsGasto(2)}><i className="fas fa-times fa-sm"></i> Close</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de detalles de task */}
            <Modal backdrop="static" show={detailsTaskModal} size="xl" dialogClassName="full_height" onHide={() => {
                handleCloseDetailsGasto(3);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Details and notes of task ({taskDetailsData.nombre_seccion})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <h4>{taskDetailsData.nombre || 'Task without name'}</h4>
                            <p>{taskDetailsData.descripcion}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Deadline:</label>
                            <p className={((Math.floor((new Date(taskDetailsData.fecha_entrega) - hoy)/86400000)) <= 3 ) ? "todo-deadline-warning" : ""}>{taskDetailsData.fecha_entrega !== null ? new Date(taskDetailsData.fecha_entrega).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Assigned to:</label>
                            <p>{taskDetailsData.nombre_responsable !== null ? taskDetailsData.nombre_responsable : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Created by:</label>
                            <p>{taskDetailsData.nombre_creador !== null ? taskDetailsData.nombre_creador : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Priority:</label>
                            <p>{prioridadTask(taskDetailsData.tipo_prioridad)}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Status:</label>
                            <p>{statusTask(taskDetailsData.status[taskDetailsData.status.length - 1]?.tipo_status)}</p>
                        </div>

                        <div className="col-md-4 form-group mb-0">
                            <label className="font-weight-bold">Collaboratos:</label>
                            <p>{taskDetailsData.colaboradores?.length > 0 ? taskDetailsData.colaboradores?.join(', ') : 'Not defined'}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <p>{taskDetailsData.nombre_proyecto !== null ? taskDetailsData.nombre_proyecto : 'Not defined'}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Add note:</label>
                            <div className="input-group">
                                <textarea className="form-control" name="nota" value={taskNoteData.nota || ''} onChange={(e) => {setTaskNoteData({...taskNoteData, nota: e.target.value})}}></textarea>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => handleAgregarArchivosTask()}><i className="fas fa-file-medical fa-sm"></i> {taskNoteData.archivos !== null ? 'Change' : 'Add'} files</button>
                                    <input type="file" className="d-none" name="files" id="files" ref={files_ref} onChange={onChangeFiles} multiple/>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-success" type="button" onClick={() => handleNoteTask()}><i className="fas fa-solid fa-check fa-sm"></i> Submit</button>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div className="row contenedor_notas px-3" id="contenedor_notas">
                        {
                            taskDetailsData.notas !== undefined ?
                                notasRow(detalles_tarea?.notas || [])
                            :
                                <div className="col-md-10 offset-md-1 text-center">
                                    <h4 className="mensaje_contenedor_notas">There are no notes or comments yet.</h4>
                                </div>
                        }
                        <div ref={myRef} />
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal de filtros */}
            <Modal backdrop="static" show={filtersModal} size="lg" onHide={() => {
                handleCloseFiltersModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <Select name="id_proyecto" isClearable={true} options={proyectosFilter} value={projectFilterInformationData} onChange={(value) => handleSelectFilterTask(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>                     

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Start date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_inicial" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_inicial !== null ? new Date(filtersData.fecha_inicial) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_inicial: date }) } />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">End date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_final" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_final !== null ? new Date(filtersData.fecha_final) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_final: date }) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseFiltersModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={() => handleFilter()}><i className="fas fa-solid fa-search fa-sm"></i> Find</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">

                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-6 text-left">
                        <h3>Schedule</h3>
                        <h6 className="mb-1">{projectFilterInformationData ? projectFilterInformationData.label : 'No project selected'}</h6>
                    </div>
                    
                    <div className="col-6 form-group text-right px-0">
                        {
                            !permisos_todolist?.filters ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleFiltersModal()}><i className="fas fa-solid fa-filter fa-sm"></i> Filters</button>
                            
                        }
                        {
                            !permisos_reminders?.view ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleRecordatorios()}><i className="fas fa-bell fa-sm"></i> View reminders</button>
                        }    
                        {
                            !permisos_todolist?.view ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleTodolist()}><i className="fas fa-check-square fa-sm"></i> View to do list</button>
                        }
                        {
                            !permisos_todolist?.new_task ?
                                null
                            :
                                <button type="button" className="btn btn-success mt-2 mr-2" onClick={() => handleNewTask()}><i className="fas fa-plus fa-sm"></i> New task</button>   
                        }
                        <button type="button" className="btn btn-secondary mt-2" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                    </div>

                    <hr/>                    
                </div>

                <div className="col-md-12 mt-3 pb-4">
                    <DnDCalendar
                        defaultDate={moment().toDate()}
                        defaultView="month"
                        events={eventosCalendario}
                        localizer={localizer}
                        onSelectEvent={handleOnSelectEvent}
                        onEventDrop={handleOnEventDrop}
                        onEventResize={handleOnEventResize}
                        onNavigate={handleOnNavigate}
                        onView={handleOnView}
                        date={dateActual}
                        view={viewActual}
                        resizable
                        selectable
                        style={{ height: "100vh" }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default Agenda;