import React, { Fragment, useState, useEffect, createRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import CurrencyInput from 'react-currency-input-field';
import 'react-image-lightbox/style.css';

//Actions
import { detallesCompletosNomina, editarOtroGastoNomina, eliminarOtroGastoNomina, editarBasuraNomina, eliminarBasuraNomina, editarGasolinaNomina, eliminarGasolinaNomina } from '../../actions/nominas';
import { listarProyectos } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const styles = {
    titulo: { color: '#2C2052' },
    infoText: {
        margin: 0,
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const DetallesNomina = ({history}) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const nominas = useSelector(state => state.nominas);
    const proyectos = useSelector(state => state.proyectos);

    const [datosGasto, setDatosGasto] = useState({
        _id: null,
        id: null,
        id_proyecto: null,
        id_usuario: null,
        fecha: null,
        tipo: 0,
        monto: null,
        observaciones: null,
        file: null,
        extension: null
    });

    const [datosEliminarGasto, setDatosEliminarGasto] = useState({
        _id: null,
        id: null,
        tipo: 0
    });

    const [gastoModal, setGastoModal] = useState(false);
    const [gastoEliminarModal, setGastoEliminarModal] = useState(false);
    const [botonGasto, setBotonGasto] = useState(false);
    const [botonGastoEliminar, setBotonGastoEliminar] = useState(false);

    let gasto_ref = createRef();

    const [dataTabla, setDataTabla] = useState({
        salariosTabla: null,
        proyectosSalariosTabla: null,
        proyectosTabla: null,
        proyectosSelect: null,
        gasolinaTabla: null,
        basuraTabla: null,
        otroTabla: null
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [detallesArchivo, setDetallesArchivo] = useState({
        extension: null,
        url: null
    });

    const { salariosTabla, proyectosSalariosTabla, proyectosTabla, proyectosSelect, gasolinaTabla, basuraTabla, otroTabla } = dataTabla;

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    
    const columnsSalaries = [
        {
            name: 'Employee',
            selector: 'empleado',
            sortable: true,
            cell: item => `${ item.empleado }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Salarie per day',
            selector: 'salario',
            sortable: true,
            cell: item => `${item.salario !== null ? numberToDecimal(item.salario) : '-'}`,
        }, {
            name: 'Total to pay',
            selector: 'total',
            sortable: true,
            cell: item => `${item.total !== null ? numberToDecimal(item.total) : '-'}`,
        }
    ];

    const columnsProjectsSalaries = [
        {
            name: 'Employee',
            selector: 'empleado',
            sortable: true,
            cell: item => `${ item.empleado }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Cost',
            selector: 'costo',
            sortable: true,
            cell: item => `${item.costo !== null ? numberToDecimal(item.costo) : '-'}`,
        }
    ];

    const columnsProjects = [
        {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Employees',
            selector: 'empleados',
            sortable: true,
            cell: item => `${ item.empleados }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Total cost',
            selector: 'costo',
            sortable: true,
            cell: item => `${item.costo !== null ? numberToDecimal(item.costo) : '-'}`,
        }
    ];

    const columnsGas = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }, {
            name: 'Options',
            sortable: false,
            cell: item => {
                return (
                    <Fragment>
                        <button className="btn" onClick={() => { handleEditarGastoNomina(item) }}><i className="fa fa-cogs" /></button>
                        <button className="btn" onClick={() => { handleEliminarGastoNomina(item) }}><i className="fa fa-trash-alt" /></button>
                    </Fragment>
                );
            }
        }
    ];

    const columnsDumps = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }, {
            name: 'Options',
            sortable: false,
            cell: item => {
                return (
                    <Fragment>
                        <button className="btn" onClick={() => { handleEditarGastoNomina(item) }}><i className="fa fa-cogs" /></button>
                        <button className="btn" onClick={() => { handleEliminarGastoNomina(item) }}><i className="fa fa-trash-alt" /></button>
                    </Fragment>
                );
            }
        }
    ];

    const columnsOthers = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }, {
            name: 'Options',
            sortable: false,
            cell: item => {
                return (
                    <Fragment>
                        <button className="btn" onClick={() => { handleEditarGastoNomina(item) }}><i className="fa fa-cogs" /></button>
                        <button className="btn" onClick={() => { handleEliminarGastoNomina(item) }}><i className="fa fa-trash-alt" /></button>
                    </Fragment>
                );
            }
        }
    ];

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    //Gasto
    const handleEditarGastoNomina = gasto => {
        setDatosGasto({
            ...datosGasto,
            id: gasto._id,
            id_proyecto: gasto.id_proyecto,
            id_usuario: gasto.id_usuario,
            fecha: gasto.fecha,
            tipo: gasto.tipo,
            monto: gasto.monto,
            observaciones: gasto.observaciones
        });
    }

    const handleEliminarGastoNomina = gasto => {
        setDatosEliminarGasto({
            ...datosEliminarGasto,
            id: gasto._id,
            tipo: gasto.tipo
        });
    }

    const handleOpenGastoNomina = () => {
        setGastoModal(true);
    }

    const handleOpenGastoEliminarNomina = () => {
        setGastoEliminarModal(true);
    }

    const handleCloseGastoNomina = () => {
        setDatosGasto({
            ...datosGasto,
            id: null,
            id_proyecto: null,
            id_usuario: null,
            fecha: null,
            tipo: 0,
            monto: null,
            observaciones: null,
            file: null,
            extension: null
        });
        setGastoModal(false);
    }

    const handleCloseGastoEliminarNomina = () => {
        setDatosEliminarGasto({
            ...datosEliminarGasto,
            id: null,
            tipo: 0
        });
        setGastoEliminarModal(false);
    }

    const onNumberChangeGasto = (name, value) => {
        setDatosGasto({
            ...datosGasto, [name]: value
        });
    };

    const onChangeGasto = ({ target }) => {
        setDatosGasto({
            ...datosGasto,
            [target.name]: String(target.value) === '(TBD) To be designed' ? null : target.value
        });
    }

    const onChangeFileGasto = () => {
        let nombre_separado = gasto_ref.current.files[0].name.split('.');

        setDatosGasto({
            ...datosGasto,
            file: gasto_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const gastoNomina = async () => {
        if(datosGasto.monto !== null && datosGasto.monto !== '' && datosGasto.monto > 0){
            await setBotonGasto(true);

            let formData = new FormData();
            formData.append('_id', datosGasto._id);
            formData.append('id', datosGasto.id);
            formData.append('id_proyecto', datosGasto.id_proyecto);
            formData.append('id_usuario', datosGasto.id_usuario);
            formData.append('fecha', datosGasto.fecha);
            formData.append('monto', datosGasto.monto);
            formData.append('observaciones', datosGasto.observaciones);
            formData.append('file', datosGasto.file);
            formData.append('extension', datosGasto.extension);

            if(datosGasto.tipo === 0 || datosGasto.tipo === '0'){
                await dispatch(editarOtroGastoNomina(formData));
            }

            if(datosGasto.tipo === 1 || datosGasto.tipo === '1'){
                await dispatch(editarBasuraNomina(formData));
            }

            if(datosGasto.tipo === 2 || datosGasto.tipo === '2'){
                await dispatch(editarGasolinaNomina(formData));
            }

            await handleCloseGastoNomina();

            await setBotonGasto(false);
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const gastoEliminarNomina = async () => {
        await setBotonGastoEliminar(true);

        if(datosEliminarGasto.tipo === 0 || datosEliminarGasto.tipo === '0'){
            await dispatch(eliminarOtroGastoNomina(datosEliminarGasto));
        }

        if(datosEliminarGasto.tipo === 1 || datosEliminarGasto.tipo === '1'){
            await dispatch(eliminarBasuraNomina(datosEliminarGasto));
        }

        if(datosEliminarGasto.tipo === 2 || datosEliminarGasto.tipo === '2'){
            await dispatch(eliminarGasolinaNomina(datosEliminarGasto));
        }

        await handleCloseGastoEliminarNomina();

        await setBotonGastoEliminar(false);
    }

    const handleSelectFile = (extension, url) => {
        setDetallesArchivo({
            ...detallesArchivo,
            extension,
            url
        });
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detallesArchivo.extension.toLowerCase()) !== -1){
            setImageData({ ...imageData, modal: true, src: detallesArchivo.url });
        }else{
            setDetallesArchivo({ ...detallesArchivo, extension: null, url: null });
            window.open(detallesArchivo.url);
        }
    }

    const handleViewFile = (file) => {
        if(file !== null){
            const url = URL.createObjectURL(file);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(detallesCompletosNomina(id));
        dispatch(listarProyectos());

        setDatosGasto({
            ...datosGasto,
            _id: id
        });

        setDatosEliminarGasto({
            ...datosEliminarGasto,
            _id: id
        });
    }, []);

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            salariosTabla: nominas.detalles?.empleados,
            proyectosSalariosTabla: nominas.detalles?.proyectos_salarios,
            proyectosTabla: nominas.detalles?.proyectos,
            gasolinaTabla: nominas.detalles?.gasolina,
            basuraTabla: nominas.detalles?.basura,
            otroTabla: nominas.detalles?.otro
        });
    }, [nominas]);

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosSelect: proyectos.listado
        });
    }, [proyectos]);

    useEffect(() => {
        if(datosGasto.id !== null){
            handleOpenGastoNomina();
        }
    }, [datosGasto]);

    useEffect(() => {
        if(datosEliminarGasto.id !== null){
            handleOpenGastoEliminarNomina();
        }
    }, [datosEliminarGasto]);

    useEffect(() => {
        if(detallesArchivo.extension !== null && detallesArchivo.url !== null){
            handleOpenFile();
        }
    }, [detallesArchivo]);

    return ( 
            <Fragment>

                {imageData.modal && (
                    <Lightbox
                        mainSrc={imageData.src}
                        onCloseRequest={() => { setDetallesArchivo({ ...detallesArchivo, extension: null, url: null }); setImageData({ ...imageData, modal: false }); } }
                    />
                )}

                {/* Modal de gastos */}
                <Modal show={gastoModal} onHide={() => {
                    handleCloseGastoNomina();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bills and expenses</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <select className="form-control" value={datosGasto.id_proyecto || null} name="id_proyecto" onChange={(e) => onChangeGasto(e)}>
                                    <option value={null}>(TBD) To be designed</option>
                                    {
                                        proyectosSelect?.length > 0 ?
                                            proyectosSelect?.map(proyecto => {
                                                return(
                                                    <option key={proyecto._id} value={ proyecto._id }>
                                                        { proyecto.direccion }
                                                    </option>
                                                )
                                            })
                                        : ''
                                    } 
                                </select>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Amount:</label>
                                <CurrencyInput
                                  name="monto"
                                  className="form-control text-center"
                                  value={datosGasto.monto || ''}
                                  decimalsLimit={2}
                                  groupSeparator=","
                                  decimalSeparator="."
                                  onValueChange={(value, name) => onNumberChangeGasto(name, value)}
                                />
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">File:</label>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input form-control" name="gastoFiles" id="gastoFiles" ref={gasto_ref} onChange={onChangeFileGasto} aria-describedby="view_gastoFiles"/>
                                        <label className="custom-file-label">{datosGasto.file ? 'File Selected' : 'Choose file'}</label>
                                    </div>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosGasto.file)}><i class="fas fa-eye"></i> View File</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Observations:</label>
                                <textarea className="form-control" value={datosGasto.observaciones || ''} name="observaciones" onChange={e => onChangeGasto(e)}></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseGastoNomina()} disabled={botonGasto}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={gastoNomina} disabled={botonGasto}><i className="fas fa-check fa-sm"></i> Save</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de eliminacion de gastos */}
                <Modal show={gastoEliminarModal} onHide={() => {
                    handleCloseGastoEliminarNomina();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete the bill</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <p>Do you want to delete this bill?</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseGastoEliminarNomina()} disabled={botonGastoEliminar}><i className="fas fa-times fa-sm"></i> No</button>
                        <button className="btn btn-success" onClick={gastoEliminarNomina} disabled={botonGastoEliminar}><i className="fas fa-check fa-sm"></i> Yes</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-8 text-left">
                            <h3>Payroll details</h3>
                        </div>
                        <div className="col-md-4 text-right">
                            <Link to="/payments" className="btn btn-secondary text-right">Go back</Link>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3 row">
                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Date</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{new Date(nominas.detalles?.fecha).toLocaleDateString('en-US')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Salaries</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(nominas.detalles?.total_salarios)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Dumps trips</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(nominas.detalles?.total_basura)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Gas bills</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(nominas.detalles?.total_gasolina)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Other bills</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(nominas.detalles?.total_otro)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Total</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(nominas.detalles?.total)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Employee payments</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsSalaries}
                                    data={salariosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Employee distribution</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsProjectsSalaries}
                                    data={proyectosSalariosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Project distribution</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsProjects}
                                    data={proyectosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Gas bills</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsGas}
                                    data={gasolinaTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Dumps trips</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsDumps}
                                    data={basuraTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Other bills</h6>
                        {
                            nominas.nominas !== undefined ?
                                <DataTable
                                    columns={columnsOthers}
                                    data={otroTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </Fragment>
    );
}
 
export default DetallesNomina;
