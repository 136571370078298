import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { v4 as uuid } from 'uuid';
import { ObjectID } from 'bson';
import CurrencyInput from 'react-currency-input-field';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../actions/alert';
import { detallesProyecto, masterlistProyecto, editarOferta, validarProyecto, activarOferta, desactivarOferta, buscarTiposProyectos, externoEliminar, externoNota, notesProyecto } from '../../actions/proyectos';
import { buscarBrokers } from '../../actions/brokers';
import { buscarBuyers, agregarBuyer } from '../../actions/buyers';
import { buscarSellers, agregarSeller } from '../../actions/sellers';
import { buscarFinancingTypes, agregarFinancingType } from '../../actions/financing_types';
import { buscarVestings, agregarVesting } from '../../actions/vestings';
import { buscarUsuarios } from '../../actions/usuarios';

const ContainerStyle = {
    width: '100%',
    height: '50vh'
};

const styles = {
    header_edificio: {
        color: '#ffffff',
        backgroundColor: '#343a40'
    },
    seccion_edificio: {
        backgroundColor: '#cdcdcd'
    }
}

const EditarOferta = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, masterlist, proyecto_coincidencia } = useSelector(state => state.proyectos);
    const tipos = useSelector(state => state.tipos);
    const brokers = useSelector(state => state.brokers);
    const buyers = useSelector(state => state.buyers);
    const sellers = useSelector(state => state.sellers);
    const financing_types = useSelector(state => state.financing_types);
    const vestings = useSelector(state => state.vestings);
    const usuarios = useSelector(state => state.usuarios);

    const [proyectoData, setProyectoData] = useState({
        id: null,
        tipo_proyecto: null,
        tipo_construccion: null,
        multi_unidades: null,
        tiene_edificios: null,
        direccion: null,
        latitud: null,
        longitud: null,
        multi_apn: false,
        apn: [''],
        terreno: [''],
        terreno_unidad: [0],
        direcciones: [''],
        construccion_general: '',
        unidades_general: 1,
        offer_price: null,
        asking_price: null,
        listing_id: null,
        id_tipo_financiamiento: null,
        down_payment: null,
        tipo_down_payment: 0,
        edificios: [
            [
                {
                    nombre: '',
                    nivel: 0,
                    habitaciones: 0,
                    sanitarios: 0,
                    construccion: '',
                    cochera: [0],
                    tipo_cochera: [0]
                }
            ]
        ],
        ano_construccion: [0],
        niveles: [0],

        url_offer_file: null,
        url_offer_image: null,
        informacion_detallada: false,
        informacion_detallada_explicacion: '',

        id_buyer_company: null,

        propietario_vende: false,
        id_seller_owner: null,

        compensaciones: [],

        forma_1099: false,

        propiedad_asignada: false,
        id_vesting: [],
        percent_vesting: [],

        id_usuario_responsable: null,
        
        id_broker_buyer: null,
        id_broker_agent_buyer: null,
        broker_represent: false,
        id_broker_seller: null,
        id_broker_agent_seller: null,

        avanzar: false
    });

    const [offerFile, setOfferFile] = useState({
        file: undefined,
        archivo: ''
    });

    const [screenshotFile, setScreenshotFile] = useState({
        file: undefined,
        url: ''
    });

    const [coincidenciaProyecto, setCoincidenciaProyecto] = useState({
        direccion: '',
        proyecto: '',
        construccion: ''
    });

    const [isBackButtonClicked, setBackbuttonPress] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [copiarBroker, setCopiarBroker] = useState(null);
    const [sectionInformation, setSectionInformation] = useState(2);
    const [contador, setContador] = useState(1);
    const [copiarUnidades, setCopiarUnidades] = useState({
        Bed: false,
        Bath: false,
        SQFT: false,
        Garage: false,
    });
    const [coord, setCoord] = useState({
        lat: 38.9071923,
        lng: -77.0368707
    });

    const [buyerCompanyModal, setBuyerCompanyModal] = useState(false);
    const [sellerOwnerModal, setSellerOwnerModal] = useState(false);
    const [financingTypeModal, setFinancingTypeModal] = useState(false);
    const [vestingModal, setVestingModal] = useState(false);
    const [coincidenciaModal, setCoincidenciaModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [screenshotModal, setScreenshotModal] = useState(false);
    const [viewScreenshotModal, setViewScreenshotModal] = useState(false);

    let fileOffer = createRef();

    /* Broker data */
    const [brokerBuyerSelectData, setBrokerBuyerSelectData] = useState(null);
    const [brokerAgentBuyerSelectData, setBrokerAgentBuyerSelectData] = useState(null);

    const [brokerSellerSelectData, setBrokerSellerSelectData] = useState(null);
    const [brokerAgentSellerSelectData, setBrokerAgentSellerSelectData] = useState(null);

    const [addBrokerBuyerData, setAddBrokerBuyerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentBuyerData, setAddBrokerAgentBuyerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addBrokerSellerData, setAddBrokerSellerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentSellerData, setAddBrokerAgentSellerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerBuyerData, setViewBrokerBuyerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerSellerData, setViewBrokerSellerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [brokersFilter, setBrokersFilter] = useState([]);
    const [brokersAgentsBuyerFilter, setBrokersAgentsBuyerFilter] = useState([]);
    const [brokersAgentsSellerFilter, setBrokersAgentsSellerFilter] = useState([]);

    /* Buyer company data */
    const [buyerCompanySelectData, setBuyerCompanySelectData] = useState(null);

    const [buyersFilter, setBuyersFilter] = useState([]);

    const [addBuyerCompanyData, setAddBuyerCompanyData] = useState({
        _id: null,
        nombre: ''
    });

    const [viewBuyerCompanyData, setViewBuyerCompanyData] = useState({
        nombre: ''
    });

    /* Seller data */
    const [sellerOwnerSelectData, setSellerOwnerSelectData] = useState(null);

    const [sellersFilter, setSellersFilter] = useState([]);

    const [addSellerOwnerData, setAddSellerOwnerData] = useState({
        _id: null,
        nombre: '',
        direccion: '',
        email: '',
        celular: '',
        telefono: ''
    });

    const [viewSellerOwnerData, setViewSellerOwnerData] = useState({
        nombre: '',
        direccion: '',
        email: '',
        celular: '',
        telefono: ''
    });

    /* Vesting data */
    const [vestingSelectData, setVestingSelectData] = useState([null]);

    const [vestingFilter, setVestingFilter] = useState([]);

    const [addVestingData, setAddVestingData] = useState({
        _id: null,
        nombre: '',
        direccion: '',
        representante: '',
        email: '',
        celular: '',
        telefono: ''
    });

    const [viewVestingData, setViewVestingData] = useState([{
        nombre: '',
        direccion: '',
        email: '',
        representante: '',
        celular: '',
        telefono: ''
    }]);

    /* Type of financing */
    const [financingTypeSelectData, setFinancingTypeSelectData] = useState(null);

    const [financingTypesFilter, setFinancingTypesFilter] = useState([]);

    const [addFinancingTypeData, setAddFinancingTypeData] = useState({
        _id: null,
        nombre: '',
        down_payment: false
    });

    const [viewFinancingTypeData, setViewFinancingTypeData] = useState({
        nombre: '',
        down_payment: false
    });

    /* User Assigned */
    const [userResponsableSelectData, setUserResponsableSelectData] = useState(null);

    const [usersFilter, setUsersFilter] = useState([]);

    /* APN funciones */
    const handleAddApn = async () => {
        let apns = proyectoData.apn;
        let lotes = proyectoData.terreno;
        let unidades = proyectoData.terreno_unidad;

        apns.push('');
        lotes.push('');
        unidades.push(0);

        setProyectoData({ 
            ...proyectoData, 
            apn: apns,
            terreno: lotes,
            terreno_unidad: unidades
        });
    };

    const onChangeAPN = ({ target }) => {

        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let apns = proyectoData.apn;

        apns[posicion] = target.value || '';

        setProyectoData({
            ...proyectoData,
            apn: apns
        });
    };

    const onNumberChangeLot = (value, name) => {

        let nombre_separado = name.split('_');

        let posicion = nombre_separado[1];
        
        let terrenos = proyectoData.terreno;

        terrenos[posicion] = value || '';

        setProyectoData({
            ...proyectoData,
            terreno: terrenos
        });
    };

    const onChangeAddressApn = ({ target }) => {

        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let direcciones = proyectoData.direcciones;

        direcciones[posicion] = target.value || '';

        setProyectoData({
            ...proyectoData,
            direcciones
        });
    };

    const onChangeUnitsLot = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[2];

        let unidades = proyectoData.terreno_unidad;

        unidades[posicion] = target.value;

        setProyectoData({ 
            ...proyectoData,
            [target.name]: unidades
        });
    };

    const handleDeleteApn = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');    

        let apns = proyectoData.apn;
        let lotes = proyectoData.terreno;
        let unidades = proyectoData.terreno_unidad;

        apns.splice(id_separado[1], 1);
        lotes.splice(id_separado[1], 1);
        unidades.splice(id_separado[1], 1);

        setProyectoData({ 
            ...proyectoData, 
            apn: apns,
            terreno: lotes,
            terreno_unidad: unidades
        });
    };

    const apnsRows = (apns, lotes, unidades, direcciones) => {

        let apns_lots = [];

        for (var x = 0; x < apns?.length; x++) {
            apns_lots.push(
                <div className="col-md-6 row">
                    <div className="col-md-3 form-group campo_numero_xl">
                        <label className="font-weight-bold">APN:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button className="btn btn-danger" type="button" id={'apnLote_' + x} onClick={e => handleDeleteApn(e)}>Delete</button>
                            </div>
                            <input className="form-control" type="text" name={"apn_" + x} onChange={ e => onChangeAPN(e) } value={apns[x] || ''}  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)){ event.preventDefault(); }}} disabled={formDisabled ? true : false} />
                        </div>
                    </div>

                    <div className="col-md-3 form-group campo_numero_lg">
                        <label className="font-weight-bold">LOT:</label>
                        <div className="input-group">
                            <CurrencyInput
                                name={'terreno_' + x}
                                className="form-control text-center"
                                value={lotes[x] || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeLot(value, name)}
                                disabled={formDisabled ? true : false}
                            />
                            <div className="input-group-append">
                                <select className="browser-default custom-select" name={"terreno_unidad_" + x} value={unidades[x]} onChange={ e => onChangeUnitsLot(e) }>
                                    <option value="0">FT</option>
                                    <option value="1">AC</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Address:</label>
                        <input className="form-control" type="text" name={'direccion_' + x} onChange={ e => onChangeAddressApn(e) } value={direcciones[x] || ''} disabled={formDisabled ? true : false} />
                    </div>
                </div>                
            );
        }

        return apns_lots;
    };

    /* Broker functions */
    const onChangeSelectSearch = async (option, origin, name_origin) => {
        console.log(option);
        if(option !== null){
            let {value, label} = option;

            switch(origin){

                case 'id_broker_seller':
                    await setBrokerSellerSelectData({value, label});
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData({value, label});
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData({value, label});
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData({value, label});
                    break;

                case 'id_buyer_company':
                    await setBuyerCompanySelectData({value, label});
                    break;

                case 'id_seller_owner':
                    await setSellerOwnerSelectData({value, label});
                    break;

                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(option);
                    break;

                case 'id_tipo_financiamiento':
                    await setFinancingTypeSelectData({value, label});
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value
                });
            }            
        }else{
            switch(origin){

                case 'id_broker_seller':
                    await setBrokerSellerSelectData(null);
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData(null);
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData(null);
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData(null);
                    break;

                case 'id_buyer_company':
                    await setBuyerCompanySelectData(null);
                    break;

                case 'id_seller_owner':
                    await setSellerOwnerSelectData(null);
                    break;

                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(null);
                    break;

                case 'id_tipo_financiamiento':
                    await setFinancingTypeSelectData(null);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null
                });
            }
        }
    };

    const handleRadiobuttonCopy = ({target}) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setCopiarBroker(true);
            }else{
                setCopiarBroker(false);
                setProyectoData({
                    ...proyectoData,
                    id_broker_buyer: null,
                    id_broker_agent_buyer: null,
                    broker_represent: false
                });
        
                setBrokerBuyerSelectData(null);
                setBrokerAgentBuyerSelectData(null);
            }
        }
    };

    const handleCopyBroker = () => {
        setProyectoData({
            ...proyectoData,
            id_broker_buyer: proyectoData.id_broker_seller,
            id_broker_agent_buyer: proyectoData.id_broker_agent_seller,
            broker_represent: true
        });

        setBrokerBuyerSelectData(brokerSellerSelectData);
        setBrokerAgentBuyerSelectData(brokerAgentSellerSelectData);
        
        dispatch(setAlert('Copied information', 'success'));
        setSectionInformation(2);
    };

    const handleViewBrokerBuyer = async () => {

        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_buyer !== null){
            let broker_buyer_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_buyer));
            if(broker_buyer_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_buyer_elegido[0].compania,
                    address: broker_buyer_elegido[0].direccion || '',
                    agents: broker_buyer_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_buyer_elegido[0]._id,
                    label: broker_buyer_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (let i = 0; i < broker_buyer_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_buyer_elegido[0].agentes[i]._id, label: broker_buyer_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_buyer !== null){
                    let broker_agent_elegido = await broker_buyer_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_buyer));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerBuyerData(datos_broker_view);
        await setBrokerBuyerSelectData(datos_broker_select);
        await setBrokersAgentsBuyerFilter(datos_broker_filter);
        await setBrokerAgentBuyerSelectData(datos_broker_agent_select);
    };

    const handleViewBrokerSeller = async () => {
        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_seller !== null){
            let broker_seller_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_seller));
            if(broker_seller_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_seller_elegido[0].compania,
                    address: broker_seller_elegido[0].direccion || '',
                    agents: broker_seller_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_seller_elegido[0]._id,
                    label: broker_seller_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (let i = 0; i < broker_seller_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_seller_elegido[0].agentes[i]._id, label: broker_seller_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_seller !== null){
                    let broker_agent_elegido = await broker_seller_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_seller));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerSellerData(datos_broker_view);
        await setBrokerSellerSelectData(datos_broker_select);
        await setBrokersAgentsSellerFilter(datos_broker_filter);
        await setBrokerAgentSellerSelectData(datos_broker_agent_select);
    };

    /* Seller functions */
    const handleSellerOwner = () => {
        setSellerOwnerModal(true);

        let id_seller = new ObjectID().toString();

        setAddSellerOwnerData({
            ...addSellerOwnerData,
            _id: id_seller
        });
    };

    const handleInputsAddSellerOwner = ({ target }) => {
        setAddSellerOwnerData({
            ...addSellerOwnerData,
            [target.name]: target.value
        });
    };

    const handleCloseModalSellerOwner = () => {
        setSellerOwnerModal(false);
    };

    const handleAddSellerOwner = async (e) => {
        e.preventDefault();
        if(addSellerOwnerData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarSeller(addSellerOwnerData));

            await setProyectoData({
                ...proyectoData,
                id_seller_owner: addSellerOwnerData._id
            });

            await setSellerOwnerSelectData({
                value: addSellerOwnerData._id,
                label: addSellerOwnerData.nombre
            });

            await setAddSellerOwnerData({
                ...addSellerOwnerData,
                _id: null,
                nombre: '',
                direccion: '',
                email: '',
                celular: '',
                telefono: ''
            });

            await setSellerOwnerModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    };

    const handleViewSellerOwner = async () => {
        let datos_seller_view = {
            nombre: '',
            direccion: '',
            email: '',
            celular: '',
            telefono: '',
        }

        let datos_seller_select = null;

        if(proyectoData.id_seller_owner !== null){
            let seller_owner_elegido = await sellers.sellers?.filter(broker => String(broker._id) === String(proyectoData.id_seller_owner));
            if(seller_owner_elegido.length > 0){
                datos_seller_view = {
                    ...datos_seller_view,
                    nombre: seller_owner_elegido[0].nombre,
                    direccion: seller_owner_elegido[0].direccion || '',
                    email: seller_owner_elegido[0].email || '',
                    telefono: seller_owner_elegido[0].telefono || '',
                    celular: seller_owner_elegido[0].celular || ''
                };

                datos_seller_select = {
                    value: seller_owner_elegido[0]._id,
                    label: seller_owner_elegido[0].nombre
                };
            }
        }

        await setViewSellerOwnerData(datos_seller_view);
        await setSellerOwnerSelectData(datos_seller_select);
    };

    /* Buyer company functions */
    const handleBuyerCompany = () => {
        setBuyerCompanyModal(true);

        let id_buyer_company = new ObjectID().toString();

        setAddBuyerCompanyData({
            ...addBuyerCompanyData,
            _id: id_buyer_company
        });
    };

    const handleInputsAddBuyerCompany = ({ target }) => {
        setAddBuyerCompanyData({
            ...addBuyerCompanyData,
            [target.name]: target.value
        });
    };

    const handleCloseModalBuyerCompany = () => {
        setBuyerCompanyModal(false);
    };

    const handleAddBuyerCompany = async (e) => {
        e.preventDefault();
        if(addBuyerCompanyData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarBuyer(addBuyerCompanyData));

            await setProyectoData({
                ...proyectoData,
                id_buyer_company: addBuyerCompanyData._id
            });

            await setBuyerCompanySelectData({
                value: addBuyerCompanyData._id,
                label: addBuyerCompanyData.nombre
            });

            await setAddBuyerCompanyData({
                ...addBuyerCompanyData,
                _id: null,
                nombre: ''
            });

            await setBuyerCompanyModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    };

    const handleViewBuyerCompany = async () => {
        let datos_buyer_view = {
            nombre: ''
        }

        let datos_buyer_select = null;

        if(proyectoData.id_buyer_company !== null){

            let buyer_company_elegido = await buyers.buyers?.filter(buyer => String(buyer._id) === String(proyectoData.id_buyer_company));

            if(buyer_company_elegido.length > 0){
                datos_buyer_view = {
                    ...datos_buyer_view,
                    nombre: buyer_company_elegido[0].nombre
                };

                datos_buyer_select = {
                    value: buyer_company_elegido[0]._id,
                    label: buyer_company_elegido[0].nombre
                };
            }
        }

        await setViewBuyerCompanyData(datos_buyer_view);
        await setBuyerCompanySelectData(datos_buyer_select);
    };

    /* Financing types functions */
    const handleFinancingType = () => {
        setFinancingTypeModal(true);

        let id_financing_type = new ObjectID().toString();

        setAddFinancingTypeData({
            ...addFinancingTypeData,
            _id: id_financing_type
        });
    };

    const handleInputsAddFinancingType = ({ target }) => {
        setAddFinancingTypeData({
            ...addFinancingTypeData,
            [target.name]: target.value
        });
    };

    const onChangeRadioButtonFinancingType = ({ target }) => {      
        if(target.checked){
            setAddFinancingTypeData({
                ...addFinancingTypeData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            });
        }
    };

    const handleCloseModalFinancingType = () => {
        setFinancingTypeModal(false);

        setAddFinancingTypeData({
            _id: null,
            nombre: '',
            down_payment: false
        })
    };

    const handleAddFinancingType = async (e) => {
        e.preventDefault();
        if(addFinancingTypeData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarFinancingType(addFinancingTypeData));

            await setProyectoData({
                ...proyectoData,
                id_tipo_financiamiento: addFinancingTypeData._id
            });

            await setFinancingTypeSelectData({
                value: addFinancingTypeData._id,
                label: addFinancingTypeData.nombre,
                down_payment: addFinancingTypeData.down_payment
            });

            await setAddFinancingTypeData({
                ...addFinancingTypeData,
                _id: null,
                nombre: '',
                down_payment: false
            });

            await setFinancingTypeModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    };

    const handleViewFinancingType = async () => {
        let datos_financing_type_view = {
            nombre: '',
            down_payment: false
        }

        let datos_financing_type_select = null;

        if(proyectoData.id_tipo_financiamiento !== null){

            let financing_type_elegido = await financing_types.financing_types?.filter(financing_type => String(financing_type._id) === String(proyectoData.id_tipo_financiamiento));

            if(financing_type_elegido.length > 0){
                datos_financing_type_view = {
                    ...datos_financing_type_view,
                    nombre: financing_type_elegido[0].nombre,
                    down_payment: financing_type_elegido[0].down_payment
                };

                datos_financing_type_select = {
                    value: financing_type_elegido[0]._id,
                    label: financing_type_elegido[0].nombre,
                    down_payment: financing_type_elegido[0].down_payment
                };
            }
        }

        await setViewFinancingTypeData(datos_financing_type_view);
        await setFinancingTypeSelectData(datos_financing_type_select);
    };

    /* Vesting functions */
    const handleVesting = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');

        setVestingModal(true);

        let id_vesting = new ObjectID().toString();

        setAddVestingData({
            ...addVestingData,
            _id: id_vesting,
            posicion: id_separado[2]
        });
    };

    const handleInputsAddVesting = ({ target }) => {
        setAddVestingData({
            ...addVestingData,
            [target.name]: target.value
        });
    };

    const handleCloseModalVesting = () => {
        setVestingModal(false);
    };

    const handleCreateVesting = async (e) => {
        e.preventDefault();
        if(addVestingData.nombre.replace(/\s/g, '') !== ''){
            await dispatch(agregarVesting(addVestingData));

            let vestings_ids = proyectoData.id_vesting;
            let vestings_select = vestingSelectData;

            vestings_ids[addVestingData.posicion] = addVestingData._id;

            vestings_select[addVestingData.posicion] = {
                value: addVestingData._id,
                label: addVestingData.nombre
            };

            await setProyectoData({
                ...proyectoData,
                id_vesting: vestings_ids
            });

            await setVestingSelectData(vestings_select);

            await setAddVestingData({
                ...addVestingData,
                _id: null,
                posicion: null,
                nombre: '',
                direccion: '',
                representante: '',
                email: '',
                celular: '',
                telefono: ''
            });

            await setVestingModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    };

    const handleViewVesting = async () => {

        let datos_view = [];
        let datos_select = [];

        for (var x = 0; x < proyectoData.id_vesting?.length; x++) {
            let datos_buyer_view = {
                nombre: '',
                direccion: '',
                representante: '',
                email: '',
                celular: '',
                telefono: '',
            }

            let datos_buyer_select = null;

            if(proyectoData.id_vesting[x] !== null){

                let vesting_elegido = await vestings.vestings?.filter(buyer => String(buyer._id) === String(proyectoData.id_vesting[x]));

                if(vesting_elegido.length > 0){
                    datos_buyer_view = {
                        ...datos_buyer_view,
                        nombre: vesting_elegido[0].nombre,
                        direccion: vesting_elegido[0].direccion || '',
                        representante: vesting_elegido[0].representante || '',
                        email: vesting_elegido[0].email || '',
                        telefono: vesting_elegido[0].telefono || '',
                        celular: vesting_elegido[0].celular || ''
                    };

                    datos_buyer_select = {
                        value: vesting_elegido[0]._id,
                        label: vesting_elegido[0].nombre
                    };
                }
            }

            datos_view.push(datos_buyer_view);
            datos_select.push(datos_buyer_select);
        }

        await setViewVestingData(datos_view);
        await setVestingSelectData(datos_select);
    };

    const handleAddVesting = async () => {
        let vestings = proyectoData.id_vesting;
        let percents = proyectoData.percent_vesting;

        vestings.push(null);
        percents.push(null);

        setProyectoData({ 
            ...proyectoData, 
            id_vesting: vestings,
            percent_vesting: percents
        });
    };

    const onChangeVesting = async (option, action) => {

        let id_separado = action.name.split('_');
        let posicion = id_separado[2];

        if(option !== null){
            let {value, label} = option;

            let vestings_ids = proyectoData.id_vesting;
            let vestings_data = vestingSelectData;

            vestings_ids[posicion] = value;
            vestings_data[posicion] = {value, label};

            await setProyectoData({
                ...proyectoData,
                id_vesting: vestings_ids
            });

            await setVestingSelectData(vestings_data);
        }else{
            let vestings_ids = proyectoData.id_vesting;
            let vestings_data = vestingSelectData;

            vestings_ids[posicion] = null;
            vestings_data[posicion] = null;

            await setProyectoData({
                ...proyectoData,
                id_vesting: vestings_ids
            });

            await setVestingSelectData(vestings_data);
        }
    };

    const onChangePercent = async ({ target }) => {

        let id_separado = target.id.split('_');
        let posicion = id_separado[2];

        let vestings_percents = proyectoData.percent_vesting;

        vestings_percents[posicion] = target.value;

        await setProyectoData({
            ...proyectoData,
            percent_vesting: vestings_percents
        });
    };

    const handleDeleteVesting = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');    

        let vestings = proyectoData.id_vesting;
        let percents = proyectoData.percent_vesting;

        vestings.splice(id_separado[2], 1);
        percents.splice(id_separado[2], 1);

        setProyectoData({ 
            ...proyectoData, 
            id_vesting: vestings,
            percent_vesting: percents
        });
    };

    const vestingRows = (vestings) => {

        let vesting_rows = [];

        if(vestings?.length > 0){
            for (var x = 0; x < vestings?.length; x++) {
                vesting_rows.push(
                    <div className="col-md-12 row">
                        {/* Empresa */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="input-group">
                                <div className="input-group-preppend">
                                    <button id={'vesting_delete_' + x} className="btn btn-danger" type="button" onClick={e => handleDeleteVesting(e)}>Delete</button>
                                </div>
                                <Select
                                    id={'id_vesting_' + x}
                                    name={'id_vesting_' + x}
                                    className="select-group"
                                    aria-describedby={'vesting_create_' + x}
                                    isClearable={true}
                                    options={vestingFilter}
                                    value={vestingSelectData[x]}
                                    onChange={(value, action) => onChangeVesting(value, action)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                                <div className="input-group-append">
                                    <button id={'vesting_create_' + x} className="btn btn-secondary" type="button" onClick={e => handleVesting(e)}>Create</button>
                                </div>
                            </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Address:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" value={viewVestingData[x]?.direccion} disabled/>
                                </div>
                            </div>
                        </div>

                        {/* Representant */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Representant:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewVestingData[x]?.representante} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewVestingData[x]?.email} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Cell Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewVestingData[x]?.celular} disabled />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewVestingData[x]?.telefono} disabled />
                            </div>
                        </div>

                        {/* Percent */}
                        <div className="col-md-1 form-group">
                            <label className="font-weight-bold">Percent:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-percent"></i>
                                        </span>
                                    </div>
                                    <input id={'porcentaje_vensting_' + x}  className="form-control" type="number" value={proyectoData?.percent_vesting[x]} onChange={e => onChangePercent(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>                
                );
            }
        }else{
            vesting_rows.push(
                <div className="col-md-12 row">
                    {/* Empresa */}
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Company:</label>
                        <div className="input-group">
                            <div className="input-group-preppend">
                                <button id={'vesting_delete_0'} className="btn btn-danger" type="button" onClick={e => handleDeleteVesting(e)}>Delete</button>
                            </div>
                            <Select
                                id={'id_vesting_0'}
                                name={'id_vesting_0'}
                                className="select-group"
                                aria-describedby={'vesting_create_0'}
                                isClearable={true}
                                options={vestingFilter}
                                value={vestingSelectData[0]}
                                onChange={(value, action) => onChangeVesting(value, action)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                            <div className="input-group-append">
                                <button id={'vesting_create_0'} className="btn btn-secondary" type="button" onClick={e => handleVesting(e)}>Create</button>
                            </div>
                        </div>
                    </div>

                    {/* Address */}
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Address:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" value={viewVestingData[0]?.direccion} disabled/>
                            </div>
                        </div>
                    </div>

                    {/* Representant */}
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Representant:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <input className="form-control" type="text" value={viewVestingData[0]?.representante} disabled />
                            </div>
                        </div>
                    </div>

                    {/* Email */}
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Email:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                                <input className="form-control" type="text" value={viewVestingData[0]?.email} disabled />
                            </div>
                        </div>
                    </div>

                    {/* Cell Phone */}
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Cell phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput className="form-control" country={'us'} value={viewVestingData[0]?.celular} disabled />
                        </div>
                    </div>

                    {/* Phone */}
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput className="form-control" country={'us'} value={viewVestingData[0]?.telefono} disabled />
                        </div>
                    </div>

                    {/* Percent */}
                    <div className="col-md-1 form-group">
                        <label className="font-weight-bold">Percent:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-percent"></i>
                                    </span>
                                </div>
                                <input id={'porcentaje_vensting_0'} className="form-control" type="number" value={proyectoData?.percent_vesting[0]} onChange={e => onChangePercent(e)}/>
                            </div>
                        </div>
                    </div>
                </div>                
            );
        }

        return vesting_rows;
    };

    /* Funciones generales */
    const onChange = ({ target }) => {
        if(String(target.name) === 'unidades_general'){
            if(target.value >= contador){
                setProyectoData({ 
                    ...proyectoData, [target.name]: target.value
                });
            }else{
                dispatch(setAlert('Decrease units per building first', 'danger'));
            }
        }else{
            setProyectoData({ 
                ...proyectoData, [target.name]: target.value
            });
        }
    };

    const onChangeArray = ({ target }) => {
        let id_separado = target.id.split('_');

        let campo = '';
        let p_edificio = '';
        let p_unidad = '';

        let edificios = proyectoData.edificios;
        
        if(id_separado.length > 3){
            campo = id_separado[0] + '_' + id_separado[1];
            p_edificio = id_separado[2];
            p_unidad = id_separado[3];
        }else{
            campo = id_separado[0];
            p_edificio = id_separado[1];
            p_unidad = id_separado[2];
        }

        edificios[p_edificio][p_unidad][[campo]] = target.value;

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    };

    const onChangeSubArray = ({ target }) => {
        let id_separado = target.id.split('_');

        let campo = '';
        let p_edificio = '';
        let p_unidad = '';
        let p_cochera = '';

        let edificios = proyectoData.edificios;
        
        if(id_separado.length < 5){
            campo = id_separado[0];
            p_edificio = id_separado[1];
            p_unidad = id_separado[2];
            p_cochera = id_separado[3];
        }else{
            campo = id_separado[0] + '_' + id_separado[1];
            p_edificio = id_separado[2];
            p_unidad = id_separado[3];
            p_cochera = id_separado[4];
        }

        edificios[p_edificio][p_unidad][[campo]][p_cochera] = target.value;

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value || ''
        });
    };

    const onNumberChangeArray = (name, value) => {
        let id_name_separado = name.split('_');

        let campo = id_name_separado[0];
        let p_edificio = id_name_separado[1];
        let p_unidad = id_name_separado[2];

        let edificios = proyectoData.edificios;

        edificios[p_edificio][p_unidad][[campo]] = value;

        setProyectoData({
            ...proyectoData, 
            edificios
        });
    };

    const onChangeBuildings = ({ target }) => {
        if(!target.value){
            setProyectoData({ 
                ...proyectoData, [target.name]: []
            });

            setContador(0);
        }else{
            if(target.value > 0){
                let edificios_valores = [];

                for (let i = 1; i <= target.value; i++) {
                    let unidades = [{
                        nombre: '',
                        nivel: [0],
                        habitaciones: 0,
                        sanitarios: 0,
                        construccion: '',
                        cochera: [0],
                        tipo_cochera: [0]
                    }];

                    edificios_valores.push(unidades);
                }

                setContador(target.value);

                setProyectoData({ 
                    ...proyectoData,
                    unidades_general: parseInt(proyectoData.unidades_general) < parseInt(target.value) ? target.value : proyectoData.unidades_general,
                    [target.name]: edificios_valores
                });                
            }else{
                setProyectoData({ 
                    ...proyectoData, [target.name]: []
                });

                setContador(0);
            }
        }
    };

    const onChangeRadioButtonApn = ({ target }) => {

        let apns = proyectoData.apn;
        let terrenos = proyectoData.terreno;
        let terrenos_unidades = proyectoData.terreno_unidad;

        if(target.value === 1 || target.value === '1'){
            setSectionInformation(10);
        }else{

            if(proyectoData.propietario_vende === true){
                setSectionInformation(6);
            }else{
                setSectionInformation(2);
            }

            if(apns.length > 1){
                apns = [apns[0]];
                terrenos = [apns[0]];
                terrenos_unidades = [apns[0]];
            }
        }        

        if(target.checked){
            setProyectoData({
                ...proyectoData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false,
                apn: apns,
                terreno: terrenos,
                terreno_unidad: terrenos_unidades
            });
        }
    };

    const onChangeRadioButtonDetailed = ({ target }) => {
        if(target.checked){
            setProyectoData({
                ...proyectoData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false,
                informacion_detallada_explicacion: target.value === 1 || target.value === '1' ? proyectoData.informacion_detallada_explicacion : ''
            });
        }
    };

    const onChangeRadioButtonOwner = ({ target }) => {
        if(target.checked){

            setSectionInformation(6);

            setProyectoData({
                ...proyectoData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            });
        }
    };

    const onChangeRadioButtonCompensation = ({ target }) => {
        if(target.checked){

            if(target.value === 1 || target.value === '1'){
                setSectionInformation(8);

                setProyectoData({
                    ...proyectoData,
                    compensaciones: [{
                        nombre: null,
                        celular: null,
                        telefono: null,
                        email: null,
                        cantidad: null
                    }]
                });
            }else{
                if(proyectoData.propietario_vende === true){
                    setSectionInformation(6);
                }else{
                    setSectionInformation(2);
                }

                setProyectoData({
                    ...proyectoData,
                    compensaciones: []
                });
            }

            
        }
    };

    const onChangeRadioButtonFormat = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    forma_1099: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    forma_1099: false
                });
            }
        }
    };

    const onChangeCompensation = ({ target }) => {

        let campo_separado = target.name.split('_');

        let compensaciones = proyectoData.compensaciones;

        if(campo_separado.length > 2){
            compensaciones[campo_separado[2]] = {
                ...compensaciones[campo_separado[2]],
                [campo_separado[0] + '_' + campo_separado[1]]: target.value
            };
        }else{
            compensaciones[campo_separado[1]] = {
                ...compensaciones[campo_separado[1]],
                [campo_separado[0]]: target.value
            };
        }

        setProyectoData({
            ...proyectoData,
            compensaciones
        });
    };

    const onNumberChangeCompensation = (name, value) => {

        let cantidad_separado = name.split('_');

        let compensaciones = proyectoData.compensaciones;

        compensaciones[cantidad_separado[1]] = {
            ...compensaciones[cantidad_separado[1]],
            [cantidad_separado[0]]: value
        };

        setProyectoData({
            ...proyectoData,
            compensaciones
        });
    };

    const onChangeUnits = ({ target }) => {
        let id_sep = target.id.split('_');
        let posicion = id_sep[1];

        if((parseInt(contador) - parseInt(proyectoData.edificios[posicion].length) + parseInt(target.value)) <= proyectoData.unidades_general){
            setCopiarUnidades({
                ...copiarUnidades,
                Bed: false,
                Bath: false,
                SQFT: false,
                Garage: false,
            });            

            let array_unidades = [];            

            if(target.value !== ''){
                if(target.value > 0){
                    for (let i = 1; i <= target.value; i++) {
                        array_unidades.push({
                            nombre: '',
                            nivel: [0],
                            habitaciones: 0,
                            sanitarios: 0,
                            construccion: '',
                            cochera: [0],
                            tipo_cochera: [0]
                        });

                        setContador(parseInt(contador) - parseInt(proyectoData.edificios[posicion].length) + parseInt(target.value));
                    }
                    
                }
            }

            let edificios = proyectoData.edificios;
            edificios[posicion] = array_unidades;

            setProyectoData({ 
                ...proyectoData,
                edificios
            });
        }
    };

    const onChangeLevels = ({ target }) => {

        let id_sep = target.id.split('_');
        let posicion = id_sep[1];

        let niveles = proyectoData.niveles;
        niveles[posicion] = target.value;

        setProyectoData({ 
            ...proyectoData,
            niveles
        });
    };

    const onChangeYear = ({ target }) => {

        let id_sep = target.id.split('_');
        let posicion = id_sep[2];

        let ano_construccion = proyectoData.ano_construccion;
        ano_construccion[posicion] = target.value;

        setProyectoData({ 
            ...proyectoData,
            ano_construccion
        });
    };

    const handleToggleFileOffer = () => {
        return fileOffer.current?.click();
    };

    const handleOpenOfferFile = (url) => {
       window.open(url);
    };
    
    const onChangeFileOffer = () => {
        if(fileOffer.current.files.length > 0){
            setOfferFile({
                ...offerFile,
                file: fileOffer.current.files[0],
                archivo: URL.createObjectURL(fileOffer.current.files[0])
            });
        } else {
            setOfferFile({
                ...offerFile,
                file: undefined,
                archivo: ''
            })
        }          
    };

    const onPasteScreenShotFile = (e) => {
        if(!e?.clipboardData?.files[0]){
            setScreenshotFile({
                ...screenshotFile,
                file: undefined,
                archivo: ''
            });
            
            alert('No file selected, try again');
            setScreenshotModal(false);
            
            
        }else{
            let file = e.clipboardData.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setScreenshotFile({
                    ...screenshotFile,
                    file: file,
                    url: reader.result
                });
            }
        }
    }

    const onDeleteScreenshot = () => {
        setScreenshotFile({
            file: undefined,
            url: ''
        });
    };

    const handleCloseModalScreenShoot = () => {
        setScreenshotModal(false);

        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (setCoord({
                lat: latLng.lat, lng: latLng.lng
            }),
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChange = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });
    };

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    }

    const handleTypeProject = (e) => {
        let valor = document.getElementById('p-proyecto');

        let id_elegido = (valor.value).split('/')[0];
        let asignar = (valor.value).split('/')[1];

        if(asignar === 'true'){
            asignar = true;
        }else{
            asignar = false;
        }

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: id_elegido,
                tipo_construccion: null,
                propiedad_asignada: asignar
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: null,
                tipo_construccion: null,
                propiedad_asignada: false
            });
        }
    }    

    const handleTypeConstruction = (e) => {
        let valor = document.getElementById('t-construccion');

        let id_elegido = (valor.value).split('/')[0];
        let multi = (valor.value).split('/')[1];
        let edificios = (valor.value).split('/')[2];

        if(multi === 'true'){
            multi = true;
        }else{
            multi = false;
        }

        if(edificios === 'true'){
            edificios = true;
        }else{
            edificios = false;
        }

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: id_elegido,
                multi_unidades: multi,
                tiene_edificios: edificios
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: null,
                multi_unidades: true,
                tiene_edificios: true
            });
        }
    }

    const handleCoincidenciaModal = () => {
        setCoincidenciaModal(true);
    }

    const handleCloseCoincidenciaModal = () => {
        setCoincidenciaModal(false);
    }

    const handleAddGarage = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');

        let p_edificio = id_separado[1];

        let edificios = proyectoData.edificios;

        for (let i = 0; i < edificios[p_edificio].length; i++) {
            edificios[p_edificio][i].cochera.push(0);
            edificios[p_edificio][i].tipo_cochera.push(0);
        }        

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    }

    const handleAddGarageSingle = async ({currentTarget}) => {
        let id_separado = currentTarget.id.split('_');

        let edificios = proyectoData.edificios;

        edificios[0][0].cochera.push(0);
        edificios[0][0].tipo_cochera.push(0);

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    }

    const handleDeleteGarage = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');

        let p_edificio = id_separado[1];
        let p_garage = id_separado[2];

        let edificios = proyectoData.edificios;

        for (let i = 0; i < edificios[p_edificio].length; i++) {
            edificios[p_edificio][i].cochera.splice(p_garage, 1);
            edificios[p_edificio][i].tipo_cochera.splice(p_garage, 1);
        }        

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    }

    const onChangeSelect = (value, action) => {

        let arregloAuxiliar = [];
        let campo = '';
        let p_edificio = '';
        let p_unidad = '';
        let edificios = proyectoData.edificios;

        for(var i=0; i<value.length; i++){
            if(!arregloAuxiliar.includes(value[i]?.value)){
                arregloAuxiliar.push(value[i]?.value);
            }
        }
        
        let id_separado = action.name.split('_');

        campo = id_separado[0];
        p_edificio = id_separado[1];
        p_unidad = id_separado[2];

        edificios[p_edificio][p_unidad][[campo]] = arregloAuxiliar;

        setProyectoData({ 
            ...proyectoData, 
            edificios
        });
    }

    const buildingsRows = (buildings) => {
        let secciones = [];

        if(proyectoData.multi_unidades === true){
            buildings.map((building, key) => {

                let units = [];

                if(building.length > 0){
                    if(building[0].habitaciones !== '' && copiarUnidades.Bed === true){
                        for(let i = 0; i < building.length; i++){
                            building[i].habitaciones = building[0].habitaciones;
                        }
                        setCopiarUnidades({
                            ...copiarUnidades,
                            Bed: false
                        })
                    }
                    if(building[0].sanitarios !== '' && copiarUnidades.Bath === true){
                        for(let i = 0; i < building.length; i++){
                            building[i].sanitarios = building[0].sanitarios;
                        }
                        setCopiarUnidades({
                            ...copiarUnidades,
                            Bath: false
                        })
                    }
                    if(building[0].construccion !== '' && copiarUnidades.SQFT === true){
                        for(let i = 0; i < building.length; i++){
                            building[i].construccion = building[0].construccion;
                        }
                        setCopiarUnidades({
                            ...copiarUnidades,
                            SQFT: false
                        })
                    }
                }          

                for (let i = 0; i < building.length; i++) {

                    let garages = [];

                    //Select de level
                    let opcionesSelect = [];

                    for(var j = 1; j <= proyectoData.niveles[key]; j++){
                        opcionesSelect.push(
                            { value: j , label: j },
                        );
                    }                    

                    for (var x = 0; x < building[i].cochera.length; x++) {
                        garages.push(
                            <div className="input-group campo_select_lg mx-2">
                                <input className="form-control text-center" type="number" name="cochera" id={'cochera_' + key + '_' + i + '_' + x} onChange={ e => onChangeSubArray(e) } value={building[i].cochera[x] || ''}></input>
                                <div className="input-group-append">
                                    <select className="browser-default custom-select" name="tipo_cochera" id={'tipo_cochera_' + key + '_' + i + '_' + x} onChange={ e => onChangeSubArray(e) } value={building[i].tipo_cochera[x] || ''}>
                                        <option value="0">Attached</option>
                                        <option value="1">Detached</option>
                                        <option value="2">Car port cover</option>
                                        <option value="3">Parking space</option>
                                        <option value="4">Basement</option>
                                    </select>
                                </div>
                                <div className="input-group-append">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">Delete</Tooltip>}
                                    >
                                        <button type="button" className="btn btn-small btn-danger py-1 px-2" id={'garage_' + key + '_' + x} onClick={e => handleDeleteGarage(e)}>
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </OverlayTrigger>
                                    
                                </div>
                            </div>
                        );
                    }

                    units.push(
                        <tr key={'edificio_' + key + 'unidad_' + i}>
                            <td>
                                <input className="form-control text-center campo_numero_sm" type="text" id={'nombre_' + key + '_' + i} name="nombre" onChange={ e => onChangeArray(e) } value={building[i].nombre}></input>
                            </td>

                            <td>
                                <input className="form-control text-center campo_numero_sm" type="number" id={'habitaciones_' + key + '_' + i} name="habitaciones" onChange={ e => onChangeArray(e) } value={building[i].habitaciones}></input>
                            </td>

                            <td>
                                <input className="form-control text-center campo_numero_sm" type="number" id={'sanitarios_' + key + '_' + i} name="sanitarios" onChange={ e => onChangeArray(e) } value={building[i].sanitarios}></input>
                            </td>

                            <td>
                                <CurrencyInput
                                    name={'construccion_' + key + '_' + i}
                                    className="form-control text-center campo_numero_sm"
                                    value={building[i].construccion}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChangeArray(name, value)}
                                />
                            </td>

                            <td>
                                <Select
                                    defaultValue={null}
                                    isMulti
                                    name={'nivel_' + key + '_' + i}
                                    options={opcionesSelect}
                                    className="basic-multi-select campo_numero_sm"
                                    classNamePrefix="select"
                                    id={'nivel_' + key + '_' + i}
                                    onChange={ onChangeSelect }
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </td>

                            <td className="form-group contenedor_garages">
                                {garages}
                            </td>
                        </tr>
                    );
                }

                secciones.push(
                    <Fragment key={'building_' + key}>
                        <div className="card-header font-weight-bold" data-toggle="collapse" data-target={'#filaBuilding' + key} style={styles.header_edificio} aria-expanded="true" aria-controls={'#filaBuilding' + key}>
                            <div className="row mx-0">
                                <div className="col-md-1 mb-0 form-group">
                                    <label className="font-weight-bold vertical_center">Build {key + 1}</label>
                                </div>

                                <div className="col-md-2 mb-0 form-group campo_numero_lg">
                                    <label className="font-weight-bold"># of units:</label>
                                    <input className="form-control text-center" type="number" name="unidades" id={'unidades_' + key} min="1" onChange={ e => onChangeUnits(e) } value={building.length}></input>
                                </div>

                                <div className="col-md-2 mb-0 form-group campo_numero_lg">
                                    <label className="font-weight-bold">Levels:</label>
                                    <input className="form-control text-center" type="number" name="niveles" id={'niveles_' + key} onChange={ e => onChangeLevels(e) } value={proyectoData.niveles[key] || ''}></input>
                                </div>

                                <div className="col-md-2 mb-0 form-group campo_numero_lg">
                                    <label className="font-weight-bold">Year Built:</label>
                                    <input className="form-control text-center" type="text" name="ano_construccion" id={'ano_construccion_' + key} onChange={ e => onChangeYear(e) } value={proyectoData.ano_construccion[key] || ''} ></input>
                                </div>
                            </div>
                        </div>

                        <div id={'filaBuilding' + key} className={key > 0 ? 'collapse p-3' : 'collapse show p-3'} style={styles.seccion_edificio} aria-labelledby="headingOne" data-parent="#accordion_planning">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Unit #:</th>
                                            <th>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">Copy in all rows</Tooltip>}
                                                >
                                                    <button type="button" className="btn btn-small btn-primary py-1 px-2 mr-2" onClick={() => setCopiarUnidades({
                                                            ...copiarUnidades,
                                                            Bed: true
                                                        })}><i className="fas fa-copy"></i>
                                                    </button>
                                                </OverlayTrigger>
                                                Bed:
                                            </th>
                                            <th>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">Copy in all rows</Tooltip>}
                                                >
                                                    <button type="button" className="btn btn-small btn-primary py-1 px-2 mr-2" onClick={() => setCopiarUnidades({
                                                            ...copiarUnidades,
                                                            Bath: true
                                                        })}><i className="fas fa-copy"></i>
                                                    </button>
                                                </OverlayTrigger>
                                                Bath:
                                            </th>
                                            <th>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">Copy in all rows</Tooltip>}
                                                >
                                                    <button type="button" className="btn btn-small btn-primary py-1 px-2 mr-2" onClick={() => setCopiarUnidades({
                                                            ...copiarUnidades,
                                                            SQFT: true
                                                        })}><i className="fas fa-copy"></i>
                                                    </button>
                                                </OverlayTrigger>
                                                SQFT:
                                            </th>
                                            <th>Level:</th>
                                            <th>
                                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Add new garage</Tooltip>}>
                                                    <button type="button" className="btn btn-small btn-info py-1 px-2 mr-2" id={'garages_' + key} onClick={e => handleAddGarage(e)}><i className="fas fa-plus"></i>
                                                    </button>
                                                </OverlayTrigger>
                                                Garage:
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{units}</tbody>
                                </table>
                            </div>
                        </div>
                    </Fragment> 
                );
            });
        }else{

            let garages = [];

            for (var x = 1; x < proyectoData.edificios[0][0]?.cochera?.length; x++) {
                garages.push(
                    <div className="col-md-2 form-group campo_select_lg">
                        <label className="font-weight-bold"> </label>
                        <div className="input-group">
                            <input className="form-control text-center" type="number" name="cochera" id={'cochera_0_0_' + x} onChange={ e => onChangeSubArray(e) } value={proyectoData.edificios[0][0]?.cochera[x] || ''}></input>
                            <div className="input-group-append">
                                <select className="browser-default custom-select" name="tipo_cochera" id={'tipo_cochera_0_0_' + x} onChange={ e => onChangeSubArray(e) } value={proyectoData.edificios[0][0]?.tipo_cochera[x] || ''}>
                                    <option value="0">Attached</option>
                                    <option value="1">Detached</option>
                                    <option value="2">Car port cover</option>
                                    <option value="3">Parking space</option>
                                    <option value="4">Basement</option>
                                </select>
                            </div>
                            <div className="input-group-append">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">Delete</Tooltip>}
                                >
                                    <button type="button" className="btn btn-small btn-danger py-1 px-2" id={'garage_0_0'} onClick={e => handleDeleteGarage(e)}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </OverlayTrigger>
                                
                            </div>
                        </div>
                    </div>
                );
            }

            secciones.push(
                <div className="row mx-0">
                    <div className="col-md-2 form-group campo_numero_lg">
                        <label className="font-weight-bold">Bed:</label>
                        <input className="form-control text-center" type="number" id={'habitaciones_' + 0 + '_' + 0} name="habitaciones" onChange={ e => onChangeArray(e) } value={proyectoData.edificios[0][0]?.habitaciones}></input>
                    </div>
                    <div className="col-md-2 form-group campo_numero_lg">
                        <label className="font-weight-bold">Bath:</label>
                        <input className="form-control text-center" type="number" id={'sanitarios_' + 0 + '_' + 0} name="sanitarios" onChange={ e => onChangeArray(e) } value={proyectoData.edificios[0][0]?.sanitarios}></input>
                    </div>
                    <div className="col-md-2 form-group campo_select_lg">
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Add new garage</Tooltip>}>
                            <button type="button" className="btn btn-small btn-info py-1 px-2 mr-2" id={'garages_' + 0} onClick={e => handleAddGarageSingle(e)}><i className="fas fa-plus"></i>
                            </button>
                        </OverlayTrigger>
                        <label className="font-weight-bold">Garage:</label>
                        <div className="input-group">
                            <input className="form-control text-center" type="number" name="cochera" id={'cochera_0_0_0'} onChange={ e => onChangeSubArray(e) } value={proyectoData.edificios[0][0]?.cochera[0] || ''}></input>
                            <div className="input-group-append">
                                <select className="browser-default custom-select" name="tipo_cochera" id={'tipo_cochera_0_0_0'} onChange={ e => onChangeSubArray(e) } value={proyectoData.edificios[0][0]?.tipo_cochera[0] || ''}>
                                    <option value="0">Attached</option>
                                    <option value="1">Detached</option>
                                    <option value="2">Car port cover</option>
                                    <option value="3">Parking space</option>
                                    <option value="4">Basement</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    {garages}

                    <div className="col-md-1 form-group campo_numero_lg">
                        <label className="font-weight-bold">Levels:</label>
                        <input className="form-control text-center" type="number" name="niveles" id={'niveles_' + 0} onChange={ e => onChangeLevels(e) } value={proyectoData.niveles[0] || ''}></input>
                    </div>
                    <div className="col-md-1 form-group campo_numero_lg">
                        <label className="font-weight-bold">Year Built:</label>
                        <input className="form-control text-center" type="text" name="ano_construccion" id={'ano_construccion_' + 0} onChange={ e => onChangeYear(e) } value={proyectoData.ano_construccion[0] || ''} ></input>
                    </div>
                    <div className="col-md-2 form-group campo_numero_lg">
                        <label className="font-weight-bold">SQFT:</label>
                        <CurrencyInput
                                name={'construccion_' + 0 + '_' + 0}
                                className="form-control text-center"
                                value={proyectoData.edificios[0][0]?.construccion}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeArray(name, value)}
                        />
                    </div>
                    <div className="col-md-2 form-group campo_numero_lg">
                        <label className="font-weight-bold">LOT:</label>
                        <div className="input-group">
                            <CurrencyInput
                                name={'terreno_' + 0}
                                className="form-control text-center"
                                value={proyectoData.terreno[0] || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeLot(value, name)}
                            />
                            <div className="input-group-append">
                                <select className="browser-default custom-select" name={"terreno_unidad_" + 0} value={proyectoData.terreno_unidad[0]} onChange={ e => onChangeUnitsLot(e) }>
                                    <option value="0">FT</option>
                                    <option value="1">AC</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <div id="accordion_planning" className="col-md-12">{secciones}</div>;
    }

    const handleAddCompensation = async () => {
        let compensaciones = proyectoData.compensaciones;

        compensaciones.push({
            nombre: null,
            celular: null,
            telefono: null,
            email: null,
            cantidad: null
        });

        setProyectoData({ 
            ...proyectoData, 
            compensaciones
        });
    }

    const handleDeleteCompensation = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('_');    

        let compensaciones = proyectoData.compensaciones;

        compensaciones.splice(id_separado[1], 1);

        setProyectoData({ 
            ...proyectoData, 
            compensaciones
        });
    }

    const compensationsRows = (compensations) => {

        let beneficiaries = [];

        for (var x = 0; x < compensations?.length; x++) {
            beneficiaries.push(
                <div className="col-md-12 row">
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Beneficiary:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button className="btn btn-danger" type="button" id={'compensation_' + x} onClick={e => handleDeleteCompensation(e)}>Delete</button>
                            </div>
                            <input className="form-control" type="text" name={'name_' + x} value={compensations[x].name} onChange={e => onChangeCompensation(e)} disabled={formDisabled ? true : false} />
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Cell phone:</label>
                        <PhoneInput className="form-control" country={'us'} value={compensations[x].cell_phone} inputProps={{ name: 'cell_phone_' + x, onChange: e => onChangeCompensation(e)}} disabled={formDisabled ? true : false} />
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Phone:</label>
                        <PhoneInput className="form-control" country={'us'} value={compensations[x].phone} inputProps={{ name: 'phone_' + x, onChange: e => onChangeCompensation(e)}} disabled={formDisabled ? true : false} />
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Email:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input className="form-control" type="text" name={'email_' + x} value={compensations[x].email} onChange={e => onChangeCompensation(e)} disabled={formDisabled ? true : false} />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Quantity $:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name={'amount_' + x}
                                className="form-control text-center"
                                value={compensations[x].amount || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeCompensation(name, value)}
                                disabled={formDisabled ? true : false}
                            />
                        </div>
                    </div>
                </div>                
            );
        }

        return beneficiaries;
    }

    const validateOfferPrice = async (avanzar) => {

        await setProyectoData({
            ...proyectoData,
            avanzar
        });

        if(String(masterlist?.offer_price) !== String(proyectoData.offer_price)) {
            await setWarningModal(true);
        }else{
            await handleValidate();
        }
    }

    const newNote = async (campo) => {
        await dispatch(externoNota(true, campo));
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
                // handleCloseNoGuardarCambiosModal();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const handleNotes = async () => {
        await dispatch(notesProyecto(id));
        
        await history.push('/projects/notes/' + id);
    }

    /* Funciones de guardado */
    const handleDelete = async () => {
        await dispatch(externoEliminar(null, true));
    }

    const handleEnable = async () => {
        await dispatch(activarOferta(proyectoData.id));

        await history.push('/projects/offers');
    }

    const handleDisable = async () => {
        await dispatch(desactivarOferta(proyectoData.id));

        await history.push('/projects/offers');
    }

    const handleCancelEdit = async () => {
        if(detalles?.proyecto?.tipo_status !== null && detalles?.proyecto?.tipo_status !== ''){
            history.push('/projects/buySale/' + id);
        }else{
            history.push('/projects/offers');
        }
    }

    const handleValidate = async () => {
        if(proyectoData.direccion !== null && proyectoData.direccion !== ''){
            if (proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.offer_price !== null) {
                await dispatch(validarProyecto(proyectoData.id, proyectoData.direccion));
            } else{
                await dispatch(setAlert('Missing information', 'danger'));
            }
        }else{
            await handleSave();
        }
    }

    const handleSave = async () => {
        if (proyectoData.direccion !== null && proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null) {
            
            let formData = new FormData();
            formData.append('id', proyectoData.id);
            formData.append('tipo_proyecto', proyectoData.tipo_proyecto);
            formData.append('tipo_construccion', proyectoData.tipo_construccion);
            formData.append('direccion', proyectoData.direccion);
            formData.append('latitud', proyectoData.latitud);
            formData.append('longitud', proyectoData.longitud);
            formData.append('apn', JSON.stringify(proyectoData.apn));
            formData.append('terreno', JSON.stringify(proyectoData.terreno));
            formData.append('terreno_unidad', JSON.stringify(proyectoData.terreno_unidad));
            formData.append('direcciones', JSON.stringify(proyectoData.direcciones));
            formData.append('informacion_detallada', proyectoData.informacion_detallada);
            formData.append('informacion_detallada_explicacion', proyectoData.informacion_detallada_explicacion);
            formData.append('construccion_general', proyectoData.construccion_general);
            formData.append('unidades_general', proyectoData.unidades_general);
            formData.append('offer_price', proyectoData.offer_price);
            formData.append('asking_price', proyectoData.asking_price);
            formData.append('listing_id', proyectoData.listing_id);
            formData.append('id_tipo_financiamiento', proyectoData.id_tipo_financiamiento);
            formData.append('down_payment', proyectoData.down_payment);
            formData.append('tipo_down_payment', proyectoData.tipo_down_payment);
            formData.append('offer_file', offerFile.file);
            formData.append('screenshot_file', screenshotFile.file);
            formData.append('edificios', JSON.stringify(proyectoData.edificios));
            formData.append('niveles', JSON.stringify(proyectoData.niveles));
            formData.append('ano_construccion', JSON.stringify(proyectoData.ano_construccion));

            formData.append('id_buyer_company', proyectoData.id_buyer_company);

            formData.append('propietario_vende', proyectoData.propietario_vende);
            formData.append('id_seller_owner', proyectoData.id_seller_owner);

            formData.append('compensaciones', JSON.stringify(proyectoData.compensaciones));

            formData.append('forma_1099', proyectoData.forma_1099);
            
            formData.append('propiedad_asignada', proyectoData.propiedad_asignada);
            formData.append('id_vesting', JSON.stringify(proyectoData.id_vesting));
            formData.append('percent_vesting', JSON.stringify(proyectoData.percent_vesting));
            formData.append('id_usuario_responsable', proyectoData.id_usuario_responsable);

            formData.append('id_broker_buyer', proyectoData.id_broker_buyer);
            formData.append('id_broker_agent_buyer', proyectoData.id_broker_agent_buyer);
            formData.append('broker_represent', proyectoData.broker_represent);
            formData.append('id_broker_seller', proyectoData.id_broker_seller);
            formData.append('id_broker_agent_seller', proyectoData.id_broker_agent_seller);

            formData.append('avanzar', proyectoData.avanzar);

            setSpinner(true);
            setFormDisabled(true);

            setTimeout(async () => {
                await dispatch(editarOferta(formData));

                await dispatch(validarProyecto(null, null));
                
                setSpinner(false);
                setFormDisabled(false);

                if(proyectoData.avanzar){
                    history.push('/projects/buySale/' + id);
                }else{
                    history.push('/projects/offers');
                }
            }, 0);
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    useEffect(() => {
        dispatch(buscarTiposProyectos(0));
        dispatch(detallesProyecto(id));
        dispatch(masterlistProyecto(id));

        dispatch(buscarBrokers());
        dispatch(buscarBuyers());
        dispatch(buscarSellers());
        dispatch(buscarFinancingTypes());
        dispatch(buscarVestings());
        dispatch(buscarUsuarios());

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            dispatch(validarProyecto(null, null));
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    useEffect(() => {
        handleViewBrokerSeller();

        handleViewBrokerBuyer();

        handleViewSellerOwner();

        handleViewBuyerCompany();

        handleViewVesting();
    }, [proyectoData]);

    useEffect(() => {
        if(proyectoData.multi_unidades === false){
            let niveles = proyectoData.niveles;
            let edificios = proyectoData.edificios;
            let ano_construccion = proyectoData.ano_construccion;

            if(edificios.length > 0){
                niveles.length = 1;
                edificios.length = 1;
                ano_construccion.length = 1;
            }

            if(edificios[0][0]){
                if('nivel' in edificios[0][0]){
                    edificios[0][0]['nivel'] = 1;
                }
            }
            
            niveles[0] = 1;

            setProyectoData({
                ...proyectoData,
                niveles,
                edificios
            });
        }
    }, [proyectoData.multi_unidades]);

    useEffect(() => {
        if(proyectoData.tipo_proyecto === null){
            dispatch(buscarTiposProyectos(0));
        }else{
            dispatch(buscarTiposProyectos(proyectoData.tipo_proyecto));
        }
    }, [proyectoData.tipo_proyecto]);

    useEffect(() => {
        if(proyectoData.propiedad_asignada === true){
            setSectionInformation(12);
        }else{
            if(proyectoData.propietario_vende === true){
                setSectionInformation(6);
            }else{
                setSectionInformation(2);
            }

            setVestingSelectData([null]);
        }
    }, [proyectoData.propiedad_asignada]);

    useEffect(() => {
        if(proyectoData.propietario_vende === true){
            setSectionInformation(6);
        }else{
            setSectionInformation(2);
        }
    }, [proyectoData.propietario_vende]);

    useEffect(() => {
        if(proyectoData.compensaciones?.length === 0){
            if(proyectoData.propietario_vende === true){
                setSectionInformation(6);
            }else{
                setSectionInformation(2);
            }
        }
    }, [proyectoData.compensaciones?.length]);

    useEffect(() => {
        if(proyectoData.id_usuario_responsable === null){
            setUserResponsableSelectData(null);
        }else{
            if(usersFilter.length > 0){

                let usuario_asignado = usersFilter.filter( usuario => String(usuario.value) === String(proyectoData.id_usuario_responsable) );

                if(usuario_asignado){
                    setUserResponsableSelectData(usuario_asignado);
                }
            }
        }
    }, [usersFilter]);

    useEffect(() => {
        if(proyectoData.id_tipo_financiamiento === null){
            setFinancingTypeSelectData(null);

            setProyectoData({
                ...proyectoData,
                tiene_down_payment: false,
                down_payment: null,
                tipo_down_payment: 0
            });
        }else{

            let tiene_down_payment = false;

            if(financingTypesFilter.length > 0){

                let financiamiento_asignado = financingTypesFilter.find( tipo => String(tipo.value) === String(proyectoData.id_tipo_financiamiento) );

                if(financiamiento_asignado){
                    setFinancingTypeSelectData({value: financiamiento_asignado.value, label: financiamiento_asignado.label});

                    tiene_down_payment = financiamiento_asignado.down_payment;
                }
            }

            setProyectoData({
                ...proyectoData,
                tiene_down_payment
            });
        }
    }, [proyectoData.id_tipo_financiamiento, financingTypesFilter]);

    useEffect(() => {
        if(coincidenciaProyecto.direccion !== '' && coincidenciaProyecto.proyecto !== '' && coincidenciaProyecto.construccion !== ''){
            handleCoincidenciaModal();
        }
    }, [coincidenciaProyecto]);

    useEffect(() => {

        let datos_ingresar = {
            ...proyectoData,
            id: detalles?.proyecto?._id || null,
            tipo_proyecto: detalles?.proyecto?.tipo_proyecto || null,
            tipo_construccion: detalles?.proyecto?.tipo_construccion || null,
            multi_unidades: detalles?.proyecto?.multi_unidades_construccion !== null && detalles?.proyecto?.multi_unidades_construccion !== undefined ? detalles?.proyecto?.multi_unidades_construccion : null,
            tiene_edificios: detalles?.proyecto?.tiene_edificios_construccion !== null && detalles?.proyecto?.tiene_edificios_construccion !== undefined ? detalles?.proyecto?.tiene_edificios_construccion : null,
            direccion: detalles?.proyecto?.direccion || null,
            latitud: detalles?.proyecto?.latitud || null,
            longitud: detalles?.proyecto?.longitud || null,
            multi_apn: detalles?.proyecto?.multi_apn !== null && detalles?.proyecto?.multi_apn !== undefined ? detalles?.proyecto?.multi_apn : null,
            apn: detalles?.proyecto?.apn || [''],
            terreno: detalles?.proyecto?.terreno || [''],
            terreno_unidad: detalles?.proyecto?.terreno_unidad || [0],
            direcciones: detalles?.proyecto?.direcciones || [''],
            construccion_general: detalles?.proyecto?.construccion_general || '',
            unidades_general: detalles?.proyecto?.unidades_general || 1,
            offer_price: masterlist?.offer_price || null,
            asking_price: detalles?.proyecto?.asking_price || null,
            listing_id: detalles?.proyecto?.listing_id || null,
            id_tipo_financiamiento: detalles?.proyecto?.id_tipo_financiamiento !== null ? detalles?.proyecto?.id_tipo_financiamiento : null,
            down_payment: detalles?.proyecto?.down_payment || null,
            tipo_down_payment: detalles?.proyecto?.tipo_down_payment || 0,

            url_offer_file: detalles?.proyecto?.url_offer_file,
            url_offer_image: detalles?.proyecto?.url_offer_image,
            informacion_detallada: detalles?.proyecto?.informacion_detallada !== null && detalles?.proyecto?.informacion_detallada !== undefined ? detalles?.proyecto?.informacion_detallada : null,
            informacion_detallada_explicacion: detalles?.proyecto?.informacion_detallada_explicacion || '',

            id_buyer_company: masterlist?.id_buyer_company || null,
            
            propietario_vende: masterlist?.propietario_vende || false,
            id_seller_owner: masterlist?.id_seller_owner || null,
            
            compensaciones: masterlist?.compensaciones || [],
            
            forma_1099: masterlist?.forma_1099 !== null && masterlist?.forma_1099 !== undefined ? masterlist?.forma_1099 : null,

            propiedad_asignada: detalles?.proyecto?.asignado_proyecto !== null && detalles?.proyecto?.asignado_proyecto !== undefined ? detalles?.proyecto?.asignado_proyecto : null,
            id_vesting: masterlist?.id_vesting || [],
            percent_vesting: masterlist?.percent_vesting || [],
            
            id_usuario_responsable: masterlist?.id_usuario_responsable || null,

            id_broker_buyer: masterlist?.id_broker_buyer_buy || null,
            id_broker_agent_buyer: masterlist?.id_broker_agent_buyer_buy || null,
            broker_represent: masterlist?.broker_represent_buy !== null && masterlist?.broker_represent_buy !== undefined ? masterlist?.broker_represent_buy : false,
            id_broker_seller: masterlist?.id_broker_seller_buy || null,
            id_broker_agent_seller: masterlist?.id_broker_agent_seller_buy || null
        };

        let edificios_temp = [];
        let niveles_temp = [];
        let ano_construccion_temp = [];
        let contador_unidades_existentes = 0;

        if(detalles?.proyecto?.edificios?.length > 0){
            for (let i = 0; i < detalles?.proyecto?.edificios?.length; i++) {
                let unidades_temp = [];

                for (var x = 0; x < detalles?.proyecto?.edificios[i]?.unidades?.length; x++) {
                    unidades_temp.push(detalles?.proyecto?.edificios[i]?.unidades[x]);
                }

                contador_unidades_existentes += detalles?.proyecto?.edificios[i]?.unidades?.length;

                edificios_temp.push(unidades_temp);
                niveles_temp.push(detalles?.proyecto?.edificios[i]?.niveles);
                ano_construccion_temp.push(detalles?.proyecto?.edificios[i]?.ano_construccion);
            }
        }else{
            edificios_temp.push([
                {
                    nombre: '',
                    nivel: 0,
                    habitaciones: 0,
                    sanitarios: 0,
                    construccion: '',
                    cochera: [0],
                    tipo_cochera: [0]
                }
            ]);
        }

        datos_ingresar.edificios = edificios_temp;
        datos_ingresar.ano_construccion = ano_construccion_temp;
        datos_ingresar.niveles = niveles_temp;

        setProyectoData(datos_ingresar);

        setContador(contador_unidades_existentes);

        setCoord({
            lat: parseFloat(detalles?.proyecto?.latitud),
            lng: parseFloat(detalles?.proyecto?.longitud)
        });
    }, [detalles, masterlist]);

    useEffect(() => {
        if(proyecto_coincidencia !== null){
            if(proyecto_coincidencia !== ''){
                setCoincidenciaProyecto({
                    ...coincidenciaProyecto,
                    direccion: proyecto_coincidencia?.direccion,
                    proyecto: proyecto_coincidencia?.proyecto,
                    construccion: proyecto_coincidencia?.construccion
                });
            }else{
                handleSave();
            }
        }
    }, [proyecto_coincidencia]);

    useEffect(() => {
        if(contador > 4){
            dispatch(setAlert('4 or more units', 'danger'));
        }
    }, [contador]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options);

        handleViewBrokerSeller();

        handleViewBrokerBuyer();
    }, [brokers]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < buyers.buyers?.length; i++) {
            options.push({ value: buyers.buyers[i]._id, label: buyers.buyers[i].nombre });
        }

        setBuyersFilter(options);

        handleViewBuyerCompany();
    }, [buyers]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < financing_types.financing_types?.length; i++) {
            options.push({ value: financing_types.financing_types[i]._id, label: financing_types.financing_types[i].nombre, down_payment: financing_types.financing_types[i].down_payment });
        }

        setFinancingTypesFilter(options);

        handleViewFinancingType();
    }, [financing_types]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < sellers.sellers?.length; i++) {
            options.push({ value: sellers.sellers[i]._id, label: sellers.sellers[i].nombre });
        }

        setSellersFilter(options);

        handleViewSellerOwner();
    }, [sellers]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < vestings.vestings?.length; i++) {
            options.push({ value: vestings.vestings[i]._id, label: vestings.vestings[i].nombre });
        }

        setVestingFilter(options);

        handleViewVesting();
    }, [vestings]);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < usuarios.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {
        if(copiarBroker){
            handleCopyBroker();
        }
    }, [copiarBroker]);

    return (
        <Fragment>
            {/* Modal de validacion de offer */}
            <Modal backdrop="static" show={warningModal} centered onHide={() => {
                setWarningModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Editing "Offer Price"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>Warning you are Editing Offer Price, Current: {masterlist?.offer_price}, New: {proyectoData?.offer_price} Are you sure that is Correct?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => setWarningModal(false)}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => handleValidate()}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de coincidencia */}
            <Modal backdrop="static" show={coincidenciaModal} centered onHide={() => {
                setCoincidenciaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Similarity in projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>The project with the address "<b>{coincidenciaProyecto.direccion}</b>" (<b>{coincidenciaProyecto.proyecto}</b> - <b>{coincidenciaProyecto.construccion}</b>) has some similarity. Do you want to continue saving anyway?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseCoincidenciaModal()}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => {setCoincidenciaModal(false); handleSave();}}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de agregar buyer */}
            <Modal backdrop="static" show={buyerCompanyModal} size="md" centered onHide={() => {
                setBuyerCompanyModal(false);
                setAddBuyerCompanyData({
                    ...addBuyerCompanyData,
                    _id: null,
                    nombre: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Buyer company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addBuyerCompanyData.nombre || ''} onChange={e => handleInputsAddBuyerCompany(e)}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBuyerCompany()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBuyerCompany}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de agregar seller */}
            <Modal backdrop="static" show={sellerOwnerModal} size="xl" centered onHide={() => {
                setSellerOwnerModal(false);
                setAddSellerOwnerData({
                    ...addSellerOwnerData,
                    _id: null,
                    nombre: '',
                    direccion: '',
                    email: '',
                    celular: '',
                    telefono: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Seller owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addSellerOwnerData.nombre || ''} onChange={e => handleInputsAddSellerOwner(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addSellerOwnerData.direccion || ''}
                            onChange={(value) => setAddSellerOwnerData({ ...addSellerOwnerData, direccion: value })}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-8 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="celular" className="form-control" country={'us'} value={addSellerOwnerData.celular || ''} onChange={phone => {setAddSellerOwnerData({...addSellerOwnerData, celular: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="telefono" className="form-control" country={'us'} value={addSellerOwnerData.telefono || ''} onChange={phone => {setAddSellerOwnerData({...addSellerOwnerData, telefono: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input name="email" className="form-control" type="text" value={addSellerOwnerData.email || ''} onChange={e => handleInputsAddSellerOwner(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalSellerOwner()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddSellerOwner}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de agregar financing type */}
            <Modal backdrop="static" show={financingTypeModal} size="md" centered onHide={() => {
                handleCloseModalFinancingType()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Financing type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addFinancingTypeData.nombre || ''} onChange={e => handleInputsAddFinancingType(e)}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group campo_radio_lg">
                            <label className="font-weight-bold">Has a down payment?:</label><br/>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="down_payment" value="1" onChange={e => onChangeRadioButtonFinancingType(e)} checked={addFinancingTypeData.down_payment ? true : false} />
                                <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="down_payment" value="2" onChange={e => onChangeRadioButtonFinancingType(e)} checked={addFinancingTypeData.down_payment ? false : true} />
                                <label className="form-check-label">No</label>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalFinancingType()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddFinancingType}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de agregar vesting */}
            <Modal backdrop="static" show={vestingModal} size="xl" centered onHide={() => {
                setVestingModal(false);
                setAddVestingData({
                    ...addVestingData,
                    _id: null,
                    nombre: '',
                    direccion: '',
                    representante: '',
                    email: '',
                    celular: '',
                    telefono: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Vesting company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" value={addVestingData.nombre || ''} onChange={e => handleInputsAddVesting(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addVestingData.direccion || ''}
                            onChange={(value) => setAddVestingData({ ...addVestingData, direccion: value })}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-8 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Representant:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input name="representante" className="form-control" type="text" value={addVestingData.representante || ''} onChange={e => handleInputsAddVesting(e)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="celular" className="form-control" country={'us'} value={addVestingData.celular || ''} onChange={phone => {setAddVestingData({...addVestingData, celular: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="telefono" className="form-control" country={'us'} value={addVestingData.telefono || ''} onChange={phone => {setAddVestingData({...addVestingData, telefono: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input name="email" className="form-control" type="text" value={addVestingData.email || ''} onChange={e => handleInputsAddVesting(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalVesting()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleCreateVesting}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal para agregar Screenshot File */}
            <Modal backdrop="static" show={screenshotModal} centered onHide={() => {
                setScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Pasting Screenshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12" onPaste={(e)=> onPasteScreenShotFile(e)} style={{height: '100%', width: '100%', 'border-style': 'dashed', 'border-color': '#6c757d' }}>
                            {
                                screenshotFile.url !== '' ?
                                <>
                                    <button className="btn btn-small btn-danger py-1 px-2" onClick={() => onDeleteScreenshot()}><i className='fas fa-times'></i></button>
                                    <img id='pasteScreenshot' src={screenshotFile.url} style={{height: '100%', width: '100%', padding: '8px'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}>Paste Here</h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalScreenShoot()}>Cancel</button>
                    <button className="btn btn-success" onClick={() => setScreenshotModal(false)}>Save</button>
                </Modal.Footer>
            </Modal>
            
            {/* Modal para ver Screenshot File */}
            <Modal backdrop="static" show={viewScreenshotModal} size="lg" centered onHide={() => {
                setViewScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>View Screenshot file</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="row">
                        <div className="col-12">
                            {
                                screenshotFile.file !== null || proyectoData.url_offer_image !== null ?
                                <>
                                    <img id='screenshotFile' src={screenshotFile.file ? screenshotFile.url : proyectoData.url_offer_image} style={{width: '100%'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}> No image Found </h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => setViewScreenshotModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <form className="col-md-12" autoComplete="off" onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}}>
                    <input autoComplete='off' name="hidden" type="text" style={{display:'none'}} />
                    
                    <div className='row'>
                        {
                            proyectoData.propietario_vende === true ?
                            <h3 className="col-md mb-3 mt-5">Edit Offer (sell by owner)</h3>
                            :
                            <h3 className="col-md mb-3 mt-5">Edit Offer</h3>
                        }

                        <div className="col-md d-flex justify-content-end mb-3 mt-5">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCancelEdit()} disabled={formDisabled ? true : false}><i className="fas fa-arrow-left fa-sm"></i> Back previus</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setScreenshotModal(true)}><i className="fas fa-file-medical fa-sm"></i> {proyectoData.url_offer_image || screenshotFile.file ? 'Update' : 'Add'} Screenshot file</button>
                            {
                                proyectoData.url_offer_image || screenshotFile.file ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => setViewScreenshotModal(true)}><i className="fas fa-file fa-sm"></i> View Screenshot file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => handleToggleFileOffer()}><i className="fas fa-file-medical fa-sm"></i> {proyectoData.url_offer_file || offerFile.file ? 'Update' : 'Add'} offer file</button>
                            {
                                proyectoData.url_offer_file || offerFile.file ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => handleOpenOfferFile(offerFile.file ? offerFile.archivo : proyectoData.url_offer_file)}><i className="fas fa-file fa-sm"></i> View offer file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setCollapse(!collapse)} data-toggle="collapse" data-target="#contenedor_mapa" aria-expanded="true" aria-controls="contenedor_mapa"><i className="fas fa-map"></i> {collapse ? 'Hide Map' : 'View Map'}</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => handleNotes()}><i className="fas fa-sticky-note fa-sm"></i> View notes</button>
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleDelete()} disabled={formDisabled ? true : false}><i className="fas fa-trash fa-sm"></i> Delete</button>
                            {
                                detalles?.proyecto?.activo === false || detalles?.proyecto?.activo === 'false' ?
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleEnable()} disabled={formDisabled ? true : false}><i className="fas fa-power-off fa-sm"></i> Enable</button>
                                :
                                    <button type="button" className="btn btn-warning mr-2" onClick={() => handleDisable()} disabled={formDisabled ? true : false}><i className="fas fa-ban fa-sm"></i> Disable</button>
                            }
                            {
                                !detalles?.proyecto?.tipo_status ?
                                    <>
                                        <button type="button" className="btn btn-info mr-2" onClick={() => validateOfferPrice(false)} disabled={formDisabled ? true : false}><i className="fas fa-save fa-sm"></i> Save and close</button>
                                        <button type="button" className="btn btn-success" onClick={() => validateOfferPrice(true)} disabled={formDisabled ? true : false}><i className="fas fa-check fa-sm"></i> Save and send</button>
                                    </>
                                :
                                    <button type="button" className="btn btn-success" onClick={() => validateOfferPrice(true)} disabled={formDisabled ? true : false}><i className="fas fa-check fa-sm"></i> Save and continue</button>

                            }
                        </div>
                    </div>

                    {spinner ?  
                            <div className="d-flex justify-content-center">
                                <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="row mt-5">
                        <div className="col-md-12 row">
                            <input type="file" name="fileOffer" id="fileOffer" ref={fileOffer} onChange={onChangeFileOffer} className="d-none" />

                            <div className="col-md-2 form-group">
                                <label className="font-weight-bold" onClick={() => newNote('Buyer')}>Buyer:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_buyer_company"
                                        className="select-group"
                                        aria-describedby="button-buyer-company"
                                        isClearable={true}
                                        options={buyersFilter}
                                        value={buyerCompanySelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_buyer_company', '')}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" id="button-buyer-company" onClick={() => handleBuyerCompany()}>Create</button>
                                    </div>
                                </div>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_proyecto !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Project Type')}>Project Type: *</label> <br></br>
                                <select name="tipo_proyecto" id="p-proyecto" className="form-control" onChange={handleTypeProject} value={proyectoData.tipo_proyecto || null} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.proyectos?.length > 0 ?
                                            tipos.proyectos?.map(tipo => {
                                                return(
                                                    <option key={tipo._id} value={ tipo._id }>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_construccion !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Property Type')}>Property Type: *</label> <br></br>
                                <select name="tipo_construccion" id="t-construccion" className="form-control" onChange={e => {handleTypeConstruction(e)}} value={(proyectoData.tipo_construccion + '/' + proyectoData.multi_unidades + '/' + proyectoData.tiene_edificios)} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.construcciones?.length > 0 ?
                                            tipos.construcciones?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={ tipo._id + '/' + tipo.multi_unidades + '/' + tipo.tiene_edificios }>
                                                        { tipo.nombre }
                                                    </option>
                                                )
                                            })
                                        :
                                            ''
                                    }
                                </select>
                            </div>

                            {
                                proyectoData.tiene_edificios ?
                                    null   
                                :
                                    <div className="col-md-2 form-group campo_radio_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('Will there be multiple APNs?')}>Will there be multiple APNs?</label><br/>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="multi_apn" value="1" onChange={e => onChangeRadioButtonApn(e)} checked={proyectoData.multi_apn ? true : false} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="multi_apn" value="2" onChange={e => onChangeRadioButtonApn(e)} checked={proyectoData.multi_apn ? false : true} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                            }

                            <PlacesAutocomplete
                                value={proyectoData.direccion || ''}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >

                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className={'col-md form-group' + (proyectoData.direccion !== null && proyectoData.direccion !== '' ? '' : ' campo-recordatorio')}>
                                        <label className="font-weight-bold" onClick={() => newNote('General address')}>General address: *</label>
                                        <input
                                            {...getInputProps({
                                                className: 'location-search-input form-control',
                                                id: 'i-direccion'
                                            })}
                                            disabled={formDisabled ? true : false}
                                            autoComplete='off'
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const id = uuid();
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        key={id}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            <div className="col-md form-group campo_numero_lg">
                                <label className="font-weight-bold" onClick={() => newNote('APN')}>APN:</label>
                                <input className="form-control" type="text" name="apn" onChange={e => onChange(e)} value={proyectoData.apn || ''} 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    disabled={formDisabled ? true : false}
                                    autoComplete='off'
                                ></input>
                            </div>
                        </div>

                        <div className="col-md-12 row">
                            <div className="col-md-1 form-group campo_fecha">
                                <label className="font-weight-bold" onClick={() => newNote('Offer create')}>Offer create:</label>
                                <input autoComplete='off' className="form-control" type="text" name="creation" value={new Date(detalles?.proyecto?.creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })} disabled/>
                            </div>

                            {
                                detalles?.proyecto?.envio ?
                                    <div className="col-md-1 form-group campo_fecha">
                                        <label className="font-weight-bold" onClick={() => newNote('Offer send')}>Offer send:</label>
                                        <input autoComplete='off' className="form-control" type="text" name="creation" value={new Date(detalles?.proyecto?.envio).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })} disabled/>
                                    </div>
                                :
                                    null
                            }

                            <div className={'col-md-1 form-group campo_dinero' + (proyectoData.offer_price !== null && proyectoData.offer_price !== '' ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Offer price')}>Offer price $:</label>
                                <CurrencyInput
                                    name="offer_price"
                                    className="offerPrice form-control text-center"
                                    value={proyectoData.offer_price || ''}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                    disabled={formDisabled ? true : false}
                                />
                            </div>

                            <div className="col-md-1 form-group campo_dinero">
                                <label className="font-weight-bold" onClick={() => newNote('Asking price')}>Asking price $:</label>
                                <CurrencyInput
                                    name="asking_price"
                                    className="form-control text-center"
                                    value={proyectoData.asking_price === null || proyectoData.asking_price === 'null' ? '' : proyectoData.asking_price}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                    disabled={formDisabled ? true : false}
                                />
                            </div>

                            <div className="col-md-2 form-group campo_dinero">
                                <label className="font-weight-bold" onClick={() => newNote('Listing ID')}>Listing ID:</label>
                                <input type="text" name="listing_id" className="form-control text-center" onChange={ e => onChange(e) } value={proyectoData.listing_id} />
                            </div>
                            
                            {
                                proyectoData.multi_unidades === true && proyectoData.tiene_edificios === true ?
                                    <div className="col-md-2 form-group campo_radio_sm">
                                        <label className="font-weight-bold" onClick={() => newNote('Detailed information')}>Detailed information:</label><br/>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="informacion_detallada" value="1" onChange={e => onChangeRadioButtonDetailed(e)} checked={proyectoData.informacion_detallada === true ? true : false} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="informacion_detallada" value="2" onChange={e => onChangeRadioButtonDetailed(e)} checked={proyectoData.informacion_detallada === false ? true : false} disabled={formDisabled ? true : false} />
                                            <label className="form-check-label">No</label>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            <div className="col-md-2 form-group campo_radio_sm">
                                <label className="font-weight-bold" onClick={() => newNote('Sell by owner?')}>Sell by owner?</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="propietario_vende" value="1" onChange={e => onChangeRadioButtonOwner(e)} checked={proyectoData.propietario_vende === true ? true : false} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="propietario_vende" value="2" onChange={e => onChangeRadioButtonOwner(e)} checked={proyectoData.propietario_vende === false ? true : false} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            <div className="col-md-2 form-group campo_radio_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Will there be compensation to others?')}>Will there be compensation to others?</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="1" onChange={e => onChangeRadioButtonCompensation(e)} checked={proyectoData.compensaciones?.length > 0 ? true : false} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="2" onChange={e => onChangeRadioButtonCompensation(e)} checked={proyectoData.compensaciones?.length > 0 ? false : true} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            <div className="col-md-2 form-group campo_radio_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Will there be 1099 format?')}>Will there be 1099 format?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="1" onChange={e => onChangeRadioButtonFormat(e)} checked={proyectoData.forma_1099 === true ? true : false} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" value="2" onChange={e => onChangeRadioButtonFormat(e)} checked={proyectoData.forma_1099 === true ? false : true} disabled={formDisabled ? true : false} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            {
                                proyectoData.propietario_vende === false ?
                                    <div className="col-md-2 form-group campo_radio_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('Listing agent representing buyer?')}>Listing agent representing buyer?:</label><br/>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="copy_information" value="1" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === true ? true : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="copy_information" value="2" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === false ? true : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who will be the responsible user?')}>Who will be the responsible user?:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_usuario_responsable"
                                        className="select-group"
                                        aria-describedby="button-broker-seller"
                                        isClearable={true}
                                        options={usersFilter}
                                        value={userResponsableSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_usuario_responsable', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Financing type')}>Financing type:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_tipo_financiamiento"
                                        className="select-group"
                                        isClearable={true}
                                        options={financingTypesFilter}
                                        value={financingTypeSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_tipo_financiamiento', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary" type="button" id="button-financing-type" onClick={() => handleFinancingType()}>Create</button>
                                    </div>
                                </div>
                            </div>

                            {
                                proyectoData.tiene_down_payment ? 
                                    <div className="col-md-2 form-group campo_dinero">
                                        <label className="font-weight-bold" onClick={() => newNote('Down payment')}>Down payment {proyectoData.tipo_down_payment === 0 || proyectoData.tipo_down_payment === '0' ? '%' : '$'}:</label>
                                        <div className="input-group">
                                            <CurrencyInput
                                                name="down_payment"
                                                className="form-control text-center"
                                                value={proyectoData.down_payment || ''}
                                                decimalsLimit={2}
                                                groupSeparator=","
                                                decimalSeparator="."
                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                disabled={formDisabled ? true : false}
                                            />
                                            <div className="input-group-append">
                                                <select className="browser-default custom-select" name="tipo_down_payment" value={proyectoData.tipo_down_payment} onChange={ e => onChange(e) }>
                                                    <option value="0">%</option>
                                                    <option value="1">$</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                proyectoData.informacion_detallada === false ? 
                                    <div className="col-md-5 form-group">
                                        <label className="font-weight-bold" onClick={() => newNote('Why don´t have information?')}>Why don´t have information?:</label>
                                        <input autoComplete='off' className="form-control" type="text" name="informacion_detallada_explicacion" onChange={ e => onChange(e) } value={proyectoData.informacion_detallada_explicacion}></input>
                                    </div> 
                                :
                                    null
                            }

                            {
                                proyectoData.multi_unidades === true && proyectoData.tiene_edificios === true ? 
                                    <div className="col-md-2 form-group campo_numero_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('Total buildings')}>Total buildings:</label>
                                        <input autoComplete='off' className="form-control text-center" type="number" name="edificios" min="1" onChange={ e => onChangeBuildings(e) } value={proyectoData.edificios.length || ''} disabled={formDisabled ? true : false}></input>
                                    </div>
                                :
                                    null
                            }

                            {
                                proyectoData.multi_unidades === true && proyectoData.tiene_edificios === true ? 
                                    <div className="col-md-2 form-group campo_numero_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('Total units')}>Total units:</label>
                                        <input autoComplete='off' className="form-control text-center" type="number" name="unidades_general" min="1" onChange={ e => onChange(e) } value={proyectoData.unidades_general} disabled={formDisabled ? true : false}></input>
                                    </div> 
                                :
                                    null
                            }

                            {
                                proyectoData.informacion_detallada === false && proyectoData.tiene_edificios === true ? 
                                    <div className="col-md-2 form-group campo_numero_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('SQFT')}>SQFT:</label>
                                        <CurrencyInput
                                            name="construccion_general"
                                            className="form-control text-center"
                                            value={proyectoData.construccion_general || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={formDisabled ? true : false}
                                        />
                                    </div> 
                                :
                                    null
                            }

                            {
                                (proyectoData.multi_unidades === true && proyectoData.tiene_edificios === true) || (proyectoData.tiene_edificios === false && proyectoData.multi_apn === false) ?
                                    <div className="col-md-2 form-group campo_numero_lg">
                                        <label className="font-weight-bold" onClick={() => newNote('LOT')}>LOT:</label>
                                        <div className="input-group">
                                            <CurrencyInput
                                                name="terreno"
                                                className="form-control text-center"
                                                value={proyectoData.terreno || ''}
                                                decimalsLimit={2}
                                                groupSeparator=","
                                                decimalSeparator="."
                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                disabled={formDisabled ? true : false}
                                            />
                                            <div className="input-group-append">
                                                <select className="browser-default custom-select" name="terreno_unidad" onChange={ e => onChange(e) } value={proyectoData.terreno_unidad || ''} disabled={formDisabled ? true : false}>
                                                    <option value="0">FT</option>
                                                    <option value="1">AC</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>

                    {
                        proyectoData.informacion_detallada === true && proyectoData.tiene_edificios === true ? 
                            <div className="row">
                                {
                                    buildingsRows(proyectoData.edificios)
                                }
                            </div>
                        :
                            null
                    }
                    
                    <div id="contenedor_mapa" className="row collapse mt-3" aria-labelledby="headingOne" data-parent="#contender_form">
                        <div className="col-md-8 offset-md-2 form-group">
                            <div id="mapa" className="mapa">
                                <GoogleMap
                                    mapContainerStyle={ContainerStyle}
                                    center={coord}
                                    zoom={16}
                                    onRightClick={e => { handleMapClick(e) }}
                                >
                                    <Marker key={coord.lat} position={coord} />
                                </GoogleMap>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12 mt-3">
                            <ul className="nav nav-tabs nav-fill">
                                {
                                    proyectoData.broker_represent === false && proyectoData.propietario_vende === false ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 4 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(4)}>Buyer broker</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }
                                {
                                    proyectoData.propietario_vende === false ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 2 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(2)}>Listing{ proyectoData.broker_represent === true ? ' & Buyer' : ''} broker</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }


                                {
                                    proyectoData.propietario_vende === true ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 6 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(6)}>Seller</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }

                                {
                                    proyectoData.compensaciones?.length > 0 ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 8 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(8)}>Compensations</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }

                                {
                                    proyectoData.multi_apn === true ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 10 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(10)}>APNS</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }

                                {
                                    proyectoData.propiedad_asignada === true ?
                                        <li className="nav-item">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 12 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(12)}>Vesting</button>
                                                </div>
                                            </div>
                                        </li>
                                    :
                                        null
                                }
                            </ul>
                        </div>
                    </div>

                    <div className={sectionInformation === 2 && proyectoData.propietario_vende === false ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        {/* Compañia */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Broker:</label>
                            <div className="input-group">
                                <Select
                                    name="id_broker_seller"
                                    className="select-group"
                                    aria-describedby="button-broker-seller"
                                    isClearable={true}
                                    options={brokersFilter}
                                    value={brokerSellerSelectData}
                                    onChange={(value) => onChangeSelectSearch(value, 'id_broker_seller', 'id_broker_agent_seller')}
                                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-9 form-group">
                            <label className="font-weight-bold">Address:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" value={viewBrokerSellerData.address} disabled/>
                                </div>
                            </div>
                        </div>

                        {/* Officer Name */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Agent:</label>
                            
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <Select
                                    name="id_broker_agent_seller"
                                    className="select-group"
                                    aria-describedby="button-broker-agent-seller"
                                    isClearable={true}
                                    options={brokersAgentsSellerFilter}
                                    value={brokerAgentSellerSelectData}
                                    onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_seller', '')}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerSellerData.email} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Cell Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewBrokerSellerData.cell_phone} disabled />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewBrokerSellerData.phone} disabled />
                            </div>
                        </div>

                        {/* License number */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerSellerData.license} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Public ID */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerSellerData.public} disabled />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={sectionInformation === 4 && proyectoData.propietario_vende === false ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        {/* Compañia */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Broker:</label>
                            <div className="input-group">
                                <Select
                                    name="id_broker_buyer"
                                    className="select-group"
                                    aria-describedby="button-broker-buyer"
                                    isClearable={true}
                                    options={brokersFilter}
                                    value={brokerBuyerSelectData}
                                    onChange={(value) => onChangeSelectSearch(value, 'id_broker_buyer', 'id_broker_agent_buyer')}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-9 form-group">
                            <label className="font-weight-bold">Address:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" value={viewBrokerBuyerData.address} disabled/>
                                </div>
                            </div>
                        </div>

                        {/* Officer Name */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Agent:</label>
                            
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <Select
                                    name="id_broker_agent_buyer"
                                    className="select-group"
                                    aria-describedby="button-broker-agent-buyer"
                                    isClearable={true}
                                    options={brokersAgentsBuyerFilter}
                                    value={brokerAgentBuyerSelectData}
                                    onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_buyer', '')}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerBuyerData.email} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Cell Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewBrokerBuyerData.cell_phone} disabled />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewBrokerBuyerData.phone} disabled />
                            </div>
                        </div>

                        {/* License number */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerBuyerData.license} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Public ID */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewBrokerBuyerData.public} disabled />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={sectionInformation === 6 && proyectoData.propietario_vende === true ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        {/* Vendedor */}
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Seller:</label>
                            <div className="input-group">
                                <Select
                                    name="id_seller_owner"
                                    className="select-group"
                                    aria-describedby="button-broker-buyer"
                                    isClearable={true}
                                    options={sellersFilter}
                                    value={sellerOwnerSelectData}
                                    onChange={(value) => onChangeSelectSearch(value, 'id_seller_owner', '')}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" id="button-broker-seller" onClick={() => handleSellerOwner()}>Create</button>
                                </div>
                            </div>
                        </div>

                        {/* Address */}
                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Address:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" value={viewSellerOwnerData.direccion} disabled/>
                                </div>
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input className="form-control" type="text" value={viewSellerOwnerData.email} disabled />
                                </div>
                            </div>
                        </div>

                        {/* Cell Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewSellerOwnerData.celular} disabled />
                            </div>
                        </div>

                        {/* Phone */}
                        <div className="col-md form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput className="form-control" country={'us'} value={viewSellerOwnerData.telefono} disabled />
                            </div>
                        </div>
                    </div>

                    <div className={sectionInformation === 8 && proyectoData.compensaciones.length > 0 ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        
                        <div className="col-md-12 form-group text-right">
                            <button type="button" className="btn btn-primary" onClick={() => handleAddCompensation()}><i className="fas fa-user fa-sm"></i> Add beneficiary</button>
                        </div>

                        { compensationsRows(proyectoData.compensaciones) }
                    </div>

                    <div className={sectionInformation === 10 && proyectoData.multi_apn === true ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        
                        <div className="col-md-12 form-group text-right">
                            <button type="button" className="btn btn-primary" onClick={() => handleAddApn()}><i className="fas fa-map fa-sm"></i> Add APN</button>
                        </div>

                        { apnsRows(proyectoData.apn, proyectoData.terreno, proyectoData.terreno_unidad, proyectoData.direcciones) }
                    </div>

                    <div className={sectionInformation === 12 ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                        <div className="col-md-12 form-group text-right">
                            <button type="button" className="btn btn-primary" onClick={() => handleAddVesting()}><i className="fas fa-map fa-sm"></i> Add vesting</button>
                        </div>

                        { vestingRows(proyectoData.id_vesting) }
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default EditarOferta;