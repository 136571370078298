import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

//Actions
import { agregarFileProyecto, externoArchivo } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const ModalFile = () => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);

    const [dataTabla, setDataTabla] = useState({
        proyectosTabla: null
    });

    const { proyectosTabla } = dataTabla;

    //Nuevo
    let nuevo_ref = createRef();
    
    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        nombre: null,
        archivo: null,
        extension: null
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const onChangeFileNuevo = () => {
        let nombre_separado = nuevo_ref.current.files[0].name.split('.');

        setDatosNuevo({
            ...datosNuevo,
            archivo: nuevo_ref.current.files[0],
            nombre: nombre_separado[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    const nuevoArchivo = async () => {

        if(datosNuevo.nombre !== null && datosNuevo.nombre !== ''){
            if(datosNuevo.archivo !== null){
                await setBotonNuevo(true);

                let formData = new FormData();
                formData.append('id_proyecto', datosNuevo.id_proyecto);
                formData.append('id_status', datosNuevo.id_status);
                formData.append('id_folder', datosNuevo.id_folder);
                formData.append('nombre', datosNuevo.nombre);
                formData.append('archivo', datosNuevo.archivo);
                formData.append('extension', datosNuevo.extension);

                await dispatch(agregarFileProyecto(formData));

                setDatosNuevo({
                    ...datosNuevo,
                    id_proyecto: null,
                    id_status: null,
                    id_folder: null,
                    nombre: null,
                    archivo: null,
                    extension: null
                });

                dispatch(externoArchivo(false));
            }else{
                dispatch(setAlert('First load a valid file', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid file name', 'danger'));
        }

        await setBotonNuevo(false);
    }

    const handleCloseNuevoArchivo = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            id_status: null,
            id_folder: null,
            nombre: null,
            archivo: null,
            extension: null
        });

        dispatch(externoArchivo(false));
    }

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosTabla: proyectos.listado
        });

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }, [proyectos]);

    return (
        <Modal show={proyectos.externo} onHide={() => {
            handleCloseNuevoArchivo();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>New file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Project:</label>
                        <select className="form-control" value={datosNuevo.id_proyecto || ''} name="id_proyecto" onChange={(e) => onChangeNuevo(e)}>
                            <option value={null}>General</option>
                            {
                                proyectosTabla?.length > 0 ?
                                    proyectosTabla?.map(proyecto => {

                                        let selected = false;

                                        if(String(proyectos.id_proyecto_dashboard) === String(proyecto._id)){
                                            selected = true;
                                        }

                                        return(
                                            <option key={proyecto._id} value={ proyecto._id } selected={ selected }>
                                                { proyecto.direccion }
                                            </option>
                                        )
                                    })
                                : ''
                            } 
                        </select>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Name of file:</label>
                        <input type="text" className="form-control" value={datosNuevo.nombre || ''} name="nombre" onChange={e => onChangeNuevo(e)} />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">File:</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input form-control" name="file" id="file" ref={nuevo_ref} onChange={onChangeFileNuevo} aria-describedby="view_file_file"/>
                                <label className="custom-file-label">{datosNuevo.archivo ? 'File Selected' : 'Choose file'}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevo.archivo)}><i class="fas fa-eye"></i> View File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevoArchivo()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={nuevoArchivo} disabled={botonNuevo}><i className="fas fa-check fa-sm"></i> Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFile;