import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editarBuyer, detallesBuyer } from '../../actions/buyers';

const EditarBuyer = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const buyers = useSelector(state => state.buyers);

    const [buyerData, setBuyerData] = useState({
        id: null,
        nombre: null,
        celular: null,
        telefono: null,
        email: null,
        direccion: null
    });

    const [spinner, setSpinner] = useState(false);

    const onChange = (e) => {
        setBuyerData({
            ...buyerData, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            dispatch(editarBuyer(buyerData));
            setSpinner(false);
            history.push('/configuration/buyers');
        }, 0);
    };

    useEffect(() => {
        dispatch(detallesBuyer(id));
    }, []);

    useEffect(() => {

        setBuyerData({
            ...buyerData,
            id: buyers.detalles?._id,
            nombre: buyers.detalles?.nombre
        });
    }, [buyers]);

    return (
        <form className="px-3">
            <div className="row pb-5">
                <div className="col-md-3">
                    <h3 className="mb-3 mt-5">Edit buyer</h3>
                </div>

                <div className="col-md-9 mt-5">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Save</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/buyers')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </div>

            {
                spinner ? 
                    <div className="col-12 mt-3 text-center d-flex justify-content-center">
                        <div className="sk-chase my-5">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                :
                    null
            }

            <div className="row">
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Name:</label>
                    <input type="text" className="form-control" name="nombre" onChange={e => onChange(e)} value={buyerData.nombre}></input>
                </div>
            </div>
        </form>
    )
}


export default EditarBuyer;