import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";
import html2canvas from 'html2canvas';
import ReactChipInput from "react-chip-input";
import Lightbox from "react-image-lightbox";
import DatePicker from 'react-datepicker';
import "react-image-lightbox/style.css";

import { setAlert } from '../../actions/alert';
import { buscarTiposProyectos, detallesProyecto, filesFoldersProyecto, agregarFileProyecto, agregarFolderProyecto, editarFileProyecto, editarFolderProyecto, detallesFileProyecto, detallesFolderProyecto, eliminarFileProyecto, eliminarFolderProyecto, compartirFilesProyecto, enviarFilesProyecto, limpiarLinkFilesProyecto } from '../../actions/proyectos';

const ContainerStyle = {
    width: '100%',
    height: '70vh'
};

const styles = {
    panel_tab: {
        backgroundColor: '#F3F2F2'
    },
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    tableHeader: {
        display: 'flex',
        backgroundColor: '#343A40',
        color: 'white',
    },
    textTableHeader: {
        padding: '2% 2%',
        marginBottom: '0'
    },
    buttonIcon: {
        fontSize: 18,
    },
    btnSnapShot: {
        cursor: 'pointer'
    }
}

const Files = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const { goBack } = useHistory();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);
    const detalles_carpeta = useSelector(state => state.proyectos?.detalles_folder);

    const [id_proyecto, setId_proyecto] = useState(null);
    const [id_folder, setId_folder] = useState(null);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [statusSaveFile, setStatusSaveFile] = useState(true);
    const [statusSaveEditFile, setStatusSaveEditFile] = useState(true);

    const [shareFilesModal, setShareFilesModal] = useState(false);
    const [newFileModal, setNewFileModal] = useState(false);
    const [newFolderModal, setNewFolderModal] = useState(false);
    const [editFileModal, setEditFileModal] = useState(false);
    const [editFolderModal, setEditFolderModal] = useState(false);
    const [actionsFileModal, setActionsFileModal] = useState(false);
    const [actionsFolderModal, setActionsFolderModal] = useState(false);
    const [snapshotModal, setSnapshotModal] = useState(false);
    const [imageModal, setImageModal] = useState(false);
    const [flag, setFlag] = useState(false);

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [shareFilesData, setShareFilesData] = useState({
        id_proyecto: null,
        todos: false,
        agregar: false,
        editar: false,
        eliminar: false,
        proyecto: null,
        ruta: null,
        archivos: [],
        carpetas: [],
        emails: [],
        vigencia: null
    });

    const [newFileData, setNewFileData] = useState({
        id_proyecto: '',
        id_status: null,
        id_folder: null,
        nombre: '',
        extension: '',
        archivo: null,
        url: '',
    });

    const [newFolderData, setNewFolderData] = useState({
        id_proyecto: '',
        id_folder: null,
        nombre: ''
    });

    const [editFileData, setEditFileData] = useState({
        id: null,
        nombre: '',
        extension: '',
        archivo: null,
        url: '',
    });

    const [editFolderData, setEditFolderData] = useState({
        id: null,
        nombre: ''
    });

    const [proyectoData, setProyectoData] = useState({
        listaStatus: [],
        direccion: '',
        latitud: '',
        longitud: '',
        imagen: '',
        imagen_2: ''
    })

    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });

    const { lat } = coord;

    const { loadingData, dataLoaded } = dataStatus;

    let file_ref = createRef();
    let file_edit_ref = createRef();

    //Centrar Mapas en direcciones de los proyectos
    const [mapcoords, setMapCoords] = useState({
        latitud: '',
        longitud: ''
    });

    const goBackProject = () => {
        history.goBack();
    }

    const publicTokens = id => {
        history.push('/projects/public_tokens/' + id);
    }

    //Compartir archivos
    const handleCompartirArchivos = () => {
        if(shareFilesData.archivos.length > 0){
            setShareFilesModal(true);

            setShareFilesData({
                ...shareFilesData,
                id_proyecto: id,
                ruta: process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id
            });
        }else{
            dispatch(setAlert('First select the files', 'danger'));
        }
    }

    const onChangeCheckbox = ({ target }) => {
        let id_sep = target.id.split('_');

        if(id_sep[1] === 0 || id_sep[1] === '0'){

            let ids_archivos = [];
            let ids_carpetas = [];

            for(let i = 0; i < proyectos.files.length; i++){
                ids_archivos.push(proyectos.files[i]._id);
            }

            for(let i = 0; i < proyectos.folders.length; i++){
                ids_carpetas.push(proyectos.folders[i]._id);
            }

            setShareFilesData({
                ...shareFilesData,
                todos: !shareFilesData.todos,
                archivos: target.checked ? ids_archivos : [],
                carpetas: target.checked ? ids_carpetas : []
            });
        }else{
            if(target.checked){
                if(String(id_sep[0]) === 'checkfolder'){
                    setShareFilesData({
                        ...shareFilesData,
                        carpetas: [...shareFilesData.carpetas, id_sep[1]]
                    });
                }else{
                    setShareFilesData({
                        ...shareFilesData,
                        archivos: [...shareFilesData.archivos, id_sep[1]]
                    });
                }                    
            }else{
                if(String(id_sep[0]) === 'checkfolder'){
                    setShareFilesData({
                        ...shareFilesData,
                        todos: false,
                        carpetas: shareFilesData.carpetas.filter( carpeta_id => String(carpeta_id) !== String(id_sep[1]) )
                    });
                }else{
                    setShareFilesData({
                        ...shareFilesData,
                        todos: false,
                        archivos: shareFilesData.archivos.filter( archivo_id => String(archivo_id) !== String(id_sep[1]) )
                    });
                }
            }
        }
    };

    const onChangeCheckboxPermission = ({ target }) => {
        setShareFilesData({
            ...shareFilesData,
            [target.name]: target.checked
        });
    };

    const handleAgregarEmail = email => {
        setShareFilesData({
            ...shareFilesData,
            emails: [...shareFilesData.emails, email]
        });
    }

    const handleEliminarEmail = posicion => {
        setShareFilesData({
            ...shareFilesData,
            emails: shareFilesData.emails.filter( (email, index) => index !== posicion )
        });
    }

    const handleObtenerLink = () => {
        if(shareFilesData.vigencia !== null){
            dispatch(compartirFilesProyecto(shareFilesData));
        }else{
            dispatch(setAlert('First enter a valid duration date', 'danger'));
        }
    }

    const handleCopiarLink = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id + '/' + proyectos.link_files);
        dispatch(setAlert('Share link coppied', 'primary'));
    }

    const handleEnviarLink = () => {
        if(shareFilesData.emails.length > 0){
            if(shareFilesData.vigencia !== null){
                dispatch(enviarFilesProyecto(shareFilesData));
            }else{
                dispatch(setAlert('First enter a valid duration date', 'danger'));
            }
        }else{
            dispatch(setAlert('First add emails', 'danger'));
        }
    }

    const handleCloseCompartirArchivo = () => {
        setShareFilesModal(false);
    }

    //Agregar archivo
    const handleAgregarArchivo = id => {
        setNewFileModal(true);
    }

    const onChangeFile = () => {
        let nombre_separado = file_ref.current.files[0].name.split('.');

        setNewFileData({
            ...newFileData,
            nombre: nombre_separado[0],
            archivo: file_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase(),
            url: URL.createObjectURL(file_ref.current.files[0])
        });
    }

    const handleInputs = ({ target }) => {
        setNewFileData({
            ...newFileData,
            [target.name]: target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);


                    setNewFileData({
                        ...newFileData,
                        id_status: null,
                        nombre: '',
                        archivo: '',
                        extension: ''
                    });
                    setNewFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseAgregarArchivo = () => {
        setNewFileData({
            ...newFileData,
            nombre: '',
            archivo: '',
            extension: ''
        });
        setNewFileModal(false);
    }

    //Agregar carpeta
    const handleAgregarCarpeta = id => {
        setNewFolderModal(true);
    }

    const handleInputsCarpeta = ({ target }) => {
        setNewFolderData({
            ...newFolderData,
            [target.name]: target.value
        });
    }

    const handleGuardarNuevaCarpeta = async (e) => {
        e.preventDefault();
        try {
            if(newFolderData.nombre !== ''){

                await dispatch(agregarFolderProyecto(newFolderData));

                setNewFolderData({
                    ...newFolderData,
                    nombre: ''
                });
                setNewFolderModal(false);
            }else{
                dispatch(setAlert('Folder name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseAgregarCarpeta = () => {
        setNewFolderData({
            ...newFolderData,
            nombre: ''
        });
        setNewFolderModal(false);
    }

    //Editar archivo
    const handleEditFile = async () => {
        await setEditFileModal(true);

        await setEditFileData({
            ...editFileData,
            id: detalles_archivo._id,
            nombre: detalles_archivo.nombre,
            extension: detalles_archivo.extension
        });
    }

    const handleInputsEditFile = ({ target }) => {
        setEditFileData({
            ...editFileData,
            [target.name]: target.value
        });
    }

    const onChangeEditFile = () => {
        let nombre_separado = file_edit_ref.current.files[0].name.split('.');

        setEditFileData({
            ...editFileData,
            archivo: file_edit_ref.current.files[0],
            nombre: nombre_separado[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase(),
            url: URL.createObjectURL(file_edit_ref.current.files[0])
        });
    }

    const handleGuardarEditarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(editFileData.extension !== ''){
                if(editFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id', editFileData.id);
                    formData.append('nombre', editFileData.nombre);
                    formData.append('extension', editFileData.extension);
                    formData.append('archivo', editFileData.archivo);

                    setStatusSaveEditFile(false);
                    await dispatch(editarFileProyecto(formData));
                    setStatusSaveEditFile(true);

                    setEditFileData({
                        ...editFileData,
                        id: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setEditFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseEditarArchivo = () => {
        setEditFileModal(false);
        setEditFileData({
            ...editFileData,
            id: null,
            nombre: '',
            extension: '',
            archivo: null
        });
    }    

    //Editar carpeta
    const handleEditFolder = async () => {
        await setEditFolderModal(true);

        await setEditFolderData({
            ...editFolderData,
            id: detalles_carpeta._id,
            nombre: detalles_carpeta.nombre
        });
    }

    const handleInputsEditFolder = ({ target }) => {
        setEditFolderData({
            ...editFolderData,
            [target.name]: target.value
        });
    }

    const handleGuardarEditarCarpeta = async (e) => {
        e.preventDefault();
        try {
            if(editFolderData.nombre !== ''){

                await dispatch(editarFolderProyecto(editFolderData));
                await dispatch(detallesFolderProyecto(editFolderData.id));

                setEditFolderData({
                    ...editFolderData,
                    id: null,
                    nombre: ''
                });

                setEditFolderModal(false);
            }else{
                dispatch(setAlert('File name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseEditarCarpeta = () => {
        setEditFolderModal(false);
        setEditFolderData({
            ...editFolderData,
            id: null,
            nombre: ''
        });
    }

    //Eliminar archivo
    const handleDeleteFile = async () => {
        await dispatch(eliminarFileProyecto(detalles_archivo._id));
        await setActionsFileModal(false);
    }

    //Eliminar carpeta
    const handleDeleteFolder = async () => {
        await dispatch(eliminarFolderProyecto(detalles_carpeta._id));
        await setActionsFolderModal(false);
    }

    //Acciones generales archivo
    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyecto(id));
    }

    const handleAccionesArchivo = async (id) => {
        await dispatch(detallesFileProyecto(id));
        await setActionsFileModal(true);
    }

    const handleCloseAccionesArchivo = () => {
        setActionsFileModal(false);
    }

    const handleCloseSnapshotModal = () => {
        setProyectoData({
            ...proyectoData, 
            imagen: '',
            imagen_2: ''
        });

        setNewFileData({
            ...newFileData,
            id_status: null,
            extension: '',
            archivo: '',
            nombre: ''
        })
        setSnapshotModal(false);
    }   

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setActionsFileModal(false);
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            setActionsFileModal(false);
            window.open(detalles_archivo.url);
        }
    }

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    }

    const handleTakeSnapShot = async () => {
        html2canvas(document.querySelector("#mapa"), {useCORS: true}).then(async (canvas) => {
            await canvas.toBlob(function(blob) {
                setProyectoData({
                    ...proyectoData, 
                    imagen_2: URL.createObjectURL(blob)
                });
                setNewFileData({
                    ...newFileData,
                    extension: 'jpg',
                    archivo: blob
                })
                
            });

            await handleImageModal();
        });
    }

    const handleImageModal = () => {
        setSnapshotModal(false);
        setImageModal(true);
    }

    const handleTakeAnotherSnapModal = () => {
        setImageModal(false);
        setProyectoData({
            ...proyectoData, imagen_2: ''
        });
        setSnapshotModal(true);
    }

    const handleAcceptImage = async () => {
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);

                    setImageModal(false);

                    setNewFileData({
                        ...newFileData,
                        id_status: null,
                        nombre: '',
                        archivo: '',
                        extension: ''
                    });
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Acciones generales carpeta
    const handleAbrirCarpeta = async (id) => {
        handleCloseAccionesCarpeta();
        if(id === null || id === 'null'){
            await history.push('/projects/files/' + id_proyecto);
        }else{
            await history.push('/projects/files/' + id_proyecto + '_'+ id);
        }
    }

    const handleAccionesCarpeta = async (id) => {
        await dispatch(detallesFolderProyecto(id));
        await setActionsFolderModal(true);
    }

    const handleCloseAccionesCarpeta = () => {
        setActionsFolderModal(false);
    }
    
    const handleViewFile = (url) => {
        if(url !== ''){
            console.log(url);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    //Filas
    const tableRowRutasFolders = (items) => {        
        return (
            <Fragment>
                {
                    items?.map((item, key) => {

                        if(item.activo === false){
                            return (
                                <Fragment key={item._id}>
                                    <li key={'carpeta_' + key} className="breadcrumb-item"><a href="#" onClick={() => {handleAbrirCarpeta(item.id)}}>{item.nombre}</a></li>
                                </Fragment>
                            );
                        }else{
                            return (
                                <Fragment key={item._id}>
                                    <li key={'carpeta_' + key} className="breadcrumb-item active" aria-current="page">{item.nombre}</li>
                                </Fragment>
                            );
                        }
                    })
                }
            </Fragment>
        );
    }

    const tableRowFolders = (items) => {        
        return (
            <Fragment>
                {
                    items?.map( (item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>
                                        <input className="checkdui" type="checkbox" checked={shareFilesData.carpetas.includes(item._id)} id={'checkfolder_' + item._id} onChange={(e) => onChangeCheckbox(e)}></input>
                                    </td>
                                    <td>{item.nombre}</td>
                                    <td>-</td>
                                    <td>
                                        <button className="btn" onClick={() => {handleAbrirCarpeta(item._id)}}>
                                            <i className="fa fa-folder" style={styles.buttonIcon} /> folder 
                                        </button>
                                    </td>
                                    <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleAccionesCarpeta(item._id);
                                        }}>Options</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        );
    }

    const tableRowFiles = (items) => {

        let extension = 'file';
        
        return (
            <Fragment>
                {
                    items?.map( (item) => {

                        switch(item.extension){
                            case 'pdf':
                                extension = 'file-pdf';
                                break;

                            case 'jpeg':
                            case 'jpg':
                            case 'png':
                            case 'gif':
                                extension = 'file-image';
                                break;

                            case 'mp4':
                            case 'wmv':
                            case 'mov':
                                extension = 'file-video';
                                break;

                            case 'mp3':
                            case 'm4a':
                            case 'aac':
                                extension = 'file-audio';
                                break;

                            case 'doc':
                            case 'docx':
                                extension = 'file-word';
                                break;

                            case 'xls':
                            case 'xlsx':
                                extension = 'file-excel';
                                break;

                            default:
                                extension = 'file';
                                break;
                        }

                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>
                                        <input className="checkdui" type="checkbox" checked={shareFilesData.archivos.includes(item._id)} id={'checkfile_' + item._id} onChange={(e) => onChangeCheckbox(e)}></input>
                                    </td>
                                    <td>{item.nombre}</td>
                                    <td>{item.seccion}</td>
                                    <td>
                                        <button className="btn" onClick={() => {handleIconoArchivo(item._id)}}>
                                            <i className={'fa fa-' + extension} style={styles.buttonIcon} /> {item.extension} 
                                        </button>
                                    </td>
                                    <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            setFlag(false);
                                            handleAccionesArchivo(item._id);
                                        }}>Options</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        );
    }

    useEffect(() => {

        dispatch(buscarTiposProyectos(null));

        dispatch(limpiarLinkFilesProyecto());

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        let ids_separados = id.split('_');

        if(ids_separados.length === 1){
            setId_proyecto(ids_separados[0]);
            setId_folder(null);
        }else{
            if(ids_separados.length === 2){
                setId_proyecto(ids_separados[0]);
                setId_folder(ids_separados[1]);
            }else{
                setId_proyecto(null);
                setId_folder(null);
            }
        }
    }, [id]);

    useEffect(() => {
        console.log(id_proyecto + ' : ' + id_folder);
        if(id_proyecto !== null){
            dispatch(detallesProyecto(id_proyecto));
        }

        dispatch(filesFoldersProyecto({
            id_proyecto: id_proyecto,
            id_folder: id_folder
        }));

        setNewFileData({
            ...newFileData,
            id_proyecto: id_proyecto,
            id_folder: id_folder
        });

        setNewFolderData({
            ...newFolderData,
            id_proyecto: id_proyecto,
            id_folder: id_folder
        });

        setShareFilesData({
            ...shareFilesData,
            id_proyecto: id_proyecto,
            ruta: process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id_proyecto
        });
    }, [id_proyecto, id_folder]);

    useEffect(() => {
        if(detalles?.proyecto){
            setMapCoords({
                lat: Number(detalles?.proyecto?.latitud),
                lng: Number(detalles?.proyecto?.longitud),
            })
        }
    }, [detalles]);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [proyectos]);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {
            setProyectoData({
                ...proyectoData,
                listaStatus: detalles?.proyecto?.lista_status_proyecto
            });

            setShareFilesData({
                ...shareFilesData,
                proyecto: detalles?.proyecto?.direccion
            });
        }
    }, [detalles]);

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Shared folder</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => { goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                <div className="btn btn-group dropdown float-right mb-3 ml-3 p-0" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Options
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item text-primary" onClick={() => handleCompartirArchivos()}>
                            Share elements
                        </button>
                        <button className="dropdown-item text-danger" onClick={() => publicTokens(id)}>
                            Public tokens
                        </button>
                    </div>
                </div>
                <button className="btn btn-info float-right ml-3" onClick={() => handleAgregarCarpeta(id)}>
                    <i className="fas fa-file-medical fa-sm"></i> Create folder
                </button>

                <button className="btn btn-info float-right ml-3" onClick={() => handleAgregarArchivo(id)}>
                    <i className="fas fa-file-medical fa-sm"></i> Upload new file
                </button>                
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>
                            {/* Modal de nuevo archivo */}
                            <Modal backdrop="static" show={newFileModal} size="lg" onHide={() => {
                                setNewFileModal(false);
                                setNewFileData({
                                    ...newFileData,
                                    nombre: '',
                                    archivo: '',
                                    extension: ''
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New file to upload</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Select the file from your computer, name it, and <b>save it</b>.</p>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">File name:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputs(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">File:</label> <br></br>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="newFile" id="newFile" ref={file_ref} onChange={onChangeFile} aria-describedby="view_file_newFile"/>
                                                    <label className="custom-file-label">{newFileData.url ? 'Choose file' : 'File Selected'}</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newFileData.url)}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>      

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Section:</label> <br></br>
                                            <select className="custom-select" name="id_status" onChange={(e) => {handleInputs(e)}}>
                                                <option value={null}>General</option>
                                                {
                                                    proyectoData.listaStatus?.map(status => (
                                                        <option key={status.id_status} value={status.id_status}>{status.nombre}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>                                        
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAgregarArchivo()} disabled={statusSaveFile ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleSubmit} disabled={statusSaveFile ? false : true}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de nueva carpeta */}
                            <Modal backdrop="static" show={newFolderModal} size="md" onHide={() => {
                                setNewFolderModal(false);
                                setNewFolderData({
                                    ...newFileData,
                                    nombre: ''
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New folder to create</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Folder name:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsCarpeta(e)} value={newFolderData.nombre || ''}/>
                                            </div>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAgregarCarpeta()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleGuardarNuevaCarpeta}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de editar archivo */}
                            <Modal backdrop="static" show={editFileModal} size="lg" onHide={() => {
                                handleCloseEditarArchivo();
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit file</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Select the file from your computer, name it, and <b>save it</b>.</p>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">File name:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFile(e)} value={editFileData.nombre || ''} disabled={statusSaveEditFile ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">File:</label> <br></br>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={file_edit_ref} onChange={onChangeEditFile} aria-describedby="view_editFile"/>
                                                    <label className="custom-file-label">{editFileData.archivo ? 'Choose file' : 'File Selected'}</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editFileData.url)}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEditarArchivo()} disabled={statusSaveEditFile ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleGuardarEditarArchivo} disabled={statusSaveEditFile ? false : true}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de editar carpeta */}
                            <Modal backdrop="static" show={editFolderModal} size="md" onHide={() => {
                                handleCloseEditarCarpeta();
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit folder</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Folder name:</label>
                                            <div className="contenedor_datepicker">
                                                <input name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFolder(e)} value={editFolderData.nombre || ''}/>
                                            </div>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEditarCarpeta()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleGuardarEditarCarpeta}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de acciones de archivo */}
                            <Modal backdrop="static" show={actionsFileModal} onHide={() => {
                                setActionsFileModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Actions of the file</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Select one of the following options.</p>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAccionesArchivo()}><i className="fas fa-times fa-sm"></i> Close</button>
                                    <button className="btn btn-warning" onClick={() => handleEditFile()}><i className="fas fa-edit fa-sm"></i> Edit</button>
                                    <button className="btn btn-warning" onClick={() => handleDeleteFile()}><i className="fas fa-eraser fa-sm"></i> Delete</button>
                                    <button className="btn btn-primary" onClick={() => handleOpenFile()}><i className="fas fa-file fa-sm"></i> Open</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de acciones de carpeta */}
                            <Modal backdrop="static" show={actionsFolderModal} onHide={() => {
                                setActionsFolderModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Actions of the folder</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Select one of the following options. This folder have {detalles_carpeta?.carpetas || 0} folder{detalles_carpeta?.carpetas === 1 || detalles_carpeta?.carpetas === '1' ? '' : 's'} and {detalles_carpeta?.archivos || 0} file{detalles_carpeta?.archivos === 1 || detalles_carpeta?.archivos === '1' ? '' : 's'} inside.</p>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAccionesCarpeta()}><i className="fas fa-times fa-sm"></i> Close</button>
                                    <button className="btn btn-warning" onClick={() => handleEditFolder()}><i className="fas fa-edit fa-sm"></i> Edit</button>
                                    <button className="btn btn-warning" onClick={() => handleDeleteFolder()}><i className="fas fa-eraser fa-sm"></i> Delete</button>
                                    <button className="btn btn-primary" onClick={() => handleAbrirCarpeta(detalles_carpeta?._id)}><i className="fas fa-file fa-sm"></i> Open</button>
                                </Modal.Footer>
                            </Modal>

                            {imageData.modal && (
                                <Lightbox
                                    mainSrc={imageData.src}
                                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                                />
                            )}

                            {/* Modal TakeSnapshot */}
                            <Modal backdrop="static" show={snapshotModal} size="lg" onHide={() => {
                                setSnapshotModal(false);
                            }}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="col-md-12 form-group">
                                    <div id="mapa" className="mapa">
                                        <GoogleMap
                                            mapContainerStyle={ContainerStyle}
                                            center={mapcoords}
                                            zoom={16}
                                            onRightClick={e => {handleMapClick(e)}}
                                        >
                                            <Marker key={lat} position={mapcoords} />
                                        </GoogleMap>
                                    </div>
                                </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={() => {handleTakeSnapShot()}}><i className="fas fa-camera fa-sm"></i> Take Snapshot</button>
                                    <button className="btn btn-danger" onClick={() => handleCloseSnapshotModal()}><i className="fas fa-times fa-sm"></i> Close</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal imagen de snapshot */}
                            <Modal backdrop="static" show={imageModal} centered onHide={() => {
                                setImageModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Street view image</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputs(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">Section:</label> <br></br>
                                        <select className="custom-select" name="id_status" onChange={(e) => {handleInputs(e)}}>
                                            <option value={null}>General</option>
                                            {
                                                proyectoData.listaStatus?.map(status => (
                                                    <option key={status.id_status} value={status.id_status}>{status.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div id="contenedor_snapshot" className="col-12 text-center">
                                            <img src={proyectoData.imagen_2 !== '' ? proyectoData.imagen_2 : '/placeholder.png'} className="picture-src" id="wizardPicturePreview" title=""/>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger mr-3" onClick={() => {handleTakeAnotherSnapModal()}}><i className="fas fa-camera fa-sm"></i> Take another snapshot</button>
                                    <button className="btn btn-secondary" onClick={() => handleAcceptImage()}><i className="fas fa-check fa-sm"></i> Accept</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de compartir archivos */}
                            <Modal backdrop="static" show={shareFilesModal} size="lg" onHide={() => {
                                setShareFilesModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Files to share</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Share the access <b>link</b> or send it to the <b>emails</b> you want:</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Duration Date:</label>
                                            <div className='d-flex'>
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </span>
                                                <DatePicker 
                                                    name="files_duration_date" 
                                                    dateFormat="MM/dd/yyyy" 
                                                    className="form-control text-center" 
                                                    selected={shareFilesData.vigencia !== null ? shareFilesData.vigencia : null} 
                                                    onChange={date => {setShareFilesData({...shareFilesData, vigencia: date})}} 
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Add permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label className="container text-center">
                                                    <input type="checkbox" name="agregar" checked={shareFilesData.agregar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Edit permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label className="container text-center">
                                                    <input type="checkbox" name="editar" checked={shareFilesData.editar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Delete permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label className="container text-center">
                                                    <input type="checkbox" name="eliminar" checked={shareFilesData.eliminar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Emails:</label>
                                            <div className="contenedor_datepicker">
                                                <ReactChipInput
                                                    chips={shareFilesData.emails}
                                                    onSubmit={value => handleAgregarEmail(value)}
                                                    onRemove={index => handleEliminarEmail(index)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            proyectos.link_files !== null ?
                                                <div className="col-md-12">
                                                    <label className="font-weight-bold">Share link:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" value={process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id + '/' + proyectos.link_files} disabled></input>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="basic-addon1" onClick={() => handleCopiarLink()}>
                                                                <i className="fas fa-copy"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseCompartirArchivo()} disabled={statusSaveFile ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={() => handleObtenerLink()} disabled={statusSaveFile ? false : true}><i className="fas fa-link fa-sm"></i> Get share link</button>
                                    <button className="btn btn-success" onClick={() => handleEnviarLink()} disabled={statusSaveFile ? false : true}><i className="fas fa-paper-plane fa-sm"></i> Send share link</button>
                                </Modal.Footer>
                            </Modal>

                            <div className="col-md-12  my-4">
                                <div className="row form-group">
                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + detalles?.proyecto?.latitud + '%2C' + detalles?.proyecto?.longitud} target="_blank">{detalles?.proyecto?.direccion}</a>
                                    </label>
                                </div>
                            </div>
                            
                            <div className="col-md-12 pl-0 pr-4">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        {tableRowRutasFolders(proyectos.rutas_folders)}
                                    </ol>
                                </nav>
                            </div>

                            {
                                proyectos.folders?.length > 0 || proyectos.files?.length > 0 ?
                                    <div className="col-md-12 row">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input className="checkdui" type="checkbox" checked={shareFilesData.todos} id="checkfile_0" onChange={(e) => onChangeCheckbox(e)}></input>
                                                        </th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Section</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Upload date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableRowFolders(proyectos.folders)}
                                                    {tableRowFiles(proyectos.files)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-10 offset-md-1 text-center mt-4">
                                        <h3>No files or folders founded.</h3>
                                        <button className="btn btn-primary mt-2" onClick={goBack}>
                                            <i className="fas fa-arrow-left fa-sm"></i> Go back
                                        </button>
                                    </div>
                            }
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default Files;