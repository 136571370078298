import { BUSCAR_FILTROS_PROYECTOS } from '../actions/types';

const initialState = {
    proyectos: [],
    construcciones: [],
    status: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        case BUSCAR_FILTROS_PROYECTOS:

            return {
                ...state,
                proyectos: payload.proyectos,
                construcciones: payload.construcciones,
                status: payload.status
            };
        default:
            return state;
    }
}