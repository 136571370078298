import {
    ACTUALIZAR_DETALLES,
    VALIDAR_PROYECTO,
    AGREGAR_OFERTA,
    AGREGAR_SECCION,
    BUSCAR_OFERTAS,
    FILTRAR_OFERTAS, 
    LISTAR_PROYECTOS,
    BUSCAR_PROYECTOS,
    BUSCAR_FILTROS_PROYECTOS,
    FILTRAR_PROYECTOS,
    HABILITAR_EDICION_PROYECTO,
    EXTERNO_ARCHIVO_PROYECTO,
    ARCHIVOS_PROYECTO,
    PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
    EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
    DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
    AGREGAR_ARCHIVO_PROYECTO,
    AGREGAR_CARPETA_PROYECTO,
    DETALLES_ARCHIVO_PROYECTO,
    DETALLES_CARPETA_PROYECTO,
    EDITAR_ARCHIVO_PROYECTO,
    EDITAR_CARPETA_PROYECTO,
    ELIMINAR_ARCHIVO_PROYECTO,
    ELIMINAR_CARPETA_PROYECTO,
    COMPARTIR_LINK_ARCHIVOS,
    COMPARTIR_LINK_REPORTE,
    EXTERNO_ARCHIVO,
    EXTERNO_NOTA,
    NOTAS_PROYECTO,
    AGREGAR_NOTA_PROYECTO,
    EDITAR_NOTA_PROYECTO, 
    DETALLES_NOTA_PROYECTO, 
    ELIMINAR_NOTA_PROYECTO,
    INSPECCIONES_PROYECTO,
    AGREGAR_INSPECCION_PROYECTO,
    EDITAR_INSPECCION_PROYECTO,
    ELIMINAR_INSPECCION_PROYECTO,
    DETALLES_INSPECCION_PROYECTO,
    EXTERNO_INSPECCION,
    MATERLIST_PROYECTO,
    SELECCIONAR_SECCION_MASTERLIST,
    CHECKLIST_PROYECTO,
    EDITAR_SECCION,
    EDITANDO_PROYECTO,
    ELIMINAR_PROYECTO,
    EXTERNO_ELIMINAR, 
    DETALLES_PROYECTO,
    OBTENER_CALENDARIO,
    ELIMINAR_SECCION,
    ELIMINAR_TAREA,
    AGREGAR_TAREA,
    SELECCIONAR_PROYECTO_DASHBOARD,
    REPARACIONES_PROYECTO,
    AGREGAR_REPARACION_PROYECTO,
    EDITAR_REPARACION_PROYECTO,
    ELIMINAR_REPARACION_PROYECTO,
    DETALLES_REPARACION_PROYECTO
} from './types';

import { setAlert } from './alert';
import axios from 'axios';

export const validarProyecto = (id, direccion) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, direccion });
    
    try {
        if(!id && !direccion){
            dispatch({
                type: VALIDAR_PROYECTO,
                payload: null
            });
        }else{
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/validar`, body, config);

            dispatch({
                type: VALIDAR_PROYECTO,
                payload: res.data.proyecto
            });
        }
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarOferta = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/nueva_oferta`, proyecto, config);
        dispatch({
            type: AGREGAR_OFERTA,
            payload: res.data.oferta
        });    
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch({
            type: VALIDAR_PROYECTO,
            payload: null
        });

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarProyecto = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/nuevo_proyecto`, proyecto, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        dispatch({
            type: VALIDAR_PROYECTO,
            payload: null
        });

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editProject = () => async dispatch => {
    dispatch({
        type: HABILITAR_EDICION_PROYECTO
    });
}

export const editandoProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_proyecto`, body, config);
        dispatch({
            type: EDITANDO_PROYECTO,
            payload: {
                id: id,
                proyecto: res.data.proyecto
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarOferta = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_oferta`, proyecto, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarProyecto = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_proyecto`, proyecto, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const activarOferta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/activar`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const desactivarOferta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/desactivar`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externoEliminar = (id, valor) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    
        const body = {
            id_proyecto: id
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_proyecto`, body, config);

            await dispatch({
                type: DETALLES_PROYECTO,
                payload: {
                    id: id,
                    proyecto: res.data.proyecto
                }
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }

    await dispatch({
        type: EXTERNO_ELIMINAR,
        payload: valor
    });
}

export const eliminarProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_PROYECTO,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const actualizarDetalles = (detalles) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(detalles);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/actualizar_checklist`, body, config);
        dispatch({
            type: ACTUALIZAR_DETALLES,
            payload: res.data.checklist
        });    
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarOfertas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/projects/ofertas`, config);
        dispatch({
            type: BUSCAR_OFERTAS,
            payload: {
                ofertas: res.data.ofertas
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const filtrarOfertas = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    console.log(body);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/filtro_ofertas`, body, config);
        dispatch({
            type: FILTRAR_OFERTAS,
            payload: res.data.ofertas
        });
        if (res.data.ofertas.length > 0) {
            dispatch(setAlert('Offers list updated', 'success'));
        } else {
            dispatch(setAlert('No offers found', 'warning'));
        }
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const listarProyectos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/projects/listado`, config);
        dispatch({
            type: LISTAR_PROYECTOS,
            payload: {
                proyectos: res.data.proyectos
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarProyectos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/projects/proyectos`, config);
        dispatch({
            type: BUSCAR_PROYECTOS,
            payload: {
                proyectos: res.data.proyectos
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const filtrarProyectos = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/filtro_proyectos`, body, config);
        dispatch({
            type: FILTRAR_PROYECTOS,
            payload: res.data.proyectos
        });
        if (res.data.proyectos.length > 0) {
            dispatch(setAlert('Projects list updated', 'success'));
        } else {
            dispatch(setAlert('No projects found', 'warning'));
        }
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarTiposProyectos = (tipo_proyecto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        let res;

        if(!tipo_proyecto){
            res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/types/projects`, config);
        }else{
            const body = JSON.stringify({ tipo_proyecto });

            res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/types/projects_filtered`, body, config);
        }

        dispatch({
            type: BUSCAR_FILTROS_PROYECTOS,
            payload: res.data
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Archivos */
export const externoArchivoProyecto = (valor) => async dispatch => {
    dispatch({
        type: EXTERNO_ARCHIVO_PROYECTO,
        payload: valor
    });
}

export const filesFoldersProyecto = ({id_proyecto, id_folder}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto,
        id_folder
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_archivos`, body, config);

        dispatch({
            type: ARCHIVOS_PROYECTO,
            payload: {
                files: res.data.files,
                folders: res.data.folders,
                rutas_folders: res.data.rutas_folders
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const filesProyectoPublico = (id, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id,
        token
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_archivos_publico`, body, config);
        dispatch({
            type: ARCHIVOS_PROYECTO,
            payload: {
                files: res.data.files
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const filesProyectoSeccion = (id, id_seccion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id,
        id_status: id_seccion
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_archivos_seccion`, body, config);
        dispatch({
            type: ARCHIVOS_PROYECTO,
            payload: {
                files: res.data.files
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarFileProyecto = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/agregar_archivo`, archivo, config);
        dispatch({
            type: AGREGAR_ARCHIVO_PROYECTO,
            payload: {
                file: res.data.file
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarFolderProyecto = (carpeta) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(carpeta);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/agregar_carpeta`, body, config);
        dispatch({
            type: AGREGAR_CARPETA_PROYECTO,
            payload: {
                folder: res.data.folder
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesFileProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_archivo`, body, config);
        dispatch({
            type: DETALLES_ARCHIVO_PROYECTO,
            payload: res.data.file
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesFolderProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_carpeta`, body, config);
        dispatch({
            type: DETALLES_CARPETA_PROYECTO,
            payload: res.data.folder
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesFileProyectoPublico = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_archivo_publico`, body, config);
        dispatch({
            type: DETALLES_ARCHIVO_PROYECTO,
            payload: res.data.file
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarFileProyecto = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_archivo`, archivo, config);
        dispatch({
            type: EDITAR_ARCHIVO_PROYECTO,
            payload: {
                file: res.data.file
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarFolderProyecto = (carpeta) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(carpeta);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_carpeta`, body, config);
        dispatch({
            type: EDITAR_CARPETA_PROYECTO,
            payload: {
                folder: res.data.folder
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarFileProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_archivo`, body, config);
        dispatch({
            type: ELIMINAR_ARCHIVO_PROYECTO,
            payload: id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarFolderProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_carpeta`, body, config);
        dispatch({
            type: ELIMINAR_CARPETA_PROYECTO,
            payload: id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const compartirFilesProyecto = (archivos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(archivos);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/compartir_archivos`, body, config);
        dispatch({
            type: COMPARTIR_LINK_ARCHIVOS,
            payload: res.data.id_permiso
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const enviarFilesProyecto = (archivos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(archivos);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/enviar_archivos`, body, config);
        dispatch({
            type: COMPARTIR_LINK_ARCHIVOS,
            payload: res.data.id_permiso
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const publicTokensProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_tokens_publicos`, body, config);
        dispatch({
            type: PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
            payload: {
                tokens: res.data.tokens
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const reenviarFilesProyecto = (token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(token);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/reenviar_archivos`, body, config);

        dispatch({
            type: EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
            payload: res.data.permiso
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTokenArchivos = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_token_archivos`, body, config);
        
        dispatch({
            type: DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO,
            payload: id
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const limpiarLinkFilesProyecto = () => async dispatch => {
    dispatch({
        type: COMPARTIR_LINK_ARCHIVOS,
        payload: null
    });
}

export const externoArchivo = (valor) => async dispatch => {
    dispatch({
        type: EXTERNO_ARCHIVO,
        payload: valor
    });
}

/* Notas */
export const externoNota = (valor, campo) => async dispatch => {
    dispatch({
        type: EXTERNO_NOTA,
        payload: {
            valor,
            campo
        }
    });
}

export const notesProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_notas`, body, config);
        dispatch({
            type: NOTAS_PROYECTO,
            payload: res.data.notes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarNoteProyecto = (nota) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/agregar_nota`, nota, config);

        dispatch({
            type: AGREGAR_NOTA_PROYECTO,
            payload: res.data.note
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarNoteProyecto = (nota) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_nota`, nota, config);
        dispatch({
            type: EDITAR_NOTA_PROYECTO,
            payload: res.data.note
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesNoteProyecto = (id_nota) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        id_nota
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_nota`, body, config);

        dispatch({
            type: DETALLES_NOTA_PROYECTO,
            payload: res.data.nota
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarNoteProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_nota`, body, config);

        dispatch({
            type: ELIMINAR_NOTA_PROYECTO,
            payload: id
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarNotasProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_notas`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Inspecciones */
export const inspectionsProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_inspecciones`, body, config);
        dispatch({
            type: INSPECCIONES_PROYECTO,
            payload: {
                inspections: res.data.inspections
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarInspectionProyecto = (inspeccion) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/agregar_inspeccion`, inspeccion, config);
        dispatch({
            type: AGREGAR_INSPECCION_PROYECTO,
            payload: {
                inspection: res.data.inspection
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarInspectionProyecto = (inspeccion) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_inspeccion`, inspeccion, config);
        dispatch({
            type: EDITAR_INSPECCION_PROYECTO,
            payload: {
                inspection: res.data.inspection
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesInspectionProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_inspeccion`, body, config);
        dispatch({
            type: DETALLES_INSPECCION_PROYECTO,
            payload: res.data.inspection
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarInspectionProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_inspeccion`, body, config);
        dispatch({
            type: ELIMINAR_INSPECCION_PROYECTO,
            payload: id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const statusInspectionProyecto = (inspeccion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(inspeccion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/status_inspeccion`, body, config);
        dispatch({
            type: EDITAR_INSPECCION_PROYECTO,
            payload: {
                inspection: res.data.inspection
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externoInspeccion = (valor) => async dispatch => {
    dispatch({
        type: EXTERNO_INSPECCION,
        payload: valor
    });
}

export const reportInspecciones = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/reporte_inspecciones`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Reparaciones */
export const repairsProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_inspeccion: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_reparaciones`, body, config);
        dispatch({
            type: REPARACIONES_PROYECTO,
            payload: {
                repairs: res.data.repairs
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarRepairProyecto = (reparacion) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/agregar_reparacion`, reparacion, config);
        dispatch({
            type: AGREGAR_REPARACION_PROYECTO,
            payload: {
                repair: res.data.repair
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarRepairProyecto = (reparacion) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/editar_reparacion`, reparacion, config);
        dispatch({
            type: EDITAR_REPARACION_PROYECTO,
            payload: {
                repair: res.data.repair
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesRepairProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/detalles_reparacion`, body, config);
        dispatch({
            type: DETALLES_REPARACION_PROYECTO,
            payload: res.data.repair
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarRepairProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/eliminar_reparacion`, body, config);
        dispatch({
            type: ELIMINAR_REPARACION_PROYECTO,
            payload: id
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const statusRepairProyecto = (reparacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(reparacion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/status_reparacion`, body, config);
        dispatch({
            type: EDITAR_REPARACION_PROYECTO,
            payload: {
                repair: res.data.repair
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Generales */
export const masterlistProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_masterlist`, body, config);
        dispatch({
            type: MATERLIST_PROYECTO,
            payload: {
                masterlist: res.data.masterlist,
                users: res.data.users
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const verReportProyecto = (reporte) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(reporte);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_reporte_masterlist`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const compartirReportProyecto = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(reporte);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/compartir_reporte_masterlist`, body, config);
        dispatch({
            type: COMPARTIR_LINK_REPORTE,
            payload: res.data.ruta
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const enviarReportProyecto = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(reporte);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/enviar_reporte_masterlist`, body, config);
        dispatch({
            type: COMPARTIR_LINK_REPORTE,
            payload: res.data.ruta
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const limpiarLinkReportProyecto = () => async dispatch => {
    dispatch({
        type: COMPARTIR_LINK_REPORTE,
        payload: null
    });
}

export const seleccionarSeccionMasterList = (seccion) => async dispatch => {
    const payloadObject = {seccion};
    try {
        dispatch({
            type: SELECCIONAR_SECCION_MASTERLIST,
            payload: payloadObject
        });   
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const checklistProyecto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_checklist`, body, config);
        dispatch({
            type: CHECKLIST_PROYECTO,
            payload: {
                checklist: res.data.checklist
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteChecklist = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/reporte_checklist`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesProyecto = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_proyecto`, body, config);
        dispatch({
            type: DETALLES_PROYECTO,
            payload: {
                id: id,
                proyecto: res.data.proyecto
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesProyectoPublico = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id_proyecto: id
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/ver_proyecto_publico`, body, config);
        dispatch({
            type: DETALLES_PROYECTO,
            payload: {
                id: id,
                proyecto: res.data.proyecto
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesPreProyecto = (proyecto) => async dispatch => {
    dispatch({
        type: DETALLES_PROYECTO,
        payload: {
            id: proyecto._id,
            proyecto
        }
    });
}

export const seleccionarProyecto = (id_proyecto) => async dispatch => {
    dispatch({
        type: SELECCIONAR_PROYECTO_DASHBOARD,
        payload: id_proyecto
    });
}

/* To Do List */
export const agregarSeccion = (seccion) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seccion);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/nueva_seccion`, body, config);
        
        dispatch({
            type: AGREGAR_SECCION,
            payload: {
                id: seccion.id_proyecto,
                section: res.data.seccion
            }
        });    
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTarea = (tarea) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(tarea);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/nueva_tarea`, body, config);

        dispatch({
            type: AGREGAR_TAREA,
            payload: {
                sectionT: res.data.seccion
            }
        });    
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarSeccion = (seccion) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seccion);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/editar_seccion`, body, config);
        dispatch({
            type: EDITAR_SECCION,
            payload: res.data.seccion
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarTarea = (tarea) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(tarea);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/editar_tarea`, body, config);
        dispatch({
            type: EDITAR_SECCION,
            payload: res.data.seccion
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarSeccion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/eliminar_seccion`, body, config);
        dispatch({
            type: ELIMINAR_SECCION,
            payload: {
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTarea = (id, id_seccion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, id_seccion });
    console.log(body);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/eliminar_tarea`, body, config);
        dispatch({
            type: ELIMINAR_TAREA,
            payload: {
                id,
                id_seccion
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerCalendario = (id_proyecto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id_proyecto };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/obtener`, body, config);
        dispatch({
            type: OBTENER_CALENDARIO,
            payload: {
                id: id_proyecto,
                sections: res.data.sections
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Calendario */
/* export const buscarSucesos = (id_proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id_proyecto };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/schedule/nueva_seccion`, body, config);
        
        dispatch({
            type: AGREGAR_SECCION,
            payload: {
                id: seccion.id_proyecto,
                section: res.data.seccion
            }
        });    
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
} */