import { BUSCAR_TITLES, AGREGAR_TITLE, EDITAR_TITLE, DETALLES_TITLE, ELIMINAR_TITLE, BUSCAR_TITLES_AGENTES, AGREGAR_TITLE_AGENTE, AGREGAR_TITLE_AGENTE_DIRECTO, EDITAR_TITLE_AGENTE, ELIMINAR_TITLE_AGENTE } from '../actions/types';

const initialState = {
    titles: [],
    agents: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_TITLE:
            return {
                ...state,
                titles: [
                    ...state.titles, payload
                ]
            };
        case EDITAR_TITLE:
            return {
                ...state,
                titles: state.titles.map(
                    title => title._id === payload._id ? payload : title
                )
            };
        case DETALLES_TITLE:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_TITLE:
            return {
                ...state,
                titles: state.titles.filter( title => title._id !== payload.id )
            };
        case BUSCAR_TITLES:
            return {
                ...state,
                titles: payload
            };
        case BUSCAR_TITLES_AGENTES:
            return {
                ...state,
                agents: payload
            };
        case AGREGAR_TITLE_AGENTE:
            return {
                ...state,
                agents: [
                    ...state.agents, payload
                ]
            };
        case AGREGAR_TITLE_AGENTE_DIRECTO:

            const title_elegido = state.titles.find(title => title._id === payload.id_title);
            
            if(title_elegido){
                title_elegido.agentes = [...title_elegido.agentes, payload];
            }

            return {
                ...state,
                titles: state.titles.map( 
                    title => title._id === payload.id_title ? title_elegido : title
                )
            };
        case EDITAR_TITLE_AGENTE:
            return {
                ...state,
                agents: state.agents.map(
                    agent => agent._id === payload._id ? payload : agent
                )
            };
        case ELIMINAR_TITLE_AGENTE:
            return {
                ...state,
                agents: state.agents.filter( agent => agent._id !== payload.id )
            };
        default:
            return state;
    }
}