import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_PUBLIC, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT, EDITAR_PERFIL, RECUPERAR, RESTABLECER } from './types';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

// LOAD USER
export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/auth/`);
        dispatch({
            type: USER_LOADED,
            payload: res.data.user
        });
    } catch (error){ 
        dispatch({
            type: AUTH_ERROR
        });
    }
}

// LOGIN USER
export const login = ({ email_usuario, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email_usuario, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/`, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// ACCESS PUBLIC
export const access_public = () => async dispatch => {
    dispatch({
        type: LOGIN_PUBLIC
    });
}

// LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

// EDITAR USUARIO
export const editarUsuario = (usuario) => async dispatch => {
    console.log(usuario);
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(usuario);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/editar`, body, config);
        dispatch({
            type: EDITAR_PERFIL,
            payload: res.data.user
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// RECUPERAR CONTRASEÑA
export const recuperarPwd = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        email: email.emailPwd
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/recuperar`, body, config);
        dispatch({
            type: RECUPERAR,
            payload: res.data.codigo
        });
        dispatch(setAlert('Code was succesfully sent', 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

// RESTABLECER CONTRASEÑA
export const restablecerPwd = (data) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(data);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/restablecer`, body, config);
        dispatch({
            type: RESTABLECER,
            payload: res.data
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}