import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { editarEmpleado } from '../../actions/empleados';

const EditarEmpleado = ({history}) => {

    const matches = useMediaQuery('(min-width:768px)');

    const dispatch = useDispatch();

    const {empleadoEditando} = useSelector(state => state.empleados);
    console.log(empleadoEditando);

    const [empleadoData, setEmpleadoData] = useState({
        id: empleadoEditando?._id,
        nombre: empleadoEditando?.nombre,
        apellido: empleadoEditando?.apellido,
        salario: empleadoEditando?.salario
    }) 

    const [spinner, setSpinner] = useState(false);

    const {nombre, apellido, salario} = empleadoData;

    


    const handleOnChange = (e) => {
        setEmpleadoData({
            ...empleadoData, 
            [e.target.name]: e.target.value
        });
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            dispatch(editarEmpleado(empleadoData));
            setSpinner(false);
            history.push('/employees');
        }, 0);

    }

    return ( 
        <div className="row pb-5">
            <form className="col-md-10 offset-md-1" style={ matches ? {} : { marginLeft: '4%' } }>
                    <h3 className="mb-3 mt-5">Edit Employee</h3>
                    <div className="row mb-4">
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">First Name</label>
                            <input type="text" className="form-control" name="nombre" onChange={handleOnChange} value={nombre}></input>
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Last Name</label>
                            <input type="text" className="form-control" name="apellido" onChange={handleOnChange} value={apellido}></input>
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Salary per day</label>
                            <input type="number" className="form-control" name="salario" onChange={handleOnChange} value={salario}></input>
                        </div>
                    </div>

                    {spinner ? 
                        <div className="col-12 mt-3 text-center d-flex justify-content-center">
                            <div className="sk-chase my-5">
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                            </div>
                        </div>
                    :
                        null
                    }

                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <button type="button" className="btn btn-danger float-right mt-3" onClick={() => {history.push('/employees')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button type="submit" className="btn btn-success float-right mt-3" onClick={handleOnSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Save</button>
                    </div>
            </form>
        </div>
    );
}

export default EditarEmpleado;