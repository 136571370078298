import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../../actions/alert';
import { editarNewDevelopment, continuarNewDevelopment } from '../../../actions/new_development';
import { detallesProyecto, masterlistProyecto, seleccionarSeccionMasterList } from '../../../actions/proyectos';

const EntitlementsNewDevelopment = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);

    const [spinner, setSpinner] = useState(false);

    const [proyectoData, setProyectoData] = useState({
        id: null
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        await dispatch(editarNewDevelopment(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const handleContinue = async (e) => {
        e.preventDefault();

        if (proyectoData.sales_price !== null && proyectoData.due_dilligence_expiration !== null && proyectoData.closing !== null) {
            setSpinner(true);
            setTimeout(async() => {

                await dispatch(continuarNewDevelopment(proyectoData));
                await dispatch(masterlistProyecto(proyectoData.id));
                await dispatch(detallesProyecto(proyectoData.id));

                setSpinner(false);

                await dispatch(seleccionarSeccionMasterList('Sold property'));
            }, 0);
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    useEffect(() => {
        if(!proyectos.guardar_cambios){
            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id
            });
        }
    }, [proyectos]);

    return (
        <Fragment>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 8 ? 
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={spinner ? true : false}>Save</button>
                    :   null
                }
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 8 ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleContinue} disabled={spinner ? true : false}>Save and Continue</button>
                    :   null
                }                
            </div>

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleContinue}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-md-12 text-center mb-3">
                <h4>What would you like to do with this property?</h4>
            </div>

        </Fragment>
    )
}

export default EntitlementsNewDevelopment;