import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import PlacesAutocomplete from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../../actions/alert';
import { editarInEscrow, renegotiateInEscrow, continuarInEscrow, closeInEscrow, guardarCambios, desplegarModalCambios, redireccionar } from '../../../actions/flipping';
import { detallesProyecto, detallesFileProyecto, masterlistProyecto, seleccionarSeccionMasterList, agregarFileProyecto, editarFileProyecto, externoNota, buscarTiposProyectos } from '../../../actions/proyectos';
import { agregarEscrowMasterlist, agregarEscrowAgentMasterlist } from '../../../actions/escrows';
import { agregarBrokerMasterlistBuy, agregarBrokerAgentMasterlistBuy } from '../../../actions/brokers';
import { agregarLenderMasterlistBuy } from '../../../actions/lenders';
import { agregarTitleMasterlistBuy, agregarTitleAgentMasterlist } from '../../../actions/titles';
import { agregarTermiteMasterlistBuy, agregarTermiteAgentMasterlist } from '../../../actions/termites';
import { agregarSepticMasterlistBuy, agregarSepticAgentMasterlist } from '../../../actions/septics';

const styles = {
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    }
}

const BuyingEscrowFlipping = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const tipos = useSelector(state => state.tipos);
    const brokers = useSelector(state => state.brokers);
    const escrows = useSelector(state => state.escrows);
    const lenders = useSelector(state => state.lenders);
    const titles = useSelector(state => state.titles);
    const termites = useSelector(state => state.termites);
    const septics = useSelector(state => state.septics);
    const usuarios = useSelector(state => state.usuarios);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);

    const history = useHistory();

    //Atrapar back button del navegador

    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logica para mostrar un popup cuando la pagina se recarga
        window.onbeforeunload = function () {
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page?")) {
                setBackbuttonPress(true);
                history.go(-1);
                handleCloseNoGuardarCambiosModal();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const [flag, setFlag] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [mostrarBoton, setMostrarBoton] = useState(false);

    const [proyectoData, setProyectoData] = useState({
        id: null,
        status: null,
        nombre_status: null,
        escrow_open: null,
        escrow_closing: null,
        real_escrow_closing: null,
        escrow_information: false,
        escrow_information_user: null,
        name_escrow_information_user: null,
        escrow_deposit_send_question: null,
        escrow_deposit_send: null,
        escrow_instructions: false,
        escrow_instructions_date: null,
        escrow_number: null,
        funds_available: false,
        purchase_price_adjustment: true,
        
        id_escrow: null,
        id_escrow_agent: null,

        id_broker_buyer: null,
        id_broker_agent_buyer: null,
        broker_represent: false,
        id_broker_seller: null,
        id_broker_agent_seller: null,

        id_lender: null,
        name_lender: null,
        lender_officer_name: null,
        lender_email: null,
        lender_cell_phone: null,
        lender_phone: null,
        lender_amount: null,
        lender_yearly_rate: null,
        lender_origination_points: null,
        lender_other_fees: null,

        id_title: null,
        id_title_agent: null,
        title_preliminary: false,
        title_preliminary_date: null,
        title_review: false,
        title_review_who: null,
        title_rep: null,
        title_order: null,      
        
        id_termite: null,
        id_termite_agent: null,        
        termite_inspection: false,
        termite_inspection_user: null,
        name_termite_inspection_user: null,
        termite_inspection_date: null,
        report_number_termite: null,

        id_septic: null,
        id_septic_agent: null,
        septic_inspection: false,
        septic_inspection_user: null,
        name_septic_inspection_user: null,
        septic_inspection_date: null,
        report_number_septic: null,

        purchase_price_history: [],
        escrow_deposit_amount_history: [],
        escrow_closing_history: [],
        due_dilligence_end_history: []
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [renegotiationData, setRenegotiationData] = useState({
        id_proyecto: null,
        id_status: null,
        purchase_price: null,
        purchase_price_date: new Date(),
        purchase_price_note: null,
        purchase_price_file_name: null,
        purchase_price_file: null,
        purchase_price_extension: null,
        due_dilligence_end: null,
        due_dilligence_end_date: new Date(),
        due_dilligence_end_note: null,
        due_dilligence_end_file_name: null,
        due_dilligence_end_file: null,
        due_dilligence_end_extension: null,
        escrow_deposit_amount: null,
        escrow_deposit_amount_date: new Date(),
        escrow_deposit_amount_note: null,
        escrow_deposit_amount_file_name: null,
        escrow_deposit_amount_file: null,
        escrow_deposit_amount_extension: null,
        escrow_deposit_increase: false,
        escrow_deposit_release: false,
        escrow_how_much_released: null,
        funds_applied_purchase_price: true,
        note_funds_applied: null,
        who_funds_applied: null,
        escrow_closing_be_change: false,
        escrow_closing: null,
        escrow_closing_date: new Date(),
        escrow_closing_note: null,
        escrow_closing_file_name: null,
        escrow_closing_file: null,
        escrow_closing_extension: null,
        per_diem: false,
        amount_per_day: null
    });

    const [cancelPurchaseData, setCancelPurchaseData] = useState({
        status: false,
        text: 'Cancel purchase'
    });

    const [onHoldData, setOnHoldData] = useState({
        status: false,
        text: 'On hold'
    });

    const [reactivateData, setReactivateData] = useState({
        status: false,
        text: 'Reactivate'
    });

    const [closePurchaseData, setClosePurchaseData] = useState({
        status: false,
        text: 'Close purchase'
    });

    const [escrowSelectData, setEscrowSelectData] = useState(null);
    const [escrowAgentSelectData, setEscrowAgentSelectData] = useState(null);

    const [brokerBuyerSelectData, setBrokerBuyerSelectData] = useState(null);
    const [brokerAgentBuyerSelectData, setBrokerAgentBuyerSelectData] = useState(null);

    const [brokerSellerSelectData, setBrokerSellerSelectData] = useState(null);
    const [brokerAgentSellerSelectData, setBrokerAgentSellerSelectData] = useState(null);

    const [lenderSelectData, setLenderSelectData] = useState(null);

    const [titleSelectData, setTitleSelectData] = useState(null);
    const [titleAgentSelectData, setTitleAgentSelectData] = useState(null);

    const [termiteSelectData, setTermiteSelectData] = useState(null);
    const [termiteAgentSelectData, setTermiteAgentSelectData] = useState(null);

    const [septicSelectData, setSepticSelectData] = useState(null);
    const [septicAgentSelectData, setSepticAgentSelectData] = useState(null);

    const [userEscrowInformationData, setUserEscrowInformationData] = useState(null);
    const [userFundsAppliedData, setUserFundsAppliedData] = useState(null);
    const [userTermiteSelectData, setUserTermiteSelectData] = useState(null);
    const [userSepticSelectData, setUserSepticSelectData] = useState(null);

    const [addEscrowData, setAddEscrowData] = useState({
        company: '',
        address: ''
    });

    const [addEscrowAgentData, setAddEscrowAgentData] = useState({
        id_escrow: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [addBrokerBuyerData, setAddBrokerBuyerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentBuyerData, setAddBrokerAgentBuyerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addBrokerSellerData, setAddBrokerSellerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentSellerData, setAddBrokerAgentSellerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addLenderData, setAddLenderData] = useState({
        company: '',
        address: ''
    });

    const [addTitleData, setAddTitleData] = useState({
        company: '',
        address: ''
    });

    const [addTitleAgentData, setAddTitleAgentData] = useState({
        id_title: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [addTermiteData, setAddTermiteData] = useState({
        company: '',
        address: ''
    });

    const [addTermiteAgentData, setAddTermiteAgentData] = useState({
        id_termite: null,
        officer_name: '',
        email: '',
        phone: ''
    });

    const [addSepticData, setAddSepticData] = useState({
        company: '',
        address: ''
    });

    const [addSepticAgentData, setAddSepticAgentData] = useState({
        id_septic: null,
        officer_name: '',
        email: '',
        phone: ''
    });

    const [viewEscrowData, setViewEscrowData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [viewBrokerBuyerData, setViewBrokerBuyerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerSellerData, setViewBrokerSellerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewLenderData, setViewLenderData] = useState({
        company: '',
        address: ''
    });

    const [viewTitleData, setViewTitleData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [viewTermiteData, setViewTermiteData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        phone: ''
    });

    const [viewSepticData, setViewSepticData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        phone: ''
    });

    const [continueModal, setContinueModal] = useState(false);
    const [guardarCambiosModal, setGuardarCambiosModal] = useState(false);

    const [escrowModal, setEscrowModal] = useState(false);
    const [escrowAgentModal, setEscrowAgentModal] = useState(false);

    const [brokerBuyerModal, setBrokerBuyerModal] = useState(false);
    const [brokerAgentBuyerModal, setBrokerAgentBuyerModal] = useState(false);

    const [brokerSellerModal, setBrokerSellerModal] = useState(false);
    const [brokerAgentSellerModal, setBrokerAgentSellerModal] = useState(false);

    const [lenderModal, setLenderModal] = useState(false);
    
    const [titleModal, setTitleModal] = useState(false);
    const [titleAgentModal, setTitleAgentModal] = useState(false);

    const [termiteModal, setTermiteModal] = useState(false);
    const [termiteAgentModal, setTermiteAgentModal] = useState(false);

    const [septicModal, setSepticModal] = useState(false);
    const [septicAgentModal, setSepticAgentModal] = useState(false);

    const [escrowsFilter, setEscrowsFilter] = useState([]);
    const [escrowsAgentsFilter, setEscrowsAgentsFilter] = useState([]);

    const [brokersFilter, setBrokersFilter] = useState([]);
    const [brokersAgentsBuyerFilter, setBrokersAgentsBuyerFilter] = useState([]);
    const [brokersAgentsSellerFilter, setBrokersAgentsSellerFilter] = useState([]);

    const [lendersFilter, setLendersFilter] = useState([]);

    const [titlesFilter, setTitlesFilter] = useState([]);
    const [titlesAgentsFilter, setTitlesAgentsFilter] = useState([]);

    const [termitesFilter, setTermitesFilter] = useState([]);
    const [termitesAgentsFilter, setTermitesAgentsFilter] = useState([]);

    const [septicsFilter, setSepticsFilter] = useState([]);
    const [septicsAgentsFilter, setSepticsAgentsFilter] = useState([]);

    const [usersFilter, setUsersFilter] = useState([]);

    const [renegotiationModal, setRenegotiationModal] = useState(false);
    
    const [sectionInformation, setSectionInformation] = useState(0);

    const [newFileModal, setNewFileModal] = useState(false);

    const [editFileModal, setEditFileModal] = useState(false);

    const [statusSaveFile, setStatusSaveFile] = useState(true);
    
    const [statusSaveEditFile, setStatusSaveEditFile] = useState(true);    

    const [newFileData, setNewFileData] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        subseccion: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [editFileData, setEditFileData] = useState({
        id: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [dataTabla, setDataTabla] = useState({
        archivosTabla: null
    });

    let file_ref = createRef();
    let file_edit_ref = createRef();

    let purchase_price_ref = createRef();
    let due_dilligence_end_ref = createRef();
    let escrow_deposit_amount_ref = createRef();
    let escrow_closing_ref = createRef();

    const handleAlertarFechas = (fecha) => {
        let dif_days = Math.floor((new Date(fecha).getTime() - new Date(proyectos.masterlist?.due_dilligence_end).getTime()) / (1000 * 3600 * 24));

        if(dif_days <= 7){
            dispatch(setAlert('This date must be at least 8 days greater than the Due Diligence End date', 'warning'));
        }
    };

    const newNote = campo => {
        dispatch(externoNota(true, campo));
    }

    //Agregar archivo
    const handleAddFileSection = (subseccion) => {
        setNewFileModal(true);

        setNewFileData({
            ...newFileData,
            subseccion: subseccion
        });
    }

    const handleInputsFile = ({ target }) => {
        setNewFileData({
            ...newFileData,
            [target.name]: target.value
        });
    }

    const onChangeFile = () => {
        let nombre_separado = file_ref.current.files[0].name.split('.');

        setNewFileData({
            ...newFileData,
            archivo: file_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseAgregarArchivo = () => {
        setNewFileModal(false);
        setNewFileData({
            ...newFileData,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('subseccion', newFileData.subseccion);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);


                    setNewFileData({
                        ...newFileData,
                        id_folder: null,
                        subseccion: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setSectionInformation((newFileData.subseccion * 2) + 1);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Editar archivo
    const handleInputsEditFile = ({ target }) => {
        setEditFileData({
            ...editFileData,
            [target.name]: target.value
        });
    }

    const onChangeEditFile = () => {
        let nombre_separado = file_edit_ref.current.files[0].name.split('.');

        setEditFileData({
            ...editFileData,
            archivo: file_edit_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseEditarArchivo = () => {
        setEditFileModal(false);
        setEditFileData({
            ...editFileData,
            id: null,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarEditarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(editFileData.extension !== ''){
                if(editFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id', editFileData.id);
                    formData.append('nombre', editFileData.nombre);
                    formData.append('extension', editFileData.extension);
                    formData.append('archivo', editFileData.archivo);

                    setStatusSaveEditFile(false);
                    await dispatch(editarFileProyecto(formData));
                    setStatusSaveEditFile(true);

                    setEditFileData({
                        ...editFileData,
                        id: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setEditFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setRenegotiationModal(false);
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyecto(id));
    }

    const handleEditarArchivo = async (archivo) => {
        setEditFileModal(true);

        setEditFileData({
            ...editFileData,
            id: archivo._id,
            nombre: archivo.nombre,
            extension: archivo.extension
        });
    }

    const onChange = ({ target }) => {
        setProyectoData({
            ...proyectoData,
            [target.name]: target.value 
        });

        dispatch(guardarCambios(true));
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value
        });

        dispatch(guardarCambios(true));
    };

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){

                case 'escrow_information_user':
                    await setUserEscrowInformationData({value, label});
                    break;

                case 'id_escrow':
                    await setEscrowSelectData({value, label});
                    break;

                case 'id_escrow_agent':
                    await setEscrowAgentSelectData({value, label});
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData({value, label});
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData({value, label});
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData({value, label});
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData({value, label});
                    break;

                case 'id_lender':
                    await setLenderSelectData({value, label});
                    break;

                case 'id_title':
                    await setTitleSelectData({value, label});
                    break;

                case 'id_title_agent':
                    await setTitleAgentSelectData({value, label});
                    break;

                case 'id_termite':
                    await setTermiteSelectData({value, label});
                    break;

                case 'id_termite_agent':
                    await setTermiteAgentSelectData({value, label});
                    break;

                case 'id_septic':
                    await setSepticSelectData({value, label});
                    break;

                case 'id_septic_agent':
                    await setSepticAgentSelectData({value, label});
                    break;

                case 'termite_inspection_user':
                    await setUserTermiteSelectData({value, label});
                    break;

                case 'septic_inspection_user':
                    await setUserSepticSelectData({value, label});
                    break;

                case 'who_funds_applied':
                    await setUserFundsAppliedData({value, label});
                    break;

                default:
                    break;
            }

            if(origin === 'who_funds_applied'){
                await setRenegotiationData({
                    ...renegotiationData,
                    [origin]: value
                });
            }else{
                if(origin === 'id_escrow' || origin === 'id_broker_buyer' || origin === 'id_broker_seller' || origin === 'id_title' || origin === 'id_termite' || origin === 'id_septic'){
                    await setProyectoData({
                        ...proyectoData,
                        [origin]: value,
                        [name_origin]: null
                    });
                }else{
                    await setProyectoData({
                        ...proyectoData,
                        [origin]: value,
                        [name_origin]: label
                    });
                }
            }

            await dispatch(guardarCambios(true));
        }else{
            switch(origin){

                case 'escrow_information_user':
                    await setUserEscrowInformationData(null);
                    break;

                case 'id_escrow':
                    await setEscrowSelectData(null);
                    break;

                case 'id_escrow_agent':
                    await setEscrowAgentSelectData(null);
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData(null);
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData(null);
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData(null);
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData(null);
                    break;

                case 'id_lender':
                    await setLenderSelectData(null);
                    break;

                case 'id_title':
                    await setTitleSelectData(null);
                    break;

                case 'id_title_agent':
                    await setTitleAgentSelectData(null);
                    break;

                case 'id_termite':
                    await setTermiteSelectData(null);
                    break;

                case 'id_termite_agent':
                    await setTermiteAgentSelectData(null);
                    break;

                case 'id_septic':
                    await setSepticSelectData(null);
                    break;

                case 'id_septic_agent':
                    await setSepticAgentSelectData(null);
                    break;

                case 'termite_inspection_user':
                    await setUserTermiteSelectData(null);
                    break;

                case 'septic_inspection_user':
                    await setUserSepticSelectData(null);
                    break;

                case 'who_funds_applied':
                    await setUserFundsAppliedData(null);
                    break;

                default:
                    break;
            }

            if(origin === 'who_funds_applied'){
                await setRenegotiationData({
                    ...renegotiationData,
                    [origin]: null,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null,
                    [name_origin]: null
                });
            }        

            await dispatch(guardarCambios(true));
        }
    };

    const handleRadiobutton = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    escrow_deposit_send_question: true,
                    escrow_deposit_send: new Date()
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    escrow_deposit_send_question: false,
                    escrow_deposit_send: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonEscrow = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    escrow_information: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    escrow_information: false
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonFundsAvailable = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    funds_available: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    funds_available: false
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonEscrowInstructions = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    escrow_instructions: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    escrow_instructions: false,
                    escrow_instructions_date: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonPriceAdjustment = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    purchase_price_adjustment: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    purchase_price_adjustment: false
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTitlePreliminary = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    title_preliminary: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    title_preliminary: false,
                    title_preliminary_date: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTitleReviewed = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    title_review: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    title_review: false,
                    title_review_who: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTermiteInspection = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    termite_inspection: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    termite_inspection: false,
                    termite_inspection_user: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonSepticInspection = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    septic_inspection: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    septic_inspection: false,
                    septic_inspection_user: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleGuardarCambiosModal = () => {
        setGuardarCambiosModal(true);
    }

    const handleCloseGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(desplegarModalCambios(false));
    }

    const handleCloseNoGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(guardarCambios(false));
        dispatch(desplegarModalCambios(false));
        dispatch(redireccionar(proyectos.paginaRedireccion));
    }

    const handleEscrow = (tipo) => {
        if(tipo){
            setEscrowModal(true);
        }else{

            setAddEscrowAgentData({
                ...addEscrowAgentData,
                id_escrow: proyectoData.id_escrow
            });

            setEscrowAgentModal(true);            
        }
    }

    const handleCloseModalEscrow = (tipo) => {
        if(tipo){
            setEscrowModal(false);

            setAddEscrowData({
                ...addEscrowData,
                company: '',
                address: ''
            });
        }else{
            setEscrowAgentModal(false);

            setAddEscrowAgentData({
                ...addEscrowAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });          
        }
    }

    const handleBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(true);
        }else{

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                id_broker: proyectoData.id_broker_seller
            });

            setBrokerAgentSellerModal(true);            
        }
    }

    const handleCloseModalBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(false);

            setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentSellerModal(false);

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            }); 
        }
    }

    const handleBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(true);
        }else{

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                id_broker: proyectoData.id_broker_buyer
            });

            setBrokerAgentBuyerModal(true);            
        }
    }

    const handleCloseModalBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(false);

            setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentBuyerModal(false);   

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            });
        }
    }

    const handleLender = () => {
        setLenderModal(true);
    }

    const handleCloseModalLender = () => {
        setLenderModal(false);
    }

    const handleTitle = (tipo) => {
        if(tipo){
            setTitleModal(true);
        }else{

            setAddTitleAgentData({
                ...addTitleAgentData,
                id_title: proyectoData.id_title
            });

            setTitleAgentModal(true);            
        }
    }

    const handleCloseModalTitle = (tipo) => {
        if(tipo){
            setTitleModal(false);

            setAddTitleData({
                ...addTitleData,
                company: '',
                address: ''
            });
        }else{
            setTitleAgentModal(false);

            setAddTitleAgentData({
                ...addTitleAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });
        }
    }

    const handleTermite = (tipo) => {
        if(tipo){
            setTermiteModal(true);
        }else{

            setAddTermiteAgentData({
                ...addTermiteAgentData,
                id_termite: proyectoData.id_termite
            });

            setTermiteAgentModal(true);            
        }
    }

    const handleCloseModalTermite = (tipo) => {
        if(tipo){
            setTermiteModal(false);

            setAddTermiteData({
                ...addTermiteData,
                company: '',
                address: ''
            });
        }else{
            setTermiteAgentModal(false);

            setAddTermiteAgentData({
                ...addTermiteAgentData,
                officer_name: '',
                email: '',
                phone: ''
            });
        }
    }

    const handleSeptic = (tipo) => {
        if(tipo){
            setSepticModal(true);
        }else{

            setAddSepticAgentData({
                ...addSepticAgentData,
                id_septic: proyectoData.id_septic
            });

            setSepticAgentModal(true);            
        }
    }

    const handleCloseModalSeptic = (tipo) => {
        if(tipo){
            setSepticModal(false);

            setAddSepticData({
                ...addSepticData,
                company: '',
                address: ''
            });
        }else{
            setSepticAgentModal(false);

            setAddSepticAgentData({
                ...addSepticAgentData,
                officer_name: '',
                email: '',
                phone: ''
            });      
        }
    }

    const handleViewEscrow = async () => {
        let datos_escrow_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: ''
        };

        let datos_escrow_select = null;
        
        let datos_escrow_filter = [];

        let datos_escrow_agent_select = null;

        if(proyectoData.id_escrow !== null){
            let escrow_elegido = await escrows.escrows?.filter(escrow => String(escrow._id) === String(proyectoData.id_escrow));
            if(escrow_elegido.length > 0){
                datos_escrow_view = {
                    ...datos_escrow_view,
                    company: escrow_elegido[0].compania,
                    address: escrow_elegido[0].direccion || '',
                    agents: escrow_elegido[0].agentes
                };

                datos_escrow_select = {
                    value: escrow_elegido[0]._id,
                    label: escrow_elegido[0].compania
                };

                let options_escrow_agents = [];
                for (var i = 0; i < escrow_elegido[0].agentes?.length; i++) {
                    options_escrow_agents.push({ value: escrow_elegido[0].agentes[i]._id, label: escrow_elegido[0].agentes[i].officer_name });
                }
                datos_escrow_filter = options_escrow_agents;

                if(proyectoData.id_escrow_agent !== null){
                    let escrow_agent_elegido = await escrow_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_escrow_agent));
                    if(escrow_agent_elegido.length > 0){

                        datos_escrow_view = {
                            ...datos_escrow_view,
                            officer_name: escrow_agent_elegido[0].officer_name || '',
                            email: escrow_agent_elegido[0].email || '',
                            cell_phone: escrow_agent_elegido[0].cell_phone || '',
                            phone: escrow_agent_elegido[0].phone || ''
                        };

                        datos_escrow_agent_select = {
                            value: escrow_agent_elegido[0]._id,
                            label: escrow_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewEscrowData(datos_escrow_view);
        await setEscrowSelectData(datos_escrow_select);
        await setEscrowsAgentsFilter(datos_escrow_filter);
        await setEscrowAgentSelectData(datos_escrow_agent_select);
    }

    const handleViewBrokerBuyer = async () => {

        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_buyer !== null){
            let broker_buyer_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_buyer));
            if(broker_buyer_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_buyer_elegido[0].compania,
                    address: broker_buyer_elegido[0].direccion || '',
                    agents: broker_buyer_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_buyer_elegido[0]._id,
                    label: broker_buyer_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (var i = 0; i < broker_buyer_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_buyer_elegido[0].agentes[i]._id, label: broker_buyer_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_buyer !== null){
                    let broker_agent_elegido = await broker_buyer_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_buyer));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerBuyerData(datos_broker_view);
        await setBrokerBuyerSelectData(datos_broker_select);
        await setBrokersAgentsBuyerFilter(datos_broker_filter);
        await setBrokerAgentBuyerSelectData(datos_broker_agent_select);
    }

    const handleViewBrokerSeller = async () => {
        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_seller !== null){
            let broker_seller_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_seller));
            if(broker_seller_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_seller_elegido[0].compania,
                    address: broker_seller_elegido[0].direccion || '',
                    agents: broker_seller_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_seller_elegido[0]._id,
                    label: broker_seller_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (var i = 0; i < broker_seller_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_seller_elegido[0].agentes[i]._id, label: broker_seller_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_seller !== null){
                    let broker_agent_elegido = await broker_seller_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_seller));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerSellerData(datos_broker_view);
        await setBrokerSellerSelectData(datos_broker_select);
        await setBrokersAgentsSellerFilter(datos_broker_filter);
        await setBrokerAgentSellerSelectData(datos_broker_agent_select);
    }

    const handleViewLender = async () => {
        let lender_elegido = await lenders.lenders?.filter(lender => String(lender._id) === String(proyectoData.id_lender));
        if(lender_elegido.length > 0){
            await setViewLenderData({
                ...viewLenderData,
                company: lender_elegido[0].compania,
                address: lender_elegido[0].direccion || ''
            });
        }
    }

    const handleViewTitle = async () => {
        let datos_title_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: ''
        };

        let datos_title_select = null;
        
        let datos_title_filter = [];

        let datos_title_agent_select = null;

        if(proyectoData.id_title !== null){
            let title_elegido = await titles.titles?.filter(title => String(title._id) === String(proyectoData.id_title));
            if(title_elegido.length > 0){
                datos_title_view = {
                    ...datos_title_view,
                    company: title_elegido[0].compania,
                    address: title_elegido[0].direccion || '',
                    agents: title_elegido[0].agentes
                };

                datos_title_select = {
                    value: title_elegido[0]._id,
                    label: title_elegido[0].compania
                };

                let options_title_agents = [];
                for (var i = 0; i < title_elegido[0].agentes?.length; i++) {
                    options_title_agents.push({ value: title_elegido[0].agentes[i]._id, label: title_elegido[0].agentes[i].officer_name });
                }
                datos_title_filter = options_title_agents;

                if(proyectoData.id_title_agent !== null){
                    let title_agent_elegido = await title_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_title_agent));
                    if(title_agent_elegido.length > 0){

                        datos_title_view = {
                            ...datos_title_view,
                            officer_name: title_agent_elegido[0].officer_name || '',
                            email: title_agent_elegido[0].email || '',
                            cell_phone: title_agent_elegido[0].cell_phone || '',
                            phone: title_agent_elegido[0].phone || ''
                        };

                        datos_title_agent_select = {
                            value: title_agent_elegido[0]._id,
                            label: title_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewTitleData(datos_title_view);
        await setTitleSelectData(datos_title_select);
        await setTitlesAgentsFilter(datos_title_filter);
        await setTitleAgentSelectData(datos_title_agent_select);
    }

    const handleViewTermite = async () => {
        let datos_termite_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            phone: ''
        };

        let datos_termite_select = null;
        
        let datos_termite_filter = [];

        let datos_termite_agent_select = null;

        if(proyectoData.id_termite !== null){
            let termite_elegido = await termites.termites?.filter(termite => String(termite._id) === String(proyectoData.id_termite));
            if(termite_elegido.length > 0){
                datos_termite_view = {
                    ...datos_termite_view,
                    company: termite_elegido[0].compania,
                    address: termite_elegido[0].direccion || '',
                    agents: termite_elegido[0].agentes
                };

                datos_termite_select = {
                    value: termite_elegido[0]._id,
                    label: termite_elegido[0].compania
                };

                let options_termite_agents = [];
                for (var i = 0; i < termite_elegido[0].agentes?.length; i++) {
                    options_termite_agents.push({ value: termite_elegido[0].agentes[i]._id, label: termite_elegido[0].agentes[i].officer_name });
                }
                datos_termite_filter = options_termite_agents;

                if(proyectoData.id_termite_agent !== null){
                    let termite_agent_elegido = await termite_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_termite_agent));
                    if(termite_agent_elegido.length > 0){

                        datos_termite_view = {
                            ...datos_termite_view,
                            officer_name: termite_agent_elegido[0].officer_name || '',
                            email: termite_agent_elegido[0].email || '',
                            phone: termite_agent_elegido[0].phone || ''
                        };

                        datos_termite_agent_select = {
                            value: termite_agent_elegido[0]._id,
                            label: termite_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewTermiteData(datos_termite_view);
        await setTermiteSelectData(datos_termite_select);
        await setTermitesAgentsFilter(datos_termite_filter);
        await setTermiteAgentSelectData(datos_termite_agent_select);
    }

    const handleViewSeptic = async () => {
        let datos_septic_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            phone: ''
        };

        let datos_septic_select = null;
        
        let datos_septic_filter = [];

        let datos_septic_agent_select = null;

        if(proyectoData.id_septic !== null){
            let septic_elegido = await septics.septics?.filter(septic => String(septic._id) === String(proyectoData.id_septic));
            if(septic_elegido.length > 0){
                datos_septic_view = {
                    ...datos_septic_view,
                    company: septic_elegido[0].compania,
                    address: septic_elegido[0].direccion || '',
                    agents: septic_elegido[0].agentes
                };

                datos_septic_select = {
                    value: septic_elegido[0]._id,
                    label: septic_elegido[0].compania
                };

                let options_septic_agents = [];
                for (var i = 0; i < septic_elegido[0].agentes?.length; i++) {
                    options_septic_agents.push({ value: septic_elegido[0].agentes[i]._id, label: septic_elegido[0].agentes[i].officer_name });
                }
                datos_septic_filter = options_septic_agents;

                if(proyectoData.id_septic_agent !== null){
                    let septic_agent_elegido = await septic_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_septic_agent));
                    if(septic_agent_elegido.length > 0){

                        datos_septic_view = {
                            ...datos_septic_view,
                            officer_name: septic_agent_elegido[0].officer_name || '',
                            email: septic_agent_elegido[0].email || '',
                            phone: septic_agent_elegido[0].phone || ''
                        };

                        datos_septic_agent_select = {
                            value: septic_agent_elegido[0]._id,
                            label: septic_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewSepticData(datos_septic_view);
        await setSepticSelectData(datos_septic_select);
        await setSepticsAgentsFilter(datos_septic_filter);
        await setSepticAgentSelectData(datos_septic_agent_select);
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    const handleViewFiles = (files) => {
        if(files.length > 0){
            for(let i=0; i<files.length; i++){
                if(files[i]){
                    const url = URL.createObjectURL(files[i]);
                    window.open(url);
                }
            }
        }else{
            dispatch(setAlert('No files selected', 'danger'));
        }
    }

    const handleInputsAddEscrow = ({ target }) => {
        setAddEscrowData({
            ...addEscrowData,
            [target.name]: target.value
        });
    }

    const handleInputsAddEscrowAgent = ({ target }) => {
        setAddEscrowAgentData({
            ...addEscrowAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerBuyer = ({ target }) => {
        setAddBrokerBuyerData({
            ...addBrokerBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentBuyer = ({ target }) => {
        setAddBrokerAgentBuyerData({
            ...addBrokerAgentBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerSeller = ({ target }) => {
        setAddBrokerSellerData({
            ...addBrokerSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentSeller = ({ target }) => {
        setAddBrokerAgentSellerData({
            ...addBrokerAgentSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddLender = ({ target }) => {
        setAddLenderData({
            ...addLenderData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTitle = ({ target }) => {
        setAddTitleData({
            ...addTitleData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTitleAgent = ({ target }) => {
        setAddTitleAgentData({
            ...addTitleAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTermite = ({ target }) => {
        setAddTermiteData({
            ...addTermiteData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTermiteAgent = ({ target }) => {
        setAddTermiteAgentData({
            ...addTermiteAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddSeptic = ({ target }) => {
        setAddSepticData({
            ...addSepticData,
            [target.name]: target.value
        });
    }

    const handleInputsAddSepticAgent = ({ target }) => {
        setAddSepticAgentData({
            ...addSepticAgentData,
            [target.name]: target.value
        });
    }

    const handleChangeAddress = async (address, origin) => {
        switch(origin){
            case 'broker_buyer':
                await setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    address: address
                });
                break;

            case 'broker_seller':
                await setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    address: address
                });
                break;

            case 'escrow':
                await setAddEscrowData({
                    ...addEscrowData,
                    address: address
                });
                break;

            case 'lender':
                await setAddLenderData({
                    ...addLenderData,
                    address: address
                });
                break;

            case 'title':
                await setAddTitleData({
                    ...addTitleData,
                    address: address
                });
                break;

            case 'termite':
                await setAddTermiteData({
                    ...addTermiteData,
                    address: address
                });
                break;

            case 'septic':
                await setAddSepticData({
                    ...addSepticData,
                    address: address
                });
                break;

            default:
                break;
        }
    };

    const handleAddEscrow = async (e) => {
        e.preventDefault();
        if(addEscrowData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarEscrowMasterlist(addEscrowData, 1));

            await setProyectoData({
                ...proyectoData,
                id_escrow: proyectos.masterlist?.id_escrow_buy
            });

            await dispatch(guardarCambios(true));

            await setEscrowSelectData({
                value: proyectos.masterlist?.id_escrow_buy,
                label: addEscrowData.company
            });

            await setAddEscrowData({
                ...addEscrowData,
                company: '',
                address: ''
            });

            await setEscrowModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddEscrowAgent = async (e) => {
        e.preventDefault();
        if(addEscrowAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarEscrowAgentMasterlist(addEscrowAgentData, 1));

            await setProyectoData({
                ...proyectoData,
                id_escrow: proyectos.masterlist?.id_escrow_buy,
                id_escrow_agent: proyectos.masterlist?.id_escrow_agent_buy
            });

            await dispatch(guardarCambios(true));

            if(!addEscrowAgentData.id_escrow){
                await setEscrowSelectData({
                    value: proyectos.masterlist?.id_escrow_buy,
                    label: 'Without name'
                });
            }

            await setEscrowAgentSelectData({
                value: proyectos.masterlist?.id_escrow_agent_buy,
                label: addEscrowAgentData.officer_name
            });

            await setAddEscrowAgentData({
                ...addEscrowAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setEscrowAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddBrokerSeller = async (e) => {
        e.preventDefault();
        if(addBrokerSellerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistBuy(addBrokerSellerData, 2));

            await setProyectoData({
                ...proyectoData,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_buy
            });

            await dispatch(guardarCambios(true));

            await setBrokerSellerSelectData({
                value: proyectos.masterlist?.id_broker_seller_buy,
                label: addBrokerSellerData.company
            });

            await setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });

            await setBrokerSellerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentSeller = async (e) => {
        e.preventDefault();
        if(addBrokerAgentSellerData.officer_name.replace(/\s/g, '') !== ''){
            if(addBrokerAgentSellerData.license.replace(/\s/g, '') !== ''){
                await dispatch(agregarBrokerAgentMasterlistBuy(addBrokerAgentSellerData, 2));

                await setProyectoData({
                    ...proyectoData,
                    id_broker_seller: proyectos.masterlist?.id_broker_seller_buy,
                    id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_buy
                });

                await dispatch(guardarCambios(true));

                if(!addBrokerAgentSellerData.id_broker){
                    await setBrokerSellerSelectData({
                        value: proyectos.masterlist?.id_broker_seller_buy,
                        label: 'Without name'
                    });
                }

                await setBrokerAgentSellerSelectData({
                    value: proyectos.masterlist?.id_broker_agent_seller_buy,
                    label: addBrokerAgentSellerData.officer_name
                });

                await setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });

                await setBrokerAgentSellerModal(false);
            }else{
                dispatch(setAlert('First enter a license number', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter an agent name', 'danger'));
        }
    }

    const handleAddBrokerBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerBuyerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistBuy(addBrokerBuyerData, 1));

            await setProyectoData({
                ...proyectoData,
                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy
            });

            await dispatch(guardarCambios(true));

            await setBrokerBuyerSelectData({
                value: proyectos.masterlist?.id_broker_buyer_buy,
                label: addBrokerBuyerData.company
            });

            await setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });

            await setBrokerBuyerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerAgentBuyerData.officer_name.replace(/\s/g, '') !== ''){
            if(addBrokerAgentBuyerData.license.replace(/\s/g, '') !== ''){
                await dispatch(agregarBrokerAgentMasterlistBuy(addBrokerAgentBuyerData, 1));

                await setProyectoData({
                    ...proyectoData,
                    id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy,
                    id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_buy
                });

                await dispatch(guardarCambios(true));

                if(!addBrokerAgentBuyerData.id_broker){
                    await setBrokerBuyerSelectData({
                        value: proyectos.masterlist?.id_broker_buyer_buy,
                        label: 'Without name'
                    });
                }

                await setBrokerAgentBuyerSelectData({
                    value: proyectos.masterlist?.id_broker_agent_buyer_buy,
                    label: addBrokerAgentBuyerData.officer_name
                });

                await setAddBrokerAgentBuyerData({
                    ...addBrokerAgentBuyerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });

                await setBrokerAgentBuyerModal(false);
            }else{
                dispatch(setAlert('First enter a license number', 'warning'));
            }
        }else{
            dispatch(setAlert('First enter an agent name', 'warning'));
        }
    }

    const handleAddLender = async (e) => {
        e.preventDefault();
        if(addLenderData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarLenderMasterlistBuy(addLenderData));

            await setProyectoData({
                ...proyectoData,
                id_lender: proyectos.masterlist?.id_lender_buy,
                name_lender: proyectos.masterlist?.name_lender_buy
            });

            await dispatch(guardarCambios(true));

            await setLenderSelectData({
                value: proyectos.masterlist?.id_lender_buy,
                label: proyectos.masterlist?.name_lender_buy
            });

            await setAddLenderData({
                ...addLenderData,
                company: '',
                address: ''
            });

            await setLenderModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddTitle = async (e) => {
        e.preventDefault();
        if(addTitleData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarTitleMasterlistBuy(addTitleData));

            await setProyectoData({
                ...proyectoData,
                id_title: proyectos.masterlist?.id_title_buy
            });

            await dispatch(guardarCambios(true));

            await setTitleSelectData({
                value: proyectos.masterlist?.id_title_buy,
                label: addTitleData.company
            });

            await setAddTitleData({
                ...addTitleData,
                company: '',
                address: ''
            });

            await setTitleModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddTitleAgent = async (e) => {
        e.preventDefault();
        if(addTitleAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarTitleAgentMasterlist(addTitleAgentData, 1));

            await setProyectoData({
                ...proyectoData,
                id_title: proyectos.masterlist?.id_title_buy,
                id_title_agent: proyectos.masterlist?.id_title_agent_buy
            });

            await dispatch(guardarCambios(true));

            if(!addTitleAgentData.id_title){
                await setTitleSelectData({
                    value: proyectos.masterlist?.id_title_buy,
                    label: 'Without name'
                });
            }

            await setTitleAgentSelectData({
                value: proyectos.masterlist?.id_title_agent_buy,
                label: addTitleAgentData.officer_name
            });

            await setAddTitleAgentData({
                ...addTitleAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setTitleAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddTermite = async (e) => {
        e.preventDefault();
        if(addTermiteData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarTermiteMasterlistBuy(addTermiteData));

            await setProyectoData({
                ...proyectoData,
                id_termite: proyectos.masterlist?.id_termite_buy
            });

            await dispatch(guardarCambios(true));

            await setTermiteSelectData({
                value: proyectos.masterlist?.id_termite_buy,
                label: addTermiteData.company
            });

            await setAddTermiteData({
                ...addTermiteData,
                company: '',
                address: ''
            });

            await setTermiteModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddTermiteAgent = async (e) => {
        e.preventDefault();
        if(addTermiteAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarTermiteAgentMasterlist(addTermiteAgentData, 1));

            await setProyectoData({
                ...proyectoData,
                id_termite: proyectos.masterlist?.id_termite_buy,
                id_termite_agent: proyectos.masterlist?.id_termite_agent_buy
            });

            await dispatch(guardarCambios(true));

            if(!addTermiteAgentData.id_termite){
                await setTermiteSelectData({
                    value: proyectos.masterlist?.id_termite_buy,
                    label: 'Without name'
                });
            }

            await setTermiteAgentSelectData({
                value: proyectos.masterlist?.id_termite_agent_buy,
                label: addTermiteAgentData.officer_name
            });

            await setAddTermiteAgentData({
                ...addTermiteAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setTermiteAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddSeptic = async (e) => {
        e.preventDefault();
        if(addSepticData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarSepticMasterlistBuy(addSepticData));

            await setProyectoData({
                ...proyectoData,
                id_septic: proyectos.masterlist?.id_septic_buy
            });

            await dispatch(guardarCambios(true));

            await setSepticSelectData({
                value: proyectos.masterlist?.id_septic_buy,
                label: addSepticData.company
            });

            await setAddSepticData({
                ...addSepticData,
                company: '',
                address: ''
            });

            await setSepticModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddSepticAgent = async (e) => {
        e.preventDefault();
        if(addSepticAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarSepticAgentMasterlist(addSepticAgentData, 1));

            await setProyectoData({
                ...proyectoData,
                id_septic: proyectos.masterlist?.id_septic_buy,
                id_septic_agent: proyectos.masterlist?.id_septic_agent_buy
            });

            await dispatch(guardarCambios(true));

            if(!addSepticAgentData.id_septic){
                await setSepticSelectData({
                    value: proyectos.masterlist?.id_septic_buy,
                    label: 'Without name'
                });
            }

            await setSepticAgentSelectData({
                value: proyectos.masterlist?.id_septic_agent_buy,
                label: addSepticAgentData.officer_name
            });

            await setAddSepticAgentData({
                ...addSepticAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setSepticAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const numberToDecimal = (number, price = '$') => {
        if(isNaN(number)){
            return price + ' -';
        }else{
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = price + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }
    }

    const handleRenegotiation = () => {
        if(proyectos.masterlist?.escrow_closing !== null && proyectos.masterlist?.purchase_price !== null && proyectos.masterlist?.due_dilligence_end !== null && proyectos.masterlist?.escrow_deposit_amount !== null){
            setRenegotiationModal(true);
        }else{
            dispatch(setAlert('Complete all fields to renegotiate', 'warning'));
        }
    }

    const handleCloseModalRenegotiation = () => {
        setRenegotiationModal(false);
        setRenegotiationData({
            ...renegotiationData,
            purchase_price: null,
            purchase_price_date: new Date(),
            purchase_price_note: null,
            purchase_price_file_name: null,
            purchase_price_file: null,
            purchase_price_extension: null,
            due_dilligence_end: null,
            due_dilligence_end_date: new Date(),
            due_dilligence_end_note: null,
            due_dilligence_end_file_name: null,
            due_dilligence_end_file: null,
            due_dilligence_end_extension: null,
            escrow_deposit_amount: null,
            escrow_deposit_amount_date: new Date(),
            escrow_deposit_amount_note: null,
            escrow_deposit_amount_file_name: null,
            escrow_deposit_amount_file: null,
            escrow_deposit_amount_extension: null,
            escrow_deposit_increase: false,
            escrow_deposit_release: false,
            escrow_how_much_released: null,
            funds_applied_purchase_price: true,
            note_funds_applied: null,
            who_funds_applied: null,
            escrow_closing_be_change: false,
            escrow_closing: null,
            escrow_closing_date: new Date(),
            escrow_closing_note: null,
            escrow_closing_file_name: null,
            escrow_closing_file: null,
            escrow_closing_extension: null,
            per_diem: false,
            amount_per_day: null
        });
    }

    const handleModalContinue = async (e) => {
        e.preventDefault();

        setContinueModal(true);
    }

    const handleCloseModalContinue = () => {
        setContinueModal(false);
        
        setCancelPurchaseData({
            ...cancelPurchaseData,
            status: false,
            text: 'Cancel purchase'
        });

        setOnHoldData({
            ...onHoldData,
            status: false,
            text: 'On hold'
        });

        setClosePurchaseData({
            ...closePurchaseData,
            status: false,
            text: 'Close purchase'
        });
    }

    const handleInputsRenegotiation = ({ target }) => {
        setRenegotiationData({
            ...renegotiationData,
            [target.name]: target.value
        });
    }

    const handleInputsNumberRenegotiation = (name, value) => {
        setRenegotiationData({
            ...renegotiationData,
            [name]: value
        });

        let valores = {
            ...renegotiationData,
            [name]: value
        };

        if(name === 'escrow_deposit_amount'){
            valores.escrow_deposit_release = false;
            valores.funds_applied_purchase_price = null;
            valores.note_funds_applied = null;
            valores.who_funds_applied = null;
        }

        setRenegotiationData(valores);
    }

    const handleRadiobuttonRenegotiation = ({ target }) => {
        if(target.checked){
            let valores = {
                ...renegotiationData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            };

            if(target.name === 'escrow_deposit_increase'){
                valores.escrow_deposit_amount = null;
                valores.escrow_deposit_release = false;
                valores.funds_applied_purchase_price = true;
                valores.note_funds_applied = null;
                valores.who_funds_applied = null;
            }

            if(target.name === 'escrow_deposit_release'){
                valores.funds_applied_purchase_price = true;
                valores.note_funds_applied = null;
                valores.who_funds_applied = null;
            }

            if(target.name === 'funds_applied_purchase_price'){
                valores.note_funds_applied = null;
                valores.who_funds_applied = null;
            }

            if(target.name === 'per_diem'){
                valores.amount_per_day = null;
            }

            if(target.name === 'escrow_closing_be_change'){
                valores.escrow_closing = null;
                valores.per_diem = false;                
                valores.amount_per_day = null;
            }

            setRenegotiationData(valores);
        }
    }

    const onChangeFilePurchaseRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < purchase_price_ref?.current?.files?.length; i++){
            let nombre_separado = purchase_price_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            purchase_price_file: purchase_price_ref.current.files,
            purchase_price_extension: extensiones
        });
    }

    const onChangeFileDueDilligenceEndRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < due_dilligence_end_ref?.current?.files?.length; i++){
            let nombre_separado = due_dilligence_end_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            due_dilligence_end_file: due_dilligence_end_ref.current.files,
            due_dilligence_end_extension: extensiones
        });
    }

    const onChangeFileEscrowDepositAmountRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < escrow_deposit_amount_ref?.current?.files?.length; i++){
            let nombre_separado = escrow_deposit_amount_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            escrow_deposit_amount_file: escrow_deposit_amount_ref.current.files,
            escrow_deposit_amount_extension: extensiones
        });
    }

    const onChangeFileEscrowClosingRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < escrow_closing_ref?.current?.files?.length; i++){
            let nombre_separado = escrow_closing_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            escrow_closing_file: escrow_closing_ref.current.files,
            escrow_closing_extension: extensiones
        });
    }

    const confirmCancelPurchase = async() => {
        if(cancelPurchaseData.status){
            handleSelectStatus('Cancelled');
        }else{
            await setCancelPurchaseData({
                ...cancelPurchaseData,
                status: true,
                text: 'Confirm Cancel purchase'
            });

            await setOnHoldData({
                ...onHoldData,
                status: false,
                text: 'On Hold',
            });

            await setClosePurchaseData({
                ...closePurchaseData,
                status: false,
                text: 'Close purchase'
            });

            await setReactivateData({
                ...reactivateData,
                status: false,
                text: 'Reactivate'
            });
        }
    }

    const confirmOnHold = async() => {
        if(onHoldData.status){
            handleSelectStatus('On hold');
        }else{
            await setOnHoldData({
                ...onHoldData,
                status: true,
                text: 'Confirm on Hold',
            });

            await setCancelPurchaseData({
                ...cancelPurchaseData,
                status: false,
                text: 'Cancel purchase'
            });

            await setClosePurchaseData({
                ...closePurchaseData,
                status: false,
                text: 'Close purchase'
            });

            await setReactivateData({
                ...reactivateData,
                status: false,
                text: 'Reactivate'
            });
        }
    }

    const confirmReactivatePurchase = async() => {
        if(reactivateData.status){
            handleSelectStatus('Buying escrow');
        }else{
            await setReactivateData({
                ...reactivateData,
                status: true,
                text: 'Confirm Reactivate'
            });

            await setCancelPurchaseData({
                ...cancelPurchaseData,
                status: false,
                text: 'Cancel purchase'
            });

            await setOnHoldData({
                ...onHoldData,
                status: false,
                text: 'On Hold',
            });

            await setClosePurchaseData({
                ...closePurchaseData,
                status: false,
                text: 'Close purchase'
            });
        }
    }

    const handleSelectStatus = (status_nombre) => {
        const tipo_status_elegido = tipos.status?.filter(tipo => String(tipo.nombre) === String(status_nombre));
        
        setProyectoData({
            ...proyectoData,
            status: tipo_status_elegido[0]?._id, 
            nombre_status: tipo_status_elegido[0]?.nombre
        });

        dispatch(guardarCambios(true));
    };

    const handleSubmitRenegotiation = async (e) => {
        e.preventDefault();
        setSpinner(true);
        if((renegotiationData.purchase_price !== null && renegotiationData.purchase_price.replace(/\s/g, '') !== '') || renegotiationData.due_dilligence_end !== null || (renegotiationData.escrow_deposit_amount !== null && renegotiationData.escrow_deposit_amount.replace(/\s/g, '') !== '') || renegotiationData.escrow_closing !== null){

            let formData = new FormData();
            formData.append('id_proyecto', renegotiationData.id_proyecto);
            formData.append('id_status', renegotiationData.id_status);
            formData.append('purchase_price', renegotiationData.purchase_price);
            formData.append('purchase_price_date', renegotiationData.purchase_price_date);
            formData.append('purchase_price_note', renegotiationData.purchase_price_note);
            formData.append('purchase_price_file_name', renegotiationData.purchase_price_file_name);

            if(renegotiationData.purchase_price_extension !== null){
                for (let i = 0; i < renegotiationData.purchase_price_extension.length; i++) {
                    formData.append('purchase_price_extension', renegotiationData.purchase_price_extension[i]);
                }   
            }

            if(renegotiationData.purchase_price_file !== null){
                for (let i = 0; i < renegotiationData.purchase_price_file.length; i++) {
                    formData.append('purchase_price_file', renegotiationData.purchase_price_file[i]);
                }   
            }

            formData.append('due_dilligence_end', renegotiationData.due_dilligence_end);
            formData.append('due_dilligence_end_date', renegotiationData.due_dilligence_end_date);
            formData.append('due_dilligence_end_note', renegotiationData.due_dilligence_end_note);
            formData.append('due_dilligence_end_file_name', renegotiationData.due_dilligence_end_file_name);

            if(renegotiationData.due_dilligence_end_extension !== null){
                for (let i = 0; i < renegotiationData.due_dilligence_end_extension.length; i++) {
                    formData.append('due_dilligence_end_extension', renegotiationData.due_dilligence_end_extension[i]);
                }   
            }

            if(renegotiationData.due_dilligence_end_file !== null){
                for (let i = 0; i < renegotiationData.due_dilligence_end_file.length; i++) {
                    formData.append('due_dilligence_end_file', renegotiationData.due_dilligence_end_file[i]);
                }   
            }

            formData.append('escrow_deposit_amount', renegotiationData.escrow_deposit_amount);
            formData.append('escrow_deposit_amount_date', renegotiationData.escrow_deposit_amount_date);
            formData.append('escrow_deposit_amount_note', renegotiationData.escrow_deposit_amount_note);
            formData.append('escrow_deposit_amount_file_name', renegotiationData.escrow_deposit_amount_file_name);

            if(renegotiationData.escrow_deposit_amount_extension !== null){
                for (let i = 0; i < renegotiationData.escrow_deposit_amount_extension.length; i++) {
                    formData.append('escrow_deposit_amount_extension', renegotiationData.escrow_deposit_amount_extension[i]);
                }   
            }

            if(renegotiationData.escrow_deposit_amount_file !== null){
                for (let i = 0; i < renegotiationData.escrow_deposit_amount_file.length; i++) {
                    formData.append('escrow_deposit_amount_file', renegotiationData.escrow_deposit_amount_file[i]);
                }   
            }

            formData.append('escrow_deposit_increase', renegotiationData.escrow_deposit_increase);
            formData.append('escrow_deposit_release', renegotiationData.escrow_deposit_release);
            formData.append('escrow_how_much_released', renegotiationData.escrow_how_much_released);
            formData.append('funds_applied_purchase_price', renegotiationData.funds_applied_purchase_price);
            formData.append('note_funds_applied', renegotiationData.note_funds_applied);
            formData.append('who_funds_applied', renegotiationData.who_funds_applied);
            formData.append('escrow_closing_be_change', renegotiationData.escrow_closing_be_change);
            formData.append('escrow_closing', renegotiationData.escrow_closing);
            formData.append('escrow_closing_date', renegotiationData.escrow_closing_date);
            formData.append('escrow_closing_note', renegotiationData.escrow_closing_note);
            formData.append('escrow_closing_file_name', renegotiationData.escrow_closing_file_name);

            if(renegotiationData.escrow_closing_extension !== null){
                for (let i = 0; i < renegotiationData.escrow_closing_extension.length; i++) {
                    formData.append('escrow_closing_extension', renegotiationData.escrow_closing_extension[i]);
                }   
            }

            if(renegotiationData.escrow_closing_file !== null){
                for (let i = 0; i < renegotiationData.escrow_closing_file.length; i++) {
                    formData.append('escrow_closing_file', renegotiationData.escrow_closing_file[i]);
                }   
            }

            formData.append('per_diem', renegotiationData.per_diem);
            formData.append('amount_per_day', renegotiationData.amount_per_day);

            dispatch(renegotiateInEscrow(formData));

            let datos_renegotiation = {
                ...proyectoData,
                escrow_closing: renegotiationData.escrow_closing !== null ? new Date(renegotiationData.escrow_closing) : proyectoData.escrow_closing
            };

            if(renegotiationData.purchase_price !== null){
                datos_renegotiation.purchase_price_history = [
                    ...proyectoData.purchase_price_history,
                    {
                        amount: renegotiationData.purchase_price,
                        type: 1,
                        fecha: renegotiationData.purchase_price_date !== null ? new Date(renegotiationData.purchase_price_date) : new Date()
                    }
                ];
            }

            if(renegotiationData.escrow_deposit_amount !== null){
                datos_renegotiation.escrow_deposit_amount_history = [
                    ...proyectoData.escrow_deposit_amount_history,
                    {
                        amount: renegotiationData.escrow_deposit_amount,
                        escrow_deposit_increase: renegotiationData.escrow_deposit_increase,
                        escrow_deposit_release: renegotiationData.escrow_deposit_release,
                        funds_applied_purchase_price: renegotiationData.funds_applied_purchase_price,
                        note_funds_applied: renegotiationData.note_funds_applied,
                        who_funds_applied: renegotiationData.who_funds_applied,
                        fecha: renegotiationData.escrow_deposit_amount_date !== null ? new Date(renegotiationData.escrow_deposit_amount_date) : new Date()
                    }
                ];
            }

            if(renegotiationData.escrow_closing !== null){
                datos_renegotiation.escrow_closing_history = [
                    ...proyectoData.escrow_closing_history,
                    {
                        date: renegotiationData.escrow_closing,
                        per_diem: renegotiationData.per_diem,
                        amount_per_day: renegotiationData.amount_per_day,
                        fecha: renegotiationData.escrow_closing_date !== null ? new Date(renegotiationData.escrow_closing_date) : new Date()
                    }
                ];
            }

            if(renegotiationData.due_dilligence_end !== null){
                datos_renegotiation.due_dilligence_end_history = [
                    ...proyectoData.due_dilligence_end_history,
                    {
                        date: renegotiationData.due_dilligence_end,
                        fecha: renegotiationData.due_dilligence_end_date !== null ? new Date(renegotiationData.due_dilligence_end_date) : new Date()
                    }
                ];
            }

            setProyectoData(datos_renegotiation);

            setTimeout(async() => {
                dispatch(guardarCambios(true));
                setSpinner(false);
            }, 0);

            setRenegotiationData({
                ...renegotiationData,
                purchase_price: null,
                purchase_price_date: new Date(),
                purchase_price_note: null,
                purchase_price_file_name: null,
                purchase_price_file: null,
                purchase_price_extension: null,
                due_dilligence_end: null,
                due_dilligence_end_date: new Date(),
                due_dilligence_end_note: null,
                due_dilligence_end_file_name: null,
                due_dilligence_end_file: null,
                due_dilligence_end_extension: null,
                escrow_deposit_amount: null,
                escrow_deposit_amount_date: new Date(),
                escrow_deposit_amount_note: null,
                escrow_deposit_amount_file_name: null,
                escrow_deposit_amount_file: null,
                escrow_deposit_amount_extension: null,
                escrow_deposit_increase: false,
                escrow_deposit_release: false,
                escrow_how_much_released: null,
                funds_applied_purchase_price: true,
                note_funds_applied: null,
                who_funds_applied: null,
                escrow_closing_be_change: false,
                escrow_closing: null,
                escrow_closing_date: new Date(),
                escrow_closing_note: null,
                escrow_closing_file_name: null,
                escrow_closing_file: null,
                escrow_closing_extension: null,
                per_diem: false,
                amount_per_day: null
            });
        }else{
            dispatch(setAlert('Enter at least one field to renegotiate', 'warning'));
            setSpinner(false);
        }
    }

    const handleSubmitModal = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarInEscrow(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        
        dispatch(redireccionar(proyectos.paginaRedireccion));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarInEscrow(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const handleCancelled = async () => {
        if (proyectoData.status !== null) {
            await setGuardarCambiosModal(false);
            await dispatch(closeInEscrow(proyectoData));
            await setProyectoData({
                ...proyectoData,
                status: null, 
                nombre_status: null 
            });
            await dispatch(masterlistProyecto(proyectoData.id));
            await dispatch(detallesProyecto(proyectoData.id));
            await dispatch(seleccionarSeccionMasterList('Buying escrow'));
            handleCloseModalContinue();
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    const handleOnHold = async () => {
        if (proyectoData.status !== null) {
            await setGuardarCambiosModal(false);
            await dispatch(closeInEscrow(proyectoData));
            await setProyectoData({
                ...proyectoData,
                status: null, 
                nombre_status: null 
            });
            await dispatch(masterlistProyecto(proyectoData.id));
            await dispatch(detallesProyecto(proyectoData.id));
            await dispatch(seleccionarSeccionMasterList('Buying escrow'));
            handleCloseModalContinue();
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    const handleContinue = async (e) => {
        e.preventDefault();

        if(closePurchaseData.status){
            if (proyectoData.escrow_open !== null && proyectoData.escrow_closing !== null && proyectoData.escrow_deposit_send !== null && proyectoData.real_escrow_closing !== null && proyectoData.funds_available === true && proyectoData.purchase_price_adjustment === false) {
                setSpinner(true);
                setTimeout(async() => {
                    await setGuardarCambiosModal(false);
                    await handleCloseModalContinue();
                    await dispatch(continuarInEscrow(proyectoData));
                    await dispatch(masterlistProyecto(proyectoData.id));
                    await dispatch(detallesProyecto(proyectoData.id));
                    setSpinner(false);
                    await dispatch(seleccionarSeccionMasterList('Waiting for repairs'));
                }, 0);
            } else {
                await dispatch(setAlert('Missing information', 'danger'));
            }
        }else{
            await setClosePurchaseData({
                ...closePurchaseData,
                status: true,
                text: 'Confirm purchase'
            });

            await setOnHoldData({
                ...onHoldData,
                status: false,
                text: 'On Hold',
            });

            await setCancelPurchaseData({
                ...cancelPurchaseData,
                status: false,
                text: 'Cancel purchase'
            });
        }
    };

    const focusCampo = ({target}) => {
        let id_focusear = target.dataset.focus;
        let campo = document.getElementById(id_focusear);
        campo.focus();
    };

    // Tabla
    const tableRow = (items) => {
        if(items !== null){

            items = items.filter(archivo => (archivo.subseccion !== null && (archivo.subseccion * 2) === (sectionInformation - 1)));

            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td>{item.nombre || '-'}</td>
                                            <td>{item.extension || '-'}</td>
                                            <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                            <td>
                                                <button className="btn" type="button" onClick={() => { handleIconoArchivo(item._id) }}><i className="fa fa-file-download" style={styles.buttonIcon} /></button>
                                                <button className="btn" type="button" onClick={() => { handleEditarArchivo(item) }}><i className="fas fa-edit" style={styles.buttonIcon} /></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    useEffect(() => {

        if(proyectoData.status !== null && proyectoData.nombre_status !== null){
            if(proyectoData.nombre_status === 'On hold'){
                handleOnHold();
            }

            if(proyectoData.nombre_status === 'Cancelled'){
                handleCancelled();
            }

            if(proyectoData.nombre_status === 'Buying escrow'){
                handleCancelled();
            }
        }

        if (proyectoData.escrow_open !== null && proyectoData.escrow_closing !== null && proyectoData.escrow_deposit_send !== null && proyectoData.real_escrow_closing !== null && proyectoData.funds_available === true && proyectoData.purchase_price_adjustment === false) {
            setMostrarBoton(true);
        }else {
            setMostrarBoton(false);
        }

        handleViewEscrow();

        handleViewBrokerSeller();

        handleViewBrokerBuyer();

        if(proyectoData.id_lender !== null){
            handleViewLender();
        }else{
            setViewLenderData({
                ...viewLenderData,
                company: '',
                address: ''
            });
        }

        handleViewTitle();

        handleViewTermite();

        handleViewSeptic();
    }, [proyectoData]);

    useEffect(() => {

        let options_escrow = [];
        let options_broker = [];
        let options_lender = [];
        let options_title = [];
        let options_termite = [];
        let options_septic = [];
        let options_usuario = [];

        for (let i = 0; i < escrows.escrows?.length; i++) {
            options_escrow.push({ value: escrows.escrows[i]._id, label: escrows.escrows[i].compania });
        }

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options_broker.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }        

        for (let i = 0; i < lenders.lenders?.length; i++) {
            options_lender.push({ value: lenders.lenders[i]._id, label: lenders.lenders[i].compania });
        }

        for (let i = 0; i < titles.titles?.length; i++) {
            options_title.push({ value: titles.titles[i]._id, label: titles.titles[i].compania });
        }

        for (let i = 0; i < termites.termites?.length; i++) {
            options_termite.push({ value: termites.termites[i]._id, label: termites.termites[i].compania });
        }

        for (let i = 0; i < septics.septics?.length; i++) {
            options_septic.push({ value: septics.septics[i]._id, label: septics.septics[i].compania });
        }

        for (let i = 0; i < usuarios?.length; i++) {
            options_usuario.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setEscrowsFilter(options_escrow);
        setBrokersFilter(options_broker);
        setLendersFilter(options_lender);
        setTitlesFilter(options_title);
        setTermitesFilter(options_termite);
        setSepticsFilter(options_septic);
        setUsersFilter(options_usuario);

        dispatch(buscarTiposProyectos(null));
    }, []);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < escrows.escrows?.length; i++) {
            options.push({ value: escrows.escrows[i]._id, label: escrows.escrows[i].compania });
        }

        setEscrowsFilter(options);
    }, [escrows]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < brokers.brokers?.length; i++) {
            options.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options);
    }, [brokers]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < lenders.lenders?.length; i++) {
            options.push({ value: lenders.lenders[i]._id, label: lenders.lenders[i].compania });
        }

        setLendersFilter(options);
    }, [lenders]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < titles.titles?.length; i++) {
            options.push({ value: titles.titles[i]._id, label: titles.titles[i].compania });
        }

        setTitlesFilter(options);
    }, [titles]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < termites.termites?.length; i++) {
            options.push({ value: termites.termites[i]._id, label: termites.termites[i].compania });
        }

        setTermitesFilter(options);
    }, [termites]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < septics.septics?.length; i++) {
            options.push({ value: septics.septics[i]._id, label: septics.septics[i].compania });
        }

        setSepticsFilter(options);
    }, [septics]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {

        if (proyectos.detalles !== undefined && proyectos.detalles !== null) {
            setNewFileData({
                ...newFileData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(proyectos.guardar_cambios === false){

            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id,
                escrow_open: proyectos.masterlist?.escrow_open,
                escrow_information: proyectos.masterlist?.escrow_information_buy,
                name_escrow_information_user: proyectos.masterlist?.name_escrow_information_user_buy,
                escrow_information_user: proyectos.masterlist?.escrow_information_user_buy,
                escrow_closing: proyectos.masterlist?.escrow_closing,
                escrow_deposit_send_question: proyectos.masterlist?.escrow_deposit_send !== null ? true: false,
                escrow_deposit_send: proyectos.masterlist?.escrow_deposit_send,
                escrow_instructions: proyectos.masterlist?.escrow_instructions_buy,
                escrow_instructions_date: proyectos.masterlist?.escrow_instructions_date_buy,
                escrow_number: proyectos.masterlist?.escrow_number_buy,
                real_escrow_closing: proyectos.masterlist?.real_escrow_closing !== null ? proyectos.masterlist?.real_escrow_closing : proyectos.masterlist?.escrow_closing,
                funds_available: proyectos.masterlist?.funds_available,
                purchase_price_adjustment: proyectos.masterlist?.purchase_price_adjustment,
                
                id_escrow: proyectos.masterlist?.id_escrow_buy,
                id_escrow_agent: proyectos.masterlist?.id_escrow_agent_buy,

                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy,
                id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_buy,
                broker_represent: proyectos.masterlist?.broker_represent_buy,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_buy,
                id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_buy,

                id_lender: proyectos.masterlist?.id_lender_buy,
                name_lender: proyectos.masterlist?.name_lender_buy,
                lender_officer_name: proyectos.masterlist?.lender_officer_name_buy,
                lender_email: proyectos.masterlist?.lender_email_buy,
                lender_cell_phone: proyectos.masterlist?.lender_cell_phone_buy,
                lender_phone: proyectos.masterlist?.lender_phone_buy,
                lender_amount: proyectos.masterlist?.lender_amount_buy,
                lender_yearly_rate: proyectos.masterlist?.lender_yearly_rate_buy,
                lender_origination_points: proyectos.masterlist?.lender_origination_points_buy,
                lender_other_fees: proyectos.masterlist?.lender_other_fees_buy,

                id_title: proyectos.masterlist?.id_title_buy,
                id_title_agent: proyectos.masterlist?.id_title_agent_buy,
                title_preliminary: proyectos.masterlist?.title_preliminary_buy,
                title_preliminary_date: proyectos.masterlist?.title_preliminary_date_buy,
                title_review: proyectos.masterlist?.title_review_buy,
                title_review_who: proyectos.masterlist?.title_review_who_buy,
                title_rep: proyectos.masterlist?.title_rep_buy,
                title_order: proyectos.masterlist?.title_order_buy,
                
                id_termite: proyectos.masterlist?.id_termite_buy,
                id_termite_agent: proyectos.masterlist?.id_termite_agent_buy,
                termite_inspection: proyectos.masterlist?.termite_inspection_buy,
                name_termite_inspection_user: proyectos.masterlist?.name_termite_inspection_user_buy,
                termite_inspection_user: proyectos.masterlist?.termite_inspection_user_buy,
                termite_inspection_date: proyectos.masterlist?.termite_inspection_date_buy,
                report_number_termite: proyectos.masterlist?.report_number_termite_buy,

                id_septic: proyectos.masterlist?.id_septic_buy,
                id_septic_agent: proyectos.masterlist?.id_septic_agent_buy,
                septic_inspection: proyectos.masterlist?.septic_inspection_buy,
                name_septic_inspection_user: proyectos.masterlist?.name_septic_inspection_user_buy,
                septic_inspection_user: proyectos.masterlist?.septic_inspection_user_buy,
                septic_inspection_date: proyectos.masterlist?.septic_inspection_date_buy,
                report_number_septic: proyectos.masterlist?.report_number_septic_buy,
                
                purchase_price_history: proyectos.masterlist?.purchase_price_history,
                escrow_deposit_amount_history: proyectos.masterlist?.escrow_deposit_amount_history,
                escrow_closing_history: proyectos.masterlist?.escrow_closing_history,
                due_dilligence_end_history: proyectos.masterlist?.due_dilligence_end_history
            });

            if(proyectos.masterlist?.escrow_information_user_buy !== null){
                setUserEscrowInformationData({
                    value: proyectos.masterlist?.escrow_information_user_buy,
                    label: proyectos.masterlist?.name_escrow_information_user_buy
                });
            }else{
                setUserEscrowInformationData(null);
            }            

            if(proyectos.masterlist?.id_lender_buy !== null){
                setLenderSelectData({
                    value: proyectos.masterlist?.id_lender_buy,
                    label: proyectos.masterlist?.name_lender_buy
                });
            }else{
                setLenderSelectData(null);
            }

            if(proyectos.masterlist?.termite_inspection_user_buy !== null){
                setUserTermiteSelectData({
                    value: proyectos.masterlist?.termite_inspection_user_buy,
                    label: proyectos.masterlist?.name_termite_inspection_user_buy
                });
            }else{
                setUserTermiteSelectData(null);
            }

            if(proyectos.masterlist?.septic_inspection_user_buy !== null){
                setUserSepticSelectData({
                    value: proyectos.masterlist?.septic_inspection_user_buy,
                    label: proyectos.masterlist?.name_septic_inspection_user_buy
                });
            }else{
                setUserSepticSelectData(null);
            }

            setDataTabla({
                archivosTabla: proyectos.files
            });

            setRenegotiationData({
                ...renegotiationData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(proyectos.modal_guardado_flipping === true){
            handleGuardarCambiosModal();
        }
    }, [proyectos]);

    //State para preguntar si salir del modal
    const [preguntarGuardar, setPreguntarGuardar] = useState(false);

    return (
        <Fragment>
            {/* Modal de guardado de cambios */}
            <Modal backdrop="static" show={guardarCambiosModal} onHide={() => {
                 if(preguntarGuardar){
                    handleCloseGuardarCambiosModal();
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit?</p>
                    :
                        <p>Do you want to save the changes?</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                            <>
                                <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                                <button className="btn btn-success" onClick={() => handleCloseNoGuardarCambiosModal()}>Yes</button>
                            </>
                            :
                            <>
                                <button className="btn btn-danger mr-3" onClick={() => handleCloseNoGuardarCambiosModal()}>No</button>
                                <button className="btn btn-success" onClick={handleSubmitModal}>Yes</button>
                            </>
                    }
                </Modal.Footer>
            </Modal>

            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}

            {/* Modal de cierre de compra */}
            <Modal backdrop="static" show={continueModal} onHide={() => {
                handleCloseModalContinue();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Escrow status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the status to which you are going to <b>continue</b>.</p>
                        </div>
                        {
                            closePurchaseData.status ?
                                <Fragment>
                                    <div className="col-md-12 form-group campo_fecha">
                                        <label className="font-weight-bold">Estimated escrow closing date:</label>
                                        <div className="contenedor_datepicker">
                                            <div className="d-flex">
                                                <span className="input-group-text" data-focus="estimated">
                                                    <i className="fas fa-calendar-alt" data-focus="estimated"></i>
                                                </span>
                                                <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={proyectoData.escrow_closing ? new Date(proyectoData.escrow_closing) : ''} disabled={true}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'col-md-12 form-group campo_fecha' + (proyectoData.real_escrow_closing !== null ? '' : ' campo-recordatorio')}>
                                        <label className="font-weight-bold">* COE Date:</label>
                                        <div className="contenedor_datepicker">
                                            <div className="d-flex">
                                                <span className="input-group-text" data-focus="estimated">
                                                    <i className="fas fa-calendar-alt" data-focus="estimated"></i>
                                                </span>
                                                <DatePicker name="real_escrow_closing" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.real_escrow_closing ? new Date(proyectoData.real_escrow_closing) : new Date(proyectoData.escrow_closing) } onChange={ date => {setProyectoData({ ...proyectoData, real_escrow_closing: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false}/>
                                            </div>
                                        </div>
                                        <div className={new Date(proyectoData.escrow_closing) > new Date(proyectoData.real_escrow_closing) ? "col-md-12 mt-3" : "d-none"}>
                                            <p className="text-center alert-danger">COE Date lower than Estimated</p>
                                        </div>
                                    </div>
                                </Fragment>
                            :
                                <></>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        proyectos.detalles?.proyecto?.nombre_status === 'On hold' ? 
                            <>
                                <button className={cancelPurchaseData.status ? 'btn btn-success' : 'btn btn-danger'} onClick={() => confirmCancelPurchase()}>{cancelPurchaseData.text}</button>
                                <button className={reactivateData.status ? 'btn btn-success' : 'btn btn-warning'} onClick={() => confirmReactivatePurchase()}>{reactivateData.text}</button>
                            </>
                        :
                        <>
                            <button className={cancelPurchaseData.status ? 'btn btn-success' : 'btn btn-danger'} onClick={() => confirmCancelPurchase()}>{cancelPurchaseData.text}</button>
                            <button type="button" className={onHoldData.status ? 'btn btn-success' : 'btn btn-warning'} onClick={() => confirmOnHold()}>{onHoldData.text}</button>
                            <button className={closePurchaseData.status ? 'btn btn-success' : 'btn btn-success'} onClick={handleContinue}>{closePurchaseData.text}</button>
                        </>
                    }
                    
                </Modal.Footer>
            </Modal>

            {/* Modal de escrow de compra */}
            <Modal backdrop="static" show={escrowModal} size="lg" onHide={() => {
                setEscrowModal(false);
                setAddEscrowData({
                    ...addEscrowData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Escrow information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the escrow you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddEscrow(e)}/>
                            </div>
                        </div>
                        <PlacesAutocomplete
                            value={addEscrowData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'escrow')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalEscrow(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddEscrow}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de escrow agente de compra */}
            <Modal backdrop="static" show={escrowAgentModal} size="lg" onHide={() => {
                setEscrowAgentModal(false);
                setAddEscrowAgentData({
                    ...addEscrowAgentData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Escrow agent information ({escrowSelectData ? escrowSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddEscrowAgent(e)} value={addEscrowAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddEscrowAgent(e)} value={addEscrowAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addEscrowAgentData.cell_phone} onChange={phone => {setAddEscrowAgentData({...addEscrowAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addEscrowAgentData.phone} onChange={phone => {setAddEscrowAgentData({...addEscrowAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalEscrow(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddEscrowAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker vendedor de compra */}
            <Modal backdrop="static" show={brokerSellerModal} size="lg" onHide={() => {
                setBrokerSellerModal(false);
                setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (buy)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerSeller(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addBrokerSellerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_seller')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente vendedor de compra */}
            <Modal backdrop="static" show={brokerAgentSellerModal} size="lg" onHide={() => {
                setBrokerAgentSellerModal(false);
                setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (listing) ({brokerSellerSelectData ? brokerSellerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.celular} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.telefono} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker comprador de compra */}
            <Modal backdrop="static" show={brokerBuyerModal} size="lg" onHide={() => {
                setBrokerBuyerModal(false);
                setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (buy)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerBuyer(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addBrokerBuyerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_buyer')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente comprador de compra */}
            <Modal backdrop="static" show={brokerAgentBuyerModal} size="lg" onHide={() => {
                setBrokerAgentBuyerModal(false);
                setAddBrokerAgentBuyerData({
                    ...addBrokerAgentBuyerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (buy) ({brokerBuyerSelectData ? brokerBuyerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.celular} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.telefono} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de lender de compra */}
            <Modal backdrop="static" show={lenderModal} size="lg" onHide={() => {
                setLenderModal(false);
                setAddLenderData({
                    ...addLenderData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Lender information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the lender you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddLender(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addLenderData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'lender')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalLender()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddLender}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de title de compra */}
            <Modal backdrop="static" show={titleModal} size="lg" onHide={() => {
                setTitleModal(false);
                setAddTitleData({
                    ...addTitleData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Title information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the title you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddTitle(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addTitleData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'title')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTitle()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTitle}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de title agente de compra */}
            <Modal backdrop="static" show={titleAgentModal} size="lg" onHide={() => {
                setTitleAgentModal(false);
                setAddTitleAgentData({
                    ...addTitleAgentData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Title agent information ({titleSelectData ? titleSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddTitleAgent(e)} value={addTitleAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddTitleAgent(e)} value={addTitleAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addTitleAgentData.cell_phone} onChange={phone => {setAddTitleAgentData({...addTitleAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addTitleAgentData.phone} onChange={phone => {setAddTitleAgentData({...addTitleAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTitle(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTitleAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de termite de compra */}
            <Modal backdrop="static" show={termiteModal} size="lg" onHide={() => {
                setTermiteModal(false);
                setAddTermiteData({
                    ...addTermiteData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Termite information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <p style={styles.info}>If the termite you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input name="company" className="form-control" type="text" onChange={e => handleInputsAddTermite(e)}/>
                            </div>
                        </div>
                        
                        <PlacesAutocomplete
                            value={addTermiteData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'termite')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTermite()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTermite}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de termite agente de compra */}
            <Modal backdrop="static" show={termiteAgentModal} size="lg" onHide={() => {
                setTermiteAgentModal(false);
                setAddTermiteAgentData({
                    ...addTermiteAgentData,
                    officer_name: '',
                    email: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Termite agent information ({termiteSelectData ? termiteSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddTermiteAgent(e)} value={addTermiteAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddTermiteAgent(e)} value={addTermiteAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addTermiteAgentData.phone} onChange={phone => {setAddTermiteAgentData({...addTermiteAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTermite(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTermiteAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de septic de compra */}
            <Modal backdrop="static" show={septicModal} size="lg" onHide={() => {
                setSepticModal(false);
                setAddSepticData({
                    ...addSepticData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Septic information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the septic you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddSeptic(e)}/>
                            </div>
                        </div>
                        
                        <PlacesAutocomplete
                            value={addSepticData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'septic')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalSeptic()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddSeptic}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de septic agente de compra */}
            <Modal backdrop="static" show={septicAgentModal} size="lg" onHide={() => {
                setSepticAgentModal(false);
                setAddSepticAgentData({
                    ...addSepticAgentData,
                    officer_name: '',
                    email: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Septic agent information ({septicSelectData ? septicSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddSepticAgent(e)} value={addSepticAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddSepticAgent(e)} value={addSepticAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addSepticAgentData.phone} onChange={phone => {setAddSepticAgentData({...addSepticAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalSeptic(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddSepticAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de renegociacion de venta */}
            <Modal backdrop="static" show={renegotiationModal} centered size="xl" onHide={() => {
                if(preguntarGuardar){
                    setUserFundsAppliedData(null);
                    setRenegotiationModal(false);
                    setRenegotiationData({
                        ...renegotiationData,
                        purchase_price: null,
                        purchase_price_date: new Date(),
                        purchase_price_note: null,
                        purchase_price_file_name: null,
                        purchase_price_file: null,
                        purchase_price_extension: null,
                        due_dilligence_end: null,
                        due_dilligence_end_date: new Date(),
                        due_dilligence_end_note: null,
                        due_dilligence_end_file_name: null,
                        due_dilligence_end_file: null,
                        due_dilligence_end_extension: null,
                        escrow_deposit_amount: null,
                        escrow_deposit_amount_date: new Date(),
                        escrow_deposit_amount_note: null,
                        escrow_deposit_amount_file_name: null,
                        escrow_deposit_amount_file: null,
                        escrow_deposit_amount_extension: null,
                        escrow_deposit_increase: false,
                        escrow_deposit_release: false,
                        escrow_how_much_released: null,
                        funds_applied_purchase_price: true,
                        note_funds_applied: null,
                        who_funds_applied: null,
                        escrow_closing_be_change: false,
                        escrow_closing: null,
                        escrow_closing_date: new Date(),
                        escrow_closing_note: null,
                        escrow_closing_file_name: null,
                        escrow_closing_file: null,
                        escrow_closing_extension: null,
                        per_diem: false,
                        amount_per_day: null
                    });
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Renegotiation in escrow</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit without saving changes?</p>
                    :
                    <>
                        { spinner ? 
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                    </div>
                                </div> 
                            :
                                null
                        }
                        <div className="row">
                            <div id="accordion-buying-escrow-renegotiation_price" className="col-md-4 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New price adjustment:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseRenegotiationPriceDate" aria-expanded="true" aria-controls="collapseRenegotiationPriceDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseRenegotiationPrice" aria-expanded="true" aria-controls="collapseRenegotiationPrice"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt mr-2" data-toggle="collapse" data-target="#collapseRenegotiationPriceNote" aria-expanded="true" aria-controls="collapseRenegotiationPriceNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-file-upload  mr-2" data-toggle="collapse" data-target="#collapseRenegotiationPriceFile" aria-expanded="true" aria-controls="collapseRenegotiationPriceFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput autoComplete='off'
                                        name="purchase_price"
                                        value={renegotiationData.purchase_price || ''}
                                        className="form-control text-center"
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                    />
                                </div>

                                <div id="collapseRenegotiationPriceDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-renegotiation_price">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.purchase_price_date ? new Date(renegotiationData.purchase_price_date) : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, purchase_price_date: date})}} />
                                    </div>        
                                </div>
                                
                                <div id="collapseRenegotiationPrice" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-renegotiation_price">
                                    <label key="asking_price_0" className="col-sm-12">
                                        <b className="ml-2">Asking price:</b> <span className="value_renegotiation">{ numberToDecimal(proyectos.detalles?.proyecto?.asking_price, '$ ') }: </span> {new Date(proyectos.detalles?.proyecto?.envio).toLocaleDateString('en-US')}
                                    </label>
                                    {   
                                        proyectoData.purchase_price_history?.length > 0 ?
                                            proyectoData.purchase_price_history?.map((purchase_price, index, array) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={index + '_' + purchase_price._id} className="col-sm-12">
                                                            <b className="ml-2">Offer price</b> <span className="value_renegotiation">{ numberToDecimal(purchase_price.amount, '$ ') }: </span> {new Date(purchase_price.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                purchase_price.id_archivo !== null && purchase_price.id_archivo !== undefined && purchase_price.id_archivo?.length > 0 ?
                                                                    purchase_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={index + '_' + purchase_price._id} className="col-sm-12">
                                                            <b className="ml-2">{purchase_price.type === 0 ? 'Counter' : 'Adjustment'} {index}°</b> <span className="value_renegotiation">{ numberToDecimal(purchase_price.amount, '$ ') }: </span> {new Date(purchase_price.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                purchase_price.id_archivo !== null && purchase_price.id_archivo !== undefined && purchase_price.id_archivo?.length > 0 ?
                                                                    purchase_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseRenegotiationPriceNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-renegotiation_price">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="purchase_price_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseRenegotiationPriceFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-renegotiation_price">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="purchase_price_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="purchase_price_file" id="purchase_price_file" ref={purchase_price_ref} onChange={onChangeFilePurchaseRenegotiation} aria-describedby="view_file_purchasePriceFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.purchase_price_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.purchase_price_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div className="col-md-4 form-group">
                                <label className="font-weight-bold label_renegotiation">Escrow deposit increase?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_increase" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_increase === true ? true : false} />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_increase" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_increase === false ? true : false} />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>

                            <div id="accordion-buying-escrow-escrow-deposit-amount" className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_increase === true ? '' : ' campo-deshabilitado')}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">Escrow deposit to be increase by:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseEscrowDepositAmountDate" aria-expanded="true" aria-controls="collapseEscrowDepositAmountDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_deposit_increase === true ? false : true}>
                                                <i className="fa fa-history btn_renegociacion" data-toggle="collapse" data-target="#collapseEscrowDepositAmount" aria-expanded="true" aria-controls="collapseEscrowDepositAmount"/>
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_deposit_increase === true ? false : true}>
                                                <i className="fas fa-pencil-alt btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseEscrowDepositAmountNote" aria-expanded="true" aria-controls="collapseEscrowDepositAmountNote"/>
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_deposit_increase === true ? false : true}>
                                                <i className="fa fa-file-upload btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseEscrowDepositAmountFile" aria-expanded="true" aria-controls="collapseEscrowDepositAmountFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput autoComplete='off'
                                        name="escrow_deposit_amount"
                                        value={renegotiationData.escrow_deposit_amount || ''}
                                        className="form-control text-center"
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                        disabled={renegotiationData.escrow_deposit_increase === true ? false : true}
                                    />
                                </div>

                                <div id="collapseEscrowDepositAmountDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-deposit-amount">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.escrow_deposit_amount_date ? new Date(renegotiationData.escrow_deposit_amount_date) : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, escrow_deposit_amount_date: date})}} />
                                    </div>        
                                </div>

                                <div id="collapseEscrowDepositAmount" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-deposit-amount">
                                    {
                                        proyectoData.escrow_deposit_amount_history?.length > 0 ?
                                            proyectoData.escrow_deposit_amount_history?.map((escrow_deposit_amount, index, array) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={index + '_' + escrow_deposit_amount._id} className="col-sm-12">
                                                            <b className="ml-2">Deposit</b> <span className="value_renegotiation">{ numberToDecimal(escrow_deposit_amount.amount, '$ ') }: </span> {new Date(escrow_deposit_amount.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                escrow_deposit_amount.id_archivo !== null && escrow_deposit_amount.id_archivo !== undefined && escrow_deposit_amount.id_archivo?.length > 0 ?
                                                                    escrow_deposit_amount.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={index + '_' + escrow_deposit_amount._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{ numberToDecimal(escrow_deposit_amount.amount, '$ ') }: </span> {new Date(escrow_deposit_amount.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                escrow_deposit_amount.id_archivo !== null && escrow_deposit_amount.id_archivo !== undefined && escrow_deposit_amount.id_archivo?.length > 0 ?
                                                                    escrow_deposit_amount.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )                                                    
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseEscrowDepositAmountNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-deposit-amount">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="escrow_deposit_amount_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseEscrowDepositAmountFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-deposit-amount">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="due_dilligence_end_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="due_dilligence_end_file" id="due_dilligence_end_file" ref={due_dilligence_end_ref} onChange={onChangeFileDueDilligenceEndRenegotiation} aria-describedby="view_file_dueDilligenceEndFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.due_dilligence_end_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.due_dilligence_end_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_amount !== null && renegotiationData.escrow_deposit_amount !== '' ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Escrow deposit to be release?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_release" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_release === true ? true : false} disabled={renegotiationData.escrow_deposit_amount !== null && renegotiationData.escrow_deposit_amount !== '' ? false : true} />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_release" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_release === false ? true : false} disabled={renegotiationData.escrow_deposit_amount !== null && renegotiationData.escrow_deposit_amount !== '' ? false : true} />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>
                                
                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_release === true ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">How much to be realesed?:</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput autoComplete='off'
                                        name="escrow_how_much_released"
                                        value={renegotiationData.escrow_how_much_released || ''}
                                        className="form-control text-center"
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                        disabled={renegotiationData.escrow_deposit_release === true ? false : true}
                                    />
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_release === true ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Funds to be applied to purchase price?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="funds_applied_purchase_price" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.funds_applied_purchase_price === true ? true : false} disabled={renegotiationData.escrow_deposit_release === true ? false : true} />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="funds_applied_purchase_price" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.funds_applied_purchase_price === false ? true : false} disabled={renegotiationData.escrow_deposit_release === true ? false : true} />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.funds_applied_purchase_price === false ? '' : ' campo-deshabilitado')}>
                                <label className={renegotiationData.funds_applied_purchase_price === false ? 'font-weight-bold label_renegotiation text-danger' : 'font-weight-bold label_renegotiation'}>Why?:</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className={renegotiationData.funds_applied_purchase_price === false ? "fas fa-question-circle text-danger" : "fas fa-question-circle"}></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' type="text" name="note_funds_applied" className="form-control text-center" onChange={e => handleInputsRenegotiation(e)} disabled={renegotiationData.funds_applied_purchase_price === false ? false : true} />
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.funds_applied_purchase_price === false ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Who approved it?:</label>
                                <Select name="who_funds_applied" isClearable={true} options={usersFilter} value={userFundsAppliedData || ''} onChange={(value) => onChangeSelectSearch(value, 'who_funds_applied', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={renegotiationData.funds_applied_purchase_price === false ? false : true} />
                            </div>

                            <div className="col-md-4 form-group">
                                <label className="font-weight-bold label_renegotiation">Escrow closing date to be change?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_closing_be_change" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_closing_be_change === true ? true : false} />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_closing_be_change" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_closing_be_change === false ? true : false} />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>

                            <div id="accordion-buying-escrow-escrow-closing-date" className={'col-md-4 form-group' + (renegotiationData.escrow_closing_be_change === true ? '' : ' campo-deshabilitado')}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New escrow closing date:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseEscrowClosingDateDate" aria-expanded="true" aria-controls="collapseEscrowClosingDateDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_closing_be_change === true ? false : true}>
                                                <i className="fa fa-history btn_renegociacion" data-toggle="collapse" data-target="#collapseEscrowClosingDate" aria-expanded="true" aria-controls="collapseEscrowClosingDate"/>
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_closing_be_change === true ? false : true}>
                                                <i className="fas fa-pencil-alt btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseEscrowClosingDateNote" aria-expanded="true" aria-controls="collapseEscrowClosingDateNote"/>
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion" disabled={renegotiationData.escrow_closing_be_change === true ? false : true}>
                                                <i className="fa fa-file-upload btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseEscrowClosingDateFile" aria-expanded="true" aria-controls="collapseEscrowClosingDateFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ renegotiationData.escrow_closing ? new Date(renegotiationData.escrow_closing) : '' } onChange={ date => setRenegotiationData({ ...renegotiationData, escrow_closing: date })} disabled={renegotiationData.escrow_closing_be_change === true ? false : true}/>
                                    </div>
                                </div>

                                <div id="collapseEscrowClosingDateDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-closing-date">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.escrow_closing_date ? new Date(renegotiationData.escrow_closing_date) : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, escrow_closing_date: date})}} />
                                    </div>        
                                </div>
                                
                                <div id="collapseEscrowClosingDate" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-closing-date">
                                    {
                                        proyectoData.escrow_closing_history?.length > 0 ?
                                            proyectoData.escrow_closing_history?.map((escrow_closing, index, array) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={index + '_' + escrow_closing._id} className="col-sm-12">
                                                            <b className="ml-2">Closing</b> <span className="value_renegotiation">{new Date(escrow_closing.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(escrow_closing.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                escrow_closing.id_archivo !== null && escrow_closing.id_archivo !== undefined && escrow_closing.id_archivo?.length > 0 ?
                                                                    escrow_closing.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={index + '_' + escrow_closing._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{new Date(escrow_closing.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(escrow_closing.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                escrow_closing.id_archivo !== null && escrow_closing.id_archivo !== undefined && escrow_closing.id_archivo?.length > 0 ?
                                                                    escrow_closing.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseEscrowClosingDateNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-closing-date">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="escrow_closing_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseEscrowClosingDateFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-escrow-closing-date">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="escrow_deposit_amount_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="escrow_deposit_amount_file" id="escrow_deposit_amount_file" ref={escrow_deposit_amount_ref} onChange={onChangeFileEscrowDepositAmountRenegotiation} aria-describedby="view_file_escrowDepositAmountFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.escrow_deposit_amount_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.escrow_deposit_amount_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_closing !== null ? '' : ' campo-deshabilitado')}>
                                <label className={renegotiationData.escrow_closing !== null ? "font-weight-bold label_renegotiation text-danger" : "font-weight-bold label_renegotiation"}>Will there be a per diem?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="per_diem" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.per_diem === true ? true : false} disabled={renegotiationData.escrow_closing !== null ? false : true} />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="per_diem" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.per_diem === false ? true : false} disabled={renegotiationData.escrow_closing !== null ? false : true} />
                                    <label className="form-check-label">No</label>
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.per_diem === true ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">What is the amount per day?:</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput autoComplete='off'
                                        name="amount_per_day"
                                        value={renegotiationData.amount_per_day || ''}
                                        className="form-control text-center"
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                        disabled={renegotiationData.per_diem === true ? false : true}
                                    />
                                </div>
                            </div>

                            <div id="accordion-buying-escrow-due-dilligence-end-date" className="col-md-4 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New due dilligence end date:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseDueDilligenceEndDateDate" aria-expanded="true" aria-controls="collapseDueDilligenceEndDateDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseDueDilligenceEndDate" aria-expanded="true" aria-controls="collapseDueDilligenceEndDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt mr-2" data-toggle="collapse" data-target="#collapseDueDilligenceEndDateNote" aria-expanded="true" aria-controls="collapseDueDilligenceEndDateNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                            <i className="fa fa-file-upload mr-2" data-toggle="collapse" data-target="#collapseDueDilligenceEndDateFile" aria-expanded="true" aria-controls="collapseDueDilligenceEndDateFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ renegotiationData.due_dilligence_end ? new Date(renegotiationData.due_dilligence_end) : '' } onChange={ date => setRenegotiationData({ ...renegotiationData, due_dilligence_end: date })}/>
                                    </div>
                                </div>

                                <div id="collapseDueDilligenceEndDateDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-due-dilligence-end-date">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.due_dilligence_end_date ? new Date(renegotiationData.due_dilligence_end_date) : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, due_dilligence_end_date: date})}} />
                                    </div>        
                                </div>
                                
                                <div id="collapseDueDilligenceEndDate" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-due-dilligence-end-date">
                                    {
                                        proyectoData.due_dilligence_end_history?.length > 0 ?
                                            proyectoData.due_dilligence_end_history?.map((due_dilligence_end, index) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={due_dilligence_end._id} className="col-sm-12">
                                                            <b className="ml-2">End</b> <span className="value_renegotiation">{new Date(due_dilligence_end.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(due_dilligence_end.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                due_dilligence_end.id_archivo !== null && due_dilligence_end.id_archivo !== undefined && due_dilligence_end.id_archivo?.length > 0 ?
                                                                    due_dilligence_end.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={due_dilligence_end._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{new Date(due_dilligence_end.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(due_dilligence_end.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                due_dilligence_end.id_archivo !== null && due_dilligence_end.id_archivo !== undefined && due_dilligence_end.id_archivo?.length > 0 ?
                                                                    due_dilligence_end.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseDueDilligenceEndDateNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-due-dilligence-end-date">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="due_dilligence_end_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseDueDilligenceEndDateFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-buying-escrow-due-dilligence-end-date">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="escrow_closing_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="escrow_closing_file" id="escrow_closing_file" ref={escrow_closing_ref} onChange={onChangeFileEscrowClosingRenegotiation} aria-describedby="view_file_escrowClosingFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.escrow_closing_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.escrow_closing_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                            <button className="btn btn-success" onClick={() => {setUserFundsAppliedData(null); handleCloseModalRenegotiation(); setPreguntarGuardar(false);}}>Yes</button>
                        </>
                        
                        :
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => {
                                setUserFundsAppliedData(null);
                                handleCloseModalRenegotiation();
                            }}
                            >Cancel</button>
                            <button className="btn btn-info" onClick={handleSubmitRenegotiation}>Save and New</button>
                            <button className="btn btn-success" onClick={(e) => {
                                handleSubmitRenegotiation(e);
                                setRenegotiationModal(false);
                            }}>Save and Close</button>
                        </>
                }
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo archivo */}
            <Modal backdrop="static" show={newFileModal} size="lg" onHide={() => {
                handleCloseAgregarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New file to upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsFile(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="file" ref={file_ref} onChange={onChangeFile} aria-describedby="view_file_file"/>
                                    <label className="custom-file-label">{newFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarArchivo()} disabled={statusSaveFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarArchivo} disabled={statusSaveFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar archivo */}
            <Modal backdrop="static" show={editFileModal} size="lg" onHide={() => {
                handleCloseEditarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFile(e)} value={editFileData.nombre || ''} disabled={statusSaveEditFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={file_edit_ref} onChange={onChangeEditFile} aria-describedby="view_file_editFile"/>
                                    <label className="custom-file-label">{editFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarArchivo()} disabled={statusSaveEditFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarEditarArchivo} disabled={statusSaveEditFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 4 ?
                        <button type="button" className="btn btn-warning ml-2 btn-sm" onClick={() => handleRenegotiation()} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false || spinner ? true : false}>Renegotiation</button>
                    :   null
                }
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 4 ? 
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false || spinner ? true : false}>Save</button>
                    :   null
                }
                {
                    mostrarBoton && (proyectos.detalles?.proyecto?.identificador_status <= 4) ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleModalContinue} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false || spinner ? true : false}>Save and Continue</button>
                    :   null
                }
            </div>    

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={() => handleRenegotiation()} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false}>
                            Renegotiation
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleModalContinue} disabled={proyectos.masterlist_section === 'Cancelled' ? true : false}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>            

            <form className="col-md-12 pb-3" autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" style={{display:'none'}} />

                <div className="row">
                    <div className={'col-md form-group campo_fecha' + (proyectoData.escrow_open !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Escrow open date')}>Escrow open date:</label>
                        <div className="contenedor_datepicker">
                        {
                            proyectos.masterlist?.escrow_open !== null && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.escrow_open ? new Date(proyectoData.escrow_open).toISOString().substring(0, 10) : 'Not defined'}</label>
                                </div>
                            :
                                <div className="d-flex">
                                    <span className="input-group-text" data-focus="open" onClick={(e) => focusCampo(e)}>
                                        <i className="fas fa-calendar-alt" data-focus="open" onClick={(e) => focusCampo(e)}></i>
                                    </span>
                                    <DatePicker
                                        id="open"
                                        name="escrow_open"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        selected={ proyectoData.escrow_open ? new Date(proyectoData.escrow_open) : '' } 
                                        // minDate={new Date(proyectos.masterlist?.contract_buy)} 
                                        onChange={ date => {setProyectoData({ ...proyectoData, escrow_open: date }); dispatch(guardarCambios(true));} }
                                        monthsShown={2}
                                        disabled={proyectos.masterlist?.escrow_open !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                    />
                                </div>
                        }
                        </div>
                    </div>

                    <div className="col-md form-group campo_radio_lg">
                        <label className="font-weight-bold" onClick={() => newNote('We have escrow information?')}>We have escrow information?:</label><br/>
                        {
                            proyectos.masterlist?.escrow_information_buy === true && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label>{(proyectoData.escrow_information === true) ? 'Yes' : 'No'}</label>
                                </div>
                            :
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_information" value="1" onChange={e => handleRadiobuttonEscrow(e)} checked={proyectoData.escrow_information === true ? true : false} disabled={proyectos.masterlist?.escrow_information_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_information" value="2" onChange={e => handleRadiobuttonEscrow(e)} checked={proyectoData.escrow_information === false ? true : false} disabled={proyectos.masterlist?.escrow_information_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No, call for escrow information</label>
                                    </div>
                                </div>
                        }
                    </div>

                    {
                        proyectoData.escrow_information === false ?
                            <div className="col-md form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who is in charge to call?')}>Who is in charge to call?:</label>
                                {
                                    proyectos.masterlist?.escrow_information_user_buy !== null && !proyectos.edicion_proyecto ?
                                        <div className='d-flex'>
                                            <label>{proyectos.masterlist?.name_escrow_information_user_buy}</label>
                                        </div>
                                    :
                                        <div>
                                            <Select
                                                name="escrow_information_user"
                                                isClearable={true}
                                                options={usersFilter}
                                                value={userEscrowInformationData}
                                                onChange={(value) => onChangeSelectSearch(value, 'escrow_information_user', 'name_escrow_information_user')}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isDisabled={proyectos.masterlist?.escrow_information_user_buy !== null || proyectoData.escrow_information === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                               }
                            </div>
                        :
                            <div className={'col-md form-group campo_radio_sm' + (proyectoData.escrow_deposit_send_question === true ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Escrow deposit send?')}>Escrow deposit send?:</label><br/>
                                {
                                    proyectos.masterlist?.escrow_deposit_send !== null && !proyectos.edicion_proyecto ?
                                        <div className='d-flex'>
                                            <label>{(proyectos.masterlist?.escrow_deposit_send !== null) ? 'Yes' : 'No'}</label>
                                        </div>
                                    :
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="escrow_deposit_send_question" value="1" onChange={e => handleRadiobutton(e)} checked={proyectoData.escrow_deposit_send_question === true ? true : false} disabled={proyectos.masterlist?.escrow_deposit_send !== null || proyectoData.escrow_information === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                <label className="form-check-label">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="escrow_deposit_send_question" value="2" onChange={e => handleRadiobutton(e)} checked={proyectoData.escrow_deposit_send_question === false ? true : false} disabled={proyectos.masterlist?.escrow_deposit_send !== null || proyectoData.escrow_information === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                <label className="form-check-label">No</label>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                    
                    {
                        proyectoData.escrow_information === true && proyectoData.escrow_deposit_send_question === true ?
                            <div className={'col-md form-group campo_fecha' + (proyectoData.escrow_deposit_send !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Escrow deposit send date')}>Escrow deposit send date:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        proyectos.masterlist?.escrow_deposit_send !== null && !proyectos.edicion_proyecto ?
                                            <div className='d-flex'>
                                                <label><i className='fas fa-calendar-alt'/> {proyectoData.escrow_deposit_send ? new Date(proyectoData.escrow_deposit_send).toISOString().substring(0, 10) : 'Not defined'}</label>
                                            </div>
                                        :
                                            <div className="d-flex">
                                                <span className="input-group-text" id="deposit2">
                                                    <i className="fas fa-calendar-alt" id="deposit2"></i>
                                                </span>
                                                <DatePicker
                                                    id='deposit'
                                                    name="escrow_deposit_send"
                                                    className="form-control text-center"
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={ proyectoData.escrow_deposit_send ? new Date(proyectoData.escrow_deposit_send) : '' } 
                                                    // minDate={proyectoData.escrow_open !== null ? new Date(proyectoData.escrow_open) : new Date(proyectos.masterlist?.contract_buy)} 
                                                    onChange={ date => {setProyectoData({ ...proyectoData, escrow_deposit_send: date }); dispatch(guardarCambios(true));} }
                                                    monthsShown={2}
                                                    disabled={proyectos.masterlist?.escrow_deposit_send !== null || proyectoData.escrow_deposit_send_question === false || proyectoData.escrow_information === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }                           

                    <div className='col-md form-group campo_fecha'>
                        <label className="font-weight-bold" onClick={() => newNote('Due dilligence end date')}>Due dilligence end date:</label>
                        <div className="contenedor_datepicker">
                            {
                                proyectos.masterlist?.due_dilligence_end !== null && !proyectos.edicion_proyecto ?
                                    <div>
                                        <label><i className="fas fa-calendar-alt"/> {proyectos.masterlist?.due_dilligence_end ? new Date(proyectos.masterlist?.due_dilligence_end).toISOString().substring(0, 10) : 'Not defined'}</label>
                                    </div>
                                :
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker
                                            name="due_dilligence_end"
                                            className="form-control text-center"
                                            dateFormat="MM/dd/yyyy"
                                            selected={ proyectos.masterlist?.due_dilligence_end ? new Date(proyectos.masterlist?.due_dilligence_end) : '' }
                                            monthsShown={2}
                                            disabled={true}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md form-group campo_radio_sm">
                        <label className="font-weight-bold" onClick={() => newNote('Instructions received?')}>Instructions received?:</label><br/>
                        {
                            proyectos.masterlist?.escrow_instructions_buy === true && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label>{(proyectos.masterlist?.escrow_instructions_buy === true) ? 'Yes' : 'No'}</label>
                                </div>
                            :
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_instructions" value="1" onChange={e => handleRadiobuttonEscrowInstructions(e)} checked={proyectoData.escrow_instructions === true ? true : false} disabled={proyectos.masterlist?.escrow_instructions_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_instructions" value="2" onChange={e => handleRadiobuttonEscrowInstructions(e)} checked={proyectoData.escrow_instructions === false ? true : false} disabled={proyectos.masterlist?.escrow_instructions_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>
                        }
                    </div>
                    
                    {
                        proyectoData.escrow_instructions === true ?
                            <div className="col-md form-group campo_numero_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Escrow number')}>Escrow number:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        proyectos.masterlist?.escrow_number_buy !== null && !proyectos.edicion_proyecto ?
                                            <label>{`# ${proyectoData.escrow_number}`}</label>
                                        :
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" data-focus="number" onClick={(e) => focusCampo(e)}>
                                                        #
                                                    </span>
                                                </div>
                                                <input autoComplete='off' id="number" name="escrow_number" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.escrow_number || ''} disabled={proyectos.masterlist?.escrow_number_buy !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.escrow_instructions === true ?
                            <div className="col-md form-group campo_fecha">
                                <label className="font-weight-bold" onClick={() => newNote('Instructions received date')}>Instructions received date:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        proyectos.masterlist?.escrow_instructions_date_buy !== null && !proyectos.edicion_proyecto ?
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.escrow_instructions_date ? new Date(proyectoData.escrow_instructions_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</label>
                                        :
                                            <div className="d-flex">
                                                <span className="input-group-text" data-focus="instructions" onClick={(e) => focusCampo(e)}>
                                                    <i className="fas fa-calendar-alt" data-focus="instructions" onClick={(e) => focusCampo(e)}></i>
                                                </span>
                                                <DatePicker
                                                    id="instructions"
                                                    name="escrow_instructions_date"
                                                    className="form-control text-center"
                                                    dateFormat="MM/dd/yyyy"
                                                    selected={ proyectoData.escrow_instructions_date ? new Date(proyectoData.escrow_instructions_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                    // minDate={proyectoData.escrow_deposit_send !== null ? new Date(proyectoData.escrow_deposit_send) : new Date(proyectos.detalles?.proyecto?.envio)} 
                                                    onChange={ date => {setProyectoData({ ...proyectoData, escrow_instructions_date: date }); dispatch(guardarCambios(true));} }
                                                    monthsShown={2}
                                                    disabled={proyectos.masterlist?.escrow_instructions_date_buy !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.escrow_information === true ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" style={{fontSize: 18}}>{`${proyectoData.escrow_closing !== null ? (Math.floor((new Date(proyectoData.escrow_closing).getTime() - new Date(proyectos.masterlist?.due_dilligence_end).getTime()) / (1000 * 3600 * 24)) <= 7 ? 'This date must be at least 8 days greater than the Due Diligence End date' : '') : ''}`}</Tooltip>}
                            >
                                <div className={'col-md form-group campo_fecha' + (proyectoData.escrow_closing !== null ? (Math.floor((new Date(proyectoData.escrow_closing).getTime() - new Date(proyectos.masterlist?.due_dilligence_end).getTime()) / (1000 * 3600 * 24)) <= 7 ? ' fecha-superior' : '') : ' campo-recordatorio')}>
                                    <label className="font-weight-bold" onClick={() => newNote('Estimated escrow closing date')}>Estimated escrow closing date:</label>
                                    <div className="contenedor_datepicker">
                                        {
                                            proyectoData.escrow_closing !== null && !proyectos.edicion_proyecto ?
                                                <label><i className='fas fa-calendar-alt'/> { proyectoData.escrow_closing ? new Date(proyectoData.escrow_closing).toISOString().substring(0, 10) : 'Not defined' }</label>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <i className="fas fa-calendar-alt"></i>
                                                    </span>
                                                    <DatePicker
                                                        name="escrow_closing"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={proyectoData.escrow_closing ? new Date(proyectoData.escrow_closing) : ''} 
                                                        // minDate={proyectoData.escrow_open !== null ? new Date(proyectoData.escrow_open) : new Date(proyectos.masterlist?.contract_buy)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, escrow_closing: date, real_escrow_closing: date }); handleAlertarFechas(date); dispatch(guardarCambios(true));} }
                                                        monthsShown={2}
                                                        disabled={proyectoData.escrow_information === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </OverlayTrigger>
                        :
                            null
                    }

                    <div className={'col-md form-group campo_radio_lg' + (proyectoData.funds_available !== false ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Are the funds available by the escrow closing date?')}>Are the funds available by the escrow closing date?:</label><br/>
                        {
                            proyectos.masterlist?.funds_available === true && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label>{(proyectos.masterlist?.funds_available === true) ? 'Yes' : 'No'}</label>
                                </div>
                            :
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="funds_available" value="1" onChange={e => handleRadiobuttonFundsAvailable(e)} checked={proyectoData.funds_available === true ? true : false} disabled={proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <label className="form-check-label">Yes</label> 
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="funds_available" value="2" onChange={e => handleRadiobuttonFundsAvailable(e)} checked={proyectoData.funds_available === false ? true : false} disabled={proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>
                        }
                    </div>

                    <div className={'col-md form-group campo_radio_lg' + (proyectoData.purchase_price_adjustment !== true ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Is a new price adjustment needed?')}>Is a new price adjustment needed?:</label><br/>
                        {
                            proyectos.masterlist?.purchase_price_adjustment === false && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label>{(proyectos.masterlist?.purchase_price_adjustment === true) ? 'Yes' : 'No'}</label>
                                </div>
                            :
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase_price_adjustment" value="1" onChange={e => handleRadiobuttonPriceAdjustment(e)} checked={proyectoData.purchase_price_adjustment === true ? true : false} disabled={proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase_price_adjustment" value="2" onChange={e => handleRadiobuttonPriceAdjustment(e)} checked={proyectoData.purchase_price_adjustment === false ? true : false} disabled={proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                { spinner ? 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                            </div>
                        </div> 
                    :
                        null
                }

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <ul className="nav nav-tabs nav-fill">
                            {
                                proyectoData.broker_represent === false && proyectos.masterlist?.propietario_vende === false ?
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 4 || sectionInformation === 5) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(4)}>Buyer broker</button>
                                    </li>
                                :
                                    null
                            }

                            {
                                proyectos.masterlist?.propietario_vende === false ?
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 2 || sectionInformation === 3) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(2)}>Listing{ proyectoData.broker_represent === true ? ' & Buyer' : ''} broker</button>
                                    </li>             
                                :
                                    null
                            }

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 0 || sectionInformation === 1) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(0)}>Escrow</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 6 || sectionInformation === 7) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(6)}>Lender</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 8 || sectionInformation === 9) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(8)}>Title</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 10 || sectionInformation === 11) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(10)}>Termite</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 12 || sectionInformation === 13) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(12)}>Septic</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 2 || sectionInformation === 3) && proyectos.masterlist?.propietario_vende === false ? '' : ' d-none')}>
                    {
                        sectionInformation === 2 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(3)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_seller" className="select-group" aria-describedby="button-broker-seller" isClearable={true} options={brokersFilter} value={brokerSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_seller', 'id_broker_agent_seller')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_seller_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_seller_buy !== null || proyectoData.id_broker_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-seller" onClick={() => handleBrokerSeller(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.address !== '') ? viewBrokerSellerData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_seller" className="select-group" aria-describedby="button-broker-agent-seller" isClearable={true} options={brokersAgentsSellerFilter} value={brokerAgentSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_seller', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_seller_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_seller_buy !== null || proyectoData.id_broker_agent_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-seller" onClick={() => handleBrokerSeller(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                    <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.email !== '') ? viewBrokerSellerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.cell_phone !== '') ? viewBrokerSellerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.phone !== '') ? viewBrokerSellerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerSellerData.license !== '') ? '# ' + viewBrokerSellerData.license  : '#'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerSellerData.public !== '') ? '# ' + viewBrokerSellerData.public  : '#'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 3 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(1)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(2)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 4 || sectionInformation === 5) && proyectos.masterlist?.propietario_vende === false ? '' : ' d-none')}>
                    {
                        sectionInformation === 4 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(5)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_buyer" className="select-group" aria-describedby="button-broker-buyer" isClearable={true} options={brokersFilter} value={brokerBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_buyer', 'id_broker_agent_buyer')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_buyer_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_buyer_buy !== null || proyectoData.id_broker_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-buyer" onClick={() => handleBrokerBuyer(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.address !== '') ? viewBrokerBuyerData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_buyer" className="select-group" aria-describedby="button-broker-agent-buyer" isClearable={true} options={brokersAgentsBuyerFilter} value={brokerAgentBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_buyer', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_buyer_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_buyer_buy !== null || proyectoData.id_broker_agent_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-buyer" onClick={() => handleBrokerBuyer(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.email !== '') ? viewBrokerBuyerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.cell_phone !== '') ? viewBrokerBuyerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.phone !== '') ? viewBrokerBuyerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerBuyerData.license !== '') ? '# ' + viewBrokerBuyerData.license  : '#'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerBuyerData.public !== '') ? '# ' + viewBrokerBuyerData.public  : '#'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 5 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(2)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(4)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 0 || sectionInformation === 1) ? '' : ' d-none')}>
                    {
                        sectionInformation === 0 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(1)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Company:</label>
                                    <div className="input-group">
                                        <Select name="id_escrow" className="select-group" aria-describedby="button-escrow" isClearable={true} options={escrowsFilter} value={escrowSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_escrow', 'id_escrow_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_escrow_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_escrow_buy !== null || proyectoData.id_escrow !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-escrow" onClick={() => handleEscrow(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewEscrowData.address !== '') ? viewEscrowData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_escrow_agent" className="select-group" aria-describedby="button-escrow-agent" isClearable={true} options={escrowsAgentsFilter} value={escrowAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_escrow_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_escrow_agent_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_escrow_agent_buy !== null || proyectoData.id_escrow_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-escrow-agent" onClick={() => handleEscrow(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewEscrowData.email !== '') ? viewEscrowData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewEscrowData.cell_phone !== '') ? viewEscrowData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewEscrowData.phone !== '') ? viewEscrowData.phone : ''}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 1 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(0)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(0)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'p-3 mx-0 seccion_information' + ((sectionInformation === 6 || sectionInformation === 7) ? '' : ' d-none')}>
                    {
                        sectionInformation === 6 ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-12 form-group text-right">
                                        <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(7)}><i className="fas fa-folder-open"></i> View files</button>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* Compañia */}
                                    <div className="col-md-4 form-group">
                                        <label className="font-weight-bold">Lender:</label>
                                        <div className="input-group">
                                            <Select name="id_lender" className="select-group" aria-describedby="button-lender" isClearable={true} options={lendersFilter} value={lenderSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_lender', 'name_lender')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_lender_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                            <div className="input-group-append">
                                                {
                                                    proyectos.masterlist?.id_lender_buy !== null || proyectoData.id_lender !== null ?
                                                        <></>
                                                    :
                                                        <button className="btn btn-secondary" type="button" id="button-lender" onClick={() => handleLender()}>Create</button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* Address */}
                                    <div className="col-md-8 form-group">
                                        <label className="font-weight-bold">Address:</label>
                                        <div className="contenedor_datepicker">
                                            <div style={{display: 'flex'}}>
                                                <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewLenderData.address !== '') ? viewLenderData.address : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* Officer Name */}
                                    <div className="col-md-3 form-group">
                                        <label className="font-weight-bold">Agent name:</label>
                                        <div className="contenedor_datepicker">
                                            {
                                                (proyectos.masterlist?.lender_officer_name_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                    ?
                                                        (proyectos.edicion_proyecto)
                                                            ?
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text" id="basic-addon1">
                                                                            <i className="fas fa-user"></i>
                                                                        </span>
                                                                    </div>
                                                                    <input autoComplete='off' name="lender_email" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.lender_officer_name || ''} disabled={proyectos.masterlist?.lender_officer_name_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                                </div>
                                                            :
                                                                <div className="input-group">
                                                                    <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(proyectoData.lender_officer_name !== '') ? proyectoData.lender_officer_name : ''}</p>
                                                                </div>

                                                    :
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input autoComplete='off' name="lender_email" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.lender_officer_name || ''} disabled={proyectos.masterlist?.lender_officer_name_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                        </div>
                                            }
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="col-md-3 form-group">
                                        <label className="font-weight-bold">Email:</label>
                                        <div className="contenedor_datepicker">
                                                {
                                                    (proyectos.masterlist?.lender_email_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                        ?
                                                            (proyectos.edicion_proyecto)
                                                                ?
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text" id="basic-addon1">
                                                                                <i className="fas fa-envelope"></i>
                                                                            </span>
                                                                        </div>
                                                                        <input autoComplete='off' name="lender_email" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.lender_email || ''} disabled={proyectos.masterlist?.lender_email_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                                    </div>
                                                                :
                                                                    <div className="input-group">
                                                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(proyectoData.lender_email !== '') ? proyectoData.lender_email : ''}</p>
                                                                    </div>

                                                        :
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-envelope"></i>
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="lender_email" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.lender_email || ''} disabled={proyectos.masterlist?.lender_email_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                }
                                        </div>
                                    </div>

                                    {/* Cell Phone */}
                                    <div className="col-md-3 form-group">
                                        <label className="font-weight-bold">Cell phone:</label>
                                        {
                                            (proyectos.masterlist?.lender_cell_phone_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="contenedor_datepicker">
                                                                <PhoneInput autoComplete='off' name="lender_cell_phone" className="form-control" country={'us'} value={proyectoData.lender_cell_phone} onChange={phone => {setProyectoData({...proyectoData, lender_cell_phone: phone}); dispatch(guardarCambios(true));}} disabled={proyectos.masterlist?.lender_cell_phone_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                                                <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(proyectoData.lender_cell_phone !== '') ? proyectoData.lender_cell_phone : ''}</p>
                                                            </div>
                                                :
                                                    <div className="contenedor_datepicker">
                                                        <PhoneInput autoComplete='off' name="lender_cell_phone" className="form-control" country={'us'} value={proyectoData.lender_cell_phone} onChange={phone => {setProyectoData({...proyectoData, lender_cell_phone: phone}); dispatch(guardarCambios(true));}} disabled={proyectos.masterlist?.lender_cell_phone_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                                    </div>
                                        }
                                        
                                    </div>

                                    {/* Phone */}
                                    <div className="col-md-3 form-group">
                                        <label className="font-weight-bold">Phone:</label>
                                        {
                                            (proyectos.masterlist?.lender_phone_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="contenedor_datepicker">
                                                                <PhoneInput autoComplete='off' name="lender_phone" className="form-control" country={'us'} value={proyectoData.lender_phone} onChange={phone => {setProyectoData({...proyectoData, lender_phone: phone}); dispatch(guardarCambios(true));}} disabled={proyectos.masterlist?.lender_phone_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                                                <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(proyectoData.lender_phone !== '') ? proyectoData.lender_phone : ''}</p>
                                                            </div>
                                                :
                                                    <div className="contenedor_datepicker">
                                                        <PhoneInput autoComplete='off' name="lender_phone" className="form-control" country={'us'} value={proyectoData.lender_phone} onChange={phone => {setProyectoData({...proyectoData, lender_phone: phone}); dispatch(guardarCambios(true));}} disabled={proyectos.masterlist?.lender_phone_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                                    </div>
                                        }
                                        
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Purchase price:</label>
                                        <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                            <p>{ (proyectoData.purchase_price_history?.length > 0) ? numberToDecimal(proyectoData?.purchase_price_history[proyectoData.purchase_price_history?.length - 1]?.amount, '$ ') : '' }</p>
                                        </div>
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Loan amount:</label>
                                        {
                                            (proyectos.masterlist?.lender_amount_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-dollar-sign"></i>
                                                                    </span>
                                                                </div>
                                                                <CurrencyInput autoComplete='off'
                                                                    name="lender_amount"
                                                                    className="form-control text-center"
                                                                    value={proyectoData?.lender_amount || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                    disabled={proyectos.masterlist?.lender_amount_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                                />
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker">
                                                                <p>{ (proyectoData.lender_amount !== '') ? numberToDecimal(proyectoData?.lender_amount, '$ ') : '' }</p>
                                                            </div>
                                                :
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-dollar-sign"></i>
                                                            </span>
                                                        </div>
                                                        <CurrencyInput autoComplete='off'
                                                            name="lender_amount"
                                                            className="form-control text-center"
                                                            value={proyectoData?.lender_amount || ''}
                                                            decimalsLimit={2}
                                                            groupSeparator=","
                                                            decimalSeparator="."
                                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                                            disabled={proyectos.masterlist?.lender_amount_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                        />
                                                    </div>
                                        }
                                        
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Loan ratio (LTV):</label>
                                        <div className="contenedor_datepicker">
                                            <p>{(proyectoData.purchase_price_history?.length > 0 && proyectoData?.lender_amount !== 0 && proyectoData?.lender_amount !== null) ? ((parseFloat(proyectoData?.lender_amount) / parseFloat(proyectoData?.purchase_price_history[0]?.amount)) * 100).toFixed(2) + ' %' : '- %'}</p>
                                        </div>
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Yearly rate:</label>
                                        {
                                            (proyectos.masterlist?.lender_yearly_rate_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="input-group">
                                                                <CurrencyInput autoComplete='off'
                                                                    name="lender_yearly_rate"
                                                                    className="form-control text-center"
                                                                    value={proyectoData?.lender_yearly_rate || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                    disabled={proyectos.masterlist?.lender_yearly_rate_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker">
                                                                <p>{(proyectoData?.lender_yearly_rate !== '') ? proyectoData?.lender_yearly_rate + ' %' : '- %'}</p>
                                                            </div>
                                                :
                                                    <div className="input-group">
                                                        <CurrencyInput autoComplete='off'
                                                            name="lender_yearly_rate"
                                                            className="form-control text-center"
                                                            value={proyectoData?.lender_yearly_rate || ''}
                                                            decimalsLimit={2}
                                                            groupSeparator=","
                                                            decimalSeparator="."
                                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                                            disabled={proyectos.masterlist?.lender_yearly_rate_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                        }
                                        
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Origination points:</label>
                                        {
                                            (proyectos.masterlist?.lender_origination_points_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="input-group">
                                                                <CurrencyInput autoComplete='off'
                                                                    name="lender_origination_points"
                                                                    className="form-control text-center"
                                                                    value={proyectoData?.lender_origination_points || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                    disabled={proyectos.masterlist?.lender_origination_points_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">%</span>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker">
                                                                <p>{(proyectoData?.lender_origination_points !== '') ? proyectoData?.lender_origination_points + ' %' : '- %'}</p>
                                                            </div>
                                                :
                                                    <div className="input-group">
                                                        <CurrencyInput autoComplete='off'
                                                            name="lender_origination_points"
                                                            className="form-control text-center"
                                                            value={proyectoData?.lender_origination_points || ''}
                                                            decimalsLimit={2}
                                                            groupSeparator=","
                                                            decimalSeparator="."
                                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                                            disabled={proyectos.masterlist?.lender_origination_points_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                        }
                                        
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Other fees:</label>
                                        {
                                            (proyectos.masterlist?.lender_other_fees_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-dollar-sign"></i>
                                                                    </span>
                                                                </div>
                                                                <CurrencyInput autoComplete='off'
                                                                    name="lender_other_fees"
                                                                    className="form-control text-center"
                                                                    value={proyectoData?.lender_other_fees || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                    disabled={proyectos.masterlist?.lender_other_fees_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                                />
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker">
                                                                <p>{ (proyectoData.lender_other_fees !== '') ? numberToDecimal(proyectoData?.lender_other_fees, '$ ') : '' }</p>
                                                            </div>
                                                :
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-dollar-sign"></i>
                                                            </span>
                                                        </div>
                                                        <CurrencyInput autoComplete='off'
                                                            name="lender_other_fees"
                                                            className="form-control text-center"
                                                            value={proyectoData?.lender_other_fees || ''}
                                                            decimalsLimit={2}
                                                            groupSeparator=","
                                                            decimalSeparator="."
                                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                                            disabled={proyectos.masterlist?.lender_other_fees_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                                        />
                                                    </div>
                                        }
                                    </div>

                                    <div className="col-md form-group">
                                        <label className="font-weight-bold">Other ratio:</label>
                                        <div className="contenedor_datepicker">
                                            <p>{(proyectoData.purchase_price_history?.length > 0 && proyectoData?.lender_other_fees !== 0 && proyectoData?.lender_other_fees !== null) ? ((parseFloat(proyectoData?.lender_other_fees) / parseFloat(proyectoData?.purchase_price_history[0]?.amount)) * 100).toFixed(2) + ' %' : '- %'}</p>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 7 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(3)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(6)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 8 || sectionInformation === 9) ? '' : ' d-none')}>
                    {
                        sectionInformation === 8 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(9)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Title information:</label>
                                    <div className="input-group">
                                        <Select name="id_title" className="select-group" aria-describedby="button-title" isClearable={true} options={titlesFilter} value={titleSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_title', 'id_title_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_title_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_title_buy !== null || proyectoData.id_title !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-title" onClick={() => handleTitle(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewTitleData.address !== '') ? viewTitleData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_title_agent" className="select-group" aria-describedby="button-title-agent" isClearable={true} options={titlesAgentsFilter} value={titleAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_title_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_title_agent_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_title_agent_buy !== null || proyectoData.id_title_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-title-agent" onClick={() => handleTitle(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewTitleData.email !== '') ? viewTitleData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTitleData.cell_phone !== '') ? viewTitleData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTitleData.phone !== '') ? viewTitleData.phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Received title preliminary?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_preliminary" value="1" onChange={e => handleRadiobuttonTitlePreliminary(e)} checked={proyectoData.title_preliminary === true ? true : false} disabled={proyectos.masterlist?.title_preliminary_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_preliminary" value="2" onChange={e => handleRadiobuttonTitlePreliminary(e)} checked={proyectoData.title_preliminary === false ? true : false} disabled={proyectos.masterlist?.title_preliminary_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Preliminary received date:</label>
                                        {
                                            (proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled')
                                                ?
                                                    (proyectos.edicion_proyecto)
                                                        ?
                                                            <div className="contenedor_datepicker">
                                                                <div className="d-flex">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-calendar-alt"></i>
                                                                    </span>
                                                                    <DatePicker name="title_preliminary_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                    // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                    onChange={ date => {setProyectoData({ ...proyectoData, title_preliminary_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className="contenedor_datepicker">
                                                                <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                            </div>
                                                :
                                                    <div className="contenedor_datepicker">
                                                        <div className="d-flex">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-calendar-alt"></i>
                                                            </span>
                                                            <DatePicker name="title_preliminary_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                            // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                            onChange={ date => {setProyectoData({ ...proyectoData, title_preliminary_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                        </div>
                                                    </div>
                                        }
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Has it been reviewed?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_review" value="1" onChange={e => handleRadiobuttonTitleReviewed(e)} checked={proyectoData.title_review === true ? true : false} disabled={proyectos.masterlist?.title_review_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_review" value="2" onChange={e => handleRadiobuttonTitleReviewed(e)} checked={proyectoData.title_review === false ? true : false} disabled={proyectos.masterlist?.title_review_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Who did the review?:</label>
                                    {
                                        (proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-user"></i>
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="title_review_who" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_review_who || ''} disabled={proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(proyectoData.title_review_who !== null) ? proyectoData.title_review_who : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="title_review_who" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_review_who || ''} disabled={proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Rep:</label>
                                    {
                                        (proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </span>
                                                                <input autoComplete='off' name="title_rep" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_rep || ''} disabled={proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-info-circle" style={{paddingRight: '10px'}}></i>{(proyectoData.title_rep !== null) ? proyectoData.title_rep : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-info-circle"></i>
                                                        </span>
                                                        <input autoComplete='off' name="title_rep" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_rep || ''} disabled={proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Order number:</label>
                                    {
                                        (proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </span>
                                                                <input autoComplete='off' name="title_order" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_order || ''} disabled={proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-info-circle" style={{paddingRight: '10px'}}></i>{(proyectoData.title_order !== null) ? proyectoData.title_order : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-info-circle"></i>
                                                        </span>
                                                        <input autoComplete='off' name="title_order" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_order || ''} disabled={proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 9 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(4)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(8)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 10 || sectionInformation === 11) ? '' : ' d-none')}>
                    {
                        sectionInformation === 10 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(11)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Termite:</label>
                                    <div className="input-group">
                                        <Select name="id_termite" className="select-group" aria-describedby="button-termite" isClearable={true} options={termitesFilter} value={termiteSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_termite', 'id_termite_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_termite_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_termite_buy !== null || proyectoData.id_termite !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-termite" onClick={() => handleTermite(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewTermiteData.address !== null) ? viewTermiteData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_termite_agent" className="select-group" aria-describedby="button-termite-agent" isClearable={true} options={termitesAgentsFilter} value={termiteAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_termite_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_termite_agent_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_termite_agent_buy !== null || proyectoData.id_termite_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-termite-agent" onClick={() => handleTermite(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewTermiteData.email !== null) ? viewTermiteData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTermiteData.phone !== null) ? viewTermiteData.phone : ''}</p>
                                    </div>
                                </div>                    

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Need to be call for inspection?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="termite_inspection" value="1" onChange={e => handleRadiobuttonTermiteInspection(e)} checked={proyectoData.termite_inspection === true ? true : false} disabled={proyectos.masterlist?.termite_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="termite_inspection" value="2" onChange={e => handleRadiobuttonTermiteInspection(e)} checked={proyectoData.termite_inspection === false ? true : false} disabled={proyectos.masterlist?.termite_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Who is in charge to call?:</label>
                                    <Select name="termite_inspection_user" isClearable={true} options={usersFilter} value={userTermiteSelectData} onChange={(value) => onChangeSelectSearch(value, 'termite_inspection_user', 'name_termite_inspection_user')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.termite_inspection_user_buy !== null || proyectoData.termite_inspection === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Inspection date:</label>
                                    {
                                        (proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker name="termite_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, termite_inspection_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker name="termite_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, termite_inspection_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Report number:</label>
                                    {
                                        (proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        #
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="report_number_termite" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_termite || ''} disabled={proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p>{(proyectoData.report_number_termite !== null) ? '# ' + proyectoData.report_number_termite : ' #'}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                #
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="report_number_termite" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_termite || ''} disabled={proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 11 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(5)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(10)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 12 || sectionInformation === 13) ? '' : ' d-none')}>
                    {
                        sectionInformation === 12 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(13)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Septic:</label>
                                    <div className="input-group">
                                        <Select name="id_septic" className="select-group" aria-describedby="button-septic" isClearable={true} options={septicsFilter} value={septicSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_septic', 'id_septic_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_septic_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_septic_buy !== null || proyectoData.id_septic !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-septic" onClick={() => handleSeptic(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewSepticData.address !== null) ? viewSepticData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_septic_agent" className="select-group" aria-describedby="button-septic-agent" isClearable={true} options={septicsAgentsFilter} value={septicAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_septic_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_septic_agent_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_septic_agent_buy !== null || proyectoData.id_septic_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-septic-agent" onClick={() => handleSeptic(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewSepticData.email !== null) ? viewSepticData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewSepticData.phone !== null) ? viewSepticData.phone : ''}</p>
                                    </div>
                                </div>                 

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Need to be call for inspection?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="septic_inspection" value="1" onChange={e => handleRadiobuttonSepticInspection(e)} checked={proyectoData.septic_inspection === true ? true : false} disabled={proyectos.masterlist?.septic_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="septic_inspection" value="2" onChange={e => handleRadiobuttonSepticInspection(e)} checked={proyectoData.septic_inspection === false ? true : false} disabled={proyectos.masterlist?.septic_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label">No</label>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Who is in charge to call?:</label>
                                    <Select name="septic_inspection_user" isClearable={true} options={usersFilter} value={userSepticSelectData} onChange={(value) => onChangeSelectSearch(value, 'septic_inspection_user', 'name_septic_inspection_user')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.septic_inspection_user_buy !== null || proyectoData.septic_inspection === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Inspection date:</label>
                                    {
                                        (proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker name="septic_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, septic_inspection_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker name="septic_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, septic_inspection_date: date }); dispatch(guardarCambios(true));} } monthsShown={2} disabled={proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Report number:</label>
                                    {
                                        (proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        #
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="report_number_septic" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_septic || ''} disabled={proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p>{(proyectoData.report_number_septic !== null) ? '# ' + proyectoData.report_number_septic : ' #'}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                #
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="report_number_septic" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_septic || ''} disabled={proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 13 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(6)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(12)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>
            </form>
        </Fragment>
    )
}

export default BuyingEscrowFlipping;