import { BUSCAR_ACTIVIDADES, AGREGAR_ACTIVIDAD, EDITAR_ACTIVIDAD, DETALLES_ACTIVIDAD, ELIMINAR_ACTIVIDAD, EXTERNA_ACTIVIDAD, OBTENER_REPORTE_ACTIVIDADES } from './types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarActividades = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities`, body, config);

            dispatch({
                type: BUSCAR_ACTIVIDADES,
                payload: {
                    actividades: res.data.actividades
                }
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: BUSCAR_ACTIVIDADES,
            payload: {
                actividades: []
            }
        });
    }
}

export const agregarActividad = (actividad) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(actividad);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/nueva`, body, config);

        dispatch({
            type: AGREGAR_ACTIVIDAD,
            payload: res.data.actividad
        });

        dispatch({
            type: DETALLES_ACTIVIDAD,
            payload: res.data.actividad
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarActividad = (actividad) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(actividad);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/editar`, body, config);

        dispatch({
            type: EDITAR_ACTIVIDAD,
            payload: res.data.actividad
        });

        dispatch({
            type: DETALLES_ACTIVIDAD,
            payload: res.data.actividad
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesActividad = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/activities/obtener`, config);
        
        dispatch({
            type: DETALLES_ACTIVIDAD,
            payload: res.data.actividad
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const finalizarActividad = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/finalizar`, body, config);
        
        dispatch({
            type: EDITAR_ACTIVIDAD,
            payload:{
                id
            }
        });

        dispatch({
            type: DETALLES_ACTIVIDAD,
            payload: null
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarActividad = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/eliminar`, body, config);
        
        dispatch({
            type: ELIMINAR_ACTIVIDAD,
            payload:{
                id
            }
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externaActividad = (valor) => async dispatch => {
    dispatch({
        type: EXTERNA_ACTIVIDAD,
        payload: valor
    });
}

export const obtenerReporte = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/obtener_reporte`, body, config);

            dispatch({
                type: OBTENER_REPORTE_ACTIVIDADES,
                payload: res.data.informacion
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: OBTENER_REPORTE_ACTIVIDADES,
            payload: null
        });
    }
}

export const generarReporte = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(filtros);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/activities/generar_reporte`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}