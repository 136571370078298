import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

//Actions
import { agregarNoteProyecto, externoNota } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

const styles = {
    contenedor_audio: {
        marginBottom: '10px'
    }
}

const ModalNote = () => {

    const dispatch = useDispatch();

    const { externo_nota, campo_nota, detalles } = useSelector(state => state.proyectos);

    const [sectionsFilter, setSectionsFilter] = useState([]);
    const [sectionAddInformationData, setSectionAddInformationData] = useState(null);

    const recorderControls = useAudioRecorder();

    //Nuevo
    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        id_status: null,
        campo: null,
        nota: null,
        audio: null
    });

    const [voiceNote, setVoiceNote] = useState({
        url: null,
        controls: false
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const handleOpenNuevaNota = () => {

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            campo: campo_nota,
            nota: null,
            audio: null
        });

        setVoiceNote({
            url: null,
            controls: false
        });

        if(detalles?.proyecto?.tipo_status !== null){
            setSectionAddInformationData({value: detalles?.proyecto?.tipo_status, label: detalles?.proyecto?.nombre_status});
        }else{
            setSectionAddInformationData({value: null, label: 'General'});
        }

        let options = [
            {value: null, label: 'General'}
        ];

        for (var i = 0; i < detalles?.proyecto?.lista_status_proyecto?.length; i++) {
            options.push({ value: detalles?.proyecto?.lista_status_proyecto[i]?.id_status, label: detalles.proyecto?.lista_status_proyecto[i]?.nombre });
        }

        setSectionsFilter(options);
    }

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const handleSelect = (option) => {
        setSectionAddInformationData(option);

        setDatosNuevo({
            ...datosNuevo,
            id_status: option !== null ? option.value : null
        });
    }

    const handleVoiceNote = (blob) => {
        const url = URL.createObjectURL(blob);

        setDatosNuevo({
            ...datosNuevo,
            audio: blob
        });

        setVoiceNote({
            url: url,
            controls: true
        });
    }

    const handleNuevaNota = async () => {
        await setBotonNuevo(true);

        if(datosNuevo.id_proyecto !== null){
            let formData = new FormData();
            formData.append('id_proyecto', datosNuevo.id_proyecto);
            formData.append('id_status', datosNuevo.id_status);
            formData.append('campo', datosNuevo.campo);
            formData.append('nota', datosNuevo.nota);
            formData.append('audio', datosNuevo.audio);

            await dispatch(agregarNoteProyecto(formData));

            await handleCloseNuevaNota();
        }else{
            dispatch(setAlert('First select a project', 'danger'));
        }

        await setBotonNuevo(false);
    }

    const handleCloseNuevaNota = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            id_status: null,
            campo: null,
            nota: null,
            audio: null
        });

        setVoiceNote({
            url: null,
            controls: false
        });

        dispatch(externoNota(false, null));

        setSectionAddInformationData(null);
    }

    useEffect(() => {

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            nota: null,
            audio: null
        });

        setVoiceNote({
            url: null,
            controls: false
        });

        if(detalles?.proyecto?.tipo_status !== null){
            setSectionAddInformationData({value: detalles?.proyecto?.tipo_status, label: detalles?.proyecto?.nombre_status});
        }else{
            setSectionAddInformationData({value: null, label: 'General'});
        }

        let options = [
            {value: null, label: 'General'}
        ];

        for (var i = 0; i < detalles?.proyecto?.lista_status_proyecto?.length; i++) {
            options.push({ value: detalles?.proyecto?.lista_status_proyecto[i]?.id_status, label: detalles.proyecto?.lista_status_proyecto[i]?.nombre });
        }

        setSectionsFilter(options);
    }, [detalles]);

    useEffect(() => {

        setDatosNuevo({
            ...datosNuevo,
            campo: campo_nota
        });
    }, [campo_nota]);

    return (
        <Modal show={externo_nota} onEntered={() => { handleOpenNuevaNota(); }} onHide={() => { handleCloseNuevaNota(); }}>
            <Modal.Header closeButton>
                <Modal.Title>New note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Section:</label>
                        <Select name="id_status" isClearable={true} options={sectionsFilter} value={sectionAddInformationData} onChange={(value) => handleSelect(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    {
                        campo_nota !== null ? 
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Field:</label>
                                <input type="text" className="form-control" value={campo_nota} disabled={true} />
                            </div>
                        :
                            null
                    }

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Note:</label>
                        <div className="contenedor_datepicker">
                            <textarea name="nota" className="form-control" onChange={e => onChangeNuevo(e)} value={datosNuevo.nota || ''}></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <div className="col-md-12">
                            <label className="font-weight-bold">Nota de Audio</label>
                        </div>
                        <div style={styles.contenedor_audio}>
                            <AudioRecorder
                                onRecordingComplete={(blob) => handleVoiceNote(blob)}
                                recorderControls={recorderControls}
                            />
                        </div>
                        <audio src={voiceNote.url} controls={voiceNote.controls} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevaNota()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={handleNuevaNota} disabled={botonNuevo}><i className="fas fa-check fa-sm"></i> Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalNote;