import { OBTENER_BANCOS, OBTENER_TIPOS_TARJETAS } from '../actions/types';

const initialState = {
    bancos: [],
    tipos_tarjetas: []
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {

        case OBTENER_BANCOS: 
            return {
                ...state,
                bancos: payload
            };

        case OBTENER_TIPOS_TARJETAS:
            return {
                ...state,
                tipos_tarjetas: payload
            };
        
        default: 
            return state;
    }

}