import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import PlacesAutocomplete from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { setAlert } from '../../../actions/alert';
import { agregarCounterListing, editarListing, continuarListing, guardarCambios, desplegarModalCambios, redireccionar } from '../../../actions/assigned';
import { detallesProyecto, masterlistProyecto, seleccionarSeccionMasterList, agregarFileProyecto, editarFileProyecto, detallesFileProyecto, externoNota } from '../../../actions/proyectos';
import { agregarBrokerMasterlistSale, agregarBrokerAgentMasterlistSale } from '../../../actions/brokers';

const styles = {
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    }
}

const ListingAssigned = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const usuarios = useSelector(state => state.usuarios);
    const brokers = useSelector(state => state.brokers);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);

    const history = useHistory();

    //Atrapar back button del navegador

    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logica para mostrar un popup cuando la pagina se recarga
        window.onbeforeunload = function () {
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page?")) {
                setBackbuttonPress(true);
                history.go(-1);
                handleCloseNoGuardarCambiosModal();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const [proyectoData, setProyectoData] = useState({
        id: null,
        listing_status: 0,
        listing_aggreement: null,
        listing: null,
        listing_aggreement_price: null,
        listing_price: null,
        utilities_are_on: null,
        scheduled_turn_on_electric_user: null,
        scheduled_turn_on_electric: null,
        scheduled_turn_on_water_user: null,
        scheduled_turn_on_water: null,
        scheduled_turn_on_gas_user: null,
        scheduled_turn_on_gas: null,

        id_broker_seller: null,
        id_broker_agent_seller: null
    });

    const [newFileData, setNewFileData] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        subseccion: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [editFileData, setEditFileData] = useState({
        id: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [newCounterData, setNewCounterData] = useState({
        id_proyecto: null,
        id_status: null,
        monto: null,
        nombre: null,
        notas: null,
        extension: null,
        archivo: null
    });

    const [dataTabla, setDataTabla] = useState({
        archivosTabla: null,
        countersTabla: [],
        statusTabla: []
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [statusSaveCounter, setStatusSaveCounter] = useState(true);
    const [statusSaveFile, setStatusSaveFile] = useState(true);
    const [statusSaveEditFile, setStatusSaveEditFile] = useState(true);
    const [statusSelectData, setStatusSelectData] = useState({ value: 0, label: 'Active' });
    
    const [userTurnElectricSelectData, setUserTurnElectricSelectData] = useState(null);
    const [userTurnWaterSelectData, setUserTurnWaterSelectData] = useState(null);
    const [userTurnGasSelectData, setUserTurnGasSelectData] = useState(null);
    const [brokerSellerSelectData, setBrokerSellerSelectData] = useState(null);
    const [brokerAgentSellerSelectData, setBrokerAgentSellerSelectData] = useState(null);

    const [addBrokerSellerData, setAddBrokerSellerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentSellerData, setAddBrokerAgentSellerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerSellerData, setViewBrokerSellerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [sectionInformation, setSectionInformation] = useState(2);   

    const [newCounterModal, setNewCounterModal] = useState(false);
    const [brokerSellerModal, setBrokerSellerModal] = useState(false);
    const [brokerAgentSellerModal, setBrokerAgentSellerModal] = useState(false);
    const [newFileModal, setNewFileModal] = useState(false);
    const [editFileModal, setEditFileModal] = useState(false);
    const [guardarCambiosModal, setGuardarCambiosModal] = useState(false);

    let statusFilter = [
        { value: 0, label: 'Active' },
        { value: 1, label: 'Pendding' },
        { value: 2, label: 'Closed' },
        { value: 3, label: 'Hold' },
        { value: 4, label: 'Cancelled' }
    ];

    const [usersFilter, setUsersFilter] = useState([]);
    const [brokersFilter, setBrokersFilter] = useState([]);
    const [brokersAgentsSellerFilter, setBrokersAgentsSellerFilter] = useState([]);

    let file_counter_ref = createRef();
    let file_ref = createRef();
    let file_edit_ref = createRef();

    const [mostrarBoton, setMostrarBoton] = useState(false);
    const [flag, setFlag] = useState(false);

    const [spinner, setSpinner] = useState(false);

    const newNote = campo => {
        dispatch(externoNota(true, campo));
    }

    //Agregar archivo
    const handleAddFileSection = (subseccion) => {
        setNewFileModal(true);

        setNewFileData({
            ...newFileData,
            subseccion: subseccion
        });
    }

    const handleInputsFile = ({ target }) => {
        setNewFileData({
            ...newFileData,
            [target.name]: target.value
        });
    }

    const onChangeFile = () => {
        let nombre_separado = file_ref.current.files[0].name.split('.');

        setNewFileData({
            ...newFileData,
            archivo: file_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseAgregarArchivo = () => {
        setNewFileModal(false);
        setNewFileData({
            ...newFileData,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('subseccion', newFileData.subseccion);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);

                    setNewFileData({
                        ...newFileData,
                        id_folder: null,
                        subseccion: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setSectionInformation((newFileData.subseccion * 2) + 1);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    //Editar archivo
    const handleInputsEditFile = ({ target }) => {
        setEditFileData({
            ...editFileData,
            [target.name]: target.value
        });
    }

    const onChangeEditFile = () => {
        let nombre_separado = file_edit_ref.current.files[0].name.split('.');

        setEditFileData({
            ...editFileData,
            archivo: file_edit_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseEditarArchivo = () => {
        setEditFileModal(false);
        setEditFileData({
            ...editFileData,
            id: null,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarEditarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(editFileData.extension !== ''){
                if(editFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id', editFileData.id);
                    formData.append('nombre', editFileData.nombre);
                    formData.append('extension', editFileData.extension);
                    formData.append('archivo', editFileData.archivo);

                    setStatusSaveEditFile(false);
                    await dispatch(editarFileProyecto(formData));
                    setStatusSaveEditFile(true);

                    setEditFileData({
                        ...editFileData,
                        id: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setEditFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyecto(id));
    }

    const handleEditarArchivo = async (archivo) => {
        setEditFileModal(true);

        setEditFileData({
            ...editFileData,
            id: archivo._id,
            nombre: archivo.nombre,
            extension: archivo.extension
        });
    }

    //Archivos
    const tableRow = (items) => {
        if(items !== null){

            items = items.filter(archivo => (archivo.subseccion !== null && (archivo.subseccion * 2) === (sectionInformation - 1)));

            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td>{item.nombre || '-'}</td>
                                            <td>{item.extension || '-'}</td>
                                            <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                            <td>
                                                <button className="btn" type="button" onClick={() => { handleIconoArchivo(item._id) }}><i className="fa fa-file-download" style={styles.buttonIcon} /></button>
                                                <button className="btn" type="button" onClick={() => { handleEditarArchivo(item) }}><i className="fas fa-edit" style={styles.buttonIcon} /></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    //Otros
    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){
                case 'listing_status':
                    await setStatusSelectData({value, label});
                    break;

                case 'scheduled_turn_on_electric_user':
                    await setUserTurnElectricSelectData({value, label});
                    break;

                case 'scheduled_turn_on_water_user':
                    await setUserTurnWaterSelectData({value, label});
                    break;

                case 'scheduled_turn_on_gas_user':
                    await setUserTurnGasSelectData({value, label});
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData({value, label});
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData({value, label});
                    break;

                default:
                    break;
            }

            if(origin === 'id_broker_seller'){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: label
                });
            }
        }else{

            switch(origin){
                case 'listing_status':
                    await setStatusSelectData(null);
                    break;

                case 'scheduled_turn_on_electric_user':
                    await setUserTurnElectricSelectData(null);
                    break;

                case 'scheduled_turn_on_water_user':
                    await setUserTurnWaterSelectData(null);
                    break;

                case 'scheduled_turn_on_gas_user':
                    await setUserTurnGasSelectData(null);
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData(null);
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData(null);
                    break;

                default:
                    break;
            }

            await setProyectoData({
                ...proyectoData,
                [origin]: null,
                [name_origin]: null
            });
        }

        await dispatch(guardarCambios(true));
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value
        });

        dispatch(guardarCambios(true));
    };

    const handleRadiobutton = ({ target }) => {
        if(target.checked){
            let valores = {
                ...proyectoData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            };

            if(target.name === 'utilities_are_on'){
                valores.scheduled_turn_on_electric = null;
                valores.scheduled_turn_on_water = null;
                valores.scheduled_turn_on_gas = null;
            }

            setProyectoData(valores);

            dispatch(guardarCambios(true));
        }
    }

    const handleBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(true);
        }else{

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                id_broker: proyectoData.id_broker_seller
            });

            setBrokerAgentSellerModal(true);            
        }
    }

    const handleCloseModalBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(false);
        }else{
            setBrokerAgentSellerModal(false);            
        }
    }

    const handleViewBrokerSeller = async () => {
        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_seller !== null){
            let broker_seller_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_seller));
            if(broker_seller_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_seller_elegido[0].compania,
                    address: broker_seller_elegido[0].direccion || '',
                    agents: broker_seller_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_seller_elegido[0]._id,
                    label: broker_seller_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (var i = 0; i < broker_seller_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_seller_elegido[0].agentes[i]._id, label: broker_seller_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_seller !== null){
                    let broker_agent_elegido = await broker_seller_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_seller));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerSellerData(datos_broker_view);
        await setBrokerSellerSelectData(datos_broker_select);
        await setBrokersAgentsSellerFilter(datos_broker_filter);
        await setBrokerAgentSellerSelectData(datos_broker_agent_select);
    }

    const handleInputsAddBrokerSeller = ({ target }) => {
        setAddBrokerSellerData({
            ...addBrokerSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentSeller = ({ target }) => {
        setAddBrokerAgentSellerData({
            ...addBrokerAgentSellerData,
            [target.name]: target.value
        });
    }

    const handleChangeAddress = async (address, origin) => {
        switch(origin){
            case 'broker_seller':
                await setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    address: address
                });
                break;

            default:
                break;
        }
    };

    const handleAddBrokerSeller = async (e) => {
        e.preventDefault();
        if(addBrokerSellerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistSale(addBrokerSellerData, 2));

            await setProyectoData({
                ...proyectoData,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_sale
            });

            await dispatch(guardarCambios(true));

            await setBrokerSellerSelectData({
                value: proyectos.masterlist?.id_broker_seller_sale,
                label: addBrokerSellerData.company
            });

            await setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });

            await setBrokerSellerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentSeller = async (e) => {
        e.preventDefault();
        if(addBrokerAgentSellerData.officer_name.replace(/\s/g, '') !== ''){
            if(addBrokerAgentSellerData.license.replace(/\s/g, '') !== ''){
                await dispatch(agregarBrokerAgentMasterlistSale(addBrokerAgentSellerData, 2));

                await setProyectoData({
                    ...proyectoData,
                    id_broker_seller: proyectos.masterlist?.id_broker_seller_sale,
                    id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_sale
                });

                await dispatch(guardarCambios(true));

                if(!addBrokerAgentSellerData.id_broker){
                    await setBrokerSellerSelectData({
                        value: proyectos.masterlist?.id_broker_seller_sale,
                        label: 'Without name'
                    });
                }

                await setBrokerAgentSellerSelectData({
                    value: proyectos.masterlist?.id_broker_agent_seller_sale,
                    label: addBrokerAgentSellerData.officer_name
                });

                await setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });

                await setBrokerAgentSellerModal(false);
            }else{
                dispatch(setAlert('First enter the license number', 'danger'));
            }
        }else{
            dispatch(setAlert('First fill the name field to add a new broker agent', 'warning'));
        }
    }

    const handleAgregarCounter = () => {
        setNewCounterModal(true);

        let nuevo_counter = 0;

        for (var i = 0; i < dataTabla.countersTabla?.length; i++) {
            if(dataTabla.countersTabla[i]?.nombre_archivo){
                let nombre_separado = dataTabla.countersTabla[i]?.nombre_archivo?.split(' ');

                if(parseInt(nombre_separado[1]) > nuevo_counter){
                    nuevo_counter = parseInt(nombre_separado[1]);
                }
            }
        }

        nuevo_counter++;

        setNewCounterData({
            ...newCounterData,
            nombre: nuevo_counter
        });
    }

    const handleInputsNewCounter = ({ target }) => {
        setNewCounterData({
            ...newCounterData,
            [target.name]: target.value
        });
    }

    const onNumberChangeCounter = (name, value) => {
        setNewCounterData({
            ...newCounterData, [name]: value
        });
    }

    const onChangeFileCounter = () => {
        let nombre_separado = file_counter_ref.current.files[0].name.split('.');

        setNewCounterData({
            ...newCounterData,
            archivo: file_counter_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseAgregarCounter = () => {
        setNewCounterData({
            ...newCounterData,
            monto: null,
            nombre: null,
            notas: null,
            extension: null,
            archivo: null
        });
        setNewCounterModal(false);
    }

    const handleGuardarCambiosModal = () => {
        setGuardarCambiosModal(true);
    }

    const handleCloseGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(desplegarModalCambios(false));
    }

    const handleCloseNoGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(guardarCambios(false));
        dispatch(desplegarModalCambios(false));
        dispatch(redireccionar(proyectos.paginaRedireccion));
    }

    const handleSubmitModal = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarListing(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));

        dispatch(redireccionar(proyectos.paginaRedireccion));
    };

    const handleSubmitCounter = async (e) => {
        e.preventDefault();
        try {
            if(newCounterData.extension !== '' && newCounterData.extension !== null){
                if(newCounterData.nombre !== '' && newCounterData.nombre !== null){
                    if(newCounterData.monto !== '' && newCounterData.monto !== null){
                        let formData = new FormData();
                        formData.append('id_proyecto', newCounterData.id_proyecto);
                        formData.append('id_status', newCounterData.id_status);
                        formData.append('monto', newCounterData.monto);
                        formData.append('nombre', newCounterData.nombre);
                        formData.append('notas', newCounterData.notas);
                        formData.append('extension', newCounterData.extension);
                        formData.append('archivo', newCounterData.archivo);

                        setStatusSaveCounter(false);
                        await dispatch(agregarCounterListing(formData));
                        setStatusSaveCounter(true);

                        setNewCounterModal(false);

                        setDataTabla({
                            countersTabla: [
                                ...dataTabla.countersTabla, 
                                {
                                    amount: newCounterData.monto,
                                    note: newCounterData.notas,
                                    nombre_archivo: 'Counter ' + newCounterData.nombre,
                                    extension_archivo: newCounterData.extension,
                                    fecha: new Date()
                                }
                            ]
                        });

                        setNewCounterData({
                            ...newCounterData,
                            monto: null,
                            nombre: null,
                            notas: null,
                            extension: null,
                            archivo: null
                        });
                    }else{
                        dispatch(setAlert('First enter a price', 'danger'));
                    }
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const numberToDecimal = (number, price = '$') => {
        if(isNaN(number)){
            return price + ' -';
        }else{
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = price + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }
    }

    const focusCampo = ({target}) => {
        let id_focusear = target.dataset.focus;
        let campo = document.getElementById(id_focusear);
        campo.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarListing(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const handleContinue = async (e) => {
        e.preventDefault();

        if (proyectoData.listing !== null && proyectoData.listing_price !== null && proyectoData.utilities_are_on !== null) {
            setSpinner(true);
            setTimeout(async() => {
                await setGuardarCambiosModal(false);
                await dispatch(continuarListing(proyectoData));
                await dispatch(masterlistProyecto(proyectoData.id));
                await dispatch(detallesProyecto(proyectoData.id));
                setSpinner(false);
                await dispatch(seleccionarSeccionMasterList('Sells in escrow'));
            }, 0);
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    // Tabla de counters
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columnsCounters = [
        {
            name: 'Name of counter',
            selector: 'nombre_archivo',
            sortable: true,
            cell: item => `${item.nombre_archivo !== null ? item.nombre_archivo : '-'}`,
        }, {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            cell: item => `${item.amount !== null ? numberToDecimal(item.amount, '$ ') : '-'}`,
        }, {
            name: 'Notes',
            selector: 'note',
            sortable: true,
            cell: item => `${item.note !== null ? item.note : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }
    ];

    // Tabla de status
    const columnsStatus = [
        {
            name: 'Status',
            selector: 'nombre_status',
            sortable: true,
            cell: item => `${item.nombre_status !== null ? item.nombre_status : '-'}`,
        }, {
            name: 'Days in this status',
            selector: 'amount',
            sortable: true,
            cell: item => `${item.duracion !== null ? item.duracion : '-'}`,
        }, {
            name: 'Initial date',
            selector: 'fecha_inicio',
            sortable: true,
            cell: item => `${ item.fecha_inicio !== null ? new Date(item.fecha_inicio).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'End date',
            selector: 'fecha_fin',
            sortable: true,
            cell: item => `${ item.fecha_fin !== null ? new Date(item.fecha_fin).toLocaleDateString('en-US') : 'Not defined' }`,
        }
    ];

    useEffect(() => {

        let options_usuario = [];
        let options_broker = [];

        for (let i = 0; i < usuarios?.length; i++) {
            options_usuario.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options_broker.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setUsersFilter(options_usuario);
        setBrokersFilter(options_broker);
    }, []);

    useEffect(() => {
        if (proyectoData.listing !== null && proyectoData.listing_price !== null && proyectoData.utilities_are_on !== null) {
            setMostrarBoton(true);
        } else {
            setMostrarBoton(false);
        }

        handleViewBrokerSeller();
    }, [proyectoData]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < brokers.brokers?.length; i++) {
            options.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options);
    }, [brokers]);

    useEffect(() => {

        if (proyectos.detalles !== undefined && proyectos.detalles !== null) {
            setNewCounterData({
                ...proyectoData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });

            setNewFileData({
                ...newFileData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(!proyectos.guardar_cambios){
            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id,
                listing_status: proyectos.masterlist?.listing_status,
                listing_aggreement: proyectos.masterlist?.listing_aggreement,
                listing: proyectos.masterlist?.listing,
                listing_aggreement_price: proyectos.masterlist?.listing_aggreement_price,
                listing_price: proyectos.masterlist?.listing_price,
                utilities_are_on: proyectos.masterlist?.utilities_are_on,
                scheduled_turn_on_electric: proyectos.masterlist?.scheduled_turn_on_electric,
                scheduled_turn_on_water: proyectos.masterlist?.scheduled_turn_on_water,
                scheduled_turn_on_gas: proyectos.masterlist?.scheduled_turn_on_gas,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_sale,
                id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_sale
            });

            if(proyectos.masterlist?.listing_status !== null){

                let listing_status_elegido = statusFilter.find(status => String(status.value) === String(proyectos.masterlist?.listing_status));

                if(listing_status_elegido){
                    setStatusSelectData({
                        value: listing_status_elegido.value,
                        label: listing_status_elegido.label
                    });
                }else{
                    setUserTurnElectricSelectData(null);                    
                }
            }else{
                setUserTurnElectricSelectData(null);
            }

            if(proyectos.masterlist?.scheduled_turn_on_electric_user !== null){
                setUserTurnElectricSelectData({
                    value: proyectos.masterlist?.scheduled_turn_on_electric_user,
                    label: proyectos.masterlist?.name_scheduled_turn_on_electric_user
                });
            }else{
                setUserTurnElectricSelectData(null);
            }

            if(proyectos.masterlist?.scheduled_turn_on_water_user !== null){
                setUserTurnWaterSelectData({
                    value: proyectos.masterlist?.scheduled_turn_on_water_user,
                    label: proyectos.masterlist?.name_scheduled_turn_on_water_user
                });
            }else{
                setUserTurnWaterSelectData(null);
            }

            if(proyectos.masterlist?.scheduled_turn_on_gas_user !== null){
                setUserTurnGasSelectData({
                    value: proyectos.masterlist?.scheduled_turn_on_gas_user,
                    label: proyectos.masterlist?.name_scheduled_turn_on_gas_user
                });
            }else{
                setUserTurnGasSelectData(null);
            }

            if(proyectos.masterlist?.id_broker_seller_sale !== null){
                setBrokerSellerSelectData({
                    value: proyectos.masterlist?.id_broker_seller_sale,
                    label: proyectos.masterlist?.name_broker_seller_sale
                });
            }else{
                setBrokerSellerSelectData(null);
            }
        }

        if(proyectos.modal_guardado_flipping=== true){
            handleGuardarCambiosModal();
        }

        setDataTabla({
            countersTabla: proyectos.masterlist?.listing_price_counters,
            statusTabla: proyectos.masterlist?.listing_status_history,
            archivosTabla: proyectos.files
        });
    }, [proyectos]);

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    return (
        <Fragment>
            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}

            {/* Modal de guardado de cambios */}
            <Modal backdrop="static" show={guardarCambiosModal} onHide={() => {
                handleCloseGuardarCambiosModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to save the changes?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNoGuardarCambiosModal()}>No</button>
                    <button className="btn btn-success" onClick={handleSubmitModal}>Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker vendedor de venta */}
            <Modal backdrop="static" show={brokerSellerModal} size="lg" onHide={() => {
                setBrokerSellerModal(false);
                setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (Listing)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerSeller(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addBrokerSellerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_seller')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente vendedor de venta */}
            <Modal backdrop="static" show={brokerAgentSellerModal} size="lg" onHide={() => {
                setBrokerAgentSellerModal(false);
                setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (listing) ({brokerSellerSelectData ? brokerSellerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.celular} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.telefono} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo counter */}
            <Modal backdrop="static" show={newCounterModal} size="lg" onHide={() => {
                setNewCounterModal(false);
                setNewCounterData({
                    ...newCounterData,
                    monto: null,
                    nombre: null,
                    notas: null,
                    extension: null,
                    archivo: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Create new counter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Counter price:</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-dollar-sign"></i>
                                    </span>
                                </div>
                                <CurrencyInput autoComplete='off'
                                    name="monto"
                                    className="form-control text-center"
                                    value={newCounterData.monto || ''}
                                    decimalsLimit={2}
                                    groupSeparator=","
                                    decimalSeparator="."
                                    onValueChange={(value, name) => onNumberChangeCounter(name, value)}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Counter number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-hashtag"></i>
                                        </span>
                                    </div>
                                    <input
                                        autoComplete='off'
                                        name="nombre"
                                        className="form-control"
                                        type="number"
                                        onChange={e => handleInputsNewCounter(e)}
                                        value={newCounterData.nombre}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Counter file:</label><br/>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="file" ref={file_counter_ref} onChange={onChangeFileCounter} aria-describedby="view_file_file"/>
                                    <label className="custom-file-label">{newCounterData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newCounterData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Notes:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-info-circle"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="notas" className="form-control" type="text" onChange={e => handleInputsNewCounter(e)} value={newCounterData.notas || ''}/>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarCounter()} disabled={statusSaveCounter ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitCounter} disabled={statusSaveCounter ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo archivo */}
            <Modal backdrop="static" show={newFileModal} size="lg" onHide={() => {
                handleCloseAgregarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New file to upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsFile(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="file" ref={file_ref} onChange={onChangeFile} aria-describedby="view_file_file"/>
                                    <label className="custom-file-label">{newFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarArchivo()} disabled={statusSaveFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarArchivo} disabled={statusSaveFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar archivo */}
            <Modal backdrop="static" show={editFileModal} size="lg" onHide={() => {
                handleCloseEditarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFile(e)} value={editFileData.nombre || ''} disabled={statusSaveEditFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={file_edit_ref} onChange={onChangeEditFile} aria-describedby="view_file_editFile"/>
                                    <label className="custom-file-label">{editFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarArchivo()} disabled={statusSaveEditFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarEditarArchivo} disabled={statusSaveEditFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 9 ?
                    <>
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={spinner ? true : false}>Save</button>
                    </>
                    :   null
                }
                {
                    mostrarBoton && (proyectos.detalles?.proyecto?.identificador_status <= 9) ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleContinue} disabled={spinner ? true : false}>Save and Continue</button>
                    :   null
                }                    
            </div>      

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleContinue}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>

            <form className="col-md-12 pb-3" autoComplete="off">
                <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />

                { spinner ? 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                            </div>
                        </div> 
                    :
                        null
                }

                <div className="row">
                    <div className="col-md-2 form-group campo_select_sm">
                        <label className="font-weight-bold" onClick={() => newNote('Status')}>Status:</label>
                        {
                            (!proyectos.edicion_proyecto)
                                ?
                                    <div>
                                        <label>{statusSelectData.label}</label>
                                    </div>
                                :
                                    <div>
                                        <Select
                                            name="listing_status"
                                            options={statusFilter}
                                            value={statusSelectData}
                                            onChange={(value) => onChangeSelectSearch(value, 'listing_status', '')}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                        }
                    </div>

                    <div className={'col-md-2 form-group campo_fecha' + (proyectoData.listing_aggreement !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Listing aggreement date')}>Listing agreement date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.listing_aggreement !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.listing_aggreement ? new Date(proyectoData.listing_aggreement).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="listingAgreement" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="listingAgreement" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="listingAgreement"
                                                name="listing_aggreement"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.listing_aggreement ? new Date(proyectoData.listing_aggreement) : '' } 
                                                // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                onChange={ date => {setProyectoData({ ...proyectoData, listing_aggreement: date }); dispatch(guardarCambios(true));} }
                                                monthsShown={2}
                                                disabled={proyectos.masterlist?.listing_aggreement !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md-2 form-group campo_fecha' + (proyectoData.listing !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Listing date')}>Listing date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.listing !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.listing ? new Date(proyectoData.listing).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="listingDate" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="listingDate" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="listingDate"
                                                name="listing"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.listing ? new Date(proyectoData.listing) : '' } 
                                                // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                onChange={ date => {setProyectoData({ ...proyectoData, listing: date }); dispatch(guardarCambios(true));} }
                                                monthsShown={2}
                                                disabled={proyectos.masterlist?.listing !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md-2 form-group campo_dinero' + (proyectoData.listing_aggreement_price !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Listing price')}>Listing aggreement price:</label>
                        {
                            (proyectos.masterlist?.listing_aggreement_price !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{ numberToDecimal(proyectoData.listing_aggreement_price, '$ ') }</label>
                                    </div>
                                :
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" data-focus="listingAggreementPrice" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-dollar-sign" data-focus="listingAggreementPrice" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                        </div>
                                        <CurrencyInput
                                            id="listingAggreementPrice"
                                            autoComplete='off'
                                            name="listing_aggreement_price"
                                            className="form-control text-center"
                                            value={proyectoData.listing_aggreement_price || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.listing_aggreement_price !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                        />
                                    </div>
                        }
                    </div>

                    <div className={'col-md-2 form-group campo_dinero' + (proyectoData.listing_price !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Listing price')}>Listing price:</label>
                        {
                            (proyectos.masterlist?.listing_price !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{ numberToDecimal(proyectoData.listing_price, '$ ') }</label>
                                    </div>
                                :
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" data-focus="listingPrice" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-dollar-sign" data-focus="listingPrice" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                        </div>
                                        <CurrencyInput
                                            id="listingPrice"
                                            autoComplete='off'
                                            name="listing_price"
                                            className="form-control text-center"
                                            value={proyectoData.listing_price || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.listing_price !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                        />
                                    </div>
                        }
                    </div>

                    <div className={'col-md-2 form-group campo_radio_sm' + (proyectoData.utilities_are_on !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Utilities are on?')}>Utilities are on?:</label><br/>
                        {
                            (proyectoData.utilities_are_on !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{(proyectoData.utilities_are_on === true) ? 'Yes' : 'No'}</label>
                                    </div>
                                :
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="utilities_are_on" value="1" onChange={e => handleRadiobutton(e)} checked={proyectoData.utilities_are_on === true ? true : false} disabled={proyectos.masterlist?.utilities_are_on !== null ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="utilities_are_on" value="2" onChange={e => handleRadiobutton(e)} checked={proyectoData.utilities_are_on === false ? true : false} disabled={proyectos.masterlist?.utilities_are_on !== null ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                        }
                    </div>

                    {
                        proyectoData.utilities_are_on === false ?
                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who will call? (electric)')}>Who will call? (electric):</label>
                                {
                                    (proyectos.masterlist?.scheduled_turn_on_electric_user !== null && !proyectos.edicion_proyecto)
                                        ?
                                            <div className='d-flex'>
                                                <label>{userTurnElectricSelectData.label}</label>
                                            </div>
                                        :
                                            <div>
                                                <Select
                                                    name="scheduled_turn_on_electric_user"
                                                    isClearable={true}
                                                    options={usersFilter}
                                                    value={userTurnElectricSelectData}
                                                    onChange={(value) => onChangeSelectSearch(value, 'scheduled_turn_on_electric_user', '')}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    isDisabled={proyectos.masterlist?.scheduled_turn_on_electric_user !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                }
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.utilities_are_on === false ? 
                            <div className="col-md-2 form-group campo_fecha">
                                <label className="font-weight-bold" onClick={() => newNote('Scheduled turn on date (electric)')}>Scheduled turn on date (electric):</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (proyectos.masterlist?.scheduled_turn_on_electric !== null && !proyectos.edicion_proyecto)
                                            ?
                                                <div className='d-flex'>
                                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.scheduled_turn_on_electric ? new Date(proyectoData.scheduled_turn_on_electric).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="scheduledElectric" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="scheduledElectric" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker
                                                        id="scheduledElectric"
                                                        name="scheduled_turn_on_electric"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={ proyectoData.scheduled_turn_on_electric ? new Date(proyectoData.scheduled_turn_on_electric) : '' } 
                                                        // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, scheduled_turn_on_electric: date }); dispatch(guardarCambios(true));} }
                                                        monthsShown={2}
                                                        disabled={proyectos.masterlist?.scheduled_turn_on_electric !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.utilities_are_on === false ?
                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who will call? (water)')}>Who will call? (water):</label>
                                {
                                    (proyectos.masterlist?.scheduled_turn_on_water_user !== null && !proyectos.edicion_proyecto)
                                        ?
                                            <div>
                                                <label>{userTurnWaterSelectData.label}</label>
                                            </div>
                                        :
                                            <div className='d-flex'>
                                                <Select
                                                    name="scheduled_turn_on_water_user"
                                                    isClearable={true}
                                                    options={usersFilter}
                                                    value={userTurnWaterSelectData}
                                                    onChange={(value) => onChangeSelectSearch(value, 'scheduled_turn_on_water_user', '')}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    isDisabled={proyectos.masterlist?.scheduled_turn_on_water_user !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                }
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.utilities_are_on === false ?
                            <div className="col-md-2 form-group campo_fecha">
                                <label className="font-weight-bold" onClick={() => newNote('Scheduled turn on date (water)')}>Scheduled turn on date (water):</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (proyectos.masterlist?.scheduled_turn_on_water !== null && !proyectos.edicion_proyecto)
                                            ?
                                                <div>
                                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.scheduled_turn_on_water ? new Date(proyectoData.scheduled_turn_on_water).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="scheduledWater" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="scheduledWater" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker
                                                        id="scheduledWater"
                                                        name="scheduled_turn_on_water"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={ proyectoData.scheduled_turn_on_water ? new Date(proyectoData.scheduled_turn_on_water) : '' } 
                                                        // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, scheduled_turn_on_water: date }); dispatch(guardarCambios(true));} }
                                                        monthsShown={2}
                                                        disabled={proyectos.masterlist?.scheduled_turn_on_water !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }
                    
                    {
                        proyectoData.utilities_are_on === false ?
                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who will call? (gas)')}>Who will call? (gas):</label>
                                {
                                    (proyectos.masterlist?.scheduled_turn_on_gas_user !== null && !proyectos.edicion_proyecto)
                                        ?
                                            <div className='d-flex'>
                                                <label>{userTurnGasSelectData.label}</label>
                                            </div>
                                        :
                                            <div>
                                                <Select
                                                    name="scheduled_turn_on_gas_user"
                                                    isClearable={true}
                                                    options={usersFilter}
                                                    value={userTurnGasSelectData}
                                                    onChange={(value) => onChangeSelectSearch(value, 'scheduled_turn_on_gas_user', '')} menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    isDisabled={proyectos.masterlist?.scheduled_turn_on_gas_user !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                }
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.utilities_are_on === false ?
                            <div className="col-md-2 form-group campo_fecha">
                                <label className="font-weight-bold" onClick={() => newNote('Scheduled turn on date (gas)')}>Scheduled turn on date (gas):</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (proyectos.masterlist?.scheduled_turn_on_gas !== null && !proyectos.edicion_proyecto)
                                            ?
                                                <div>
                                                    <label><i className='fas fa-calenar-alt'/> {proyectoData.scheduled_turn_on_gas ? new Date(proyectoData.scheduled_turn_on_gas).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="scheduledGas" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="scheduledGas" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker
                                                        id="scheduledGas"
                                                        name="scheduled_turn_on_gas"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={ proyectoData.scheduled_turn_on_gas ? new Date(proyectoData.scheduled_turn_on_gas) : '' } 
                                                        // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, scheduled_turn_on_gas: date }); dispatch(guardarCambios(true));} }
                                                        monthsShown={2}
                                                        disabled={proyectos.masterlist?.scheduled_turn_on_gas !== null || proyectoData.utilities_are_on === true || proyectoData.utilities_are_on === null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <ul className="nav nav-tabs nav-fill">
                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 2 || sectionInformation === 3) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(2)}>Listing Broker</button>
                            </li>
                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 4 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(4)}>Listing Counters</button>
                            </li>
                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 5 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(5)}>Status history</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 2 || sectionInformation === 3) ? '' : ' d-none')}>
                    {
                        sectionInformation === 2 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(3)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select
                                            name="id_broker_seller"
                                            className="select-group"
                                            aria-describedby="button-broker-seller"
                                            isClearable={true}
                                            options={brokersFilter}
                                            value={brokerSellerSelectData}
                                            onChange={(value) => onChangeSelectSearch(value, 'id_broker_seller', 'id_broker_agent_seller')}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            isDisabled={proyectos.masterlist?.id_broker_seller_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}
                                        />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_seller_sale !== null || proyectoData.id_broker_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-seller" onClick={() => handleBrokerSeller(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.address !== '') ? viewBrokerSellerData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_seller" className="select-group" aria-describedby="button-broker-agent-seller" isClearable={true} options={brokersAgentsSellerFilter} value={brokerAgentSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_seller', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_seller_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_seller_sale !== null || proyectoData.id_broker_agent_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-seller" onClick={() => handleBrokerSeller(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.email !== '') ? viewBrokerSellerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.cell_phone !== '') ? viewBrokerSellerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.phone !== '') ? viewBrokerSellerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerSellerData.license !== '') ? '# ' + viewBrokerSellerData.license  : '#'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker">
                                        <p>{(viewBrokerSellerData.public !== '') ? '# ' + viewBrokerSellerData.public  : '#'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 3 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(1)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(2)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + (sectionInformation === 4 ? '' : ' d-none')}>
                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-info ml-2" onClick={() => handleAgregarCounter()}>New counter</button>
                    </div>

                    <div className="table-responsive pl-2">
                        {
                            proyectos.masterlist?.listing_price_counters !== undefined ?
                                <DataTable
                                    columns={columnsCounters}
                                    data={dataTabla.countersTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>                    
                </div>

                <div className={'row p-3 mx-0 seccion_information' + (sectionInformation === 5 ? '' : ' d-none')}>
                    <div className="table-responsive pl-2">
                        {
                            proyectos.masterlist?.listing_price_counters !== undefined ?
                                <DataTable
                                    columns={columnsStatus}
                                    data={dataTabla.statusTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>                    
                </div>
            </form>
        </Fragment>
    )
}

export default ListingAssigned;