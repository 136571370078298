import React, { Fragment, useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Modal, Badge } from 'react-bootstrap';

//Actions
import { detallesProveedor, agregarDeudaProveedor, detallesDeudaProveedor, detallesFileDeudaProveedor, editarDeudaProveedor, eliminarDeudaProveedor, agregarPagoProveedor, detallesPagoProveedor, editarPagoProveedor, eliminarPagoProveedor } from '../../actions/deudas';
import { setAlert } from '../../actions/alert';

const styles = {
    titulo: {
        color: '#2C2052'
    }, 
    titulo_restaltado: {
        color: '#FFFFFF'
    }, 
    infoText: {
        margin: 0
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const DetallesProveedor = () => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles_proveedor, detalles_deuda_proveedor, detalles_pago_proveedor, detalles_archivo } = useSelector(state => state.deudas);

    const [datosProveedor, setDatosProveedor] = useState({
        deuda_total: 0, 
        pago_total: 0, 
        balance: 0, 
        pagado: false,
        notas: null
    });

    const [dataTabla, setDataTabla] = useState({
        deudasProveedor: [],
        pagosProveedor: []
    });

    /* Nueva deuda */
    const [datosNuevaDeuda, setDatosNuevaDeuda] = useState({
        id_proveedor: null,
        monto: null,
        fecha: null,
        concepto: null,
        observaciones: null,
        archivo: null
    });

    let nuevo_ref = createRef();

    const [nuevaDeudaModal, setNuevaDeudaoModal] = useState(false);
    const [botonNuevaDeuda, setBotonNuevaDeuda] = useState(false);

    /* Editar deuda */
    const [datosEditarDeuda, setDatosEditarDeuda] = useState({
        id: null,
        id_proveedor: null,
        monto: null,
        fecha: null,
        concepto: null,
        observaciones: null,
        archivo: null, 
        url: null
    });

    let editar_ref = createRef();

    const [editarDeudaModal, setEditarDeudaModal] = useState(false);
    const [botonEditarDeuda, setBotonEditarDeuda] = useState(false);

    /* Eliminar deuda */
    const [datosEliminarDeuda, setDatosEliminarDeuda] = useState({
        id: null,
        id_proveedor: null,
        monto: null
    });

    const [eliminarDeudaModal, setEliminarDeudaModal] = useState(false);
    const [botonEliminarDeuda, setBotonEliminarDeuda] = useState(false);

    /* Nuevo pago */
    const [datosNuevoPago, setDatosNuevoPago] = useState({
        id_proveedor: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [nuevoPagoModal, setNuevoPagoModal] = useState(false);
    const [botonNuevoPago, setBotonNuevoPago] = useState(false);

    /* Editar pago */
    const [datosEditarPago, setDatosEditarPago] = useState({
        id: null,
        id_proveedor: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [editarPagoModal, setEditarPagoModal] = useState(false);
    const [botonEditarPago, setBotonEditarPago] = useState(false);

    /* Eliminar pago */
    const [datosEliminarPago, setDatosEliminarPago] = useState({
        id: null,
        id_proveedor: null,
        monto: null, 
        pagos: 0
    });

    const [eliminarPagoModal, setEliminarPagoModal] = useState(false);
    const [botonEliminarPago, setBotonEliminarPago] = useState(false);

    //Nueva deuda
    const handleOpenNuevaDeuda = () => {
        setDatosNuevaDeuda({
            ...datosNuevaDeuda, 
            fecha: new Date()
        });
        
        setNuevaDeudaoModal(true);
    }

    const onNumberChangeNuevaDeuda = (name, value) => {
        setDatosNuevaDeuda({
            ...datosNuevaDeuda, [name]: value
        });
    }

    const onChangeFileNuevo = () => {
        setDatosNuevaDeuda({
            ...datosNuevaDeuda,
            archivo: nuevo_ref.current.files[0]
        });
    }

    const onChangeNuevaDeuda = ({ target }) => {
        setDatosNuevaDeuda({
            ...datosNuevaDeuda,
            [target.name]: target.value
        });
    }

    const handleSaveNuevaDeuda = async () => {
        if(datosNuevaDeuda.monto !== null && datosNuevaDeuda.monto !== ''){
            if(datosNuevaDeuda.fecha !== null && datosNuevaDeuda.fecha !== ''){
                await setBotonNuevaDeuda(true);

                let formData = new FormData();
                formData.append('id_proveedor', datosNuevaDeuda.id_proveedor);
                formData.append('monto', datosNuevaDeuda.monto);
                formData.append('fecha', datosNuevaDeuda.fecha);
                formData.append('concepto', datosNuevaDeuda.concepto);
                formData.append('observaciones', datosNuevaDeuda.observaciones);
                formData.append('archivo', datosNuevaDeuda.archivo);
                
                await dispatch(agregarDeudaProveedor(formData));

                await handleCloseNuevaDeuda();

                await setBotonNuevaDeuda(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseNuevaDeuda = () => {
        setDatosNuevaDeuda({
            ...datosNuevaDeuda, 
            monto: null,
            fecha: null,
            concepto: null,
            observaciones: null,
            archivo: null
        });

        setNuevaDeudaoModal(false);
    }

    //Editar deuda
    const handleSetEditarDeuda = (id_deuda) => {
        let deuda = {
            id_proveedor: id,
            id: id_deuda
        };

        dispatch(detallesDeudaProveedor(deuda));
    }    

    const handleOpenEditarDeuda = () => {
        setEditarDeudaModal(true);
    }

    const onNumberChangeEditarDeuda = (name, value) => {
        setDatosEditarDeuda({
            ...datosEditarDeuda, [name]: value
        });
    }

    const onChangeFileEditar = () => {
        setDatosEditarDeuda({
            ...datosEditarDeuda,
            archivo: editar_ref.current.files[0]
        });
    }

    const onChangeEditarDeuda = ({ target }) => {
        setDatosEditarDeuda({
            ...datosEditarDeuda,
            [target.name]: target.value
        });
    }

    const handleSaveEditarDeuda = async () => {
        if(datosEditarDeuda.monto !== null && datosEditarDeuda.monto !== ''){
            if(datosEditarDeuda.fecha !== null && datosEditarDeuda.fecha !== ''){
                await setBotonEditarDeuda(true);

                let formData = new FormData();
                formData.append('id', datosEditarDeuda.id);
                formData.append('id_proveedor', datosEditarDeuda.id_proveedor);
                formData.append('monto', datosEditarDeuda.monto);
                formData.append('fecha', datosEditarDeuda.fecha);
                formData.append('concepto', datosEditarDeuda.concepto);
                formData.append('observaciones', datosEditarDeuda.observaciones);
                formData.append('archivo', datosEditarDeuda.archivo);

                await dispatch(editarDeudaProveedor(formData));

                await handleCloseEditarDeuda();

                await setBotonEditarDeuda(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseEditarDeuda = () => {
        setDatosEditarDeuda({
            ...datosEditarDeuda,
            id: null,
            monto: null,
            fecha: null,
            concepto: null, 
            observaciones: null,
            archivo: null, 
            url: null
        });

        setEditarDeudaModal(false);
    }

    //Eliminar deuda
    const handleSetEliminarDeuda = (deuda) => {
        setDatosEliminarDeuda({
            ...datosEliminarDeuda,
            id: deuda._id,
            monto: deuda.monto
        });

        handleOpenEliminarDeuda();
    }    

    const handleOpenEliminarDeuda = () => {
        setEliminarDeudaModal(true);
    }

    const handleSaveEliminarDeuda = async () => {
        await setBotonEliminarDeuda(true);
    
        await dispatch(eliminarDeudaProveedor(datosEliminarDeuda));

        await handleCloseEliminarDeuda();

        await setBotonEliminarDeuda(false);
    }

    const handleCloseEliminarDeuda = () => {
        setDatosEliminarDeuda({
            ...datosEliminarDeuda,
            id: null,
            monto: null
        });

        setEliminarDeudaModal(false);
    }

    //Nuevo pago
    const handleOpenNuevoPago = () => {
        if(dataTabla.deudasProveedor.length > 0){
            if(datosProveedor.balance > 0){
                setNuevoPagoModal(true);
            }else{
                dispatch(setAlert('The current balance is 0', 'danger'));
            }
        }else{
            dispatch(setAlert('First add a statement', 'danger'));
        }
    }

    const onNumberChangeNuevoPago = (name, value) => {
        setDatosNuevoPago({
            ...datosNuevoPago, [name]: value
        });
    }

    const onChangeNuevoPago = ({ target }) => {
        setDatosNuevoPago({
            ...datosNuevoPago,
            [target.name]: target.value
        });
    }

    const handleSaveNuevoPago = async () => {
        if(datosNuevoPago.monto !== null && datosNuevoPago.monto !== '' && datosNuevoPago.monto > 0){
            if(datosNuevoPago.fecha !== null && datosNuevoPago.fecha !== ''){
                await setBotonNuevoPago(true);

                await dispatch(agregarPagoProveedor(datosNuevoPago));

                await handleCloseNuevoPago();

                await setBotonNuevoPago(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseNuevoPago = () => {
        setDatosNuevoPago({
            ...datosNuevoPago,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setNuevoPagoModal(false);
    }

    //Editar pago
    const handleSetEditarPago = (id_pago) => {
        let pago = {
            id_proveedor: id, 
            id: id_pago
        };

        dispatch(detallesPagoProveedor(pago));
    }    

    const handleOpenEditarPago = () => {
        setEditarPagoModal(true);
    }

    const onNumberChangeEditarPago = (name, value) => {
        setDatosEditarPago({
            ...datosEditarPago, [name]: value
        });
    }

    const onChangeEditarPago = ({ target }) => {
        setDatosEditarPago({
            ...datosEditarPago,
            [target.name]: target.value
        });
    }

    const handleSaveEditarPago = async () => {
        if(datosEditarPago.monto !== null && datosEditarPago.monto !== '' && datosEditarPago.monto > 0){
            if(datosEditarPago.fecha !== null && datosEditarPago.fecha !== ''){
                await setBotonEditarPago(true);

                await dispatch(editarPagoProveedor(datosEditarPago));

                await handleCloseEditarPago();

                await setBotonEditarPago(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseEditarPago = () => {
        setDatosEditarPago({
            ...datosEditarPago,
            id: null,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setEditarPagoModal(false);
    }

    //Eliminar pago
    const handleSetEliminarPago = (pago) => {
        setDatosEliminarPago({
            ...datosEliminarPago,
            id: pago._id,
            monto: pago.monto, 
            pagos: dataTabla.pagosProveedor.length 
        });

        handleOpenEliminarPago();
    }    

    const handleOpenEliminarPago = () => {
        setEliminarPagoModal(true);
    }

    const handleSaveEliminarPago = async () => {
        await setBotonEliminarPago(true);
    
        await dispatch(eliminarPagoProveedor(datosEliminarPago));

        await handleCloseEliminarPago();

        await setBotonEliminarPago(false);
    }

    const handleCloseEliminarPago = () => {
        setDatosEliminarPago({
            ...datosEliminarPago,
            id: null,
            monto: null, 
            pagos: 0
        });

        setEliminarPagoModal(false);
    }

    //Funciones
    const getPreviousDate = (fecha, meses) => {
        if(fecha){
            let fecha_actual = new Date(fecha);

            fecha_actual.setMonth(fecha_actual.getMonth() - meses);

            return fecha_actual;
        }else{
            let fecha_actual = new Date();

            fecha_actual.setMonth(fecha_actual.getMonth() - meses);

            return fecha_actual;
        }
    }

    const handleViewFile = (archivo, url = null) => {
        if(archivo){
            const url_open = URL.createObjectURL(archivo);
            window.open(url_open);
        }else{
            if(url){
                window.open(url);
            }else{
                dispatch(setAlert('File not found', 'danger'));
            }
        }
    }

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const handleIconoArchivo = async (id_deuda) => {
        await dispatch(detallesFileDeudaProveedor(id, id_deuda));
    }

    const handleOpenFile = () => {
        window.open(detalles_archivo.url);
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    
    const columnsBills = [
        {
            name: 'Creator',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        },  {
            name: 'Description',
            selector: 'concepto',
            sortable: true,
            cell: item => `${ item.concepto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones }`,
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(item.archivo){
                    return (
                        <button className="btn btn-link text-primary" onClick={() => handleIconoArchivo(item._id)}>
                            <i className="fas fa-file-invoice-dollar"></i>
                        </button>
                    );
                }else{
                    return (
                        <></>
                    );
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-info" onClick={() => handleSetEditarDeuda(item._id)}>
                        <i className="fa fa-edit" />
                    </button>
                );
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-danger" onClick={() => handleSetEliminarDeuda(item)}>
                        <i className="fa fa-times" />
                    </button>
                );
            }
        }
    ];

    const columnsPayments = [
        {
            name: 'Creator',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones }`,
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-info" onClick={() => handleSetEditarPago(item._id)}>
                        <i className="fa fa-edit" />
                    </button>
                );
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-danger" onClick={() => handleSetEliminarPago(item)}>
                        <i className="fa fa-times" />
                    </button>
                );
            }
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(detallesProveedor(null));
            dispatch(detallesDeudaProveedor(null));
            dispatch(detallesPagoProveedor(null));
            dispatch(detallesFileDeudaProveedor(null, null));
        }
    }, []);

    useEffect(() => {
        dispatch(detallesProveedor(id));
    }, [id]);

    useEffect(() => {
        if(detalles_proveedor){
            setDataTabla({
                ...dataTabla,
                deudasProveedor: detalles_proveedor.deudas, 
                pagosProveedor: detalles_proveedor.pagos
            });

            setDatosNuevaDeuda({
                ...datosNuevaDeuda,
                id_proveedor: detalles_proveedor._id
            });

            setDatosEditarDeuda({
                ...datosEditarDeuda,
                id_proveedor: detalles_proveedor._id
            });

            setDatosEliminarDeuda({
                ...datosEliminarDeuda,
                id_proveedor: detalles_proveedor._id
            });

            setDatosNuevoPago({
                ...datosNuevoPago,
                id_proveedor: detalles_proveedor._id
            });

            setDatosEditarPago({
                ...datosEditarPago,
                id_proveedor: detalles_proveedor._id
            });

            setDatosEliminarPago({
                ...datosEliminarPago,
                id_proveedor: detalles_proveedor._id
            });

            let deuda_total = 0;
            let pago_total = 0;
            let balance = 0;
            let pagado = false;

            for (var x = 0; x < detalles_proveedor.deudas.length; x++) {
                deuda_total += parseFloat(detalles_proveedor.deudas[x].monto);
            }

            for (var x = 0; x < detalles_proveedor.pagos.length; x++) {
                pago_total += parseFloat(detalles_proveedor.pagos[x].monto);
            }

            balance = deuda_total - pago_total;
            pagado = balance <= 0 ? true : false;

            setDatosProveedor({
                ...datosProveedor,
                deuda_total, 
                pago_total, 
                balance, 
                pagado, 
                notas: detalles_proveedor.notas || null
            });
        }
    }, [detalles_proveedor, detalles_proveedor?.deudas, detalles_proveedor?.pagos]);

    useEffect(() => {
        if(detalles_deuda_proveedor){
            setDatosEditarDeuda({
                ...datosEditarDeuda,
                id: detalles_deuda_proveedor._id,
                monto: detalles_deuda_proveedor.monto, 
                fecha: detalles_deuda_proveedor.fecha, 
                concepto: detalles_deuda_proveedor.concepto,
                observaciones: detalles_deuda_proveedor.observaciones, 
                url: detalles_deuda_proveedor.url
            });

            handleOpenEditarDeuda();
        }
    }, [detalles_deuda_proveedor]);

    useEffect(() => {
        if(detalles_pago_proveedor){
            setDatosEditarPago({
                ...datosEditarPago,
                id: detalles_pago_proveedor._id,
                observaciones: detalles_pago_proveedor.observaciones,
                fecha: detalles_pago_proveedor.fecha,
                monto: detalles_pago_proveedor.monto
            });

            handleOpenEditarPago();
        }
    }, [detalles_pago_proveedor]);

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.url){
            handleOpenFile();
        }
    }, [detalles_archivo]);

    return (
        <Fragment>

            {/* Modal de nueva deuda */}
            <Modal show={nuevaDeudaModal} size="md" onHide={() => {
                handleCloseNuevaDeuda();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosNuevaDeuda.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevaDeuda(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    selected={ datosNuevaDeuda.fecha !== null ? new Date(datosNuevaDeuda.fecha) : '' }
                                    onChange={ date => setDatosNuevaDeuda({ ...datosNuevaDeuda, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea className="form-control" value={datosNuevaDeuda.concepto || ''} name="concepto" onChange={e => onChangeNuevaDeuda(e)}></textarea>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={nuevo_ref} onChange={onChangeFileNuevo} aria-describedby="viewfile_file"/>
                                    <label className="custom-file-label">{datosNuevaDeuda.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevaDeuda.archivo)}><i className="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosNuevaDeuda.observaciones || ''} name="observaciones" onChange={e => onChangeNuevaDeuda(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevaDeuda()} disabled={botonNuevaDeuda}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveNuevaDeuda} disabled={botonNuevaDeuda}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar deuda */}
            <Modal show={editarDeudaModal} size="md" onHide={() => {
                handleCloseEditarDeuda();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosEditarDeuda.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarDeuda(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    selected={ datosEditarDeuda.fecha !== null ? new Date(datosEditarDeuda.fecha) : '' }
                                    onChange={ date => setDatosEditarDeuda({ ...datosEditarDeuda, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea className="form-control" value={datosEditarDeuda.concepto || ''} name="concepto" onChange={e => onChangeEditarDeuda(e)}></textarea>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={editar_ref} onChange={onChangeFileEditar} aria-describedby="viewfile_file"/>
                                    <label className="custom-file-label">{datosEditarDeuda.archivo ? 'File Selected' : (datosEditarDeuda.url ? 'File Saved' : 'Choose file')}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosEditarDeuda.archivo, datosEditarDeuda.url)}><i className="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosEditarDeuda.observaciones || ''} name="observaciones" onChange={e => onChangeEditarDeuda(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEditarDeuda()} disabled={botonEditarDeuda}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveEditarDeuda} disabled={botonEditarDeuda}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminacion de deuda */}
            <Modal show={eliminarDeudaModal} onHide={() => {
                handleCloseEliminarDeuda();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the bill of <b>{numberToDecimal(datosEliminarDeuda?.monto) || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEliminarDeuda()} disabled={botonEliminarDeuda}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={handleSaveEliminarDeuda} disabled={botonEliminarDeuda}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo pago */}
            <Modal show={nuevoPagoModal} onHide={() => {
                handleCloseNuevoPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosNuevoPago.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoPago(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_proveedor?.fecha_pago, 1) } 
                                    selected={ datosNuevoPago.fecha !== null ? new Date(datosNuevoPago.fecha) : null }
                                    onChange={ date => setDatosNuevoPago({ ...datosNuevoPago, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosNuevoPago.observaciones || ''} name="observaciones" onChange={e => onChangeNuevoPago(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevoPago()} disabled={botonNuevoPago}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveNuevoPago} disabled={botonNuevoPago}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar pago */}
            <Modal show={editarPagoModal} onHide={() => {
                handleCloseEditarPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosEditarPago.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarPago(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_proveedor?.fecha_corte, 1) } 
                                    selected={ datosEditarPago.fecha !== null ? new Date(datosEditarPago.fecha) : null }
                                    onChange={ date => setDatosEditarPago({ ...datosEditarPago, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosEditarPago.observaciones || ''} name="observaciones" onChange={e => onChangeEditarPago(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEditarPago()} disabled={botonEditarPago}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveEditarPago} disabled={botonEditarPago}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminacion de pago */}
            <Modal show={eliminarPagoModal} onHide={() => {
                handleCloseEliminarPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the payment of <b>{numberToDecimal(datosEliminarPago?.monto) || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEliminarPago()} disabled={botonEliminarPago}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={handleSaveEliminarPago} disabled={botonEliminarPago}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-md-6 text-left">
                        <h3>Provider details</h3>
                    </div>
                    <div className="col-md-6 text-right">
                        <Link to="/bills/providers" className="btn btn-secondary text-right">Go back</Link>
                        <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevaDeuda()}><i className="fas fa-file-invoice-dollar"></i> Add a bill</button>
                        <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevoPago()}><i className="fas fa-dollar-sign fa-sm"></i> Add a payment</button>
                    </div>
                </div>

                <div className="col-md-12 mb-3 mt-4 row">
                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Name</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{detalles_proveedor?.nombre || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Contact</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{detalles_proveedor?.contacto_completo || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Address</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                    <p style={styles.infoText}>{detalles_proveedor?.direccion || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mb-3 mt-4 row">                    
                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Bank</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{detalles_proveedor?.banco?.nombre || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Number account</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{detalles_proveedor?.cuenta || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Total paid</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{numberToDecimal(datosProveedor.pago_total)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Total debt</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{numberToDecimal(datosProveedor.deuda_total)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className={"card " + (datosProveedor.balance <= 0 ? 'bg-success' : 'bg-danger')}>
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={datosProveedor.pagos_totales <= 0 ? null : styles.titulo_restaltado}>Balance</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={datosProveedor.pagos_totales <= 0 ? {...styles.infoText} : {...styles.infoText, ...styles.titulo_restaltado}}>{numberToDecimal(datosProveedor.balance)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    datosProveedor.notas ?
                        <div className="col-md-12 mb-3 mt-4 row">                    
                            <div className="col-md-12">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Notes</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <p style={styles.infoText}>{datosProveedor.notas}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                }

                <div className="table-responsive mt-5 pl-2">
                    <h6 className="my-2 ml-3">Bills</h6>
                    <DataTable
                        columns={columnsBills}
                        data={dataTabla.deudasProveedor || []}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>

                <div className="table-responsive mt-5 pl-2">
                    <h6 className="my-2 ml-3">Payments</h6>
                    <DataTable
                        columns={columnsPayments}
                        data={dataTabla.pagosProveedor || []}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default DetallesProveedor;