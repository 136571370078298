import { BUSCAR_NOMINAS, EDITAR_NOMINA, DETALLES_NOMINA, FILTROS_NOMINA, ELIMINAR_NOMINA, EXTERNA_NOMINA, HORAS_RESTANTES_NOMINA } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarNominas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/payments`, config);
        dispatch({
            type: BUSCAR_NOMINAS,
            payload: res.data.nominas
        })
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const crearNominaIndividual = (nomina) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(nomina);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/individual`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarNomina = (nomina) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(nomina);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/generar`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarNomina = (nomina) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(nomina);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/editar`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const basuraNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/basura`, gasto, config);
        dispatch({
            type: EDITAR_NOMINA,
            payload: res.data.nomina
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarBasuraNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/editar-basura`, gasto, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarBasuraNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(gasto);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/eliminar-basura`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const gasolinaNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/gasolina`, gasto, config);
        dispatch({
            type: EDITAR_NOMINA,
            payload: res.data.nomina
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarGasolinaNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/editar-gasolina`, gasto, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarGasolinaNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(gasto);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/eliminar-gasolina`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const otroGastoNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/otro`, gasto, config);
        dispatch({
            type: EDITAR_NOMINA,
            payload: res.data.nomina
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarOtroGastoNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/editar-otro`, gasto, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarOtroGastoNomina = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(gasto);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/eliminar-otro`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesNomina = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/detalles`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesCompletosNomina = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/detalles_completos`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.nomina
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const filtrosNomina = (fechas) => async dispatch => {
    dispatch({
        type: FILTROS_NOMINA,
        payload: fechas
    })
}

export const reportesNomina = (fechas) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(fechas);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/reporte`, body, config);
        dispatch({
            type: DETALLES_NOMINA,
            payload: res.data.datos
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generateReporte = (fechas) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(fechas);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/imprimir`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarNomina = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_NOMINA,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externaNomina = (valor) => async dispatch => {
    dispatch({
        type: EXTERNA_NOMINA,
        payload: valor
    });
}

export const horasEmpleado = (empleado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {

        if(empleado !== null && empleado !== 'null'){
            const body = JSON.stringify(empleado);

            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/payments/horas_restantes`, body, config);

            dispatch({
                type: HORAS_RESTANTES_NOMINA,
                payload: res.data.horas
            });
        }else{
            dispatch({
                type: HORAS_RESTANTES_NOMINA,
                payload: 0
            });
        }
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}