import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { editarWaitingForRepairs } from '../../../actions/flipping';
import { detallesProyecto, masterlistProyecto, seleccionarSeccionMasterList } from '../../../actions/proyectos';

const WaitingForRepairsFlipping = ({ statusClick }) => {

    const dispatch = useDispatch();

    const history = useHistory();

    const proyectos = useSelector(state => state.proyectos);

    const [proyectoData, setProyectoData] = useState({
        id: null
    });

    const handleNo = () => {
        history.push('/dashboard');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await dispatch(editarWaitingForRepairs(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        await dispatch(seleccionarSeccionMasterList('Rehab on progress'));
    };

    useEffect(() => {

        if(!proyectos.guardar_cambios){
            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id
            });
        }

    }, [proyectos]);

    return (
        <Fragment>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                <button className='btn btn-danger btn-sm' onClick={handleNo}>
                    No
                </button>

                {
                    proyectos.detalles?.proyecto?.identificador_status <= 5 ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleSubmit}>Save and Continue</button>
                    :   null
                }
            </div>

            <div className='proyectos-content-center'>
                <div className="col-12 text-center btn-group-actions mb-3">
                    <div className="btn btn-group" role="group">
                        <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                            <button className="dropdown-item" onClick={() => statusClick()}>
                                Status list
                            </button>
                            <button className="dropdown-item" onClick={handleSubmit}>
                                Save and Continue
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 text-center">
                    <div>
                        <h4>Do you want to continue with <b>Rehab on progress</b>?</h4>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default WaitingForRepairsFlipping;