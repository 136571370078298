import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { editarUsuario } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const Perfil = ({ history }) => {

    const { auth } = useSelector(state => state);
    const { permisos_profile } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    const [editingPassword, setEditingPassword] = useState(false);

    const [usuarioData, setUsuarioData] = useState({
        id: '',
        nombre: '',
        usuario: '',
        email: '',
        telefono: '',
        active: true
    });

    // Permisos
    const [superadmin, setSuperadmin] = useState(false);

    const [dashboard, setDashboard] = useState({
        all: false,
        view: false
    });

    const [reminders, setReminders] = useState({
        all: false,
        view: false,
        add: false,
        check: false
    });

    const [todolist, setTodolist] = useState({
        all: false,
        view: false,
        calendar: false,
        filters: false,
        new_task: false
    });

    const [payrolls, setPayrolls] = useState({
        all: false,
        view: false,
        report: false,
        new_single_payroll: false,
        new_multiple_payroll: false,
        bill: false,
        edit: false,
        detail: false,
        delete: false
    });

    const [expenses, setExpenses] = useState({
        all: false,
        view: false,
        report: false,
        new: false,
        edit: false,
        detail: false,
        delete: false
    });

    const [accounting, setAccounting] = useState({
        all: false,
        view: false,
        credit_cards: false,
        new_credit_card: false, 
        edit_credit_card: false, 
        detail_credit_card: false, 
        delete_credit_card: false
    });

    const [configuration, setConfiguration] = useState({
        all: false,
        view_users: false,
        add_user: false,
        edit_user: false,
        delete_user: false,
        disable_user: false,
        view_employees: false,
        add_employee: false,
        edit_employee: false,
        delete_employee: false,
        view_due_dilligence_checklist: false,
        view_deal_analyzer: false,
        view_sellers: false,
        add_seller: false,
        edit_seller: false,
        delete_seller: false,
        view_buyers: false,
        add_buyer: false,
        edit_buyer: false,
        delete_buyer: false,
        view_vestings: false,
        add_vesting: false,
        edit_vesting: false,
        delete_vesting: false,
        view_brokers: false,
        add_broker: false,
        edit_broker: false,
        delete_broker: false,
        agents_broker: false,
        add_agent_broker: false,
        edit_agent_broker: false,
        delete_agent_broker: false,
        view_escrows: false,
        add_escrow: false,
        edit_escrow: false,
        delete_escrow: false,
        agents_escrow: false,
        add_agent_escrow: false,
        edit_agent_escrow: false,
        delete_agent_escrow: false,
        view_titles: false,
        add_title: false,
        edit_title: false,
        delete_title: false,
        agents_title: false,
        add_agent_title: false,
        edit_agent_title: false,
        delete_agent_title: false,
        view_termites: false,
        add_termite: false,
        edit_termite: false,
        delete_termite: false,
        agents_termite: false,
        add_agent_termite: false,
        edit_agent_termite: false,
        delete_agent_termite: false,
        view_septics: false,
        add_septic: false,
        edit_septic: false,
        delete_septic: false,
        agents_septic: false,
        add_agent_septic: false,
        edit_agent_septic: false,
        delete_agent_septic: false,
        view_categories_inspection: false,
        add_categorie_inspection: false,
        edit_categorie_inspection: false,
        delete_categorie_inspection: false,
        view_sections_todolist: false,
        add_section_todolist: false,
        edit_section_todolist: false,
        delete_section_todolist: false
    });

    const [profile, setProfile] = useState({
        all: false,
        view: false,
        edit: false
    });

    /* Datos de usuario */
    const onChange = (e) => {
        setUsuarioData({
            ...usuarioData, [e.target.name]: e.target.value
        });
    }

    /* Permisos */
    const changeCategory = (e) => {

        if(e.target.dataset.type === 'superadmin'){

            setSuperadmin(e.target.checked);

            let temp_dashboard = Object.fromEntries(Array.from(document.getElementsByClassName('dashboard')).map(element => [element.dataset.field, e.target.checked]));
            let temp_reminders = Object.fromEntries(Array.from(document.getElementsByClassName('reminders')).map(element => [element.dataset.field, e.target.checked]));
            let temp_todolist = Object.fromEntries(Array.from(document.getElementsByClassName('todolist')).map(element => [element.dataset.field, e.target.checked]));
            let temp_payrolls = Object.fromEntries(Array.from(document.getElementsByClassName('payrolls')).map(element => [element.dataset.field, e.target.checked]));
            let temp_expenses = Object.fromEntries(Array.from(document.getElementsByClassName('expenses')).map(element => [element.dataset.field, e.target.checked]));
            let temp_accounting = Object.fromEntries(Array.from(document.getElementsByClassName('accounting')).map(element => [element.dataset.field, e.target.checked]));
            let temp_configuration = Object.fromEntries(Array.from(document.getElementsByClassName('configuration')).map(element => [element.dataset.field, e.target.checked]));
            let temp_profile = Object.fromEntries(Array.from(document.getElementsByClassName('profile')).map(element => [element.dataset.field, e.target.checked]));

            setDashboard({
                all: e.target.checked,
                ...temp_dashboard
            });

            setReminders({
                all: e.target.checked,
                ...temp_reminders
            });

            setTodolist({
                all: e.target.checked,
                ...temp_todolist
            });

            setPayrolls({
                all: e.target.checked,
                ...temp_payrolls
            });

            setExpenses({
                all: e.target.checked,
                ...temp_expenses
            });

            setAccounting({
                all: e.target.checked,
                ...temp_accounting
            });

            setConfiguration({
                all: e.target.checked,
                ...temp_configuration
            });

            setProfile({
                all: e.target.checked,
                ...temp_profile
            });
        }else{

            setSuperadmin(e.target.checked === false ? false : superadmin);

            switch(e.target.id){
                case 'dashboard':
                    let temp_dashboard = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setDashboard({
                        all: e.target.checked,
                        ...temp_dashboard
                    });
                    break;

                case 'reminders':
                    let temp_reminders = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setReminders({
                        all: e.target.checked,
                        ...temp_reminders
                    });
                    break;

                case 'todolist':
                    let temp_todolist = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setTodolist({
                        all: e.target.checked,
                        ...temp_todolist
                    });
                    break;

                case 'payrolls':
                    let temp_payrolls = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setPayrolls({
                        all: e.target.checked,
                        ...temp_payrolls
                    });
                    break;

                case 'expenses':
                    let temp_expenses = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setExpenses({
                        all: e.target.checked,
                        ...temp_expenses
                    });
                    break;

                case 'accounting':
                    let temp_accounting = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setAccounting({
                        all: e.target.checked,
                        ...temp_accounting
                    });
                    break;

                case 'configuration':
                    let temp_configuration = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setConfiguration({
                        all: e.target.checked,
                        ...temp_configuration
                    });
                    break;

                case 'profile':
                    let temp_profile = Object.fromEntries(Array.from(document.getElementsByClassName(e.target.id)).map(element => [element.dataset.field, e.target.checked]));

                    setProfile({
                        all: e.target.checked,
                        ...temp_profile
                    });
                    break;

                default:
                    break;
            }
        }
    }

    const onChangeCheckbox = (e) => {
        if(!e.target.checked){
            setSuperadmin(false);
        }

        switch(e.target.dataset.type){
            case 'dashboard':
                setDashboard({
                    ...dashboard,
                    all: !e.target.checked ? false : dashboard.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'reminders':
                setReminders({
                    ...reminders,
                    all: !e.target.checked ? false : reminders.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'todolist':
                setTodolist({
                    ...todolist,
                    all: !e.target.checked ? false : todolist.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'payrolls':
                setPayrolls({
                    ...payrolls,
                    all: !e.target.checked ? false : payrolls.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'expenses':
                setExpenses({
                    ...expenses,
                    all: !e.target.checked ? false : expenses.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'accounting':
                setAccounting({
                    ...accounting,
                    all: !e.target.checked ? false : accounting.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'configuration':
                setConfiguration({
                    ...configuration,
                    all: !e.target.checked ? false : configuration.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            case 'profile':
                setProfile({
                    ...profile,
                    all: !e.target.checked ? false : profile.all,
                    [e.target.dataset.field]: e.target.checked
                });
                break;

            default:
                break;
        }
    }

    /* Guardar */
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Comprobamos las contraseñas
        if (editingPassword) {
            let contrasena = document.getElementById('contrasena');
            let contrasena2 = document.getElementById('contrasena2');
            if (contrasena.value !== contrasena2.value || !contrasena.value || !contrasena2.value) {
                dispatch(setAlert('Passwords do not match', 'danger'));
                return;
            } else {
                await setUsuarioData({
                    ...usuarioData,
                    password: contrasena
                });

                await dispatch(editarUsuario({
                    ...usuarioData,
                    dashboard,
                    reminders,
                    todolist,
                    payrolls,
                    expenses,
                    accounting,
                    configuration,
                    profile
                }));

                await history.push('/users');
            }
        }

        await dispatch(editarUsuario({
            ...usuarioData,
            dashboard,
            reminders,
            todolist,
            payrolls,
            expenses,
            accounting,
            configuration,
            profile
        }));

        await history.push('/users');
    }

    useEffect(() => {
        if (!dashboard.all) {
            let check = Object.values(dashboard);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setDashboard({
                    ...dashboard,
                    all: true,
                })
            }
        }
    }, [dashboard]);

    useEffect(() => {
        if (!reminders.all) {
            let check = Object.values(reminders);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setReminders({
                    ...reminders,
                    all: true,
                })
            }
        }
    }, [reminders]);

    useEffect(() => {
        if (!todolist.all) {
            let check = Object.values(todolist);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setTodolist({
                    ...todolist,
                    all: true,
                })
            }
        }
    }, [todolist]);

    useEffect(() => {
        if (!payrolls.all) {
            let check = Object.values(payrolls);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setPayrolls({
                    ...payrolls,
                    all: true,
                })
            }
        }
    }, [payrolls]);

    useEffect(() => {
        if (!expenses.all) {
            let check = Object.values(expenses);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setExpenses({
                    ...expenses,
                    all: true,
                })
            }
        }
    }, [expenses]);

    useEffect(() => {
        if (!accounting.all) {
            let check = Object.values(accounting);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setAccounting({
                    ...accounting,
                    all: true,
                })
            }
        }
    }, [accounting]);

    useEffect(() => {
        if (!configuration.all) {
            let check = Object.values(configuration);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setConfiguration({
                    ...configuration,
                    all: true,
                })
            }
        }
    }, [configuration]);

    useEffect(() => {
        if (!profile.all) {
            let check = Object.values(profile);
            check.shift();
            if (check.every(element => element) && check.length > 0) {
                setProfile({
                    ...profile,
                    all: true,
                })
            }
        }
    }, [profile]);

    useEffect(() => {

        if (!superadmin) {
            let check_dashboard = Object.values(dashboard);
            let check_reminders = Object.values(reminders);
            let check_todolist = Object.values(todolist);
            let check_payrolls = Object.values(payrolls);
            let check_expenses = Object.values(expenses);
            let check_accounting = Object.values(accounting);
            let check_configuration = Object.values(configuration);
            let check_profile = Object.values(profile);

            if (check_dashboard.every(element => element) && check_reminders.every(element => element) && check_todolist.every(element => element) && check_payrolls.every(element => element) && check_expenses.every(element => element) && check_accounting.every(element => element) && check_configuration.every(element => element) && check_profile.every(element => element)) {
                setSuperadmin(true);
            }
        }
    }, [dashboard, reminders, todolist, payrolls, expenses, accounting, configuration, profile]);

    useEffect(() => {
        setUsuarioData({
            ...usuarioData,
            id: auth.user._id,
            nombre: auth.user.nombre,
            usuario: auth.user.usuario,
            telefono: auth.user.telefono,
            email: auth.user.email,
            active: true,
        });

        let check_dashboard = auth.user?.permisos_dashboard ? Object.values(auth.user?.permisos_dashboard) : [false];
        let check_reminders = auth.user?.permisos_reminders ? Object.values(auth.user?.permisos_reminders) : [false];
        let check_todolist = auth.user?.permisos_todolist ? Object.values(auth.user?.permisos_todolist) : [false];
        let check_payrolls = auth.user?.permisos_payrolls ? Object.values(auth.user?.permisos_payrolls) : [false];
        let check_expenses = auth.user?.permisos_expenses ? Object.values(auth.user?.permisos_expenses) : [false];
        let check_accounting = auth.user?.permisos_accounting ? Object.values(auth.user?.permisos_accounting) : [false];
        let check_configuration = auth.user?.permisos_configuration ? Object.values(auth.user?.permisos_configuration) : [false];
        let check_profile = auth.user?.permisos_profile ? Object.values(auth.user?.permisos_profile) : [false];

        setDashboard({
            all: check_dashboard.every(element => element),
            ...auth.user?.permisos_dashboard
        });

        setReminders({
            all: check_reminders.every(element => element),
            ...auth.user?.permisos_reminders
        });

        setTodolist({
            all: check_todolist.every(element => element),
            ...auth.user?.permisos_todolist
        });

        setPayrolls({
            all: check_payrolls.every(element => element),
            ...auth.user?.permisos_payrolls
        });

        setExpenses({
            all: check_expenses.every(element => element),
            ...auth.user?.permisos_expenses
        });

        setAccounting({
            all: check_accounting.every(element => element),
            ...auth.user?.permisos_accounting
        });

        setConfiguration({
            all: check_configuration.every(element => element),
            ...auth.user?.permisos_configuration
        });

        setProfile({
            all: check_profile.every(element => element),
            ...auth.user?.permisos_profile
        });
    }, [auth.user]);

    return (
        <div className="row">
            {
                auth.user ?
                    <form className="col-md-8 offset-md-2">
                        <h3 className="mb-3 mt-5">Edit Profile</h3>

                        <div className="row mb-4 text-right">
                                {
                                    !permisos_profile?.edit ?
                                    null
                                    :
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-success float-right mt-3 ml-3" onClick={handleSubmit}><i className="fas fa-check fa-sm"></i> Save</button>
                                        <button type="button" className="btn btn-danger float-right mt-3" onClick={() => { history.push('/users') }}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    </div>
                                }
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-3 form-group">
                                <label className="font-weight-bold">Name:</label>
                                <input type="text" className="form-control" name="nombre" onChange={e => onChange(e)} value={usuarioData.nombre || ''}></input>
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="font-weight-bold">User:</label>
                                <input type="text" className="form-control" name="usuario" onChange={e => onChange(e)} value={usuarioData.usuario || ''}></input>
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="font-weight-bold">Email:</label>
                                <input type="email" className="form-control" name="email" onChange={e => onChange(e)} value={usuarioData.email || ''}></input>
                            </div>
                            <div className="col-md-3 form-group">
                                <label className="font-weight-bold">Phone:</label>
                                <input type="number" className="form-control" name="telefono" onChange={e => onChange(e)} value={usuarioData.telefono || ''}></input>
                            </div>
                        </div>

                        <div className={editingPassword ? 'form-group d-none' : 'form-group'}>
                            <button className="btn btn-link text-primary" onClick={(e) => { e.preventDefault(); setEditingPassword(true) }}>Change password</button>
                        </div>
                        <div className={editingPassword ? 'form-group' : 'form-group d-none'}>
                            <label className="font-weight-bold">Password:</label>
                            <input autoComplete="new-password" type="password" placeholder="********" id="contrasena" className="form-control" />
                        </div>
                        <div className={editingPassword ? 'form-group' : 'form-group d-none'}>
                            <label className="font-weight-bold">Confirm password:</label>
                            <input autoComplete="new-password" id="contrasena2" type="password" placeholder="********" className="form-control" name="password" onChange={e => onChange(e)} />
                        </div>

                        <h5>Permissions</h5>
                        <div className="row mt-4">

                            {/* Superadmin */}
                            <div className="col-md-12">
                                <div className="card p-4 bg-light">
                                    <div className="form-check d-flex align-items-center">
                                        <input data-type="superadmin" className="form-check-input" type="checkbox" checked={superadmin} id="superadmin" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <div className="pl-3">
                                            <label className="form-check-label font-weight-bold" htmlFor="superadmin">
                                                Superadmin
                                            </label>
                                            <p className="text-muted m-0">Superadmin has all the permissions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Dashboard */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="dashboard" className="form-check-input superadmin" type="checkbox" checked={dashboard.all} id="dashboard" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="dashboard">
                                            Dashboard
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input dashboard superadmin" type="checkbox" checked={dashboard.view} id="dashboard_view" data-field="view" data-type="dashboard" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="dashboard_view">
                                            View section
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Reminders */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="reminders" className="form-check-input superadmin" type="checkbox" checked={reminders.all} id="reminders" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="reminders">
                                            Reminders
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input reminders superadmin" type="checkbox" checked={reminders.view} id="reminders_view" data-field="view" data-type="reminders" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="reminders_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input reminders superadmin" type="checkbox" checked={reminders.add} id="reminders_add" data-field="add" data-type="reminders" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="reminders_add">
                                            Add
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input reminders superadmin" type="checkbox" checked={reminders.check} id="reminders_check" data-field="check" data-type="reminders" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="reminders_check">
                                            Check
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* To do list */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="todolist" className="form-check-input superadmin" type="checkbox" checked={todolist.all} id="todolist" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="todolist">
                                            To do list
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input todolist superadmin" type="checkbox" checked={todolist.view} id="todolist_view" data-field="view" data-type="todolist" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="todolist_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input todolist superadmin" type="checkbox" checked={todolist.calendar} id="todolist_calendar" data-field="calendar" data-type="todolist" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="todolist_calendar">
                                            Calendar
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input todolist superadmin" type="checkbox" checked={todolist.filters} id="todolist_filters" data-field="filters" data-type="todolist" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="todolist_filters">
                                            Filters
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input todolist superadmin" type="checkbox" checked={todolist.new_task} id="todolist_new_task" data-field="new_task" data-type="todolist" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="todolist_new_task">
                                            New task
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Payrolls */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="payrolls" className="form-check-input superadmin" type="checkbox" checked={payrolls.all} id="payrolls" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="payrolls">
                                            Payrolls
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.view} id="payrolls_view" data-field="view" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.report} id="payrolls_report" data-field="report" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_report">
                                            Report
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.new_single_payroll} id="payrolls_new_single_payroll" data-field="new_single_payroll" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_new_single_payroll">
                                            New single payroll
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.new_multiple_payroll} id="payrolls_new_multiple_payroll" data-field="new_multiple_payroll" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_new_multiple_payroll">
                                            New multiple payroll
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.bill} id="payrolls_bill" data-field="bill" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_bill">
                                            Bills
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.edit} id="payrolls_edit" data-field="edit" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_edit">
                                            Edit
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.detail} id="payrolls_detail" data-field="detail" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_detail">
                                            Detail
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input payrolls superadmin" type="checkbox" checked={payrolls.delete} id="payrolls_delete" data-field="delete" data-type="payrolls" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="payrolls_delete">
                                            Delete
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Expenses */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="expenses" className="form-check-input superadmin" type="checkbox" checked={expenses.all} id="expenses" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="expenses">
                                            Expenses
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.view} id="expenses_view" data-field="view" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.report} id="expenses_report" data-field="report" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_report">
                                            Report
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.new} id="expenses_new" data-field="new" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_new">
                                            Add
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.edit} id="expenses_edit" data-field="edit" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_edit">
                                            Edit
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.detail} id="expenses_detail" data-field="detail" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_detail">
                                            Detail
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input expenses superadmin" type="checkbox" checked={expenses.delete} id="expenses_delete" data-field="delete" data-type="expenses" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="expenses_delete">
                                            Delete
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Accounting */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="accounting" className="form-check-input superadmin" type="checkbox" checked={accounting.all} id="accounting" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="accounting">
                                            Accounting
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.view} id="accounting_view" data-field="view" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.credit_cards} id="accounting_credit_cards" data-field="credit_cards" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_credit_cards">
                                            Credit cards
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.new_credit_card} id="accounting_new_credit_card" data-field="new_credit_card" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_new_credit_card">
                                            New credit card
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.edit_credit_card} id="accounting_edit_credit_card" data-field="edit_credit_card" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_edit_credit_card">
                                            Edit credit card
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.detail_credit_card} id="accounting_detail_credit_card" data-field="detail_credit_card" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_detail_credit_card">
                                            Detail credit card
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input accounting superadmin" type="checkbox" checked={accounting.delete_credit_card} id="accounting_delete_credit_card" data-field="delete_credit_card" data-type="accounting" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="accounting_delete_credit_card">
                                            Delete credit card
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Profile */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className="form-check">
                                        <input data-type="profile" className="form-check-input superadmin" type="checkbox" checked={profile.all} id="profile" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <label className="form-check-label" htmlFor="profile">
                                            Profile
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="form-check ml-3">
                                        <input className="form-check-input profile superadmin" type="checkbox" checked={profile.view} id="profile_view" data-field="view" data-type="profile" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="profile_view">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input profile superadmin" type="checkbox" checked={profile.edit} id="profile_edit" data-field="edit" data-type="profile" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="profile_edit">
                                            Edit
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* Settings */}
                                {/* Users */}
                            <div className="col-md-12 pt-4">
                                <div className="card p-4 bg-light">
                                    <div className="form-check d-flex align-items-center">
                                        <input data-type="configuration" className="form-check-input" type="checkbox" checked={configuration.all} id="configuration" onChange={(e) => {
                                            changeCategory(e)
                                        }}></input>
                                        <div className="pl-3">
                                            <label className="form-check-label font-weight-bold" htmlFor="configuration">
                                                Configuration
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Users</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_users} id="configuration_view_users" data-field="view_users" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_users">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_user} id="add_user" data-field="add_user" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_user">
                                            Add users
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_user} id="edit_user" data-field="edit_user" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_user">
                                            Edit users
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_user} id="delete_user" data-field="delete_user" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_user">
                                            Delete users
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.disable_user} id="disable_user" data-field="disable_user" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_disable_user">
                                            Disable users
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Employees */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Employees</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_employees} id="configuration_view_employees" data-field="view_employees" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_employees">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_employee} id="add_employee" data-field="add_employee" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_employee">
                                            Add employees
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_employee} id="edit_employee" data-field="edit_employee" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_employee">
                                            Edit employees
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_employee} id="delete_employee" data-field="delete_employee" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_employee">
                                            Delete employees
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Sellers */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Sellers</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_sellers} id="configuration_view_sellers" data-field="view_sellers" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_sellers">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_seller} id="add_seller" data-field="add_seller" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_seller">
                                            Add sellers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_seller} id="edit_seller" data-field="edit_seller" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_seller">
                                            Edit sellers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_seller} id="delete_seller" data-field="delete_seller" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_seller">
                                            Delete sellers
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Buyers */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Buyers</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_buyers} id="configuration_view_buyers" data-field="view_buyers" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_buyers">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_buyer} id="add_buyer" data-field="add_buyer" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_buyer">
                                            Add buyers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_buyer} id="edit_buyer" data-field="edit_buyer" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_buyer">
                                            Edit buyers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_buyer} id="delete_buyer" data-field="delete_buyer" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_buyer">
                                            Delete buyers
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Vestings */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Vestings</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_vestings} id="configuration_view_vestings" data-field="view_vestings" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_vestings">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_vesting} id="add_vesting" data-field="add_vesting" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_vesting">
                                            Add vestings
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_vesting} id="edit_vesting" data-field="edit_vesting" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_buyer">
                                            Edit vestings
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_vesting} id="delete_vesting" data-field="delete_vesting" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_vesting">
                                            Delete vestings
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Brokers */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Brokers</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_brokers} id="configuration_view_brokers" data-field="view_brokers" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_brokers">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_broker} id="add_broker" data-field="add_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_broker">
                                            Add brokers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_broker} id="edit_broker" data-field="edit_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_broker">
                                            Edit brokers
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_broker} id="delete_broker" data-field="delete_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_broker">
                                            Delete brokers
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Agent Brokers */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Agents Broker</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.agents_broker} id="configuration_agents_broker" data-field="agents_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_agents_broker">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_agent_broker} id="add_agent_broker" data-field="add_agent_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_agent_broker">
                                            Add agents broker
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_agent_broker} id="edit_agent_broker" data-field="edit_agent_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_agent_broker">
                                            Edit agents broker
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_agent_broker} id="delete_agent_broker" data-field="delete_agent_broker" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_agent_broker">
                                            Delete agents broker
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Escrows */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Escrows</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_escrows} id="configuration_view_escrows" data-field="view_escrows" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_escrow">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_escrow} id="add_escrow" data-field="add_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_escrow">
                                            Add escrows
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_escrow} id="edit_escrow" data-field="edit_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_escrow">
                                            Edit escrows
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_escrow} id="delete_escrow" data-field="delete_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_escrow">
                                            Delete escrows
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Agents Escrow */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Agents Escrow</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.agents_escrow} id="configuration_agents_escrow" data-field="agents_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_agents_escrow">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_agent_escrow} id="add_agent_escrow" data-field="add_agent_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_agent_escrow">
                                            Add agents escrow
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_agent_escrow} id="edit_agent_escrow" data-field="edit_agent_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_agent_escrow">
                                            Edit agents escrow
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_agent_escrow} id="delete_agent_escrow" data-field="delete_agent_escrow" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_agent_escrow">
                                            Delete agents escrow
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Titles */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Titles</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_titles} id="configuration_view_titles" data-field="view_titles" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_titles">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_title} id="add_title" data-field="add_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_title">
                                            Add titles
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_title} id="edit_title" data-field="edit_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_title">
                                            Edit titles
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_title} id="delete_title" data-field="delete_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_title">
                                            Delete titles
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Agents Title */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Agents Title</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.agents_title} id="configuration_agents_title" data-field="agents_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_agents_title">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_agent_title} id="add_agent_title" data-field="add_agent_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_agent_title">
                                            Add agents title
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_agent_title} id="edit_agent_title" data-field="edit_agent_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_agent_title">
                                            Edit agents title
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_agent_title} id="delete_agent_title" data-field="delete_agent_title" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_agent_title">
                                            Delete agents title
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Termites */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Termites</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_termites} id="configuration_view_termites" data-field="view_termites" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_termites">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_termite} id="add_termite" data-field="add_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_termite">
                                            Add termite
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_termite} id="edit_termite" data-field="edit_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_termite">
                                            Edit termite
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_termite} id="delete_termite" data-field="delete_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_termite">
                                            Delete termite
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Agents Termite */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Agents Termite</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.agents_termite} id="configuration_agents_termite" data-field="agents_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_agents_termite">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_agent_termite} id="add_agent_termite" data-field="add_agent_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_agent_termite">
                                            Add agents termite
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_agent_termite} id="edit_agent_termite" data-field="edit_agent_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_agent_termite">
                                            Edit agents termite
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_agent_termite} id="delete_agent_termite" data-field="delete_agent_termite" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_agent_termite">
                                            Delete agents termite
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Septics */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Septics</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_septics} id="configuration_view_septics" data-field="view_septics" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_septics">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_septic} id="add_septic" data-field="add_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_septic">
                                            Add sepctics
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_septic} id="edit_septic" data-field="edit_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_septic">
                                            Edit sepctics
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_septic} id="delete_septic" data-field="delete_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_septic">
                                            Delete sepctics
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Agents Sepctic */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Agents Septic</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.agents_septic} id="configuration_agents_septic" data-field="agents_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_agents_septic">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_agent_septic} id="add_agent_septic" data-field="add_agent_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_agent_septic">
                                            Add agents sepctic
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_agent_septic} id="edit_agent_septic" data-field="edit_agent_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_agent_septic">
                                            Edit agents sepctic
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_agent_septic} id="delete_agent_septic" data-field="delete_agent_septic" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_agent_septic">
                                            Delete agents sepctic
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* Inspections */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>Inspections</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_categories_inspection} id="configuration_view_categories_inspection" data-field="view_categories_inspection" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_categories_inspections">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_categorie_inspection} id="add_categorie_inspection" data-field="add_categorie_inspection" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_categorie_inspection">
                                            Add inspections
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_categorie_inspection} id="edit_categorie_inspection" data-field="edit_categorie_inspection" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_categorie_inspection">
                                            Edit inspections
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_categorie_inspection} id="delete_categorie_inspection" data-field="delete_categorie_inspection" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_categorie_inspection">
                                            Delete inspections
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* To do list */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>To Do List</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_sections_todolist} id="configuration_view_sections_todolist" data-field="view_sections_todolist" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_view_sections_todolist">
                                            View section
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.add_section_todolist} id="add_section_todolist" data-field="add_section_todolist" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_add_section_todolist">
                                            Add to do list
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.edit_section_todolist} id="edit_section_todolist" data-field="edit_section_todolist" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_edit_section_todolist">
                                            Edit to do list
                                        </label>
                                    </div>
                                    <div className="form-check ml-3">
                                        <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.delete_section_todolist} id="delete_section_todolist" data-field="delete_section_todolist" data-type="configuration" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration_delete_section_todolist">
                                            Delete to do list
                                        </label>
                                    </div>
                                </div>
                            </div>
                                {/* View Due Dilligence Checklist */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>View Due Dilligence Checklist</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                    <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_due_dilligence_checklist} id="view_due_dilligence_checklist" data-field="view_due_dilligence_checklist" data-type="configuration" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="configuration_view_due_dilligence_checklist">
                                        View due dilligence checklist
                                    </label>
                                    </div>
                                </div>
                            </div>
                                {/* View Deal Analyzer */}
                            <div className="col-md-4 mt-4">
                                <div className="card px-4 py-4">
                                    <div className='ml-3'>
                                        <label>View Deal Analyzer</label>
                                        <hr/>
                                    </div>
                                    <div className="form-check ml-3">
                                    <input className="form-check-input configuration superadmin" type="checkbox" checked={configuration.view_deal_analyzer} id="view_deal_analyzer" data-field="view_deal_analyzer" data-type="configuration" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="configuration_view_deal_analyzer">
                                        View Deal Analyzer
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{marginTop: '10%'}}> 
                        <h3>No user was selected to edit, please go back to users selection.</h3>
                        <button className="btn btn-primary" onClick={ () => history.push('/users')}><i className="fas fa-users fa-sm"></i> Users</button>
                    </div>
            }
        </div>
    );
}

export default Perfil;