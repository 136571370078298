import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Select from 'react-select';

import { buscarRecordatorios, statusRecordatorio, externaRecordatorio } from '../../actions/recordatorios';
import { listarProyectos, seleccionarProyecto } from '../../actions/proyectos';

const Recordatorios = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const recordatorios = useSelector(state => state.recordatorios);
    const { listado } = useSelector(state => state.proyectos);
    const { permisos_todolist, permisos_reminders } = useSelector(state => state.auth.user);

    const [proyectoData, setProyectoData] = useState('');
    const [proyectosFilter, setProyectosFilter] = useState([]);

    /* Reminders */
    const handleSelectProyecto = (option) => {
        setProyectoData(option);
    }

    const handleCheckReminder = (item) => {
        dispatch(statusRecordatorio(item._id));
    }

    const tableRow = (items) => {
        return (
            <tbody>
                {items.map(item => {
                    let rutaProyecto = null;

                    if(item.tipo === 0 || item.tipo === '0' || item.tipo === 1 || item.tipo === '1' || item.tipo === 3 || item.tipo === '3'){
                        rutaProyecto = item.ruta; 
                    }

                    return(
                        <Fragment key={item._id}>
                            <tr>
                                <td>{item.mensaje}</td>
                                <td>
                                    {
                                        item.reminder !== '' && permisos_reminders.check ? 
                                            <button type="button" className="btn btn-link text-success" onClick={() => handleCheckReminder(item)}>Check</button>
                                        :
                                            <></>
                                    }
                                </td>
                                <td>
                                    {
                                        rutaProyecto !== null && permisos_reminders.view ?
                                            <Link className="btn btn-link text-primary" to={`${rutaProyecto}`}>View more</Link>
                                        :
                                            <></>
                                    }
                                </td>
                            </tr>
                        </Fragment>
                    )
                })}
            </tbody>
        );
    }

    /* Otros */
    const handleNewReminder = () => {
        dispatch(externaRecordatorio(true));
    }

    const handleAgenda = () => {
        history.push('/agenda/' + (id || ''));
    }

    const handleTodolist = () => {
        history.push('/todolist/listado/' + (id || ''));
    }

    const handleGoBack = () => {
        if(id){
            history.push('/projects/buySale/' + (id || ''));
        }else{
            history.goBack();
        }
    }

    useEffect(() => {
        dispatch(listarProyectos());

        return () => {
            dispatch(buscarRecordatorios(''));
        }
    }, []);

    useEffect(() => {
        if(id){
            if(proyectosFilter.length > 0){
                let proyecto_elegido = proyectosFilter?.find(proyecto => String(proyecto.value) === String(id));
                
                setProyectoData(proyecto_elegido);
            }
            dispatch(seleccionarProyecto(id));
        }else{
            setProyectoData(null);
            dispatch(seleccionarProyecto(id));
        }
    }, [id, proyectosFilter]);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < listado.length; i++) {
            options.push({ value: listado[i]._id, label: listado[i].direccion });
        }

        setProyectosFilter(options);

    }, [listado]);

    useEffect(() => {
        if(proyectoData != ''){
            if(proyectoData){
                dispatch(buscarRecordatorios(proyectoData.value));
            }else{
                dispatch(buscarRecordatorios(null));
            }
        }
    }, [proyectoData]);

    return (
        <Fragment>
            <div className="row mx-0">

                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-8 text-left">
                        <h3>Reminders</h3>
                    </div>

                    <div className="col-4 form-group text-right px-0">
                        {
                            permisos_reminders?.view ?
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleAgenda()}><i className="fas fa-calendar-day"></i> View calendar</button>   
                            :
                                null
                        }
                        {
                            !permisos_todolist?.view ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleTodolist()}><i className="fas fa-check-square fa-sm"></i> View to do list</button>
                        }
                        {
                            permisos_reminders?.add ?
                                <button type="button" className="btn btn-success mt-2 mr-2" onClick={() => handleNewReminder()}><i className="fas fa-plus fa-sm"></i> New Reminder</button>   
                            :
                                null
                        }
                        <button type="button" className="btn btn-secondary mt-2" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                    </div>

                    <hr/>
                </div>

                <div className="col-md-12 form-group">
                    <label className="font-weight-bold">Project:</label>
                    <Select name="proyectos" isClearable={true} options={proyectosFilter} value={proyectoData} onChange={(value) => handleSelectProyecto(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                </div>

                {
                    recordatorios.recordatorios?.length > 0 ?
                        <div className="col-md-12 mt-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Message</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {
                                    tableRow(recordatorios.recordatorios !== undefined ? recordatorios.recordatorios : [])
                                }
                            </table>
                        </div>
                    :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>There is no reminder to attend.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('dashboard')}>
                                Dashboard
                            </button>
                        </div>
                }
            </div>
        </Fragment>
    );
}

export default Recordatorios;