import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import DatePicker from 'react-datepicker';
import ReactChipInput from 'react-chip-input';

import { setAlert } from '../../actions/alert';
import { detallesProyecto, notesProyecto, agregarNoteProyecto, editarNoteProyecto, detallesNoteProyecto, eliminarNoteProyecto } from '../../actions/proyectos';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

const styles = {
    contenedor_audio: {
        marginBottom: '10px'
    }
}

const Notes = ({ history }) => {

    const { id } = useParams();
    const { goBack } = useHistory();

    const dispatch = useDispatch();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);

    const [statusShareNotes, setStatusShareNote] = useState(true);

    const [newNoteModal, setNewNoteModal] = useState(false);
    const [editNoteModal, setEditNoteModal] = useState(false);
    const [detailsNoteModal, setDetailsNoteModal] = useState(false);
    const [deleteNoteModal, setDeleteNoteModal] = useState(false);
    const [shareNotesModal, setShareNotesModal] = useState(false);

    const recorderControls = useAudioRecorder();

    const [mensajeAddData, setMensajeAddData] = useState({
        id_proyecto: null,
        id_status: null,
        nota: '',
        audio: null
    });

    const [mensajeEditData, setMensajeEditData] = useState({
        id: null,
        id_status: null,
        nota: '',
        audio: null
    });

    const [mensajDetailstData, setMensajDetailstData] = useState({
        id: null,
        url: null, 
        controls: false
    });

    const [mensajeDeleteData, setMensajeDeleteData] = useState({
        id: null,
        nota: ''
    });

    const [shareNotesData, setShareNotesData] = useState({
        agregar: false,
        editar: false,
        emails: [],
        id_proyecto: null,
        notas: [],
        proyecto: null,
        ruta: null,
        todos: false,
        vigencia: null
    });

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [voiceNote, setVoiceNote] = useState({
        url: null,
        controls: false
    });

    const [voiceNoteFlag, setVoiceNoteFlag] = useState({});

    const [flagDetails, setFlagDetails] = useState(false);

    const { loadingData, dataLoaded } = dataStatus;

    //Agregar nota
    const handleAgregarNota = () => {
        setNewNoteModal(true);

        setVoiceNote({
            url: null,
            controls: false
        });
    }

    const handleInputs = ({ target }) => {
        setMensajeAddData({
            ...mensajeAddData,
            [target.name]: target.value
        });
    }

    const handleAgregarVoiceNote = (blob) => {
        if(voiceNoteFlag !== blob){

            const url = URL.createObjectURL(blob);

            setMensajeAddData({
                ...mensajeAddData,
                audio: blob
            });

            setVoiceNote({
                ...voiceNote,
                url: url,
                controls: true
            });

            setVoiceNoteFlag(blob);
        }
    }

    const handleAgregarSubmit = async (e) => {
        e.preventDefault();

        if(mensajeAddData.id_proyecto !== null){
            let formData = new FormData();
            formData.append('id_proyecto', mensajeAddData.id_proyecto);
            formData.append('id_status', mensajeAddData.id_status);
            formData.append('nota', mensajeAddData.nota);
            formData.append('audio', mensajeAddData.audio);

            await dispatch(agregarNoteProyecto(formData));
            await setNewNoteModal(false);
        }
        else{
            dispatch(setAlert('First select a project', 'danger'));
        }
    }

    const handleCloseAgregarNota = () => {
        setVoiceNote({
            url: null,
            controls: false
        })

        setMensajeAddData({
            ...mensajeAddData,
            id_status: null,
            nota: ''
        });

        setNewNoteModal(false);
    }
    
    //Editar nota
    const handleEditarNota = (nota) => {
        setMensajeEditData({
            ...mensajeEditData,
            id: nota._id,
            id_status: nota.id_status,
            nota: nota.nota
        });

        setVoiceNote({
            ...voiceNote,
            url: null,
            controls: false
        });

        setEditNoteModal(true);
    }

    const handleEditInputs = ({ target }) => {
        setMensajeEditData({
            ...mensajeEditData,
            [target.name]: target.value
        });
    }

    const handleEditarVoiceNote = (blob) => {
        if(voiceNoteFlag !== blob){

            const url = URL.createObjectURL(blob);

            setMensajeEditData({
                ...mensajeEditData,
                audio: blob
            });

            setVoiceNote({
                ...voiceNote,
                url: url,
                controls: true
            });

            setVoiceNoteFlag(blob);
        }
    }    
    
    const handleEditSubmit = async (e) => {
        e.preventDefault();

        if(mensajeEditData.nota.trim() !== '' && mensajeEditData.nota !== null){
            let formData = new FormData();
            formData.append('id', mensajeEditData.id);
            formData.append('id_status', mensajeEditData.id_status);
            formData.append('nota', mensajeEditData.nota);
            formData.append('audio', mensajeEditData.audio);

            await dispatch(editarNoteProyecto(formData));
            await handleCloseEditarNota();
        }else{
            await dispatch(setAlert('You can not send an empty note', 'danger'));
        }
    }

    const handleCloseEditarNota = () => {
        setMensajeEditData({
            ...mensajeEditData,
            id: null,
            id_status: null,
            nota: '', 
            audio: null
        });

        setVoiceNote({
            ...voiceNote,
            url: null,
            controls: false
        });

        setEditNoteModal(false);
    }

    //Detalles nota
    const handleDetallesNota = async (id_nota) => {
        await setFlagDetails(true);

        await dispatch(detallesNoteProyecto(id_nota));
    }

    const handleCloseDetallesNota = () => {
        setMensajDetailstData({
            ...mensajDetailstData,
            id: null,
            url: null, 
            controls: false
        });

        setFlagDetails(false);

        setDetailsNoteModal(false);
    }
    
    //Eliminar nota
    const handleEliminarNota = (nota) => {
        setMensajeDeleteData({
            ...mensajeDeleteData,
            id: nota._id,
            nota: nota.nota
        });

        setDeleteNoteModal(true);
    }

    const handleCloseEliminarNota = () => {
        setMensajeDeleteData({
            ...mensajeDeleteData,
            id: null,
            nota: ''
        });
        setDeleteNoteModal(false);
    }
    
    const handleDeleteSubmit = async (e) => {
        await dispatch(eliminarNoteProyecto(mensajeDeleteData.id));
        await setMensajeDeleteData({
            ...mensajeDeleteData,
            id: null,
            nota: ''
        });
        setDeleteNoteModal(false);
    } 

    const onChangeCheckbox = ({ target }) => {
        let id_sep = target.id.split('_');

        if(id_sep[1] === 0 || id_sep[1] === '0'){

            let ids_notas = [];

            for(let i = 0; i < proyectos.notes.length; i++){
                ids_notas.push(proyectos.notes[i]._id);
            }

            setShareNotesData({
                ...shareNotesData,
                todos: !shareNotesData.todos,
                notas: target.checked ? ids_notas : [],
            });
        }else{
            if(target.checked){
                setShareNotesData({
                    ...shareNotesData,
                    notas: [...shareNotesData.notas, id_sep[1]]
                });              
            }else{
                setShareNotesData({
                    ...shareNotesData,
                    todos: false,
                    notas: shareNotesData.notas.filter( nota_id => String(nota_id) !== String(id_sep[1]) )
                });
            }
        }
    }

    //Share Notes
    const handleShareNotes = () => {
        if(shareNotesData.notas.length > 0){
            setShareNotesModal(true)

            setShareNotesData({
                ...shareNotesData,
                id_proyecto: id,
            });
        }else{
            dispatch(setAlert('First select the notes', 'danger'));
        }
    }

    const onChangeCheckboxPermission = ({ target }) => {
        setShareNotesData({
            ...shareNotesData,
            [target.name]: target.checked
        });
    }

    const handleAgregarEmail = email => {
        setShareNotesData({
            ...shareNotesData,
            emails: [...shareNotesData.emails, email]
        });
    }

    const handleEliminarEmail = posicion => {
        setShareNotesData({
            ...shareNotesData,
            emails: shareNotesData.emails.filter( (email, index) => index !== posicion )
        });
    }

    const handleCopiarLink = () => {
        //navigator.clipboard.writeText(process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_notes/' + id + '/' + proyectos.link_notes);
        dispatch(setAlert('Share link coppied', 'primary'));
    }

    const handleObtenerLink = async () => {
        if(shareNotesData.vigencia !== null){
            await setStatusShareNote(true);
            //await dispatch(compartirNotesProyecto(shareNotesData));
            await setStatusShareNote(false);
        }else{
            dispatch(setAlert('First enter a valid duration date', 'danger'));
        }
    }

    const handleEnviarLink = async () => {
        if(shareNotesData.emails.length > 0){
            if(shareNotesData.vigencia !== null){
                await setStatusShareNote(true);
                //await dispatch(enviarNotesProyecto(shareNotesData));
                await setStatusShareNote(false);
            }else{
                dispatch(setAlert('First enter a valid duration date', 'danger'));
            }
        }else{
            dispatch(setAlert('First add emails', 'danger'));
        }
    }

    const handleCloseCompartirNotas = () => {
        setShareNotesModal(false);
    }

    //Other functions
    const goBackProject = () => {
        history.goBack();
    }

    const tableRow = (items) => {        
        return (
            <tbody>
                {
                    items.map( (item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>
                                        <input className="checkdui" type="checkbox" checked={shareNotesData.notas.includes(item._id)} id={'checknote_' + item._id} onChange={(e) => onChangeCheckbox(e)}></input>
                                    </td>
                                    <td>{item.nota}</td>
                                    <td>{item.campo !== null ? item.campo : 'Without field'}</td>
                                    <td>{item.seccion}</td>
                                    <td>{item.emisor.nombre}</td>
                                    <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>
                                        {
                                            item.audio ?
                                                <button className="btn btn-link text-success" onClick={() => handleDetallesNota(item._id)}>
                                                    <i className="fas fa-volume-up" />
                                                </button>
                                            :
                                                null
                                        }
                                        <button className="btn btn-link text-info" onClick={() => handleEditarNota(item)}>
                                            <i className="fa fa-edit" />
                                        </button>
                                        <button className="btn btn-link text-danger" onClick={() => handleEliminarNota(item)}>
                                            <i className="fa fa-times" />
                                        </button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(detallesProyecto(id));

        dispatch(notesProyecto(id));

        setMensajeAddData({
            ...mensajeAddData,
            id_proyecto: id
        });

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (proyectos.notes !== undefined && proyectos.notes !== null) {

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }

        if(proyectos.detalles_note !== null && flagDetails === true){
            console.log(proyectos.detalles_note);

            setMensajDetailstData({
                ...mensajDetailstData,
                id: proyectos.detalles_note?._id,
                url: proyectos.detalles_note?.url_audio, 
                controls: true
            });

            setDetailsNoteModal(true);
        }
    }, [proyectos]);

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Project notes</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => goBackProject()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                <button type="button" className='btn btn-primary float-right mb-3 ml-3' onClick={() => handleShareNotes()}><i className="fas fa-share-alt fa-sm"></i> Share notes</button>
                <button type="button" className="btn btn-success float-right mb-3" onClick={() => handleAgregarNota()}><i className="fas fa-plus fa-sm"></i> Add new note</button>
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>
                            {/* Modal de nueva nota */}
                            <Modal id="modalNewNote" backdrop="static" show={newNoteModal} onHide={() => { handleCloseAgregarNota() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Section:</label> <br></br>
                                            <select className="custom-select" name="id_status" onChange={(e) => {handleInputs(e)}}>
                                                <option value={null}>General</option>
                                                {
                                                    detalles?.proyecto?.lista_status_proyecto?.map(status => (
                                                        <option key={status.id_status} value={status.id_status}>{status.nombre}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Note:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea name="nota" className="form-control" onChange={e => handleInputs(e)} value={mensajeAddData.nota || ''}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <div className="col-md-12">
                                                <label className="font-weight-bold">Nota de Audio</label>
                                            </div>
                                            <div style={styles.contenedor_audio}>
                                                <AudioRecorder
                                                    onRecordingComplete={(blob) => handleAgregarVoiceNote(blob)}
                                                    recorderControls={recorderControls}
                                                />
                                            </div>
                                            <audio src={voiceNote.url} controls={voiceNote.controls} />
                                        </div>   
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAgregarNota()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={(e) => handleAgregarSubmit(e)}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de editar nota */}
                            <Modal backdrop="static" show={editNoteModal} onHide={() => { handleCloseEditarNota() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Section:</label> <br></br>
                                            <select className="custom-select" name="id_status" value={mensajeEditData.id_status} onChange={(e) => {handleEditInputs(e)}}>
                                                <option value={null}>General</option>
                                                {
                                                    detalles?.proyecto?.lista_status_proyecto?.map(status => (
                                                        <option key={status.id_status} value={status.id_status}>{status.nombre}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Note:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea name="nota" className="form-control" onChange={e => handleEditInputs(e)} value={mensajeEditData.nota || ''}></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <div className="col-md-12">
                                                <label className="font-weight-bold">Nota de Audio</label>
                                            </div>
                                            <div style={styles.contenedor_audio}>
                                                <AudioRecorder
                                                    onRecordingComplete={(blob) => handleEditarVoiceNote(blob)}
                                                    recorderControls={recorderControls}
                                                />
                                            </div>
                                            <audio src={voiceNote.url} controls={voiceNote.controls} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEditarNota()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleEditSubmit}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de detalles de nota */}
                            <Modal backdrop="static" show={detailsNoteModal} size="md" onHide={() => { handleCloseDetallesNota() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Details note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Voice note:</label><br/>
                                            <div className="text-center">
                                                <audio src={mensajDetailstData.url} controls={mensajDetailstData.controls} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseDetallesNota()}><i className="fas fa-times fa-sm"></i> Close</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de eliminar nota */}
                            <Modal backdrop="static" show={deleteNoteModal} size="lg" onHide={() => {
                                setDeleteNoteModal(false);
                                setMensajeDeleteData({
                                    ...mensajeDeleteData,
                                    id: null,
                                    nota: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete note</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label>Do you want to delete the note "<b>{mensajeDeleteData.nota}</b>"?</label>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEliminarNota()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleDeleteSubmit}><i className="fas fa-check fa-sm"></i> Yes</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de compartir notas */}
                            <Modal backdrop="static" show={shareNotesModal} size="lg" onHide={() => {
                                setShareNotesModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Notes to share</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p>Share the access <b>link</b> or send it to the <b>emails</b> you want:</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Duration Date:</label>
                                            <div className='d-flex'>
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </span>
                                                <DatePicker 
                                                    name="notes_duration_date" 
                                                    dateFormat="MM/dd/yyyy" 
                                                    className="form-control text-center" 
                                                    selected={shareNotesData.vigencia !== null ? shareNotesData.vigencia : null} 
                                                    onChange={date => {setShareNotesData({...shareNotesData, vigencia: date})}} 
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Add permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label class="container text-center">
                                                    <input type="checkbox" name="agregar" checked={shareNotesData.agregar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Edit permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label class="container text-center">
                                                    <input type="checkbox" name="editar" checked={shareNotesData.editar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Delete permission:</label>
                                            <div className="contenedor_datepicker">
                                                <label class="container text-center">
                                                    <input type="checkbox" name="eliminar" checked={shareNotesData.eliminar} onChange={ (e) => onChangeCheckboxPermission(e) }/>
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Emails:</label>
                                            <div className="contenedor_datepicker">
                                                <ReactChipInput
                                                    chips={shareNotesData.emails}
                                                    onSubmit={value => handleAgregarEmail(value)}
                                                    onRemove={index => handleEliminarEmail(index)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            proyectos.link_notes !== null ?
                                                <div className="col-md-12">
                                                    <label className="font-weight-bold">Share link:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" value={process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_notes/' + id + '/' + proyectos.link_notes} disabled></input>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="basic-addon1" onClick={() => handleCopiarLink()}>
                                                                <i className="fas fa-copy"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseCompartirNotas()} disabled={statusShareNotes ? false : true}><i class="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={() => handleObtenerLink()} disabled={statusShareNotes ? false : true}><i class="fas fa-link fa-sm"></i> Get share link</button>
                                    <button className="btn btn-success" onClick={() => handleEnviarLink()} disabled={statusShareNotes ? false : true}><i class="fas fa-paper-plane fa-sm"></i> Send share link</button>
                                </Modal.Footer>
                            </Modal>

                            <div className="col-md-12 my-4">
                                <div className="row form-group">
                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + detalles?.proyecto?.latitud + '%2C' + detalles?.proyecto?.longitud} target="_blank">{detalles?.proyecto?.direccion}</a>
                                    </label>
                                </div>
                            </div>

                            {
                                proyectos.notes?.length > 0 ?
                                    <div className="col-md-12 row">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <input className="checkdui" type="checkbox" checked={shareNotesData.todos} id="checknote_0" onChange={(e) => onChangeCheckbox(e)}></input>
                                                        </th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Field</th>
                                                        <th scope="col">Section</th>
                                                        <th scope="col">Creator</th>
                                                        <th scope="col">Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                { tableRow(proyectos.notes) }
                                            </table>
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-10 offset-md-1 text-center mt-4">
                                        <h3>No notes founded.</h3>
                                        <button className="btn btn-primary mt-2" onClick={goBack}>
                                            <i className="fas fa-arrow-left fa-sm"></i> Go back
                                        </button>
                                    </div>

                            }
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center">
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default Notes;