import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { logout } from '../../actions/auth';
import { desplegarModalCambios, buySalePage, paginaRedireccion } from '../../actions/flipping';
import { useSelector, useDispatch } from 'react-redux';

//Actions
import { externaRecordatorio } from '../../actions/recordatorios';
import { externaTarea } from '../../actions/todolist';
import { buscarSecciones } from '../../actions/secciones_todolist';
import { buscarUsuarios } from '../../actions/usuarios';
import { externaNomina } from '../../actions/nominas';
import { externoGasto } from '../../actions/gastos';
import { listarProyectos } from '../../actions/proyectos';
import { buscarEmpleados } from '../../actions/empleados';

const styles = {
    navStyles: { fontSize: '1rem', whiteSpace: 'nowrap', color: 'white' },
    navContentColor: {color: 'white'}
}

const Navbar = () => {

    const dispatch = useDispatch();
    const { goBack, goForward } = useHistory();

    const { permisos_dashboard, permisos_reminders, permisos_todolist, permisos_payrolls, permisos_expenses, permisos_accounting, permisos_bills, permisos_profile, permisos_configuration } = useSelector(state => state.auth.user);
    const { buy_sale_page, guardar_cambios } = useSelector(state => state.proyectos);

    const handleLogout = () => {
        dispatch(logout());
    }

    const handleOpenPageNavbar = (page) => {
        if(buy_sale_page && guardar_cambios){
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion(page));
            dispatch(buySalePage(false));
        }else{
            window.location.href = page;
            dispatch(buySalePage(false));
        }
    }

    const handleOpenNuevoRecordatorio = () => {
        dispatch(listarProyectos());
        
        dispatch(externaRecordatorio(true));
    }

    const handleOpenNuevaTarea = () => {

        dispatch(buscarSecciones());
        dispatch(buscarUsuarios());
        dispatch(listarProyectos());

        dispatch(externaTarea(true));
    }

    const handleOpenNuevaNomina = () => {

        dispatch(listarProyectos());
        dispatch(buscarEmpleados());

        dispatch(externaNomina(true));
    }

    const handleOpenNuevoGasto = () => {

        dispatch(listarProyectos());

        dispatch(externoGasto(true));
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <div className="w-100">
                    <a className="navbar-brand" href="/dashboard">
                        <img src="/logo.png" alt="logo" height="35" style={{ marginRight: '0.5rem', verticalAlign: 'baseline' }} />
                    </a>
                    <button className="btn btn-direccion" type="button" onClick={goBack}>
                        <i className="fas fa-angle-left" style={styles.navStyles}></i>
                    </button>
                    <button className="btn btn-direccion" type="button" onClick={goForward}>
                        <i className="fas fa-angle-right" style={styles.navStyles}></i>
                    </button>
                    <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div className="collapse navbar-collapse d-lg-flex justify-content-lg-center px-3" id="navbarNav">
                    <ul className="navbar-nav">
                        {/* Dashboard */}
                        {
                            permisos_dashboard.view ?
                            <li key="btn_header_0" className="nav-item d-flex align-items-center mr-3">
                                <a className="nav-link d-flex align-items-center navlink" aria-current="page" onClick={() => handleOpenPageNavbar('/dashboard')}>
                                    <i className="fa fa-home" style={styles.navStyles} /> 
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Dashboard</p>
                                </a>
                            </li>
                            :
                            null
                        }

                        {/* Reminders */}
                        {
                            (!permisos_reminders.view) ?
                            null
                            :
                            (permisos_reminders.check) &&  !permisos_reminders.add ?
                            <li key="btn_header_1" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_reminders" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-calendar-day" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Reminders</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_reminders">
                                    {/* <div><a className="dropdown-item navlink" onClick={() => handleOpenNuevoRecordatorio()}>New</a></div> */}
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/reminders')}>History</a></div>
                                </div>
                            </li>
                            :
                            <li key="btn_header_1" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_reminders" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-calendar-day" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Reminders</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_reminders">
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenNuevoRecordatorio()}>New</a></div>
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/reminders')}>History</a></div>
                                </div>
                            </li>
                        }

                        {/* To do list */}
                        {
                            !permisos_todolist.view ?
                            null
                            :
                            !permisos_todolist.new_task ?
                            <li key="btn_header_2" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_todolist" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-check-square" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>To do list</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_todolist">
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/todolist/listado')}>To do list</a></div>
                                </div>
                            </li>
                            :
                            <li key="btn_header_2" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_todolist" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-check-square" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>To do list</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_todolist">
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenNuevaTarea()}>New task</a></div>
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/todolist/listado')}>To do list</a></div>
                                </div>
                            </li>
                        }

                        {/* Deal analyzer */}
                        {/* { */}
                            {/* (!permisos_payrolls.new && !permisos_payrolls.view) ?
                                null
                            : */}
                                <li key="btn_header_3" className="nav-item dropdown mr-3">
                                    <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-percent" style={styles.navStyles} />
                                        <p className="ml-2 mb-0" style={styles.navContentColor}>Deal analyzer</p>
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                        
                                        {/* (permisos_payrolls.new) ? */}
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/deal/new')}>New</a></div>
                                        {/* :
                                            null */}

                                        {/* (permisos_payrolls.view) ? */}
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/deal/list')}>List</a></div>
                                        {/* :
                                            null */}

                                    </div>
                            </li>
                        {/* } */}

                        {/* Offers */}
                        {/* { */}
                            {/* (!permisos_payrolls.new && !permisos_payrolls.view) ?
                                null
                            : */}
                                <li key="btn_header_4" className="nav-item dropdown mr-3">
                                    <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-hand-holding-usd" style={styles.navStyles} />
                                        <p className="ml-2 mb-0" style={styles.navContentColor}>Offers</p>
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                        
                                        {/* (permisos_payrolls.new) ? */}
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/projects/offer')}>New</a></div>
                                        {/* :
                                            null */}

                                        {/* (permisos_payrolls.view) ? */}
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/projects/offers')}>List</a></div>
                                        {/* :
                                            null */}

                                    </div>
                            </li>
                        {/* } */}
                        
                        {/* Projects */}
                        <li key="btn_header_5" className="nav-item dropdown mr-3">
                            <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_proyectos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-hotel" style={styles.navStyles} />
                                <p className="ml-2 mb-0" style={styles.navContentColor}>Projects</p>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_proyectos">
                                <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/projects/side')}>New side project</a></div>
                                <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/projects/projects')}>Projects</a></div>
                            </div>
                        </li>

                        {/* Payments */}
                        {
                            (!permisos_payrolls.view) ?
                            null
                            :
                            (!permisos_payrolls.new_single_payroll || !permisos_payrolls.new_multiple_payroll) ?
                            <li key="btn_header_6" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-hard-hat" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Payrolls</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/payments')}>History</a></div>
                                </div>
                            </li>
                            :
                            <li key="btn_header_6" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-hard-hat" style={styles.navStyles} />
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Payrolls</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenNuevaNomina()}>New</a></div>
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/payments')}>History</a></div>
                                </div>
                            </li>
                        }

                        {/* Expenses */}
                        {
                            !permisos_expenses.view ?
                                null
                            :
                                !permisos_expenses.new ?
                                    <li key="btn_header_7" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-dollar-sign" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Expenses</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/expenses')}>History</a></div>
                                        </div>
                                    </li>
                                :
                                    <li key="btn_header_7" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-dollar-sign" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Expenses</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenNuevoGasto()}>New</a></div>
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/expenses')}>History</a></div>
                                        </div>
                                    </li>
                        }

                        {/* Accounting */}
                        {
                            !permisos_accounting.view ?
                                null
                            :
                                !permisos_accounting.credit_cards ?
                                    <li key="btn_header_8" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-calculator" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Accounting</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/accounting')}>Dashboard</a></div>
                                        </div>
                                    </li>
                                :
                                    <li key="btn_header_8" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-calculator" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Accounting</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/accounting')}>Dashboard</a></div>
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/accounting/credit_cards')}>Credit cards</a></div>
                                        </div>
                                    </li>
                        }

                        {/* Bills */}
                        {
                            !permisos_bills.view ?
                                null
                            :
                                !permisos_bills.providers ?
                                    <li key="btn_header_9" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-file-invoice-dollar" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Bills</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/bills')}>Dashboard</a></div>
                                        </div>
                                    </li>
                                :
                                    <li key="btn_header_9" className="nav-item dropdown mr-3">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_gastos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-file-invoice-dollar" style={styles.navStyles} />
                                            <p className="ml-2 mb-0" style={styles.navContentColor}>Bills</p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_gastos">
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/bills')}>Dashboard</a></div>
                                            <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/bills/providers')}>Providers</a></div>
                                        </div>
                                    </li>
                        }

                        {/* Configuration */}
                        <li key="btn_header_10" className="nav-item dropdown mr-3">
                            <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_configuracion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-cog" style={styles.navStyles} />
                                <p className="ml-2 mb-0" style={styles.navContentColor}>Configuration</p>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_configuracion">
                                {
                                    !permisos_configuration.view_users ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/users')}>Users</a></div>
                                }
                                {
                                    !permisos_configuration.view_employees ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/employees')}>Employees</a></div>
                                }
                                {
                                    !permisos_configuration.view_due_dilligence_checklist ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/due')}>Due diligence checklist</a></div>
                                }
                                {
                                    !permisos_configuration.view_deal_analyzer ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/deal')}>Deal Analyzer</a></div>
                                }
                                {
                                    !permisos_configuration.view_sellers ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/sellers')}>Seller list</a></div>
                                }
                                {
                                    !permisos_configuration.view_buyers ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/buyers')}>Buyer list</a></div>
                                }
                                {
                                    !permisos_configuration.view_vestings ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/vestings')}>Vesting list</a></div>
                                }
                                {
                                    !permisos_configuration.view_brokers ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/brokers')}>Broker list</a></div>
                                }
                                {
                                    !permisos_configuration.view_escrows ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/escrows')}>Escrow list</a></div>
                                }
                                {
                                    !permisos_configuration.view_titles ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/titles')}>Title list</a></div>
                                }
                                {
                                    !permisos_configuration.view_termites ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/termites')}>Termite list</a></div>
                                }
                                {
                                    !permisos_configuration.view_septics ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/septics')}>Septic list</a></div>
                                }
                                {
                                    !permisos_configuration.view_categories_inspection ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/inspections')}>Inspection categories</a></div>
                                }
                                {
                                    !permisos_configuration.view_sections_todolist ?
                                    null
                                    :
                                    <div><a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/configuration/todolist')}>To do list sections</a></div>
                                }
                            </div>
                        </li>

                        {/* Profile and Logout */}
                        {
                            !permisos_profile.view ?
                            null
                            :
                            <li key="btn_header_11" className="nav-item dropdown mr-3">
                                <a className="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbar_perfil" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-user-cog" style={styles.navStyles}></i> 
                                    <p className="ml-2 mb-0" style={styles.navContentColor}>Profile</p>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbar_perfil">
                                    <a className="dropdown-item navlink" onClick={() => handleOpenPageNavbar('/profile')}>Profile</a>
                                    <a className="dropdown-item navlink" onClick={handleLogout} href="/">Log Out</a>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;