import { BUSCAR_ANALISIS, DETALLES_ANALISIS, ELIMINAR_ANALISIS, BUSCAR_VALORES_ANALISIS } from '../actions/types';

const initialState = {
    analisis: [],
    detalles: null, 
    valores: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        
        case BUSCAR_ANALISIS:
            return {
                ...state,
                analisis: payload
            };

        case DETALLES_ANALISIS:
            return {
                ...state,
                detalles: payload
            };

        case ELIMINAR_ANALISIS:
            return {
                ...state,
                analisis: state.analisis.filter( analisis => analisis._id !== payload.id )
            };

        case BUSCAR_VALORES_ANALISIS:
            return {
                ...state,
                valores: payload
            };
        
        default:
            return state;
    }
}