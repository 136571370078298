import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import PlacesAutocomplete from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../../actions/alert';
import { editarSellsInEscrow, renegotiateSellsInEscrow, continuarSellsInEscrow, guardarCambios, desplegarModalCambios, redireccionar } from '../../../actions/new_development';
import { detallesProyecto, detallesFileProyecto, masterlistProyecto, seleccionarSeccionMasterList, agregarFileProyecto, editarFileProyecto, externoNota } from '../../../actions/proyectos';
import { agregarEscrowMasterlist, agregarEscrowAgentMasterlist } from '../../../actions/escrows';
import { agregarBrokerMasterlistSale, agregarBrokerAgentMasterlistSale } from '../../../actions/brokers';
import { agregarTitleMasterlistSale, agregarTitleAgentMasterlist } from '../../../actions/titles';
import { agregarTermiteMasterlistSale, agregarTermiteAgentMasterlist } from '../../../actions/termites';
import { agregarSepticMasterlistSale, agregarSepticAgentMasterlist } from '../../../actions/septics';

const styles = {
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    }
}

const SellsInEscrowNewDevelopment = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const escrows = useSelector(state => state.escrows);
    const brokers = useSelector(state => state.brokers);
    const titles = useSelector(state => state.titles);
    const termites = useSelector(state => state.termites);
    const septics = useSelector(state => state.septics);
    const usuarios = useSelector(state => state.usuarios);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);

    const history = useHistory();

    //Atrapar back button del navegador

    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logica para mostrar un popup cuando la pagina se recarga
        window.onbeforeunload = function () {

        return "Data will be lost if you leave the page, are you sure?";
        };
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {

        if (window.confirm("Do you want to exit the page?")) {
            setBackbuttonPress(true);
            history.go(-1);
            handleCloseNoGuardarCambiosModal();
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setBackbuttonPress(false)
        }
        }
    }

    const [dilligenceDays, setDilligenceDays] = useState(0);
    const [dilligenceDaysFlag, setDilligenceDaysFlag] = useState(false);
    const [flag, setFlag] = useState(false);

    const [proyectoData, setProyectoData] = useState({
        id: null,
        sales_price: null,
        contract_sale: null,
        escrow_deposit_amount: null,
        buys_help: null,
        dilligence_days: 0,
        due_dilligence_expiration: null,
        inspection: null,
        closing: null,
        escrow_deposit_received_question: null,
        escrow_deposit_received_user: null,
        name_escrow_deposit_received_user: null,
        escrow_deposit_received: null,
        
        id_escrow: null,
        id_escrow_agent: null,
        escrow_instructions: false,
        escrow_instructions_date: null,
        escrow_number: null,

        id_broker_seller: null,
        id_broker_agent_seller: null,
        broker_represent: false,
        id_broker_buyer: null,
        id_broker_agent_buyer: null,

        id_title: null,
        id_title_agent: null,
        title_preliminary: false,
        title_preliminary_date: null,
        title_review: false,
        title_review_who: null,
        title_rep: null,
        title_order: null,

        id_termite: null,
        id_termite_agent: null,        
        termite_inspection: false,
        termite_inspection_user: null,
        name_termite_inspection_user: null,
        termite_inspection_date: null,
        report_number_termite: null,

        id_septic: null,
        id_septic_agent: null,        
        septic_inspection: false,
        septic_inspection_user: null,
        name_septic_inspection_user: null,
        septic_inspection_date: null,
        report_number_septic: null,

        sales_price_history: [],
        due_dilligence_expiration_history: [],
        closing_history: []
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [userEscrowDepositSelectData, setUserEscrowDepositSelectData] = useState(null);

    const [renegotiationData, setRenegotiationData] = useState({
        id_proyecto: null,
        id_status: null,
        sales_price: null,
        sales_price_date: null,
        sales_price_note: null,
        sales_price_file_name: null,
        sales_price_file: null,
        sales_price_extension: null,
        closing: null,
        closing_date: null,
        closing_note: null,
        closing_file_name: null,
        closing_file: null,
        closing_extension: null,
        due_dilligence_expiration: null,
        due_dilligence_expiration_date: null,
        due_dilligence_expiration_note: null,
        due_dilligence_expiration_file_name: null,
        due_dilligence_expiration_file: null,
        due_dilligence_expiration_extension: null,
        escrow_deposit_increase: null,
        escrow_deposit_release: null,
        funds_applied_sale_price: null
    });


    const [escrowSelectData, setEscrowSelectData] = useState(null);
    const [escrowAgentSelectData, setEscrowAgentSelectData] = useState(null);

    const [brokerSellerSelectData, setBrokerSellerSelectData] = useState(null);
    const [brokerAgentSellerSelectData, setBrokerAgentSellerSelectData] = useState(null);

    const [brokerBuyerSelectData, setBrokerBuyerSelectData] = useState(null);
    const [brokerAgentBuyerSelectData, setBrokerAgentBuyerSelectData] = useState(null);

    const [titleSelectData, setTitleSelectData] = useState(null);
    const [titleAgentSelectData, setTitleAgentSelectData] = useState(null);

    const [termiteSelectData, setTermiteSelectData] = useState(null);
    const [termiteAgentSelectData, setTermiteAgentSelectData] = useState(null);

    const [septicSelectData, setSepticSelectData] = useState(null);
    const [septicAgentSelectData, setSepticAgentSelectData] = useState(null);

    const [userTermiteSelectData, setUserTermiteSelectData] = useState(null);
    const [userSepticSelectData, setUserSepticSelectData] = useState(null);

    const [addEscrowData, setAddEscrowData] = useState({
        company: '',
        address: ''
    });

    const [addEscrowAgentData, setAddEscrowAgentData] = useState({
        id_escrow: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [addBrokerBuyerData, setAddBrokerBuyerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentBuyerData, setAddBrokerAgentBuyerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addBrokerSellerData, setAddBrokerSellerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentSellerData, setAddBrokerAgentSellerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addTitleData, setAddTitleData] = useState({
        company: '',
        address: ''
    });

    const [addTitleAgentData, setAddTitleAgentData] = useState({
        id_title: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [addTermiteData, setAddTermiteData] = useState({
        company: '',
        address: ''
    });

    const [addTermiteAgentData, setAddTermiteAgentData] = useState({
        id_termite: null,
        officer_name: '',
        email: '',
        phone: ''
    });

    const [addSepticData, setAddSepticData] = useState({
        company: '',
        address: ''
    });

    const [addSepticAgentData, setAddSepticAgentData] = useState({
        id_septic: null,
        officer_name: '',
        email: '',
        phone: ''
    });

    const [viewEscrowData, setViewEscrowData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [viewBrokerBuyerData, setViewBrokerBuyerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerSellerData, setViewBrokerSellerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewTitleData, setViewTitleData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: ''
    });

    const [viewTermiteData, setViewTermiteData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        phone: ''
    });

    const [viewSepticData, setViewSepticData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        phone: ''
    });

    const [copiar, setCopiar] = useState(null);

    const [escrowModal, setEscrowModal] = useState(false);
    const [escrowAgentModal, setEscrowAgentModal] = useState(false);

    const [brokerBuyerModal, setBrokerBuyerModal] = useState(false);
    const [brokerAgentBuyerModal, setBrokerAgentBuyerModal] = useState(false);

    const [brokerSellerModal, setBrokerSellerModal] = useState(false);
    const [brokerAgentSellerModal, setBrokerAgentSellerModal] = useState(false);

    const [titleModal, setTitleModal] = useState(false);
    const [titleAgentModal, setTitleAgentModal] = useState(false);

    const [termiteModal, setTermiteModal] = useState(false);
    const [termiteAgentModal, setTermiteAgentModal] = useState(false);

    const [septicModal, setSepticModal] = useState(false);
    const [septicAgentModal, setSepticAgentModal] = useState(false);

    const [escrowsFilter, setEscrowsFilter] = useState([]);
    const [escrowsAgentsFilter, setEscrowsAgentsFilter] = useState([]);

    const [brokersFilter, setBrokersFilter] = useState([]);
    const [brokersAgentsBuyerFilter, setBrokersAgentsBuyerFilter] = useState([]);
    const [brokersAgentsSellerFilter, setBrokersAgentsSellerFilter] = useState([]);

    const [titlesFilter, setTitlesFilter] = useState([]);
    const [titlesAgentsFilter, setTitlesAgentsFilter] = useState([]);

    const [termitesFilter, setTermitesFilter] = useState([]);
    const [termitesAgentsFilter, setTermitesAgentsFilter] = useState([]);

    const [septicsFilter, setSepticsFilter] = useState([]);
    const [septicsAgentsFilter, setSepticsAgentsFilter] = useState([]);

    const [usersFilter, setUsersFilter] = useState([]);

    const [sectionInformation, setSectionInformation] = useState(0);

    const [newFileModal, setNewFileModal] = useState(false);

    const [editFileModal, setEditFileModal] = useState(false);

    const [statusSaveFile, setStatusSaveFile] = useState(true);

    const [statusSaveEditFile, setStatusSaveEditFile] = useState(true);    

    const [newFileData, setNewFileData] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        subseccion: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [editFileData, setEditFileData] = useState({
        id: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [dataTabla, setDataTabla] = useState({
        archivosTabla: null
    });

    const [renegotiationModal, setRenegotiationModal] = useState(false);
    const [guardarCambiosModal, setGuardarCambiosModal] = useState(false);

    let file_ref = createRef();
    let file_edit_ref = createRef();
    let sales_price_ref = createRef();
    let closing_ref = createRef();
    let due_dilligence_expiration_ref = createRef();

    const newNote = campo => {
        dispatch(externoNota(true, campo));
    }

    const handleAlertarFechas = (date) => {

        let dif_days = Math.floor((new Date(date).getTime() - new Date(proyectoData.due_dilligence_expiration).getTime()) / (1000 * 3600 * 24));

        if(dif_days >= 3){
            dispatch(setAlert('This date must be less than 3 days greater than the Due Diligence expiration date', 'warning'));
        }
    }

    //Agregar archivo
    const handleAddFileSection = (subseccion) => {
        setNewFileModal(true);

        setNewFileData({
            ...newFileData,
            subseccion: subseccion
        });
    }

    const handleInputsFile = ({ target }) => {
        setNewFileData({
            ...newFileData,
            [target.name]: target.value
        });
    }

    const onChangeFile = () => {
        let nombre_separado = file_ref.current.files[0].name.split('.');

        setNewFileData({
            ...newFileData,
            archivo: file_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseAgregarArchivo = () => {
        setNewFileModal(false);
        setNewFileData({
            ...newFileData,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('subseccion', newFileData.subseccion);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);


                    setNewFileData({
                        ...newFileData,
                        id_folder: null,
                        subseccion: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setSectionInformation((newFileData.subseccion * 2) + 1);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Editar archivo
    const handleInputsEditFile = ({ target }) => {
        setEditFileData({
            ...editFileData,
            [target.name]: target.value
        });
    }

    const onChangeEditFile = () => {
        let nombre_separado = file_edit_ref.current.files[0].name.split('.');

        setEditFileData({
            ...editFileData,
            archivo: file_edit_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleCloseEditarArchivo = () => {
        setEditFileModal(false);
        setEditFileData({
            ...editFileData,
            id: null,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    const handleGuardarEditarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(editFileData.extension !== ''){
                if(editFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id', editFileData.id);
                    formData.append('nombre', editFileData.nombre);
                    formData.append('extension', editFileData.extension);
                    formData.append('archivo', editFileData.archivo);

                    setStatusSaveEditFile(false);
                    await dispatch(editarFileProyecto(formData));
                    setStatusSaveEditFile(true);

                    setEditFileData({
                        ...editFileData,
                        id: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setEditFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setRenegotiationModal(false);
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyecto(id));
    }

    const handleEditarArchivo = async (archivo) => {
        setEditFileModal(true);

        setEditFileData({
            ...editFileData,
            id: archivo._id,
            nombre: archivo.nombre,
            extension: archivo.extension
        });
    }

    const handleCopyBroker = () => {
        setProyectoData({
            ...proyectoData,
            id_broker_buyer: proyectoData.id_broker_seller,
            id_broker_agent_buyer: proyectoData.id_broker_agent_seller,
            broker_represent: true
        });

        setBrokerBuyerSelectData(brokerSellerSelectData);
        setBrokerAgentBuyerSelectData(brokerAgentSellerSelectData);
        
        dispatch(setAlert('Copied information', 'success'));
        setSectionInformation(2);
    };

    const onChange = ({ target }) => {
        setProyectoData({
            ...proyectoData,
            [target.name]: target.value 
        });

        dispatch(guardarCambios(true));
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value
        });

        dispatch(guardarCambios(true));
    };

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){
                case 'escrow_deposit_received_user':
                    await setUserEscrowDepositSelectData({value, label});
                    break;

                case 'id_escrow':
                    await setEscrowSelectData({value, label});
                    break;

                case 'id_escrow_agent':
                    await setEscrowAgentSelectData({value, label});
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData({value, label});
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData({value, label});
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData({value, label});
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData({value, label});
                    break;

                case 'id_title':
                    await setTitleSelectData({value, label});
                    break;

                case 'id_title_agent':
                    await setTitleAgentSelectData({value, label});
                    break;

                case 'id_termite':
                    await setTermiteSelectData({value, label});
                    break;

                case 'id_termite_agent':
                    await setTermiteAgentSelectData({value, label});
                    break;

                case 'id_septic':
                    await setSepticSelectData({value, label});
                    break;

                case 'id_septic_agent':
                    await setSepticAgentSelectData({value, label});
                    break;

                case 'termite_inspection_user':
                    await setUserTermiteSelectData({value, label});
                    break;

                case 'septic_inspection_user':
                    await setUserSepticSelectData({value, label});
                    break;

                default:
                    break;
            }

            if(origin === 'id_escrow' || origin === 'id_broker_buyer' || origin === 'id_broker_seller' || origin === 'id_title' || origin === 'id_termite' || origin === 'id_septic'){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: label
                });
            }
        }else{

            let datos = {
                ...proyectoData,
                [origin]: null,
                [name_origin]: null
            };

            switch(origin){
                case 'escrow_deposit_received_user':
                    await setUserEscrowDepositSelectData(null);
                    break;

                case 'id_escrow':
                    await setEscrowSelectData(null);
                    break;

                case 'id_escrow_agent':
                    await setEscrowAgentSelectData(null);
                    break;

                case 'id_broker_seller':
                    await setBrokerSellerSelectData(null);
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData(null);
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData(null);
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData(null);
                    break;

                case 'id_title':
                    await setTitleSelectData(null);
                    break;

                case 'id_title_agent':
                    await setTitleAgentSelectData(null);
                    break;

                case 'id_termite':
                    await setTermiteSelectData(null);
                    datos.report_number_termite = null;
                    datos.termite_inspection_date = null;
                    break;

                case 'id_termite_agent':
                    await setTermiteAgentSelectData(null);
                    break;

                case 'id_septic':
                    await setSepticSelectData(null);
                    datos.report_number_septic = null;
                    datos.septic_inspection = null;
                    break;

                case 'id_septic_agent':
                    await setSepticAgentSelectData(null);
                    break;

                case 'termite_inspection_user':
                    await setUserTermiteSelectData(null);
                    break;

                case 'septic_inspection_user':
                    await setUserSepticSelectData(null);
                    break;

                default:
                    break;
            }

            await setProyectoData(datos);
        }

        await dispatch(guardarCambios(true));
    };

    const onContractDateChange = ( date ) => {

        setDilligenceDaysFlag(false);

        let fecha_inicio = new Date(date);
        let fecha_final = new Date(date);
        let valor = proyectoData.dilligence_days;

        if(proyectoData.due_dilligence_expiration === null){
            valor = 17;
        }

        fecha_final = new Date(fecha_inicio.getTime() + (parseInt(valor) * 24 * 60 * 60 * 1000));

        setDilligenceDays(valor);

        setProyectoData({
            ...proyectoData,
            contract_sale: date,
            dilligence_days: valor,
            due_dilligence_expiration: fecha_final
        });

        dispatch(guardarCambios(true));
    }

    const onChangeDays = ({ target }) => {
        let fecha_inicio = proyectoData.contract_sale;
        let fecha_final = proyectoData.due_dilligence_expiration;
        let valor;

        if(target.value === '' || target.value === null){
            valor = 0;
            setDilligenceDaysFlag(true);
        }else{
            valor = target.value;
            setDilligenceDaysFlag(false);
        }

        if(fecha_inicio !== null){
            fecha_inicio = new Date(fecha_inicio);
            fecha_final = new Date(fecha_final);
            fecha_final = new Date(fecha_inicio.getTime() + (parseInt(valor) * 24 * 60 * 60 * 1000));

            setProyectoData({
                ...proyectoData,
                dilligence_days: parseInt(valor),
                due_dilligence_expiration: fecha_final
            });

            dispatch(guardarCambios(true));
        }
    };

    const handleRadiobutton = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    escrow_deposit_received_question: true,
                    escrow_deposit_received: new Date()
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    escrow_deposit_received_question: false,
                    escrow_deposit_received: null
                });
            } 

            dispatch(guardarCambios(true));           
        }
    }

    const handleRadiobuttonCopy = ({target}) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setCopiar(true);
            }else{
                setCopiar(false);
                setProyectoData({
                    ...proyectoData,
                    id_broker_buyer: null,
                    id_broker_agent_buyer: null,
                    broker_represent: false
                });
        
                setBrokerBuyerSelectData(null);
                setBrokerAgentBuyerSelectData(null);
            }
        }
    }

    const handleRadiobuttonEscrowInstructions = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    escrow_instructions: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    escrow_instructions: false,
                    escrow_instructions_date: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTitlePreliminary = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    title_preliminary: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    title_preliminary: false,
                    title_preliminary_date: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTitleReviewed = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    title_review: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    title_review: false,
                    title_review_who: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonTermiteInspection = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    termite_inspection: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    termite_inspection: false,
                    termite_inspection_user: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleRadiobuttonSepticInspection = ({ target }) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setProyectoData({
                    ...proyectoData,
                    septic_inspection: true
                });
            }else{
                setProyectoData({
                    ...proyectoData,
                    septic_inspection: false,
                    septic_inspection_user: null
                });
            }

            dispatch(guardarCambios(true));
        }
    }

    const handleGuardarCambiosModal = () => {
        setGuardarCambiosModal(true);
    }

    const handleCloseGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(desplegarModalCambios(false));
    }

    const handleCloseNoGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(guardarCambios(false));
        dispatch(desplegarModalCambios(false));
        dispatch(redireccionar(proyectos.paginaRedireccion));
    }

    const handleEscrow = (tipo) => {
        if(tipo){
            setEscrowModal(true);
        }else{

            setAddEscrowAgentData({
                ...addEscrowAgentData,
                id_escrow: proyectoData.id_escrow
            });

            setEscrowAgentModal(true);            
        }
    }

    const handleCloseModalEscrow = (tipo) => {
        if(tipo){
            setEscrowModal(false);

            setAddEscrowData({
                ...addEscrowData,
                company: '',
                address: ''
            });
        }else{
            setEscrowAgentModal(false);

            setAddEscrowAgentData({
                ...addEscrowAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });          
        }
    }

    const handleBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(true);
        }else{

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                id_broker: proyectoData.id_broker_seller
            });

            setBrokerAgentSellerModal(true);            
        }
    }

    const handleCloseModalBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(false);

            setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentSellerModal(false);

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            }); 
        }
    }

    const handleBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(true);
        }else{

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                id_broker: proyectoData.id_broker_buyer
            });

            setBrokerAgentBuyerModal(true);            
        }
    }

    const handleCloseModalBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(false);

            setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentBuyerModal(false);   

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            });
        }
    }

    const handleTitle = (tipo) => {
        if(tipo){
            setTitleModal(true);
        }else{

            setAddTitleAgentData({
                ...addTitleAgentData,
                id_title: proyectoData.id_title
            });

            setTitleAgentModal(true);            
        }
    }

    const handleCloseModalTitle = (tipo) => {
        if(tipo){
            setTitleModal(false);

            setAddTitleData({
                ...addTitleData,
                company: '',
                address: ''
            });
        }else{
            setTitleAgentModal(false);

            setAddTitleAgentData({
                ...addTitleAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });
        }
    }

    const handleTermite = (tipo) => {
        if(tipo){
            setTermiteModal(true);
        }else{

            setAddTermiteAgentData({
                ...addTermiteAgentData,
                id_termite: proyectoData.id_termite
            });

            setTermiteAgentModal(true);            
        }
    }

    const handleCloseModalTermite = (tipo) => {
        if(tipo){
            setTermiteModal(false);

            setAddTermiteData({
                ...addTermiteData,
                company: '',
                address: ''
            });
        }else{
            setTermiteAgentModal(false);

            setAddTermiteAgentData({
                ...addTermiteAgentData,
                officer_name: '',
                email: '',
                phone: ''
            });
        }
    }

    const handleSeptic = (tipo) => {
        if(tipo){
            setSepticModal(true);
        }else{

            setAddSepticAgentData({
                ...addSepticAgentData,
                id_septic: proyectoData.id_septic
            });

            setSepticAgentModal(true);            
        }
    }

    const handleCloseModalSeptic = (tipo) => {
        if(tipo){
            setSepticModal(false);

            setAddSepticData({
                ...addSepticData,
                company: '',
                address: ''
            });
        }else{
            setSepticAgentModal(false);

            setAddSepticAgentData({
                ...addSepticAgentData,
                officer_name: '',
                email: '',
                phone: ''
            });      
        }
    }

    const handleViewEscrow = async () => {
        let datos_escrow_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: ''
        };

        let datos_escrow_select = null;
        
        let datos_escrow_filter = [];

        let datos_escrow_agent_select = null;

        if(proyectoData.id_escrow !== null){
            let escrow_elegido = await escrows.escrows?.filter(escrow => String(escrow._id) === String(proyectoData.id_escrow));
            if(escrow_elegido.length > 0){
                datos_escrow_view = {
                    ...datos_escrow_view,
                    company: escrow_elegido[0].compania,
                    address: escrow_elegido[0].direccion || '',
                    agents: escrow_elegido[0].agentes
                };

                datos_escrow_select = {
                    value: escrow_elegido[0]._id,
                    label: escrow_elegido[0].compania
                };

                let options_escrow_agents = [];
                for (var i = 0; i < escrow_elegido[0].agentes?.length; i++) {
                    options_escrow_agents.push({ value: escrow_elegido[0].agentes[i]._id, label: escrow_elegido[0].agentes[i].officer_name });
                }
                datos_escrow_filter = options_escrow_agents;

                if(proyectoData.id_escrow_agent !== null){
                    let escrow_agent_elegido = await escrow_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_escrow_agent));
                    if(escrow_agent_elegido.length > 0){

                        datos_escrow_view = {
                            ...datos_escrow_view,
                            officer_name: escrow_agent_elegido[0].officer_name || '',
                            email: escrow_agent_elegido[0].email || '',
                            cell_phone: escrow_agent_elegido[0].cell_phone || '',
                            phone: escrow_agent_elegido[0].phone || ''
                        };

                        datos_escrow_agent_select = {
                            value: escrow_agent_elegido[0]._id,
                            label: escrow_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewEscrowData(datos_escrow_view);
        await setEscrowSelectData(datos_escrow_select);
        await setEscrowsAgentsFilter(datos_escrow_filter);
        await setEscrowAgentSelectData(datos_escrow_agent_select);
    }

    const handleViewBrokerBuyer = async () => {

        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_buyer !== null){
            let broker_buyer_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_buyer));
            if(broker_buyer_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_buyer_elegido[0].compania,
                    address: broker_buyer_elegido[0].direccion || '',
                    agents: broker_buyer_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_buyer_elegido[0]._id,
                    label: broker_buyer_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (var i = 0; i < broker_buyer_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_buyer_elegido[0].agentes[i]._id, label: broker_buyer_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_buyer !== null){
                    let broker_agent_elegido = await broker_buyer_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_buyer));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerBuyerData(datos_broker_view);
        await setBrokerBuyerSelectData(datos_broker_select);
        await setBrokersAgentsBuyerFilter(datos_broker_filter);
        await setBrokerAgentBuyerSelectData(datos_broker_agent_select);
    }

    const handleViewBrokerSeller = async () => {
        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_seller !== null){
            let broker_seller_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_seller));
            if(broker_seller_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_seller_elegido[0].compania,
                    address: broker_seller_elegido[0].direccion || '',
                    agents: broker_seller_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_seller_elegido[0]._id,
                    label: broker_seller_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (var i = 0; i < broker_seller_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_seller_elegido[0].agentes[i]._id, label: broker_seller_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_seller !== null){
                    let broker_agent_elegido = await broker_seller_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_seller));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerSellerData(datos_broker_view);
        await setBrokerSellerSelectData(datos_broker_select);
        await setBrokersAgentsSellerFilter(datos_broker_filter);
        await setBrokerAgentSellerSelectData(datos_broker_agent_select);
    }

    const handleViewTitle = async () => {
        let datos_title_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: ''
        };

        let datos_title_select = null;
        
        let datos_title_filter = [];

        let datos_title_agent_select = null;

        if(proyectoData.id_title !== null){
            let title_elegido = await titles.titles?.filter(title => String(title._id) === String(proyectoData.id_title));
            if(title_elegido.length > 0){
                datos_title_view = {
                    ...datos_title_view,
                    company: title_elegido[0].compania,
                    address: title_elegido[0].direccion || '',
                    agents: title_elegido[0].agentes
                };

                datos_title_select = {
                    value: title_elegido[0]._id,
                    label: title_elegido[0].compania
                };

                let options_title_agents = [];
                for (var i = 0; i < title_elegido[0].agentes?.length; i++) {
                    options_title_agents.push({ value: title_elegido[0].agentes[i]._id, label: title_elegido[0].agentes[i].officer_name });
                }
                datos_title_filter = options_title_agents;

                if(proyectoData.id_title_agent !== null){
                    let title_agent_elegido = await title_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_title_agent));
                    if(title_agent_elegido.length > 0){

                        datos_title_view = {
                            ...datos_title_view,
                            officer_name: title_agent_elegido[0].officer_name || '',
                            email: title_agent_elegido[0].email || '',
                            cell_phone: title_agent_elegido[0].cell_phone || '',
                            phone: title_agent_elegido[0].phone || ''
                        };

                        datos_title_agent_select = {
                            value: title_agent_elegido[0]._id,
                            label: title_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewTitleData(datos_title_view);
        await setTitleSelectData(datos_title_select);
        await setTitlesAgentsFilter(datos_title_filter);
        await setTitleAgentSelectData(datos_title_agent_select);
    }

    const handleViewTermite = async () => {
        let datos_termite_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            phone: ''
        };

        let datos_termite_select = null;
        
        let datos_termite_filter = [];

        let datos_termite_agent_select = null;

        if(proyectoData.id_termite !== null){
            let termite_elegido = await termites.termites?.filter(termite => String(termite._id) === String(proyectoData.id_termite));
            if(termite_elegido.length > 0){
                datos_termite_view = {
                    ...datos_termite_view,
                    company: termite_elegido[0].compania,
                    address: termite_elegido[0].direccion || '',
                    agents: termite_elegido[0].agentes
                };

                datos_termite_select = {
                    value: termite_elegido[0]._id,
                    label: termite_elegido[0].compania
                };

                let options_termite_agents = [];
                for (var i = 0; i < termite_elegido[0].agentes?.length; i++) {
                    options_termite_agents.push({ value: termite_elegido[0].agentes[i]._id, label: termite_elegido[0].agentes[i].officer_name });
                }
                datos_termite_filter = options_termite_agents;

                if(proyectoData.id_termite_agent !== null){
                    let termite_agent_elegido = await termite_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_termite_agent));
                    if(termite_agent_elegido.length > 0){

                        datos_termite_view = {
                            ...datos_termite_view,
                            officer_name: termite_agent_elegido[0].officer_name || '',
                            email: termite_agent_elegido[0].email || '',
                            phone: termite_agent_elegido[0].phone || ''
                        };

                        datos_termite_agent_select = {
                            value: termite_agent_elegido[0]._id,
                            label: termite_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewTermiteData(datos_termite_view);
        await setTermiteSelectData(datos_termite_select);
        await setTermitesAgentsFilter(datos_termite_filter);
        await setTermiteAgentSelectData(datos_termite_agent_select);
    }

    const handleViewSeptic = async () => {
        let datos_septic_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            phone: ''
        };

        let datos_septic_select = null;
        
        let datos_septic_filter = [];

        let datos_septic_agent_select = null;

        if(proyectoData.id_septic !== null){
            let septic_elegido = await septics.septics?.filter(septic => String(septic._id) === String(proyectoData.id_septic));
            if(septic_elegido.length > 0){
                datos_septic_view = {
                    ...datos_septic_view,
                    company: septic_elegido[0].compania,
                    address: septic_elegido[0].direccion || '',
                    agents: septic_elegido[0].agentes
                };

                datos_septic_select = {
                    value: septic_elegido[0]._id,
                    label: septic_elegido[0].compania
                };

                let options_septic_agents = [];
                for (var i = 0; i < septic_elegido[0].agentes?.length; i++) {
                    options_septic_agents.push({ value: septic_elegido[0].agentes[i]._id, label: septic_elegido[0].agentes[i].officer_name });
                }
                datos_septic_filter = options_septic_agents;

                if(proyectoData.id_septic_agent !== null){
                    let septic_agent_elegido = await septic_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_septic_agent));
                    if(septic_agent_elegido.length > 0){

                        datos_septic_view = {
                            ...datos_septic_view,
                            officer_name: septic_agent_elegido[0].officer_name || '',
                            email: septic_agent_elegido[0].email || '',
                            phone: septic_agent_elegido[0].phone || ''
                        };

                        datos_septic_agent_select = {
                            value: septic_agent_elegido[0]._id,
                            label: septic_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewSepticData(datos_septic_view);
        await setSepticSelectData(datos_septic_select);
        await setSepticsAgentsFilter(datos_septic_filter);
        await setSepticAgentSelectData(datos_septic_agent_select);
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    const handleViewFiles = (files) => {
        if(files.length > 0){
            for(let i=0; i<files.length; i++){
                if(files[i]){
                    const url = URL.createObjectURL(files[i]);
                    window.open(url);
                }
            }
        }else{
            dispatch(setAlert('No files selected', 'danger'));
        }
    }

    const handleInputsAddEscrow = ({ target }) => {
        setAddEscrowData({
            ...addEscrowData,
            [target.name]: target.value
        });
    }

    const handleInputsAddEscrowAgent = ({ target }) => {
        setAddEscrowAgentData({
            ...addEscrowAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerBuyer = ({ target }) => {
        setAddBrokerBuyerData({
            ...addBrokerBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentBuyer = ({ target }) => {
        setAddBrokerAgentBuyerData({
            ...addBrokerAgentBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerSeller = ({ target }) => {
        setAddBrokerSellerData({
            ...addBrokerSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentSeller = ({ target }) => {
        setAddBrokerAgentSellerData({
            ...addBrokerAgentSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTitle = ({ target }) => {
        setAddTitleData({
            ...addTitleData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTitleAgent = ({ target }) => {
        setAddTitleAgentData({
            ...addTitleAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTermite = ({ target }) => {
        setAddTermiteData({
            ...addTermiteData,
            [target.name]: target.value
        });
    }

    const handleInputsAddTermiteAgent = ({ target }) => {
        setAddTermiteAgentData({
            ...addTermiteAgentData,
            [target.name]: target.value
        });
    }

    const handleInputsAddSeptic = ({ target }) => {
        setAddSepticData({
            ...addSepticData,
            [target.name]: target.value
        });
    }

    const handleInputsAddSepticAgent = ({ target }) => {
        setAddSepticAgentData({
            ...addSepticAgentData,
            [target.name]: target.value
        });
    }

    const handleChangeAddress = async (address, origin) => {
        switch(origin){
            case 'escrow':
                await setAddEscrowData({
                    ...addEscrowData,
                    address: address
                });
                break;

            case 'broker_seller':
                await setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    address: address
                });
                break;

            case 'broker_buyer':
                await setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    address: address
                });
                break;

            case 'title':
                await setAddTitleData({
                    ...addTitleData,
                    address: address
                });
                break;

            case 'termite':
                await setAddTermiteData({
                    ...addTermiteData,
                    address: address
                });
                break;

            case 'septic':
                await setAddSepticData({
                    ...addSepticData,
                    address: address
                });
                break;

            default:
                break;
        }
    };

    const handleAddEscrow = async (e) => {
        e.preventDefault();
        if(addEscrowData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarEscrowMasterlist(addEscrowData, 2));

            await setProyectoData({
                ...proyectoData,
                id_escrow: proyectos.masterlist?.id_escrow_sale
            });

            await dispatch(guardarCambios(true));

            await setEscrowSelectData({
                value: proyectos.masterlist?.id_escrow_sale,
                label: addEscrowData.company
            });

            await setAddEscrowData({
                ...addEscrowData,
                company: '',
                address: ''
            });

            await setEscrowModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddEscrowAgent = async (e) => {
        e.preventDefault();
        if(addEscrowAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarEscrowAgentMasterlist(addEscrowAgentData, 2));

            await setProyectoData({
                ...proyectoData,
                id_escrow: proyectos.masterlist?.id_escrow_sale,
                id_escrow_agent: proyectos.masterlist?.id_escrow_agent_sale
            });

            await dispatch(guardarCambios(true));

            if(addEscrowAgentData.id_escrow === null){
                await setEscrowSelectData({
                    value: proyectos.masterlist?.id_escrow_sale,
                    label: 'Without name'
                });
            }

            await setEscrowAgentSelectData({
                value: proyectos.masterlist?.id_escrow_agent_sale,
                label: addEscrowAgentData.officer_name
            });

            await setAddEscrowAgentData({
                ...addEscrowAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setEscrowAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddBrokerSeller = async (e) => {
        e.preventDefault();
        if(addBrokerSellerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistSale(addBrokerSellerData, 2));

            await setProyectoData({
                ...proyectoData,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_sale
            });

            await dispatch(guardarCambios(true));

            await setBrokerSellerSelectData({
                value: proyectos.masterlist?.id_broker_seller_sale,
                label: addBrokerSellerData.company
            });

            await setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });

            await setBrokerSellerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentSeller = async (e) => {
        e.preventDefault();
        if(addBrokerAgentSellerData.officer_name.replace(/\s/g, '') !== ''){
            if(addBrokerAgentSellerData.license.replace(/\s/g, '') !== ''){
                await dispatch(agregarBrokerAgentMasterlistSale(addBrokerAgentSellerData, 2));

                await setProyectoData({
                    ...proyectoData,
                    id_broker_seller: proyectos.masterlist?.id_broker_seller_sale,
                    id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_sale
                });

                await dispatch(guardarCambios(true));

                if(addBrokerAgentSellerData.id_broker === null){
                    await setBrokerSellerSelectData({
                        value: proyectos.masterlist?.id_broker_seller_sale,
                        label: 'Without name'
                    });
                }

                await setBrokerAgentSellerSelectData({
                    value: proyectos.masterlist?.id_broker_agent_seller_sale,
                    label: addBrokerAgentSellerData.officer_name
                });

                await setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });

                await setBrokerAgentSellerModal(false);
            }else{
                dispatch(setAlert('First enter a license numer', 'warning'));
            }
        }else{
            dispatch(setAlert('First enter an agent name', 'warning'));
        }
    }

    const handleAddBrokerBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerBuyerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistSale(addBrokerBuyerData, 1));

            await setProyectoData({
                ...proyectoData,
                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_sale
            });

            await dispatch(guardarCambios(true));

            await setBrokerBuyerSelectData({
                value: proyectos.masterlist?.id_broker_buyer_sale,
                label: addBrokerBuyerData.company
            });

            await setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });

            await setBrokerBuyerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerAgentBuyerData.officer_name.replace(/\s/g, '') !== ''){
            if(addBrokerAgentBuyerData.license.replace(/\s/g, '') !== ''){
                await dispatch(agregarBrokerAgentMasterlistSale(addBrokerAgentBuyerData, 1));

                await setProyectoData({
                    ...proyectoData,
                    id_broker_buyer: proyectos.masterlist?.id_broker_buyer_sale,
                    id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_sale
                });

                await dispatch(guardarCambios(true));

                if(addBrokerAgentBuyerData.id_broker === null){
                    await setBrokerBuyerSelectData({
                        value: proyectos.masterlist?.id_broker_buyer_sale,
                        label: 'Without name'
                    });
                }

                await setBrokerAgentBuyerSelectData({
                    value: proyectos.masterlist?.id_broker_agent_buyer_sale,
                    label: addBrokerAgentBuyerData.officer_name
                });

                await setAddBrokerAgentBuyerData({
                    ...addBrokerAgentBuyerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });

                await setBrokerAgentBuyerModal(false);
            }else{
                dispatch(setAlert('First enter a license number', 'warning'));
            }
        }else{
            dispatch(setAlert('First enter an agent name', 'warning'));
        }
    }

    const handleAddTitle = async (e) => {
        e.preventDefault();
        if(addTitleData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarTitleMasterlistSale(addTitleData));

            await setProyectoData({
                ...proyectoData,
                id_title: proyectos.masterlist?.id_title_sale
            });

            await dispatch(guardarCambios(true));

            await setTitleSelectData({
                value: proyectos.masterlist?.id_title_sale,
                label: addTitleData.company
            });

            await setAddTitleData({
                ...addTitleData,
                company: '',
                address: ''
            });

            await setTitleModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddTitleAgent = async (e) => {
        e.preventDefault();
        if(addTitleAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarTitleAgentMasterlist(addTitleAgentData, 2));

            await setProyectoData({
                ...proyectoData,
                id_title: proyectos.masterlist?.id_title_sale,
                id_title_agent: proyectos.masterlist?.id_title_agent_sale
            });

            await dispatch(guardarCambios(true));

            if(addTitleAgentData.id_title === null){
                await setTitleSelectData({
                    value: proyectos.masterlist?.id_title_sale,
                    label: 'Without name'
                });
            }

            await setTitleAgentSelectData({
                value: proyectos.masterlist?.id_title_agent_sale,
                label: addTitleAgentData.officer_name
            });

            await setAddTitleAgentData({
                ...addTitleAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setTitleAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddTermite = async (e) => {
        e.preventDefault();
        if(addTermiteData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarTermiteMasterlistSale(addTermiteData));

            await setProyectoData({
                ...proyectoData,
                id_termite: proyectos.masterlist?.id_termite_sale
            });

            await dispatch(guardarCambios(true));

            await setTermiteSelectData({
                value: proyectos.masterlist?.id_termite_sale,
                label: addTermiteData.company
            });

            await setAddTermiteData({
                ...addTermiteData,
                company: '',
                address: ''
            });

            await setTermiteModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddTermiteAgent = async (e) => {
        e.preventDefault();
        if(addTermiteAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarTermiteAgentMasterlist(addTermiteAgentData, 2));

            await setProyectoData({
                ...proyectoData,
                id_termite: proyectos.masterlist?.id_termite_sale,
                id_termite_agent: proyectos.masterlist?.id_termite_agent_sale
            });

            await dispatch(guardarCambios(true));

            if(addTermiteAgentData.id_termite === null){
                await setTermiteSelectData({
                    value: proyectos.masterlist?.id_termite_sale,
                    label: 'Without name'
                });
            }

            await setTermiteAgentSelectData({
                value: proyectos.masterlist?.id_termite_agent_sale,
                label: addTermiteAgentData.officer_name
            });

            await setAddTermiteAgentData({
                ...addTermiteAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setTermiteAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddSeptic = async (e) => {
        e.preventDefault();
        if(addSepticData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarSepticMasterlistSale(addSepticData));

            await setProyectoData({
                ...proyectoData,
                id_septic: proyectos.masterlist?.id_septic_sale
            });

            await dispatch(guardarCambios(true));

            await setSepticSelectData({
                value: proyectos.masterlist?.id_septic_sale,
                label: addSepticData.company
            });

            await setAddSepticData({
                ...addSepticData,
                company: '',
                address: ''
            });

            await setSepticModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddSepticAgent = async (e) => {
        e.preventDefault();
        if(addSepticAgentData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarSepticAgentMasterlist(addSepticAgentData, 2));

            await setProyectoData({
                ...proyectoData,
                id_septic: proyectos.masterlist?.id_septic_sale,
                id_septic_agent: proyectos.masterlist?.id_septic_agent_sale
            });

            await dispatch(guardarCambios(true));

            if(addSepticAgentData.id_septic === null){
                await setSepticSelectData({
                    value: proyectos.masterlist?.id_septic_sale,
                    label: 'Without name'
                });
            }

            await setSepticAgentSelectData({
                value: proyectos.masterlist?.id_septic_agent_sale,
                label: addSepticAgentData.officer_name
            });

            await setAddSepticAgentData({
                ...addSepticAgentData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: ''
            });

            await setSepticAgentModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const numberToDecimal = (number, price = '$') => {
        if(isNaN(number)){
            return price + ' -';
        }else{
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = price + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }
    }

    const handleRenegotiation = () => {
        console.log(proyectos.masterlist?.sales_price);
        console.log(proyectos.masterlist?.closing);
        console.log(proyectos.masterlist?.due_dilligence_expiration);
        if(proyectos.masterlist?.sales_price !== null && proyectos.masterlist?.closing !== null && proyectos.masterlist?.due_dilligence_expiration !== null){
            setRenegotiationModal(true);
        }else{
            dispatch(setAlert('Complete all fields to renegotiate', 'warning'));
        }
    }

    const handleCloseModalRenegotiation = () => {
        setRenegotiationModal(false);
        setRenegotiationData({
            ...renegotiationData,
            sales_price: null,
            closing: null,
            due_dilligence_expiration: null,
            escrow_deposit_increase: null,
            escrow_deposit_release: null,
            funds_applied_sale_price: null
        });
    }

    const handleInputsRenegotiation = ({ target }) => {
        setRenegotiationData({
            ...renegotiationData,
            [target.name]: target.value
        });
    }

    const handleInputsNumberRenegotiation = (name, value) => {
        setRenegotiationData({
            ...renegotiationData,
            [name]: value
        });
    }

    const handleRadiobuttonRenegotiation = ({ target }) => {
        if(target.checked){
            let valores = {
                ...renegotiationData,
                [target.name]: target.value === 1 || target.value === '1' ? true : false
            };

            if(target.name === 'escrow_deposit_increase'){
                valores.escrow_deposit_release = null;
                valores.funds_applied_sale_price = null;
            }

            if(target.name === 'escrow_deposit_release'){
                valores.funds_applied_sale_price = null;
            }

            setRenegotiationData(valores);
        }
    }

    const onChangeFileSalesPriceRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < sales_price_ref?.current?.files?.length; i++){
            let nombre_separado = sales_price_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            sales_price_file: sales_price_ref.current.files,
            sales_price_extension: extensiones
        });
    }

    const onChangeFileClosingRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < closing_ref?.current?.files?.length; i++){
            let nombre_separado = closing_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            closing_file: closing_ref.current.files,
            closing_extension: extensiones
        });
    }

    const onChangeFileDueDilligenceExpirationRenegotiation = () => {
        let extensiones = [];

        for(let i = 0; i < due_dilligence_expiration_ref?.current?.files?.length; i++){
            let nombre_separado = due_dilligence_expiration_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            due_dilligence_expiration_file: due_dilligence_expiration_ref.current.files,
            due_dilligence_expiration_extension: extensiones
        });
    }

    const handleSubmitRenegotiation = async (e) => {
        e.preventDefault();
        setSpinner(true);
        if(renegotiationData.sales_price !== null || renegotiationData.closing !== null || renegotiationData.due_dilligence_expiration !== null){
            let formData = new FormData();
            formData.append('id_proyecto', renegotiationData.id_proyecto);
            formData.append('id_status', renegotiationData.id_status);
            formData.append('sales_price', renegotiationData.sales_price);
            formData.append('sales_price_date', renegotiationData.sales_price_date);
            formData.append('sales_price_note', renegotiationData.sales_price_note);
            formData.append('sales_price_file_name', renegotiationData.sales_price_file_name);

            if(renegotiationData.sales_price_extension !== null){
                for (let i = 0; i < renegotiationData.sales_price_extension.length; i++) {
                    formData.append('sales_price_extension', renegotiationData.sales_price_extension[i]);
                }   
            }

            if(renegotiationData.sales_price_file !== null){
                for (let i = 0; i < renegotiationData.sales_price_file.length; i++) {
                    formData.append('sales_price_file', renegotiationData.sales_price_file[i]);
                }   
            }

            formData.append('closing', renegotiationData.closing);
            formData.append('closing_date', renegotiationData.closing_date);
            formData.append('closing_note', renegotiationData.closing_note);
            formData.append('closing_file_name', renegotiationData.closing_file_name);

            if(renegotiationData.closing_extension !== null){
                for (let i = 0; i < renegotiationData.closing_extension.length; i++) {
                    formData.append('closing_extension', renegotiationData.closing_extension[i]);
                }   
            }

            if(renegotiationData.closing_file !== null){
                for (let i = 0; i < renegotiationData.closing_file.length; i++) {
                    formData.append('closing_file', renegotiationData.closing_file[i]);
                }   
            }

            formData.append('due_dilligence_expiration', renegotiationData.due_dilligence_expiration);
            formData.append('due_dilligence_expiration_date', renegotiationData.due_dilligence_expiration_date);
            formData.append('due_dilligence_expiration_note', renegotiationData.due_dilligence_expiration_note);
            formData.append('due_dilligence_expiration_file_name', renegotiationData.due_dilligence_expiration_file_name);

            if(renegotiationData.due_dilligence_expiration_extension !== null){
                for (let i = 0; i < renegotiationData.due_dilligence_expiration_extension.length; i++) {
                    formData.append('due_dilligence_expiration_extension', renegotiationData.due_dilligence_expiration_extension[i]);
                }   
            }

            if(renegotiationData.due_dilligence_expiration_file !== null){
                for (let i = 0; i < renegotiationData.due_dilligence_expiration_file.length; i++) {
                    formData.append('due_dilligence_expiration_file', renegotiationData.due_dilligence_expiration_file[i]);
                }   
            }

            formData.append('escrow_deposit_increase', renegotiationData.escrow_deposit_increase);
            formData.append('escrow_deposit_release', renegotiationData.escrow_deposit_release);
            formData.append('funds_applied_sale_price', renegotiationData.funds_applied_sale_price);

            dispatch(renegotiateSellsInEscrow(formData));

            setProyectoData({
                ...proyectoData,
                sales_price: renegotiationData.sales_price !== null ? renegotiationData.sales_price : proyectoData.sales_price,
                closing: renegotiationData.closing !== null ? renegotiationData.closing : proyectoData.closing,
                due_dilligence_expiration: renegotiationData.due_dilligence_expiration !== null ? renegotiationData.due_dilligence_expiration : proyectoData.due_dilligence_expiration,
                sales_price_history: [
                    ...proyectoData.sales_price_history,
                    {
                        amount: renegotiationData.sales_price,
                        fecha: renegotiationData.sales_price_date !== null ? new Date(renegotiationData.sales_price_date) : new Date()
                    }
                ],
                due_dilligence_expiration_history: [
                    ...proyectoData.due_dilligence_expiration_history,
                    {
                        date: renegotiationData.due_dilligence_expiration,
                        escrow_deposit_increase: renegotiationData.escrow_deposit_increase,
                        escrow_deposit_release: renegotiationData.escrow_deposit_release,
                        funds_applied_sale_price: renegotiationData.funds_applied_sale_price,
                        fecha: renegotiationData.closing_date !== null ? new Date(renegotiationData.closing_date) : new Date()
                    }
                ],
                closing_history: [
                    ...proyectoData.closing_history,
                    {
                        date: renegotiationData.closing,
                        fecha: renegotiationData.due_dilligence_expiration_date !== null ? new Date(renegotiationData.due_dilligence_expiration_date) : new Date()
                    }
                ]
            });

            setTimeout(async() => {
                dispatch(guardarCambios(true));
                setSpinner(false);
            }, 0);

            setRenegotiationData({
                ...renegotiationData,
                sales_price: null,
                sales_price_date: null,
                sales_price_note: null,
                sales_price_file_name: null,
                sales_price_file: null,
                sales_price_extension: null,
                closing: null,
                closing_date: null,
                closing_note: null,
                closing_file_name: null,
                closing_file: null,
                closing_extension: null,
                due_dilligence_expiration: null,
                due_dilligence_expiration_date: null,
                due_dilligence_expiration_note: null,
                due_dilligence_expiration_file_name: null,
                due_dilligence_expiration_file: null,
                due_dilligence_expiration_extension: null,
                escrow_deposit_increase: null,
                escrow_deposit_release: null,
                funds_applied_sale_price: null
            });
        }else{
            dispatch(setAlert('Enter at least one field to renegotiate', 'warning'));
            setSpinner(false);
        }
    }

    const handleSubmitModal = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarSellsInEscrow(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        
        dispatch(redireccionar(proyectos.paginaRedireccion));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarSellsInEscrow(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const [spinner, setSpinner] = useState(false);

    const handleContinue = async (e) => {
        e.preventDefault();

        if (proyectoData.sales_price !== null && proyectoData.due_dilligence_expiration !== null && proyectoData.closing !== null) {
            setSpinner(true);
            setTimeout(async() => {
                await setGuardarCambiosModal(false);
                await dispatch(continuarSellsInEscrow(proyectoData));
                await dispatch(masterlistProyecto(proyectoData.id));
                await dispatch(detallesProyecto(proyectoData.id));
                setSpinner(false);
                await dispatch(seleccionarSeccionMasterList('Sold property'));
            }, 0);
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    };

    const [mostrarBoton, setMostrarBoton] = useState(false);

    const focusCampo = ({target}) => {
        let id_focusear = target.dataset.focus;
        let campo = document.getElementById(id_focusear);
        campo.focus();  
    };

    // Tabla
    const tableRow = (items) => {
        if(items !== null){

            items = items.filter(archivo => (archivo.subseccion !== null && (archivo.subseccion * 2) === (sectionInformation - 1)));

            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td>{item.nombre || '-'}</td>
                                            <td>{item.extension || '-'}</td>
                                            <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                            <td>
                                                <button className="btn" type="button" onClick={() => { handleIconoArchivo(item._id) }}><i className="fa fa-file-download" style={styles.buttonIcon} /></button>
                                                <button className="btn" type="button" onClick={() => { handleEditarArchivo(item) }}><i className="fas fa-edit" style={styles.buttonIcon} /></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    useEffect(() => {
        if (proyectoData.sales_price !== null && proyectoData.due_dilligence_expiration !== null && proyectoData.closing !== null) {
            setMostrarBoton(true);
        } else { 
            setMostrarBoton(false);
        }

        handleViewEscrow();

        handleViewBrokerSeller();

        handleViewBrokerBuyer();

        handleViewTitle();

        handleViewTermite();

        handleViewSeptic();
    }, [proyectoData]);
    
    useEffect(() => {

        let options_escrow = [];
        let options_broker = [];
        let options_title = [];
        let options_termite = [];
        let options_septic = [];
        let options_usuario = [];

        for (let i = 0; i < escrows.escrows?.length; i++) {
            options_escrow.push({ value: escrows.escrows[i]._id, label: escrows.escrows[i].compania });
        }

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options_broker.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        for (let i = 0; i < titles.titles?.length; i++) {
            options_title.push({ value: titles.titles[i]._id, label: titles.titles[i].compania });
        }

        for (let i = 0; i < termites.termites?.length; i++) {
            options_termite.push({ value: termites.termites[i]._id, label: termites.termites[i].compania });
        }

        for (let i = 0; i < septics.septics?.length; i++) {
            options_septic.push({ value: septics.septics[i]._id, label: septics.septics[i].compania });
        }

        for (let i = 0; i < usuarios?.length; i++) {
            options_usuario.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setEscrowsFilter(options_escrow);
        setBrokersFilter(options_broker);
        setTitlesFilter(options_title);
        setTermitesFilter(options_termite);
        setSepticsFilter(options_septic);
        setUsersFilter(options_usuario);
    }, []);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < escrows.escrows?.length; i++) {
            options.push({ value: escrows.escrows[i]._id, label: escrows.escrows[i].compania });
        }

        setEscrowsFilter(options);
    }, [escrows]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < brokers.brokers?.length; i++) {
            options.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options);
    }, [brokers]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < titles.titles?.length; i++) {
            options.push({ value: titles.titles[i]._id, label: titles.titles[i].compania });
        }

        setTitlesFilter(options);
    }, [titles]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < termites.termites?.length; i++) {
            options.push({ value: termites.termites[i]._id, label: termites.termites[i].compania });
        }

        setTermitesFilter(options);
    }, [termites]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < septics.septics?.length; i++) {
            options.push({ value: septics.septics[i]._id, label: septics.septics[i].compania });
        }

        setSepticsFilter(options);
    }, [septics]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {

        if (proyectos.detalles !== undefined && proyectos.detalles !== null) {
            setNewFileData({
                ...newFileData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(!proyectos.guardar_cambios){
            let dias_separacion = 0;
            if(proyectos.masterlist?.contract_sale !== null && proyectos.masterlist?.due_dilligence_expiration !== null){
                dias_separacion = new Date(proyectos.masterlist?.due_dilligence_expiration).getTime() - new Date(proyectos.masterlist?.contract_sale).getTime();
                dias_separacion = Math.floor(dias_separacion / (1000 * 3600 * 24));
            }

            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id,
                sales_price: proyectos.masterlist?.sales_price,
                contract_sale: proyectos.masterlist?.contract_sale,
                escrow_deposit_amount: proyectos.masterlist?.escrow_deposit_amount_sale,
                buys_help: proyectos.masterlist?.buys_help,
                dilligence_days: dias_separacion,
                due_dilligence_expiration: proyectos.masterlist?.due_dilligence_expiration,
                inspection: proyectos.masterlist?.inspection,
                closing: proyectos.masterlist?.closing,
                escrow_deposit_received_question: proyectos.masterlist?.escrow_deposit_received !== null ? true: false,
                name_escrow_deposit_received_user: proyectos.masterlist?.name_escrow_deposit_received_user,
                escrow_deposit_received_user: proyectos.masterlist?.escrow_deposit_received_user,
                escrow_deposit_received: proyectos.masterlist?.escrow_deposit_received,
                
                id_escrow: proyectos.masterlist?.id_escrow_sale,
                id_escrow_agent: proyectos.masterlist?.id_escrow_agent_sale,
                escrow_instructions: proyectos.masterlist?.escrow_instructions_sale,
                escrow_instructions_date: proyectos.masterlist?.escrow_instructions_date_sale,
                escrow_number: proyectos.masterlist?.escrow_number_sale,
                
                id_broker_seller: proyectos.masterlist?.id_broker_seller_sale,
                id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_sale,                
                broker_represent: proyectos.masterlist?.broker_represent_sale,
                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_sale,
                id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_sale,
                
                id_title: proyectos.masterlist?.id_title_sale,
                id_title_agent: proyectos.masterlist?.id_title_agent_sale,                
                title_preliminary: proyectos.masterlist?.title_preliminary_sale,
                title_preliminary_date: proyectos.masterlist?.title_preliminary_date_sale,
                title_review: proyectos.masterlist?.title_review_sale,
                title_review_who: proyectos.masterlist?.title_review_who_sale,
                title_rep: proyectos.masterlist?.title_rep_sale,
                title_order: proyectos.masterlist?.title_order_sale,

                id_termite: proyectos.masterlist?.id_termite_sale,
                id_termite_agent: proyectos.masterlist?.id_termite_agent_sale,
                termite_inspection: proyectos.masterlist?.termite_inspection_sale,
                name_termite_inspection_user: proyectos.masterlist?.name_termite_inspection_user_sale,
                termite_inspection_user: proyectos.masterlist?.termite_inspection_user_sale,
                termite_inspection_date: proyectos.masterlist?.termite_inspection_date_sale,
                report_number_termite: proyectos.masterlist?.report_number_termite_sale,
                
                id_septic: proyectos.masterlist?.id_septic_sale,
                id_septic_agent: proyectos.masterlist?.id_septic_agent_sale,
                septic_inspection: proyectos.masterlist?.septic_inspection_sale,
                name_septic_inspection_user: proyectos.masterlist?.name_septic_inspection_user_sale,
                septic_inspection_user: proyectos.masterlist?.septic_inspection_user_sale,
                septic_inspection_date: proyectos.masterlist?.septic_inspection_date_sale,
                report_number_septic: proyectos.masterlist?.report_number_septic_sale,

                sales_price_history: proyectos.masterlist?.sales_price_history,
                due_dilligence_expiration_history: proyectos.masterlist?.due_dilligence_expiration_history,
                closing_history: proyectos.masterlist?.closing_history
            });

            setDilligenceDays(dias_separacion);

            if(proyectos.masterlist?.escrow_deposit_received_user !== null){
                setUserEscrowDepositSelectData({
                    value: proyectos.masterlist?.escrow_deposit_received_user,
                    label: proyectos.masterlist?.name_escrow_deposit_received_user
                });
            }else{
                setUserEscrowDepositSelectData(null);
            }

            if(proyectos.masterlist?.termite_inspection_user_sale !== null){
                setUserTermiteSelectData({
                    value: proyectos.masterlist?.termite_inspection_user_sale,
                    label: proyectos.masterlist?.name_termite_inspection_user_sale
                });
            }else{
                setUserTermiteSelectData(null);
            }

            if(proyectos.masterlist?.septic_inspection_user_sale !== null){
                setUserSepticSelectData({
                    value: proyectos.masterlist?.septic_inspection_user_sale,
                    label: proyectos.masterlist?.name_septic_inspection_user_sale
                });
            }else{
                setUserSepticSelectData(null);
            }

            setDataTabla({
                archivosTabla: proyectos.files
            });

            setRenegotiationData({
                ...renegotiationData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(proyectos.modal_guardado_flipping === true){
            handleGuardarCambiosModal();
        }

    }, [proyectos]);

    //Funcion copiado buyer
    useEffect(() => {
        if(copiar){
            handleCopyBroker()
        }
    }, [copiar]);

    //State para preguntar si salir del modal
    const [preguntarGuardar, setPreguntarGuardar] = useState(false);

    return (
        <Fragment>
            {/* Modal de guardado de cambios */}
            <Modal backdrop="static" show={guardarCambiosModal} onHide={() => {
                if(preguntarGuardar){
                    handleCloseGuardarCambiosModal();
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit?</p>
                    :
                        <p>Do you want to save the changes?</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                            <>
                                <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                                <button className="btn btn-success" onClick={() => handleCloseNoGuardarCambiosModal()}>Yes</button>
                            </>
                        :
                            <>
                                <button className="btn btn-danger mr-3" onClick={() => handleCloseNoGuardarCambiosModal()}>No</button>
                                <button className="btn btn-success" onClick={handleSubmitModal}>Yes</button>
                            </>
                    }
                </Modal.Footer>
            </Modal>

            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}
            
            {/* Modal de escrow de venta */}
            <Modal backdrop="static" show={escrowModal} size="lg" onHide={() => {
                setEscrowModal(false);
                setAddEscrowData({
                    ...addEscrowData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Escrow information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the escrow you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddEscrow(e)}/>
                            </div>
                        </div>
                        <PlacesAutocomplete
                            value={addEscrowData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'escrow')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalEscrow(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddEscrow}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de escrow agente de compra */}
            <Modal backdrop="static" show={escrowAgentModal} size="lg" onHide={() => {
                setEscrowAgentModal(false);
                setAddEscrowAgentData({
                    ...addEscrowAgentData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Escrow agent information ({escrowSelectData ? escrowSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddEscrowAgent(e)} value={addEscrowAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddEscrowAgent(e)} value={addEscrowAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addEscrowAgentData.cell_phone} onChange={phone => {setAddEscrowAgentData({...addEscrowAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addEscrowAgentData.phone} onChange={phone => {setAddEscrowAgentData({...addEscrowAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalEscrow(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddEscrowAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker vendedor de venta */}
            <Modal backdrop="static" show={brokerSellerModal} size="lg" onHide={() => {
                setBrokerSellerModal(false);
                setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Listing Broker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerSeller(e)}/>
                            </div>
                        </div>
                        <PlacesAutocomplete
                            value={addBrokerSellerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_seller')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente vendedor de venta */}
            <Modal backdrop="static" show={brokerAgentSellerModal} size="lg" onHide={() => {
                setBrokerAgentSellerModal(false);
                setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (listing) ({brokerSellerSelectData ? brokerSellerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.celular} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.telefono} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker comprador de venta */}
            <Modal backdrop="static" show={brokerBuyerModal} size="lg" onHide={() => {
                setBrokerBuyerModal(false);
                setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (buy)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerBuyer(e)}/>
                            </div>
                        </div>
                        <PlacesAutocomplete
                            value={addBrokerBuyerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_buyer')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente comprador de venta */}
            <Modal backdrop="static" show={brokerAgentBuyerModal} size="lg" onHide={() => {
                setBrokerAgentBuyerModal(false);
                setAddBrokerAgentBuyerData({
                    ...addBrokerAgentBuyerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (buy) ({brokerBuyerSelectData ? brokerBuyerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.celular} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.telefono} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de title de venta */}
            <Modal backdrop="static" show={titleModal} size="lg" onHide={() => {
                setTitleModal(false);
                setAddTitleData({
                    ...addTitleData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Title information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the title you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddTitle(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addTitleData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'title')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTitle()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTitle}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de title agente de venta */}
            <Modal backdrop="static" show={titleAgentModal} size="lg" onHide={() => {
                setTitleAgentModal(false);
                setAddTitleAgentData({
                    ...addTitleAgentData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Title agent information ({titleSelectData ? titleSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddTitleAgent(e)} value={addTitleAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddTitleAgent(e)} value={addTitleAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="cell_phone" className="form-control" country={'us'} value={addTitleAgentData.cell_phone} onChange={phone => {setAddTitleAgentData({...addTitleAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addTitleAgentData.phone} onChange={phone => {setAddTitleAgentData({...addTitleAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTitle(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTitleAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de termite de venta */}
            <Modal backdrop="static" show={termiteModal} size="lg" onHide={() => {
                setTermiteModal(false);
                setAddTermiteData({
                    ...addTermiteData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Termite information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the termite you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddTermite(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addTermiteData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'termite')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTermite()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTermite}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de termite agente de venta */}
            <Modal backdrop="static" show={termiteAgentModal} size="lg" onHide={() => {
                setTermiteAgentModal(false);
                setAddTermiteAgentData({
                    ...addTermiteAgentData,
                    officer_name: '',
                    email: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Termite agent information ({termiteSelectData ? termiteSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddTermiteAgent(e)} value={addTermiteAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddTermiteAgent(e)} value={addTermiteAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addTermiteAgentData.phone} onChange={phone => {setAddTermiteAgentData({...addTermiteAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalTermite(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddTermiteAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de septic de venta */}
            <Modal backdrop="static" show={septicModal} size="lg" onHide={() => {
                setSepticModal(false);
                setAddSepticData({
                    ...addSepticData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Septic information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the septic you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddSeptic(e)}/>
                            </div>
                        </div>
                        
                        <PlacesAutocomplete
                            value={addSepticData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'septic')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalSeptic()}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddSeptic}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de septic agente de venta */}
            <Modal backdrop="static" show={septicAgentModal} size="lg" onHide={() => {
                setSepticAgentModal(false);
                setAddSepticAgentData({
                    ...addSepticAgentData,
                    officer_name: '',
                    email: '',
                    phone: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Septic agent information ({septicSelectData ? septicSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddSepticAgent(e)} value={addSepticAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddSepticAgent(e)} value={addSepticAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput autoComplete='off' name="phone" className="form-control" country={'us'} value={addSepticAgentData.phone} onChange={phone => {setAddSepticAgentData({...addSepticAgentData, phone: phone}); }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalSeptic(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddSepticAgent}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de renegociacion de venta */}
            <Modal backdrop="static" show={renegotiationModal} centered size="xl" onHide={() => {
                if(preguntarGuardar){
                    setRenegotiationModal(false);
                    setRenegotiationData({
                        ...renegotiationData,
                        sales_price: null,
                        closing: null,
                        due_dilligence_expiration: null,
                        escrow_deposit_increase: null,
                        escrow_deposit_release: null,
                        funds_applied_sale_price: null
                    });
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Renegotiation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit without saving changes?</p>
                    :
                    <>
                        { spinner ? 
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                    </div>
                                </div> 
                            :
                                null
                        }
                        <div className="row">
                            <div id="accordion-sells-in-escrow-sales_price" className="col-md-4 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New sales price:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseSalesPriceDate" aria-expanded="true" aria-controls="collapseSalesPriceDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseSalesPrice" aria-expanded="true" aria-controls="collapseSalesPrice"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseSalesPriceNote" aria-expanded="true" aria-controls="collapseSalesPriceNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-file-upload mr-2" data-toggle="collapse" data-target="#collapseSalesPriceFile" aria-expanded="true" aria-controls="collapseSalesPriceFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput
                                        name="sales_price"
                                        className="form-control text-center"
                                        value={renegotiationData.sales_price || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                        autoComplete='off'
                                    />
                                </div>

                                <div id="collapseSalesPriceDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-sales_price">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker monthsShown={2} name="sales_price_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.sales_price_date ? renegotiationData.sales_price_date : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, sales_price_date: date})}} />
                                    </div>        
                                </div>
                                
                                <div id="collapseSalesPrice" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-sales_price">
                                    <label key="asking_price_0" className="col-sm-12">
                                        <b className="ml-2">Listing price:</b> <span className="value_renegotiation">{ numberToDecimal(proyectos.masterlist?.listing_price, '$ ') }: </span> {new Date(proyectos.masterlist?.listing).toLocaleDateString('en-US')}
                                    </label>
                                    {   
                                        proyectoData.sales_price_history?.length > 0 ?
                                            proyectoData.sales_price_history?.map((sales_price, index, array) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={sales_price._id} className="col-sm-12">
                                                            <b className="ml-2">Sales price</b> <span className="value_renegotiation">{ numberToDecimal(sales_price.amount, '$ ') }: </span> {new Date(sales_price.fecha).toLocaleDateString('en-US')} 
                                                            {
                                                                sales_price.id_archivo !== null && sales_price.id_archivo !== undefined && sales_price.id_archivo?.length > 0 ?
                                                                    sales_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={sales_price._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{ numberToDecimal(sales_price.amount, '$ ') }: </span> {new Date(sales_price.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                sales_price.id_archivo !== null && sales_price.id_archivo !== undefined && sales_price.id_archivo?.length > 0 ?
                                                                    sales_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseSalesPriceNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-sales_price">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="sales_price_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseSalesPriceFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-sales_price">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="sales_price_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="sales_price_file" id="sales_price_file" ref={sales_price_ref} onChange={onChangeFileSalesPriceRenegotiation} aria-describedby="view_file_salesPriceFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.sales_price_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.sales_price_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div id="accordion-sells-in-escrow-closing" className="col-md-4 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New closing date:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseClosingDate" aria-expanded="true" aria-controls="collapseClosingDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseClosing" aria-expanded="true" aria-controls="collapseClosing"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt mr-2" data-toggle="collapse" data-target="#collapseClosingNote" aria-expanded="true" aria-controls="collapseClosingNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-file-upload mr-2" data-toggle="collapse" data-target="#collapseClosingFile" aria-expanded="true" aria-controls="collapseClosingFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker monthsShown={2} className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ renegotiationData.closing ? new Date(renegotiationData.closing) : '' } onChange={ date => setRenegotiationData({ ...renegotiationData, closing: date })}/>
                                    </div>
                                </div>

                                <div id="collapseClosingDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-closing">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker monthsShown={2} name="closing_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.closing_date ? renegotiationData.closing_date : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, closing_date: date})}} />
                                    </div>        
                                </div>

                                <div id="collapseClosing" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-closing">
                                    {
                                        proyectoData.closing_history?.length > 0 ?
                                            proyectoData.closing_history?.map((closing, index) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={closing._id} className="col-sm-12">
                                                            <b className="ml-2">Closing</b> <span className="value_renegotiation">{new Date(closing.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(closing.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                closing.id_archivo !== null && closing.id_archivo !== undefined && closing.id_archivo?.length > 0 ?
                                                                    closing.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={closing._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{new Date(closing.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(closing.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                closing.id_archivo !== null && closing.id_archivo !== undefined && closing.id_archivo?.length > 0 ?
                                                                    closing.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseClosingNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-closing">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="closing_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseClosingFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-closing">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="closing_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="closing_file" id="closing_file" ref={closing_ref} onChange={onChangeFileClosingRenegotiation} aria-describedby="view_file_closingFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.closing_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.closing_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div id="accordion-sells-in-escrow-due_dilligence_expiration" className="col-md-4 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100 label_renegotiation">New due dilligence expiration date:</label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseDueDilligenceExpirationDateDate" aria-expanded="true" aria-controls="collapseDueDilligenceExpirationDateDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseDueDilligenceExpirationDate" aria-expanded="true" aria-controls="collapseDueDilligenceExpirationDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt mr-2" data-toggle="collapse" data-target="#collapseDueDilligenceExpirationDateNote" aria-expanded="true" aria-controls="collapseDueDilligenceExpirationDateNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-file-upload mr-2" data-toggle="collapse" data-target="#collapseDueDilligenceExpirationDateFile" aria-expanded="true" aria-controls="collapseDueDilligenceExpirationDateFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker monthsShown={2} className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ renegotiationData.due_dilligence_expiration ? new Date(renegotiationData.due_dilligence_expiration) : '' } onChange={ date => setRenegotiationData({ ...renegotiationData, due_dilligence_expiration: date })}/>
                                    </div>
                                </div>

                                <div id="collapseDueDilligenceExpirationDateDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-due_dilligence_expiration">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker monthsShown={2} name="due_dilligence_expiration_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.due_dilligence_expiration_date ? renegotiationData.due_dilligence_expiration_date : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, due_dilligence_expiration_date: date})}} />
                                    </div>
                                </div>

                                <div id="collapseDueDilligenceExpirationDate" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-due_dilligence_expiration">
                                    {
                                        proyectoData.due_dilligence_expiration_history?.length > 0 ?
                                            proyectoData.due_dilligence_expiration_history?.map((due_dilligence_expiration, index) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={due_dilligence_expiration._id} className="col-sm-12">
                                                            <b className="ml-2">Expiration</b> <span className="value_renegotiation">{new Date(due_dilligence_expiration.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(due_dilligence_expiration.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                due_dilligence_expiration.id_archivo !== null && due_dilligence_expiration.id_archivo !== undefined && due_dilligence_expiration.id_archivo?.length > 0 ?
                                                                    due_dilligence_expiration.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={due_dilligence_expiration._id} className="col-sm-12">
                                                            <b className="ml-2">Counter {index}°</b> <span className="value_renegotiation">{new Date(due_dilligence_expiration.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: </span> {new Date(due_dilligence_expiration.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                due_dilligence_expiration.id_archivo !== null && due_dilligence_expiration.id_archivo !== undefined && due_dilligence_expiration.id_archivo?.length > 0 ?
                                                                    due_dilligence_expiration.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseDueDilligenceExpirationDateNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-due_dilligence_expiration">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="due_dilligence_expiration_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseDueDilligenceExpirationDateFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-sells-in-escrow-due_dilligence_expiration">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="due_dilligence_expiration_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="due_dilligence_expiration_file" id="due_dilligence_expiration_file" ref={due_dilligence_expiration_ref} onChange={onChangeFileDueDilligenceExpirationRenegotiation} aria-describedby="view_file_due_dilligenceExpirationFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.due_dilligence_expiration_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.due_dilligence_expiration_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>
                            
                            <div className={'col-md-4 form-group' + (renegotiationData.due_dilligence_expiration !== null ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Escrow deposit increase?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_increase" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_increase === true ? true : false} disabled={renegotiationData.due_dilligence_expiration !== null ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_increase" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_increase === false ? true : false} disabled={renegotiationData.due_dilligence_expiration !== null ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_increase === true ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Escrow deposit to be release?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_release" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_release === true ? true : false} disabled={renegotiationData.escrow_deposit_increase === true ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="escrow_deposit_release" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.escrow_deposit_release === false ? true : false} disabled={renegotiationData.escrow_deposit_increase === true ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            <div className={'col-md-4 form-group' + (renegotiationData.escrow_deposit_release === true ? '' : ' campo-deshabilitado')}>
                                <label className="font-weight-bold label_renegotiation">Funds to be applied to sale price?:</label><br/>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="funds_applied_sale_price" value="1" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.funds_applied_sale_price === true ? true : false} disabled={renegotiationData.escrow_deposit_release === true ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="funds_applied_sale_price" value="2" onChange={e => handleRadiobuttonRenegotiation(e)} checked={renegotiationData.funds_applied_sale_price === false ? true : false} disabled={renegotiationData.escrow_deposit_release === true ? false : true} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                </div>
                            </div>

                            
                        </div>
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                            <button className="btn btn-success" onClick={() => {handleCloseModalRenegotiation(); setPreguntarGuardar(false);}}>Yes</button>
                        </>
                        
                        :
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => handleCloseModalRenegotiation()}>Cancel</button>
                            <button className="btn btn-success" onClick={handleSubmitRenegotiation}>Save and New</button>
                            <button className="btn btn-success" onClick={(e) => {
                                handleSubmitRenegotiation(e);
                                setRenegotiationModal(false);
                            }}>Save and Close</button>
                        </>
                    }
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo archivo */}
            <Modal backdrop="static" show={newFileModal} size="lg" onHide={() => {
                handleCloseAgregarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New file to upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsFile(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="file" ref={file_ref} onChange={onChangeFile} aria-describedby="view_file_file"/>
                                    <label className="custom-file-label">{newFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarArchivo()} disabled={statusSaveFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarArchivo} disabled={statusSaveFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar archivo */}
            <Modal backdrop="static" show={editFileModal} size="lg" onHide={() => {
                handleCloseEditarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFile(e)} value={editFileData.nombre || ''} disabled={statusSaveEditFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={file_edit_ref} onChange={onChangeEditFile} aria-describedby="view_file_editFile"/>
                                    <label className="custom-file-label">{editFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarArchivo()} disabled={statusSaveEditFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarEditarArchivo} disabled={statusSaveEditFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 11 ? 
                        <button type="button" className="btn btn-warning ml-2 btn-sm" onClick={() => handleRenegotiation()} disabled={spinner ? true : false}>Renegotiation</button> 
                    :   null
                }
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 11 ?
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={spinner ? true : false}>Save</button>
                    :   null
                }
                {
                    mostrarBoton && (proyectos.detalles?.proyecto?.identificador_status <= 11) ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleContinue} disabled={spinner ? true : false}>Save and Continue</button>
                    :   null
                }                    
            </div>     

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={() => handleRenegotiation()}>
                            Renegotiation
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleContinue}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>

            <form className="col-md-12 pb-3" autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" style={{display:'none'}} />

                { spinner ? 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                            </div>
                        </div> 
                    :
                        null
                }

                <div className="row">
                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold" onClick={() => newNote('Listing price')}>Listing price:</label>
                            {
                                (proyectos.masterlist?.listing_price !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label>{ numberToDecimal(proyectos.masterlist?.listing_price, '$ ') }</label>
                                        </div>
                                    :
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-dollar-sign"></i>
                                                </span>
                                            </div>
                                            <CurrencyInput
                                                autoComplete='off'
                                                className="form-control text-center"
                                                value={proyectos.masterlist?.listing_price || ''}
                                                decimalsLimit={2}
                                                groupSeparator=","
                                                decimalSeparator="."
                                                disabled={true}
                                            />
                                        </div>
                            }
                    </div>

                    <div className={'col-md-2 form-group campo_dinero' + (proyectoData.sales_price !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Sales price')}>Sales price:</label>
                        {
                            (proyectos.masterlist?.sales_price !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{ numberToDecimal(proyectoData.sales_price, '$ ') }</label>
                                    </div>
                                :
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" data-focus="salesPrice" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-dollar-sign" data-focus="salesPrice" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                        </div>
                                        <CurrencyInput
                                            autoComplete='off'
                                            id="salesPrice"
                                            name="sales_price"
                                            className="form-control text-center"
                                            value={proyectoData.sales_price || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.sales_price !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                        />
                                    </div>
                        }
                            
                    </div>

                    <div className="col-md-2 form-group campo_fecha">
                        <label className="font-weight-bold" onClick={() => newNote('Contract date')}>Contract date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.contract_sale !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.contract_sale ? new Date(proyectoData.contract_sale).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="contractDate" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="contractDate" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="contractDate"
                                                monthsShown={2}
                                                name="contract_sale"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.contract_sale ? new Date(proyectoData.contract_sale) : '' } 
                                                // minDate={new Date(proyectos.masterlist?.listing)} 
                                                onChange={ date => {onContractDateChange(date)} }
                                                disabled={proyectos.masterlist?.contract_sale !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    {
                        proyectoData.contract_sale !== null ?
                            <div className="col-md-2 form-group campo_numero_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Dilligence days')}>Dilligence days:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (!proyectos.edicion_proyecto)
                                            ?
                                                <div className='d-flex'>
                                                    <label><i className='fas fa-calendar-day'/> {dilligenceDays}</label>
                                                </div>
                                            :
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" data-focus="dilligence" onClick={(e) => focusCampo(e)}>
                                                            <i className="fas fa-calendar-day" data-focus="dilligence" onClick={(e) => focusCampo(e)}></i>
                                                        </span>
                                                    </div>
                                                    <input
                                                        id="dilligence"
                                                        autoComplete='off'
                                                        className="form-control"
                                                        type="number"
                                                        min={0}
                                                        value={dilligenceDays}
                                                        onChange={e => { setDilligenceDays(e.target.value); onChangeDays(e); }} 
                                                        disabled={proyectos.masterlist?.contract_sale !== null && proyectos.masterlist?.due_dilligence_expiration !== null ? (proyectos.edicion_proyecto ? false : true) : (proyectoData.contract_sale !== null ? false : (proyectos.edicion_proyecto ? false : true))}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.contract_sale !== null ?
                            <div className={'col-md-2 form-group campo_fecha' + (proyectoData.due_dilligence_expiration !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Due dilligence expiration date')}>Due dilligence expiration date:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (proyectos.masterlist?.due_dilligence_expiration !== null && !proyectos.edicion_proyecto)
                                            ?
                                                <div className='d-flex'>
                                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.due_dilligence_expiration ? new Date(proyectoData.due_dilligence_expiration).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="dueDilligence" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="dueDilligence" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker
                                                        id="dueDilligence"
                                                        monthsShown={2}
                                                        name="due_dilligence_expiration"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={ dilligenceDaysFlag === true ? '' : (proyectoData.due_dilligence_expiration !== null ? new Date(proyectoData.due_dilligence_expiration) : '') } 
                                                        // minDate={new Date(proyectos.masterlist?.listing)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, due_dilligence_expiration: date }); dispatch(guardarCambios(true));} }
                                                        disabled={(proyectos.masterlist?.due_dilligence_expiration !== null ? (proyectos.edicion_proyecto ? false : true) : false) || (dilligenceDaysFlag === true ? (proyectos.edicion_proyecto ? false : true) : false)}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold" onClick={() => newNote('Buy´s help')}>Buy´s help:</label>
                        {
                            (proyectos.masterlist?.buys_help !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{ numberToDecimal(proyectoData.buys_help, '$ ') }</label>
                                    </div>
                                :
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" data-focus="buys_help" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-dollar-sign" data-focus="buys_help" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                        </div>
                                        <CurrencyInput
                                            id='buys_help'
                                            name="buys_help"
                                            className="form-control text-center"
                                            value={proyectoData.buys_help || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.buys_help !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            autoComplete='off'
                                        />
                                    </div>
                        }
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold" onClick={() => newNote('Escrow deposit amount')}>Escrow deposit amount:</label>
                            {
                                (proyectos.masterlist?.escrow_deposit_amount_sale !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label>{ numberToDecimal(proyectoData.escrow_deposit_amount, '$ ') }</label>
                                        </div>
                                    :
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" data-focus="escrow_deposit" onClick={(e) => focusCampo(e)}>
                                                    <i className="fas fa-dollar-sign" data-focus="escrow_deposit" onClick={(e) => focusCampo(e)}></i>
                                                </span>
                                            </div>
                                            <CurrencyInput
                                            id="escrow_deposit"
                                            name="escrow_deposit_amount"
                                            className="form-control text-center"
                                            value={proyectoData.escrow_deposit_amount || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.escrow_deposit_amount_sale !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            autoComplete='off'
                                            />
                                        </div>
                            }
                    </div>

                    <div className="col-md-2 form-group campo_radio_lg">
                        <label className="font-weight-bold" onClick={() => newNote('Escrow deposit received?')}>Escrow deposit received?:</label><br/>
                        {
                            (proyectoData.escrow_deposit_received_question !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div>
                                        <label>{(proyectoData.escrow_deposit_received_question === true) ? 'Yes' : 'No'}</label>
                                    </div>
                                :
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="escrow_deposit_received_question" value="1" onChange={e => handleRadiobutton(e)} checked={proyectoData.escrow_deposit_received_question === true ? true : false} disabled={proyectos.masterlist?.escrow_deposit_received !== null ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="escrow_deposit_received_question" value="2" onChange={e => handleRadiobutton(e)} checked={proyectoData.escrow_deposit_received_question === false ? true : false} disabled={proyectos.masterlist?.escrow_deposit_received !== null ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                        }
                    </div>
                    
                    {
                        proyectoData.escrow_deposit_received_question === false ?
                            <div className="col-md-2 form-group campo_select_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Who will call?')}>Who will call?:</label>
                                {
                                    (proyectos.masterlist?.escrow_deposit_received_user !== null && !proyectos.edicion_proyecto)
                                        ?
                                            <div className='d-flex'>
                                                <label>{userEscrowDepositSelectData.label}</label>
                                            </div>
                                        :
                                            <Select
                                                name="escrow_deposit_received_user"
                                                isClearable={true}
                                                options={usersFilter}
                                                value={userEscrowDepositSelectData}
                                                onChange={(value) => onChangeSelectSearch(value, 'escrow_deposit_received_user', '')}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isDisabled={proyectos.masterlist?.escrow_deposit_received_user !== null || proyectoData.escrow_deposit_received_question !== false ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                }
                            </div>
                        :
                            <div>
                                 <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip" style={{fontSize: 18}}>{`${proyectoData.escrow_deposit_received !== null ? (Math.floor((new Date(proyectoData.escrow_deposit_received).getTime() - new Date(proyectoData.due_dilligence_expiration).getTime()) / (1000 * 3600 * 24)) >= 3 ? 'This date must be less than 3 days greater than the Due Diligence expiration date' : '') : ''}`}</Tooltip>}
                                >
                                    <div className={'col-md-2 form-group campo_fecha' + (proyectoData.escrow_deposit_received !== null ? (Math.floor((new Date(proyectoData.escrow_deposit_received).getTime() - new Date(proyectoData.due_dilligence_expiration).getTime()) / (1000 * 3600 * 24)) >= 3 ? ' fecha-superior' : '') : '')}>
                                        <label className="font-weight-bold" onClick={() => newNote('Escrow deposit received date')}>Escrow deposit received date:</label>
                                        <div className="contenedor_datepicker">
                                            {
                                                (proyectos.masterlist?.escrow_deposit_received !== null && !proyectos.edicion_proyecto)
                                                    ?
                                                        <div className='d-flex'>
                                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.escrow_deposit_received ? new Date(proyectoData.escrow_deposit_received).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                        </div>
                                                    :
                                                        <div className="d-flex">
                                                            <span className="input-group-text" data-focus="escrow_deposit_received" onClick={(e) => focusCampo(e)}>
                                                                <i className="fas fa-calendar-alt" data-focus="escrow_deposit_received" onClick={(e) => focusCampo(e)}></i>
                                                            </span>
                                                            <DatePicker
                                                                id="escrow_deposit_received"
                                                                monthsShown={2}
                                                                name="escrow_deposit_received"
                                                                className="form-control text-center"
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={ proyectoData.escrow_deposit_received ? new Date(proyectoData.escrow_deposit_received) : '' } 
                                                                // minDate={new Date(proyectos.masterlist?.listing)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, escrow_deposit_received: date }); handleAlertarFechas(date); dispatch(guardarCambios(true));} }
                                                                disabled={proyectos.masterlist?.escrow_deposit_received !== null || proyectoData.escrow_deposit_received_question === false ? (proyectos.edicion_proyecto ? false : true) : false}
                                                            />
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            </div>
                    }

                    <div className="col-md-2 form-group campo_fecha">
                        <label className="font-weight-bold" onClick={() => newNote('Inspection date')}>Inspection date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.inspection !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.inspection ? new Date(proyectoData.inspection).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="inspection" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="inspection" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="inspection"
                                                monthsShown={2}
                                                name="inspection"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.inspection ? new Date(proyectoData.inspection) : '' } 
                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                onChange={ date => {setProyectoData({ ...proyectoData, inspection: date }); dispatch(guardarCambios(true));} }
                                                disabled={proyectos.masterlist?.inspection !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md-2 form-group campo_fecha' + (proyectoData.closing !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Closing date')}>Closing date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.closing !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.closing ? new Date(proyectoData.closing).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="closingDate" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="closingDate" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="closingDate"
                                                monthsShown={2}
                                                name="closing"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.closing ? new Date(proyectoData.closing) : '' } 
                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                onChange={ date => {setProyectoData({ ...proyectoData, closing: date }); dispatch(guardarCambios(true));} }
                                                disabled={proyectos.masterlist?.closing !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_radio_lg">
                        <label className="font-weight-bold" onClick={() => newNote('Listing agent representing buyer?')}>Listing agent representing buyer?:</label><br/>
                        {
                            (proyectoData.broker_represent !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div>
                                        <label>{(proyectoData.broker_represent === true) ? 'Yes' : 'No'}</label>
                                    </div>
                                :
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="copy_information" value="1" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === true ? true : false} disabled={proyectos.masterlist?.broker_represent_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="copy_information" value="2" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === false ? true : false} disabled={proyectos.masterlist?.broker_represent_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <ul className="nav nav-tabs nav-fill">

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 0 || sectionInformation === 1) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(0)}>Escrow</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 2 || sectionInformation === 3) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(2)}>Listing{ proyectoData.broker_represent === true ? ' & Buyer' : ''} broker</button>
                            </li>

                            {
                                proyectoData.broker_represent === true ?
                                    null
                                :
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 4 || sectionInformation === 5) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(4)}>Buyer broker</button>
                                    </li>
                            }
                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 8 || sectionInformation === 9) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(8)}>Title</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 10 || sectionInformation === 11) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(10)}>Termite</button>
                            </li>

                            <li className="nav-item">
                                <button type="button" className={'nav-link btn_tab_information border border-dark' + ((sectionInformation === 12 || sectionInformation === 13) ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(12)}>Septic</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 0 || sectionInformation === 1) ? '' : ' d-none')}>
                    {
                        sectionInformation === 0 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(1)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Company:</label>
                                    <div className="input-group">
                                        <Select name="id_escrow" className="select-group" aria-describedby="button-escrow" isClearable={true} options={escrowsFilter} value={escrowSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_escrow', 'id_escrow_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_escrow_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_escrow_sale !== null || proyectoData.id_escrow !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-escrow" onClick={() => handleEscrow(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewEscrowData.address !== '') ? viewEscrowData.address : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_escrow_agent" className="select-group" aria-describedby="button-escrow-agent" isClearable={true} options={escrowsAgentsFilter} value={escrowAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_escrow_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_escrow_agent_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_escrow_agent_sale !== null || proyectoData.id_escrow_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-escrow-agent" onClick={() => handleEscrow(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div style={{display: 'flex'}}>
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewEscrowData.email !== '') ? viewEscrowData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewEscrowData.cell_phone !== '') ? viewEscrowData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewEscrowData.phone !== '') ? viewEscrowData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* Instructions Received */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Instructions received?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_instructions" value="1" onChange={e => handleRadiobuttonEscrowInstructions(e)} checked={proyectoData.escrow_instructions === true ? true : false} disabled={proyectos.masterlist?.escrow_instructions_sale === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="escrow_instructions" value="2" onChange={e => handleRadiobuttonEscrowInstructions(e)} checked={proyectoData.escrow_instructions === false ? true : false} disabled={proyectos.masterlist?.escrow_instructions_sale === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>

                                {/* Instructions Received Date */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Instructions received date:</label>
                                    {
                                        (proyectos.masterlist?.escrow_instructions_date_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker monthsShown={2} name="escrow_instructions_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.escrow_instructions_date ? new Date(proyectoData.escrow_instructions_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, escrow_instructions_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.escrow_instructions_date_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div style={{display: 'contenedor_datepicker'}}>
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.escrow_instructions_date ? new Date(proyectoData.escrow_instructions_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker monthsShown={2} name="escrow_instructions_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.escrow_instructions_date ? new Date(proyectoData.escrow_instructions_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, escrow_instructions_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.escrow_instructions_date_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                {/* Number */}
                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Number:</label>
                                    {
                                        (proyectos.masterlist?.escrow_number_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        #
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="escrow_number" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.escrow_number || ''} disabled={proyectos.masterlist?.escrow_number_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div style={{display: 'contenedor_datepicker'}}>
                                                            <p>{(proyectoData.title_order !== null) ? '# ' + proyectoData.title_order : '# -'}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                #
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="escrow_number" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.escrow_number || ''} disabled={proyectos.masterlist?.escrow_number_sale !== null || proyectoData.escrow_instructions === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 1 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(0)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(0)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 2 || sectionInformation === 3) ? '' : ' d-none')}>
                    {
                        sectionInformation === 2 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(3)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_seller" className="select-group" aria-describedby="button-broker-seller" isClearable={true} options={brokersFilter} value={brokerSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_seller', 'id_broker_agent_seller')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_seller_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_seller_sale !== null || proyectoData.id_broker_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-seller" onClick={() => handleBrokerSeller(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className='contenedor_datepicker'>
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.address !== null) ? viewBrokerSellerData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_seller" className="select-group" aria-describedby="button-broker-agent-seller" isClearable={true} options={brokersAgentsSellerFilter} value={brokerAgentSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_seller', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_seller_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_seller_sale !== null || proyectoData.id_broker_agent_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-seller" onClick={() => handleBrokerSeller(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-4 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.email !== '') ? viewBrokerSellerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.cell_phone !== '') ? viewBrokerSellerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.phone !== '') ? viewBrokerSellerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerSellerData.license !== '') ? `# ${viewBrokerSellerData.license}` : '# -'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerSellerData.public !== '') ? `# ${viewBrokerSellerData.public}` : '# -'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 3 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(1)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(2)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 4 || sectionInformation === 5) ? '' : ' d-none')}>
                    {
                        sectionInformation === 4 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(5)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_buyer" className="select-group" aria-describedby="button-broker-buyer" isClearable={true} options={brokersFilter} value={brokerBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_buyer', 'id_broker_agent_buyer')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_buyer_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_buyer_sale !== null || proyectoData.id_broker_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-buyer" onClick={() => handleBrokerBuyer(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className='contenedor_datepicker'>
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.address !== null) ? viewBrokerBuyerData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_buyer" className="select-group" aria-describedby="button-broker-agent-buyer" isClearable={true} options={brokersAgentsBuyerFilter} value={brokerAgentBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_buyer', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_buyer_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_buyer_sale !== null || proyectoData.id_broker_agent_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-buyer" onClick={() => handleBrokerBuyer(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-4 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.email !== '') ? viewBrokerBuyerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.cell_phone !== '') ? viewBrokerBuyerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.phone !== '') ? viewBrokerBuyerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerBuyerData.license !== '') ? `# ${viewBrokerBuyerData.license}` : '# -'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerBuyerData.public !== '') ? `# ${viewBrokerBuyerData.public}` : '# -'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 5 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(2)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(4)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 8 || sectionInformation === 9) ? '' : ' d-none')}>
                    {
                        sectionInformation === 8 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(9)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Title information:</label>
                                    <div className="input-group">
                                        <Select name="id_title" className="select-group" aria-describedby="button-title" isClearable={true} options={titlesFilter} value={titleSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_title', 'id_title_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_title_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_title_sale !== null || proyectoData.id_title !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-title" onClick={() => handleTitle(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewTitleData.address !== null) ? viewTitleData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_title_agent" className="select-group" aria-describedby="button-title-agent" isClearable={true} options={titlesAgentsFilter} value={titleAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_title_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_title_agent_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_title_agent_sale !== null || proyectoData.id_title_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-title-agent" onClick={() => handleTitle(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewTitleData.email !== null) ? viewTitleData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTitleData.cell_phone !== '') ? viewTitleData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTitleData.phone !== '') ? viewTitleData.phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-2 form-group">
                                    <label className="font-weight-bold">Received title preliminary?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_preliminary" value="1" onChange={e => handleRadiobuttonTitlePreliminary(e)} checked={proyectoData.title_preliminary === true ? true : false} disabled={proyectos.masterlist?.title_preliminary_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_preliminary" value="2" onChange={e => handleRadiobuttonTitlePreliminary(e)} checked={proyectoData.title_preliminary === false ? true : false} disabled={proyectos.masterlist?.title_preliminary_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Preliminary received date:</label>
                                    {
                                        (proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker monthsShown={2} name="title_preliminary_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, title_preliminary_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :  
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker monthsShown={2} name="title_preliminary_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.title_preliminary_date ? new Date(proyectoData.title_preliminary_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, title_preliminary_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.title_preliminary_date_buy !== null || proyectoData.title_preliminary === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Has it been reviewed?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_review" value="1" onChange={e => handleRadiobuttonTitleReviewed(e)} checked={proyectoData.title_review === true ? true : false} disabled={proyectos.masterlist?.title_review_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="title_review" value="2" onChange={e => handleRadiobuttonTitleReviewed(e)} checked={proyectoData.title_review === false ? true : false} disabled={proyectos.masterlist?.title_review_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Who did the review?:</label>
                                    {
                                        (proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-user"></i>
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="title_review_who" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_review_who || ''} disabled={proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(proyectoData.title_review_who !== null) ? proyectoData.title_review_who : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="title_review_who" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_review_who || ''} disabled={proyectos.masterlist?.title_review_who_buy !== null || proyectoData.title_review === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Rep:</label>
                                    {
                                        (proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </span>
                                                                <input autoComplete='off' name="title_rep" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_rep || ''} disabled={proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-info-circle" style={{paddingRight: '10px'}}></i>{(proyectoData.title_rep !== null) ? proyectoData.title_rep : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-info-circle"></i>
                                                        </span>
                                                        <input autoComplete='off' name="title_rep" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_rep || ''} disabled={proyectos.masterlist?.title_rep_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Order:</label>
                                    {
                                        (proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-info-circle"></i>
                                                                </span>
                                                                <input autoComplete='off' name="title_order" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_order || ''} disabled={proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-info-circle" style={{paddingRight: '10px'}}></i>{(proyectoData.title_order !== null) ? proyectoData.title_order : ''}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-info-circle"></i>
                                                        </span>
                                                        <input autoComplete='off' name="title_order" className="form-control" type="text" onChange={e => onChange(e)} value={proyectoData.title_order || ''} disabled={proyectos.masterlist?.title_order_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 9 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(4)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(8)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 10 || sectionInformation === 11) ? '' : ' d-none')}>
                    {
                        sectionInformation === 10 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(11)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>
                                
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Termite:</label>
                                    <div className="input-group">
                                        <Select name="id_termite" className="select-group" aria-describedby="button-termite" isClearable={true} options={termitesFilter} value={termiteSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_termite', 'id_termite_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_termite_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_termite_sale !== null || proyectoData.id_termite !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-termite" onClick={() => handleTermite(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewTermiteData.address !== '') ? viewTermiteData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_termite_agent" className="select-group" aria-describedby="button-termite-agent" isClearable={true} options={termitesAgentsFilter} value={termiteAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_termite_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_termite_agent_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_termite_agent_sale !== null || proyectoData.id_termite_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-termite-agent" onClick={() => handleTermite(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewTermiteData.phone !== '') ? viewTermiteData.phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewTermiteData.email !== '') ? viewTermiteData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Need to be call for inspection?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="termite_inspection" value="1" onChange={e => handleRadiobuttonTermiteInspection(e)} checked={proyectoData.termite_inspection === true ? true : false} disabled={proyectos.masterlist?.termite_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="termite_inspection" value="2" onChange={e => handleRadiobuttonTermiteInspection(e)} checked={proyectoData.termite_inspection === false ? true : false} disabled={proyectos.masterlist?.termite_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Who is in charge to call?:</label>
                                    <Select name="termite_inspection_user" isClearable={true} options={usersFilter} value={userTermiteSelectData} onChange={(value) => onChangeSelectSearch(value, 'termite_inspection_user', 'name_termite_inspection_user')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.termite_inspection_user_buy !== null || proyectoData.termite_inspection === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Inspection date:</label>
                                    {
                                        (proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker monthsShown={2} name="termite_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, termite_inspection_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker monthsShown={2} name="termite_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.termite_inspection_date ? new Date(proyectoData.termite_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, termite_inspection_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.termite_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Report number:</label>
                                    {
                                        (proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        #
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="report_number_termite" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_termite || ''} disabled={proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p>{(proyectoData.report_number_termite !== null) ? `# ${proyectoData.report_number_termite}` : ' # -'}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                #
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="report_number_termite" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_termite || ''} disabled={proyectos.masterlist?.report_number_termite_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 11 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(5)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(10)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={'row p-3 mx-0 seccion_information' + ((sectionInformation === 12 || sectionInformation === 13) ? '' : ' d-none')}>
                    {
                        sectionInformation === 12 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(13)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Septic:</label>
                                    <div className="input-group">
                                        <Select name="id_septic" className="select-group" aria-describedby="button-septic" isClearable={true} options={septicsFilter} value={septicSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_septic', 'id_septic_agent')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_septic_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_septic_sale !== null || proyectoData.id_septic !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-septic" onClick={() => handleSeptic(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-6 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewSepticData.address !== '') ? viewSepticData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_septic_agent" className="select-group" aria-describedby="button-septic-agent" isClearable={true} options={septicsAgentsFilter} value={septicAgentSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_septic_agent', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_septic_agent_sale !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_septic_agent_sale !== null || proyectoData.id_septic_agent !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-septic-agent" onClick={() => handleSeptic(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-phone" style={{paddingRight: '10px'}}></i>{(viewSepticData.phone !== '') ? viewSepticData.phone : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewSepticData.email !== '') ? viewSepticData.email : ''}</p>
                                    </div>
                                </div>

                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Need to be call for inspection?:</label><br/>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="septic_inspection" value="1" onChange={e => handleRadiobuttonSepticInspection(e)} checked={proyectoData.septic_inspection === true ? true : false} disabled={proyectos.masterlist?.septic_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="septic_inspection" value="2" onChange={e => handleRadiobuttonSepticInspection(e)} checked={proyectoData.septic_inspection === false ? true : false} disabled={proyectos.masterlist?.septic_inspection_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                    </div>
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Who is in charge to call?:</label>
                                    <Select name="septic_inspection_user" isClearable={true} options={usersFilter} value={userSepticSelectData} onChange={(value) => onChangeSelectSearch(value, 'septic_inspection_user', 'name_septic_inspection_user')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.septic_inspection_user_buy !== null || proyectoData.septic_inspection === false || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                </div>

                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Inspection date:</label>
                                    {
                                        (proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="d-flex">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                </span>
                                                                <DatePicker monthsShown={2} name="septic_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                                // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                                onChange={ date => {setProyectoData({ ...proyectoData, septic_inspection_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p><i className="fas fa-calendar-alt" style={{paddingRight: '10px'}}></i>{proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date).toISOString().substring(0, 10) : new Date(proyectos.detalles?.proyecto?.envio).toISOString().substring(0, 10)}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="d-flex">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-calendar-alt"></i>
                                                        </span>
                                                        <DatePicker monthsShown={2} name="septic_inspection_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.septic_inspection_date ? new Date(proyectoData.septic_inspection_date) : new Date(proyectos.detalles?.proyecto?.envio) } 
                                                        // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, septic_inspection_date: date }); dispatch(guardarCambios(true));} } disabled={proyectos.masterlist?.septic_inspection_date_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                                
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Report number:</label>
                                    {
                                        (proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled')
                                            ?
                                                (proyectos.edicion_proyecto)
                                                    ?
                                                        <div className="contenedor_datepicker">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        #
                                                                    </span>
                                                                </div>
                                                                <input autoComplete='off' name="report_number_septic" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_septic || ''} disabled={proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="contenedor_datepicker">
                                                            <p>{(proyectoData.report_number_septic !== null) ? `# ${proyectoData.report_number_septic}` : ' # -'}</p>
                                                        </div>
                                            :
                                                <div className="contenedor_datepicker">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                #
                                                            </span>
                                                        </div>
                                                        <input autoComplete='off' name="report_number_septic" className="form-control text-center" type="text" onChange={e => onChange(e)} value={proyectoData.report_number_septic || ''} disabled={proyectos.masterlist?.report_number_septic_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 13 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(6)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(12)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>
            </form>
        </Fragment>
    )
}

export default SellsInEscrowNewDevelopment;