import { BUSCAR_LENDERS, AGREGAR_LENDER, EDITAR_LENDER, DETALLES_LENDER, ELIMINAR_LENDER } from '../actions/types';

const initialState = {
    lenders: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_LENDER:
            return {
                ...state,
                lenders: [
                    ...state.lenders, payload
                ]
            };
        case EDITAR_LENDER:
            return {
                ...state,
                lenders: state.lenders.map(
                    lender => lender._id === payload._id ? payload : lender
                )
            };
        case DETALLES_LENDER:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_LENDER:
            return {
                ...state,
                lenders: state.lenders.filter( lender => lender._id !== payload.id )
            };
        case BUSCAR_LENDERS:
            return {
                ...state,
                lenders: payload
            };
        default:
            return state;
    }
}