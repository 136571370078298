import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import dashboard from './dashboard';
import usuarios from './usuarios';
import deal_analyzer from './deal_analyzer';
import proyectos from './proyectos';
import buyers from './buyers';
import financing_types from './financing_types';
import sellers from './sellers';
import vestings from './vestings';
import brokers from './brokers';
import escrows from './escrows';
import lenders from './lenders';
import titles from './titles';
import termites from './termites';
import septics from './septics';
import tipos from './tipos';
import recordatorios from './recordatorios';
import empleados from './empleados';
import nominas from './nominas';
import gastos from './gastos';
import contabilidad from './contabilidad';
import deudas from './deudas';
import bancos from './bancos';
import categorias_inspeccion from './categorias_inspeccion';
import secciones_todolist from './secciones_todolist';
import todolist from './todolist';
import actividades from './actividades';

export default combineReducers({
    auth,
    alert,
    dashboard,
    usuarios, 
    deal_analyzer, 
    proyectos,
    buyers,
    financing_types,
    sellers,
    vestings,
    brokers,
    escrows,
    lenders,
    titles,
    termites,
    septics,
    tipos,
    recordatorios,
    empleados,
    nominas,
    gastos,
    contabilidad,
    deudas,
    bancos,
    categorias_inspeccion,
    secciones_todolist,
    todolist, 
    actividades
})