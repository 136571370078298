import { OBTENER_DATOS_CONTABILIDAD, OBTENER_TARJETAS_CREDITO, DETALLES_TARJETA_CREDITO, STATUS_TARJETA_CREDITO, ELIMINAR_TARJETA_CREDITO, AGREGAR_CREDITO_TARJETA, EDITAR_CREDITO_TARJETA, DETALLES_CREDITO_TARJETA, ELIMINAR_CREDITO_TARJETA, AGREGAR_PAGO_TARJETA, EDITAR_PAGO_TARJETA, DETALLES_PAGO_TARJETA, ELIMINAR_PAGO_TARJETA, AGREGAR_ESTADO_TARJETA, EDITAR_ESTADO_TARJETA, DETALLES_ESTADO_TARJETA, DETALLES_ARCHIVO_ESTADO_TARJETA, ELIMINAR_ESTADO_TARJETA } from '../actions/types';

const initialState = {
    datos: null, 
    tarjetas_deuda: [],
    tarjetas_libres: [],
    detalles_tarjeta: null,
    detalles_estado_tarjeta: null, 
    detalles_pago_tarjeta: null,
    detalles_credito_tarjeta: null,
    detalles_archivo: null
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {

        case OBTENER_DATOS_CONTABILIDAD: 
            return {
                ...state,
                datos: payload
            };

        case OBTENER_TARJETAS_CREDITO:
            return {
                ...state,
                tarjetas_deuda: payload.tarjetas_deuda,
                tarjetas_libres: payload.tarjetas_libres
            };

        case DETALLES_TARJETA_CREDITO: 
            return {
                ...state,
                detalles_tarjeta: payload
            };

        case STATUS_TARJETA_CREDITO: 
            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    status: payload
                }
            };

        case ELIMINAR_TARJETA_CREDITO: 
            return {
                ...state,
                tarjetas_deuda: state.tarjetas_deuda.filter( tarjeta => tarjeta._id !== payload.id ),
                tarjetas_libres: state.tarjetas_libres.filter( tarjeta => tarjeta._id !== payload.id )
            };

        case AGREGAR_ESTADO_TARJETA:
            let detalles_agregar_estado_tarjeta = state.detalles_tarjeta;

            let estados_agregar_tarjeta = [
                ...detalles_agregar_estado_tarjeta.estados, payload
            ];

            estados_agregar_tarjeta.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_agregar_estado_tarjeta.estados = estados_agregar_tarjeta;

            return {
                ...state,
                detalles_tarjeta: detalles_agregar_estado_tarjeta
            };

        case EDITAR_ESTADO_TARJETA:
            let detalles_editar_estado_tarjeta = state.detalles_tarjeta;

            let estados_editar_tarjeta = detalles_editar_estado_tarjeta.estados.map( estado => String(estado._id) === String(payload._id) ? payload : estado );

            estados_editar_tarjeta.sort(function (a, b) {
                if (a.fecha_pago > b.fecha_pago) {
                    return -1;
                }
                if (a.fecha_pago < b.fecha_pago) {
                    return 1;
                }
                return 0;
            });

            detalles_editar_estado_tarjeta.estados = estados_editar_tarjeta;

            return {
                ...state,
                detalles_tarjeta: detalles_editar_estado_tarjeta
            };

        case DETALLES_ESTADO_TARJETA: 
            return {
                ...state,
                detalles_estado_tarjeta: payload
            };

        case DETALLES_ARCHIVO_ESTADO_TARJETA: 
            return {
                ...state,
                detalles_archivo: payload
            };

        case ELIMINAR_ESTADO_TARJETA:
            let detalles_eliminar_estado_tarjeta = state.detalles_tarjeta;

            detalles_eliminar_estado_tarjeta.estados = detalles_eliminar_estado_tarjeta.estados.filter( estado => estado._id !== payload.id );

            return {
                ...state,
                detalles_tarjeta: detalles_eliminar_estado_tarjeta
            };

        case AGREGAR_PAGO_TARJETA:
            let detalles_agregar_pago_tarjeta = state.detalles_tarjeta.estados;

            detalles_agregar_pago_tarjeta[0].pagos = [
                ...detalles_agregar_pago_tarjeta[0].pagos, payload
            ];

            detalles_agregar_pago_tarjeta[0].pagos.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_agregar_pago_tarjeta
                }
            };

        case EDITAR_PAGO_TARJETA:
            let detalles_editar_pago_tarjeta = state.detalles_tarjeta.estados;

            detalles_editar_pago_tarjeta[0].pagos = detalles_editar_pago_tarjeta[0].pagos.map( pago => String(pago._id) === String(payload._id) ? payload : pago );

            detalles_editar_pago_tarjeta[0].pagos.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_editar_pago_tarjeta
                }
            };

        case DETALLES_PAGO_TARJETA: 
            return {
                ...state,
                detalles_pago_tarjeta: payload
            };

        case ELIMINAR_PAGO_TARJETA: 
            let detalles_eliminar_pago_tarjeta = state.detalles_tarjeta.estados;

            detalles_eliminar_pago_tarjeta[0].pagos = detalles_eliminar_pago_tarjeta[0].pagos.filter( pago => pago._id !== payload.id );

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_eliminar_pago_tarjeta
                }
            };
        
        case AGREGAR_CREDITO_TARJETA:
            let detalles_agregar_credito_tarjeta = state.detalles_tarjeta.estados;

            detalles_agregar_credito_tarjeta[0].creditos = [
                ...detalles_agregar_credito_tarjeta[0].creditos, payload
            ];

            detalles_agregar_credito_tarjeta[0].creditos.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_agregar_credito_tarjeta
                }
            };

        case EDITAR_CREDITO_TARJETA:
            let detalles_editar_credito_tarjeta = state.detalles_tarjeta.estados;

            detalles_editar_credito_tarjeta[0].creditos = detalles_editar_credito_tarjeta[0].creditos.map( credito => String(credito._id) === String(payload._id) ? payload : credito );

            detalles_editar_credito_tarjeta[0].creditos.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_editar_credito_tarjeta
                }
            };

        case DETALLES_CREDITO_TARJETA: 
            return {
                ...state,
                detalles_credito_tarjeta: payload
            };

        case ELIMINAR_CREDITO_TARJETA: 
            let detalles_eliminar_credito_tarjeta = state.detalles_tarjeta.estados;

            detalles_eliminar_credito_tarjeta[0].creditos = detalles_eliminar_credito_tarjeta[0].creditos.filter( credito => credito._id !== payload.id );

            return {
                ...state,
                detalles_tarjeta: {
                    ...state.detalles_tarjeta, 
                    estados: detalles_eliminar_credito_tarjeta
                }
            };
        
        default: 
            return state;
    }

}