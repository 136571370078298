import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../../actions/alert';
import { renegotiateOffer, editarOffer, continuarOffer, guardarCambios, desplegarModalCambios, redireccionar } from '../../../actions/flipping';
import { detallesProyecto, detallesFileProyecto, masterlistProyecto, seleccionarSeccionMasterList, agregarFileProyecto, editarFileProyecto, externoNota } from '../../../actions/proyectos';
import { agregarBrokerMasterlistBuy, agregarBrokerAgentMasterlistBuy } from '../../../actions/brokers';

const styles = {
    buttonMapa: {
        cursor: 'pointer',
    }
}

const OfferFlipping = ({ statusClick }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const proyectos = useSelector(state => state.proyectos);
    const brokers = useSelector(state => state.brokers);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);

    const [dilligenceDays, setDilligenceDays] = useState(0);
    const [dilligenceDaysFlag, setDilligenceDaysFlag] = useState(false);
    const [flag, setFlag] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [mostrarBoton, setMostrarBoton] = useState(false);
    const [isBackButtonClicked, setBackbuttonPress] = useState(false);
    const [preguntarGuardar, setPreguntarGuardar] = useState(false);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
                handleCloseNoGuardarCambiosModal();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const [proyectoData, setProyectoData] = useState({
        id: null,
        status: null,
        direccion: null,
        latitud: null,
        longitud: null,
        apn: null,
        asking_price: null,
        creacion: null,
        envio: null,
        purchase_price: null,
        contract_buy: null,
        dilligence_days: 0,
        due_dilligence_end: null,
        escrow_deposit_amount: null,
        escrow_closing: null,

        id_broker_buyer: null,
        id_broker_agent_buyer: null,
        broker_represent: false,
        id_broker_seller: null,
        id_broker_agent_seller: null,

        purchase_price_history: []
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [renegotiationData, setRenegotiationData] = useState({
        id_proyecto: null,
        id_status: null,
        purchase_price: null,
        purchase_price_date: new Date(),
        purchase_price_note: null,
        purchase_price_file_name: null,
        purchase_price_file: null,
        purchase_price_extension: null
    });

    const [brokerBuyerSelectData, setBrokerBuyerSelectData] = useState(null);
    const [brokerAgentBuyerSelectData, setBrokerAgentBuyerSelectData] = useState(null);

    const [brokerSellerSelectData, setBrokerSellerSelectData] = useState(null);
    const [brokerAgentSellerSelectData, setBrokerAgentSellerSelectData] = useState(null);

    const [addBrokerBuyerData, setAddBrokerBuyerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentBuyerData, setAddBrokerAgentBuyerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [addBrokerSellerData, setAddBrokerSellerData] = useState({
        company: '',
        address: ''
    });

    const [addBrokerAgentSellerData, setAddBrokerAgentSellerData] = useState({
        id_broker: null,
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerBuyerData, setViewBrokerBuyerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [viewBrokerSellerData, setViewBrokerSellerData] = useState({
        company: '',
        address: '',
        agents: [],
        officer_name: '',
        email: '',
        cell_phone: '',
        phone: '',
        license: '',
        public: ''
    });

    const [brokersFilter, setBrokersFilter] = useState([]);
    const [brokersAgentsBuyerFilter, setBrokersAgentsBuyerFilter] = useState([]);
    const [brokersAgentsSellerFilter, setBrokersAgentsSellerFilter] = useState([]);

    const [newFileModal, setNewFileModal] = useState(false);

    const [editFileModal, setEditFileModal] = useState(false);

    const [statusSaveFile, setStatusSaveFile] = useState(true);

    const [statusSaveEditFile, setStatusSaveEditFile] = useState(true);    

    const [newFileData, setNewFileData] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        subseccion: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [editFileData, setEditFileData] = useState({
        id: null,
        nombre: '',
        extension: '',
        archivo: null
    });

    const [dataTabla, setDataTabla] = useState({
        archivosTabla: null
    });

    const [sectionInformation, setSectionInformation] = useState(2);

    const [copiar, setCopiar] = useState(null);

    const [brokerBuyerModal, setBrokerBuyerModal] = useState(false);
    const [brokerAgentBuyerModal, setBrokerAgentBuyerModal] = useState(false);

    const [brokerSellerModal, setBrokerSellerModal] = useState(false);
    const [brokerAgentSellerModal, setBrokerAgentSellerModal] = useState(false);

    const [renegotiationModal, setRenegotiationModal] = useState(false);
    const [guardarCambiosModal, setGuardarCambiosModal] = useState(false);

    let file_ref = createRef();
    let file_edit_ref = createRef();
    let offer_price_ref = createRef();

    //Agregar archivo
    const handleAddFileSection = (subseccion) => {
        setNewFileModal(true);

        setNewFileData({
            ...newFileData,
            subseccion: subseccion
        });
    }

    const handleInputsFile = ({ target }) => {
        setNewFileData({
            ...newFileData,
            [target.name]: target.value
        });
    }

    const onChangeFile = () => {
        let nombre_separado = file_ref.current.files[0].name.split('.');

        setNewFileData({
            ...newFileData,
            archivo: file_ref.current.files[0],
            nombre: nombre_separado[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleGuardarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(newFileData.extension !== ''){
                if(newFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id_proyecto', newFileData.id_proyecto);
                    formData.append('id_status', newFileData.id_status);
                    formData.append('id_folder', newFileData.id_folder);
                    formData.append('subseccion', newFileData.subseccion);
                    formData.append('nombre', newFileData.nombre);
                    formData.append('extension', newFileData.extension);
                    formData.append('archivo', newFileData.archivo);

                    setStatusSaveFile(false);
                    await dispatch(agregarFileProyecto(formData));
                    setStatusSaveFile(true);

                    setNewFileData({
                        ...newFileData,
                        id_folder: null,
                        subseccion: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setSectionInformation((newFileData.subseccion * 2) + 1);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseAgregarArchivo = () => {
        setNewFileModal(false);
        setNewFileData({
            ...newFileData,
            nombre: '',
            extension: '',
            archivo: null
        });
    }

    //Editar archivo
    const handleInputsEditFile = ({ target }) => {
        setEditFileData({
            ...editFileData,
            [target.name]: target.value
        });
    }

    const onChangeEditFile = () => {
        let nombre_separado = file_edit_ref.current.files[0].name.split('.');

        setEditFileData({
            ...editFileData,
            archivo: file_edit_ref.current.files[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleGuardarEditarArchivo = async (e) => {
        e.preventDefault();
        try {
            if(editFileData.extension !== ''){
                if(editFileData.nombre !== ''){
                    let formData = new FormData();
                    formData.append('id', editFileData.id);
                    formData.append('nombre', editFileData.nombre);
                    formData.append('extension', editFileData.extension);
                    formData.append('archivo', editFileData.archivo);

                    setStatusSaveEditFile(false);
                    await dispatch(editarFileProyecto(formData));
                    setStatusSaveEditFile(true);

                    setEditFileData({
                        ...editFileData,
                        id: null,
                        nombre: '',
                        extension: '',
                        archivo: null
                    });

                    setEditFileModal(false);
                }else{
                    dispatch(setAlert('File name missing', 'danger'));
                }
            }else{
                dispatch(setAlert('No file selected', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleCloseEditarArchivo = () => {
        setEditFileModal(false);
        setEditFileData({
            ...editFileData,
            id: null,
            nombre: '',
            extension: '',
            archivo: null
        });
    }
    
    //Ver archivo
    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setRenegotiationModal(false);
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    const handleViewFiles = (files) => {
        if(files.length > 0){
            for(let i=0; i<files.length; i++){
                if(files[i]){
                    const url = URL.createObjectURL(files[i]);
                    window.open(url);
                }
            }
        }else{
            dispatch(setAlert('No files selected', 'danger'));
        }
    }

    //Funciones generales
    const newNote = campo => {
        dispatch(externoNota(true, campo));
    }
    
    const focusCampo = ({target}) => {
        let id_focusear = target.dataset.focus;
        let campo = document.getElementById(id_focusear);
        campo.focus();
    };

    const handleAlertarFechas = (date) => {

        let dif_days = Math.floor((new Date(date).getTime() - new Date(proyectoData.contract_buy).getTime()) / (1000 * 3600 * 24));

        if(dif_days <= 17){
            dispatch(setAlert('This date should be 17 days greater than the Accepted contract date', 'warning'));
        }
    }

    const numberToDecimal = (number, price = '$') => {
        if(isNaN(number)){
            return price + ' -';
        }else{
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = price + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }
    }

    const handleCopyBroker = () => {
        setProyectoData({
            ...proyectoData,
            id_broker_buyer: proyectoData.id_broker_seller,
            id_broker_agent_buyer: proyectoData.id_broker_agent_seller,
            broker_represent: true
        });

        setBrokerBuyerSelectData(brokerSellerSelectData);
        setBrokerAgentBuyerSelectData(brokerAgentSellerSelectData);
        
        dispatch(setAlert('Copied information', 'success'));
        setSectionInformation(2);
    };

    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyecto(id));
    }

    const handleEditarArchivo = async (archivo) => {
        setEditFileModal(true);

        setEditFileData({
            ...editFileData,
            id: archivo._id,
            nombre: archivo.nombre,
            extension: archivo.extension
        });
    }

    const onChange = ({ target }) => {
        setProyectoData({
            ...proyectoData,
            [target.name]: target.value 
        });

        dispatch(guardarCambios(true));
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value
        });

        dispatch(guardarCambios(true));
    };

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){

                case 'id_broker_seller':
                    await setBrokerSellerSelectData({value, label});
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData({value, label});
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData({value, label});
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData({value, label});
                    break;

                default:
                    break;
            }

            await setProyectoData({
                ...proyectoData,
                [origin]: value,
                [name_origin]: null
            });

            await dispatch(guardarCambios(true));
        }else{
            switch(origin){

                case 'id_broker_seller':
                    await setBrokerSellerSelectData(null);
                    break;

                case 'id_broker_agent_seller':
                    await setBrokerAgentSellerSelectData(null);
                    break;

                case 'id_broker_buyer':
                    await setBrokerBuyerSelectData(null);
                    break;

                case 'id_broker_agent_buyer':
                    await setBrokerAgentBuyerSelectData(null);
                    break;

                default:
                    break;
            }

            await setProyectoData({
                ...proyectoData,
                [origin]: null,
                [name_origin]: null
            });      

            await dispatch(guardarCambios(true));
        }
    };

    const handleRadiobuttonCopy = ({target}) => {
        if(target.checked){
            if(target.value === 1 || target.value === '1'){
                setCopiar(true);
            }else{
                setCopiar(false);
                setProyectoData({
                    ...proyectoData,
                    id_broker_buyer: null,
                    id_broker_agent_buyer: null,
                    broker_represent: false
                });
        
                setBrokerBuyerSelectData(null);
                setBrokerAgentBuyerSelectData(null);
            }
        }
    }

    const onContractDateChange = ( date ) => {

        setDilligenceDaysFlag(false);

        let fecha_inicio = new Date(date);
        let fecha_final = new Date(date);
        let valor = proyectoData.dilligence_days;

        if(proyectoData.due_dilligence_end === null){
            valor = 17;
        }

        fecha_final = new Date(fecha_inicio.getTime() + (parseInt(valor) * 24 * 60 * 60 * 1000));

        setDilligenceDays(valor);

        setProyectoData({
            ...proyectoData,
            contract_buy: date,
            dilligence_days: valor,
            due_dilligence_end: fecha_final
        });

        dispatch(guardarCambios(true));
    }

    const onChangeDays = ({ target }) => {
        let fecha_inicio = proyectoData.contract_buy;
        let fecha_final = proyectoData.due_dilligence_end;
        let valor;

        if(target.value === '' || target.value === null){
            valor = 0;
            setDilligenceDaysFlag(true);
        }else{
            valor = target.value;
            setDilligenceDaysFlag(false);
        }

        if(fecha_inicio !== null){
            fecha_inicio = new Date(fecha_inicio);
            fecha_final = new Date(fecha_final);
            fecha_final = new Date(fecha_inicio.getTime() + (parseInt(valor) * 24 * 60 * 60 * 1000));

            setProyectoData({
                ...proyectoData,
                dilligence_days: parseInt(valor),
                due_dilligence_end: fecha_final
            });

            dispatch(guardarCambios(true));
        }
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                }),
                dispatch(guardarCambios(true))
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChange = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });

        dispatch(guardarCambios(true));
    };

    const handleGuardarCambiosModal = () => {
        setGuardarCambiosModal(true);
    }

    const handleCloseGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(desplegarModalCambios(false));
    }

    const handleCloseNoGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(guardarCambios(false));
        dispatch(desplegarModalCambios(false));
        dispatch(redireccionar(proyectos.paginaRedireccion));
        
    }

    const handleBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(true);
        }else{

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                id_broker: proyectoData.id_broker_seller
            });

            setBrokerAgentSellerModal(true);            
        }
    }

    const handleCloseModalBrokerSeller = (tipo) => {
        if(tipo){
            setBrokerSellerModal(false);

            setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentSellerModal(false);

            setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            }); 
        }
    }

    const handleBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(true);
        }else{

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                id_broker: proyectoData.id_broker_buyer
            });

            setBrokerAgentBuyerModal(true);            
        }
    }

    const handleCloseModalBrokerBuyer = (tipo) => {
        if(tipo){
            setBrokerBuyerModal(false);

            setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });
        }else{
            setBrokerAgentBuyerModal(false);   

            setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            });
        }
    }

    const handleViewBrokerBuyer = async () => {

        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_buyer !== null){
            let broker_buyer_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_buyer));
            if(broker_buyer_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_buyer_elegido[0].compania,
                    address: broker_buyer_elegido[0].direccion || '',
                    agents: broker_buyer_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_buyer_elegido[0]._id,
                    label: broker_buyer_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (let i = 0; i < broker_buyer_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_buyer_elegido[0].agentes[i]._id, label: broker_buyer_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_buyer !== null){
                    let broker_agent_elegido = await broker_buyer_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_buyer));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerBuyerData(datos_broker_view);
        await setBrokerBuyerSelectData(datos_broker_select);
        await setBrokersAgentsBuyerFilter(datos_broker_filter);
        await setBrokerAgentBuyerSelectData(datos_broker_agent_select);
    }

    const handleViewBrokerSeller = async () => {
        let datos_broker_view = {
            company: '',
            address: '',
            agents: [],
            officer_name: '',
            email: '',
            cell_phone: '',
            phone: '',
            license: '',
            public: ''
        }

        let datos_broker_select = null;
        
        let datos_broker_filter = [];

        let datos_broker_agent_select = null;

        if(proyectoData.id_broker_seller !== null){
            let broker_seller_elegido = await brokers.brokers?.filter(broker => String(broker._id) === String(proyectoData.id_broker_seller));
            if(broker_seller_elegido.length > 0){
                datos_broker_view = {
                    ...datos_broker_view,
                    company: broker_seller_elegido[0].compania,
                    address: broker_seller_elegido[0].direccion || '',
                    agents: broker_seller_elegido[0].agentes
                };

                datos_broker_select = {
                    value: broker_seller_elegido[0]._id,
                    label: broker_seller_elegido[0].compania
                };

                let options_brokers_agents = [];
                for (let i = 0; i < broker_seller_elegido[0].agentes?.length; i++) {
                    options_brokers_agents.push({ value: broker_seller_elegido[0].agentes[i]._id, label: broker_seller_elegido[0].agentes[i].officer_name });
                }
                datos_broker_filter = options_brokers_agents;

                if(proyectoData.id_broker_agent_seller !== null){
                    let broker_agent_elegido = await broker_seller_elegido[0].agentes?.filter(agente => String(agente._id) === String(proyectoData.id_broker_agent_seller));
                    if(broker_agent_elegido.length > 0){

                        datos_broker_view = {
                            ...datos_broker_view,
                            officer_name: broker_agent_elegido[0].officer_name || '',
                            email: broker_agent_elegido[0].email || '',
                            cell_phone: broker_agent_elegido[0].cell_phone || '',
                            phone: broker_agent_elegido[0].phone || '',
                            license: broker_agent_elegido[0].license || '',
                            public: broker_agent_elegido[0].public || ''
                        };

                        datos_broker_agent_select = {
                            value: broker_agent_elegido[0]._id,
                            label: broker_agent_elegido[0].officer_name
                        }
                    }
                }
            }
        }

        await setViewBrokerSellerData(datos_broker_view);
        await setBrokerSellerSelectData(datos_broker_select);
        await setBrokersAgentsSellerFilter(datos_broker_filter);
        await setBrokerAgentSellerSelectData(datos_broker_agent_select);
    }

    const handleInputsAddBrokerBuyer = ({ target }) => {
        setAddBrokerBuyerData({
            ...addBrokerBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentBuyer = ({ target }) => {
        setAddBrokerAgentBuyerData({
            ...addBrokerAgentBuyerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerSeller = ({ target }) => {
        setAddBrokerSellerData({
            ...addBrokerSellerData,
            [target.name]: target.value
        });
    }

    const handleInputsAddBrokerAgentSeller = ({ target }) => {
        setAddBrokerAgentSellerData({
            ...addBrokerAgentSellerData,
            [target.name]: target.value
        });
    }

    const handleChangeAddress = async (address, origin) => {
        switch(origin){
            case 'broker_buyer':
                await setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    address: address
                });
                break;

            case 'broker_seller':
                await setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    address: address
                });
                break;

            default:
                break;
        }
    };

    const handleAddBrokerSeller = async (e) => {
        e.preventDefault();
        if(addBrokerSellerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistBuy(addBrokerSellerData, 2));

            await setProyectoData({
                ...proyectoData,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_buy
            });

            await dispatch(guardarCambios(true));

            await setBrokerSellerSelectData({
                value: proyectos.masterlist?.id_broker_seller_buy,
                label: addBrokerSellerData.company
            });

            await setAddBrokerSellerData({
                ...addBrokerSellerData,
                company: '',
                address: ''
            });

            await setBrokerSellerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentSeller = async (e) => {
        e.preventDefault();
        if(addBrokerAgentSellerData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerAgentMasterlistBuy(addBrokerAgentSellerData, 2));

            await setProyectoData({
                ...proyectoData,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_buy,
                id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_buy
            });

            await dispatch(guardarCambios(true));

            if(addBrokerAgentSellerData.id_broker === null){
                await setBrokerSellerSelectData({
                    value: proyectos.masterlist?.id_broker_seller_buy,
                    label: 'Without name'
                });
            }

            await setBrokerAgentSellerSelectData({
                value: proyectos.masterlist?.id_broker_agent_seller_buy,
                label: addBrokerAgentSellerData.officer_name
            });

            await setAddBrokerAgentSellerData({
                ...addBrokerAgentSellerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            });

            await setBrokerAgentSellerModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleAddBrokerBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerBuyerData.company.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerMasterlistBuy(addBrokerBuyerData, 1));

            await setProyectoData({
                ...proyectoData,
                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy
            });

            await dispatch(guardarCambios(true));

            await setBrokerBuyerSelectData({
                value: proyectos.masterlist?.id_broker_buyer_buy,
                label: addBrokerBuyerData.company
            });

            await setAddBrokerBuyerData({
                ...addBrokerBuyerData,
                company: '',
                address: ''
            });

            await setBrokerBuyerModal(false);
        }else{
            dispatch(setAlert('First fill the company field', 'warning'));
        }
    }

    const handleAddBrokerAgentBuyer = async (e) => {
        e.preventDefault();
        if(addBrokerAgentBuyerData.officer_name.replace(/\s/g, '') !== ''){
            await dispatch(agregarBrokerAgentMasterlistBuy(addBrokerAgentBuyerData, 1));

            await setProyectoData({
                ...proyectoData,
                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy,
                id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_buy
            });

            await dispatch(guardarCambios(true));

            if(addBrokerAgentBuyerData.id_broker === null){
                await setBrokerBuyerSelectData({
                    value: proyectos.masterlist?.id_broker_buyer_buy,
                    label: 'Without name'
                });
            }

            await setBrokerAgentBuyerSelectData({
                value: proyectos.masterlist?.id_broker_agent_buyer_buy,
                label: addBrokerAgentBuyerData.officer_name
            });

            await setAddBrokerAgentBuyerData({
                ...addBrokerAgentBuyerData,
                officer_name: '',
                email: '',
                cell_phone: '',
                phone: '',
                license: '',
                public: ''
            });

            await setBrokerAgentBuyerModal(false);
        }else{
            dispatch(setAlert('First fill the name field', 'warning'));
        }
    }

    const handleRenegotiation = () => {
        if(proyectos.detalles?.proyecto?.asking_price !== null && proyectos.masterlist?.purchase_price !== null){
            setRenegotiationModal(true);
        }else{
            dispatch(setAlert('Complete all fields to renegotiate', 'warning'));
        }
    }

    const handleCloseModalRenegotiation = () => {
        setRenegotiationModal(false);
        setRenegotiationData({
            ...renegotiationData,
            purchase_price: null,
            purchase_price_date: null,
            purchase_price_note: null,
            purchase_price_file_name: null,
            purchase_price_file: null,
            purchase_price_extension: null
        });
    }

    const handleInputsRenegotiation = ({ target }) => {
        setRenegotiationData({
            ...renegotiationData,
            [target.name]: target.value
        });
    }

    const handleInputsNumberRenegotiation = (name, value) => {
        setRenegotiationData({
            ...renegotiationData,
            [name]: value
        });

        let valores = {
            ...renegotiationData,
            [name]: value
        };

        if(name === 'escrow_deposit_amount'){
            valores.escrow_deposit_release = null;
            valores.funds_applied_purchase_price = null;
        }

        setRenegotiationData(valores);
    }

    const onChangeFileRenegotiation = () => {
        let extensiones = [];
        let nombre_separado = [];

        for(let i = 0; i < offer_price_ref?.current?.files?.length; i++){
            nombre_separado = offer_price_ref.current.files[i].name.split('.');
            extensiones.push(nombre_separado[nombre_separado.length - 1].toLowerCase());
        }

        setRenegotiationData({
            ...renegotiationData,
            purchase_price_file: offer_price_ref.current.files,
            purchase_price_extension: extensiones
        });
    }

    const handleSubmitRenegotiation = async (e) => {
        e.preventDefault();
        setSpinner(true);

        if(renegotiationData.purchase_price !== null && renegotiationData.purchase_price.replace(/\s/g, '') !== ''){

            let formData = new FormData();
            formData.append('id_proyecto', renegotiationData.id_proyecto);
            formData.append('id_status', renegotiationData.id_status);
            formData.append('purchase_price', renegotiationData.purchase_price);
            formData.append('purchase_price_date', renegotiationData.purchase_price_date);
            formData.append('purchase_price_note', renegotiationData.purchase_price_note);
            formData.append('purchase_price_file_name', renegotiationData.purchase_price_file_name);

            if(renegotiationData.purchase_price_extension !== null){
                for (let i = 0; i < renegotiationData.purchase_price_extension.length; i++) {
                    formData.append('purchase_price_extension', renegotiationData.purchase_price_extension[i]);
                }   
            }

            if(renegotiationData.purchase_price_file !== null){
                for (let i = 0; i < renegotiationData.purchase_price_file.length; i++) {
                    formData.append('purchase_price_file', renegotiationData.purchase_price_file[i]);
                }   
            }

            dispatch(renegotiateOffer(formData));
            setProyectoData({
                ...proyectoData,
                purchase_price: renegotiationData.purchase_price !== null ? renegotiationData.purchase_price : proyectoData.purchase_price,
                purchase_price_history: [
                    ...proyectoData.purchase_price_history,
                    {
                        amount: renegotiationData.purchase_price !== null ? renegotiationData.purchase_price : proyectoData.purchase_price,
                        type: 0,
                        fecha: renegotiationData.purchase_price_date !== null ? new Date(renegotiationData.purchase_price_date) : new Date()
                    }
                ]
            });

            setTimeout(async() => {
                await dispatch(guardarCambios(true));
                setSpinner(false);
            }, 0);
            

            setRenegotiationData({
                ...renegotiationData,
                purchase_price: null,
                purchase_price_date: null,
                purchase_price_note: null,
                purchase_price_file_name: null,
                purchase_price_file: null,
                purchase_price_extension: null
            });
        }else{
            dispatch(setAlert('Enter at least one field to renegotiate', 'warning'));
        }
    }

    const handleSubmitModal = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarOffer(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        
        dispatch(redireccionar(proyectos.paginaRedireccion));
    };

    const tableRow = (items) => {
        if(items !== null){

            items = items.filter(archivo => (archivo.subseccion !== null && (archivo.subseccion * 2) === (sectionInformation - 1)));

            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td>{item.nombre || '-'}</td>
                                            <td>{item.extension || '-'}</td>
                                            <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                            <td>
                                                <button className="btn" type="button" onClick={() => { handleIconoArchivo(item._id) }}><i className="fa fa-file-download" style={styles.buttonIcon} /></button>
                                                <button className="btn" type="button" onClick={() => { handleEditarArchivo(item) }}><i className="fas fa-edit" style={styles.buttonIcon} /></button>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">There are no rows to display.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    //Funciones de guardado
    const handleSubmit = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarOffer(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const handleContinue = async (e) => {
        e.preventDefault();

        if (proyectoData.direccion !== '' && proyectoData.latitud !== '' && proyectoData.longitud !== '' && proyectoData.asking_price !== null && proyectoData.purchase_price !== null && proyectoData.contract_buy !== null && proyectoData.due_dilligence_end !== null && proyectoData.escrow_deposit_amount !== null && proyectoData.escrow_closing !== null) {
            setSpinner(true);
            setTimeout(async() => {
                await setGuardarCambiosModal(false);
                await dispatch(continuarOffer(proyectoData));
                await dispatch(masterlistProyecto(proyectoData.id));
                await dispatch(detallesProyecto(proyectoData.id));
                setSpinner(false);
                await dispatch(seleccionarSeccionMasterList('Buying escrow'));
            }, 0);
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
            setSpinner(false);
        }
    };

    useEffect(() => {

        let options_broker = [];

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options_broker.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options_broker);

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < brokers.brokers?.length; i++) {
            options.push({ value: brokers.brokers[i]._id, label: brokers.brokers[i].compania });
        }

        setBrokersFilter(options);
    }, [brokers]);

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    useEffect(() => {
        if (proyectoData.direccion !== '' && proyectoData.latitud !== '' && proyectoData.longitud !== '' && proyectoData.asking_price !== null && proyectoData.purchase_price !== null && proyectoData.contract_buy !== null && proyectoData.due_dilligence_end !== null && proyectoData.escrow_deposit_amount !== null && proyectoData.escrow_closing !== null){
            setMostrarBoton(true);
        } else {
            setMostrarBoton(false);
        }

        handleViewBrokerSeller();

        handleViewBrokerBuyer();

    }, [proyectoData]);

    useEffect(() => {

        if (proyectos.detalles !== undefined && proyectos.detalles !== null) {
            setNewFileData({
                ...newFileData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(!proyectos.guardar_cambios){
            let dias_separacion = 0;
            if(proyectos.masterlist?.contract_buy !== null && proyectos.masterlist?.due_dilligence_end !== null){
                dias_separacion = new Date(proyectos.masterlist?.due_dilligence_end).getTime() - new Date(proyectos.masterlist?.contract_buy).getTime();
                dias_separacion = Math.floor(dias_separacion / (1000 * 3600 * 24));
            }

            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id,
                status: proyectos.detalles?.proyecto?.status?._id,
                direccion: proyectos.detalles?.proyecto?.direccion,
                latitud: proyectos.detalles?.proyecto?.latitud,
                longitud: proyectos.detalles?.proyecto?.longitud,
                apn: proyectos.detalles?.proyecto?.apn,
                asking_price: proyectos.detalles?.proyecto?.asking_price,
                creacion: proyectos.detalles?.proyecto?.creacion,
                envio: proyectos.detalles?.proyecto?.envio,
                purchase_price: proyectos.masterlist?.purchase_price,
                contract_buy: proyectos.masterlist?.contract_buy,
                dilligence_days: dias_separacion,
                due_dilligence_end: proyectos.masterlist?.due_dilligence_end,
                escrow_deposit_amount: proyectos.masterlist?.escrow_deposit_amount,
                escrow_closing: proyectos.masterlist?.escrow_closing,

                id_broker_buyer: proyectos.masterlist?.id_broker_buyer_buy,
                id_broker_agent_buyer: proyectos.masterlist?.id_broker_agent_buyer_buy,
                broker_represent: proyectos.masterlist?.broker_represent_buy,
                id_broker_seller: proyectos.masterlist?.id_broker_seller_buy,
                id_broker_agent_seller: proyectos.masterlist?.id_broker_agent_seller_buy,

                purchase_price_history: proyectos.masterlist?.purchase_price_history
            });

            setDilligenceDays(dias_separacion);

            setDataTabla({
                archivosTabla: proyectos.files
            });

            setRenegotiationData({
                ...renegotiationData,
                id_proyecto: proyectos.detalles?.proyecto?._id,
                id_status: proyectos.detalles?.proyecto?.tipo_status
            });
        }

        if(proyectos.modal_guardado_flipping === true){
            handleGuardarCambiosModal();
        }
    }, [proyectos]);

    useEffect(() => {
        if(copiar){
            handleCopyBroker();
        }
    }, [copiar]);

    return (
        <Fragment>
            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}

            {/* Modal de guardado de cambios */}
            <Modal backdrop="static" show={guardarCambiosModal} onHide={() => {
                if(preguntarGuardar){
                    handleCloseGuardarCambiosModal();
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit?</p>
                    :
                        <p>Do you want to save the changes?</p>
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                            <button className="btn btn-success" onClick={() => handleCloseNoGuardarCambiosModal()}>Yes</button>
                        </>
                        
                        :
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => handleCloseNoGuardarCambiosModal()}>No</button>
                            <button className="btn btn-success" onClick={handleSubmitModal}>Yes</button>
                        </>
                    }
                    
                </Modal.Footer>
            </Modal>

            {/* Modal de broker vendedor de compra */}
            <Modal backdrop="static" show={brokerSellerModal} size="lg" onHide={() => {
                setBrokerSellerModal(false);
                setAddBrokerSellerData({
                    ...addBrokerSellerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (buy)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerSeller(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addBrokerSellerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_seller')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente vendedor de compra */}
            <Modal backdrop="static" show={brokerAgentSellerModal} size="lg" onHide={() => {
                setBrokerAgentSellerModal(false);
                setAddBrokerAgentSellerData({
                    ...addBrokerAgentSellerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (listing) ({brokerSellerSelectData ? brokerSellerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.celular} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="phone" className="form-control" country={'us'} value={addBrokerAgentSellerData.telefono} onChange={phone => {setAddBrokerAgentSellerData({...addBrokerAgentSellerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentSeller(e)} value={addBrokerAgentSellerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerSeller(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentSeller}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker comprador de compra */}
            <Modal backdrop="static" show={brokerBuyerModal} size="lg" onHide={() => {
                setBrokerBuyerModal(false);
                setAddBrokerBuyerData({
                    ...addBrokerBuyerData,
                    company: '',
                    address: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker (buy)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>If the broker you were looking for is not yet created, you must fill out the following form and click <b>Save</b></p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Company:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="company" className="form-control" type="text" onChange={e => handleInputsAddBrokerBuyer(e)}/>
                            </div>
                        </div>

                        <PlacesAutocomplete
                            value={addBrokerBuyerData.address || ''}
                            onChange={(value) => handleChangeAddress(value, 'broker_buyer')}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className="col-md-12 form-group">
                                    <label className="font-weight-bold">Address</label>
                                    <input
                                        {...getInputProps({
                                            className: 'location-search-input form-control',
                                        })}
                                        autoComplete='off'
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const id = uuid();
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={id}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer(true)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de broker agente comprador de compra */}
            <Modal backdrop="static" show={brokerAgentBuyerModal} size="lg" onHide={() => {
                setBrokerAgentBuyerModal(false);
                setAddBrokerAgentBuyerData({
                    ...addBrokerAgentBuyerData,
                    officer_name: '',
                    email: '',
                    cell_phone: '',
                    phone: '',
                    license: '',
                    public: ''
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Broker agent information (buy) ({brokerBuyerSelectData ? brokerBuyerSelectData.label : ''})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="officer_name" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="email" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="cell_phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.celular} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="phone" className="form-control" country={'us'} value={addBrokerAgentBuyerData.telefono} onChange={phone => {setAddBrokerAgentBuyerData({...addBrokerAgentBuyerData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="license" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' name="public" className="form-control" type="text" onChange={e => handleInputsAddBrokerAgentBuyer(e)} value={addBrokerAgentBuyerData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalBrokerBuyer(false)}>Cancel</button>
                    <button className="btn btn-success" onClick={handleAddBrokerAgentBuyer}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de renegociacion de venta */}
            <Modal backdrop="static" show={renegotiationModal} onHide={() => {
                if(preguntarGuardar){
                    setRenegotiationModal(false);
                    setRenegotiationData({
                        ...renegotiationData,
                        purchase_price: null,
                        purchase_price_date: null,
                        purchase_price_note: null,
                        purchase_price_file_name: null,
                        purchase_price_file: null,
                        purchase_price_extension: null
                    });
                }
                setPreguntarGuardar(true);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Renegotiation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preguntarGuardar ? 
                        <p>Are you sure you want to exit without saving changes?</p>
                    :
                        <div className="row">
                            <div id="accordion-offer-offer_price" className="col-md-12 form-group">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="font-weight-bold w-100">New counter price: </label>
                                    <div className='d-flex mb-2'>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Date</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-calendar" data-toggle="collapse" data-target="#collapseDate" aria-expanded="true" aria-controls="collapseDate"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">History</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fa fa-history" data-toggle="collapse" data-target="#collapseOfferPrice" aria-expanded="true" aria-controls="collapseOfferPrice"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Add a note</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                                <i className="fas fa-pencil-alt btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseOfferPriceNote" aria-expanded="true" aria-controls="collapseOfferPriceNote"/>
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Upload a file</Tooltip>}
                                        >
                                            <button className="btn_renegociacion">
                                            <i className="fa fa-file-upload btn_renegociacion mr-2" data-toggle="collapse" data-target="#collapseOfferPriceFile" aria-expanded="true" aria-controls="collapseOfferPriceFile"/>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput
                                        value={renegotiationData.purchase_price || ''}
                                        name="purchase_price"
                                        className="form-control text-center"
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleInputsNumberRenegotiation(name, value)}
                                    />
                                </div>

                                { spinner ? 
                                        <div className="d-flex justify-content-center align-items-center">
                                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                                <div className="sk-chase-dot"></div>
                                                <div className="sk-chase-dot"></div>
                                                <div className="sk-chase-dot"></div>
                                                <div className="sk-chase-dot"></div>
                                                <div className="sk-chase-dot"></div>
                                                <div className="sk-chase-dot"></div>
                                            </div>
                                        </div> 
                                    :
                                        null
                                }
                                
                                <div id="collapseDate" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-offer-offer_price">
                                    <div className="col-md-12 form-group">
                                        <label>Date:</label>
                                        <DatePicker name="purchase_price_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={renegotiationData.purchase_price_date ? renegotiationData.purchase_price_date : new Date()} onChange={date => {setRenegotiationData({...renegotiationData, purchase_price_date: date})}} monthsShown={2}/>
                                    </div>        
                                </div>

                                <div id="collapseOfferPrice" className="col-lg-12 row collapse contenedor_renegociacion" aria-labelledby="headingOne" data-parent="#accordion-offer-offer_price">
                                    <label key="asking_price_0" className="col-sm-12">
                                        <b className="ml-2">Asking price:</b> <span className="value_renegotiation">{ numberToDecimal(proyectoData.asking_price, '$ ') }: </span> {new Date(proyectos.detalles?.proyecto?.envio).toLocaleDateString('en-US')}
                                    </label>
                                    {   
                                        proyectoData.purchase_price_history?.length > 0 ?
                                            proyectoData.purchase_price_history?.map((purchase_price, index, array) => {
                                                if(index === 0){
                                                    return (
                                                        <label key={purchase_price._id} className="col-sm-12">
                                                            <b className="ml-2">Offer price</b> <span className="value_renegotiation">{ numberToDecimal(purchase_price.amount, '$ ') }: </span> {new Date(purchase_price.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                purchase_price.id_archivo !== null && purchase_price.id_archivo !== undefined && purchase_price.id_archivo?.length > 0 ?
                                                                    purchase_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }else{
                                                    return (
                                                        <label key={purchase_price._id} className="col-sm-12">
                                                            <b className="ml-2">{purchase_price.type === 0 || purchase_price.type === '0' ? 'Counter' : 'Adjustment'} {index}°</b> <span className="value_renegotiation">{ numberToDecimal(purchase_price.amount, '$ ') }: </span> {new Date(purchase_price.fecha).toLocaleDateString('en-US')}
                                                            {
                                                                purchase_price.id_archivo !== null && purchase_price.id_archivo !== undefined && purchase_price.id_archivo?.length > 0 ?
                                                                    purchase_price.id_archivo?.map((id_archivo, index) => {
                                                                        return <button className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                                    })
                                                                :
                                                                    null
                                                            }
                                                        </label>
                                                    )
                                                }
                                            })
                                        :
                                            <label className="col-sm-12">
                                                <span>There is no history yet</span>
                                            </label>
                                    }
                                </div>

                                <div id="collapseOfferPriceNote" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-offer-offer_price">
                                    <div className="col-md-12 form-group">
                                        <label>Note:</label>
                                        <textarea className="form-control" name="purchase_price_note" onChange={e => handleInputsRenegotiation(e)}></textarea>
                                    </div>        
                                </div>

                                <div id="collapseOfferPriceFile" className="col-lg-12 collapse contenedor_renegociacion pb-3" aria-labelledby="headingOne" data-parent="#accordion-offer-offer_price">
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File name:</label>
                                        <div className="contenedor_datepicker">
                                            <input autoComplete='off' type="text" className="form-control" name="purchase_price_file_name" onChange={e => handleInputsRenegotiation(e)}/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold">File:</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input form-control" name="purchase_price_file" id="purchase_price_file" ref={offer_price_ref} onChange={onChangeFileRenegotiation} aria-describedby="view_file_purchasePriceFile" multiple/>
                                                <label className="custom-file-label">{renegotiationData.purchase_price_file ? 'Files Selected' : 'Choose files'}</label>
                                            </div>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(renegotiationData.purchase_price_file)}><i class="fas fa-eye"></i> View Files</button>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        preguntarGuardar ?
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => setPreguntarGuardar(false)}>No</button>
                            <button className="btn btn-success" onClick={() => {handleCloseModalRenegotiation(); setPreguntarGuardar(false);}}>Yes</button>
                        </>
                        
                        :
                        <>
                            <button className="btn btn-danger mr-3" onClick={() => handleCloseModalRenegotiation()}>Cancel</button>
                            <button className="btn btn-info" onClick={handleSubmitRenegotiation}>Save and New</button>
                            <button className="btn btn-success" onClick={(e) => {
                                handleSubmitRenegotiation(e);
                                setRenegotiationModal(false);
                            }}>Save and Close</button>
                        </>
                    }
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo archivo */}
            <Modal backdrop="static" show={newFileModal} size="lg" onHide={() => {
                handleCloseAgregarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New file to upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsFile(e)} value={newFileData.nombre || ''} disabled={statusSaveFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="file" ref={file_ref} onChange={onChangeFile} aria-describedby="view_file_file"/>
                                    <label className="custom-file-label">{newFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarArchivo()} disabled={statusSaveFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarArchivo} disabled={statusSaveFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar archivo */}
            <Modal backdrop="static" show={editFileModal} size="lg" onHide={() => {
                handleCloseEditarArchivo();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12">
                            <p style={styles.info}>Select the file from your computer, name it, and <b>save it</b>.</p>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File name:</label>
                            <div className="contenedor_datepicker">
                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditFile(e)} value={editFileData.nombre || ''} disabled={statusSaveEditFile ? false : true}/>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label> <br></br>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={file_edit_ref} onChange={onChangeEditFile} aria-describedby="view_file_editFile"/>
                                    <label className="custom-file-label">{editFileData.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editFileData.archivo)}><i class="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarArchivo()} disabled={statusSaveEditFile ? false : true}>Cancel</button>
                    <button className="btn btn-success" onClick={handleGuardarEditarArchivo} disabled={statusSaveEditFile ? false : true}>Save</button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 1 ?
                        <button type="button" className="btn btn-warning ml-2 btn-sm" onClick={() => handleRenegotiation()} disabled={spinner ? true : false}>Renegotiation</button>
                    :   null
                }
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 1 ? 
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={spinner ? true : false}>Save</button>
                    :   null
                }
                {
                    mostrarBoton && (proyectos.detalles?.proyecto?.identificador_status <= 1) ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleContinue} disabled={spinner ? true : false}>Save and Continue</button>
                    :   null
                }
                
            </div>

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={() => handleRenegotiation()}>
                            Renegotiation
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleContinue}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>
                
            <form className="col-md-12 pb-3" autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" style={{display:'none'}} />
                
                { spinner ? 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                            </div>
                        </div> 
                    :
                        null
                }
                
                <div className="row">
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold" onClick={() => newNote('Project Type')}>Project Type:</label> <br></br>
                        {
                            proyectos.detalles?.proyecto?.nombre_proyecto && !proyectos.edicion_proyecto ?
                                <label>{proyectos.detalles?.proyecto?.nombre_proyecto}</label>
                            :
                                <input autoComplete='off' type="text" className="form-control" value={proyectos.detalles?.proyecto?.nombre_proyecto} disabled />
                        }
                    </div>

                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold" onClick={() => newNote('Property Type')}>Property Type:</label> <br></br>
                        {
                            proyectos.detalles?.proyecto?.nombre_construccion && !proyectos.edicion_proyecto ?
                                <label>{proyectos.detalles?.proyecto?.nombre_construccion}</label>
                            :
                                <input autoComplete='off' type="text" className="form-control" value={proyectos.detalles?.proyecto?.nombre_construccion} disabled />
                        }
                    </div>

                    <PlacesAutocomplete
                        value={proyectoData.direccion || ''}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold" onClick={() => newNote('Address')}>Address</label>
                                
                                {
                                    proyectoData.direccion !== null && !proyectos.edicion_proyecto ?
                                        <div>
                                            <label><i className="fas fa-map-marker-alt"></i> {proyectoData.direccion}</label>
                                        </div>
                                    :
                                        <div>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <i className="fas fa-map-marker-alt"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    {...getInputProps({
                                                        className: 'location-search-input form-control'
                                                    })}
                                                    disabled={true}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const id = uuid();
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            key={id}
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                }
                            </div>
                        )}

                    </PlacesAutocomplete>

                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">APN:</label>
                        {
                            proyectos.detalles?.proyecto?.apn !== null && !proyectos.edicion_proyecto ?
                                <div className='input-group'>
                                    <label># {proyectoData.apn}</label>
                                </div>
                            :
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input autoComplete='off' className="form-control" type="text" name="apn" onChange={e => onChange(e)} value={proyectoData.apn || ''}/>
                                </div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className={'col-md form-group campo_fecha'}>
                        <label className="font-weight-bold" onClick={() => newNote('Create offer date')}>Create offer date:</label>
                        <div>
                            {
                                proyectoData.creacion !== null && !proyectos.edicion_proyecto ?
                                    <div className="d-flex">
                                        <label><i className="fas fa-calendar-alt"></i> {proyectoData.creacion ? new Date (proyectoData.creacion).toISOString().substring(0, 10) : 'Not defined'}</label>
                                    </div>
                                :
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1"> 
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker monthsShown={2} disabled name="contract_buy" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.creacion ? new Date(proyectoData.creacion) : '' } />
                                    </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md form-group campo_fecha'}>
                        <label className="font-weight-bold" onClick={() => newNote('Send offer date')}>Send offer date:</label>
                        <div>
                            {
                                proyectoData.envio !== null && !proyectos.edicion_proyecto ?
                                    <div className="d-flex">
                                        <label><i className="fas fa-calendar-alt"></i> {proyectoData.envio ? new Date (proyectoData.envio).toISOString().substring(0, 10) : 'Not defined'}</label>
                                    </div>
                                :
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1"> 
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker monthsShown={2} disabled name="contract_buy" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ proyectoData.envio ? new Date(proyectoData.envio) : '' } />
                                    </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md form-group campo_fecha' + (proyectoData.contract_buy !== null ? ''  : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Accepted contract date')}>Accepted contract date:</label>
                        <div className="contenedor_datepicker">
                            {
                                proyectos.masterlist?.contract_buy !== null && proyectoData.contract_buy !== null && !proyectos.edicion_proyecto ?
                                    <div className='d-flex'>
                                        <label><i className="fas fa-calendar-alt"></i> {proyectoData.contract_buy ? new Date(proyectoData.contract_buy).toISOString().substring(0, 10) : 'Not defined'}</label>
                                    </div>
                                :
                                    <div className="d-flex">
                                        <span className="input-group-text" data-focus="Date" onClick={(e) => focusCampo(e)}>
                                            <i className="fas fa-calendar-alt" data-focus="Date" onClick={(e) => focusCampo(e)}></i>
                                        </span>
                                        <DatePicker 
                                            id="Date"
                                            monthsShown={2} 
                                            name="contract_buy" 
                                            className="form-control text-center" 
                                            dateFormat="MM/dd/yyyy" 
                                            selected={ proyectoData.contract_buy ? new Date(proyectoData.contract_buy) : '' } 
                                            // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                            onChange={ date => {onContractDateChange(date)}} 
                                            disabled={proyectos.masterlist?.contract_buy !== null ? (proyectos.edicion_proyecto ? false : true) : false} 
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                    
                    {
                        proyectoData.contract_buy !== null ?
                            <div className="col-md form-group campo_numero_lg">
                                <label className="font-weight-bold" onClick={() => newNote('Dilligence days')}>Dilligence days:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        proyectos.masterlist?.due_dilligence_end !== null && !proyectos.edicion_proyecto ?
                                            <div className='d-flex'>
                                                <label><i className='fas fa-calendar-day'/> {dilligenceDays}</label>
                                            </div>
                                        :
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" data-focus="dilligence" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-day" data-focus="dilligence" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                </div>
                                                <input
                                                    id="dilligence"
                                                    autoComplete='off'
                                                    className="form-control"
                                                    type="number"
                                                    min={0}
                                                    value={dilligenceDays} 
                                                    onChange={e => {
                                                        setDilligenceDays(e.target.value);
                                                        onChangeDays(e);
                                                    }} 
                                                    disabled={proyectos.masterlist?.due_dilligence_end !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }
                    
                    {
                        proyectoData.contract_buy !== null ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip" style={{fontSize: 18}}>{`${proyectoData.due_dilligence_end !== null ? (Math.floor((new Date(proyectoData.due_dilligence_end).getTime() - new Date(proyectoData.contract_buy).getTime()) / (1000 * 3600 * 24)) <= 17 ? 'This date should be 17 days greater than the Accepted contract date' : '') : ''}`}</Tooltip>}
                            >
                                <div className={'col-md form-group campo_fecha' + (proyectoData.due_dilligence_end !== null ? (Math.floor((new Date(proyectoData.due_dilligence_end).getTime() - new Date(proyectoData.contract_buy).getTime()) / (1000 * 3600 * 24)) <= 17 ? ' fecha-superior' : '') : ' campo-recordatorio')}>
                                    <label className="font-weight-bold" onClick={() => newNote('Due dilligence end date')}>Due dilligence end date:</label>
                                    <div className="contenedor_datepicker">
                                       {
                                            proyectos.masterlist?.due_dilligence_end !== null && !proyectos.edicion_proyecto ?
                                                <div className='d-flex' style={{color: '#000'}}>
                                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.due_dilligence_end ? new Date(proyectoData.due_dilligence_end).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="due" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="due" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker id='due' name="due_dilligence_end" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ dilligenceDaysFlag === true ? '' : (proyectoData.due_dilligence_end ? new Date(proyectoData.due_dilligence_end) : '') } 
                                                    // minDate={new Date(proyectos.detalles?.proyecto?.envio)} 
                                                    onChange={ date => {setProyectoData({ ...proyectoData, due_dilligence_end: date }); handleAlertarFechas(date); dispatch(guardarCambios(true));} } monthsShown={2}
                                                    disabled={(proyectos.masterlist?.due_dilligence_end !== null ? (proyectos.edicion_proyecto ? false : true) : false) || (dilligenceDaysFlag === true ? (proyectos.edicion_proyecto ? false : true) : false)}/>
                                                </div>
                                       }
                                    </div>
                                </div>
                            </OverlayTrigger>
                        :
                            null
                    }

                    {
                        proyectoData.contract_buy !== null ?
                            <div className={'col-md form-group campo_dinero' + (proyectoData.escrow_deposit_amount !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Escrow deposit amount')}>Escrow deposit amount:</label>
                                {
                                    proyectos.masterlist?.escrow_deposit_amount !== null && !proyectos.edicion_proyecto?
                                        <div className='d-flex'>
                                            <label>{ numberToDecimal(proyectoData.escrow_deposit_amount, '$ ') }</label>
                                        </div>
                                    :
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" data-focus="escrowDeposit" onClick={(e) => focusCampo(e)}>
                                                    <i className="fas fa-dollar-sign" data-focus="escrowDeposit" onClick={(e) => focusCampo(e)}></i>
                                                </span>
                                            </div>
                                            <CurrencyInput
                                                id='escrowDeposit'
                                                name="escrow_deposit_amount"
                                                className="form-control text-center"
                                                value={proyectoData.escrow_deposit_amount || ''}
                                                decimalsLimit={2}
                                                groupSeparator=","
                                                decimalSeparator="."
                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                disabled={proyectos.masterlist?.escrow_deposit_amount !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                                }
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.contract_buy !== null ?
                            <div className={'col-md form-group campo_fecha' + (proyectoData.escrow_closing !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Estimated escrow closing date')}>Estimated escrow closing date:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        proyectos.masterlist?.escrow_closing !== null && !proyectos.edicion_proyecto ?
                                            <div className='d-flex'>
                                                <label><i className='fas fa-calendar-alt'/> {proyectoData.escrow_closing ? new Date(proyectoData.escrow_closing).toISOString().substring(0, 10) : 'Not defined'}</label>
                                            </div>
                                        :
                                            <div className="d-flex">
                                                <span className="input-group-text" data-focus="estimated" onClick={(e) => focusCampo(e)}>
                                                    <i className="fas fa-calendar-alt" data-focus="estimated" onClick={(e) => focusCampo(e)}></i>
                                                </span>
                                                <DatePicker id='estimated' name="escrow_closing" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={proyectoData.escrow_closing ? new Date(proyectoData.escrow_closing) : ''} 
                                                // minDate={new Date(proyectoData.due_dilligence_end)} 
                                                onChange={ date => {setProyectoData({ ...proyectoData, escrow_closing: date }); handleAlertarFechas(date); dispatch(guardarCambios(true));} } monthsShown={2}
                                                disabled={proyectos.masterlist?.escrow_closing !== null ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                            </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }
                </div>                

                <div className="row">
                    <div className={'col-md-2 form-group campo_dinero' + (proyectoData.asking_price !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Asking price')}>Asking price:</label>
                        {
                            proyectos.detalles?.proyecto?.asking_price !== null && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <label>{ numberToDecimal(proyectoData.asking_price, '$ ') }</label>
                                </div>
                            :
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput
                                        name="asking_price"
                                        className="form-control text-center"
                                        value={proyectoData.asking_price || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => onNumberChange(name, value)}
                                        disabled={proyectos.detalles?.proyecto?.asking_price !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                    />
                                </div>
                        }
                    </div>

                    <div className={'col-md-2 form-group campo_dinero' + (proyectoData.purchase_price !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Offer price')}>Offer price:</label>
                        {
                            proyectoData.purchase_price !== null && !proyectos.edicion_proyecto ?
                                <div className='d-flex'>
                                    <lable>{ numberToDecimal(proyectoData.purchase_price, '$ ') }</lable>
                                </div>
                            :
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <CurrencyInput
                                        name="purchase_price"
                                        className="form-control text-center"
                                        value={proyectoData?.purchase_price_history?.length > 0 ? (proyectoData?.purchase_price_history[0]?.amount || '') : ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => onNumberChange(name, value)}
                                        disabled={true}
                                    />
                                </div>
                        }
                    </div>

                    {
                        proyectoData.purchase_price_history?.length > 1 ?
                            [...proyectoData.purchase_price_history]?.map((purchase_price, index, array) => {
                                if(index === 0){
                                    return null
                                } else {
                                    return (
                                        <div className="col-md-2 form-group campo_dinero">
                                            <label key={purchase_price._id} className="font-weight-bold">{purchase_price.type === 0 || purchase_price.type === '0' ? 'Counter' : 'Adjustment'} {index}°</label>
                                            {
                                                purchase_price.id_archivo !== null && purchase_price.id_archivo !== undefined && purchase_price.id_archivo?.length > 0 ?
                                                    purchase_price.id_archivo?.map((id_archivo, index) => {
                                                        return <button type="button" className="btn_descarga_archivo" onClick={() => {handleIconoArchivo(id_archivo)}}><i className="fa fa-file-download mr-2"/></button>
                                                    })
                                                :
                                                    null
                                            }
                                            {
                                                !proyectos.edicion_proyecto ?
                                                    <div className='d-flex'>
                                                        <label>{ numberToDecimal(purchase_price.amount, '$ ') }</label>
                                                    </div>
                                                :
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1">
                                                                <i className="fas fa-dollar-sign"></i>
                                                            </span>
                                                        </div>
                                                        <CurrencyInput
                                                            name={`counter_${index}`}
                                                            className="form-control text-center"
                                                            value={(Math.round((purchase_price.amount) * 100) / 100).toFixed(0)}
                                                            decimalsLimit={2}
                                                            groupSeparator=","
                                                            decimalSeparator="."
                                                            disabled={proyectos.masterlist?.purchase_price !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    )
                                }
                            }) : null
                    }

                    {
                        proyectos.masterlist?.propietario_vende === false 
                            ?
                                <div>
                                    <label className="font-weight-bold" onClick={() => newNote('Listing agent representing buyer')}>Listing agent representing buyer?:</label><br/>
                                    {
                                        !proyectos.edicion_proyecto ?
                                            <div className='d-flex'>
                                                <label>{(proyectoData.broker_represent === true) ? 'Yes' : 'No'}</label>
                                            </div>
                                        :
                                            <div className="col-md-3 form-group">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="copy_information" value="1" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === true ? true : false} disabled={proyectos.masterlist?.broker_represent_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="copy_information" value="2" onChange={e => handleRadiobuttonCopy(e)} checked={proyectoData.broker_represent === false ? true : false} disabled={proyectos.masterlist?.broker_represent_buy === true || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false}/>
                                                    <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                                </div>
                                            </div>
                                    }
                                </div>
                            :
                                null
                    }
                </div>
                
                {
                    proyectos.masterlist?.propietario_vende === false ?
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <ul className="nav nav-tabs nav-fill">
                                    <li className="nav-item">
                                        <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 2 || sectionInformation === 3 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(2)}>Listing{ proyectoData.broker_represent === true ? ' & Buyer' : ''} broker</button>
                                    </li>

                                    {
                                        proyectoData.broker_represent === true ?
                                            null
                                        :
                                            <li className="nav-item">
                                                <button type="button" className={'nav-link btn_tab_information border border-dark' + (sectionInformation === 4 || sectionInformation === 5 ? ' text-light bg-dark' : '')} onClick={() => setSectionInformation(4)}>Buyer broker</button>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    :
                        null
                }

                <div className={(sectionInformation === 2 || sectionInformation === 3) && proyectos.masterlist?.propietario_vende === false ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                    {
                        sectionInformation === 2 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(3)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_seller" className="select-group" aria-describedby="button-broker-seller" isClearable={true} options={brokersFilter} value={brokerSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_seller', 'id_broker_agent_seller')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_seller_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_seller_buy !== null || proyectoData.id_broker_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-seller" onClick={() => handleBrokerSeller(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.address !== null) ? viewBrokerSellerData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_seller" className="select-group" aria-describedby="button-broker-agent-seller" isClearable={true} options={brokersAgentsSellerFilter} value={brokerAgentSellerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_seller', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_seller_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_seller_buy !== null || proyectoData.id_broker_agent_seller !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-seller" onClick={() => handleBrokerSeller(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerSellerData.email !== null) ? viewBrokerSellerData.email : ''}</p>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.cell_phone !== '') ? viewBrokerSellerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerSellerData.phone !== '') ? viewBrokerSellerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerSellerData.license !== '') ? `# ${viewBrokerSellerData.license}` : '# -'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerSellerData.public !== '') ? `# ${viewBrokerSellerData.public}` : '# -'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 3 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(1)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(2)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>

                <div className={(sectionInformation === 4 || sectionInformation === 5) && proyectos.masterlist?.propietario_vende === false ? 'row p-3 mx-0 seccion_information' : 'row p-3 mx-0 seccion_information d-none'}>
                    {
                        sectionInformation === 4 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(5)}><i className="fas fa-folder-open"></i> View files</button>
                                </div>

                                {/* Compañia */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Broker:</label>
                                    <div className="input-group">
                                        <Select name="id_broker_buyer" className="select-group" aria-describedby="button-broker-buyer" isClearable={true} options={brokersFilter} value={brokerBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_buyer', 'id_broker_agent_buyer')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_buyer_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_buyer_buy !== null || proyectoData.id_broker_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-buyer" onClick={() => handleBrokerBuyer(true)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className="col-md-9 form-group">
                                    <label className="font-weight-bold">Address:</label>
                                    <div className='contenedor_datepicker'>
                                        <p><i className="fas fa-user" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.address !== null) ? viewBrokerBuyerData.address : ''}</p>
                                    </div>
                                </div>

                                {/* Officer Name */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Agent:</label>
                                    
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fas fa-user"></i>
                                            </span>
                                        </div>
                                        <Select name="id_broker_agent_buyer" className="select-group" aria-describedby="button-broker-agent-buyer" isClearable={true} options={brokersAgentsBuyerFilter} value={brokerAgentBuyerSelectData} onChange={(value) => onChangeSelectSearch(value, 'id_broker_agent_buyer', '')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isDisabled={proyectos.masterlist?.id_broker_agent_buyer_buy !== null || proyectos.masterlist_section === 'Cancelled' ? (proyectos.edicion_proyecto ? false : true) : false} />
                                        <div className="input-group-append">
                                            {
                                                proyectos.masterlist?.id_broker_agent_buyer_buy !== null || proyectoData.id_broker_agent_buyer !== null ?
                                                    <></>
                                                :
                                                    <button className="btn btn-secondary" type="button" id="button-broker-agent-buyer" onClick={() => handleBrokerBuyer(false)}>Create</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-md-3 form-group">
                                    <label className="font-weight-bold">Email:</label>
                                    <div className="contenedor_datepicker">
                                        <div className="input-group">
                                            <p><i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>{(viewBrokerBuyerData.email !== '') ? viewBrokerBuyerData.email : ''}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Cell Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Cell phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.cell_phone !== '') ? viewBrokerBuyerData.cell_phone : ''}</p>
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Phone:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p><i className="fas fa-phone" style={{paddingRight: '5px'}}></i>{(viewBrokerBuyerData.phone !== '') ? viewBrokerBuyerData.phone : ''}</p>
                                    </div>
                                </div>

                                {/* License number */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">License number:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerBuyerData.license !== '') ? `# ${viewBrokerBuyerData.license}` : '# -'}</p>
                                    </div>
                                </div>

                                {/* Public ID */}
                                <div className="col-md form-group">
                                    <label className="font-weight-bold">Public ID:</label>
                                    <div className="contenedor_datepicker" style={{display: 'flex'}}>
                                        <p>{(viewBrokerBuyerData.public !== '') ? `# ${viewBrokerBuyerData.public}` : '# -'}</p>
                                    </div>
                                </div>
                            </Fragment>
                        :
                            null
                    }

                    {
                        sectionInformation === 5 ?
                            <Fragment>
                                <div className="col-md-12 form-group text-right">
                                    <button type="button" className="btn btn-success mr-2" onClick={() => handleAddFileSection(2)}><i className="fas fa-info-circle"></i> Add file</button>
                                    <button type="button" className="btn btn-primary" onClick={() => setSectionInformation(4)}><i className="fas fa-info-circle"></i> View information</button>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Upload date</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        {tableRow(dataTabla.archivosTabla)}
                                    </table>
                                </div>
                            </Fragment>
                        :
                            null
                    }
                </div>
            </form>
        </Fragment>
    )
}

export default OfferFlipping;