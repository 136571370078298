import {
    ELEGIR_FILTROS_TODOLIST,
    BUSCAR_TAREAS_TODOLIST,
    AGREGAR_TAREA_TODOLIST,
    EDITAR_TAREA_TODOLIST,
    NOTA_TAREA_TODOLIST,
    ARCHIVO_NOTA_TODOLIST,
    DETALLES_TAREA_TODOLIST,
    ELIMINAR_TAREA_TODOLIST,
    REORDENAR_TAREA_TODOLIST,
    EXTERNA_TAREA, 
    OBTENER_REPORTE_TODOLIST
} from '../actions/types';

const initialState = {
    secciones: [],
    listado_secciones: [],
    filtros: null,
    detalles_seccion: null,
    detalles_tarea: null,
    detalles_archivo: null,
    externo: false, 
    detalles_reporte: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case ELEGIR_FILTROS_TODOLIST:
            return {
                ...state,
                filtros: payload
            };

        case BUSCAR_TAREAS_TODOLIST:
            return {
                ...state,
                secciones: payload
            };

        case AGREGAR_TAREA_TODOLIST:

            let seccion_encontrada = state.secciones.find( seccion => seccion._id === payload.id_seccion );

            if(seccion_encontrada !== undefined && seccion_encontrada !== null){
                let tareas_agregar = seccion_encontrada.tareas;

                tareas_agregar = [...tareas_agregar, payload];

                state.secciones.find( seccion => seccion._id === payload.id_seccion ).tareas = tareas_agregar;
            }

            return {
                ...state
            };
        
        case EDITAR_TAREA_TODOLIST:

            //Eliminar

            let tareas_eliminar_editar = state.secciones.find( seccion => seccion._id === payload.id_seccion_anterior ).tareas;

            tareas_eliminar_editar = tareas_eliminar_editar.filter( tarea => tarea._id !== payload._id );

            state.secciones.find( seccion => seccion._id === payload.id_seccion_anterior ).tareas = tareas_eliminar_editar;

            //Agregar

            let tareas_agregar_editar = state.secciones.find( seccion => seccion._id === payload.id_seccion ).tareas;

            tareas_agregar_editar = [...tareas_agregar_editar, payload];

            tareas_agregar_editar.sort(function (a, b) {
                if (a.orden > b.orden) {
                  return 1;
                }
                if (a.orden < b.orden) {
                  return -1;
                }
                return 0;
            });

            state.secciones.find( seccion => seccion._id === payload.id_seccion ).tareas = tareas_agregar_editar;

            return {
                ...state
            };

        case NOTA_TAREA_TODOLIST:

            let notas_nota = state.detalles_tarea.notas;

            let notas_totales = [...notas_nota, payload.nota];

            state.detalles_tarea.notas = notas_totales;

            return {
                ...state
            };

        case ARCHIVO_NOTA_TODOLIST:
            return {
                ...state,
                detalles_archivo: payload
            };

        case DETALLES_TAREA_TODOLIST:
            return {
                ...state,
                detalles_tarea: payload
            };

        case ELIMINAR_TAREA_TODOLIST:

            let tareas_eliminar = state.secciones.find( seccion => seccion._id === payload.id_seccion ).tareas;

            tareas_eliminar = tareas_eliminar.filter( tarea => tarea._id !== payload.id );

            state.secciones.find( seccion => seccion._id === payload.id_seccion ).tareas = tareas_eliminar;

            return {
                ...state
            };

        case REORDENAR_TAREA_TODOLIST:
            return{
                ...state,
                secciones: payload
            };

        case EXTERNA_TAREA: 
            return {
                ...state,
                externo: payload
            };

        case OBTENER_REPORTE_TODOLIST: 
            return {
                ...state,
                detalles_reporte: payload
            };

        default:
            return state;
    }
}