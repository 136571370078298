import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { detallesInspectionProyecto, repairsProyecto, agregarRepairProyecto, editarRepairProyecto, detallesRepairProyecto, eliminarRepairProyecto, statusRepairProyecto } from '../../actions/proyectos';

const styles = {
    panel_tab: {
        backgroundColor: '#F3F2F2'
    },
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    tableHeader: {
        display: 'flex',
        backgroundColor: '#343A40',
        color: 'white',
    },
    textTableHeader: {
        padding: '2% 2%',
        marginBottom: '0'
    },
    buttonIcon: {
        fontSize: 18,
    },
    btnSnapShot: {
        cursor: 'pointer'
    },
}

const Repairs = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles_inspection);
    const usuarios = useSelector(state => state.usuarios);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [newRepairData, setNewRepairData] = useState({
        id_proyecto: null,
        id_inspeccion: null,
        id_responsable: null,
        nombre: '',
        descripcion: '',
        archivos: null
    });

    const [editRepairData, setEditRepairData] = useState({
        id: '',
        id_responsable: null,
        nombre: '',
        descripcion: '',
        archivos: null
    });

    const [deleteRepairData, setDeleteRepairData] = useState({
        id: '',
        nombre: ''
    });

    const [detailsRepairData, setDetailsRepairData] = useState({
        id: '',
        nombre: '',
        responsable: '',
        descripcion: '',
        fecha_creacion: null,
        fecha_inicio: null,
        fecha_fin: null,
        archivos: null,
        status: null
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [startData, setStartData] = useState({
        status: false,
        text: 'Start'
    });

    const [finishData, setFinishData] = useState({
        status: false,
        text: 'Finish'
    });

    const [cancelData, setCancelData] = useState({
        status: false,
        text: 'Cancel'
    });

    const [statusSaveRepair, setStatusSaveRepair] = useState(true);
    const [newRepairModal, setNewRepairModal] = useState(false);
    const [editRepairModal, setEditRepairModal] = useState(false);
    const [deleteRepairModal, setDeleteRepairModal] = useState(false);
    const [detailsRepairModal, setDetailsRepairModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [flagDetails, setFlagDetails] = useState(false);

    let files_ref = createRef();
    let files_edit_ref = createRef();

    const { loadingData, dataLoaded } = dataStatus;

    const handleAgregarReparacion = id => {
        setNewRepairModal(true);
    }

    const handleCloseAgregarReparacion = () => {
        setNewRepairData({
            ...newRepairData,
            id_responsable: null,
            nombre: '',
            descripcion: '',
            archivos: null
        });

        setNewRepairModal(false);
    }

    const handleInputs = ({ target }) => {
        setNewRepairData({
            ...newRepairData,
            [target.name]: target.value
        });
    }

    const handleInputsEditar = ({ target }) => {
        setEditRepairData({
            ...editRepairData,
            [target.name]: target.value
        });
    }

    const onChangeFiles = () => {
        setNewRepairData({
            ...newRepairData,
            archivos: files_ref.current.files
        });
    }

    const onChangeFilesEditar = () => {
        setEditRepairData({
            ...editRepairData,
            archivos: files_edit_ref.current.files
        });
    }

    const handleSubmitCrear = async (e) => {
        e.preventDefault();
        try {
            if(newRepairData.nombre !== ''){
                let formData = new FormData();
                formData.append('id_proyecto', newRepairData.id_proyecto);
                formData.append('id_inspeccion', newRepairData.id_inspeccion);
                formData.append('id_responsable', newRepairData.id_responsable);
                formData.append('nombre', newRepairData.nombre);
                formData.append('descripcion', newRepairData.descripcion);

                if(newRepairData.archivos !== null){
                    for (var i = 0; i < newRepairData.archivos.length; i++) {
                        formData.append('archivos', newRepairData.archivos[i]);
                    }
                }

                setStatusSaveRepair(false);
                await dispatch(agregarRepairProyecto(formData));
                setStatusSaveRepair(true);

                setNewRepairModal(false);

                setNewRepairData({
                    ...newRepairData,
                    id_responsable: null,
                    nombre: '',
                    descripcion: '',
                    archivos: null
                });
            }else{
                dispatch(setAlert('File name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleEditarReparacion = async (id) => {
        await setFlag(true);
        await dispatch(detallesRepairProyecto(id));
    }

    const handleCloseEditarReparacion = () => {
        setEditRepairData({
            ...editRepairData,
            id: '',
            id_responsable: null,
            nombre: '',
            descripcion: '',
            archivos: null
        });

        setFlag(false);
        setEditRepairModal(false);
    }

    const handleSubmitEditar = async (e) => {
        e.preventDefault();
        try {
            if(editRepairData.nombre !== ''){
                let formData = new FormData();
                formData.append('id', editRepairData.id);
                formData.append('id_responsable', editRepairData.id_responsable);
                formData.append('nombre', editRepairData.nombre);
                formData.append('descripcion', editRepairData.descripcion);

                if(editRepairData.archivos !== null){
                    for (var i = 0; i < editRepairData.archivos.length; i++) {
                        formData.append('archivos', editRepairData.archivos[i]);
                    }
                }

                setStatusSaveRepair(false);
                await dispatch(editarRepairProyecto(formData));
                setStatusSaveRepair(true);

                setEditRepairModal(false);

                setFlag(false);

                setEditRepairData({
                    ...editRepairData,
                    id: '',
                    id_responsable: null,
                    nombre: '',
                    descripcion: '',
                    archivos: null
                });
            }else{
                dispatch(setAlert('File name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleEliminarReparacion = async (fila) => {
        await setDeleteRepairData({
            ...deleteRepairData,
            id: fila._id,
            nombre: fila.nombre
        });
        await setDeleteRepairModal(true);
    }

    const handleCloseEliminarReparacion = () => {
        setDeleteRepairModal(false);
    }

    const handleSubmitEliminar = async () => {
        try {
            setStatusSaveRepair(false);
            await dispatch(eliminarRepairProyecto(deleteRepairData.id));
            setStatusSaveRepair(true);

            setDeleteRepairModal(false);
        } catch (error) {
            return false;
        }
    }

    const handleDetallesReparacion = async (id) => {
        await setFlagDetails(true);
        await dispatch(detallesRepairProyecto(id));
    }

    const handleCloseDetallesReparacion = () => {
        setDetailsRepairData({
            ...detailsRepairData,
            id: '',
            nombre: '',
            responsable: '',
            descripcion: '',
            fecha_creacion: null,
            fecha_inicio: null,
            fecha_fin: null,
            archivos: null,
            status: null
        });

        setFlagDetails(false);
        setDetailsRepairModal(false);
    }

    const handleOpenFile = async () => {
        let boton_archivos = document.getElementById('id_archivo');

        let archivo_elegido = detailsRepairData.archivos?.filter(archivo => {
            return String(archivo._id) === String(boton_archivos.value)
        });

        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(archivo_elegido[0].extension.toLowerCase()) !== -1){
            setImageData({ ...imageData, modal: true, src: archivo_elegido[0].ruta });
        }else{
            window.open(archivo_elegido[0].ruta);
        }        
    }    

    const confirmStatusReparacion = async (status) => {
        if(status === 1 || status === '1'){
            if(startData.status){
                await dispatch(statusRepairProyecto({
                    id: detailsRepairData.id,
                    status: 1
                }));

                await handleCloseDetallesReparacion();
            }else{
                await setStartData({
                    ...startData,
                    status: true,
                    text: 'Confirm Start'
                });
            }

            await setFinishData({
                ...finishData,
                status: false,
                text: 'Finish'
            });

            await setCancelData({
                ...cancelData,
                status: false,
                text: 'Cancel'
            });
        }

        if(status === 2 || status === '2'){
            if(finishData.status){
                await dispatch(statusRepairProyecto({
                    id: detailsRepairData.id,
                    status: 2
                }));

                await handleCloseDetallesReparacion();
            }else{
                await setFinishData({
                    ...finishData,
                    status: true,
                    text: 'Confirm Finish'
                });
            }

            await setStartData({
                ...startData,
                status: false,
                text: 'Start'
            });

            await setCancelData({
                ...cancelData,
                status: false,
                text: 'Cancel'
            });
        }

        if(status === 3 || status === '3'){
            if(cancelData.status){
                await dispatch(statusRepairProyecto({
                    id: detailsRepairData.id,
                    status: 3
                }));

                await handleCloseDetallesReparacion();
            }else{
                await setCancelData({
                    ...cancelData,
                    status: true,
                    text: 'Confirm Cancel'
                });
            }

            await setStartData({
                ...startData,
                status: false,
                text: 'Start'
            });

            await setFinishData({
                ...finishData,
                status: false,
                text: 'Finish'
            });
        }
    }

    const handleViewFile = (file) => {
        if(file !== null){
            console.log(file);
            const url = URL.createObjectURL(file);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(detallesInspectionProyecto(id));

        dispatch(repairsProyecto(id));

        dispatch(buscarUsuarios());

        setNewRepairData({
            ...newRepairData,
            id_inspeccion: id
        });

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {

            setNewRepairData({
                ...newRepairData,
                id_proyecto: detalles.id_proyecto
            });

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }

        if(proyectos.detalles_repair !== null && flag === true){
            setEditRepairData({
                ...editRepairData,
                id: proyectos.detalles_repair?._id,
                id_responsable: proyectos.detalles_repair?.id_responsable,
                nombre: proyectos.detalles_repair?.nombre,
                descripcion: proyectos.detalles_repair?.descripcion,
            });
        }

        if(proyectos.detalles_repair !== null && flagDetails === true){
            setDetailsRepairData({
                ...detailsRepairData,
                id: proyectos.detalles_repair?._id,
                nombre: proyectos.detalles_repair?.nombre,
                responsable: proyectos.detalles_repair?.responsable,
                descripcion: proyectos.detalles_repair?.descripcion,
                fecha_creacion: proyectos.detalles_repair?.fecha_creacion,
                fecha_inicio: proyectos.detalles_repair?.fecha_inicio,
                fecha_fin: proyectos.detalles_repair?.fecha_fin,
                archivos: proyectos.detalles_repair?.archivos,
                status: proyectos.detalles_repair?.status
            });
        }
    }, [proyectos]);

    useEffect(() => {
        if(editRepairData.id !== ''){
            setEditRepairModal(true);
        }
    }, [editRepairData]);

    useEffect(() => {
        if(detailsRepairData.id !== ''){
            setDetailsRepairModal(true);
        }
    }, [detailsRepairData]);

    const tableRow = (items) => {        
        return (
            <tbody>
                {
                    items.map( (item) => {
                        const statusReminder = (status) => {
                            switch(status){
                                case 0:
                                case '0':
                                    return <h4><span className="badge badge-warning">Waiting</span></h4>;
                                    break;

                                case 1:
                                case '1':
                                    return <h4><span className="badge badge-primary">In process</span></h4>;
                                    break;

                                case 2:
                                case '2':
                                    return <h4><span className="badge badge-success">Finished</span></h4>;
                                    break;

                                default:
                                    return <h4><span className="badge badge-danger">Cancelled</span></h4>;
                                    break;
                            }
                        }

                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>{item.nombre}</td>
                                    <td>{item.responsable !== null ? item.responsable : 'Not assigned'}</td>
                                    <td>{item.fecha_creacion !== null ? new Date(item.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                                    <td>{item.fecha_inicio !== null ? new Date(item.fecha_inicio).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                                    <td>{item.fecha_fin !== null ? new Date(item.fecha_fin).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                                    <td>{statusReminder(item.status)}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleDetallesReparacion(item._id);
                                        }}>Details</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleEditarReparacion(item._id);
                                        }} disabled={item.status === 2 || item.status === '2' || item.status === 3 || item.status === '3' ? true : false}>Edit</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-link text-danger" onClick={() => {
                                            handleEliminarReparacion(item);
                                        }} disabled={item.status === 2 || item.status === '2' || item.status === 3 || item.status === '3' ? true : false}>Delete</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    const goBackProject = () => {
        history.goBack();
    }

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Repairs list</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => { goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                <button type="button" className="btn btn-success float-right mb-3" onClick={() => {handleAgregarReparacion(id)}}><i className="fas fa-plus fa-sm"></i> Create new repair</button>
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>

                            {/* Modal de nueva reparacion */}
                            <Modal backdrop="static" show={newRepairModal} size="lg" onHide={() => {
                                setNewRepairModal(false);
                                setNewRepairData({
                                    ...newRepairData,
                                    id_responsable: null,
                                    nombre: '',
                                    descripcion: '',
                                    archivos: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New repair</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of repair:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputs(e)} value={newRepairData.nombre || ''} disabled={statusSaveRepair ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea className='form-control' name="descripcion" onChange={e => handleInputs(e)} value={newRepairData.descripcion || ''} disabled={statusSaveRepair ? false : true}></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Assinged user:</label> <br></br>
                                            <select className="custom-select" name="id_responsable" onChange={(e) => {handleInputs(e)}}>
                                                <option value={null}>General</option>
                                                {usuarios ? usuarios.map(user => (
                                                    <option key={user._id} value={user._id}>{user.nombre}</option>
                                                )) : null}
                                            </select>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Files:</label> <br></br>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={files_ref} onChange={onChangeFiles} aria-describedby="view_editFile"/>
                                                    <label className="custom-file-label">{newRepairData.archivos ? 'File Selected' : 'Choose file' }</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(newRepairData.archivos)}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAgregarReparacion()} disabled={statusSaveRepair ? false : true}><i className="fas fa-times fa-sm"></i>Cancel</button>
                                    <button className="btn btn-success" onClick={handleSubmitCrear} disabled={statusSaveRepair ? false : true}><i className="fas fa-plus fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de editar reparacion */}
                            <Modal backdrop="static" show={editRepairModal} size="lg" onHide={() => {
                                setFlag(false);
                                setEditRepairModal(false);
                                setEditRepairData({
                                    ...editRepairData,
                                    id: '',
                                    id_responsable: null,
                                    nombre: '',
                                    descripcion: '',
                                    archivos: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit repair</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of repair:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editRepairData.nombre || ''} disabled={statusSaveRepair ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea className='form-control' name="descripcion" onChange={e => handleInputsEditar(e)} value={editRepairData.descripcion || ''} disabled={statusSaveRepair ? false : true}></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Assinged user:</label> <br></br>
                                            <select className="custom-select" name="id_responsable" value={editRepairData.id_responsable || null} onChange={(e) => {handleInputsEditar(e)}}>
                                                <option value={null}>General</option>
                                                {usuarios ? usuarios.map(user => (
                                                    <option key={user._id} value={user._id}>{user.nombre}</option>
                                                )) : null}
                                            </select>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Files:</label> <br></br>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="editFile" id="editFile" ref={files_edit_ref} onChange={onChangeFilesEditar} aria-describedby="view_editFile"/>
                                                    <label className="custom-file-label">{editRepairData.archivos ? 'File Selected' : 'Choose file' }</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(editRepairData.archivos[0])}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEditarReparacion()} disabled={statusSaveRepair ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleSubmitEditar} disabled={statusSaveRepair ? false : true}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de eliminacion */}
                            <Modal backdrop="static" show={deleteRepairModal} onHide={() => {
                                setDeleteRepairModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete the repair</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p style={styles.info}>Do you want to delete the selected repair?</p>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEliminarReparacion()} disabled={statusSaveRepair ? false : true}><i className="fas fa-times fa-sm"></i> No</button>
                                    <button className="btn btn-warning" onClick={() => handleSubmitEliminar()} disabled={statusSaveRepair ? false : true}><i className="fas fa-check fa-sm"></i>Yes</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de detalles de reparacion */}
                            <Modal backdrop="static" show={detailsRepairModal} size="lg" onHide={() => {
                                setFlagDetails(false);
                                setDetailsRepairModal(false);
                                setDetailsRepairData({
                                    ...detailsRepairData,
                                    id: '',
                                    id_responsable: '',
                                    nombre: '',
                                    descripcion: '',
                                    fecha_creacion: null,
                                    fecha_inicio: null,
                                    fecha_fin: null,
                                    archivos: null,
                                    status: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Repair details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of repair:</label>
                                            <p>{detailsRepairData.nombre || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <p>{detailsRepairData.descripcion || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Assigned user:</label>
                                            <p>{detailsRepairData.responsable || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Creation date:</label>
                                            <p>{detailsRepairData.fecha_creacion !== null ? new Date(detailsRepairData.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Start date:</label>
                                            <p>{detailsRepairData.fecha_inicio !== null ? new Date(detailsRepairData.fecha_inicio).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">End date:</label>
                                            <p>{detailsRepairData.fecha_fin !== null ? new Date(detailsRepairData.fecha_fin).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                                        </div>

                                        {
                                            detailsRepairData.archivos?.length > 0 ?
                                                <div className="col-md-12 form-group">
                                                    <label className="font-weight-bold">Files:</label> <br></br>
                                                    <div className="input-group">
                                                        <select className="custom-select" name="id_archivo" id="id_archivo">
                                                            {detailsRepairData.archivos ? detailsRepairData.archivos?.map(archivo => (
                                                                <option key={archivo._id} value={archivo._id}>{archivo.nombre + archivo.extension}</option>
                                                            )) : null}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" id="button-open-file" onClick={() => handleOpenFile()}><i className="fas fa-file fa-sm"></i> Open</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {
                                        detailsRepairData.status !== 3 && detailsRepairData.status !== '3' && detailsRepairData.status !== 2 && detailsRepairData.status !== '2' ?
                                            <button className="btn btn-danger" onClick={() => confirmStatusReparacion(3)} disabled={statusSaveRepair ? false : true}>{cancelData.text}</button>
                                        :
                                            <></>
                                    }
                                    {
                                        detailsRepairData.status === 0 || detailsRepairData.status === '0' ?
                                            <button className="btn btn-success" onClick={() => confirmStatusReparacion(1)} disabled={statusSaveRepair ? false : true}>{startData.text}</button>
                                        :
                                            <></>
                                    }
                                    {
                                        detailsRepairData.status === 1 || detailsRepairData.status === '1' ?
                                            <button className="btn btn-success" onClick={() => confirmStatusReparacion(2)} disabled={statusSaveRepair ? false : true}>{finishData.text}</button>
                                        :
                                            <></>
                                    }
                                </Modal.Footer>
                            </Modal>

                            {imageData.modal && (
                                <Lightbox
                                    mainSrc={imageData.src}
                                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                                />
                            )}

                            <div className="col-md-12 my-4">
                                <div className="row form-group">
                                    <label className="col-lg-12 col-sm-12">
                                        <span className="font-weight-bold mr-2">Inspection:</span> {detalles?.nombre}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> {detalles?.proyecto?.direccion}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12 row">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Assigned user</th>
                                                <th scope="col">Creation date</th>
                                                <th scope="col">Start date</th>
                                                <th scope="col">End date</th>
                                                <th scope="col">Status</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {tableRow(proyectos.repairs)}
                                    </table>
                                </div>
                            </div>
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No inspection was selected, please go back to inspection selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => goBackProject()}>
                                <i className="fas fa-eye fa-sm"></i> Inspections
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default Repairs;