import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Actions
import { eliminarProyecto, externoEliminar } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const ModalDelete = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const { externo_eliminar, detalles } = useSelector(state => state.proyectos);

    //Nuevo
    const [datosEliminar, setDatosEliminar] = useState({
        id_proyecto: null,
        id_status: null,
        tipo: null,
        listing_id: null,
        externo: false
    });

    const [botonEliminar, setBotonEliminar] = useState(false);

    const handleOpen = () => {

        setDatosEliminar({
            ...datosEliminar,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            tipo: detalles?.proyecto?.externo ? 'project' : (detalles?.proyecto?.tipo_status ? 'project' : 'offer'),
            listing_id: detalles?.proyecto?.listing_id, 
            externo: detalles?.proyecto?.externo
        });
    }

    const handleDelete= async () => {
        await setBotonEliminar(true);

        if(datosEliminar.id_proyecto !== null){
            await dispatch(eliminarProyecto(datosEliminar.id_proyecto));

            await handleClose();
        }else{
            dispatch(setAlert('First select a project or offer', 'danger'));
        }

        await setBotonEliminar(false);
    }

    const handleClose = () => {
        setDatosEliminar({
            ...datosEliminar,
            id_proyecto: null,
            id_status: null,
            tipo: null,
            listing_id: null, 
            externo: false
        });

        dispatch(externoEliminar(null, false));

        if(datosEliminar.id_status || datosEliminar.externo){
            history.push('/projects/projects');
        }else{
            history.push('/projects/offers');
        }
    }

    useEffect(() => {

        setDatosEliminar({
            ...datosEliminar,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            tipo: detalles?.proyecto?.externo ? 'project' : (detalles?.proyecto?.tipo_status ? 'project' : 'offer'),
            listing_id: detalles?.proyecto?.listing_id, 
            externo: detalles?.proyecto?.externo
        });
    }, [detalles]);

    return (
        <Modal show={externo_eliminar} onEntered={() => { handleOpen(); }} onHide={() => { handleClose(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Delete {datosEliminar.tipo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    datosEliminar.externo ?
                        <p>Do you want to delete this side project?</p>
                    :
                        <p>Do you want to delete {datosEliminar.listing_id ? 'the' : 'this'} {datosEliminar.tipo}{datosEliminar.listing_id ? ' ' + datosEliminar.listing_id : ''}?</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger mr-3" onClick={() => handleClose()} disabled={botonEliminar}><i className="fas fa-times fa-sm"></i> No</button>
                <button className="btn btn-success" onClick={handleDelete} disabled={botonEliminar}><i className="fas fa-check fa-sm"></i> Yes</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalDelete;