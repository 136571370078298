import { BUSCAR_NOMINAS, BUSCAR_EMPLEADOS_DIA, GENERAR_NOMINA, EDITAR_NOMINA, DETALLES_NOMINA, FILTROS_NOMINA, ELIMINAR_NOMINA, EXTERNA_NOMINA, HORAS_RESTANTES_NOMINA } from '../actions/types';

const initialState = {
    nominas: [],
    empleados: [],
    detalles: null,
    filtros: null,
    externo: false,
    detalle_horas: 8
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {
        case BUSCAR_NOMINAS: 
            return {
                ...state,
                nominas: payload
            }
        case BUSCAR_EMPLEADOS_DIA: 
            return {
                ...state,
                empleados: payload
            }
        case GENERAR_NOMINA: 
            return {
                ...state
            }
        case EDITAR_NOMINA:
            return {
                ...state,
                nominas: state.nominas.map(nomina => String(nomina._id) === String(payload._id) ? payload : nomina)
            }
        case DETALLES_NOMINA: 
            return {
                ...state,
                detalles: payload
            }
        case FILTROS_NOMINA: 
            return {
                ...state,
                filtros: payload
            }
        case ELIMINAR_NOMINA: 
            return {
                ...state,
                nominas: state.nominas.filter( nomina => nomina._id !== payload.id )
            }

        case EXTERNA_NOMINA: 
            return {
                ...state,
                externo: payload
            }

        case HORAS_RESTANTES_NOMINA: 
            return {
                ...state,
                detalle_horas: payload
            }
        
        default: 
            return state;
    }

}