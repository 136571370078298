import { LOAD_DASHBOARD, FILTER_PROYECTOS, FILTER_OFERTAS } from './types';
import { setAlert } from './alert';
import axios from 'axios';

// LOAD DASHBOARD
export const loadDashboard = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/dashboard`);

        dispatch({
            type: LOAD_DASHBOARD,
            payload: {
                info: res.data.info
            }
        });
    } catch (error) {
        
    }
}

export const filterProyectos = (filtros) => async dispatch => {
    try {
        let {project_id, construction_id, status_id, active} = filtros;
        
        dispatch({
            type: FILTER_PROYECTOS,
            payload: {
                filter_project: project_id,
                filter_property: construction_id,
                filter_status: status_id, 
                filter_active: active
            }
        });
    } catch (error) {
        dispatch(setAlert('An error occurred while getting the listing', 'danger'));
    }
}

export const filterOfertas = (filtros) => async dispatch => {
    try {
        let {project_id, construction_id, user_id, active} = filtros;
        
        dispatch({
            type: FILTER_OFERTAS,
            payload: {
                filter_project: project_id,
                filter_property: construction_id,
                filter_user: user_id, 
                filter_active: active
            }
        });
    } catch (error) {
        dispatch(setAlert('An error occurred while getting the listing', 'danger'));
    }
}