import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import Select from 'react-select';

//Actions
import { setAlert } from '../../actions/alert';
import { agregarRecordatorio, externaRecordatorio } from '../../actions/recordatorios';

const ModalReminder = () => {

    const dispatch = useDispatch();

    const { externo } = useSelector(state => state.recordatorios);
    const proyectos = useSelector(state => state.proyectos);

    const [projectsFilter, setProjectsFilter] = useState([]);

    //Nuevo
    const [reminderData, setReminderData] = useState({
        start_date: new Date(),
        start_time: new Date(),
        end_date: new Date(),
        end_time: new Date(),
        id_proyecto: null,
        notes: null
    })

    const [projectAddInformationData, setProjectAddInformationData] = useState(null);

    const handleOpenNewReminderModal = () => {
        if(proyectos.id_proyecto_dashboard === null){
            setProjectAddInformationData(null);
        }else{
            if(projectsFilter.length > 0){

                let proyecto_elegido = projectsFilter.filter( tipo => String(tipo.value) === String(proyectos.id_proyecto_dashboard) );

                if(proyecto_elegido){
                    setProjectAddInformationData(proyecto_elegido);
                }
            }
        }

        setReminderData({
            ...reminderData,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }

    const handleSelect = (option) => {
        setProjectAddInformationData(option);
        setReminderData({
            ...reminderData,
            id_proyecto: option !== null ? option.value : null
        });
    }

    const handleAddReminder = (method) => {
        if(reminderData.start_date !== null){
            if(reminderData.end_date !== null){
                if(reminderData.id_proyecto !== null){
                    if(reminderData.notes !== null && reminderData.notes !== ''){

                        let end_date_final = reminderData.end_date;
                        let end_time_final = reminderData.end_time;

                        if(reminderData.start_date > reminderData.end_date){
                            end_date_final = reminderData.start_date;

                            if(reminderData.start_time > reminderData.end_time){
                                end_time_final = reminderData.start_time;

                                setReminderData({
                                    ...reminderData,
                                    end_date: end_date_final,
                                    end_time: end_time_final
                                });
                            }else{
                                setReminderData({
                                    ...reminderData,
                                    end_date: end_date_final,
                                    end_time: end_time_final
                                });
                            }
                        }else{
                            if(reminderData.start_time > reminderData.end_time){
                                end_time_final = reminderData.start_time;

                                setReminderData({
                                    ...reminderData,
                                    end_date: end_date_final,
                                    end_time: end_time_final
                                });
                            }
                        }

                        dispatch(agregarRecordatorio(reminderData));

                        if(method === 'new'){

                            handleClearAddReminderModal();

                        }else if(method === 'close'){

                            handleClearAddReminderModal();
                            handleCloseNewReminderModal();

                        }
                    }else{
                        dispatch(setAlert('Enter a valid note', 'danger'));
                    }
                }else{
                    dispatch(setAlert('Complete the project field', 'danger'));
                }
            }else{
                dispatch(setAlert('Select a valid end date', 'danger'));
            }
        }else{
            dispatch(setAlert('Select a valid start date', 'danger'));
        }
    }

    const handleClearAddReminderModal = () => {
        setReminderData({
            ...reminderData,
            start_date: new Date(),
            start_time: new Date(),
            end_date: new Date(),
            end_time: new Date(),
            id_proyecto: null,
            notes: null
        });

        setProjectAddInformationData(null);
    }

    const handleCloseNewReminderModal = () => {
        handleClearAddReminderModal();
        
        dispatch(externaRecordatorio(false));
    }

    useEffect(() => {
        let options = [];
        options.push({ value: '0', label: 'Without project' });

        for (var i = 0; i < proyectos.listado?.length; i++) {
            options.push({ value: proyectos.listado[i]._id, label: proyectos.listado[i].direccion });
        }

        setProjectsFilter(options);

        if(proyectos.id_proyecto_dashboard === null){
            setProjectAddInformationData(null);
        }else{
            if(options.length > 0){

                let proyecto_elegido = options.filter( tipo => String(tipo.value) === String(proyectos.id_proyecto_dashboard) );

                if(proyecto_elegido){
                    setProjectAddInformationData(proyecto_elegido);
                }
            }
        }

        setReminderData({
            ...reminderData,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }, [proyectos]);

    return (
        <Modal backdrop="static" show={externo} size="lg" onEntered={() => handleOpenNewReminderModal()} onHide={() => handleCloseNewReminderModal()}>
            <Modal.Header closeButton>
                <Modal.Title>New Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Start date:</label>
                        <div className="d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="fas fa-calendar-alt"></i>
                            </span>
                            <DatePicker name="start_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={reminderData.start_date || ''} onChange={ date => {setReminderData({ ...reminderData, start_date: date })}} />
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Start time:</label>
                        <div className="d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="fas fa-calendar-alt"></i>
                            </span>
                            <TimePicker name="start_time" className="form-control text-center" clearAriaLabel="Clear value" dateFormat="h:m" value={reminderData.start_time || new Date()} onChange={ time => {setReminderData({ ...reminderData, start_time: time })}} />
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">End date:</label>
                        <div className="d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="fas fa-calendar-alt"></i>
                            </span>
                            <DatePicker name="end_date" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={reminderData.end_date || ''} onChange={ date => {setReminderData({ ...reminderData, end_date: date })}} />
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">End time:</label>
                        <div className="d-flex">
                            <span className="input-group-text" id="basic-addon1">
                                <i className="fas fa-calendar-alt"></i>
                            </span>
                            <TimePicker name="end_time" className="form-control text-center" clearAriaLabel="Clear value" dateFormat="h:m" value={reminderData.end_time || new Date()} onChange={ time => {setReminderData({ ...reminderData, end_time: time })}} />
                        </div>
                    </div>
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Project:</label>
                        <Select name="id_proyecto" isClearable={true} options={projectsFilter} value={projectAddInformationData} onChange={(value) => handleSelect(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                    <div className="col-md-12 form-group">
                        <i className="fas fa-pencil-alt mr-2"/>
                        <label className="font-weight-bold">Note:</label>
                        <textarea className="form-control" name="notes" value={reminderData.notes || ''} onChange={(e) => {setReminderData({...reminderData, notes: e.target.value})}}></textarea>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNewReminderModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={() => handleAddReminder('new')}><i className="fas fa-solid fa-plus fa-sm"></i> Add Reminder and New</button>
                <button className="btn ml-2 btn-success" onClick={() => handleAddReminder('close')}><i className="fas fa-solid fa-check fa-sm"></i> Add Reminder and Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalReminder;