import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { detallesNomina, editarNomina } from '../../actions/nominas';
import { buscarEmpleados } from '../../actions/empleados';
import { listarProyectos } from '../../actions/proyectos';

const EditarNomina = ({history}) => {
    
    const dispatch = useDispatch();

    const { id } = useParams();

    const nominas = useSelector(state => state.nominas);
    const empleados = useSelector(state => state.empleados);
    const proyectos = useSelector(state => state.proyectos);

    const [nominaData, setNominaData] = useState({
        id: null,
        fecha: null,
        horas_meta: 8,
        num_empleados: 0,
        empleados: [],
        horas: [],
        proyectos: []
    });

    const [empleadosUsar, setEmpleadosUsar] = useState([]);
    const [proyectosUsar, setProyectosUsar] = useState([]);

    const handleAddEmployee = () => {
        setNominaData({
            ...nominaData,
            empleados: [...nominaData.empleados, null],
            horas: [...nominaData.horas, null],
            proyectos: [...nominaData.proyectos, null],
            num_empleados: nominaData.num_empleados + 1
        });
    }

    const handleDeleteEmployee = (posicion) => {

        let empleados_provisional = nominaData.empleados;
        empleados_provisional.splice(posicion, 1);

        let horas_provisional = nominaData.horas;
        horas_provisional.splice(posicion, 1);

        let proyectos_provisional = nominaData.proyectos;
        proyectos_provisional.splice(posicion, 1);

        setNominaData({
            ...nominaData,
            empleados: empleados_provisional,
            horas: horas_provisional,
            proyectos: proyectos_provisional,
            num_empleados: nominaData.num_empleados - 1
        });
    }

    const handleOnChange = e => {
        setNominaData({
            ...nominaData,
            [e.target.name]: e.target.value
        })
    }

    const handleOnChangeEmployee = e => {
        let id_separado = e.target.id.split('-');

        let empleados_cambiar = nominaData.empleados;
        empleados_cambiar[id_separado[1]] = String(e.target.value) === 'Choose a employee' ? null : e.target.value;

        setNominaData({
            ...nominaData,
            empleados: empleados_cambiar
        });
    }

    const handleOnChangeHours = e => {
        let id_separado = e.target.id.split('-');

        let horas_cambiar = nominaData.horas;
        horas_cambiar[id_separado[1]] = e.target.value;

        setNominaData({
            ...nominaData,
            horas: horas_cambiar
        });
    }

    const handleOnChangeProject = e => {
        let id_separado = e.target.id.split('-');

        let proyectos_cambiar = nominaData.proyectos;
        proyectos_cambiar[id_separado[1]] = String(e.target.value) === 'Choose a project' ? null : e.target.value;

        setNominaData({
            ...nominaData,
            proyectos: proyectos_cambiar
        });
    }

    const handleOnSubmit = async e => {
        e.preventDefault();
        await dispatch(editarNomina(nominaData));
        history.push('/payments');
    }

    const employeeInput = (times) => {
        let items = [];

        for(let i = 0; i < times; i++){
            items.push(
                <tr key={'empleado-' + i}>
                    <td className="col-xl-4 col-lg-4 col-sm-8 minimo_campo_movil">
                        <div className="input-group">
                            <select className="form-control" id={'empleado-' + i} value={nominaData.empleados[i] || ''} onChange={(e) => handleOnChangeEmployee(e)}>
                                <option value={null}>Choose a employee</option>
                                {
                                    empleadosUsar.length > 0 ?
                                        empleadosUsar.map(employee => {
                                            return(
                                                <option key={employee._id} value={ employee._id }>
                                                    { employee.nombre }
                                                </option>
                                            )
                                        })
                                    : ''
                                } 

                            </select>
                            <div className="input-group-append">
                                <button className="btn btn-danger" type="button" onClick={() => handleDeleteEmployee(i)}><i className="fas fa-times"></i></button>
                            </div>
                        </div>
                    </td>
                    <td className="col-xl-1 col-lg-1 col-sm-2">
                        <div className="input-group">
                            <input className="form-control" type="number" id={'horas-' + i} value={nominaData.horas[i]} onChange={(e) => handleOnChangeHours(e)} />
                        </div>
                    </td>
                    <td className="col-xl-7 col-lg-7 col-sm-2 minimo_campo_movil">
                        <div className="input-group">
                            <select className="form-control" id={'proyecto-' + i} value={nominaData.proyectos[i] || ''} onChange={(e) => handleOnChangeProject(e)}>
                                <option value={null}>Choose a project</option>
                                    {
                                        proyectosUsar.length > 0 ?
                                            proyectosUsar.map(project => {
                                                return(
                                                    <option key={project._id} value={ project._id }>
                                                        { project.nombre }
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                            </select>
                        </div>
                    </td>
                </tr>
            );
        }

        return <tbody>{items}</tbody>;
    }

    useEffect(() => {
        dispatch(listarProyectos());
        dispatch(buscarEmpleados());
        dispatch(detallesNomina(id));
    }, []);

    useEffect(() => {
        let array_empleados = [];

        for (var i = 0; i < empleados.empleados?.length; i++) {
            array_empleados.push({
                _id: empleados.empleados[i]._id,
                nombre: empleados.empleados[i].nombre,
                status: false
            });
        }

        setEmpleadosUsar(array_empleados);
    }, [empleados]);

    useEffect(() => {

        let array_proyectos = [];

        for (var i = 0; i < proyectos.listado?.length; i++) {
            array_proyectos.push({
                _id: proyectos.listado[i]._id,
                nombre: proyectos.listado[i].direccion
            });
        }

        setProyectosUsar(array_proyectos);
    }, [proyectos]);

    useEffect(() => {

        let array_empleados = [];
        let array_horas = [];
        let array_proyectos = [];

        console.log(nominas.detalles);

        for (var i = 0; i < nominas.detalles?.empleados?.length; i++) {
            if(nominas.detalles?.empleados[i].proyectos?.length > 0){
                for (var x = 0; x < nominas.detalles?.empleados[i].proyectos?.length; x++) {
                    array_empleados.push(nominas.detalles?.empleados[i].id_empleado);
                    array_horas.push(nominas.detalles?.empleados[i].proyectos[x].horas);
                    array_proyectos.push(nominas.detalles?.empleados[i].proyectos[x].id_proyecto);
                }
            }else{
                array_empleados.push(nominas.detalles?.empleados[i].id_empleado);
                array_horas.push(0);
                array_proyectos.push(null);
            }
            
        }

        setNominaData({
            ...nominaData,
            id,
            fecha: nominas.detalles?.fecha,
            horas_meta: nominas.detalles?.horas_meta,
            empleados: array_empleados,
            horas: array_horas,
            proyectos: array_proyectos,
            num_empleados: array_empleados.length
        });
    }, [nominas]);


    return ( 
        <div className="row pb-5">
            <form className="col-md-12" autoComplete="off">
                <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />

                <div className='row'>
                    <h3 className="col-md mb-3 mt-5">Edit payroll</h3>
                    <div className="col-md form-group text-right mb-3 mt-5">
                        <button type="button" className="btn btn-primary" onClick={() => handleAddEmployee()}><i className="fas fa-user-plus fa-sm"></i> Add new employee</button>
                    </div>
                </div>
                

                <div className="row mb-4">
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Date:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker name="fecha" maxDate={new Date()} className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ nominaData.fecha !== null ? new Date(nominaData.fecha) : '' } onChange={ date => setNominaData({ ...nominaData, fecha: date }) } />
                        </div>
                    </div>

                    <div className="col-md-2 form-group">
                        <label hidden={true} className="font-weight-bold">Hours per day:</label>
                        <div className="contenedor_datepicker">
                            <input hidden={true} type="number" name="horas_meta" className="form-control text-center" value={nominaData.horas_meta} onChange={ (e) => handleOnChange(e) } />
                        </div>
                    </div>

                    
                </div>

                <div className="table-responsive">
                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Employee</th>
                                <th scope="col">Hours</th>
                                <th scope="col">Projects</th>
                            </tr>
                        </thead>
                        {
                            nominaData.num_empleados > 0 ?
                                employeeInput(nominaData.num_empleados)
                            :   
                                <tbody>
                                    <tr>
                                        <td colSpan="3" className="text-center">There are no employees yet</td>
                                    </tr>
                                </tbody>
                        }
                    </table>
                </div>

                <div className="mt-5">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleOnSubmit}><i className="fas fa-check fa-sm"></i> Save</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/payments')}}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default EditarNomina;