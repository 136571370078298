import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';

import { setAlert } from '../../../actions/alert';
import { editarRehabOnProgress, continuarRehabOnProgress, guardarCambios, desplegarModalCambios, redireccionar } from '../../../actions/flipping';
import { detallesProyecto, masterlistProyecto, seleccionarSeccionMasterList, externoNota } from '../../../actions/proyectos';

const RehabOnProgressFlipping = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);

    const history = useHistory();


    //Atrapar back button del navegador

    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //logica para mostrar un popup cuando la pagina se recarga
        window.onbeforeunload = function () {

        return "Data will be lost if you leave the page, are you sure?";
        };
        return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {

        if (window.confirm("Do you want to exit the page?")) {
            setBackbuttonPress(true);
            history.go(-1);
            handleCloseNoGuardarCambiosModal();
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setBackbuttonPress(false)
        }
        }
    }

    const [proyectoData, setProyectoData] = useState({
        id: null,
        initial_date: null,
        estimated_completion: null,
        real_completion: null,
        estimated_initial_budget: null,
        estimated_final_budget: null
    });

    const [guardarCambiosModal, setGuardarCambiosModal] = useState(false);

    const newNote = campo => {
        dispatch(externoNota(true, campo));
    }

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData,
            [name]: value
        });

        dispatch(guardarCambios(true));
    };

    const handleGuardarCambiosModal = () => {
        setGuardarCambiosModal(true);
    }

    const handleCloseGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(desplegarModalCambios(false));
    }

    const handleCloseNoGuardarCambiosModal = () => {
        setGuardarCambiosModal(false);
        dispatch(guardarCambios(false));
        dispatch(desplegarModalCambios(false));
        dispatch(redireccionar(proyectos.paginaRedireccion));
    }

    const handleSubmitModal = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarRehabOnProgress(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    
        dispatch(redireccionar(proyectos.paginaRedireccion));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await setGuardarCambiosModal(false);
        await dispatch(editarRehabOnProgress(proyectoData));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
    };

    const [spinner, setSpinner] = useState(false);

    const handleContinue = async (e) => {
        e.preventDefault();

        if(proyectoData.initial_date !== null){
            if(proyectoData.estimated_completion !== null){
                if(proyectoData.estimated_initial_budget !== null){
                    if(proyectoData.real_completion !== null){
                        if(proyectoData.estimated_final_budget !== null){
                            setSpinner(true);
                            setTimeout(async() => {
                                await setGuardarCambiosModal(false);
                                await dispatch(continuarRehabOnProgress(proyectoData));
                                await dispatch(masterlistProyecto(proyectoData.id));
                                await dispatch(detallesProyecto(proyectoData.id));
                                setSpinner(false);
                                await dispatch(seleccionarSeccionMasterList('Holding'));
                            }, 0);
                        }else{
                            dispatch(setAlert('First enter the actual cost', 'warning'));
                        }
                    }else{
                        dispatch(setAlert('First select the actual completion date', 'warning'));
                    }
                }else{
                    dispatch(setAlert('First enter a valid estimated initial budget', 'warning'));
                }
            }else{
                dispatch(setAlert('First select a valid estimated completion date', 'warning'));
            }
        }else{
            dispatch(setAlert('First select a valid initial date', 'warning'));
        }
    };

    const [mostrarBoton, setMostrarBoton] = useState(false);

    const numberToDecimal = (number, price = '$') => {
        if(isNaN(number)){
            return price + ' -';
        }else{
            number = (Math.round(number * 100) / 100).toFixed(2);
            const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
            const finalFormated = price + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return finalFormated;
        }
    }

    const focusCampo = ({target}) => {
        let id_focusear = target.dataset.focus;
        let campo = document.getElementById(id_focusear);
        campo.focus();
    };

    useEffect(() => {
        if (proyectoData.initial_date !== null && proyectoData.estimated_completion !== null && proyectoData.real_completion !== null && proyectoData.estimated_initial_budget !== null && proyectoData.estimated_final_budget !== null) {
            setMostrarBoton(true);
        } else {
            setMostrarBoton(false);
        }
    }, [proyectoData]);
    

    useEffect(() => {

        if(!proyectos.guardar_cambios){
            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id,
                initial_date: proyectos.masterlist?.initial_date,
                estimated_completion: proyectos.masterlist?.estimated_completion,
                real_completion: proyectos.masterlist?.real_completion,
                estimated_initial_budget: proyectos.masterlist?.estimated_initial_budget,
                estimated_final_budget: proyectos.masterlist?.estimated_final_budget
            });
        }

        if(proyectos.modal_guardado_flipping === true){
            handleGuardarCambiosModal();
        }

    }, [proyectos]);

    return (
        <Fragment>
            {/* Modal de guardado de cambios */}
            <Modal backdrop="static" show={guardarCambiosModal} onHide={() => {
                handleCloseGuardarCambiosModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to save the changes?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNoGuardarCambiosModal()}>No</button>
                    <button className="btn btn-success" onClick={handleSubmitModal}>Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status <= 6 ?
                        <button type="submit" className="btn btn-primary ml-2 btn-sm" onClick={handleSubmit} disabled={spinner ? true : false}>Save</button>
                    :   null
                }
                {
                    mostrarBoton && (proyectos.detalles?.proyecto?.identificador_status <= 6) ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleContinue} disabled={spinner ? true : false}>Save and Continue</button>
                    :   null
                }
            </div>

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={handleSubmit}>
                            Save and Close
                        </button>
                        <button className="dropdown-item" onClick={handleContinue}>
                            Save and Continue
                        </button>
                    </div>
                </div>
            </div>

            

            <form className="col-md-12 pb-3" autoComplete="off">
                <input autoComplete="off" name="hidden" type="text" style={{display:'none'}} />

                { spinner ? 
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                                <div className="sk-chase-dot"></div>
                            </div>
                        </div> 
                    :
                        null
                }

                <div className="row">
                    <div className={'col-md form-group campo_fecha' + (proyectoData.initial_date !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Start date')}>Start date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.initial_date !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.initial_date ? new Date(proyectoData.initial_date).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="startDate" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="startDate" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="startDate"
                                                name="initial_date"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.initial_date ? new Date(proyectoData.initial_date) : '' } 
                                                // minDate={new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                maxDate={proyectoData.estimated_completion !== null ? new Date(proyectoData.estimated_completion) : null }
                                                onChange={ date => {setProyectoData({ ...proyectoData, initial_date: date }); dispatch(guardarCambios(true));} }
                                                monthsShown={2}
                                                disabled={proyectos.masterlist?.initial_date !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md form-group campo_fecha' + (proyectoData.estimated_completion !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Estimated completion date')}>Estimated completion date:</label>
                        <div className="contenedor_datepicker">
                            {
                                (proyectos.masterlist?.estimated_completion !== null && !proyectos.edicion_proyecto)
                                    ?
                                        <div className='d-flex'>
                                            <label><i className='fas fa-calendar-alt'/> {proyectoData.estimated_completion ? new Date(proyectoData.estimated_completion).toISOString().substring(0, 10) : 'Not defined'}</label>
                                        </div>
                                    :
                                        <div className="d-flex">
                                            <span className="input-group-text" data-focus="completionDate" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-calendar-alt" data-focus="completionDate" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                            <DatePicker
                                                id="completionDate"
                                                name="estimated_completion"
                                                className="form-control text-center"
                                                dateFormat="MM/dd/yyyy"
                                                selected={ proyectoData.estimated_completion ? new Date(proyectoData.estimated_completion) : '' } 
                                                // minDate={proyectoData.initial_date !== null ? new Date(proyectoData.initial_date) : new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                maxDate={proyectoData.real_completion !== null ? new Date(proyectoData.real_completion) : null}
                                                onChange={ date => {setProyectoData({ ...proyectoData, estimated_completion: date }); dispatch(guardarCambios(true));} }
                                                monthsShown={2}
                                                disabled={proyectos.masterlist?.estimated_completion !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                            />
                                        </div>
                            }
                        </div>
                    </div>

                    <div className={'col-md form-group campo_dinero' + (proyectoData.estimated_initial_budget !== null ? '' : ' campo-recordatorio')}>
                        <label className="font-weight-bold" onClick={() => newNote('Estimated initial budget')}>Estimated initial budget:</label>
                        {
                            (proyectos.masterlist?.estimated_initial_budget !== null && !proyectos.edicion_proyecto)
                                ?
                                    <div className='d-flex'>
                                        <label>{ numberToDecimal(proyectoData.estimated_initial_budget, '$ ') }</label>
                                    </div>

                                :
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" data-focus="initialBudget" onClick={(e) => focusCampo(e)}>
                                                <i className="fas fa-dollar-sign" data-focus="initialBudget" onClick={(e) => focusCampo(e)}></i>
                                            </span>
                                        </div>
                                        <CurrencyInput
                                            autoComplete='off'
                                            id="initialBudget"
                                            name="estimated_initial_budget"
                                            className="form-control text-center"
                                            value={proyectoData.estimated_initial_budget || ''}
                                            decimalsLimit={2}
                                            groupSeparator=","
                                            decimalSeparator="."
                                            onValueChange={(value, name) => onNumberChange(name, value)}
                                            disabled={proyectos.masterlist?.estimated_initial_budget !== null ? (proyectos.edicion_proyecto ? false : true) : false}
                                        />
                                    </div>
                        }
                    </div>

                    {
                        proyectoData.initial_date !== null && proyectoData.estimated_completion !== null && proyectoData.estimated_initial_budget !== null ?
                            <div className={'col-md form-group campo_fecha' + (proyectoData.real_completion !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Actual completion date')}>Actual completion date:</label>
                                <div className="contenedor_datepicker">
                                    {
                                        (proyectos.masterlist?.real_completion && !proyectos.edicion_proyecto)
                                            ?
                                                <div className='d-flex'>
                                                    <label><i className='fas fa-calendar-alt'/> {proyectoData.real_completion ? new Date(proyectoData.real_completion).toISOString().substring(0, 10) : 'Not defined'}</label>
                                                </div>
                                            :
                                                <div className="d-flex">
                                                    <span className="input-group-text" data-focus="realCompletion" onClick={(e) => focusCampo(e)}>
                                                        <i className="fas fa-calendar-alt" data-focus="realCompletion" onClick={(e) => focusCampo(e)}></i>
                                                    </span>
                                                    <DatePicker
                                                        id="realCompletion"
                                                        name="real_completion"
                                                        className="form-control text-center"
                                                        dateFormat="MM/dd/yyyy"
                                                        selected={ proyectoData.real_completion ? new Date(proyectoData.real_completion) : '' } 
                                                        // minDate={proyectoData.initial_date !== null ? new Date(proyectoData.initial_date) : new Date(proyectos.masterlist?.real_escrow_closing)} 
                                                        onChange={ date => {setProyectoData({ ...proyectoData, real_completion: date }); dispatch(guardarCambios(true));} }
                                                        monthsShown={2}
                                                        disabled={(proyectos.masterlist?.real_completion !== null && proyectoData.initial_date !== null && proyectoData.estimated_completion !== null && proyectoData.estimated_initial_budget !== null ? (proyectos.edicion_proyecto ? false : true) : false)}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        :
                            null
                    }

                    {
                        proyectoData.initial_date !== null && proyectoData.estimated_completion !== null && proyectoData.estimated_initial_budget !== null ?
                            <div className={'col-md form-group campo_dinero' + (proyectoData.estimated_final_budget !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Actual cost')}>Actual cost:</label>
                                {
                                    (proyectos.masterlist?.estimated_final_budget !== null && !proyectos.edicion_proyecto)
                                        ?
                                            <div className='d-flex'>
                                                <label>{ numberToDecimal(proyectoData.estimated_final_budget, '$ ') }</label>
                                            </div>
                                        :
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <i className="fas fa-dollar-sign"></i>
                                                    </span>
                                                </div>
                                                <CurrencyInput
                                                    autoComplete='off'
                                                    name="estimated_final_budget"
                                                    className="form-control text-center"
                                                    value={proyectoData.estimated_final_budget || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                    disabled={(proyectos.masterlist?.estimated_final_budget !== null && proyectoData.initial_date !== null && proyectoData.estimated_completion !== null && proyectoData.estimated_initial_budget !== null ? (proyectos.edicion_proyecto ? false : true) : false)}
                                                />
                                            </div>
                                }
                            </div>
                        :
                            null
                    }
                </div>
                
            </form>
        </Fragment>
    )
}

export default RehabOnProgressFlipping;