import React from 'react';

const NavbarPublic = () => {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <div className="w-100">
                    <a className="navbar-brand" href="/">
                        <img src="/logo.png" alt="logo" height="35" style={{ marginRight: '0.5rem', verticalAlign: 'baseline' }} />
                    </a>
                </div>

                <div className="collapse navbar-collapse d-lg-flex justify-content-lg-center px-3" id="navbarNav">
                    <ul className="navbar-nav">
                        
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavbarPublic;