import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Modal, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

//Actions
import { buscarTarjetasCredito, eliminarTarjetaCredito } from '../../actions/contabilidad';
import { setAlert } from '../../actions/alert';

//Components
import ModalColumnas from '../../components/extras/ModalColumnas';

const TarjetasCredito = ({ history }) => {

    const dispatch = useDispatch();

    const { permisos_accounting } = useSelector(state => state.auth.user);
    const { tarjetas_deuda, tarjetas_libres } = useSelector(state => state.contabilidad);

    const [tarjetasDeudaTabla, setTarjetasDeudaTabla] = useState([]);
    const [tarjetasLibresTabla, setTarjetasLibresTabla] = useState([]);

    //Calculadora
    const [datosCalculatePagos, setDatosCalculatePagos] = useState({
        filtro: false,
        fecha_inicio: new Date(),
        fecha_fin: new Date(),
        monto_minimo: 0,
        monto_total: 0
    });
    const [calculateModal, setCalculateModal] = useState(false);

    //Sumatoria y promedio
    const [datosSumatoriaPromedioPagos, setDatosSumatoriaPromedioPagos] = useState({
        columna: null,
        sumatoria: 0,
        promedio: 0
    });
    const [tarjetasSumatoriaPromedioDeuda, setTarjetasSumatoriaPromedioDeuda] = useState([]);
    const [tarjetasSumatoriaPromedioLibres, setTarjetasSumatoriaPromedioLibres] = useState([]);
    const [columnasSumatoriaPromedioFilter, setColumnasSumatoriaPromedioFilter] = useState([]);
    const [columnaSumatoriaPromedioData, setColumnaSumatoriaPromedioData] = useState(null);
    const [sumatoriaPromedioModal, setSumatoriaPromedio] = useState(false);

    //Eliminar
    const [datosDeleteTarjeta, setDatosDeleteTarjeta] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const [tablaModificar, setTablaModificar] = useState(null);
    const [columnasModificar, setColumnasModificar] = useState([]);

    //Funciones generales
    const numberToDecimal = (number, prefijo, sufijo) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = prefijo + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufijo;
        return finalFormated;
    }

    //Nuevo
    const handleNewTarjeta = () => {
        history.push('/accounting/new_credit_card');
    }

    //Detalles
    const handleDetailsTarjeta = row => {
        history.push('/accounting/details_credit_card/' + row._id);
    }

    //Editar
    const handleEditTarjeta = (id) => {
        history.push('/accounting/edit_credit_card/' + id);
    }

    //Eliminar
    const handleDeleteTarjeta = (tarjeta) => {
        setDatosDeleteTarjeta(tarjeta);
    }

    const handleOpenDeleteTarjeta = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteTarjeta = () => {
        setDatosDeleteTarjeta(null);
        setDeleteModal(false);
    }

    const deleteTarjeta = async () => {
        await dispatch(eliminarTarjetaCredito(datosDeleteTarjeta._id));
        await handleCloseDeleteTarjeta();
    }

    //Calcular
    const handleOpenCalculatePagos = () => {
        setCalculateModal(true);
    }

    const calculateTotalPagos = async () => {
        let monto_minimo = 0;
        let monto_total = 0;
        let tarjetas_filtradas = [];
        let fechas_ordenadas = [];

        for (var i = 0; i < tarjetas_deuda.length; i++) {
            tarjetas_filtradas.push(tarjetas_deuda[i]);
            
            monto_minimo += tarjetas_deuda[i].pago_minimo ? parseFloat(tarjetas_deuda[i].pago_minimo) : 0;
            monto_total += tarjetas_deuda[i].balance ? parseFloat(tarjetas_deuda[i].balance) : 0;

            if(tarjetas_deuda[i].fecha_pago){
                fechas_ordenadas.push(new Date(tarjetas_deuda[i].fecha_pago));
            }
        }

        fechas_ordenadas.sort((date1, date2) => date1 - date2);

        setTarjetasDeudaTabla(tarjetas_filtradas);

        setDatosCalculatePagos({
            ...datosCalculatePagos, 
            filtro: true, 
            fecha_inicio: fechas_ordenadas.length > 0 ? fechas_ordenadas[0] : new Date(),
            fecha_fin: fechas_ordenadas.length > 0 ? fechas_ordenadas[fechas_ordenadas.length - 1] : new Date(),
            monto_minimo,
            monto_total
        });
    }

    const calculatePagos = async () => {
        let monto_minimo = 0;
        let monto_total = 0;

        if(datosCalculatePagos.fecha_inicio && datosCalculatePagos.fecha_fin){
            if(new Date(datosCalculatePagos.fecha_inicio).getTime() <= new Date(datosCalculatePagos.fecha_fin).getTime()){
                let tarjetas_filtradas = [];

                for (var i = 0; i < tarjetas_deuda.length; i++) {
                    if(new Date(tarjetas_deuda[i].fecha_pago).getTime() >= new Date(datosCalculatePagos.fecha_inicio).getTime() && new Date(tarjetas_deuda[i].fecha_pago).getTime() <= new Date(datosCalculatePagos.fecha_fin).getTime()){
                        tarjetas_filtradas.push(tarjetas_deuda[i]);
                        
                        monto_minimo += tarjetas_deuda[i].pago_minimo ? parseFloat(tarjetas_deuda[i].pago_minimo) : 0;
                        monto_total += tarjetas_deuda[i].balance ? parseFloat(tarjetas_deuda[i].balance) : 0;
                    }
                }

                setTarjetasDeudaTabla(tarjetas_filtradas);

                setDatosCalculatePagos({
                    ...datosCalculatePagos, 
                    filtro: true, 
                    monto_minimo,
                    monto_total
                });
            }else{
                dispatch(setAlert('First enter a valid period', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid period', 'danger'));
        }
    }

    const clearFiltros = async () => {
        setTarjetasDeudaTabla(tarjetas_deuda);

        setDatosCalculatePagos({
            filtro: false,
            fecha_inicio: new Date(),
            fecha_fin: new Date(),
            monto_minimo: 0,
            monto_total: 0
        });

        setCalculateModal(false);
    }

    const handleCloseCalculatePagos = () => {
        setCalculateModal(false);
    }

    //Sumatoria y promedio
    const handleChangeSeleccionadasDeuda = (state) => {
        setTarjetasSumatoriaPromedioDeuda(state.selectedRows);
    }

    const handleChangeSeleccionadasLibres = (state) => {
        setTarjetasSumatoriaPromedioLibres(state.selectedRows);
    }

    const handleOpenSumatoriaPromedio = () => {
        if(tarjetasSumatoriaPromedioDeuda.length > 0 || tarjetasSumatoriaPromedioLibres.length > 0){
            setSumatoriaPromedio(true);
        }else{
            dispatch(setAlert('First select at least one credit card', 'danger'));
        }
    }

    const handleSelectColumnaSumatoriaPromedio = (option) => {
        setColumnaSumatoriaPromedioData(option);

        setDatosSumatoriaPromedioPagos({
            ...datosSumatoriaPromedioPagos,
            columna: option !== null ? option.value : null
        });
    }

    const handleCalcularSumatoriaPromedio = () => {
        let sumatoria = 0;
        let promedio = 0;
        let contador = 0;

        if(datosSumatoriaPromedioPagos.columna){
            for (var i = 0; i < tarjetasSumatoriaPromedioDeuda.length; i++) {
                if(tarjetasSumatoriaPromedioDeuda[i][datosSumatoriaPromedioPagos.columna]){
                    sumatoria += parseFloat(tarjetasSumatoriaPromedioDeuda[i][datosSumatoriaPromedioPagos.columna]);
                }
            }

            for (var i = 0; i < tarjetasSumatoriaPromedioLibres.length; i++) {
                if(tarjetasSumatoriaPromedioLibres[i][datosSumatoriaPromedioPagos.columna]){
                    sumatoria += parseFloat(tarjetasSumatoriaPromedioLibres[i][datosSumatoriaPromedioPagos.columna]);
                }
            }

            contador = tarjetasSumatoriaPromedioDeuda.length + tarjetasSumatoriaPromedioLibres.length;
            promedio = sumatoria / contador;

            setDatosSumatoriaPromedioPagos({
                ...datosSumatoriaPromedioPagos, 
                sumatoria,
                promedio
            });
        }else{
            dispatch(setAlert('First select a column', 'danger'));
        }
    }

    const handleCloseSumatoriaPromedio = () => {
        setDatosSumatoriaPromedioPagos({
            ...datosSumatoriaPromedioPagos, 
            columna: null,
            sumatoria: 0,
            promedio: 0
        });

        setColumnaSumatoriaPromedioData(null);

        setSumatoriaPromedio(false);
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const conditionalRowStyles = [
        {
            when: row => row.tasa_credito <= 5,
            style: {
                backgroundColor: "#5fff5f"
            }
        },
        {
            when: row => row.tasa_credito > 5 && row.tasa_credito <= 10,
            style: {
                backgroundColor: "#c8f7b0"
            }
        }, {
            when: row => row.tasa_credito > 10 && row.tasa_credito <= 15,
            style: {
                backgroundColor: "#ecf7b0"
            }
        }, {
            when: row => row.tasa_credito > 15 && row.tasa_credito <= 20,
            style: {
                backgroundColor: "#f5da8f"
            }
        }, {
            when: row => row.tasa_credito > 20,
            style: {
                backgroundColor: "#f5ab8f"
            }
        }
    ];

    const columnsDeuda = [
        {
            name: 'Type',
            numeric: false,
            selector: 'tipo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 0)?.valor 
                : false,
            cell: item => `${item.tipo}`,
        }, {
            name: 'Last digits',
            numeric: false,
            selector: 'digitos',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 1)?.valor 
                : false,
            cell: item => `${item.digitos}`,
        }, {
            name: 'Expiration',
            numeric: false,
            selector: 'expiracion',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 2)?.valor 
                : false,
            cell: item => `${item.expiracion}`,
        }, {
            name: 'CVV',
            numeric: false,
            selector: 'cvv',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 3)?.valor 
                : false,
            cell: item => `${item.cvv}`,
        }, {
            name: 'Bank',
            numeric: false,
            selector: 'banco',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 4)?.valor 
                : false,
            cell: item => `${item.banco}`,
        }, {
            name: 'Credit line',
            numeric: true,
            selector: 'limite_credito',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 5)?.valor 
                : false,
            cell: item => `${item.limite_credito !== null ? numberToDecimal(item.limite_credito, '$ ', '') : '-'}`,
        }, {
            name: 'Credit line (rate %)',
            numeric: true,
            selector: 'tasa_credito',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 6)?.valor 
                : false,
            cell: item => `${item.tasa_credito !== null ? numberToDecimal(item.tasa_credito, '', ' %') : '-'}`,
        }, {
            name: 'Cash advantage',
            numeric: true,
            selector: 'limite_efectivo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 7)?.valor 
                : false,
            cell: item => `${item.limite_efectivo !== null ? numberToDecimal(item.limite_efectivo, '$ ', '') : '-'}`,
        }, {
            name: 'Cash advantage (rate %)',
            numeric: true,
            selector: 'tasa_efectivo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 8)?.valor 
                : false,
            cell: item => `${item.tasa_efectivo !== null ? numberToDecimal(item.tasa_efectivo, '', ' %') : '-'}`,
        }, {
            name: 'Promotion date',
            numeric: false,
            selector: 'fecha_promocion',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 9)?.valor 
                : false,
            cell: item => {
                if(item.fecha_promocion){
                    let date1 = new Date();
                    let date2 = new Date(item.fecha_promocion);

                    let diferencia_tiempo = date2.getTime() - date1.getTime();
                    let diferencia_dias = Math.floor(diferencia_tiempo / (1000 * 3600 * 24));

                    let mensaje = null;

                    if(diferencia_dias <= 15){
                        mensaje = (
                            <h6><Badge variant="danger">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                        );
                    }else{
                        if(diferencia_dias <= 45){
                            mensaje = (
                                <h6><Badge variant="warning">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                            );
                        }else{
                            mensaje = (
                                <h6><Badge variant="success">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                            );
                        }
                    }

                    let tooltip = 'This promotion date expires in ' + diferencia_dias + (diferencia_dias == 1 ? ' day' : ' days');

                    if(diferencia_dias < 0){
                        tooltip = 'This promotion has expired';
                    }

                    return <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                            >
                                { mensaje }
                            </OverlayTrigger>;
                }else{
                    return 'Not defined';
                }
            }
        }, {
            name: 'Due date',
            numeric: false,
            selector: 'fecha_pago',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 10)?.valor 
                : false,
            cell: item => `${ item.fecha_pago !== null ? new Date(item.fecha_pago).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Closing date',
            numeric: false,
            selector: 'fecha_corte',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 11)?.valor 
                : false,
            cell: item => `${ item.fecha_corte !== null ? new Date(item.fecha_corte).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Previous balance',
            numeric: true,
            selector: 'deuda_anterior',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 12)?.valor 
                : false,
            cell: item => `${item.deuda_anterior !== null ? numberToDecimal(item.deuda_anterior, '$ ', '') : '-'}`,
        }, {
            name: 'New balance',
            numeric: true,
            selector: 'deuda',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 13)?.valor 
                : false,
            cell: item => `${item.deuda !== null ? numberToDecimal(item.deuda, '$ ', '') : '-'}`,
        }, {
            name: 'Minimum payment',
            numeric: true,
            selector: 'pago_minimo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 14)?.valor 
                : false,
            cell: item => `${item.pago_minimo !== null ? numberToDecimal(item.pago_minimo, '$ ', '') : '-'}`,
        }, {
            name: 'Amount paid',
            numeric: true,
            selector: 'total_pagado',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 15)?.valor 
                : false,
            cell: item => `${item.total_pagado !== null ? numberToDecimal(item.total_pagado, '$ ', '') : '-'}`,
        }, {
            name: 'Available credit',
            numeric: true,
            selector: 'credito_disponible',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 16)?.valor 
                : false,
            cell: item => `${item.credito_disponible !== null ? numberToDecimal(item.credito_disponible, '$ ', '') : '-'}`,
        }, {
            name: 'Carry balance',
            numeric: true,
            selector: 'balance',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 17)?.valor 
                : false,
            cell: item => `${item.balance !== null ? numberToDecimal(item.balance, '$ ', '') : '-'}`,
        }, {
            name: 'Paid',
            numeric: false,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 18)?.valor 
                : false,
            cell: item => {
                let mensaje = null;
                let tooltip = null;

                if(item.pagado === true){
                    tooltip = 'This card is already payed';

                    mensaje = (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    if(item.pagado === false){
                        tooltip = 'Pay this card as soon as posible';

                        mensaje = (
                            <h6><Badge variant="danger">No</Badge></h6>
                        );
                    }else{
                        tooltip = 'This card doesn´t have statements yet';

                        mensaje = (
                            <h6><Badge variant="secondary">Not defined</Badge></h6>
                        );
                    }
                }

                return <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                        >
                            { mensaje }
                        </OverlayTrigger>;
            }
        }, {
            name: 'Status',
            numeric: false,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasDeuda')).find(columna => columna.posicion === 19)?.valor 
                : false,
            cell: item => {
                let mensaje = null;
                let tooltip = null;

                if(item.status === true){
                    tooltip = 'This card is already active';

                    mensaje = (
                        <h6><Badge variant="success">Active</Badge></h6>
                    );
                }else{
                    tooltip = 'Active this card to use';

                    mensaje = (
                        <h6><Badge variant="danger">Deactivated</Badge></h6>
                    );
                }

                return <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                        >
                            { mensaje }
                        </OverlayTrigger>;
            }
        }, {
            name: '',
            numeric: false,
            button: true,
            cell: item =>{
                if(permisos_accounting?.edit_credit_card === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleEditTarjeta(item._id)}>
                            <i className="fa fa-edit" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
                
        }
    ];

    const columnsLibre = [
        {
            name: 'Type',
            numeric: false,
            selector: 'tipo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 0)?.valor 
                : false,
            cell: item => `${item.tipo}`,
        }, {
            name: 'Last digits',
            numeric: false,
            selector: 'digitos',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 1)?.valor 
                : false,
            cell: item => `${item.digitos}`,
        }, {
            name: 'Expiration',
            numeric: false,
            selector: 'expiracion',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 2)?.valor 
                : false,
            cell: item => `${item.expiracion}`,
        }, {
            name: 'CVV',
            numeric: false,
            selector: 'cvv',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 3)?.valor 
                : false,
            cell: item => `${item.cvv}`,
        }, {
            name: 'Bank',
            numeric: false,
            selector: 'banco',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 4)?.valor 
                : false,
            cell: item => `${item.banco}`,
        }, {
            name: 'Credit line',
            numeric: true,
            selector: 'limite_credito',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 5)?.valor 
                : false,
            cell: item => `${item.limite_credito !== null ? numberToDecimal(item.limite_credito, '$ ', '') : '-'}`,
        }, {
            name: 'Credit line (rate %)',
            numeric: true,
            selector: 'tasa_credito',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 6)?.valor 
                : false,
            cell: item => `${item.tasa_credito !== null ? numberToDecimal(item.tasa_credito, '', ' %') : '-'}`,
        }, {
            name: 'Cash advantage',
            numeric: true,
            selector: 'limite_efectivo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 7)?.valor 
                : false,
            cell: item => `${item.limite_efectivo !== null ? numberToDecimal(item.limite_efectivo, '$ ', '') : '-'}`,
        }, {
            name: 'Cash advantage (rate %)',
            numeric: true,
            selector: 'tasa_efectivo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 8)?.valor 
                : false,
            cell: item => `${item.tasa_efectivo !== null ? numberToDecimal(item.tasa_efectivo, '', ' %') : '-'}`,
        }, {
            name: 'Promotion date',
            numeric: false,
            selector: 'fecha_promocion',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 9)?.valor 
                : false,
            cell: item => {
                if(item.fecha_promocion){
                    let date1 = new Date();
                    let date2 = new Date(item.fecha_promocion);

                    let diferencia_tiempo = date2.getTime() - date1.getTime();
                    let diferencia_dias = Math.floor(diferencia_tiempo / (1000 * 3600 * 24));

                    let mensaje = null;

                    if(diferencia_dias <= 15){
                        mensaje = (
                            <h6><Badge variant="danger">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                        );
                    }else{
                        if(diferencia_dias <= 45){
                            mensaje = (
                                <h6><Badge variant="warning">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                            );
                        }else{
                            mensaje = (
                                <h6><Badge variant="success">{new Date(item.fecha_promocion).toLocaleDateString('en-US')}</Badge></h6>
                            );
                        }
                    }

                    let tooltip = 'This promotion date expires in ' + diferencia_dias + (diferencia_dias == 1 ? ' day' : ' days');

                    if(diferencia_dias < 0){
                        tooltip = 'This promotion has expired';
                    }

                    return <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                            >
                                { mensaje }
                            </OverlayTrigger>;
                }else{
                    return 'Not defined';
                }
            }
        }, {
            name: 'Due date',
            numeric: false,
            selector: 'fecha_pago',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 10)?.valor 
                : false,
            cell: item => `${ item.fecha_pago !== null ? new Date(item.fecha_pago).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Closing date',
            numeric: false,
            selector: 'fecha_corte',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 11)?.valor 
                : false,
            cell: item => `${ item.fecha_corte !== null ? new Date(item.fecha_corte).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Previous balance',
            numeric: true,
            selector: 'deuda_anterior',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 12)?.valor 
                : false,
            cell: item => `${item.deuda_anterior !== null ? numberToDecimal(item.deuda_anterior, '$ ', '') : '-'}`,
        }, {
            name: 'New balance',
            numeric: true,
            selector: 'deuda',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 13)?.valor 
                : false,
            cell: item => `${item.deuda !== null ? numberToDecimal(item.deuda, '$ ', '') : '-'}`,
        }, {
            name: 'Minimum payment',
            numeric: true,
            selector: 'pago_minimo',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 14)?.valor 
                : false,
            cell: item => `${item.pago_minimo !== null ? numberToDecimal(item.pago_minimo, '$ ', '') : '-'}`,
        }, {
            name: 'Amount paid',
            numeric: true,
            selector: 'total_pagado',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 15)?.valor 
                : false,
            cell: item => `${item.total_pagado !== null ? numberToDecimal(item.total_pagado, '$ ', '') : '-'}`,
        }, {
            name: 'Available credit',
            numeric: true,
            selector: 'credito_disponible',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 16)?.valor 
                : false,
            cell: item => `${item.credito_disponible !== null ? numberToDecimal(item.credito_disponible, '$ ', '') : '-'}`,
        }, {
            name: 'Carry balance',
            numeric: true,
            selector: 'balance',
            sortable: true,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 17)?.valor 
                : false,
            cell: item => `${item.balance !== null ? numberToDecimal(item.balance, '$ ', '') : '-'}`,
        }, {
            name: 'Paid',
            numeric: false,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 18)?.valor 
                : false,
            cell: item => {
                let mensaje = null;
                let tooltip = null;

                if(item.pagado === true){
                    tooltip = 'This card is already payed';

                    mensaje = (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    if(item.pagado === false){
                        tooltip = 'Pay this card as soon as posible';

                        mensaje = (
                            <h6><Badge variant="danger">No</Badge></h6>
                        );
                    }else{
                        tooltip = 'This card doesn´t have statements yet';

                        mensaje = (
                            <h6><Badge variant="secondary">Not defined</Badge></h6>
                        );
                    }
                }

                return <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                        >
                            { mensaje }
                        </OverlayTrigger>;
            }
        }, {
            name: 'Status',
            numeric: false,
            omit: localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre') ? 
                JSON.parse(localStorage.getItem('views_contabilidad_tarjetasCredito_tarjetasLibre')).find(columna => columna.posicion === 19)?.valor 
                : false,
            cell: item => {
                let mensaje = null;
                let tooltip = null;

                if(item.status === true){
                    tooltip = 'This card is already active';

                    mensaje = (
                        <h6><Badge variant="success">Active</Badge></h6>
                    );
                }else{
                    tooltip = 'Active this card to use';

                    mensaje = (
                        <h6><Badge variant="danger">Deactivated</Badge></h6>
                    );
                }

                return <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                        >
                            { mensaje }
                        </OverlayTrigger>;
            }
        }, {
            name: '',
            numeric: false,
            button: true,
            cell: item =>{
                if(permisos_accounting?.edit_credit_card === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleEditTarjeta(item._id)}>
                            <i className="fa fa-edit" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
                
        }
    ];

    useEffect(() => {
        dispatch(buscarTarjetasCredito());
    }, []);

    useEffect(() => {
        
        let options = [];

        if(columnasSumatoriaPromedioFilter.length == 0){
            for (var i = 0; i < columnsDeuda.length; i++) {
                if(columnsDeuda[i].numeric){
                    options.push({ value: columnsDeuda[i].selector, label: columnsDeuda[i].name });
                }
            }

            setColumnasSumatoriaPromedioFilter(options);
        }

    }, [columnsDeuda]);

    useEffect(() => {
        if(datosDeleteTarjeta !== null){
            handleOpenDeleteTarjeta();
        }
    }, [datosDeleteTarjeta]);

    useEffect(() => {
        setTarjetasDeudaTabla(tarjetas_deuda);
    }, [tarjetas_deuda]);

    useEffect(() => {
        setTarjetasLibresTabla(tarjetas_libres);
    }, [tarjetas_libres]);

    return ( 
            <Fragment>

                <ModalColumnas table={tablaModificar} columns={columnasModificar} reestablecerColumns={() => {setColumnasModificar([]); setTablaModificar(null);}}></ModalColumnas>

                {/* Modal de sumatoria y promedio */}
                <Modal show={sumatoriaPromedioModal} onHide={() => {
                    handleCloseSumatoriaPromedio();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Summation and average</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Table column:</label>
                                <Select name="id_columna" isClearable={true} options={columnasSumatoriaPromedioFilter} value={columnaSumatoriaPromedioData} onChange={(value) => handleSelectColumnaSumatoriaPromedio(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Summation:</label>
                                <p>{ numberToDecimal(datosSumatoriaPromedioPagos.sumatoria, '', '') }</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Average:</label>
                                <p>{ numberToDecimal(datosSumatoriaPromedioPagos.promedio, '', '') }</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseSumatoriaPromedio()}><i className="fas fa-times fa-sm"></i> Close</button>
                        <button className="btn btn-primary mr-3" onClick={() => handleCalcularSumatoriaPromedio()}><i className="fas fa-check fa-sm"></i> Calculate</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de calculadora */}
                <Modal show={calculateModal} onHide={() => {
                    handleCloseCalculatePagos();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Calculate payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Initial date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker
                                        name="fecha_inicio"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        selected={ datosCalculatePagos.fecha_inicio ? new Date(datosCalculatePagos.fecha_inicio) : new Date() }
                                        onChange={ date => setDatosCalculatePagos({ ...datosCalculatePagos, fecha_inicio: date }) } 
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Final date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker
                                        name="fecha_fin"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        selected={ datosCalculatePagos.fecha_fin ? new Date(datosCalculatePagos.fecha_fin) : new Date() }
                                        onChange={ date => setDatosCalculatePagos({ ...datosCalculatePagos, fecha_fin: date }) } 
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Minimum payment:</label>
                                <p>{ numberToDecimal(datosCalculatePagos.monto_minimo, '$ ', '') }</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Total payment:</label>
                                <p>{ numberToDecimal(datosCalculatePagos.monto_total, '$ ', '') }</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseCalculatePagos()}><i className="fas fa-times fa-sm"></i> Close</button>
                        {
                            datosCalculatePagos.filtro ?
                                <button className="btn btn-warning mr-3" onClick={clearFiltros}><i className="fas fa-filter fa-sm"></i> Clear</button>
                            :
                                <></>
                        }
                        <button className="btn btn-primary mr-3" onClick={calculatePagos}><i className="fas fa-check fa-sm"></i> Calculate</button>
                        <button className="btn btn-success" onClick={calculateTotalPagos}><i className="fas fa-check-circle fa-sm"></i> Calculate all</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de eliminacion */}
                <Modal show={deleteModal} onHide={() => {
                    handleCloseDeleteTarjeta();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Credit Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to delete the card ending <b>{datosDeleteTarjeta?.digitos || ''}</b>?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseDeleteTarjeta()}><i className="fas fa-times fa-sm"></i> No</button>
                        <button className="btn btn-success" onClick={deleteTarjeta}><i className="fas fa-check fa-sm"></i> Yes</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-4 text-left">
                            <h3>Suggested cards to pay</h3>
                            <p className="text-muted">{tarjetas_deuda ? tarjetas_deuda.length : null} registered credit cards</p>
                        </div>

                        <div className="col-md-8 text-right">
                            {
                                !permisos_accounting?.new_credit_card ?
                                    null
                                :
                                    <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleNewTarjeta()}><i className="fas fa-credit-card fa-sm mr-1"></i>  Add credit card</button>
                            }
                            <button type="button" className={'btn float-right mb-3 ml-3' + (datosCalculatePagos.filtro ? ' btn-warning' : ' btn-primary')} onClick={() => handleOpenCalculatePagos()}>{datosCalculatePagos.filtro ? <i className="fas fa-filter fa-sm mr-1"></i> : <i className="fas fa-money-bill fa-sm mr-1"></i>}  Calculate payments</button>
                            <button type="button" className={'btn float-right mb-3 ml-3' + (datosCalculatePagos.filtro ? ' btn-warning' : ' btn-primary')} onClick={() => handleOpenSumatoriaPromedio()}><i className="fas fa-calculator fa-sm mr-1"></i> Summation and average</button>
                        </div>
                    </div>
                </div>
                
                <div className="row mx-0">
                    <div className="table-responsive pl-2">
                        <div className="text-right">
                            <button type="button" className="btn btn-link float-right" onClick={() => {setColumnasModificar(columnsDeuda); setTablaModificar('views_contabilidad_tarjetasCredito_tarjetasDeuda')}}><i className="fas fa-table fa-sm"></i></button>
                        </div>
                        <DataTable
                            columns={columnsDeuda}
                            data={tarjetasDeudaTabla}
                            noHeader={true}
                            selectableRows
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover={true}
                            onRowDoubleClicked={handleDetailsTarjeta}
                            onSelectedRowsChange={handleChangeSeleccionadasDeuda}
                        />
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-4 text-left">
                            <h3>Debt free credit cards</h3>
                            <p className="text-muted">{tarjetas_libres ? tarjetas_libres.length : null} registered credit cards</p>
                        </div>
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="table-responsive pl-2">
                        <div className="text-right">
                            <button type="button" className="btn btn-link float-right" onClick={() => {setColumnasModificar(columnsLibre); setTablaModificar('views_contabilidad_tarjetasCredito_tarjetasLibre')}}><i className="fas fa-table fa-sm"></i></button>
                        </div>
                        <DataTable
                            columns={columnsLibre}
                            data={tarjetasLibresTabla}
                            noHeader={true}
                            selectableRows
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover={true}
                            onRowDoubleClicked={handleDetailsTarjeta}
                            onSelectedRowsChange={handleChangeSeleccionadasLibres}
                        />
                    </div>
                </div>
            </Fragment>
    );
}
 
export default TarjetasCredito;