import React, { Fragment, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';

import { listarProyectos } from '../../actions/proyectos';
import { obtenerReporte, generarReporte } from '../../actions/actividades';

const styles = {
    titulo: { color: '#2C2052' },
    infoText: {
        margin: 0,
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const DetallesReporteActividades = ({ history }) => {

    const dispatch = useDispatch();

    const { detalles_reporte } = useSelector(state => state.actividades);

    const [datosReporte, setDatosReporte] = useState({
        fecha_inicio: null, 
        fecha_fin: null, 
        total_actividades: null, 
        total_tiempo: null, 
        empleados: []
    });

    const [filtersModal, setFiltersModal] = useState(false);
    const [filtersData, setFiltersData] = useState({
        fecha_inicio: null, 
        fecha_fin: null
    });

    /* Reporte */
    const handleGenerateReporte = () => {
        dispatch(generarReporte(filtersData));
    }

    /* Filtros */
    const handleFiltersModal = () => {
        setFiltersModal(true);
    }

    const handleFilter = () => {
        dispatch(obtenerReporte(filtersData));

        setFiltersModal(false);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    /* Funciones generales */
    const fechasRango = (fecha_inicio, fecha_fin) => {
        let texto_fechas = 'All time';

        if(fecha_inicio !== null && fecha_fin !== null){
            texto_fechas = new Date(fecha_inicio).toLocaleDateString('en-US') + ' - ' + new Date(fecha_fin).toLocaleDateString('en-US');
        }

        return texto_fechas;
    }

    const timeConvert = (segundos) => {
        let mensaje = 'Without time';

        if(segundos > 0){
            let cantidad_minuto = 1000 * 60;

            let minutos = Math.floor(segundos / cantidad_minuto);
            let horas = 0;
            let dias = 0;
            let semanas = 0;

            if(minutos >= 10080){
                semanas = Math.floor(minutos / 10080);
                minutos = minutos - (semanas * 10080);
            }

            if(minutos >= 1440){
                dias = Math.floor(minutos / 1440);
                minutos = minutos - (dias * 1440);
            }

            if(minutos >= 60){
                horas = Math.floor(minutos / 60);
                minutos = minutos - (horas * 60);
            }

            if(minutos || horas || dias || semanas){
                let texto_minutos = minutos > 0 ? minutos + ' ' + (minutos == 1 ? 'minute' : 'minutes') : '';
                let texto_horas = horas > 0 ? horas + ' ' + (horas == 1 ? 'hour' : 'hours') : '';
                let texto_dias = dias > 0 ? dias + ' ' + (dias == 1 ? 'day' : 'days') : '';
                let texto_semanas = semanas > 0 ? semanas + ' ' + (semanas == 1 ? 'week' : 'weeks') : '';

                mensaje = texto_semanas + ' ' + texto_dias + ' ' + texto_horas + ' ' + texto_minutos;
            }else{
                mensaje = 'Less than minute';
            }
        }

        return mensaje;
    }

    const handleGoBack = () => {
        history.push('/actividades/listado');
    }

    /* Tabla */
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    
    const columnasEmpleados = [
        {
            name: 'Employee',
            selector: 'nombre',
            sortable: true,
            cell: item => `${ item.nombre }`,
        }, {
            name: 'Time worked',
            selector: 'tiempo',
            sortable: true,
            cell: item => `${ item.tiempo !== null ? timeConvert(item.tiempo) : 'Not defined' }`,
        }, {
            name: 'Activities asigned',
            selector: 'numero_actividades',
            sortable: true,
            cell: item => `${ item.numero_actividades !== null ? item.numero_actividades : 'Not defined' }`,
        }
    ];

    useEffect(() => {
        dispatch(listarProyectos());
        dispatch(obtenerReporte(filtersData));

        return () => {
            dispatch(obtenerReporte(null));
        }
    }, []);

    useEffect(() => {
        if(detalles_reporte){
            setDatosReporte({
                ...datosReporte, 
                empleados: detalles_reporte.empleados, 
                total_actividades: detalles_reporte.total_actividades, 
                total_tiempo: detalles_reporte.total_tiempo,
                fecha_inicio: detalles_reporte.fecha_inicio,
                fecha_fin: detalles_reporte.fecha_fin
            });
        }
    }, [detalles_reporte]);

    return ( 
            <Fragment>

                {/* Modal de filtros */}
                <Modal backdrop="static" show={filtersModal} size="md" onHide={() => {
                    handleCloseFiltersModal();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Start date:</label>
                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker
                                            name="fecha_inicio"
                                            className="form-control text-center"
                                            dateFormat="MM/dd/yyyy"
                                            selected={ filtersData.fecha_inicio !== null ? new Date(filtersData.fecha_inicio) : '' }
                                            onChange={ date => setFiltersData({ ...filtersData, fecha_inicio: date }) }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">End date:</label>
                                <div className="contenedor_datepicker">
                                    <div className="d-flex">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-calendar-alt"></i>
                                        </span>
                                        <DatePicker
                                            name="fecha_fin"
                                            className="form-control text-center"
                                            dateFormat="MM/dd/yyyy"
                                            minDate={ filtersData.fecha_inicio !== null ? new Date(filtersData.fecha_inicio) : null }
                                            selected={ filtersData.fecha_fin !== null ? new Date(filtersData.fecha_fin) : '' }
                                            onChange={ date => setFiltersData({ ...filtersData, fecha_fin: date }) }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger" onClick={() => handleCloseFiltersModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={() => handleFilter()}><i className="fas fa-solid fa-search fa-sm"></i> Find</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-8 text-left">
                            <h3>Activities report</h3>
                        </div>
                        <div className="col-md-4 text-right">
                            <button type="button" className="btn btn-primary" onClick={() => handleFiltersModal()}><i className="fas fa-solid fa-filter fa-sm"></i> Filters</button>
                            <button type="button" className="btn btn-primary ml-3" onClick={() => handleGenerateReporte()}><i className="fas fa-chart-bar fa-sm"></i> Generate report</button>
                            <button type="button" className="btn btn-secondary ml-3" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3 row">
                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Date range</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{fechasRango(datosReporte.fecha_inicio, datosReporte.fecha_fin)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Total activities</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{datosReporte.total_actividades != null ? datosReporte.total_actividades : 'Not defined'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Total time</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                        <p style={styles.infoText}>{datosReporte.total_tiempo != null ? timeConvert(datosReporte.total_tiempo) : 'Not defined'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive mt-3 pl-2">
                        <h6 className="my-2 ml-3">Employee details</h6>
                            <DataTable
                                columns={columnasEmpleados}
                                data={datosReporte.empleados}
                                noHeader={true}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                    </div>
                </div>
            </Fragment>
    );
}
 
export default DetallesReporteActividades;
