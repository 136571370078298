import { BUSCAR_SECCIONES_TODOLIST, AGREGAR_SECCION_TODOLIST, EDITAR_SECCION_TODOLIST, ELIMINAR_SECCION_TODOLIST } from '../actions/types';

const initialState = {
    secciones: []
}

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        case BUSCAR_SECCIONES_TODOLIST:
            return {
                ...state,
                secciones: payload
            };

        case AGREGAR_SECCION_TODOLIST:
            return {
                ...state,
                secciones: [
                    ...state.secciones, payload
                ]
            };

        case EDITAR_SECCION_TODOLIST:
            return {
                ...state,
                secciones: state.secciones.map(seccion => String(seccion._id) === String(payload._id) ? payload : seccion)
            };

        case ELIMINAR_SECCION_TODOLIST: 
            return {
                ...state,
                secciones: state.secciones.filter(seccion => seccion._id !== payload.id)
            };

        default:
            return state;
    }
}