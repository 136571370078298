import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Timeline from 'react-calendar-timeline';
import { TimelineHeaders, SidebarHeader, DateHeader } from "react-calendar-timeline/lib";
import DatePicker from 'react-datepicker';
import { Spring } from "react-spring/renderprops";
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';

import { buscarUsuarios } from '../../actions/usuarios';
import { setAlert } from '../../actions/alert';
import { agregarSeccion, agregarTarea, editarSeccion, editarTarea, eliminarSeccion, eliminarTarea, obtenerCalendario, detallesProyecto } from '../../actions/proyectos';

import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = {
    tableLeftHeaders: { display: 'flex', backgroundColor: 'rgb(52, 58, 64)', color: 'white', height: '100%', alignItems: 'center', justifyContent: 'space-around' },
    tableLeftHeaders2: { display: 'flex', backgroundColor: 'rgb(52, 58, 64)', color: 'white', height: '100%', alignItems: 'center', justifyContent: 'space-evenly' },
    tableLeftData: { display: 'flex', height: '100%', width: '100%', alignItems: 'center', cursor: "pointer" },
    btnAgregarCateg: { color: '#2196F3', fontSize: '12px', padding: '5px 9px', marginBottom: '8px' },
    itemsTabla: { padding: '0', height: '100%' },
    txtTabla: { fontSize: '15px', overflowX: 'scroll', paddingLeft: '8px' }
}

const AgendaProyecto = ({ history }) => {

    const matches = useMediaQuery('(min-width:768px)');

    const { id_project } = useParams();
    
    const dispatch = useDispatch();

    const detalles = useSelector(state => state.proyectos?.detalles);
    const sections = useSelector(state => state.proyectos?.sections);
    const usuarios = useSelector(state => state.usuarios);

    let sortedData = [];
    let itemsData = [];

    const [seccionData, setSeccionData] = useState({
        id_proyecto: null,
        id: null,
        nombre: null,
        descripcion: null,
        responsable: null,
        inicio: null,
        fin: null,
        id_padre: null,
        days: null
    });

    const [calendarData, setCalendarData] = useState({
        groups: [],
        items: [],
        openGroups: {},
        visibleTimeStart: moment().add(-12, 'day'),
        visibleTimeEnd: moment().add(12, 'day'),
        scrolling: true
    });

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [showModal, setShowModal] = useState(false);

    const [modalData, setModalData] = useState({
        lvl: null,
        modalType: null
    });

    const { groups, items, openGroups, visibleTimeStart, visibleTimeEnd } = calendarData;
    const { id, nombre, descripcion, responsable, inicio, fin, id_seccion, days } = seccionData;
    const { lvl, modalType } = modalData;
    const { loadingData, dataLoaded } = dataStatus;

    const sortData = (group) => {
        const addItem = (id, group, title, start_time, end_time, canMove, canResize, itemProps) => {
            const item = {
                id,
                group,
                title,
                start_time,
                end_time,
                canMove,
                canResize,
                itemProps
            }
            itemsData.push(item);
        }

        const buscarHijos = (tarea) => {
            if (group.tareas.filter(tr => tr.id_padre === tarea._id).length <= 0) {
                return 1;
            } else {
                group.tareas.filter(tr => tr.id_padre === tarea._id).map(t => {
                    t.id = t._id;
                    t.id_seccion = group._id
                    t.title = '';
                    t.lvl = tarea.lvl + 1;
                    t.days = Math.floor((new Date(t.fin).getTime() - new Date(t.inicio).getTime()) / 1000 / 60 / 60 / 24);
                    sortedData.push(t);

                    const itemProps = {
                        style: { background: 'rgb(70, 140, 152)' }
                    };

                    addItem(t._id, t._id, t.nombre, new Date(t.inicio), new Date(t.fin), true, 'both', itemProps);

                    return buscarHijos(t);
                });
            }
        }

        if (group.root) {
            let maxDate = new Date(Math.max.apply(null, group.tareas.map(function (e) {
                return new Date(e.fin);
            })));

            let minDate = new Date(Math.min.apply(null, group.tareas.map(function (e) {
                return new Date(e.inicio);
            })));

            group.id = group._id;
            group.title = '';
            group.lvl = 1;
            group.inicio = minDate;
            group.fin = maxDate;
            group.duration = Math.floor((new Date(maxDate).getTime() - new Date(minDate).getTime()) / 1000 / 60 / 60 / 24);
            sortedData.push(group);

            const itemProps = {
                style: { background: 'rgb(187, 10, 33)' }
            };

            addItem(group._id, group._id, group.nombre, minDate, maxDate, false, false, itemProps);
        }

        group.tareas.filter(t => t.id_padre === null).map(tarea => {

            tarea.id = tarea._id;
            tarea.id_seccion = group._id
            tarea.title = '';
            tarea.lvl = 2;
            tarea.days = Math.floor((new Date(tarea.fin).getTime() - new Date(tarea.inicio).getTime()) / 1000 / 60 / 60 / 24);
            sortedData.push(tarea);

            const itemProps = {
                style: { background: 'rgb(70, 140, 152)' }
            };

            addItem(tarea._id, tarea._id, tarea.nombre, new Date(tarea.inicio), new Date(tarea.fin), true, 'both', itemProps);

            return buscarHijos(tarea);
        });
    }

    const refreshTableData = async () => {
        let ex = [];

        sections.map(section => {
            section.root = true;
            ex.push(section);
        });

        ex.map(section => {
            sortData(section);
        });

        setCalendarData({
            ...calendarData,
            groups: sortedData,
            items: itemsData
        });
    }

    useEffect(() => {
        dispatch(buscarUsuarios());

        let id = id_project;

        if(detalles === null){
            dispatch(detallesProyecto(id));
        }
    }, []);

    useEffect(() => {
        console.log(loadingData);
        if(detalles !== null){
            if ('proyecto' in detalles) {
                dispatch(obtenerCalendario(detalles?.proyecto?._id));

                setDataStatus({
                    ...dataStatus,
                    loadingData: true
                });

                setSeccionData({
                    ...seccionData,
                    id_proyecto: detalles?.proyecto?._id
                });
            }
        }
    }, [detalles]);

    useEffect(() => {
        if (sections !== undefined) {

            refreshTableData().then(() => {

                setDataStatus({
                    loadingData: false,
                    dataLoaded: true
                });

            });
        }
    }, [sections]);

    const onChange = ({ target }) => {
        if (target.name === 'days' && inicio !== null && inicio !== undefined) {
            let finalDate = moment(inicio).add((parseInt(target.value)), 'day');
            finalDate = new Date(finalDate);

            setSeccionData({
                ...seccionData,
                [target.name]: target.value,
                fin: finalDate
            });
        } else {
            setSeccionData({
                ...seccionData, [target.name]: target.value
            });
        }
    }

    const handleCloseModal = () => {
        setModalData({
            lvl: null,
            modalType: null
        });
        setSeccionData({
            nombre: '',
            descripcion: '',
            id_padre: null
        });
        setShowModal(false)
    }

    const handleShowModal = (lvl, modalType, id_seccion, id_padre = null) => {

        setModalData({
            lvl,
            modalType
        });

        if (modalType === 1) {
            setSeccionData({
                ...seccionData,
                id_seccion,
                id_padre,
                id_proyecto: detalles?.proyecto?._id
            });
        }

        setShowModal(true);
    }

    const handleGroups = (group) => {

        const buscarHijos = (tarea) => {
            const seccion = groups.find(g => g.id === tarea.id_seccion);

            if (seccion?.tareas && openGroups[tarea.id]) {
                if (seccion.tareas.filter(tr => tr.id_padre === tarea._id).length <= 0) {
                    return 1;
                } else {
                    seccion.tareas.filter(tr => tr.id_padre === tarea._id).map(t => {

                        setCalendarData(prevState => ({
                            ...prevState,
                            ...calendarData,
                            openGroups: {
                                ...prevState.openGroups,
                                [t.id]: false
                            }
                        }));

                        return buscarHijos(t);
                    });
                }
            }
        }

        const setSubdata = (group, gState) => {
            if (group.tareas.length <= 0) {
                setCalendarData({
                    ...calendarData,
                    openGroups: {
                        ...openGroups,
                        [gState[0]]: gState[1]
                    }
                });
            } else {
                group.tareas.map(tarea => {
                    setCalendarData(prevState => ({
                        ...prevState,
                        ...calendarData,
                        openGroups: {
                            ...prevState.openGroups,
                            [gState[0]]: gState[1],
                            [tarea.id]: false
                        }
                    }));
                });
            }
        }

        if (group.root && openGroups[group.id]) {
            const gState = [group.id, !openGroups[group.id]];

            setSubdata(group, gState);
        } else {
            const oG = openGroups[group.id];

            setCalendarData({
                ...calendarData,
                openGroups: {
                    ...openGroups,
                    [group.id]: !openGroups[group.id]
                }
            });

            if (oG) {
                buscarHijos(group);
            }
        }
    }

    const handleHover = ({ target }) => {
        target.style.opacity = 0.3;
    }

    const handleOut = ({ target }) => {
        target.style.opacity = 1;
    }

    const handleItemClick = (itemId, e, time) => {
        const sect = newGroups.find(section => section._id === String(itemId));
        let days = 0;

        if (sect.lvl >= 2) {
            days = sect.inicio !== null && sect.fin !== null ? Math.floor((new Date(sect.fin).getTime() - new Date(sect.inicio).getTime()) / 1000 / 60 / 60 / 24) : 0;
        }

        if (sect.lvl <= 1) {
            setSeccionData({
                _id: sect._id,
                id: sect._id,
                id_proyecto: sect?.id_proyecto,
                nombre: sect.nombre,
                descripcion: sect.descripcion
            });
        } else {
            setSeccionData({
                id: sect._id,
                id_seccion: sect.id_seccion,
                id_padre: sect.id_padre,
                responsable: sect?.responsable,
                nombre: sect.nombre,
                descripcion: sect.descripcion,
                inicio: sect?.inicio,
                fin: sect?.fin,
                days
            });
        }

        handleShowModal(sect.lvl, 2);
    }

    const handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const sect = newGroups.find(section => section._id === String(itemId));

        sect.inicio = new Date(dragTime);
        let finalDate = moment(dragTime).add((parseInt(sect.days)), 'day');
        sect.fin = new Date(finalDate);

        let tarea = {
            id: sect.id,
            id_seccion: sect.id_seccion,
            id_padre: sect.id_padre,
            responsable: sect.responsable,
            nombre: sect.nombre,
            descripcion: sect.descripcion,
            inicio: sect.inicio,
            fin: sect.fin
        }

        dispatch(editarTarea(tarea));
    }

    const handleItemResize = (itemId, time, edge) => {
        const sect = newGroups.find(section => section._id === String(itemId));

        sect.inicio = edge === 'left' ? new Date(time) : sect.inicio;
        sect.fin = edge === 'right' ? new Date(time) : sect.fin;

        if (new Date(sect.fin).getTime() - new Date(sect.inicio).getTime() >= 0) {
            let tarea = {
                id: sect.id,
                id_seccion: sect.id_seccion,
                id_padre: sect.id_padre,
                responsable: sect.responsable,
                nombre: sect.nombre,
                descripcion: sect.descripcion,
                inicio: sect.inicio,
                fin: sect.fin
            }

            dispatch(editarTarea(tarea));
        } else {
            dispatch(setAlert('Duration must be a positive number', 'warning'));
        }
    }

    const handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
        setCalendarData({
            ...calendarData,
            visibleTimeStart,
            visibleTimeEnd,
            scrolling: true
        });
    }

    const handleSelect = ({ target }) => {
        if (target.value !== null) {

            setSeccionData({
                ...seccionData,
                [target.name]: target.value,
            });
        }
    }

    const handleDateDuration = date => {
        let finalDate = moment(date).add((parseInt(days)), 'day');

        setSeccionData({ ...seccionData, inicio: date, fin: new Date(finalDate) });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (lvl === 0) {
            if(seccionData.nombre !== null && seccionData.nombre !== ''){
                if(seccionData.descripcion !== null && seccionData.descripcion !== ''){
                    dispatch(agregarSeccion(seccionData));

                    setDataStatus({
                        loadingData: true,
                        dataLoaded: false
                    });

                    handleCloseModal();
                }else{
                    dispatch(setAlert('first enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid name', 'danger'));
            }
        } else {

            if (lvl > 2) {
                setCalendarData({
                    ...calendarData,
                    openGroups: {
                        ...openGroups,
                        [seccionData.id_padre]: true
                    }
                });
            }

            if(seccionData.nombre !== null && seccionData.nombre !== ''){
                if(seccionData.descripcion !== null && seccionData.descripcion !== ''){
                    if(seccionData.responsable !== null && seccionData.responsable !== '0'){
                        if(seccionData.inicio !== null && seccionData.inicio !== undefined){
                            if(seccionData.days !== null && seccionData.days > 0){
                                dispatch(agregarTarea(seccionData));
                                handleCloseModal();
                            }else{
                                dispatch(setAlert('FIrst enter a valid days number', 'danger'));
                            }
                        }else{
                            dispatch(setAlert('Time period is required', 'danger'));
                        }
                    }else{
                        dispatch(setAlert('First enter an owner', 'danger'));
                    }
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid name', 'danger'));
            }
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault();

        if (lvl === 1) {
            if(seccionData.nombre !== null && seccionData.nombre !== ''){
                if(seccionData.descripcion !== null && seccionData.descripcion !== ''){
                    dispatch(editarSeccion(seccionData));
                    handleCloseModal();
                }
            }
        } else {
            if(seccionData.nombre !== null && seccionData.nombre !== ''){
                if(seccionData.descripcion !== null && seccionData.descripcion !== ''){
                    if(seccionData.responsable !== null && seccionData.responsable !== 0){
                        if(seccionData.inicio !== null && seccionData.inicio !== undefined){
                            if(seccionData.days !== null && seccionData.days > 0){
                                dispatch(editarTarea(seccionData));
                                handleCloseModal();
                            }else{
                                dispatch(setAlert('FIrst enter a valid days number', 'danger'));
                            }
                        }else{
                            dispatch(setAlert('Time period is required', 'danger'));
                        }
                    }else{
                        dispatch(setAlert('First enter an owner', 'danger'));
                    }
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid name', 'danger'));
            }
        }
    }

    const handleDelete = (id, id_seccion) => {
        if (lvl === 1) {
            dispatch(eliminarSeccion(id));
        } else {
            dispatch(eliminarTarea(id, id_seccion));
        }

        handleCloseModal();
    }

    // FILTER -> filtra si g es root o si el padre de "g" se encuentra en "openGroups" como true
    const newGroups = groups.length > 0 ? groups
        .filter(g => g.root || openGroups[g.id_padre] || (g.id_padre === null && openGroups[g.id_seccion]))
        .map(group => {

            return Object.assign({}, group, {
                title: group.root ? (
                    <div className="row m-0" style={styles.tableLeftData}>
                        <div className="col-md-4 p-0 text-left" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                            <p style={styles.txtTabla}>
                                {openGroups[group.id] ?
                                    <> [-] <i className="far fa-folder-open"></i> </>
                                    :
                                    <> [+] <i className="far fa-folder"></i> </>
                                } {group.nombre}
                            </p>
                        </div>
                        <div className="col-md-3 p-0 text-center" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                            <p style={styles.txtTabla}>
                                {
                                    group.inicio.getDate() === group.inicio.getDate() ? group.inicio.toUTCString() : 'No date'
                                }
                            </p>
                        </div>
                        <div className="col-md-2 p-0 text-center" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                            <p style={styles.txtTabla}>
                                {
                                    isNaN(group.duration) ? '-' : group.duration
                                }
                            </p>
                        </div>

                        <div className="col-md-2 p-0 text-center" style={styles.itemsTabla}>
                            <input type="checkbox"></input>
                        </div>
                        <div className="col-md-1 p-0 text-center" style={styles.itemsTabla}>
                            <button className="btn btnAgregarCateg" onMouseOver={handleHover} onMouseOut={handleOut} onClick={() => { handleShowModal(group.lvl, 1, group.id) }} style={styles.btnAgregarCateg}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                ) : (
                        <div className="row m-0" style={styles.tableLeftData}>
                            <div className="col-md-4 p-0" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                                <p style={Object.assign({}, styles.txtTabla, { paddingLeft: 15 + (15 * group.lvl) })}>
                                    <i className="far fa-file"></i> {` ${group.nombre}`}
                                </p>
                            </div>

                            <div className="col-md-3 p-0 text-center" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                                <p style={styles.txtTabla}>
                                    {
                                        new Date(group.inicio).getDate() === new Date(group.inicio).getDate() ? new Date(group.inicio).toUTCString() : 'No date'
                                    }
                                </p>
                            </div>
                            <div className="col-md-2 p-0 text-center" onClick={() => handleGroups(group)} onDoubleClick={() => handleItemClick(group.id)} style={styles.itemsTabla}>
                                <p style={styles.txtTabla}>
                                    {
                                        isNaN(group.days) ? '-' : group.days
                                    }
                                </p>
                            </div>

                            <div className="col-md-2 p-0 text-center" style={styles.itemsTabla}>
                                <input type="checkbox"></input>
                            </div>
                            <div className="col-md-1 p-0 text-center" style={styles.itemsTabla}>
                                <button className="btn btnAgregarCateg" onMouseOver={handleHover} onMouseOut={handleOut}
                                    onClick={() => { handleShowModal(group.lvl, 1, group.id_seccion, group.id) }} style={styles.btnAgregarCateg}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    )
            });
        }) : [];

    const goBackProject = () => {
        history.goBack();
    }

    return (
        <div className="row form-group" style={matches ? { margin: '2%' } : { marginLeft: '4%' }}>

            <div className="col-md-12">
                <div className="" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2>Schedule</h2>
                    <div className="d-flex">
                        <button type="button" className="btn btn-secondary btn-project-goback mr-3" onClick={() => { goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                        <button className="btn btn-primary" onClick={() => {
                            setCalendarData({
                                ...calendarData,
                                visibleTimeStart: moment().add(-12, 'day').valueOf(),
                                visibleTimeEnd: moment().add(12, 'day').valueOf(),
                                scrolling: true
                            });
                        }}><i className="fas fa-calendar-day fa-sm"></i> Today</button>
                    </div>
                </div>
                <hr />
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true && ((sections?.length > 0 && (items > 1 || items !== null)) || (sections?.length === 0)) ?

                        <>
                            <div className="col-md-12">
                                <Spring
                                    config={{ duration: 150 }}
                                    to={{ visibleTimeStart, visibleTimeEnd }}
                                    immediate={calendarData.scrolling}
                                >
                                    {
                                        props => (
                                            <Timeline
                                                visibleTimeStart={props.visibleTimeStart || moment().add(-12, 'day')}
                                                visibleTimeEnd={props.visibleTimeEnd || moment().add(+12, 'day')}
                                                onTimeChange={handleTimeChange}

                                                groups={newGroups}
                                                items={items}
                                                lineHeight={50}
                                                sidebarWidth={500}
                                                sidebarContent={<div>Above The Left</div>}
                                                stackItems
                                                /* sidebarWidth={0} */
                                                /* minZoom={ 24 * 60 * 60 * 1000 }
                                                maxZoom={ 2 * 365.24 * 86400 * 1000 } */
                                                onItemClick={handleItemClick}
                                                onItemMove={handleItemMove}
                                                onItemResize={handleItemResize}
                                            >
                                                <TimelineHeaders className="sticky">
                                                    <SidebarHeader>
                                                        {({ getRootProps }) => {
                                                            return <div {...getRootProps()} >
                                                                <div className="row pt-3 m-0" style={styles.tableLeftHeaders2}>
                                                                    <div className="col-md-4 p-0 text-center" style={styles.itemsTabla}>
                                                                        <p>Task Name</p>
                                                                    </div>
                                                                    <div className="col-md-3 p-0 text-center" style={styles.itemsTabla}>
                                                                        <p>Start Date</p>
                                                                    </div>
                                                                    <div className="col-md-2 p-0 text-center" style={styles.itemsTabla}>
                                                                        <p>Duration</p>
                                                                    </div>
                                                                    <div className="col-md-2 p-0 text-center" style={styles.itemsTabla}>
                                                                        <p>Complete</p>
                                                                    </div>
                                                                    <div className="col-md-1 p-0 text-center" style={styles.itemsTabla}>
                                                                        <button className="btn btnAgregarCateg" onMouseOver={handleHover} onMouseOut={handleOut} onClick={() => { handleShowModal(0, 1) }} style={styles.btnAgregarCateg}>
                                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>;
                                                        }}
                                                    </SidebarHeader>
                                                    <DateHeader unit="primaryHeader" style={{ backgroundColor: 'rgb(52, 58, 64)' }} />
                                                    <DateHeader />
                                                </TimelineHeaders>
                                            </Timeline>
                                        )
                                    }
                                </Spring>
                            </div>

                            <Modal backdrop="static" show={showModal} onHide={handleCloseModal} animation={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {
                                            modalType !== null && modalType === 1 ?
                                                (
                                                    lvl !== null && lvl === 0 ? 'Add new category' : 'Add new task'
                                                ) :
                                                (
                                                    lvl !== null && lvl === 1 ? 'Edit category' : 'Edit task'
                                                )
                                        }
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {

                                        lvl !== null && ((modalType === 1 && lvl === 0) || (modalType === 2 && lvl === 1)) ?
                                            <form>
                                                <div className="form-group">
                                                    <label>Name:</label>
                                                    <input autoComplete='off' type="text" className="form-control" value={nombre || ''} name="nombre" onChange={e => onChange(e)} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Description:</label>
                                                    <textarea className="form-control" value={descripcion || ''} name="descripcion" onChange={e => onChange(e)}></textarea>
                                                </div>
                                            </form>
                                            :
                                            <form>
                                                <div className="form-group">
                                                    <label>Name:</label>
                                                    <input autoComplete='off' type="text" className="form-control" value={nombre || ''} name="nombre" onChange={e => onChange(e)}></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Description:</label>
                                                    <textarea className="form-control" value={descripcion || ''} name="descripcion" onChange={e => onChange(e)}></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <label>Owner:</label>
                                                    <select className="form-control" name="responsable" onChange={(e) => handleSelect(e)} value={responsable || 0}>
                                                        <option key={0} value={0}>Choose one option</option>
                                                        {
                                                            usuarios.map(usuario => {

                                                                return (
                                                                    <option key={usuario._id} value={usuario._id}> {usuario.nombre} </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group" >
                                                    <div className="row">
                                                        <div className="col-md-4">

                                                            
                                                            <label>Time Period:</label>
                                                            <DatePicker className="form-control" selected={(inicio === null || inicio === undefined) ? null : new Date(inicio)} onChange={date => { handleDateDuration(date) }} />
                                                        </div>
                                                        <div className="col-md-3 form-group">
                                                            <label>Duration:</label>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input autoComplete='off' type="number" className="form-control" min="0" name="days" value={days || 0} onChange={e => onChange(e)} />
                                                                <span className="ml-1"> {parseInt(days) === 1 ? ' Day' : ' Days'} </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4" style={{ display: 'grid' }}>
                                                            <label>Final Date:</label>
                                                            <label>{new Date(fin)?.toUTCString() || ''}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                    }
                                </Modal.Body>
                                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="text-left">
                                        {
                                            modalType !== null && modalType === 1 ?
                                                <Button variant="primary" onClick={handleSubmit}>
                                                    <i className="fas fa-check mr-1"></i>
                                                Save data
                                            </Button>
                                                :
                                                <Button variant="primary" onClick={handleEdit}>
                                                    <i className="fas fa-edit mr-1"></i>
                                                Edit data
                                            </Button>
                                        }
                                        <Button className="ml-1" variant="secondary" onClick={handleCloseModal}>
                                            <i className="fas fa-ban mr-1"></i>
                                        Cancel
                                    </Button>
                                    </div>
                                    <div className="text-right">
                                        <Button variant="warning" onClick={() => { handleDelete(id, id_seccion) }}>
                                            <i className="far fa-trash-alt mr-1"></i>
                                    Delete
                                    </Button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </>

                        :
                        <div className="col-md-10 offset-md-1 text-center" style={matches ? { marginTop: '10%' } : { marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                    :
                    <div className="col-md-10 offset-md-1 text-center" style={matches ? { marginTop: '10%' } : { marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default AgendaProyecto;