import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { loadDashboard, filterProyectos } from '../../actions/dashboard';
import { elegirFiltros, externaTarea } from '../../actions/todolist';
import { buscarSecciones } from '../../actions/secciones_todolist';
import { listarProyectos, seleccionarProyecto } from '../../actions/proyectos';
import { buscarRecordatorios, externaRecordatorio } from '../../actions/recordatorios';
import { setAlert } from '../../actions/alert';
import { externaNomina } from '../../actions/nominas';
import { externoGasto } from '../../actions/gastos';
import { externoInspeccion, externoArchivo } from '../../actions/proyectos';
import { buscarEmpleados } from '../../actions/empleados';
import { buscarUsuarios } from '../../actions/usuarios';
import { buscarCategorias } from '../../actions/categorias_inspeccion';
import { detallesActividad, externaActividad } from '../../actions/actividades';

const styles = {
    sectionContainer: {
        paddingLeft: 15
    },
    titulo: { color: '#2C2052' },
    subtitulo: { margin: 0, marginTop: 5, fontWeight: 'bold' },
    info: { margin: 0, marginBottom: 10 },
    infoText: {
        margin: 0,
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    },
    tituloIcon: {
        //fontSize: 30
    },
    buttonIcon: {
        padding: 15,
        fontSize: 30,
        color: '#000000',
        borderRadius: 10
    },
    labelIcon: {
        fontSize: 30,
    },
    buttonSelector: {
        cursor: 'pointer'
    },
    bgImg: {
        height: '40vw',
        opacity: 0.08,
        position: 'absolute',
        left: '-320px',
        top: '10vh'
    }
}

const Dashboard = ({ history }) => {
    
    const dispatch = useDispatch();

    const dashboard = useSelector(state => state.dashboard);
    const auth = useSelector(state => state.auth);
    const proyectos = useSelector(state => state.proyectos);
    const recordatorios = useSelector(state => state.recordatorios);
    const { detalles: detalles_actividad } = useSelector(state => state.actividades);

    const [typeProjectSelected, setTypeProjectSelected] = useState({
        _id: null,
        nombre: null
    });

    const [projectsFilter, setProjectsFilter] = useState([]);

    const [projectSelectData, setProjectSelectData] = useState(null);

    const onChangeSelectSearch = async (option, origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){

                case 'project_selected':
                    await setProjectSelectData({value, label});
                    dispatch(seleccionarProyecto(value));
                    break;

                default:
                    break;
            }
        }else{
            switch(origin){

                case 'project_selected':
                    await setProjectSelectData(null);
                    dispatch(seleccionarProyecto(null));
                    break;

                default:
                    break;
            }
        }
    };

    const openDetails = () => {
        if(projectSelectData !== null){
            history.push('/projects/buySale/' + projectSelectData.value);
        }else{
            dispatch(setAlert('First select a project', 'danger'));
        }
    }

    const openNewPayroll = () => {

        dispatch(listarProyectos());
        dispatch(buscarEmpleados());

        dispatch(externaNomina(true));
    }

    const openNewExpense = () => {
        
        dispatch(listarProyectos());

        dispatch(externoGasto(true));
    }

    const openNewInspection = () => {

        dispatch(listarProyectos());
        dispatch(buscarUsuarios());
        dispatch(buscarCategorias());

        dispatch(externoInspeccion(true));
    }

    const openInspections = () => {
        if(projectSelectData !== null){
            history.push('/projects/inspections/' + projectSelectData.value);
        }else{
            dispatch(setAlert('First select a project', 'danger'));
        }
    }

    const openNewFile = () => {
        dispatch(listarProyectos());

        dispatch(externoArchivo(true));
    }

    const openFiles = () => {
        if(projectSelectData !== null){
            history.push('/projects/files/' + projectSelectData.value);
        }else{
            dispatch(setAlert('First select a project', 'danger'));
        }
    }

    const openNewReminder = () => {
        dispatch(externaRecordatorio(true));
    }

    const openRemainders = () => {
        history.push('/reminders/' + (projectSelectData?.value || ''));
    }

    const openNewTask = () => {

        dispatch(buscarSecciones());
        dispatch(buscarUsuarios());
        dispatch(listarProyectos());

        dispatch(externaTarea(true));
    }

    const openToDoList = () => {
        dispatch(elegirFiltros({
            id_proyecto: projectSelectData ? projectSelectData.value : null,
            nombre_proyecto: projectSelectData ? projectSelectData.label : null, 
            id_responsable: auth.user?._id,
            ids_colaboradores: [auth.user?._id],
        }));

        history.push('/todolist/listado/' + (projectSelectData?.value || ''));
    }

    const handleOpenActivity = () => {
        dispatch(externaActividad(true));
    }

    const handleFilterProjects = async (status) => {
        await dispatch(filterProyectos({
            project_id: typeProjectSelected._id,
            construction_id: null,
            status_id: status, 
            active: null
        }));
    }

    const handleOpenPageNavbar = (page) => {
        window.location.href = page;
    }

    useEffect(() => {
        dispatch(loadDashboard());
        dispatch(listarProyectos());
        dispatch(buscarRecordatorios(null));
        dispatch(detallesActividad());

        return () => {
            dispatch(buscarRecordatorios(''));
        }
    }, []);

    useEffect(() => {
        if(proyectos.filter_project !== null || proyectos.filter_property !== null || proyectos.filter_status.length > 0){
            history.push('/projects/projects');
        }

        let options = [];

        for (var i = 0; i < proyectos.listado?.length; i++) {
            options.push({ value: proyectos.listado[i]._id, label: proyectos.listado[i].direccion });
        }

        setProjectsFilter(options);

    }, [proyectos]);

    return (
        dashboard.info ?
            <div className="row">
                <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
                <div className="col-md-12 mt-3">
                    
                    <div className="row py-4 desplegado_grande pr-4">
                        <div className="col-md-8">
                            <h4 style={styles.titulo} className="ml-3 d-none d-lg-block d-xl-block">
                                Welcome {auth.user?.nombre}
                            </h4>
                        </div>
                        <div className="col-md-4 text-right">
                            <button className="ml-2 boton-alerta" style={{ backgroundColor: detalles_actividad ? 'rgb(201, 255, 217)' : 'rgb(255, 194, 194)'}} onClick={() => handleOpenActivity()}>
                                <i className={detalles_actividad ? "fas fa-thumbtack icono-no-reminder" : "fas fa-thumbtack icono-reminder"}></i>
                            </button>
                            <button className="ml-2 boton-alerta" style={{ backgroundColor: (dashboard.info?.numero_tareas > 0) ? 'rgb(255, 194, 194)' : 'rgb(201, 255, 217)'}} onClick={() => openToDoList()}>
                                <i className={(dashboard.info?.numero_tareas > 0) ? "fas fa-calendar icono-reminder" : "fas fa-calendar icono-no-reminder"}></i>
                                <p>{dashboard.info?.numero_tareas > 0 ? `+${dashboard.info?.numero_tareas}` : null}</p>
                            </button>
                            <button className="ml-2 boton-alerta" style={{ backgroundColor: (recordatorios.recordatorios.length > 0) ? 'rgb(255, 194, 194)' : 'rgb(201, 255, 217)'}} onClick={() => handleOpenPageNavbar('/reminders')}>
                                <i className={(recordatorios.recordatorios.length > 0) ? "fas fa-bell icono-reminder" : "fas fa-bell icono-no-reminder"}></i>
                                <p>{recordatorios.recordatorios.length > 0 ? `+${recordatorios.recordatorios.length}` : null}</p>
                            </button>
                        </div>
                    </div>

                    <div className="row desplegado_grande">
                        <div className="col-md-3">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-1">
                                        <h5 style={styles.titulo}>Project types</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'Offers & Escrow' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected, _id: dashboard.info?.datos_offer_escrow?._id, nombre: 'Offers & Escrow'})}>
                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_offer_escrow?.total}</p>
                                                <p style={styles.infoText}>Offers & Escrows</p>
                                            </div>
                                        </div>

                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'Flipping' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected, _id: dashboard.info?.datos_flipping?._id, nombre: 'Flipping'})}>
                                            <i className="fa fa-tools" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_flipping?.total}</p>
                                                <p style={styles.infoText}>Flipping</p>
                                            </div>
                                        </div>

                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'New development' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected,  _id: dashboard.info?.datos_new_development?._id, nombre: 'New development'})}>
                                            <i className="fa fa-pencil-ruler" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_new_development?.total}</p>
                                                <p style={styles.infoText}>New development</p>
                                            </div>
                                        </div>

                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'Assigned' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected,  _id: dashboard.info?.datos_assigned?._id, nombre: 'Assigned'})}>
                                            <i className="fa fa-project-diagram" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_assigned?.total}</p>
                                                <p style={styles.infoText}>Assigned</p>
                                            </div>
                                        </div>

                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'Comercial' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected,  _id: dashboard.info?.datos_comercial?._id, nombre: 'Comercial'})}>
                                            <i className="fa fa-dollar-sign" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_comercial?.total}</p>
                                                <p style={styles.infoText}>Comercial</p>
                                            </div>
                                        </div>

                                        <div className={'col-12 mt-4 text-center' + (typeProjectSelected.nombre === 'Inventory' ? ' section_selected' : ' section_unselected')} style={styles.buttonSelector} onClick={() => setTypeProjectSelected({...typeProjectSelected,  _id: dashboard.info?.datos_inventory?._id, nombre: 'Inventory'})}>
                                            <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                <p style={styles.infoNumero}>{dashboard.info?.datos_inventory?.total}</p>
                                                <p style={styles.infoText}>Inventory</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card bg-light h-100">
                                <div className="card-body">
                                    {
                                        typeProjectSelected.nombre === 'Offers & Escrow' ?
                                            <Fragment>
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Projects in offers & escrows</h5>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_offer_escrow?.offer?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_offer_escrow?.offer?.valor}</p>
                                                                <p style={styles.infoText}>Offer</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_offer_escrow?.buying_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_offer_escrow?.buying_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Buying escrow</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_offer_escrow?.sells_in_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_offer_escrow?.sells_in_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Sells in escrow</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === 'Flipping' ?
                                            <Fragment>
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Flipping projects</h5>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.offer?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.offer?.valor}</p>
                                                                <p style={styles.infoText}>Offer</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.buying_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.buying_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Buying escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.on_hold?.group_id)}>
                                                            <i className="fa fa-pause" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.on_hold?.valor}</p>
                                                                <p style={styles.infoText}>On hold</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_inventory" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.inventory?.group_id)}>
                                                            <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.inventory?.valor}</p>
                                                                <p style={styles.infoText}>Inventory</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.waiting_for_repairs?.group_id)}>
                                                            <i className="fa fa-tools" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.waiting_for_repairs?.valor}</p>
                                                                <p style={styles.infoText}>Waiting for repairs</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.rehab_on_progress?.group_id)}>
                                                            <i className="fa fa-hard-hat" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.rehab_on_progress?.valor}</p>
                                                                <p style={styles.infoText}>Rehab on progress</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.holding?.group_id)}>
                                                            <i className="fa fa-hard-hat" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.holding?.valor}</p>
                                                                <p style={styles.infoText}>Holding</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.rented_property?.group_id)}>
                                                            <i className="fa fa-hard-hat" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.rented_property?.valor}</p>
                                                                <p style={styles.infoText}>Rented property</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.listing?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.listing?.valor}</p>
                                                                <p style={styles.infoText}>Listing</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.sells_in_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.sells_in_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Sells in escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.sold_property?.group_id)}>
                                                            <i className="fa fa-store-alt-slash" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.sold_property?.valor}</p>
                                                                <p style={styles.infoText}>Sold property</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_flipping?.cancelled?.group_id)}>
                                                            <i className="fa fa-comment-slash" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_flipping?.cancelled?.valor}</p>
                                                                <p style={styles.infoText}>Cancelled</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === 'New development' ?
                                            <Fragment>
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>New development projects</h5>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.offer?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.offer?.valor}</p>
                                                                <p style={styles.infoText}>Offer</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.buying_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.buying_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Buying escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_inventory" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.inventory?.group_id)}>
                                                            <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.inventory?.valor}</p>
                                                                <p style={styles.infoText}>Inventory</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.new_development?.group_id)}>
                                                            <i className="fa fa-drafting-compass" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.new_development?.valor}</p>
                                                                <p style={styles.infoText}>New development</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.entitlements?.group_id)}>
                                                            <i className="fa fa-file-signature" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.entitlements?.valor}</p>
                                                                <p style={styles.infoText}>Entitlements</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.construction?.group_id)}>
                                                            <i className="fa fa-hard-hat" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.construction?.valor}</p>
                                                                <p style={styles.infoText}>Construction</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.listing?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.listing?.valor}</p>
                                                                <p style={styles.infoText}>Listing</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.sells_in_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.sells_in_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Sells in escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_new_development?.sold_property?.group_id)}>
                                                            <i className="fa fa-store-alt-slash" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_new_development?.sold_property?.valor}</p>
                                                                <p style={styles.infoText}>Sold property</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === 'Assigned' ?
                                            <Fragment>
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Assigned projects</h5>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.offer?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.offer?.valor}</p>
                                                                <p style={styles.infoText}>Offer</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.buying_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.buying_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Buying escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_inventory" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.inventory?.group_id)}>
                                                            <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.inventory?.valor}</p>
                                                                <p style={styles.infoText}>Inventory</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.listing?.group_id)}>
                                                            <i className="fa fa-comments-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.listing?.valor}</p>
                                                                <p style={styles.infoText}>Listing</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.sells_in_escrow?.group_id)}>
                                                            <i className="fa fa-file-invoice-dollar" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.sells_in_escrow?.valor}</p>
                                                                <p style={styles.infoText}>Sells in escrow</p>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_assigned?.sold_property?.group_id)}>
                                                            <i className="fa fa-store-alt-slash" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_assigned?.sold_property?.valor}</p>
                                                                <p style={styles.infoText}>Sold property</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === 'Comercial' ?
                                            <Fragment>
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Comercial projects</h5>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_inventory" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_comercial?.holding?.group_id)}>
                                                            <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_comercial?.holding?.valor}</p>
                                                                <p style={styles.infoText}>Holding</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_comercial?.rented_property?.group_id)}>
                                                            <i className="fa fa-coins" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_comercial?.rented_property?.valor}</p>
                                                                <p style={styles.infoText}>For rent</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="mt-4 text-center status_ordinary" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_comercial?.on_sale?.group_id)}>
                                                            <i className="fa fa-money-bill-wave" style={styles.buttonIcon} />
                                                            <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                                <p style={styles.infoNumero}>{dashboard.info?.contadores_comercial?.on_sale?.valor}</p>
                                                                <p style={styles.infoText}>On sale</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === 'Inventory' ?
                                        <Fragment>
                                            <div className="card-title mb-3">
                                                <h5 style={styles.titulo}>Inventory projects</h5>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-md-4">
                                                    <div className="mt-4 text-center status_inventory" style={styles.buttonSelector} onClick={() => handleFilterProjects(dashboard.info?.contadores_inventory?.inventory?.group_id)}>
                                                        <i className="fa fa-warehouse" style={styles.buttonIcon} />
                                                        <div className="d-flex flex-row-reverse justify-content-center mb-1">
                                                            <p style={styles.infoNumero}>{dashboard.info?.contadores_inventory?.inventory?.valor}</p>
                                                            <p style={styles.infoText}>Inventory</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                            null
                                    }

                                    {
                                        typeProjectSelected.nombre === null ?
                                            <div className="card border-0 bg-light text-center msgNotSelected height-100">
                                                <div className="col-md-12 py-2">
                                                    <i className="fa fa-chart-pie" style={styles.labelIcon} />
                                                </div>
                                                Click on a project type to view his data.
                                            </div>
                                        :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row desplegado_chico">
                        <div className="col-12">
                            <div className="row">

                                <div className="col-12 text-left">
                                    <h5 style={styles.titulo}>Project:</h5>
                                </div>

                                <div className="col-12 text-center mb-2">
                                    <Select isClearable={true} options={projectsFilter} value={projectSelectData} onChange={(value) => onChangeSelectSearch(value, 'project_selected')} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-info-circle" style={styles.tituloIcon} />
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => openDetails()}>Details</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-hard-hat" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewPayroll()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => handleOpenPageNavbar('/payments')}>Payrolls</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-dollar-sign" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewExpense()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => handleOpenPageNavbar('/expenses')}>Expenses</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-tools" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewInspection()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => openInspections()}>Inspections</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-folder-open" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewFile()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => openFiles()}>Files</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fa fa-bell" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewReminder()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => openRemainders()}>Reminders</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 mt-3">
                                    <div className="card bg-light">
                                        <div className="card-body p-3">
                                            <div className="card-title mb-3">
                                                <h6 style={styles.titulo}>
                                                    <i className="icon-icon fas fa-check-square" style={styles.tituloIcon} />
                                                    <button className="button-icon float-right" onClick={() => openNewTask()}>
                                                        <i className="fa fa-plus" style={styles.tituloIcon} />
                                                    </button>
                                                </h6>
                                                <h5 style={styles.titulo} onClick={() => openToDoList()}>To do list</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div></div>
    )
}

export default Dashboard;