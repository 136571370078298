import { BUSCAR_BROKERS, AGREGAR_BROKER, EDITAR_BROKER, DETALLES_BROKER, ELIMINAR_BROKER, BUSCAR_BROKERS_AGENTES, AGREGAR_BROKER_AGENTE, AGREGAR_BROKER_AGENTE_DIRECTO, EDITAR_BROKER_AGENTE, ELIMINAR_BROKER_AGENTE } from '../actions/types';

const initialState = {
    brokers: [],
    agents: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_BROKER:
            return {
                ...state,
                brokers: [
                    ...state.brokers, payload
                ]
            };
        case EDITAR_BROKER:
            return {
                ...state,
                brokers: state.brokers.map(
                    broker => broker._id === payload._id ? payload : broker
                )
            };
        case DETALLES_BROKER:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_BROKER:
            return {
                ...state,
                brokers: state.brokers.filter( broker => broker._id !== payload.id )
            };
        case BUSCAR_BROKERS:
            return {
                ...state,
                brokers: payload
            };
        case BUSCAR_BROKERS_AGENTES:
            return {
                ...state,
                agents: payload
            };
        case AGREGAR_BROKER_AGENTE:
            return {
                ...state,
                agents: [
                    ...state.agents, payload
                ]
            };
        case AGREGAR_BROKER_AGENTE_DIRECTO:

            const broker_elegido = state.brokers.find(broker => broker._id === payload.id_broker);
            
            if(broker_elegido){
                broker_elegido.agentes = [...broker_elegido.agentes, payload];
            }

            return {
                ...state,
                brokers: state.brokers.map( 
                    broker => broker._id === payload.id_broker ? broker_elegido : broker
                )
            };
        case EDITAR_BROKER_AGENTE:
            return {
                ...state,
                agents: state.agents.map(
                    agent => agent._id === payload._id ? payload : agent
                )
            };
        case ELIMINAR_BROKER_AGENTE:
            return {
                ...state,
                agents: state.agents.filter( agent => agent._id !== payload.id )
            };
        default:
            return state;
    }
}