import { BUSCAR_RECORDATORIOS, AGREGAR_RECORDATORIO, EDITAR_RECORDATORIO, DETALLES_RECORDATORIO, STATUS_RECORDATORIO, ELIMINAR_RECORDATORIO, EXTERNA_RECORDATORIO } from '../actions/types';

const initialState = {
    recordatorios: [],
    detalles: null,
    externo: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        case BUSCAR_RECORDATORIOS:
            return {
                ...state,
                recordatorios: payload.recordatorios
            };

        case AGREGAR_RECORDATORIO:
            return {
                ...state,
                recordatorios: [
                    payload, ...state.recordatorios
                ]
            };

        case EDITAR_RECORDATORIO:
            return {
                ...state,
                recordatorios: state.recordatorios.map(recordatorio => String(recordatorio._id) === String(payload._id) ? payload : recordatorio)
            };

        case DETALLES_RECORDATORIO: 
            return {
                ...state,
                detalles: payload
            };

        case STATUS_RECORDATORIO:
            return {
                ...state,
                recordatorios: state.recordatorios.filter( recordatorio => recordatorio._id !== payload.id )
            };

        case ELIMINAR_RECORDATORIO: 
            return {
                ...state,
                recordatorios: state.recordatorios.filter( recordatorio => recordatorio._id !== payload.id )
            }

        case EXTERNA_RECORDATORIO: 
            return {
                ...state,
                externo: payload
            }
            
        default:
            return state;
    }
}