import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { stringSimilarity } from 'string-similarity-js';
import Select from 'react-select';

import { setAlert } from '../../actions/alert';
import { filterProyectos } from '../../actions/dashboard';
import { elegirFiltros } from '../../actions/todolist';
import { buscarTiposProyectos, buscarProyectos, filtrarProyectos } from '../../actions/proyectos';

const Proyectos = ({ history }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const tipos = useSelector(state => state.tipos);

    const [dataTabla, setDataTabla] = useState({
        proyectosTabla: null
    });

    const [tiposProyectoFilter, setTiposProyectoFilter] = useState([]);
    const [tiposConstruccionFilter, setTiposConstruccionFilter] = useState([]);
    const [tiposStatusFilter, setTiposStatusFilter] = useState([]);

    const [tipoProyectoSelectData, setTipoProyectoSelectData] = useState(null);
    const [tipoConstruccionSelectData, setTipoConstruccionSelectData] = useState(null);
    const [tipoStatusSelectData, setTipoStatusSelectData] = useState(null);
    
    const [filtroData, setFiltroData] = useState({
        tipo_proyecto: null,
        tipo_construccion: null,
        tipo_status: [],
        address: '',
        ids_address: []
    });

    const [filtersModal, setFiltersModal] = useState(false);

    const handleOpenReminders = (proyecto_id) => {
        history.push('/reminders', {id: proyecto_id});
    }

    const handleOpenTasks = (proyecto_id) => {
        dispatch(elegirFiltros({
            id_proyecto: proyecto_id
        }));
        
        history.push('/todolist/listado/' + proyecto_id, {id: proyecto_id});
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columns = [
        {
            name: 'Address',
            selector: 'direccion',
            sortable: true,
            cell: item => `${item.direccion || 'Not defined'}`,
            wrap: false,
            grow: 4
        }, {
            name: 'Reminders',
            selector: 'reminders',
            sortable: true,
            cell: item => 
                <button className="btn btn-link text-info" onClick={() => handleOpenReminders(item._id)} >
                    {item.reminders}
                </button>
        },
           {
            name: 'Tasks',
            selector: 'tasks',
            sortable: true,
            cell: item => 
                <button className="btn btn-link text-info" onClick={() => handleOpenTasks(item._id)} >
                    {item.tasks}
                </button>
        }, {
            name: 'Type',
            selector: 'externo',
            sortable: true,
            cell: item => `${item.externo ? 'Side' : 'Personal'}`,
        }, {
            name: 'APN',
            selector: 'apn',
            sortable: true,
            cell: item => `${item.apn?.join(', ')}`,
        }, {
            name: 'Project Type',
            selector: 'nombre_proyecto',
            sortable: true,
            cell: item => `${item.nombre_proyecto}`,
        }, {
            name: 'Property Type',
            selector: 'nombre_construccion',
            sortable: true,
            cell: item => `${item.nombre_construccion}`,
        }, {
            name: 'Status',
            selector: 'nombre_status',
            sortable: true,
            cell: item => `${item.nombre_status}`,
        }, {
            name: 'ID',
            selector: 'folio',
            sortable: true,
            cell: item => `${item.folio || '-'}`,
        }
    ];

    const handleSubmitInstant = async () => {

        if (filtroData.tipo_proyecto !== null || filtroData.tipo_construccion !== null || filtroData.tipo_status.length > 0) {
            await dispatch(filtrarProyectos(filtroData)).then(() => {
                history.push('/projects/projects');
            });
        }else{
            if(proyectos.filter_project === null && proyectos.filter_property === null && proyectos.filter_status.length == 0){
                await dispatch(buscarProyectos());
            }
        }
    }

    const handleShowAll = async (e) => {
        e.preventDefault();

        await setTipoProyectoSelectData(null);
        await setTipoConstruccionSelectData(null);
        await setTipoStatusSelectData(null);

        await setFiltroData({
            ...filtroData,
            tipo_proyecto: null,
            tipo_construccion: null,
            tipo_status: []
        });
        
        await dispatch(filterProyectos({
            project_id: null,
            construction_id: null,
            status_id: [], 
            active: null
        }));

        await dispatch(buscarProyectos());
    }

    const openDetalles = row => {
        if(row.externo === false){
            history.push('/projects/buySale/' + row._id);
        }else{
            history.push('/projects/edit_side/' + row._id);
        }
    }

    const onChangeTypeProject = async (option, action) => {
        let nombre_campo = action.name;

        if(option !== null){
            let { value } = option;

            await setFiltroData({
                ...filtroData,
                [nombre_campo]: value,
            });

            await setTipoProyectoSelectData(option);
        }else{
            await setFiltroData({
                ...filtroData,
                [nombre_campo]: null
            });

            await setTipoProyectoSelectData(null);
        }
    }

    const onChangeTypeConstruction = async (option, action) => {
        let nombre_campo = action.name;

        if(option !== null){
            let { value } = option;

            await setFiltroData({
                ...filtroData,
                [nombre_campo]: value,
            });

            await setTipoConstruccionSelectData(option);
        }else{
            await setFiltroData({
                ...filtroData,
                [nombre_campo]: null
            });

            await setTipoConstruccionSelectData(null);
        }
    }

    const onChangeTypeStatus = async (option, action) => {
        let nombre_campo = action.name;

        if(option?.length > 0){

            let ids_status = [];

            for (var i = 0; i < option?.length; i++) {
                ids_status.push(option[i].value);
            }

            await setFiltroData({
                ...filtroData,
                [nombre_campo]: ids_status,
            });

            await setTipoStatusSelectData(option);
        }else{
            await setFiltroData({
                ...filtroData,
                [nombre_campo]: []
            });

            await setTipoStatusSelectData(null);
        }
    }

    const handleAddress = (origen) => {

        let addressC = null;

        if(origen === 1){
            addressC = document.getElementById('a-address-m').value;
        }else{
            addressC = document.getElementById('a-address').value;
        }

        let proyectos_resultado = proyectos.proyectos;

        if(addressC !== ''){
            proyectos_resultado = proyectos_resultado.filter( proyecto => stringSimilarity(proyecto.direccion, addressC) > 0.1);
        }

        setDataTabla({
            proyectosTabla: proyectos_resultado
        });
    }

    const handleFiltersModal = () => {
        setFiltersModal(true);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    useEffect(() => {
        dispatch(buscarTiposProyectos(null));
    }, []);

    useEffect(() => {
        if(proyectos.filter_project !== null || proyectos.filter_property !== null || proyectos.filter_status.length > 0){
            setFiltroData({
                tipo_proyecto: proyectos.filter_project,
                tipo_construccion: proyectos.filter_property,
                tipo_status: proyectos.filter_status
            });

            let tipo_proyecto_elegido = tipos?.proyectos?.find(tipo_proyecto => String(tipo_proyecto._id) === String(proyectos.filter_project));
            if(tipo_proyecto_elegido){
                setTipoProyectoSelectData({
                    value: tipo_proyecto_elegido._id,
                    label: tipo_proyecto_elegido.nombre
                });
            }

            let tipo_construccion_elegido = tipos?.construcciones?.find(tipo_construccion => String(tipo_construccion._id) === String(proyectos.filter_property));
            if(tipo_construccion_elegido){
                setTipoConstruccionSelectData({
                    value: tipo_construccion_elegido._id,
                    label: tipo_construccion_elegido.nombre
                });
            }
            
            let tipos_status_elegidos = [];
            for (var i = 0; i < proyectos.filter_status.length; i++) {
                let tipo_status_elegido = tipos?.status?.find(tipo_status => String(tipo_status._id) === String(proyectos.filter_status[i]));

                if(tipo_status_elegido){
                    tipos_status_elegidos.push({ value: tipo_status_elegido._id, label: tipo_status_elegido.nombre });
                }
            }

            console.log(tipos_status_elegidos);

            if(tipos_status_elegidos.length > 0){
                setTipoStatusSelectData(tipos_status_elegidos);
            }
        }else{
            dispatch(buscarProyectos());
        }

        /* Listados */

        let options_proyectos = [];
        for (var i = 0; i < tipos?.proyectos?.length; i++) {
            options_proyectos.push({ value: tipos.proyectos[i]._id, label: tipos.proyectos[i].nombre });
        }
        setTiposProyectoFilter(options_proyectos);

        let options_construcciones = [];
        for (var i = 0; i < tipos?.construcciones?.length; i++) {
            options_construcciones.push({ value: tipos.construcciones[i]._id, label: tipos.construcciones[i].nombre });
        }
        setTiposConstruccionFilter(options_construcciones);

        let options_status = [];
        for (var i = 0; i < tipos?.status?.length; i++) {
            options_status.push({ value: tipos.status[i]._id, label: tipos.status[i].nombre });
        }
        setTiposStatusFilter(options_status);
    }, [proyectos.filter_project, proyectos.filter_property, proyectos.filter_status, tipos.proyectos, tipos.construcciones, tipos.status]);

    useEffect(() => {
        if (proyectos.proyectos !== undefined) {
            setDataTabla({
                proyectosTabla: proyectos.proyectos
            });
        }
    }, [proyectos.proyectos]);

    useEffect(() => {
        handleSubmitInstant();
    }, [filtroData]);

    return (
        <Fragment>
            {/* Modal de filtros */}
            <Modal backdrop="static" show={filtersModal} centered onHide={() => {
                setFiltersModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Project filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row">

                        <div className="col-12 mb-2">
                            <label className="font-weight-bold">Project Type</label>
                            <div className="input-group">
                                <Select
                                    id={'tipo_proyecto'}
                                    name={'tipo_proyecto'}
                                    className="select-group"
                                    isClearable={true}
                                    options={tiposProyectoFilter}
                                    value={tipoProyectoSelectData}
                                    onChange={(value, action) => onChangeTypeProject(value, action)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            <label className="font-weight-bold">Property Type</label>
                            <div className="input-group">
                                <Select
                                    id={'tipo_construccion'}
                                    name={'tipo_construccion'}
                                    className="select-group"
                                    isClearable={true}
                                    options={tiposConstruccionFilter}
                                    value={tipoConstruccionSelectData}
                                    onChange={(value, action) => onChangeTypeConstruction(value, action)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            <label className="font-weight-bold">Status</label>
                            <div className="input-group">
                                <Select
                                    id={'tipo_status'}
                                    name={'tipo_status'}
                                    className="select-group"
                                    isClearable={true}
                                    isMulti={true}
                                    options={tiposStatusFilter}
                                    value={tipoStatusSelectData}
                                    onChange={(value, action) => onChangeTypeStatus(value, action)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        <div className="col-12  mb-2">
                            <label className="font-weight-bold">Address</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
                                </div>
                                <input id="a-address-m" aria-describedby="basic-addon2" className="form-control" onChange={() => handleAddress(1)}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseFiltersModal()}><i className="fas fa-solid fa-times fa-sm"></i> Close</button>
                    <button className="btn btn-secondary" onClick={handleShowAll}><i className="fas fa-solid fa-bars fa-sm"></i> Show all projects</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-8 text-left">
                        <h3>Projects</h3>
                        <p className="text-muted col-12 mb-0 px-0"> {proyectos.proyectos?.length} {proyectos.proyectos?.length > 0 ? 'registered projects' : 'registered project'} </p>
                    </div>
                    <div className="col-4 form-group text-right px-0">
                        <button className="btn btn-info mt-2 status-button-list" onClick={() => handleFiltersModal()}><i className="fas fa-filter fa-sm"></i> FIlters</button>
                        <button className="btn btn-secondary filter-projects-lg" onClick={handleShowAll}><i className="fas fa-solid fa-bars fa-sm"></i>  Show all projects</button>
                    </div>
                </div>

                {
                    proyectos.proyectos !== undefined && proyectos.proyectos !== null ?
                        <form className="col-md-12 filter-projects-lg my-4 row">

                            <div className="col-md">
                                <label className="font-weight-bold">Project Type</label>
                                <div className="input-group">
                                    <Select
                                        id={'tipo_proyecto'}
                                        name={'tipo_proyecto'}
                                        className="select-group"
                                        isClearable={true}
                                        options={tiposProyectoFilter}
                                        value={tipoProyectoSelectData}
                                        onChange={(value, action) => onChangeTypeProject(value, action)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>

                            <div className="col-md">
                                <label className="font-weight-bold">Property Type</label>
                                <div className="input-group">
                                    <Select
                                        id={'tipo_construccion'}
                                        name={'tipo_construccion'}
                                        className="select-group"
                                        isClearable={true}
                                        options={tiposConstruccionFilter}
                                        value={tipoConstruccionSelectData}
                                        onChange={(value, action) => onChangeTypeConstruction(value, action)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>

                            <div className="col-md">
                                <label className="font-weight-bold">Status</label>
                                <div className="input-group">
                                    <Select
                                        id={'tipo_status'}
                                        name={'tipo_status'}
                                        className="select-group"
                                        isClearable={true}
                                        isMulti={true}
                                        options={tiposStatusFilter}
                                        value={tipoStatusSelectData}
                                        onChange={(value, action) => onChangeTypeStatus(value, action)}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label className="font-weight-bold">Address</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                                    </div>
                                    <input id="a-address" aria-describedby="basic-addon1" className="form-control" onChange={() => handleAddress(2)}/>
                                </div>
                            </div>
                        </form> :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>LOADING DATA.</h3>
                            <i className="fas fa-spinner fa-pulse"></i>
                        </div>
                }

                <div className="table-responsive pl-2">
                    {
                        proyectos.proyectos !== undefined ?
                            <DataTable
                                columns={columns}
                                data={dataTabla.proyectosTabla || []}
                                noHeader={true}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                                onRowDoubleClicked={openDetalles}
                            />
                            :
                            <></>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default Proyectos;