import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setAlert } from '../../actions/alert';
import { login, recuperarPwd, restablecerPwd } from '../../actions/auth';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
    },
    btnIniciar: {
        background: '#42B4D9',
        borderColor: '#42B4D9'
    },
    email: {
        color: 'darkblue',
        fontWeight: 'bold'
    },
    btnsCodigo: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}

const Login = ({ history }) => {

    const [loginData, setLoginData] = useState({
        email_usuario: '',
        password: ''
    });

    const [pwdData, setPwdData] = useState({
        emailPwd: '',
        password: '',
        confirmar: '',
        recuperando: false,
        restableciendo: false
    });

    const [restab, setRestab] = useState(true);
    
    const { isAuthenticated, codigo } = useSelector(state => state.auth);
    const dispatch = useDispatch(); 

    useEffect(() => {
        if (codigo !== undefined && recuperando === true) {
            handleRestablecer();
        }
    }, [codigo]); 

    if(isAuthenticated){
        return <Redirect to="/dashboard"></Redirect>
    }

    const { email_usuario, password } = loginData;
    const { emailPwd, recuperando, restableciendo } = pwdData;

    const onChange = (e) => {
        setLoginData({
            ...loginData, [e.target.name]: e.target.value
        })
    }

    const onChangeEmailPwd = (e) => {
        setPwdData({
            ...pwdData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        dispatch(login({ email_usuario, password }));
    }

    const submitFormPwd = async (e) => {
        e.preventDefault();
        dispatch(recuperarPwd({ emailPwd }));
        if (codigo !== undefined) {
            handleRestablecer();
        }
    }

    const submitFormRestb = async (e) => {
        e.preventDefault();
        let codigo2 = document.getElementById('codigo2');

        if (codigo !== undefined && String(codigo) === String(codigo2.value)) {
            let contrasena = document.getElementById('contrasena');
            let contrasena2 = document.getElementById('contrasena2');
            if (contrasena.value !== contrasena2.value || !contrasena.value || !contrasena2.value ) {
                dispatch(setAlert('Passwords do not match', 'danger'));
                return;
            } else {
                dispatch(restablecerPwd({ 
                    email_usuario: emailPwd,
                    password: contrasena.value,
                    confirmar: contrasena2.value
                }));
                
                setPwdData({
                    ...pwdData,
                    recuperando: false,
                    restableciendo: false
                });
                setLoginData({
                    password: ''
                });
            }
        }
    }

    // FORM
    const handleClose = () => {
        setPwdData({
            ...pwdData,
            emailPwd: '',
            recuperando: false,
            restableciendo: false
        });
        setRestab({restab: true});
        document.getElementById('codigo2').value = '';
    };

    const handleShow = () => {
        setLoginData({ email_usuario: '', password: '' })
        setPwdData({
            ...pwdData,
            recuperando: true,
            restableciendo: false
        });
        document.getElementById('codigo2').value = '';
    };

    const handleRestablecer = () => {
        setPwdData({
            ...pwdData,
            recuperando: false,
            restableciendo: true
        });
    };

    const handleComparar = () => {
        let codigo2 = document.getElementById('codigo2');
        if (String(codigo) === String(codigo2.value)) {
            dispatch(setAlert('Correct code', 'success'));
            setRestab(false);
        } else {
            dispatch(setAlert('Incorrect code', 'danger'));
        }
    };

    const handleNuevoCodigo = () => {
        dispatch(recuperarPwd({ emailPwd }));
    };

    return ( 
        <div className="row d-flex align-items-center" style={styles.rowStyle}>
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light "> 
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <img src="logo.png" className="d-inline-block my-4" alt="logo" height="45" />
                            <h4 className="card-title">Welcome!</h4>
                        </div>
                        <form id="f-login" onSubmit={submitForm} method="POST" className={ recuperando === true || restableciendo === true ? 'd-none' : '' }>
                            <div className="form-group">
                                <input
                                    id="email-L"
                                    type="text"
                                    className="form-control"
                                    placeholder="Email or username"
                                    name="email_usuario"
                                    value={email_usuario || ''}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    value={password || ''}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <button type="button" className="btn btn-link text-info" onClick={handleShow}>Forgot account?</button>
                            <div className="form-group text-right pt-3">
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}><i className="fas fa-sign-in-alt"></i> Log In</button>
                            </div>
                        </form>

                        <form id="f-recuperar" onSubmit={submitFormPwd} method="POST" className={ recuperando === true ? '' : 'd-none' }>
                            <div className="form-group">
                                <h5>Reset your password</h5>
                                <p>Please enter your email to get a code and reset the password.</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email or username"
                                    name="emailPwd"
                                    value={emailPwd || ''} 
                                    onChange={e => onChangeEmailPwd(e)}></input>
                            </div>
                            <button type="button" className="btn btn-link text-info" onClick={handleClose}>Log In</button>
                            <div className="form-group text-right pt-3">
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}><i className="fas fa-paper-plane fa-sm"></i> Send</button>
                            </div>
                        </form>

                        <form id="f-restablecer" onSubmit={submitFormRestb} method="POST" className={ restableciendo === true ? '' : 'd-none' }>
                            <div className="form-group comp-codigo">
                                <h5>Please enter the 4 digits code.</h5>
                                <input
                                    type="number"
                                    className='form-control'
                                    disabled={restab ? false : true}
                                    placeholder="Code"
                                    id="codigo2"></input>
                                <div>
                                    <button type="button" className='btn btn-link text-info' disabled={restab ? false : true} onClick={handleNuevoCodigo}>
                                        Send a new code to the email <span style={styles.email}> { emailPwd } </span> 
                                    </button>
                                </div>
                            </div>
                            <div className="form-group" style={styles.btnsCodigo}>
                                <button id="btn-volver" type="button" className='btn btn-secondary' disabled={restab ? false : true} onClick={handleShow}>
                                    <i className="fas fa-times fa-sm"></i>Cancel
                                </button>
                                
                                <button id="ingresar" type="button" className='btn btn-primary' disabled={restab ? false : true} onClick={handleComparar}>
                                    <i className="fas fa-arrow-right fa-sm"></i>Continue
                                </button>
                            </div>
                            <div className="form-group" hidden={restab}>
                                <h5>Reset the password of <span style={styles.email}>{ emailPwd }</span></h5>
                                <p>Enter the new password.</p>
                                <div className={restab ? 'form-group d-none' : 'form-group'}>
                                    <input id="contrasena" autoComplete="new-password" type="password" placeholder="New password" className="form-control"/>
                                </div>
                                <div className={restab ? 'form-group d-none' : 'form-group'}>
                                    <input id="contrasena2" autoComplete="new-password" type="password" placeholder="Confirm the password" className="form-control" name="password" onChange={e => onChange(e)}/>
                                </div>
                            </div>
                            <div className="form-group" hidden={restab} style={styles.btnsCodigo}>
                                <button id="btn-cancelar" type="button" className="btn btn-secondary" onClick={handleClose}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}><i className="fas fa-paper-plane fa-sm"></i> Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;