import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

//Actions
import { agregarInspectionProyecto, externoInspeccion } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const ModalInspection = () => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const categorias_inspeccion = useSelector(state => state.categorias_inspeccion);
    const usuarios = useSelector(state => state.usuarios);

    const [dataTabla, setDataTabla] = useState({
        proyectosTabla: null,
        categoriasTabla: null
    });

    const { proyectosTabla, categoriasTabla } = dataTabla;

    //Nuevo
    let files_ref = createRef();

    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        id_categoria: null,
        id_responsable: null,
        nombre: '',
        descripcion: '',
        archivos: null
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const onChangeFilesNuevo = () => {
        setDatosNuevo({
            ...datosNuevo,
            archivos: files_ref.current.files
        });
    }

    const handleViewFile = (index) => {
        if(index !== null){
            const url = URL.createObjectURL(index);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    const nuevoArchivo = async () => {
        if(datosNuevo.nombre !== null && datosNuevo.nombre !== ''){
            if(datosNuevo.descripcion !== null && datosNuevo.descripcion !== ''){
                await setBotonNuevo(true);

                let formData = new FormData();
                formData.append('id_proyecto', datosNuevo.id_proyecto);
                formData.append('id_categoria', datosNuevo.id_categoria);
                formData.append('id_responsable', datosNuevo.id_responsable);
                formData.append('nombre', datosNuevo.nombre);
                formData.append('descripcion', datosNuevo.descripcion);
                
                if(datosNuevo.archivos !== null){
                    for (var i = 0; i < datosNuevo.archivos.length; i++) {
                        formData.append('archivos', datosNuevo.archivos[i]);
                    }
                }

                await dispatch(agregarInspectionProyecto(formData));

                handleCloseNuevoArchivo();
            }else{
                dispatch(setAlert('Firste enter a valid description (Notes)', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid inspection name', 'danger'));
        }

        await setBotonNuevo(false);
    }
    
    const handleCloseNuevoArchivo = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            id_categoria: null,
            id_responsable: null,
            nombre: '',
            descripcion: '',
            archivos: null
        });

        dispatch(externoInspeccion(false));
    }

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosTabla: proyectos.listado
        });

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }, [proyectos]);

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            categoriasTabla: categorias_inspeccion.categorias
        });
    }, [categorias_inspeccion]);

    return (
        <Modal show={proyectos.externo_inspeccion} size="lg" onHide={() => {
            handleCloseNuevoArchivo();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>New inspection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row"> 
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Project:</label>
                        <select className="form-control" value={datosNuevo.id_proyecto || ''} name="id_proyecto" onChange={(e) => onChangeNuevo(e)}>
                            <option value={null}>General</option>
                            {
                                proyectosTabla?.length > 0 ?
                                    proyectosTabla?.map(proyecto => {

                                        let selected = false;

                                        if(String(proyectos.id_proyecto_dashboard) === String(proyecto._id)){
                                            selected = true;
                                        }

                                        return(
                                            <option key={proyecto._id} value={ proyecto._id } selected={ selected }>
                                                { proyecto.direccion }
                                            </option>
                                        )
                                    })
                                : ''
                            } 
                        </select>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Type of inspection:</label>
                        <select className="form-control" value={datosNuevo.id_categoria || ''} name="id_categoria" onChange={(e) => onChangeNuevo(e)}>
                            <option value={null}>General</option>
                            {
                                categoriasTabla?.length > 0 ?
                                    categoriasTabla?.map(categoria => {
                                        return(
                                            <option key={categoria._id} value={ categoria._id }>
                                                { categoria.nombre }
                                            </option>
                                        )
                                    })
                                : ''
                            } 
                        </select>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Name of inspection:</label>
                        <div className="contenedor_datepicker">
                            <input name="nombre" className="form-control" type="text" onChange={e => onChangeNuevo(e)} value={datosNuevo.nombre || ''}/>
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Notes:</label>
                        <div className="contenedor_datepicker">
                            <textarea className='form-control' name="descripcion" onChange={e => onChangeNuevo(e)} value={datosNuevo.descripcion || ''}></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Assinged user:</label> <br></br>
                        <select className="custom-select" name="id_responsable" onChange={(e) => {onChangeNuevo(e)}}>
                            <option value={null}>General</option>
                            {
                                usuarios ?
                                    usuarios.map(user => (
                                        <option key={user._id} value={user._id}>{user.nombre}</option>
                                    )) 
                                :
                                    null
                            }
                        </select>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Files:</label> <br></br>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input form-control" name="files" id="files" ref={files_ref} onChange={onChangeFilesNuevo} aria-describedby="view_files"/>
                                <label className="custom-file-label">{datosNuevo.archivos ? 'File Selected' : 'Choose file'}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevo.archivos)}><i class="fas fa-eye"></i> View File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevoArchivo()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={nuevoArchivo} disabled={botonNuevo}><i className="fas fa-check fa-sm"></i> Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalInspection;