import { BUSCAR_LENDERS, AGREGAR_LENDER, SELECCIONAR_LENDER_BUY, EDITAR_LENDER, DETALLES_LENDER, ELIMINAR_LENDER } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarLenders = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/lenders`, config);
        dispatch({
            type: BUSCAR_LENDERS,
            payload: res.data.lenders
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarLender = (lender) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(lender);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/lenders/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_LENDER,
            payload: res.data.lender
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarLenderMasterlistBuy = (lender) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(lender);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/lenders/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_LENDER,
            payload: res.data.lender
        });

        dispatch({
            type: SELECCIONAR_LENDER_BUY,
            payload: res.data.lender
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarLender = (lender) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(lender);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/lenders/editar`, body, config);
        dispatch({
            type: EDITAR_LENDER,
            payload: res.data.lender
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesLender = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/lenders/detalles`, body, config);

        dispatch({
            type: DETALLES_LENDER,
            payload: res.data.lender
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarLender = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/lenders/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_LENDER,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}