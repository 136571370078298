import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { stringSimilarity } from 'string-similarity-js';

import { filterOfertas } from '../../actions/dashboard';
import { buscarTiposProyectos, buscarOfertas, filtrarOfertas, externoEliminar } from '../../actions/proyectos';

const Ofertas = ({ history }) => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const tipos = useSelector(state => state.tipos);
    const { ofertas, filter_project, filter_property, filter_active } = useSelector(state => state.proyectos);

    const [dataTabla, setDataTabla] = useState({
        ofertasTabla: null
    });
    
    const [filtroData, setFiltroData] = useState({
        tipo_proyecto: null,
        tipo_construccion: null,
        id_responsable: null,
        activo: true, 
        address: '',
        ids_address: []
    });

    const [filtersModal, setFiltersModal] = useState(false);

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columns = [
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-danger" onClick={() => handleDelete(item._id)}>
                    Delete
                </button>,
        }, {
            name: 'Address',
            selector: 'direccion',
            sortable: true,
            cell: item => `${item.direccion || 'Not defined'}`,
            wrap: false,
            grow: 4
        }, {
            name: 'APN',
            selector: 'apn',
            sortable: true,
            cell: item => `${item.apn?.join(', ')}`,
        }, {
            name: 'Project Type',
            selector: 'nombre_proyecto',
            sortable: true,
            cell: item => `${item.nombre_proyecto}`,
        }, {
            name: 'Property Type',
            selector: 'nombre_construccion',
            sortable: true,
            cell: item => `${item.nombre_construccion}`,
        }, {
            name: 'Listing ID',
            selector: 'listing_id',
            sortable: true,
            cell: item => `${item.listing_id || '-'}`,
        }, {
            name: 'Assigned user',
            selector: 'usuario_responsable',
            sortable: true,
            cell: item => `${item.usuario_responsable || 'Not assigned'}`,
        }, {
            name: 'Created',
            selector: 'creacion',
            sortable: true,
            cell: item => `${ item.creacion !== null ? new Date(item.creacion).toLocaleDateString('en-US') : 'Not defined' }`,
        }
    ];

    const handleSubmitInstant = async () => {

        if (filtroData.tipo_proyecto !== null || filtroData.tipo_construccion !== null ||  filtroData.id_responsable !== null || filtroData.activo !== null) {
            await dispatch(filtrarOfertas(filtroData)).then(() => {
                history.push('/projects/offers');
            });
        }else{
            if((filter_project === null || filter_project === 'null') && (filter_property === null || filter_property === 'null') && (filter_active === null || filter_active === 'null')){
                await dispatch(buscarOfertas());
            }
        }
    }

    const handleShowAll = async (e) => {
        e.preventDefault();

        await setFiltroData({
            ...filtroData,
            tipo_proyecto: null,
            tipo_construccion: null
        });
        
        await dispatch(filterOfertas({
            project_id: null,
            construction_id: null,
            user_id: null, 
            active: true
        }));

        await dispatch(buscarOfertas());
    }

    const handleShowMy = async (e) => {
        e.preventDefault();

        await setFiltroData({
            ...filtroData,
            tipo_proyecto: null,
            tipo_construccion: null,
            id_responsable: auth.user?._id
        });
        
        await dispatch(filterOfertas({
            project_id: null,
            construction_id: null,
            user_id: auth.user?._id, 
            active: true
        }));

        await dispatch(filtrarOfertas({
            tipo_proyecto: null,
            tipo_construccion: null,
            id_responsable: auth.user?._id, 
            activo: true
        }));
    }

    const handleDelete = async (id) => {
        await dispatch(externoEliminar(id, true));
    }

    const handleOpenDetalles = row => {
        history.push('/projects/edit_offer/' + row._id);
    }

    const handleTypeProject = (origen) => {

        let valor = null;

        if(origen === 1 || origen === '1'){
            valor = document.getElementById('type_project_m');
        }else{
            valor = document.getElementById('type_project_b');
        }

        if (valor.selectedIndex !== 0) {
            setFiltroData({
                ...filtroData,
                tipo_proyecto: valor.value
            });
        } else {
            setFiltroData({
                ...filtroData,
                tipo_proyecto: null
            });
        }
    }

    const handleTypeConstruction = (origen) => {

        let valor = null;

        if(origen === 1 || origen === '1'){
            valor = document.getElementById('type_construction_m');
        }else{
            valor = document.getElementById('type_construction_b');
        }

        if (valor.selectedIndex !== 0) {
            setFiltroData({
                ...filtroData,
                tipo_construccion: valor.value
            });
        } else {
            setFiltroData({
                ...filtroData,
                tipo_construccion: null
            });
        }
    }

    const handleStatus = (origen) => {

        let valor = null;

        if(origen === 1 || origen === '1'){
            valor = document.getElementById('activo_m');
        }else{
            valor = document.getElementById('activo_b');
        }

        setFiltroData({
            ...filtroData,
            activo: valor.value === true || valor.value === 'true' ? true : false
        });
    }

    const handleAddress = (origen) => {

        let addressC = null;

        if(origen === 1 || origen === '1'){
            addressC = document.getElementById('a-address-m').value;
        }else{
            addressC = document.getElementById('a-address').value;
        }

        let ofertas_resultado = ofertas;

        if(addressC){
            ofertas_resultado = ofertas_resultado.filter( proyecto => stringSimilarity(proyecto.direccion, addressC) > 0.1);
        }

        setDataTabla({
            ofertasTabla: ofertas_resultado
        });
    }

    const handleFiltersModal = () => {
        setFiltersModal(true);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    useEffect(() => {
        if(filter_project !== null || filter_property !== null || filter_active !== null){
            setFiltroData({
                tipo_proyecto: filter_project,
                tipo_construccion: filter_property, 
                activo: filter_active
            });
        }else{
            dispatch(buscarOfertas());
        }

        dispatch(buscarTiposProyectos(null));
    }, []);

    useEffect(() => {
        if (ofertas) {
            setDataTabla({
                ofertasTabla: ofertas
            });
        }
    }, [ofertas]);

    useEffect(() => {
        handleSubmitInstant();
    }, [filtroData]);

    return (
        <Fragment>
            {/* Modal de filtros */}
            <Modal backdrop="static" show={filtersModal} centered onHide={() => {
                setFiltersModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Offers filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row">
                        <div className="col-12 mb-2">
                            <label className="font-weight-bold">Project Type</label>
                            <select id="type_project_m" className="form-control" onChange={() => handleTypeProject(1)} value={filtroData.tipo_proyecto}>
                                <option value={null} selected={!filtroData.tipo_proyecto ? true : false}>Choose a project type</option>
                                {
                                    tipos.proyectos?.length > 0 ?
                                        tipos.proyectos?.map(tipo => {
                                            return (
                                                <option key={tipo._id} value={tipo._id}>
                                                    {tipo.nombre}
                                                </option>
                                            )
                                        })
                                    : ''
                                }
                            </select>
                        </div>

                        <div className="col-12 mb-2">
                            <label className="font-weight-bold">Property Type</label>
                            <select id="type_construction_m" className="form-control" onChange={() => handleTypeConstruction(1)} value={filtroData.tipo_construccion}>
                                <option value={null} selected={!filtroData.tipo_construccion ? true : false}>Choose a property type</option>
                                {
                                    tipos.construcciones?.length > 0 ?
                                        tipos.construcciones?.map(tipo => {
                                            return (
                                                <option key={tipo._id} value={tipo._id}>
                                                    {tipo.nombre}
                                                </option>
                                            )
                                        })
                                    : ''
                                }
                            </select>
                        </div>

                        <div className="col-12  mb-2">
                            <label className="font-weight-bold">Address</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon2"><i className="fas fa-search"></i></span>
                                </div>
                                <input id="a-address-m" aria-describedby="basic-addon2" className="form-control" onChange={() => handleAddress(1)}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseFiltersModal()}><i className="fas fa-solid fa-times fa-sm"></i> Close</button>
                    <button className="btn btn-secondary mr-3" onClick={handleShowMy}><i className="fas fa-solid fa-bars fa-sm"></i> Show my offers</button>
                    <button className="btn btn-primary" onClick={handleShowAll}><i className="fas fa-solid fa-bars fa-sm"></i> Show offers of all</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-8 text-left">
                        <h3>Offers</h3>
                        <p className="text-muted col-12 mb-0 px-0"> {ofertas?.length} {ofertas?.length === 1 ? 'pending offer' : 'pending offers'} </p>
                    </div>
                    <div className="col-4 form-group text-right px-0">
                        <button className="btn btn-info mt-2 status-button-list" onClick={() => handleFiltersModal()}><i className="fas fa-filter fa-sm"></i> Filters</button>
                        <button className="btn btn-primary filter-projects-lg mr-2" onClick={handleShowAll}><i className="fas fa-solid fa-bars fa-sm"></i>  Show offers of all</button>
                        <button className="btn btn-secondary filter-projects-lg" onClick={handleShowMy}><i className="fas fa-solid fa-bars fa-sm"></i>  Show my offers</button>
                    </div>
                </div>

                {
                    ofertas ?
                        <form className="col-md-12 filter-projects-lg my-4 row">
                            <div className="col-md-3">
                                <label className="font-weight-bold">Project Type</label>
                                <select id="type_project_b" className="form-control" onChange={() => handleTypeProject(2)} value={filtroData.tipo_proyecto}>
                                    <option value={null} selected={!filtroData.tipo_proyecto ? true : false}>Choose a project type</option>
                                    {
                                        tipos.proyectos?.length > 0 ?
                                            tipos.proyectos?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={tipo._id}>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className="col-md-3">
                                <label className="font-weight-bold">Property Type</label>
                                <select id="type_construction_b" className="form-control" onChange={() => handleTypeConstruction(2)} value={filtroData.tipo_construccion}>
                                    <option value={null} selected={!filtroData.tipo_construccion ? true : false}>Choose a property type</option>
                                    {
                                        tipos.construcciones?.length > 0 ?
                                            tipos.construcciones?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={tipo._id}>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className="col-md-2">
                                <label className="font-weight-bold">Status</label>
                                <select id="activo_b" className="form-control" onChange={() => handleStatus(2)} value={filtroData.activo}>
                                    <option value={true} selected={filtroData.activo === true ? true : false}>Enable</option>
                                    <option value={false} selected={filtroData.activo === false ? true : false}>Disable</option>
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label className="font-weight-bold">Address</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                                    </div>
                                    <input id="a-address" aria-describedby="basic-addon1" className="form-control" onChange={() => handleAddress(2)}/>
                                </div>
                            </div>
                        </form>
                    :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>LOADING DATA.</h3>
                            <i className="fas fa-spinner fa-pulse"></i>
                        </div>
                }

                <div className="table-responsive pl-2">
                    {
                        ofertas ?
                            <DataTable
                                columns={columns}
                                data={dataTabla.ofertasTabla || []}
                                noHeader={true}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                                onRowDoubleClicked={handleOpenDetalles}
                            />
                            :
                            <></>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default Ofertas;