import {
    VALIDAR_PROYECTO, 
    AGREGAR_OFERTA, 
    ACTUALIZAR_DETALLES, 
    DETALLES_PROYECTO, 
    EDITANDO_PROYECTO, 
    EXTERNO_ELIMINAR, 
    ELIMINAR_PROYECTO, 
    BUSCAR_OFERTAS, 
    FILTER_OFERTAS, 
    FILTRAR_OFERTAS, 
    LISTAR_PROYECTOS, 
    BUSCAR_PROYECTOS, 
    FILTER_PROYECTOS, 
    FILTRAR_PROYECTOS, 
    HABILITAR_EDICION_PROYECTO, 
    MATERLIST_PROYECTO, 
    
    EXTERNO_ARCHIVO_PROYECTO, 
    EXTERNO_ARCHIVO, 
    ARCHIVOS_PROYECTO, 
    PUBLIC_TOKENS_ARCHIVOS_PROYECTO, 
    EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO, 
    DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO, 
    AGREGAR_ARCHIVO_PROYECTO, 
    AGREGAR_CARPETA_PROYECTO, 
    DETALLES_ARCHIVO_PROYECTO, 
    DETALLES_CARPETA_PROYECTO, 
    EDITAR_ARCHIVO_PROYECTO, 
    EDITAR_CARPETA_PROYECTO, 
    ELIMINAR_ARCHIVO_PROYECTO, 
    ELIMINAR_CARPETA_PROYECTO, 
    COMPARTIR_LINK_ARCHIVOS, 

    COMPARTIR_LINK_NOTAS, 
    COMPARTIR_LINK_REPORTE, 
    EXTERNO_NOTA, 
    NOTAS_PROYECTO, 
    AGREGAR_NOTA_PROYECTO, 
    EDITAR_NOTA_PROYECTO, 
    DETALLES_NOTA_PROYECTO, 
    ELIMINAR_NOTA_PROYECTO, 

    INSPECCIONES_PROYECTO, 
    AGREGAR_INSPECCION_PROYECTO, 
    EDITAR_INSPECCION_PROYECTO, 
    DETALLES_INSPECCION_PROYECTO, 
    ELIMINAR_INSPECCION_PROYECTO, 
    EXTERNO_INSPECCION, 

    REPARACIONES_PROYECTO, 
    AGREGAR_REPARACION_PROYECTO, 
    EDITAR_REPARACION_PROYECTO, 
    DETALLES_REPARACION_PROYECTO, 
    ELIMINAR_REPARACION_PROYECTO, 

    AGREGAR_COUNTER_LISTING_PROYECTO, 
    EDITAR_COUNTER_LISTING_PROYECTO, 
    DETALLES_COUNTER_LISTING_PROYECTO, 
    ELIMINAR_COUNTER_LISTING_PROYECTO, 

    SELECCIONAR_ESCROW_BUY, 
    SELECCIONAR_ESCROW_AGENTE_BUY, 
    SELECCIONAR_ESCROW_SALE, 
    SELECCIONAR_ESCROW_AGENTE_SALE, 

    SELECCIONAR_BROKER_BUYER_BUY, 
    SELECCIONAR_BROKER_AGENTE_BUYER_BUY, 
    SELECCIONAR_BROKER_SELLER_BUY, 
    SELECCIONAR_BROKER_AGENTE_SELLER_BUY, 
    SELECCIONAR_BROKER_BUYER_SALE, 
    SELECCIONAR_BROKER_AGENTE_BUYER_SALE, 
    SELECCIONAR_BROKER_SELLER_SALE, 
    SELECCIONAR_BROKER_AGENTE_SELLER_SALE, 

    SELECCIONAR_LENDER_BUY, 

    SELECCIONAR_TITLE_BUY, 
    SELECCIONAR_TITLE_AGENTE_BUY, 
    SELECCIONAR_TITLE_SALE, 
    SELECCIONAR_TITLE_AGENTE_SALE, 

    SELECCIONAR_TERMITE_BUY, 
    SELECCIONAR_TERMITE_AGENTE_BUY, 
    SELECCIONAR_TERMITE_SALE, 
    SELECCIONAR_TERMITE_AGENTE_SALE, 

    SELECCIONAR_SEPTIC_BUY, 
    SELECCIONAR_SEPTIC_AGENTE_BUY, 
    SELECCIONAR_SEPTIC_SALE, 
    SELECCIONAR_SEPTIC_AGENTE_SALE, 

    AGREGAR_SECCION, 
    AGREGAR_TAREA, 
    EDITAR_SECCION, 
    EDITAR_TAREA, 
    ELIMINAR_SECCION, 
    ELIMINAR_TAREA, 
    OBTENER_CALENDARIO, 

    RENEGOCIAR_OFFER, 
    RENEGOCIAR_IN_ESCROW, 
    SELECCIONAR_SECCION_MASTERLIST, 
    CHECKLIST_PROYECTO, 
    GUARDAR_CAMBIOS_FLIPPING, 
    DESPLEGAR_GUARDADO_FLIPPING, 
    PAGINA_REDIRECCIONAR_FLIPPING, 
    REDIRECCIONAR_FLIPPING, 
    ID_REDIRECCIONAR_FLIPPING, 
    BUYSALE_PAGE, 
    SELECCIONAR_PROYECTO_DASHBOARD
} from '../actions/types';

const initialState = {
    ofertas: [],
    proyectos: [],
    externo_eliminar: false,
    listado: [],
    sections: [],
    detalles: null,
    checklist: null,
    masterlist: null,
    masterlist_section: '',
    inspections: [],
    detalles_inspection: null,
    repairs: [],
    detalles_repair: null,
    counters_listing: [],
    detalles_couter_listing: null,
    notes: [],
    detalles_note: null,
    files: [],
    folders: [],
    rutas_folders: [],
    tokens: [],
    detalles_file: null,
    detalles_folder: null,
    link_files: null,
    link_notes: null,
    link_reporte: null,
    filter_project: null,
    filter_property: null,
    filter_status: [],
    filter_active: true, 
    guardar_cambios: false,
    modal_guardado_flipping: false,
    proyecto_coincidencia: null,
    edicion_proyecto: false,
    buy_sale_page: false,
    paginaRedireccion: null,
    idRedireccion: null,
    redireccion: null,
    externo: false,
    externo_inspeccion: false,
    externo_archivo_proyecto: false,
    externo_nota: false,
    campo_nota: false,
    id_proyecto_dashboard: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        
        /* Proyectos */
        case VALIDAR_PROYECTO:
            return {
                ...state,
                proyecto_coincidencia: payload
            };
        case AGREGAR_OFERTA:
            return {
                ...state,
                ofertas: [
                    ...state.ofertas, payload
                ],
                proyecto_coincidencia: null
            };
        case ACTUALIZAR_DETALLES:
            state.proyectos.map( proyecto => proyecto._id === payload.id_proyecto ? payload : proyecto);
            return state;
        case DETALLES_PROYECTO:
            return {
                ...state,
                detalles: payload
            };
        case EDITANDO_PROYECTO:
            payload.proyecto.editing = true;
            return {
                ...state,
                proyectos:  state.proyectos.map(
                    proyecto => proyecto._id === payload.id ? (payload.proyecto) : proyecto
                )
            };
        case EXTERNO_ELIMINAR: 
            return {
                ...state,
                externo_eliminar: payload
            };
        case ELIMINAR_PROYECTO:
            return {
                ...state,
                proyectos: state.proyectos.filter( proyecto => proyecto._id !== payload.id ),
                ofertas: state.ofertas.filter( oferta => oferta._id !== payload.id )
            };
        case BUSCAR_OFERTAS:
            return {
                ...state,
                ofertas: payload.ofertas
            };
        case FILTER_OFERTAS:
            return {
                ...state,
                filter_project: payload.filter_project,
                filter_property: payload.filter_property,
                filter_status: [], 
                filter_active: payload.filter_active
            };
        case FILTRAR_OFERTAS:
            return {
                ...state,
                ofertas: payload
            };
        case LISTAR_PROYECTOS:
            return {
                ...state,
                listado: payload.proyectos
            };
        case BUSCAR_PROYECTOS:
            return {
                ...state,
                proyectos: payload.proyectos
            };
        case FILTER_PROYECTOS:
            return {
                ...state,
                filter_project: payload.filter_project,
                filter_property: payload.filter_property,
                filter_status: payload.filter_status, 
                filter_active: true
            };
        case FILTRAR_PROYECTOS:
            return {
                ...state,
                proyectos: payload
            };
        case HABILITAR_EDICION_PROYECTO:
            return {
                ...state,
                edicion_proyecto: !state.edicion_proyecto
            };
        case MATERLIST_PROYECTO:
            return {
                ...state,
                masterlist: payload.masterlist,
                users: payload.users,
                guardar_cambios: false,
                modal_guardado_flipping: false
            };
        
        /* Archivos */
        case EXTERNO_ARCHIVO_PROYECTO: 
            return {
                ...state,
                externo_archivo_proyecto: payload
            };
        case EXTERNO_ARCHIVO: 
            return {
                ...state,
                externo: payload
            };
        case ARCHIVOS_PROYECTO:
            return {
                ...state,
                files: payload.files,
                folders: payload.folders,
                rutas_folders: payload.rutas_folders
            };
        case PUBLIC_TOKENS_ARCHIVOS_PROYECTO:
            return {
                ...state,
                tokens: payload.tokens
            };
        case EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO:
            return {
                ...state,
                tokens: state.tokens.map(
                    token => String(token._id) === String(payload._id) ? payload : token
                )
            };
        case DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO:
            return {
                ...state,
                tokens: state.tokens.filter( token => token._id !== payload )
            };
        case AGREGAR_ARCHIVO_PROYECTO:
            return {
                ...state,
                files: [
                    ...state.files, payload.file
                ]
            };
        case AGREGAR_CARPETA_PROYECTO:
            return {
                ...state,
                folders: [
                    ...state.folders, payload.folder
                ]
            };
        case DETALLES_ARCHIVO_PROYECTO:
            return {
                ...state,
                detalles_file: payload
            };
        case DETALLES_CARPETA_PROYECTO:
            return {
                ...state,
                detalles_folder: payload
            };
        case EDITAR_ARCHIVO_PROYECTO:
            return {
                ...state,
                files: state.files.map(
                    file => String(file._id) === String(payload.file._id) ? payload.file : file
                )
            };
        case EDITAR_CARPETA_PROYECTO:
            return {
                ...state,
                folders: state.folders.map(
                    folder => String(folder._id) === String(payload.folder._id) ? payload.folder : folder
                )
            };
        case ELIMINAR_ARCHIVO_PROYECTO:
            return {
                ...state,
                files: state.files.filter( archivo => archivo._id !== payload )
            };
        case ELIMINAR_CARPETA_PROYECTO:
            return {
                ...state,
                folders: state.folders.filter( carpeta => carpeta._id !== payload )
            };
        case COMPARTIR_LINK_ARCHIVOS:
            return {
                ...state,
                link_files: payload
            };
        
        /* Notas */
        case COMPARTIR_LINK_NOTAS:
            return {
                ...state,
                link_notes: payload
            };
        case COMPARTIR_LINK_REPORTE:
            return {
                ...state,
                link_reporte: payload
            };
        case EXTERNO_NOTA: 
            return {
                ...state,
                externo_nota: payload.valor,
                campo_nota: payload.campo
            };
        case NOTAS_PROYECTO:
            return {
                ...state,
                notes: payload
            };
        case AGREGAR_NOTA_PROYECTO:
            return {
                ...state,
                notes: [
                    ...state.notes, payload
                ]
            };
        case EDITAR_NOTA_PROYECTO:
            return {
                ...state,
                notes: state.notes.map(
                    note => String(note._id) === String(payload._id) ? payload : note
                )
            };
        case DETALLES_NOTA_PROYECTO:
            return {
                ...state,
                detalles_note: payload
            };
        case ELIMINAR_NOTA_PROYECTO:
            return {
                ...state,
                notes: state.notes.filter( note => String(note._id) !== String(payload) )
            };
        
        /* Inspecciones */
        case INSPECCIONES_PROYECTO:
            return {
                ...state,
                inspections: payload.inspections
            };
        case AGREGAR_INSPECCION_PROYECTO:
            return {
                ...state,
                inspections: [
                    ...state.inspections, payload.inspection
                ]
            };
        case EDITAR_INSPECCION_PROYECTO:
            return {
                ...state,
                inspections: state.inspections.map(
                    inspection => String(inspection._id) === String(payload.inspection._id) ? payload.inspection : inspection
                )
            };
        case DETALLES_INSPECCION_PROYECTO:
            return {
                ...state,
                detalles_inspection: payload
            };
        case ELIMINAR_INSPECCION_PROYECTO:
            return {
                ...state,
                inspections: state.inspections.filter( inspection => String(inspection._id) !== String(payload) )
            };
        case EXTERNO_INSPECCION: 
            return {
                ...state,
                externo_inspeccion: payload
            };
        
        /* Reparaciones */
        case REPARACIONES_PROYECTO:
            return {
                ...state,
                repairs: payload.repairs
            };
        case AGREGAR_REPARACION_PROYECTO:
            return {
                ...state,
                repairs: [
                    ...state.repairs, payload.repair
                ]
            };
        case EDITAR_REPARACION_PROYECTO:
            return {
                ...state,
                repairs: state.repairs.map(
                    repair => String(repair._id) === String(payload.repair._id) ? payload.repair : repair
                )
            };
        case DETALLES_REPARACION_PROYECTO:
            return {
                ...state,
                detalles_repair: payload
            };
        case ELIMINAR_REPARACION_PROYECTO:
            return {
                ...state,
                repairs: state.repairs.filter( repair => String(repair._id) !== String(payload) )
            };
        
        /* Listing counters */
        case AGREGAR_COUNTER_LISTING_PROYECTO:
            state.masterlist.listing_price_counters = [
                ...state.masterlist.listing_price_counters, payload.counter
            ];

            return state;
        case EDITAR_COUNTER_LISTING_PROYECTO:
            return {
                ...state,
                counters_listing: state.counters_listing.map(
                    counter => String(counter._id) === String(payload.counter._id) ? payload.counter : counter
                )
            };
        case DETALLES_COUNTER_LISTING_PROYECTO:
            return {
                ...state,
                detalles_couter_listing: payload
            };
        case ELIMINAR_COUNTER_LISTING_PROYECTO:
            return {
                ...state,
                counters_listing: state.counters_listing.filter( counter => String(counter._id) !== String(payload) )
            };

        /* Escrow */
        case SELECCIONAR_ESCROW_BUY:
            state.masterlist.id_escrow_buy = payload._id;
            return state;
        case SELECCIONAR_ESCROW_AGENTE_BUY:
            state.masterlist.id_escrow_buy = payload.id_escrow;
            state.masterlist.id_escrow_agent_buy = payload._id;
            return state;
        case SELECCIONAR_ESCROW_SALE:
            state.masterlist.id_escrow_sale = payload._id;
            return state;
        case SELECCIONAR_ESCROW_AGENTE_SALE:
            state.masterlist.id_escrow_sale = payload.id_escrow;
            state.masterlist.id_escrow_agent_sale = payload._id;
            return state;
        
        /* Broker */
        case SELECCIONAR_BROKER_BUYER_BUY:
            state.masterlist.id_broker_buyer_buy = payload._id;
            return state;
        case SELECCIONAR_BROKER_AGENTE_BUYER_BUY:
            state.masterlist.id_broker_buyer_buy = payload.id_broker;
            state.masterlist.id_broker_agent_buyer_buy = payload._id;
            return state;
        case SELECCIONAR_BROKER_SELLER_BUY:
            state.masterlist.id_broker_seller_buy = payload._id;
            return state;
        case SELECCIONAR_BROKER_AGENTE_SELLER_BUY:
            state.masterlist.id_broker_seller_buy = payload.id_broker;
            state.masterlist.id_broker_agent_seller_buy = payload._id;
            return state;
        case SELECCIONAR_BROKER_BUYER_SALE:
            state.masterlist.id_broker_buyer_sale = payload._id;
            return state;
        case SELECCIONAR_BROKER_AGENTE_BUYER_SALE:
            state.masterlist.id_broker_buyer_sale = payload.id_broker;
            state.masterlist.id_broker_agent_buyer_sale = payload._id;
            return state;
        case SELECCIONAR_BROKER_SELLER_SALE:
            state.masterlist.id_broker_seller_sale = payload._id;
            return state;
        case SELECCIONAR_BROKER_AGENTE_SELLER_SALE:
            state.masterlist.id_broker_seller_sale = payload.id_broker;
            state.masterlist.id_broker_agent_seller_sale = payload._id;
            return state;
        
        /* Lender */
        case SELECCIONAR_LENDER_BUY:
            state.masterlist.id_lender_buy = payload._id;
            state.masterlist.name_lender_buy = payload.compania;
            return state;
        
        /* Title */
        case SELECCIONAR_TITLE_BUY:
            state.masterlist.id_title_buy = payload._id;
            return state;
        case SELECCIONAR_TITLE_AGENTE_BUY:
            state.masterlist.id_title_buy = payload.id_title;
            state.masterlist.id_title_agent_buy = payload._id;
            return state;
        case SELECCIONAR_TITLE_SALE:
            state.masterlist.id_title_sale = payload._id;
            return state;
        case SELECCIONAR_TITLE_AGENTE_SALE:
            state.masterlist.id_title_sale = payload.id_title;
            state.masterlist.id_title_agent_sale = payload._id;
            return state;
        
        /* Termite */
        case SELECCIONAR_TERMITE_BUY:
            state.masterlist.id_termite_buy = payload._id;
            return state;
        case SELECCIONAR_TERMITE_AGENTE_BUY:
            state.masterlist.id_termite_buy = payload.id_termite;
            state.masterlist.id_termite_agent_buy = payload._id;
            return state;
        case SELECCIONAR_TERMITE_SALE:
            state.masterlist.id_termite_sale = payload._id;
            state.masterlist.name_termite_sale = payload.compania;
            return state;
        case SELECCIONAR_TERMITE_AGENTE_SALE:
            state.masterlist.id_termite_sale = payload.id_termite;
            state.masterlist.id_termite_agent_sale = payload._id;
            return state;
        
        /* Septic */
        case SELECCIONAR_SEPTIC_BUY:
            state.masterlist.id_septic_buy = payload._id;
            return state;
        case SELECCIONAR_SEPTIC_AGENTE_BUY:
            state.masterlist.id_septic_buy = payload.id_septic;
            state.masterlist.id_septic_agent_buy = payload._id;
            return state;            
        case SELECCIONAR_SEPTIC_SALE:
            state.masterlist.id_septic_sale = payload._id;
            state.masterlist.name_septic_sale = payload.compania;
            return state;
        case SELECCIONAR_SEPTIC_AGENTE_SALE:
            state.masterlist.id_septic_sale = payload.id_septic;
            state.masterlist.id_septic_agent_sale = payload._id;
            return state;
            
        /* To do list */
        case AGREGAR_SECCION:
            const { section } = payload;
            return {
                ...state,
                sections: [
                    ...state.sections, section
                ]
            };
        case AGREGAR_TAREA:
            const { sectionT } = payload;
            return {
                ...state,
                sections: state.sections.map(
                    section => section._id === sectionT._id ? sectionT : section
                )
            };
        case EDITAR_SECCION:
            return {
                ...state,
                sections: state.sections.map(
                    section => section._id === payload._id ? payload : section
                )
            };
        case EDITAR_TAREA:
            return {
                ...state,
                sections: state.sections.map(
                    section => section._id === payload._id ? payload : section
                )
            };
        case ELIMINAR_SECCION:
            return {
                ...state,
                sections: state.sections.filter( section => section._id !== payload.id )
            };
        case ELIMINAR_TAREA:
            const newSection = state.sections.find(section => section.id === payload.id_seccion);
            newSection.tareas = newSection.tareas.filter(tarea => tarea._id !== payload.id);

            return {
                ...state,
                sections: state.sections.map( 
                    section => section._id === payload.id_seccion ? newSection : section 
                )
            };
        case OBTENER_CALENDARIO:
            return {
                ...state, 
                sections: payload.sections
            };

        /* Otras */
        case RENEGOCIAR_OFFER:
            state.masterlist.purchase_price = payload.purchase_price !== null && payload.purchase_price !== 'null' ? payload.purchase_price : state.masterlist.purchase_price;
            state.masterlist.purchase_price_history = [
                ...state.masterlist.purchase_price_history, payload.renegotiation
            ];
            return state; 
        case RENEGOCIAR_IN_ESCROW:
            state.masterlist.purchase_price = payload.purchase_price !== null && payload.purchase_price !== 'null' ? payload.purchase_price : state.masterlist.purchase_price;
            state.masterlist.due_dilligence_end = payload.due_dilligence_end !== null && payload.due_dilligence_end !== 'null' ? payload.due_dilligence_end : state.masterlist.due_dilligence_end;
            state.masterlist.escrow_deposit_amount = payload.escrow_deposit_amount !== null && payload.escrow_deposit_amount !== 'null' ? payload.escrow_deposit_amount : state.masterlist.escrow_deposit_amount;
            return state;
        case SELECCIONAR_SECCION_MASTERLIST:
            return {
                ...state,
                masterlist_section: payload.seccion
            };
        case CHECKLIST_PROYECTO:
            return {
                ...state,
                checklist: payload.checklist
            };
        case GUARDAR_CAMBIOS_FLIPPING:
            return {
                ...state,
                guardar_cambios: payload
            };
        case DESPLEGAR_GUARDADO_FLIPPING:
            return {
                ...state,
                modal_guardado_flipping: payload
            };
        case PAGINA_REDIRECCIONAR_FLIPPING: 
            return {
                ...state,
                paginaRedireccion: payload 
            };
        case REDIRECCIONAR_FLIPPING: 
            return {
                ...state,
                redireccion: payload
            };
        case ID_REDIRECCIONAR_FLIPPING: 
            return {
                ...state,
                idRedireccion: payload
            };
        case BUYSALE_PAGE: 
            return {
                ...state,
                buy_sale_page: payload
            };
        case SELECCIONAR_PROYECTO_DASHBOARD: 
            return {
                ...state,
                id_proyecto_dashboard: payload
            };

        default:
            return state;
    }
}