import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

//Actions
import { setAlert } from '../../actions/alert';
import { crearNominaIndividual, externaNomina, horasEmpleado } from '../../actions/nominas';

const ModalPayroll = () => {

    const dispatch = useDispatch();

    const nominas = useSelector(state => state.nominas);
    const proyectos = useSelector(state => state.proyectos);
    const empleados = useSelector(state => state.empleados);

    const [dataTabla, setDataTabla] = useState({
        proyectosTabla: null
    });

    const { proyectosTabla } = dataTabla;

    const [empleadosFilter, setEmpleadosFilter] = useState([]);
    const [empleadoData, setEmpleadoData] = useState(null);

    //Nuevo
    const [horasRestantes, setHorasRestantes] = useState(0);
    const [datosNuevo, setDatosNuevo] = useState({
        id_empleado: null,
        fecha: null,
        horas_extras: false,
        id_proyecto: [],
        horas: []
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const handleCloseNuevaNomina = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_empleado: null,
            fecha: null,
            horas_extras: false,
            id_proyecto: [],
            horas: []
        });
        setHorasRestantes(0);
        setEmpleadoData(null);
        dispatch(horasEmpleado(null));
        dispatch(externaNomina(false));
    };

    const clearModalNuevaNomina = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_empleado: null,
            fecha: null,
            horas_extras: false,
            id_proyecto: [],
            horas: []
        });
        setHorasRestantes(0);
        setEmpleadoData(null);
        dispatch(horasEmpleado(null));
    };

    const onNumberChangeNuevo = (name, value) => {

        let nombre_separado = name.split('-');
        let horas_proyectos = datosNuevo.horas;
        let horas_restantes = horasRestantes;

        if(datosNuevo.horas_extras === false){
            if(!isNaN(horas_proyectos[nombre_separado[1]]) && horas_proyectos[nombre_separado[1]] !== null){
                horas_restantes += parseInt(horas_proyectos[nombre_separado[1]]);
            }

            if(isNaN(value)){                
                horas_proyectos[nombre_separado[1]] = value;

                setDatosNuevo({
                    ...datosNuevo,
                    horas: horas_proyectos
                });

                setHorasRestantes(horas_restantes);
            }else{
                if(value > horas_restantes){
                    value = horas_restantes;
                }

                horas_proyectos[nombre_separado[1]] = value;

                setDatosNuevo({
                    ...datosNuevo,
                    horas: horas_proyectos
                });

                setHorasRestantes(horas_restantes - value);
            }
        }else{
            horas_proyectos[nombre_separado[1]] = value;

            setDatosNuevo({
                ...datosNuevo,
                horas: horas_proyectos
            });

            setHorasRestantes(0);
        }
    };

    const handleSelect = (option) => {

        if(option !== null && datosNuevo.fecha !== null && datosNuevo.fecha !== 'null'){
            dispatch(horasEmpleado({
                ...datosNuevo,
                id_empleado: option.value
            }));
        }else{
            dispatch(horasEmpleado(null));
        }

        setEmpleadoData(option);
        setDatosNuevo({
            ...datosNuevo,
            id_empleado: option !== null ? option.value : null
        });
    };

    const handleDate = (fecha) => {
        if(fecha !== null && fecha !== 'null' && datosNuevo.id_empleado !== null && datosNuevo.id_empleado !== 'Choose a employee'){
            dispatch(horasEmpleado({
                ...datosNuevo,
                fecha
            }));
        }else{
            dispatch(horasEmpleado(null));
        }

        setDatosNuevo({
            ...datosNuevo,
            fecha
        });
    };

    const handleCheckbox = ({ target }) => {
        if(target.checked){
            setDatosNuevo({
                ...datosNuevo,
                horas_extras: true
            });
        }else{
            setDatosNuevo({
                ...datosNuevo,
                horas_extras: false
            });
        }
    };

    const onProjectChangeNuevo = ({ target }) => {
        let id_separado = target.id.split('-');

        let ids_proyectos = datosNuevo.id_proyecto;
        ids_proyectos[id_separado[1]] = target.value;

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: ids_proyectos
        });
    };

    const handleAddProject = async ({ currentTarget }) => {

        if(datosNuevo.id_empleado !== null && datosNuevo.id_empleado !== 'Choose a employee' && datosNuevo.fecha !== null && datosNuevo.fecha !== 'null'){
            let ids_proyectos = datosNuevo.id_proyecto;
            let horas_proyectos = datosNuevo.horas;

            ids_proyectos.push(null);
            horas_proyectos.push(null);

            setDatosNuevo({ 
                ...datosNuevo, 
                id_proyecto: ids_proyectos,
                horas: horas_proyectos
            });
        }else{
            dispatch(setAlert('First select an employee and date', 'danger'));
        }
    };

    const handleDeleteProject = async ({ currentTarget }) => {

        let id_separado = currentTarget.id.split('-');

        let ids_proyectos = datosNuevo.id_proyecto;
        let horas_proyectos = datosNuevo.horas;

        if(!isNaN(horas_proyectos[id_separado[1]]) && horas_proyectos[id_separado[1]] !== null){
            setHorasRestantes(parseInt(horasRestantes) + parseInt(horas_proyectos[id_separado[1]]));
        }

        ids_proyectos.splice(id_separado[1], 1);
        horas_proyectos.splice(id_separado[1], 1);

        setDatosNuevo({ 
            ...datosNuevo, 
            id_proyecto: ids_proyectos,
            horas: horas_proyectos
        });
    };

    const projectsRows = (ids_proyectos) => {
        let secciones = [];

        for (var i = 0; i < ids_proyectos.length; i++) {
            secciones.push(
                <div key={'fila_proyecto_' + i} className="col-md-6 form-group">
                    <label className="font-weight-bold">Project {i + 1}:</label>
                    <div className="input-group">
                        <div className="d-flex">
                            <CurrencyInput
                                name={'horas-' + i}
                                className="form-control text-center w-25"
                                value={datosNuevo.horas[i]}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevo(name, value)}
                            />
                        
                            <select className="browser-default custom-select" value={datosNuevo.id_proyecto[i] || null} id={'id_proyecto-' + i} name="id_proyecto" onChange={(e) => onProjectChangeNuevo(e)}>
                                <option value={null}>General</option>
                                {
                                    proyectosTabla?.length > 0 ?
                                        proyectosTabla?.map(proyecto => {

                                            return(
                                                <option key={proyecto._id} value={ proyecto._id }>
                                                    { proyecto.direccion }
                                                </option>
                                            )
                                        })
                                    : ''
                                } 
                            </select>

                            <button type="button" className="btn ml-2 btn-small btn-danger py-1 px-2" id={'id_proyecto' + '-' + i} onClick={e => handleDeleteProject(e)}>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return secciones;
    };

    const nuevaNomina = async (method) => {

        let validHours = true;

        for(var i = 0; i <= (datosNuevo.horas.length - 1); i++){
            if(datosNuevo.horas[i] === null){
                validHours = false;
            }
        }

        if(datosNuevo.id_empleado !== null){
            if(datosNuevo.fecha !== null){
                if(datosNuevo.id_proyecto.length > 0){
                    if(validHours === true){
                        if(datosNuevo.id_proyecto.length === datosNuevo.horas.length){
                            await setBotonNuevo(true);

                            await dispatch(crearNominaIndividual(datosNuevo));

                            await clearModalNuevaNomina();

                            if(method === 'close'){
                                dispatch(externaNomina(false));
                            }
                        }else{
                            dispatch(setAlert('Fill every project informtion', 'danger'));
                        }
                    }else{
                        dispatch(setAlert('Fill hour inputs', 'danger'));
                    }
                }else{
                    dispatch(setAlert('Select at least one project', 'danger'));
                }
            }else{
                dispatch(setAlert('First select a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First select an employee', 'danger'));
        }

        await setBotonNuevo(false);
    };

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosTabla: proyectos.listado
        });
    }, [proyectos]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < empleados.empleados?.length; i++) {
            options.push({ value: empleados.empleados[i]._id, label: empleados.empleados[i].nombre });
        }

        setEmpleadosFilter(options);
    }, [empleados]);

    useEffect(() => {
        setHorasRestantes(nominas.detalle_horas);
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: [],
            horas: []
        });
    }, [nominas]);

    return (
        <Modal show={nominas.externo} size="lg" onHide={() => {
            handleCloseNuevaNomina();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>New payroll ({datosNuevo.horas_extras === false ? (horasRestantes === 1 ? horasRestantes + ' hour left' : horasRestantes + ' hours left') : '∞ hours left'})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Employee:</label>
                        <Select name="id_empleado" isClearable={true} options={empleadosFilter} value={empleadoData} onChange={(value) => handleSelect(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Date:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker name="fecha" maxDate={new Date()} className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ datosNuevo.fecha !== null ? new Date(datosNuevo.fecha) : '' } onChange={ date => handleDate(date) } />
                        </div>
                    </div>

                    <div className="col-md-2 form-group mb-5">
                        <label className="font-weight-bold">Extra hours:</label>
                        <div className="contenedor_datepicker">
                            <label className="container">
                                <input type="checkbox" name="horas_extras" checked={datosNuevo.horas_extras} onChange={ (e) => handleCheckbox(e) }/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>

                    {
                        projectsRows(datosNuevo.id_proyecto)
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-info mr-3" onClick={e => handleAddProject(e)} disabled={horasRestantes === 0 ? (datosNuevo.horas_extras === true ? botonNuevo : true) : botonNuevo}><i className="fas fa-hammer fa-sm"></i> {datosNuevo.id_proyecto.length > 0 ? 'Other project' : 'Project'}</button>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevaNomina()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-primary" onClick={() => {nuevaNomina('new')}} disabled={botonNuevo}><i className="fas fa-plus fa-sm"></i> Save and New</button>
                <button className="btn ml-2 btn-success" onClick={() => {nuevaNomina('close')}}><i className="fas fa-check fa-sm"></i> Save and Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPayroll;