import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//Actions
import { buscarEmpleados, eliminarEmpleado, editandoEmpleado } from '../../actions/empleados';

const Empleados = ({ history }) => {

    const dispatch = useDispatch();
    const { empleados } = useSelector(state => state.empleados);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const deleteEmpleado = (id) => {
        dispatch(eliminarEmpleado(id));
    }

    const editarEmpleado = (id) => {
        dispatch(editandoEmpleado(id));
        history.push('/employees/edit');
    }

    useEffect(() => {
        dispatch(buscarEmpleados());
    }, []);

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const tableRow = (empleados) => {
        if(empleados){
            if(empleados.length > 0){

                return (
                    <tbody>
                        {empleados.map(empleado => (
                            <Fragment key={empleado._id}>
                                <tr>
                                    <td>{empleado.nombre}</td>
                                    <td>{empleado.apellido}</td>
                                    <td>{numberToDecimal(empleado.salario)}</td>
                                    <td>
                                        {
                                            !permisos_configuration?.edit_employee ?
                                            null
                                            :
                                            <button className="btn btn-link text-primary" onClick={() => editarEmpleado(empleado._id)}>Edit</button>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !permisos_configuration?.delete_employee ?
                                            null
                                            :
                                            <button /*id={}*/ className="btn btn-link text-danger" onClick={() => deleteEmpleado(empleado._id)}>Delete</button>
                                        }
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                        
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">No employees registered.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">No employees registered.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    return ( 
        <div className="row">
            <div className="col-md-10 offset-md-1" >
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Employees</h3>
                        <p className="text-muted">{empleados ? empleados.length : null} registered employees</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            !permisos_configuration?.add_employee ?
                            null
                            :
                            <Link to="/employees/new" className="btn btn-success text-right"><i className="fas fa-plus fa-sm"></i> Add employee</Link>
                        }

                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Salary per day</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {tableRow(empleados)}
                    </table>
                </div>
            </div>
        </div>
     );
}
 
export default Empleados;
