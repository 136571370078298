import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../actions/alert';
import { detallesBroker, buscarAgents, agregarAgent, editarAgent, eliminarAgent } from '../../actions/brokers';

const AgentesBroker = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const brokers = useSelector(state => state.brokers);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const [brokerData, setBrokerData] = useState({
        id: '',
        company: ''
    });

    const [newAgentData, setNewAgentData] = useState({
        id_broker: null,
        officer_name: null,
        email: null,
        cell_phone: null,
        phone: null,
        license: null,
        public: null
    });

    const [editAgentData, setEditAgentData] = useState({
        id: null,
        id_broker: null,
        officer_name: null,
        email: null,
        cell_phone: null,
        phone: null,
        license: null,
        public: null
    });

    const [deleteAgentData, setDeleteAgentData] = useState({
        id: null,
        id_broker: null,
        officer_name: null
    });

    const [newAgentModal, setNewAgentModal] = useState(false);
    const [editAgentModal, setEditAgentModal] = useState(false);
    const [deleteAgentModal, setDeleteAgentModal] = useState(false);

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    //Nuevo agente
    const handleAgregarAgente = () => {
        setNewAgentModal(true);
    }

    const handleInputsAgregar = ({ target }) => {
        setNewAgentData({
            ...newAgentData,
            [target.name]: target.value
        });
    }

    const handleCloseAgregarAgente = () => {
        setNewAgentData({
            ...newAgentData,
            officer_name: null,
            email: null,
            cell_phone: null,
            phone: null,
            license: null,
            public: null
        });
        setNewAgentModal(false);
    }

    const handleSubmitAgregar = async (e) => {
        e.preventDefault();
        try {
            if(newAgentData.officer_name !== '' && newAgentData.officer_name !== null){
                if(newAgentData.license !== null && newAgentData.license !== ''){
                    await dispatch(agregarAgent(newAgentData));

                    setNewAgentModal(false);

                    setNewAgentData({
                        ...newAgentData,
                        officer_name: null,
                        email: null,
                        cell_phone: null,
                        phone: null,
                        license: null,
                        public: null
                    });
                }else{
                    dispatch(setAlert('First enter a license number', 'danger'));
                }
            }else{
                dispatch(setAlert('Agent name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }   

    //Editar agente
    const handleEditarAgente = agente => {
        setEditAgentModal(true);

        setEditAgentData({
            ...editAgentData,
            id: agente._id,
            officer_name: agente.officer_name,
            email: agente.email,
            cell_phone: agente.cell_phone,
            phone: agente.phone,
            license: agente.license,
            public: agente.public
        });
    }

    const handleInputsEditar = ({ target }) => {
        setEditAgentData({
            ...editAgentData,
            [target.name]: target.value
        });
    }

    const handleCloseEditarAgente = () => {
        setEditAgentData({
            ...editAgentData,
            id: null,
            officer_name: null,
            email: null,
            cell_phone: null,
            phone: null,
            license: null,
            public: null
        });
        setEditAgentModal(false);
    }

    const handleSubmitEditar = async (e) => {
        e.preventDefault();
        try {
            if(editAgentData.officer_name !== '' && editAgentData.officer_name !== null){
                if(editAgentData.license !== '' && editAgentData.license !== null){
                    await dispatch(editarAgent(editAgentData));

                    setEditAgentModal(false);

                    setEditAgentData({
                        ...editAgentData,
                        id: null,
                        officer_name: null,
                        email: null,
                        cell_phone: null,
                        phone: null,
                        license: null,
                        public: null
                    });
                }else{
                    dispatch(setAlert('First enter a license number', 'danger'));
                }
            }else{
                dispatch(setAlert('Agent name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Eliminar
    const handleEliminarAgente = agente => {
        setDeleteAgentModal(true);

        setDeleteAgentData({
            ...deleteAgentData,
            id: agente._id,
            officer_name: agente.officer_name
        });
    }

    const handleCloseEliminarAgente = () => {
        setDeleteAgentData({
            ...deleteAgentData,
            id: null,
            officer_name: null
        });
        setDeleteAgentModal(false);
    }

    const handleSubmitEliminar = async (e) => {
        e.preventDefault();
        try {
            await dispatch(eliminarAgent(deleteAgentData.id_broker, deleteAgentData.id));

            setDeleteAgentModal(false);

            setDeleteAgentData({
                ...deleteAgentData,
                id: null,
                officer_name: null
            });
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(detallesBroker(id));
        dispatch(buscarAgents(id));
    }, []);

    useEffect(() => {
        setBrokerData({
            ...brokerData,
            id: brokers.detalles?._id,
            company: brokers.detalles?.compania
        });

        setNewAgentData({
            ...newAgentData,
            id_broker: brokers.detalles?._id
        });

        setEditAgentData({
            ...editAgentData,
            id_broker: brokers.detalles?._id
        });

        setDeleteAgentData({
            ...deleteAgentData,
            id_broker: brokers.detalles?._id
        });
    }, [brokers]);

    const tableRow = (items) => {
        
        return (
            <tbody>
                {
                    items.map((item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td> {item.officer_name || '-'} </td>
                                    <td> {item.license || '-'} </td>
                                    <td> {item.public || '-'} </td>
                                    <td> {item.email || '-'} </td>
                                    <td> {formatPhoneNumber(item.cell_phone) || '-'} </td>
                                    <td> {formatPhoneNumber(item.phone) || '-'} </td>
                                    <td>
                                        {
                                            !permisos_configuration?.edit_agent_broker ? 
                                            null 
                                            :
                                            <button className="btn btn-link text-primary" onClick={() => handleEditarAgente(item)}>Edit</button>
                                        }
                                    </td>
                                    <td>
                                        {
                                            !permisos_configuration?.delete_agent_broker ?
                                            null
                                            :
                                            <button id={'eliminar_' + item._id} className="btn btn-link text-danger" onClick={() => handleEliminarAgente(item)}>Delete</button>
                                        }
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    return (
        <Fragment>
            {/* Modal de nuevo agente */}
            <Modal backdrop="static" show={newAgentModal} size="lg" onHide={() => {
                setNewAgentModal(false);
                setNewAgentData({
                    ...newAgentData,
                    officer_name: null,
                    email: null,
                    cell_phone: null,
                    phone: null,
                    license: null,
                    public: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New agent ({brokerData.company})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input name="officer_name" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input name="email" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="cell_phone" className="form-control" country={'us'} value={newAgentData.cell_phone} onChange={phone => {setNewAgentData({...newAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="phone" className="form-control" country={'us'} value={newAgentData.phone} onChange={phone => {setNewAgentData({...newAgentData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input name="license" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newAgentData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input name="public" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newAgentData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarAgente()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitAgregar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar agente */}
            <Modal backdrop="static" show={editAgentModal} size="lg" onHide={() => {
                setEditAgentModal(false);
                setEditAgentData({
                    ...editAgentData,
                    id: null,
                    officer_name: null,
                    email: null,
                    cell_phone: null,
                    phone: null,
                    license: null,
                    public: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit agent ({brokerData.company})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input name="officer_name" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editAgentData.officer_name || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Email:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <input name="email" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editAgentData.email || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Cell phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="cell_phone" className="form-control" country={'us'} value={editAgentData.cell_phone} onChange={phone => {setEditAgentData({...editAgentData, cell_phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Phone:</label>
                            <div className="contenedor_datepicker">
                                <PhoneInput name="phone" className="form-control" country={'us'} value={editAgentData.phone} onChange={phone => {setEditAgentData({...editAgentData, phone: phone}); }} />
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">License number:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input name="license" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editAgentData.license || ''} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Public ID:</label>
                            <div className="contenedor_datepicker">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            #
                                        </span>
                                    </div>
                                    <input name="public" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editAgentData.public || ''} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarAgente()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEditar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminar agente */}
            <Modal backdrop="static" show={deleteAgentModal} onHide={() => {
                setDeleteAgentModal(false);
                setDeleteAgentData({
                    ...deleteAgentData,
                    id: null,
                    officer_name: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete agent ({brokerData.company})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Do you want to delete the {deleteAgentData.officer_name || ''} agent?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEliminarAgente()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEliminar}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Agents of broker ({brokerData.company})</h3>
                            <p className="text-muted"> {brokers.agents?.length} registered agents</p>
                        </div>
                        <div className="col-md-4 text-right">
                            {
                                !permisos_configuration?.add_agent_broker ?
                                null
                                :
                                <button type="button" className="btn btn-success text-right" onClick={() => handleAgregarAgente()}><i className="fas fa-plus fa-sm"></i> Add agent</button>
                            }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">License #</th>
                                    <th scope="col">Public ID</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Cell phone</th>
                                    <th scope="col">Phone</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            { tableRow(brokers.agents) }
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default AgentesBroker;