import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer/legacy';
import { Modal } from 'react-bootstrap';

const Idle = () => {

    const { isAuthenticated } = useSelector(state => state.auth);

    const [state, setState] = useState('Active');
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(300000);
    const [modalInactividad, setModalInactividad] = useState(false);

    let audio = new Audio('/alerta.mp3')

    const onIdle = () => {
        setState('Idle');
    }

    const onActive = () => {
        setState('Active');
    }

    const onAction = () => {
        setCount(count + 1);
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 300000,
        throttle: 500
    });

    const handleClose = () => {
        setModalInactividad(false);
        audio.pause();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);
    
        return () => {
            clearInterval(interval);
        }
    });

    useEffect(() => {
        if(remaining <= 0 && isAuthenticated){
            setModalInactividad(true);
            let resp = audio.play();

            if (resp!== undefined) {
                resp.then(_ => {
                    // autoplay starts!
                }).catch(error => {
                   //show error
                });
            }
        }
    }, [remaining]);

    return (
        <Modal show={modalInactividad} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Inactivity detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You still there?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger mr-3" onClick={() => handleClose()}><i className="fas fa-times fa-sm"></i> No</button>
                <button className="btn btn-success" onClick={() => handleClose()}><i className="fas fa-check fa-sm"></i> Yes</button>
            </Modal.Footer>
        </Modal>
    )
}

export default Idle;
