import { BUSCAR_TITLES, AGREGAR_TITLE, SELECCIONAR_TITLE_BUY, SELECCIONAR_TITLE_SALE, EDITAR_TITLE, DETALLES_TITLE, ELIMINAR_TITLE, BUSCAR_TITLES_AGENTES, AGREGAR_TITLE_AGENTE, AGREGAR_TITLE_AGENTE_DIRECTO, SELECCIONAR_TITLE_AGENTE_BUY, SELECCIONAR_TITLE_AGENTE_SALE, EDITAR_TITLE_AGENTE, ELIMINAR_TITLE_AGENTE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarTitles = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/titles`, config);
        dispatch({
            type: BUSCAR_TITLES,
            payload: res.data.titles
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTitle = (title) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(title);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TITLE,
            payload: res.data.title
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTitleMasterlistBuy = (title) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(title);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TITLE,
            payload: res.data.title
        });

        dispatch({
            type: SELECCIONAR_TITLE_BUY,
            payload: res.data.title
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTitleMasterlistSale = (title) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(title);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TITLE,
            payload: res.data.title
        });

        dispatch({
            type: SELECCIONAR_TITLE_SALE,
            payload: res.data.title
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarTitle = (title) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(title);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/editar`, body, config);
        dispatch({
            type: EDITAR_TITLE,
            payload: res.data.title
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesTitle = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/detalles`, body, config);

        dispatch({
            type: DETALLES_TITLE,
            payload: res.data.title
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTitle = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_TITLE,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarAgents = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/agentes`, body, config);
        dispatch({
            type: BUSCAR_TITLES_AGENTES,
            payload: res.data.agentes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/nuevo-agente`, body, config);
        dispatch({
            type: AGREGAR_TITLE_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTitleAgentMasterlist = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/nuevo-agente/`, body, config);

        dispatch({
            type: AGREGAR_TITLE,
            payload: {
                _id: res.data.agente.id_title,
                compania: 'Without name',
                direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_TITLE_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_TITLE_AGENTE_BUY,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_TITLE_AGENTE_SALE,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/editar-agente`, body, config);
        dispatch({
            type: EDITAR_TITLE_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAgent = (id_title, id_agente) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id_title, id_agente });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/titles/eliminar-agente`, body, config);
        dispatch({
            type: ELIMINAR_TITLE_AGENTE,
            payload:{
                id: id_agente
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}