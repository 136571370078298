import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Badge } from 'react-bootstrap';
import Select from 'react-select';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';

//Actions
import { obtenerDatos, obtenerEstadosTarjeta, obtenerBalancesTarjeta, obtenerPeriodoMovimientosTarjeta } from '../../actions/contabilidad';
import { setAlert } from '../../actions/alert';

const styles = {
    titulo: { color: '#2C2052' },
    infoText: {
        margin: 0,
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const RADIAN = Math.PI / 180;

const DashboardContabilidad = ({ history }) => {

    const dispatch = useDispatch();

    const { datos } = useSelector(state => state.contabilidad);

    const [datosDashboard, setDatosDashboard] = useState({
        tarjetaSeleccionda: null, 
        periodoMovimientosSeleccionado: { value: 1, label: 'Current month' }, 
        periodoComparativaSeleccionado: { value: 1, label: 'Current month' }, 
        estadosTarjeta: [],
        tarjetasBalances: [],
        informacionTarjeta: []
    });

    const [datosTarjeta, setDatosTarjeta] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        issuer: ''
    });

    const [creditCardsFilter, setCreditCardsFilter] = useState([]);
    const [periodMovementsFilter, setPeriodMovementsFilter] = useState([
        { value: 1, label: 'Current month' },
        { value: 2, label: 'Current Year' }
    ]);

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columnsStatements = [
        {
            name: 'Initial date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha_inicial !== null ? new Date(item.fecha_inicial).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Close date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha_final !== null ? new Date(item.fecha_final).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'New balance',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.deuda !== null ? numberToDecimal(item.deuda) : '-'}`,
        }, {
            name: 'Amount paid',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.total_pagado !== null ? numberToDecimal(item.total_pagado) : '-'}`,
        }, {
            name: 'Status paid',
            cell: item => {
                if(item.pagado === true){
                    return (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    if(item.pagado === false){
                        return (
                            <h6><Badge variant="danger">No</Badge></h6>
                        );
                    }else{
                        return (
                            <h6><Badge variant="secondary">Not defined</Badge></h6>
                        );
                    }
                }
            }
        }, 
    ];

    const columnsMovementsCards = [
        {
            name: 'Color',
            selector: 'color',
            sortable: false,
            width: '50px', 
            cell: item => {
                return (
                    <i className="fa fa-square" style={{color: item.color}} />
                );
            }
        }, {
            name: 'Card',
            selector: 'nombre',
            sortable: true,
            grow: 3, 
            cell: item => `${ item.nombre }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }
    ];

    //Funciones
    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const handleDetailsCard = () => {
        if(datosDashboard.tarjetaSeleccionda){
            history.push('/accounting/details_credit_card/' + datosDashboard.tarjetaSeleccionda.value);
        }else{
            dispatch(setAlert('First select a creit card', 'danger'));
        }
    }

    const onSelectTarjetaCredito = (option) => {
        setDatosDashboard({
            ...datosDashboard, 
            tarjetaSeleccionda: option
        });

        if(option){
            setDatosTarjeta({
                ...datosTarjeta, 
                number: String(option.datos.digitos).padStart(16, '*'),
                expiry: '',
                cvc: '',
                name: option.datos.nombre,
                issuer: option.datos.abreviacion
            });

            dispatch(obtenerEstadosTarjeta(option.value, datos));
        }else{
            setDatosTarjeta({
                number: '',
                expiry: '',
                cvc: '',
                name: '', 
                issuer: ''
            });
        }
    }

    const onSelectPeriodoMovimiento = (option) => {
        setDatosDashboard({
            ...datosDashboard, 
            periodoMovimientosSeleccionado: option
        });

        dispatch(obtenerBalancesTarjeta(option.value, datos));
    }

    const onSelectPeriodoComparativa = (option) => {
        setDatosDashboard({
            ...datosDashboard, 
            periodoComparativaSeleccionado: option
        });

        dispatch(obtenerPeriodoMovimientosTarjeta(option.value, datos));
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    useEffect(() => {
        dispatch(obtenerDatos());
    }, []);

    useEffect(() => {
        if(datos){
            let options_tarjetas = [];
            for (var i = 0; i < datos.tarjetas.length; i++) {
                options_tarjetas.push({ value: datos.tarjetas[i]._id, label: datos.tarjetas[i].nombre + ' (' + datos.tarjetas[i].banco + ' - ' + datos.tarjetas[i].tipo + ' - ' + datos.tarjetas[i].digitos + ')', datos: datos.tarjetas[i] });
            }
            setCreditCardsFilter(options_tarjetas);

            setDatosDashboard({
                ...datosDashboard, 
                estadosTarjeta: datos.estados_tarjeta,
                tarjetasBalances: datos.balances_tarjeta, 
                informacionTarjeta: datos.periodo_movimientos_tarjeta
            });
        }
    }, [datos]);

    return ( 
            <Fragment>
                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-12 text-left">
                            <h3>Accounting dashboard</h3>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3 mt-4 row">
                        <div className="col-md-6">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-4 row">
                                        <div className="col-5 text-start">
                                            <h5 style={styles.titulo}>Your wallet</h5>
                                        </div>
                                        <div className="col-7 text-end">
                                            <Select name="id_tarjeta" isClearable={true} options={creditCardsFilter} value={datosDashboard.tarjetaSeleccionda} onChange={(value) => onSelectTarjetaCredito(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center mt-3" onClick={() => handleDetailsCard()}>
                                            <Cards
                                                number={datosTarjeta.number}
                                                expiry={datosTarjeta.expiry}
                                                cvc={datosTarjeta.cvc}
                                                name={datosTarjeta.name}
                                                issuer={datosTarjeta.issuer}
                                                preview={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-title mt-5 mb-4">
                                        <h5 style={styles.titulo}>Last statements</h5>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <DataTable
                                                columns={columnsStatements}
                                                data={datosDashboard.estadosTarjeta || []}
                                                noHeader={true}
                                                pagination
                                                paginationPerPage={20}
                                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-4 row">
                                        <div className="col-4 text-start">
                                            <h5 style={styles.titulo}>Balance</h5>
                                        </div>
                                        <div className="col-8 text-end">
                                            <div className='campo_select_sm float-right ml-3'>
                                                <Select isClearable={false} options={periodMovementsFilter} value={datosDashboard.periodoMovimientosSeleccionado} onChange={(value) => onSelectPeriodoMovimiento(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 py-4">
                                        <div className="col-12 text-center row">
                                            <div className="col-6">
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <PieChart width={400} height={400}>
                                                        <Pie
                                                            data={datosDashboard.tarjetasBalances}
                                                            cx="50%"
                                                            cy="50%"
                                                            labelLine={false}
                                                            label={renderCustomizedLabel}
                                                            outerRadius={80}
                                                            fill="#8884d8"
                                                            dataKey="value"
                                                        >
                                                            {datosDashboard.tarjetasBalances.map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                            ))}
                                                        </Pie>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div className="col-6">
                                                <DataTable
                                                    columns={columnsMovementsCards}
                                                    data={datosDashboard.tarjetasBalances || []}
                                                    noHeader={true}
                                                    pagination
                                                    paginationPerPage={5}
                                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                                    paginationComponentOptions={paginationOptions}
                                                    highlightOnHover={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-title mt-5 mb-4 row">
                                        <div className="col-5 text-start">
                                            <h5 style={styles.titulo}>Statements vs. Payments</h5>
                                        </div>
                                        <div className="col-7 text-end">
                                            <div className='campo_select_sm float-right'>
                                                <Select isClearable={false} options={periodMovementsFilter} value={datosDashboard.periodoComparativaSeleccionado} onChange={(value) => onSelectPeriodoComparativa(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <LineChart width={700} height={300} data={datosDashboard.informacionTarjeta} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                                <Line type="monotone" dataKey="Statements" stroke="#a21111" />
                                                <Line type="monotone" dataKey="Payments" stroke="#0b5a0c" />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="Period" />
                                                <YAxis />
                                                <Tooltip />
                                            </LineChart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    );
}
 
export default DashboardContabilidad;
