import { BUSCAR_SEPTICS, AGREGAR_SEPTIC, SELECCIONAR_SEPTIC_BUY, SELECCIONAR_SEPTIC_SALE, EDITAR_SEPTIC, DETALLES_SEPTIC, ELIMINAR_SEPTIC, BUSCAR_SEPTICS_AGENTES, AGREGAR_SEPTIC_AGENTE, AGREGAR_SEPTIC_AGENTE_DIRECTO, SELECCIONAR_SEPTIC_AGENTE_BUY, SELECCIONAR_SEPTIC_AGENTE_SALE, EDITAR_SEPTIC_AGENTE, ELIMINAR_SEPTIC_AGENTE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarSeptics = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/septics`, config);
        dispatch({
            type: BUSCAR_SEPTICS,
            payload: res.data.septics
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSeptic = (septic) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(septic);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_SEPTIC,
            payload: res.data.septic
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSepticMasterlistBuy = (septic) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(septic);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_SEPTIC,
            payload: res.data.septic
        });

        dispatch({
            type: SELECCIONAR_SEPTIC_BUY,
            payload: res.data.septic
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSepticMasterlistSale = (septic) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(septic);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_SEPTIC,
            payload: res.data.septic
        });

        dispatch({
            type: SELECCIONAR_SEPTIC_SALE,
            payload: res.data.septic
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarSeptic = (septic) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(septic);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/editar`, body, config);
        dispatch({
            type: EDITAR_SEPTIC,
            payload: res.data.septic
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesSeptic = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/detalles`, body, config);

        dispatch({
            type: DETALLES_SEPTIC,
            payload: res.data.septic
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarSeptic = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_SEPTIC,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarAgents = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/agentes`, body, config);
        dispatch({
            type: BUSCAR_SEPTICS_AGENTES,
            payload: res.data.agentes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/nuevo-agente`, body, config);
        dispatch({
            type: AGREGAR_SEPTIC_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSepticAgentMasterlist = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/nuevo-agente/`, body, config);

        dispatch({
            type: AGREGAR_SEPTIC,
            payload: {
                _id: res.data.agente.id_septic,
                compania: 'Without name',direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_SEPTIC_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_SEPTIC_AGENTE_BUY,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_SEPTIC_AGENTE_SALE,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/editar-agente`, body, config);
        dispatch({
            type: EDITAR_SEPTIC_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAgent = (id_septic, id_agente) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id_septic, id_agente });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/septics/eliminar-agente`, body, config);
        dispatch({
            type: ELIMINAR_SEPTIC_AGENTE,
            payload:{
                id: id_agente
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}