// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_PUBLIC = 'LOGIN_PUBLIC';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const EDITAR_PERFIL = 'EDITAR_PERFIL';
export const RECUPERAR = 'RECUPERAR';
export const RESTABLECER = 'RESTABLECER';

// Dashboard
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const FILTER_PROYECTOS = 'FILTER_PROYECTOS';
export const FILTER_OFERTAS = 'FILTER_OFERTAS';

// Actividades
export const BUSCAR_ACTIVIDADES = 'BUSCAR_ACTIVIDADES';
export const AGREGAR_ACTIVIDAD = 'AGREGAR_ACTIVIDAD';
export const EDITAR_ACTIVIDAD = 'EDITAR_ACTIVIDAD';
export const DETALLES_ACTIVIDAD = 'DETALLES_ACTIVIDAD';
export const STATUS_ACTIVIDAD = 'STATUS_ACTIVIDAD';
export const ELIMINAR_ACTIVIDAD = 'ELIMINAR_ACTIVIDAD';
export const EXTERNA_ACTIVIDAD = 'EXTERNA_ACTIVIDAD';
export const OBTENER_REPORTE_ACTIVIDADES = 'OBTENER_REPORTE_ACTIVIDADES';

// Recordatorios
export const BUSCAR_RECORDATORIOS = 'BUSCAR_RECORDATORIOS';
export const AGREGAR_RECORDATORIO = 'AGREGAR_RECORDATORIO';
export const EDITAR_RECORDATORIO = 'EDITAR_RECORDATORIO';
export const DETALLES_RECORDATORIO = 'DETALLES_RECORDATORIO';
export const STATUS_RECORDATORIO = 'STATUS_RECORDATORIO';
export const ELIMINAR_RECORDATORIO = 'ELIMINAR_RECORDATORIO';
export const EXTERNA_RECORDATORIO = 'EXTERNA_RECORDATORIO';

// Usuarios
export const AGREGAR_USUARIO = 'AGREGAR_ESPECIALIDAD';
export const ELIMINAR_USUARIO = 'ELIMINAR_ESPECIALIDAD';
export const EDITANDO_USUARIO = 'EDITANDO_USUARIO';
export const BUSCAR_USUARIOS = 'BUSCAR_USUARIOS';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';

// Empleados
export const AGREGAR_EMPLEADO = 'AGREGAR_EMPLEADO';
export const ELIMINAR_EMPLEADO = 'ELIMINAR_EMPLEADO';
export const EDITANDO_EMPLEADO = 'EDITANDO_EMPLEADO';
export const BUSCAR_EMPLEADOS = 'BUSCAR_EMPLEADOS';
export const EDITAR_EMPLEADO = 'EDITAR_EMPLEADO';

// Deal analyzer
export const BUSCAR_ANALISIS = 'BUSCAR_ANALISIS';
export const AGREGAR_ANALISIS = 'AGREGAR_ANALISIS';
export const EDITAR_ANALISIS = 'EDITAR_ANALISIS';
export const DETALLES_ANALISIS = 'DETALLES_ANALISIS';
export const ELIMINAR_ANALISIS = 'ELIMINAR_ANALISIS';
export const BUSCAR_VALORES_ANALISIS = 'BUSCAR_VALORES_ANALISIS';

// Nominas
export const BUSCAR_NOMINAS = 'BUSCAR_NOMINAS';
export const BUSCAR_EMPLEADOS_DIA = 'BUSCAR_EMPLEADOS_DIA';
export const GENERAR_NOMINA = 'GENERAR_NOMINA';
export const EDITAR_NOMINA = 'EDITAR_NOMINA';
export const DETALLES_NOMINA = 'DETALLES_NOMINA';
export const FILTROS_NOMINA = 'FILTROS_NOMINA';
export const ELIMINAR_NOMINA = 'ELIMINAR_NOMINA';
export const EXTERNA_NOMINA = 'EXTERNA_NOMINA';
export const HORAS_RESTANTES_NOMINA = 'HORAS_RESTANTES_NOMINA';

// Gastos
export const BUSCAR_GASTOS = 'BUSCAR_GASTOS';
export const NUEVO_GASTO = 'NUEVO_GASTO';
export const EDITAR_GASTO = 'EDITAR_GASTO';
export const DETALLES_GASTO = 'DETALLES_GASTO';
export const ELIMINAR_GASTO = 'ELIMINAR_GASTO';
export const EXTERNO_GASTO = 'EXTERNO_GASTO';

// Contabilidad
export const OBTENER_DATOS_CONTABILIDAD = 'OBTENER_DATOS_CONTABILIDAD';
export const OBTENER_TARJETAS_CREDITO = 'OBTENER_TARJETAS_CREDITO';
export const DETALLES_TARJETA_CREDITO = 'DETALLES_TARJETA_CREDITO';
export const STATUS_TARJETA_CREDITO = 'STATUS_TARJETA_CREDITO';
export const ELIMINAR_TARJETA_CREDITO = 'ELIMINAR_TARJETA_CREDITO';
export const AGREGAR_ESTADO_TARJETA = 'AGREGAR_ESTADO_TARJETA';
export const EDITAR_ESTADO_TARJETA = 'EDITAR_ESTADO_TARJETA';
export const DETALLES_ESTADO_TARJETA = 'DETALLES_ESTADO_TARJETA';
export const DETALLES_ARCHIVO_ESTADO_TARJETA = 'DETALLES_ARCHIVO_ESTADO_TARJETA';
export const ELIMINAR_ESTADO_TARJETA = 'ELIMINAR_ESTADO_TARJETA';
export const AGREGAR_PAGO_TARJETA = 'AGREGAR_PAGO_TARJETA';
export const EDITAR_PAGO_TARJETA = 'EDITAR_PAGO_TARJETA';
export const DETALLES_PAGO_TARJETA = 'DETALLES_PAGO_TARJETA';
export const ELIMINAR_PAGO_TARJETA = 'ELIMINAR_PAGO_TARJETA';
export const AGREGAR_CREDITO_TARJETA = 'AGREGAR_CREDITO_TARJETA';
export const EDITAR_CREDITO_TARJETA = 'EDITAR_CREDITO_TARJETA';
export const DETALLES_CREDITO_TARJETA = 'DETALLES_CREDITO_TARJETA';
export const ELIMINAR_CREDITO_TARJETA = 'ELIMINAR_CREDITO_TARJETA';

// Deudas
export const OBTENER_DATOS_DEUDAS = 'OBTENER_DATOS_DEUDAS';
export const OBTENER_PROVEEDORES = 'OBTENER_PROVEEDORES';
export const DETALLES_PROVEEDOR = 'DETALLES_PROVEEDOR';
export const ELIMINAR_PROVEEDOR = 'ELIMINAR_PROVEEDOR';
export const AGREGAR_DEUDA_PROVEEDOR = 'AGREGAR_DEUDA_PROVEEDOR';
export const EDITAR_DEUDA_PROVEEDOR = 'EDITAR_DEUDA_PROVEEDOR';
export const DETALLES_DEUDA_PROVEEDOR = 'DETALLES_DEUDA_PROVEEDOR';
export const DETALLES_ARCHIVO_DEUDA_PROVEEDOR = 'DETALLES_ARCHIVO_DEUDA_PROVEEDOR';
export const ELIMINAR_DEUDA_PROVEEDOR = 'ELIMINAR_DEUDA_PROVEEDOR';
export const AGREGAR_PAGO_PROVEEDOR = 'AGREGAR_PAGO_PROVEEDOR';
export const EDITAR_PAGO_PROVEEDOR = 'EDITAR_PAGO_PROVEEDOR';
export const DETALLES_PAGO_PROVEEDOR = 'DETALLES_PAGO_PROVEEDOR';
export const DETALLES_ARCHIVO_PAGO_PROVEEDOR = 'DETALLES_ARCHIVO_PAGO_PROVEEDOR';
export const ELIMINAR_PAGO_PROVEEDOR = 'ELIMINAR_PAGO_PROVEEDOR';

//Bancos
export const OBTENER_BANCOS = 'OBTENER_BANCOS';
export const OBTENER_TIPOS_TARJETAS = 'OBTENER_TIPOS_TARJETAS';

//Inspecciones
export const INSPECCIONES_PROYECTO = 'INSPECCIONES_PROYECTO';
export const AGREGAR_INSPECCION_PROYECTO = 'AGREGAR_INSPECCION_PROYECTO';
export const EDITAR_INSPECCION_PROYECTO = 'EDITAR_INSPECCION_PROYECTO';
export const ELIMINAR_INSPECCION_PROYECTO = 'ELIMINAR_INSPECCION_PROYECTO';
export const DETALLES_INSPECCION_PROYECTO = 'DETALLES_INSPECCION_PROYECTO';
export const EXTERNO_INSPECCION = 'EXTERNO_INSPECCION';

//Reparaciones
export const REPARACIONES_PROYECTO = 'REPARACIONES_PROYECTO';
export const AGREGAR_REPARACION_PROYECTO = 'AGREGAR_REPARACION_PROYECTO';
export const EDITAR_REPARACION_PROYECTO = 'EDITAR_REPARACION_PROYECTO';
export const ELIMINAR_REPARACION_PROYECTO = 'ELIMINAR_REPARACION_PROYECTO';
export const DETALLES_REPARACION_PROYECTO = 'DETALLES_REPARACION_PROYECTO';

// To do list
export const ELEGIR_FILTROS_TODOLIST = 'ELEGIR_FILTROS_TODOLIST';
export const DETALLES_SECCION_TODOLIST = 'DETALLES_SECCION_TODOLIST';
export const BUSCAR_TAREAS_TODOLIST = 'BUSCAR_TAREAS_TODOLIST';
export const AGREGAR_TAREA_TODOLIST = 'AGREGAR_TAREA_TODOLIST';
export const EDITAR_TAREA_TODOLIST = 'EDITAR_TAREA_TODOLIST';
export const DETALLES_TAREA_TODOLIST = 'DETALLES_TAREA_TODOLIST';
export const NOTA_TAREA_TODOLIST = 'NOTA_TAREA_TODOLIST';
export const ARCHIVO_NOTA_TODOLIST = 'ARCHIVO_NOTA_TODOLIST';
export const ELIMINAR_TAREA_TODOLIST = 'ELIMINAR_TAREA_TODOLIST';
export const REORDENAR_TAREA_TODOLIST = 'REORDENAR_TAREA_TODOLIST';
export const EXTERNA_TAREA = 'EXTERNA_TAREA';
export const OBTENER_REPORTE_TODOLIST = 'OBTENER_REPORTE_TODOLIST';

// Proyectos
export const VALIDAR_PROYECTO = 'VALIDAR_PROYECTO';
export const AGREGAR_OFERTA = 'AGREGAR_OFERTA';
export const AGREGAR_SECCION = 'AGREGAR_SECCION';
export const AGREGAR_TAREA = 'AGREGAR_TAREA';
export const ACTUALIZAR_DETALLES = 'ACTUALIZAR_DETALLES';
export const LISTAR_PROYECTOS = 'LISTAR_PROYECTOS';
export const EXTERNO_ELIMINAR = 'EXTERNO_ELIMINAR';
export const ELIMINAR_PROYECTO = 'ELIMINAR_PROYECTO';
export const BUSCAR_OFERTAS = 'BUSCAR_OFERTAS';
export const FILTRAR_OFERTAS = 'FILTRAR_OFERTAS';
export const BUSCAR_PROYECTOS = 'BUSCAR_PROYECTOS';
export const BUSCAR_FILTROS_PROYECTOS = 'BUSCAR_FILTROS_PROYECTOS';
export const FILTRAR_PROYECTOS = 'FILTRAR_PROYECTOS';
export const HABILITAR_EDICION_PROYECTO = 'HABILITAR_EDICION_PROYECTO';
export const EXTERNO_ARCHIVO_PROYECTO = 'EXTERNO_ARCHIVO_PROYECTO';
export const ARCHIVOS_PROYECTO = 'ARCHIVOS_PROYECTO';
export const PUBLIC_TOKENS_ARCHIVOS_PROYECTO = 'PUBLIC_TOKENS_ARCHIVOS_PROYECTO';
export const EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO = 'EDIT_PUBLIC_TOKENS_ARCHIVOS_PROYECTO';
export const DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO = 'DELETE_PUBLIC_TOKENS_ARCHIVOS_PROYECTO';
export const AGREGAR_ARCHIVO_PROYECTO = 'AGREGAR_ARCHIVO_PROYECTO';
export const AGREGAR_CARPETA_PROYECTO = 'AGREGAR_CARPETA_PROYECTO';
export const DETALLES_ARCHIVO_PROYECTO = 'DETALLES_ARCHIVO_PROYECTO';
export const DETALLES_CARPETA_PROYECTO = 'DETALLES_CARPETA_PROYECTO';
export const EDITAR_ARCHIVO_PROYECTO = 'EDITAR_ARCHIVO_PROYECTO';
export const EDITAR_CARPETA_PROYECTO = 'EDITAR_CARPETA_PROYECTO';
export const ELIMINAR_ARCHIVO_PROYECTO = 'ELIMINAR_ARCHIVO_PROYECTO';
export const ELIMINAR_CARPETA_PROYECTO = 'ELIMINAR_CARPETA_PROYECTO';
export const COMPARTIR_LINK_ARCHIVOS = 'COMPARTIR_LINK_ARCHIVOS';
export const COMPARTIR_LINK_NOTAS = 'COMPARTIR_LINK_NOTAS';
export const COMPARTIR_LINK_REPORTE = 'COMPARTIR_LINK_REPORTE';
export const EXTERNO_ARCHIVO = 'EXTERNO_ARCHIVO';
export const EXTERNO_NOTA = 'EXTERNO_NOTA';
export const NOTAS_PROYECTO = 'NOTAS_PROYECTO';
export const AGREGAR_NOTA_PROYECTO = 'AGREGAR_NOTA_PROYECTO';
export const EDITAR_NOTA_PROYECTO = 'EDITAR_NOTA_PROYECTO';
export const DETALLES_NOTA_PROYECTO = 'DETALLES_NOTA_PROYECTO';
export const ELIMINAR_NOTA_PROYECTO = 'ELIMINAR_NOTA_PROYECTO';
export const AGREGAR_COUNTER_LISTING_PROYECTO = 'AGREGAR_COUNTER_LISTING_PROYECTO';
export const EDITAR_COUNTER_LISTING_PROYECTO = 'EDITAR_COUNTER_LISTING_PROYECTO';
export const ELIMINAR_COUNTER_LISTING_PROYECTO = 'ELIMINAR_COUNTER_LISTING_PROYECTO';
export const DETALLES_COUNTER_LISTING_PROYECTO = 'DETALLES_COUNTER_LISTING_PROYECTO';
export const MATERLIST_PROYECTO = 'MATERLIST_PROYECTO';

export const SELECCIONAR_ESCROW_BUY = 'SELECCIONAR_ESCROW_BUY';
export const SELECCIONAR_ESCROW_AGENTE_BUY = 'SELECCIONAR_ESCROW_AGENTE_BUY';

export const SELECCIONAR_BROKER_BUYER_BUY = 'SELECCIONAR_BROKER_BUYER_BUY';
export const SELECCIONAR_BROKER_AGENTE_BUYER_BUY = 'SELECCIONAR_BROKER_AGENTE_BUYER_BUY';
export const SELECCIONAR_BROKER_SELLER_BUY = 'SELECCIONAR_BROKER_SELLER_BUY';
export const SELECCIONAR_BROKER_AGENTE_SELLER_BUY = 'SELECCIONAR_BROKER_AGENTE_SELLER_BUY';

export const SELECCIONAR_LENDER_BUY = 'SELECCIONAR_LENDER_BUY';

export const SELECCIONAR_TITLE_BUY = 'SELECCIONAR_TITLE_BUY';
export const SELECCIONAR_TITLE_AGENTE_BUY = 'SELECCIONAR_TITLE_AGENTE_BUY';

export const SELECCIONAR_TERMITE_BUY = 'SELECCIONAR_TERMITE_BUY';
export const SELECCIONAR_TERMITE_AGENTE_BUY = 'SELECCIONAR_TERMITE_AGENTE_BUY';

export const SELECCIONAR_SEPTIC_BUY = 'SELECCIONAR_SEPTIC_BUY';
export const SELECCIONAR_SEPTIC_AGENTE_BUY = 'SELECCIONAR_SEPTIC_AGENTE_BUY';

export const SELECCIONAR_ESCROW_SALE = 'SELECCIONAR_ESCROW_SALE';
export const SELECCIONAR_ESCROW_AGENTE_SALE = 'SELECCIONAR_ESCROW_AGENTE_SALE';

export const SELECCIONAR_BROKER_BUYER_SALE = 'SELECCIONAR_BROKER_BUYER_SALE';
export const SELECCIONAR_BROKER_AGENTE_BUYER_SALE = 'SELECCIONAR_BROKER_AGENTE_BUYER_SALE';
export const SELECCIONAR_BROKER_SELLER_SALE = 'SELECCIONAR_BROKER_SELLER_SALE';
export const SELECCIONAR_BROKER_AGENTE_SELLER_SALE = 'SELECCIONAR_BROKER_AGENTE_SELLER_SALE';

export const SELECCIONAR_TITLE_SALE = 'SELECCIONAR_TITLE_SALE';
export const SELECCIONAR_TITLE_AGENTE_SALE = 'SELECCIONAR_TITLE_AGENTE_SALE';

export const SELECCIONAR_TERMITE_SALE = 'SELECCIONAR_TERMITE_SALE';
export const SELECCIONAR_TERMITE_AGENTE_SALE = 'SELECCIONAR_TERMITE_AGENTE_SALE';

export const SELECCIONAR_SEPTIC_SALE = 'SELECCIONAR_SEPTIC_SALE';
export const SELECCIONAR_SEPTIC_AGENTE_SALE = 'SELECCIONAR_SEPTIC_AGENTE_SALE';

export const RENEGOCIAR_OFFER = 'RENEGOCIAR_OFFER';
export const RENEGOCIAR_IN_ESCROW = 'RENEGOCIAR_IN_ESCROW';
export const SELECCIONAR_SECCION_MASTERLIST = 'SELECCIONAR_SECCION_MASTERLIST';
export const CHECKLIST_PROYECTO = 'CHECKLIST_PROYECTO';
export const DETALLES_PROYECTO = 'DETALLES_PROYECTO';
export const EDITANDO_PROYECTO = 'EDITANDO_PROYECTO';
export const EDITAR_SECCION = 'EDITAR_SECCION';
export const EDITAR_TAREA = 'EDITAR_TAREA';
export const ELIMINAR_SECCION = 'ELIMINAR_SECCION';
export const ELIMINAR_TAREA = 'ELIMINAR_TAREA';
export const OBTENER_CALENDARIO = 'OBTENER_CALENDARIO';
export const GUARDAR_CAMBIOS_FLIPPING = 'GUARDAR_CAMBIOS_FLIPPING';
export const DESPLEGAR_GUARDADO_FLIPPING = 'DESPLEGAR_GUARDADO_FLIPPING';
export const PAGINA_REDIRECCIONAR_FLIPPING = 'PAGINA_REDIRECCIONAR_FLIPPING';
export const ID_REDIRECCIONAR_FLIPPING = 'ID_REDIRECCIONAR_FLIPPING';
export const REDIRECCIONAR_FLIPPING = 'REDIRECCIONAR_FLIPPING';
export const BUYSALE_PAGE = 'BUYSALE_PAGE';
export const SELECCIONAR_PROYECTO_DASHBOARD = 'SELECCIONAR_PROYECTO_DASHBOARD';

// Buyers
export const BUSCAR_BUYERS = 'BUSCAR_BUYERS';
export const AGREGAR_BUYER = 'AGREGAR_BUYER';
export const EDITAR_BUYER = 'EDITAR_BUYER';
export const DETALLES_BUYER = 'DETALLES_BUYER';
export const ELIMINAR_BUYER = 'ELIMINAR_BUYER';

// Sellers
export const BUSCAR_SELLERS = 'BUSCAR_SELLERS';
export const AGREGAR_SELLER = 'AGREGAR_SELLER';
export const EDITAR_SELLER = 'EDITAR_SELLER';
export const DETALLES_SELLER = 'DETALLES_SELLER';
export const ELIMINAR_SELLER = 'ELIMINAR_SELLER';

// Vestings
export const BUSCAR_VESTINGS = 'BUSCAR_VESTINGS';
export const AGREGAR_VESTING = 'AGREGAR_VESTING';
export const EDITAR_VESTING = 'EDITAR_VESTING';
export const DETALLES_VESTING = 'DETALLES_VESTING';
export const ELIMINAR_VESTING = 'ELIMINAR_VESTING';

// Financing types
export const BUSCAR_FINANCING_TYPES = 'BUSCAR_FINANCING_TYPES';
export const AGREGAR_FINANCING_TYPE = 'AGREGAR_FINANCING_TYPE';
export const EDITAR_FINANCING_TYPE = 'EDITAR_FINANCING_TYPE';
export const DETALLES_FINANCING_TYPE = 'DETALLES_FINANCING_TYPE';
export const ELIMINAR_FINANCING_TYPE = 'ELIMINAR_FINANCING_TYPE';

// Escrows
export const BUSCAR_ESCROWS = 'BUSCAR_ESCROWS';
export const AGREGAR_ESCROW = 'AGREGAR_ESCROW';
export const EDITAR_ESCROW = 'EDITAR_ESCROW';
export const DETALLES_ESCROW = 'DETALLES_ESCROW';
export const ELIMINAR_ESCROW = 'ELIMINAR_ESCROW';
export const BUSCAR_ESCROWS_AGENTES = 'BUSCAR_ESCROWS_AGENTES';
export const AGREGAR_ESCROW_AGENTE = 'AGREGAR_ESCROW_AGENTE';
export const AGREGAR_ESCROW_AGENTE_DIRECTO = 'AGREGAR_ESCROW_AGENTE_DIRECTO';
export const EDITAR_ESCROW_AGENTE = 'EDITAR_ESCROW_AGENTE';
export const DETALLES_ESCROW_AGENTE = 'DETALLES_ESCROW_AGENTE';
export const ELIMINAR_ESCROW_AGENTE = 'ELIMINAR_ESCROW_AGENTE';

// Brokers
export const BUSCAR_BROKERS = 'BUSCAR_BROKERS';
export const AGREGAR_BROKER = 'AGREGAR_BROKER';
export const EDITAR_BROKER = 'EDITAR_BROKER';
export const DETALLES_BROKER = 'DETALLES_BROKER';
export const ELIMINAR_BROKER = 'ELIMINAR_BROKER';
export const BUSCAR_BROKERS_AGENTES = 'BUSCAR_BROKERS_AGENTES';
export const AGREGAR_BROKER_AGENTE = 'AGREGAR_BROKER_AGENTE';
export const AGREGAR_BROKER_AGENTE_DIRECTO = 'AGREGAR_BROKER_AGENTE_DIRECTO';
export const EDITAR_BROKER_AGENTE = 'EDITAR_BROKER_AGENTE';
export const DETALLES_BROKER_AGENTE = 'DETALLES_BROKER_AGENTE';
export const ELIMINAR_BROKER_AGENTE = 'ELIMINAR_BROKER_AGENTE';

// Lenders
export const BUSCAR_LENDERS = 'BUSCAR_LENDERS';
export const AGREGAR_LENDER = 'AGREGAR_LENDER';
export const EDITAR_LENDER = 'EDITAR_LENDER';
export const DETALLES_LENDER = 'DETALLES_LENDER';
export const ELIMINAR_LENDER = 'ELIMINAR_LENDER';

// Titles
export const BUSCAR_TITLES = 'BUSCAR_TITLES';
export const AGREGAR_TITLE = 'AGREGAR_TITLE';
export const EDITAR_TITLE = 'EDITAR_TITLE';
export const DETALLES_TITLE = 'DETALLES_TITLE';
export const ELIMINAR_TITLE = 'ELIMINAR_TITLE';
export const BUSCAR_TITLES_AGENTES = 'BUSCAR_TITLES_AGENTES';
export const AGREGAR_TITLE_AGENTE = 'AGREGAR_TITLE_AGENTE';
export const AGREGAR_TITLE_AGENTE_DIRECTO = 'AGREGAR_TITLE_AGENTE_DIRECTO';
export const EDITAR_TITLE_AGENTE = 'EDITAR_TITLE_AGENTE';
export const DETALLES_TITLE_AGENTE = 'DETALLES_TITLE_AGENTE';
export const ELIMINAR_TITLE_AGENTE = 'ELIMINAR_TITLE_AGENTE';

// Termites
export const BUSCAR_TERMITES = 'BUSCAR_TERMITES';
export const AGREGAR_TERMITE = 'AGREGAR_TERMITE';
export const EDITAR_TERMITE = 'EDITAR_TERMITE';
export const DETALLES_TERMITE = 'DETALLES_TERMITE';
export const ELIMINAR_TERMITE = 'ELIMINAR_TERMITE';
export const BUSCAR_TERMITES_AGENTES = 'BUSCAR_TERMITES_AGENTES';
export const AGREGAR_TERMITE_AGENTE = 'AGREGAR_TERMITE_AGENTE';
export const AGREGAR_TERMITE_AGENTE_DIRECTO = 'AGREGAR_TERMITE_AGENTE_DIRECTO';
export const EDITAR_TERMITE_AGENTE = 'EDITAR_TERMITE_AGENTE';
export const DETALLES_TERMITE_AGENTE = 'DETALLES_TERMITE_AGENTE';
export const ELIMINAR_TERMITE_AGENTE = 'ELIMINAR_TERMITE_AGENTE';

// Septics
export const BUSCAR_SEPTICS = 'BUSCAR_SEPTICS';
export const AGREGAR_SEPTIC = 'AGREGAR_SEPTIC';
export const EDITAR_SEPTIC = 'EDITAR_SEPTIC';
export const DETALLES_SEPTIC = 'DETALLES_SEPTIC';
export const ELIMINAR_SEPTIC = 'ELIMINAR_SEPTIC';
export const BUSCAR_SEPTICS_AGENTES = 'BUSCAR_SEPTICS_AGENTES';
export const AGREGAR_SEPTIC_AGENTE = 'AGREGAR_SEPTIC_AGENTE';
export const AGREGAR_SEPTIC_AGENTE_DIRECTO = 'AGREGAR_SEPTIC_AGENTE_DIRECTO';
export const EDITAR_SEPTIC_AGENTE = 'EDITAR_SEPTIC_AGENTE';
export const DETALLES_SEPTIC_AGENTE = 'DETALLES_SEPTIC_AGENTE';
export const ELIMINAR_SEPTIC_AGENTE = 'ELIMINAR_SEPTIC_AGENTE';

// Categorias Inspeccion
export const BUSCAR_CATEGORIAS_INSPECCION = 'BUSCAR_CATEGORIAS_INSPECCION';
export const AGREGAR_CATEGORIA_INSPECCION = 'AGREGAR_CATEGORIA_INSPECCION';
export const EDITAR_CATEGORIA_INSPECCION = 'EDITAR_CATEGORIA_INSPECCION';
export const ELIMINAR_CATEGORIA_INSPECCION = 'ELIMINAR_CATEGORIA_INSPECCION';

// Secciones To do list
export const BUSCAR_SECCIONES_TODOLIST = 'BUSCAR_SECCIONES_TODOLIST';
export const AGREGAR_SECCION_TODOLIST = 'AGREGAR_SECCION_TODOLIST';
export const EDITAR_SECCION_TODOLIST = 'EDITAR_SECCION_TODOLIST';
export const ELIMINAR_SECCION_TODOLIST = 'ELIMINAR_SECCION_TODOLIST';