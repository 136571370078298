import { BUSCAR_ESCROWS, AGREGAR_ESCROW, EDITAR_ESCROW, DETALLES_ESCROW, ELIMINAR_ESCROW, BUSCAR_ESCROWS_AGENTES, AGREGAR_ESCROW_AGENTE, AGREGAR_ESCROW_AGENTE_DIRECTO, EDITAR_ESCROW_AGENTE, ELIMINAR_ESCROW_AGENTE } from '../actions/types';

const initialState = {
    escrows: [],
    agents: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_ESCROW:
            return {
                ...state,
                escrows: [
                    ...state.escrows, payload
                ]
            };
        case EDITAR_ESCROW:
            return {
                ...state,
                escrows: state.escrows.map(
                    escrow => escrow._id === payload._id ? payload : escrow
                )
            };
        case DETALLES_ESCROW:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_ESCROW:
            return {
                ...state,
                escrows: state.escrows.filter( escrow => escrow._id !== payload.id )
            };
        case BUSCAR_ESCROWS:
            return {
                ...state,
                escrows: payload
            };
        case BUSCAR_ESCROWS_AGENTES:
            return {
                ...state,
                agents: payload
            };
        case AGREGAR_ESCROW_AGENTE:
            return {
                ...state,
                agents: [
                    ...state.agents, payload
                ]
            };
        case AGREGAR_ESCROW_AGENTE_DIRECTO:

            const escrow_elegido = state.escrows.find(escrow => escrow._id === payload.id_escrow);
            
            if(escrow_elegido){
                escrow_elegido.agentes = [...escrow_elegido.agentes, payload];
            }

            return {
                ...state,
                escrows: state.escrows.map( 
                    escrow => escrow._id === payload.id_escrow ? escrow_elegido : escrow
                )
            };
        case EDITAR_ESCROW_AGENTE:
            return {
                ...state,
                agents: state.agents.map(
                    agent => agent._id === payload._id ? payload : agent
                )
            };
        case ELIMINAR_ESCROW_AGENTE:
            return {
                ...state,
                agents: state.agents.filter( agent => agent._id !== payload.id )
            };
        default:
            return state;
    }
}