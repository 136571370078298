import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Lightbox from 'react-image-lightbox';

import { generateReporte } from '../../actions/nominas';

const styles = {
    titulo: { color: '#2C2052' },
    infoText: {
        margin: 0,
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const DetallesReporteNomina = () => {

    const dispatch = useDispatch();

    const { nominas, detalles, filtros } = useSelector(state => state.nominas);

    const [dataTabla, setDataTabla] = useState({
        salariosTabla: null,
        proyectosSalariosTabla: null,
        proyectosTabla: null,
        gasolinaTabla: null,
        basuraTabla: null,
        otroTabla: null
    });

    const { salariosTabla, proyectosSalariosTabla, proyectosTabla, gasolinaTabla, basuraTabla, otroTabla } = dataTabla;

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [detallesArchivo, setDetallesArchivo] = useState({
        extension: null,
        url: null
    });

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const fechasRango = (fecha_inicial, fecha_final) => {
        let texto_fechas = 'All time';

        if(fecha_inicial !== null && fecha_final !== null){
            texto_fechas = new Date(fecha_inicial).toLocaleDateString('en-US') + ' - ' + new Date(fecha_final).toLocaleDateString('en-US');
        }

        return texto_fechas;
    }

    /* Reporte */
    const handleGenerateReporte = () => {
        dispatch(generateReporte(filtros));
    }

    const handleSelectFile = (extension, url) => {
        setDetallesArchivo({
            ...detallesArchivo,
            extension,
            url
        });
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    
    const columnsSalaries = [
        {
            name: 'Employee',
            selector: 'empleado',
            sortable: true,
            cell: item => `${ item.empleado }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Salarie per day',
            selector: 'salario',
            sortable: true,
            cell: item => `${item.salario !== null ? numberToDecimal(item.salario) : '-'}`,
        }, {
            name: 'Total to pay',
            selector: 'total',
            sortable: true,
            cell: item => `${item.total !== null ? numberToDecimal(item.total) : '-'}`,
        }
    ];

    const columnsProjectsSalaries = [
        {
            name: 'Employee',
            selector: 'empleado',
            sortable: true,
            cell: item => `${ item.empleado }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto || 'General' }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Cost',
            selector: 'costo',
            sortable: true,
            cell: item => `${item.costo !== null ? numberToDecimal(item.costo) : '-'}`,
        }
    ];

    const columnsProjects = [
        {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Employees',
            selector: 'empleados',
            sortable: true,
            cell: item => `${ item.empleados }`,
        }, {
            name: 'Hours worked',
            selector: 'horas',
            sortable: true,
            cell: item => `${ item.horas }`,
        }, {
            name: 'Total cost',
            selector: 'costo',
            sortable: true,
            cell: item => `${item.costo !== null ? numberToDecimal(item.costo) : '-'}`,
        }
    ];

    const columnsGas = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }
    ];

    const columnsDumps = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }
    ];

    const columnsOthers = [
        {
            name: 'User',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${ item.proyecto }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones !== null && item.observaciones !== 'null' ? item.observaciones : '-' }`,
        }, {
            name: 'File',
            sortable: true,
            cell: item => {
                if(item.url !== null){
                    return <button className="btn" onClick={() => { handleSelectFile(item.extension, item.url) }}><i className="fa fa-file" /></button>
                }else{
                    return <>-</>
                }
            }
        }
    ];

    useEffect(() => {
        setDataTabla({
            salariosTabla: detalles?.empleados,
            proyectosSalariosTabla: detalles?.proyectos_salarios,
            proyectosTabla: detalles?.proyectos,
            gasolinaTabla: detalles?.gasolina,
            basuraTabla: detalles?.basura,
            otroTabla: detalles?.otro
        });
    }, []);

    return ( 
            <Fragment>

                {imageData.modal && (
                    <Lightbox
                        mainSrc={imageData.src}
                        onCloseRequest={() => { setDetallesArchivo({ ...detallesArchivo, extension: null, url: null }); setImageData({ ...imageData, modal: false }); } }
                    />
                )}

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-8 text-left">
                            <h3>Payroll details</h3>
                        </div>
                        <div className="col-md-4 text-right">
                            <button type="button" className="btn btn-primary" onClick={() => {handleGenerateReporte()}}><i className="fas fa-chart-bar fa-sm"></i> Generate report</button>
                            <Link to="/payments" className="btn btn-secondary ml-3"><i className="fas fa-arrow-left fa-sm"></i> Go back</Link>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3 row">
                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Date range</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{fechasRango(detalles?.fecha_inicio, detalles?.fecha_fin)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Salaries</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(detalles?.total_salarios)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Dumps trips</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(detalles?.total_basura)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Gas bills</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(detalles?.total_gasolina)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Other bills</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(detalles?.total_otro)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md">
                            <div className="card bg-light">
                                <div className="card-body">
                                    <div className="card-title mb-3">
                                        <h5 style={styles.titulo}>Total</h5>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 text-center">
                                            <p style={styles.infoText}>{numberToDecimal(detalles?.total)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            filtros !== null ?
                                filtros.id_empleado !== null ?
                                    <div className="col-md-2">
                                        <div className="card bg-light">
                                            <div className="card-body">
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Employee selected</h5>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12 text-center">
                                                        <p style={styles.infoText}>{filtros.nombre_empleado}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            :
                                null
                        }

                        {
                            filtros !== null ?
                                filtros.id_proyecto !== null ?
                                    <div className="col-md-3">
                                        <div className="card bg-light">
                                            <div className="card-body">
                                                <div className="card-title mb-3">
                                                    <h5 style={styles.titulo}>Project selected</h5>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-12 text-center">
                                                        <p style={styles.infoText}>{filtros.nombre_proyecto}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            :
                                null
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Employee payments</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsSalaries}
                                    data={salariosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Employee distribution</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsProjectsSalaries}
                                    data={proyectosSalariosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Project distribution</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsProjects}
                                    data={proyectosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Gas bills</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsGas}
                                    data={gasolinaTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Dumps trips</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsDumps}
                                    data={basuraTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>

                    <div className="table-responsive pl-2">
                        <h6 className="my-2 ml-3">Other bills</h6>
                        {
                            nominas !== undefined ?
                                <DataTable
                                    columns={columnsOthers}
                                    data={otroTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </Fragment>
    );
}
 
export default DetallesReporteNomina;
