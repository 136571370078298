import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// Components
import Alert from './components/layout/Alert';
import Idle from './components/layout/Idle';
import ModalDelete from './components/extras/ModalDelete';
import ModalReminder from './components/extras/ModalReminder';
import ModalTask from './components/extras/ModalTask';
import ModalPayroll from './components/extras/ModalPayroll';
import ModalExpense from './components/extras/ModalExpense';
import ModalInspection from './components/extras/ModalInspection';
import ModalFile from './components/extras/ModalFile';
import ModalFileProject from './components/extras/ModalFileProject';
import ModalNote from './components/extras/ModalNote';
import ModalActividad from './components/extras/ModalActividad';
import Layout from './components/layout/Layout';
import LayoutPublic from './components/layout/LayoutPublic';
import PrivateRoute from './components/routing/PrivateRoute';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Usuarios
import Perfil from './views/usuarios/Perfil';
import Usuarios from './views/usuarios/Usuarios';
import EditarUsuario from './views/usuarios/EditarUsuario';
import NuevoUsuario from './views/usuarios/NuevoUsuario';

//Empleados
import Empleados from './views/empleados/Empleados';
import EditarEmpleado from './views/empleados/EditarEmpleado';
import NuevoEmpleado from './views/empleados/NuevoEmpleado';

//Nominas
import Nominas from './views/nominas/Nominas';
import DetallesNomina from './views/nominas/DetallesNomina';
import DetallesReporteNomina from './views/nominas/DetallesReporteNomina';
import EditarNomina from './views/nominas/EditarNomina';
import NuevaNomina from './views/nominas/NuevaNomina';

//Gastos
import Gastos from './views/gastos/Gastos';

//Contabilidad
import DashboardContabilidad from './views/contabilidad/DashboardContabilidad';
import TarjetasCredito from './views/contabilidad/TarjetasCredito';
import NuevaTarjetaCredito from './views/contabilidad/NuevaTarjetaCredito';
import EditarTarjetaCredito from './views/contabilidad/EditarTarjetaCredito';
import EstadosTarjetaCredito from './views/contabilidad/EstadosTarjetaCredito';

//Deudas
import DashboardDeudas from './views/deudas/DashboardDeudas';
import Proveedores from './views/deudas/Proveedores';
import NuevoProveedor from './views/deudas/NuevoProveedor';
import EditarProveedor from './views/deudas/EditarProveedor';
import DetallesProveedor from './views/deudas/DetallesProveedor';

// Actividades
import Actividades from './views/actividades/Actividades';
import ReporteActividades from './views/actividades/Reporte';

// Recordatorios
import Recordatorios from './views/recordatorios/Recordatorios';

// To do list
import ToDoList from './views/todolist/ToDoList';
import ReporteToDoList from './views/todolist/Reporte';

// Agenda
import Agenda from './views/agenda/Agenda';

// Analisis de ofertas
import NuevoAnalisis from './views/analizador/NuevoAnalisis';
import EditarAnalisis from './views/analizador/EditarAnalisis';
import Analisis from './views/analizador/Analisis';

// Proyectos
import NuevaOferta from './views/proyectos/NuevaOferta';
import EditarOferta from './views/proyectos/EditarOferta';
import Ofertas from './views/proyectos/Ofertas';
import NuevoProyecto from './views/proyectos/NuevoProyecto';
import EditarProyecto from './views/proyectos/EditarProyecto';
import Proyectos from './views/proyectos/Proyectos';
import AgendaProyecto from './views/proyectos/AgendaProyecto';
import HistorialProyecto from './views/proyectos/HistorialProyecto';
import BuySale from './views/proyectos/BuySale';
import Files from './views/proyectos/Files';
import PublicFiles from './views/proyectos/PublicFiles';
import PublicTokens from './views/proyectos/PublicTokens';
import Notes from './views/proyectos/Notes';
import Inspections from './views/proyectos/Inspections';
import Repairs from './views/proyectos/Repairs';
import DueDiligenceChecklist from './views/proyectos/DueDiligenceChecklist';

// Deal analyzer
import DealAnalyzer from './views/configuracion/DealAnalyzer';

// Buyers
import Buyers from './views/configuracion/Buyers';
import NuevoBuyer from './views/configuracion/NuevoBuyer';
import EditarBuyer from './views/configuracion/EditarBuyer';

// Sellers
import Sellers from './views/configuracion/Sellers';
import NuevoSeller from './views/configuracion/NuevoSeller';
import EditarSeller from './views/configuracion/EditarSeller';

// Vestings
import Vestings from './views/configuracion/Vestings';
import NuevoVesting from './views/configuracion/NuevoVesting';
import EditarVesting from './views/configuracion/EditarVesting';

// Brokers
import Brokers from './views/configuracion/Brokers';
import NuevoBroker from './views/configuracion/NuevoBroker';
import EditarBroker from './views/configuracion/EditarBroker';
import AgentesBroker from './views/configuracion/AgentesBroker';

// Escrows
import Escrows from './views/configuracion/Escrows';
import NuevoEscrow from './views/configuracion/NuevoEscrow';
import EditarEscrow from './views/configuracion/EditarEscrow';
import AgentesEscrow from './views/configuracion/AgentesEscrow';

// Titles
import Titles from './views/configuracion/Titles';
import NuevoTitle from './views/configuracion/NuevoTitle';
import EditarTitle from './views/configuracion/EditarTitle';
import AgentesTitle from './views/configuracion/AgentesTitle';

// Termites
import Termites from './views/configuracion/Termites';
import NuevoTermite from './views/configuracion/NuevoTermite';
import EditarTermite from './views/configuracion/EditarTermite';
import AgentesTermite from './views/configuracion/AgentesTermite';

// Septics
import Septics from './views/configuracion/Septics';
import NuevoSeptic from './views/configuracion/NuevoSeptic';
import EditarSeptic from './views/configuracion/EditarSeptic';
import AgentesSeptic from './views/configuracion/AgentesSeptic';

// Categories of inspection
import InspectionCategories from './views/configuracion/InspectionCategories';

// Sections of to do list
import TodolistSections from './views/configuracion/TodolistSections';

// Redux
import store from './store';
import { Provider } from 'react-redux';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  useEffect(() => {
      store.dispatch(loadUser());
  }, [])

  return (
    <Provider store={store}>
        <Router>
            <Fragment>
                <Layout></Layout>
                <LayoutPublic></LayoutPublic>
                <section className="container-fluid principal-section">
                    <Alert></Alert>
                    <Idle></Idle>
                    <ModalDelete></ModalDelete>
                    <ModalReminder></ModalReminder>
                    <ModalTask></ModalTask>
                    <ModalPayroll></ModalPayroll>
                    <ModalExpense></ModalExpense>
                    <ModalInspection></ModalInspection>
                    <ModalFile></ModalFile>
                    <ModalFileProject></ModalFileProject>
                    <ModalNote></ModalNote>
                    <ModalActividad></ModalActividad>
                    
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute exact path="/dashboard" component={Dashboard} />

                        {/* Analisis de ofertas */}
                        <PrivateRoute exact path="/deal/new" component={NuevoAnalisis} />
                        <PrivateRoute exact path="/deal/edit/:id" component={EditarAnalisis} />
                        <PrivateRoute exact path="/deal/list" component={Analisis} />

                        {/* Proyectos */}
                        <Route exact path="/projects/public_files/:id/:token" component={PublicFiles} />
                        <PrivateRoute exact path="/projects/offer" component={NuevaOferta} />
                        <PrivateRoute exact path="/projects/edit_offer/:id" component={EditarOferta} />
                        <PrivateRoute exact path="/projects/offers" component={Ofertas} />
                        <PrivateRoute exact path="/projects/side" component={NuevoProyecto} />
                        <PrivateRoute exact path="/projects/edit_side/:id" component={EditarProyecto} />
                        <PrivateRoute exact path="/projects/projects" component={Proyectos} />
                        <PrivateRoute exact path="/projects/buySale/:id" component={BuySale} />
                        <PrivateRoute exact path="/projects/history/:id" component={HistorialProyecto} />
                        <PrivateRoute exact path="/projects/files/:id" component={Files} />
                        <PrivateRoute exact path="/projects/public_tokens/:id" component={PublicTokens} />
                        <PrivateRoute exact path="/projects/notes/:id" component={Notes} />
                        <PrivateRoute exact path="/projects/inspections/:id" component={Inspections} />
                        <PrivateRoute exact path="/projects/repairs/:id" component={Repairs} />
                        <PrivateRoute exact path="/projects/dueDiligence/:id" component={DueDiligenceChecklist} />
                        <PrivateRoute exact path="/projects/schedule/:id_project" component={AgendaProyecto} />

                        {/* Actividades */}
                        <PrivateRoute exact path="/actividades/listado" component={Actividades} />
                        <PrivateRoute exact path="/actividades/reporte" component={ReporteActividades} />

                        {/* Recordatorios */}
                        <PrivateRoute exact path="/reminders" component={Recordatorios} />
                        <PrivateRoute exact path="/reminders/:id" component={Recordatorios} />

                        {/* To do list */}
                        <PrivateRoute exact path="/todolist/listado" component={ToDoList} />
                        <PrivateRoute exact path="/todolist/listado/:id" component={ToDoList} />
                        <PrivateRoute exact path="/todolist/reporte" component={ReporteToDoList} />
                        <PrivateRoute exact path="/todolist/reporte/:id" component={ReporteToDoList} />
                        
                        {/* Agenda */}
                        <PrivateRoute exact path="/agenda" component={Agenda} />
                        <PrivateRoute exact path="/agenda/:id" component={Agenda} />

                        {/* Usuarios */}
                        <PrivateRoute exact path="/users" component={Usuarios} />
                        <PrivateRoute exact path="/users/edit" component={EditarUsuario} />
                        <PrivateRoute exact path="/users/new" component={NuevoUsuario} />
                        <PrivateRoute exact path="/profile" component={Perfil} />

                        {/* Empleados */}
                        <PrivateRoute exact path="/employees" component={Empleados} />
                        <PrivateRoute exact path="/employees/new" component={NuevoEmpleado} />
                        <PrivateRoute exact path="/employees/edit" component={EditarEmpleado} />

                        {/* Nominas */}
                        <PrivateRoute exact path="/payments" component={Nominas} />
                        <PrivateRoute exact path="/payments/new" component={NuevaNomina} />
                        <PrivateRoute exact path="/payments/edit/:id" component={EditarNomina} />
                        <PrivateRoute exact path="/payments/details/:id" component={DetallesNomina} />
                        <PrivateRoute exact path="/payments/report" component={DetallesReporteNomina} />

                        {/* Expenses */}
                        <PrivateRoute exact path="/expenses" component={Gastos} />

                        {/* Accounting */}
                        <PrivateRoute exact path="/accounting" component={DashboardContabilidad} />
                        <PrivateRoute exact path="/accounting/credit_cards" component={TarjetasCredito} />
                        <PrivateRoute exact path="/accounting/new_credit_card" component={NuevaTarjetaCredito} />
                        <PrivateRoute exact path="/accounting/edit_credit_card/:id" component={EditarTarjetaCredito} />
                        <PrivateRoute exact path="/accounting/details_credit_card/:id" component={EstadosTarjetaCredito} />

                        {/* Bills */}
                        <PrivateRoute exact path="/bills" component={DashboardDeudas} />
                        <PrivateRoute exact path="/bills/providers" component={Proveedores} />
                        <PrivateRoute exact path="/bills/new_provider" component={NuevoProveedor} />
                        <PrivateRoute exact path="/bills/edit_provider/:id" component={EditarProveedor} />
                        <PrivateRoute exact path="/bills/details_provider/:id" component={DetallesProveedor} />

                        {/* Deal analyzer */}
                        <PrivateRoute exact path="/configuration/deal" component={DealAnalyzer} />

                        {/* Buyers */}
                        <PrivateRoute exact path="/configuration/buyers" component={Buyers} />
                        <PrivateRoute exact path="/configuration/new-buyer" component={NuevoBuyer} />
                        <PrivateRoute exact path="/configuration/edit-buyer/:id" component={EditarBuyer} />

                        {/* Sellers */}
                        <PrivateRoute exact path="/configuration/sellers" component={Sellers} />
                        <PrivateRoute exact path="/configuration/new-seller" component={NuevoSeller} />
                        <PrivateRoute exact path="/configuration/edit-seller/:id" component={EditarSeller} />

                        {/* Vestings */}
                        <PrivateRoute exact path="/configuration/vestings" component={Vestings} />
                        <PrivateRoute exact path="/configuration/new-vesting" component={NuevoVesting} />
                        <PrivateRoute exact path="/configuration/edit-vesting/:id" component={EditarVesting} />

                        {/* Brokers */}
                        <PrivateRoute exact path="/configuration/brokers" component={Brokers} />
                        <PrivateRoute exact path="/configuration/new-broker" component={NuevoBroker} />
                        <PrivateRoute exact path="/configuration/edit-broker/:id" component={EditarBroker} />
                        <PrivateRoute exact path="/configuration/agents-broker/:id" component={AgentesBroker} />

                        {/* Escrows */}
                        <PrivateRoute exact path="/configuration/escrows" component={Escrows} />
                        <PrivateRoute exact path="/configuration/new-escrow" component={NuevoEscrow} />
                        <PrivateRoute exact path="/configuration/edit-escrow/:id" component={EditarEscrow} />
                        <PrivateRoute exact path="/configuration/agents-escrow/:id" component={AgentesEscrow} />

                        {/* Titles */}
                        <PrivateRoute exact path="/configuration/titles" component={Titles} />
                        <PrivateRoute exact path="/configuration/new-title" component={NuevoTitle} />
                        <PrivateRoute exact path="/configuration/edit-title/:id" component={EditarTitle} />
                        <PrivateRoute exact path="/configuration/agents-title/:id" component={AgentesTitle} />

                        {/* Termites */}
                        <PrivateRoute exact path="/configuration/termites" component={Termites} />
                        <PrivateRoute exact path="/configuration/new-termite" component={NuevoTermite} />
                        <PrivateRoute exact path="/configuration/edit-termite/:id" component={EditarTermite} />
                        <PrivateRoute exact path="/configuration/agents-termite/:id" component={AgentesTermite} />

                        {/* Septics */}
                        <PrivateRoute exact path="/configuration/septics" component={Septics} />
                        <PrivateRoute exact path="/configuration/new-septic" component={NuevoSeptic} />
                        <PrivateRoute exact path="/configuration/edit-septic/:id" component={EditarSeptic} />
                        <PrivateRoute exact path="/configuration/agents-septic/:id" component={AgentesSeptic} />

                        {/* Categories of inspection */}
                        <PrivateRoute exact path="/configuration/inspections" component={InspectionCategories} />
                        
                        {/* Sections of to do list */}
                        <PrivateRoute exact path="/configuration/todolist" component={TodolistSections} />

                        {/* Cuando no matchea con nada*/}
                        <Route path="*">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </section>
            </Fragment>
        </Router>
    </Provider>
  );
}

export default App;
