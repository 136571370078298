import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { buscarTitles, eliminarTitle } from '../../actions/titles';
import { Link } from 'react-router-dom';

const Titles = ({ history }) => {

    const titles = useSelector(state => state.titles);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(buscarTitles());
    }, []);

    const tableRow = (items) => {
        if(items){
            if(items.length > 0){
                
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td> {item.compania || '-'} </td>
                                            <td> {item.direccion || '-'} </td>
                                            <td> {item.agentes?.length} </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.agents_title ?
                                                    null
                                                    :
                                                    <button className="btn btn-link text-primary" onClick={() => agentes(item._id)}>Agents</button>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.edit_title ?
                                                    null
                                                    :
                                                    <button className="btn btn-link text-primary" onClick={() => editar(item._id)}>Edit</button>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.delete_title ?
                                                    null
                                                    :
                                                    <button id={'eliminar_'+item._id} className="btn btn-link text-danger" onClick={() => confirmar(item._id, 1)}>Delete</button>
                                                }
                                                
                                                <div id={'confirmar_'+item._id} className="text-center" style={{display: 'none'}}>
                                                    <label>Continue?</label>
                                                    <div style={{display: 'flex'}}>
                                                        <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}><i className="fas fa-check fa-sm"></i> Yes</button>
                                                        <button className="btn btn-link text-danger" onClick={() => confirmar(item._id, 2)}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="6" className="text-center">No titles registered.</td>
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="6" className="text-center">No titles registered.</td>
                    </tr>
                </tbody>
            );
        }
    }

    const agentes = id => {
        history.push('/configuration/agents-title/' + id);
    }

    const editar = id => {
        history.push('/configuration/edit-title/' + id);
    }

    const confirmar = (id, type) => {
        if (type === 1) {
            document.getElementById('eliminar_'+id).style.display = 'none';
            document.getElementById('confirmar_'+id).style.display = 'unset';
        } else {
            document.getElementById('eliminar_'+id).style.display = 'unset';
            document.getElementById('confirmar_'+id).style.display = 'none';
        }
    }

    const eliminar = id => {
        dispatch(eliminarTitle(id));
    }

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Titles</h3>
                        <p className="text-muted"> {titles.titles?.length} registered titles</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            !permisos_configuration?.add_title ?
                            null
                            :
                            <Link to="/configuration/new-title" className="btn btn-success text-right"><i className="fas fa-plus fa-sm"></i> Add title</Link>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Company</th>
                                <th scope="col">Address</th>
                                <th scope="col"># of agents</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {tableRow(titles.titles)}
                    </table>
                </div>
            </div>
        </div>
    );    
}

export default Titles;