import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { v4 as uuid } from 'uuid';
import { ObjectID } from 'bson';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { filterProyectos } from '../../actions/dashboard';
import { validarProyecto, agregarProyecto, buscarTiposProyectos, detallesPreProyecto, externoNota, eliminarNotasProyecto } from '../../actions/proyectos';

const ContainerStyle = {
    width: '100%',
    height: '50vh'
};

const NuevoProyecto = ({ history }) => {
    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const tipos = useSelector(state => state.tipos);
    const usuarios = useSelector(state => state.usuarios);

    const proyectoDataInit = {
        _id: null,
        tipo_proyecto: null,
        tipo_construccion: null,
        direccion: null,
        latitud: null,
        longitud: null,
        id_usuario_responsable: null,
        url_screenshot_file: null
    }

    const [proyectoData, setProyectoData] = useState(proyectoDataInit);

    const [screenshotFile, setScreenshotFile] = useState({
        file: null,
        url: ''
    });

    const [coincidenciaProyecto, setCoincidenciaProyecto] = useState({
        direccion: '',
        proyecto: '',
        construccion: ''
    });

    const [isBackButtonClicked, setBackbuttonPress] = useState(false);    
    const [spinner, setSpinner] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });
    
    const [screenshotModal, setScreenshotModal] = useState(false);
    const [viewScreenshotModal, setViewScreenshotModal] = useState(false);
    const [coincidenciaModal, setCoincidenciaModal] = useState(false);

    /* User Assigned */
    const [userResponsableSelectData, setUserResponsableSelectData] = useState(null);

    const [usersFilter, setUsersFilter] = useState([]);

    /* ScreenShoot functions */
    const onPasteScreenShotFile = (e) => {
        if(!e?.clipboardData?.files[0]){
            setScreenshotFile({
                ...screenshotFile,
                file: undefined,
                archivo: ''
            });
            
            alert('No file selected, try again');
            setScreenshotModal(false);
            
            
        }else{
            let file = e.clipboardData.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setScreenshotFile({
                    ...screenshotFile,
                    file: file,
                    url: reader.result
                });
            }
        }
    }

    const onDeleteScreenshot = () => {
        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }

    const handleCloseModalScreenShoot = () => {
        setScreenshotModal(false);

        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }


    /* Funciones generales */
    const handleSelectAddress = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setCoord({
                    lat: latLng.lat, lng: latLng.lng
                }),
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChangeAddress = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });
    };

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    }

    const handleTypeProject = (e) => {
        let valor = document.getElementById('p-proyecto');

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: valor.value,
                tipo_construccion: null
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: null,
                tipo_construccion: null
            });
        }
    }

    const handleTypeConstruction = (e) => {
        let valor = document.getElementById('t-construccion');

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: valor.value
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: null
            });
        }
    }

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let { value } = option;

            switch(origin){
                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(option);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value
                });
            }            
        }else{
            switch(origin){
                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(null);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null
                });
            }
        }
    };

    const handleCoincidenciaModal = () => {
        setCoincidenciaModal(true);
    }

    const handleCloseCoincidenciaModal = () => {
        setCoincidenciaModal(false);
    }

    const newNote = async (campo) => {
        await dispatch(detallesPreProyecto(proyectoData));

        await dispatch(externoNota(true, campo));
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    /* Funciones de guardado */
    const handleCancel = async () => {
        await eliminarNotasProyecto(proyectoData._id);
        await localStorage.removeItem('datos');
        await history.push('/dashboard');
    }

    const handleValidate = async () => {
        if (proyectoData.direccion !== null && proyectoData.direccion !== '' && proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.id_usuario_responsable !== null) {
            await dispatch(validarProyecto(null, proyectoData.direccion));
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    const handleSave = async () => {
        if (proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.id_usuario_responsable !== null) {

            let formData = new FormData();
            formData.append('tipo_proyecto', proyectoData.tipo_proyecto);
            formData.append('tipo_construccion', proyectoData.tipo_construccion);
            formData.append('direccion', proyectoData.direccion);
            formData.append('latitud', proyectoData.latitud);
            formData.append('longitud', proyectoData.longitud);
            formData.append('id_usuario_responsable', proyectoData.id_usuario_responsable);
            formData.append('screenshot_file', screenshotFile.file);
            
            await setSpinner(true);
            await setFormDisabled(true);

            await dispatch(agregarProyecto(formData));

            await dispatch(filterProyectos({
                project_id: proyectoData.tipo_proyecto,
                construction_id: proyectoData.tipo_construccion,
                status_id: [null], 
                active: null
            }));

            await localStorage.removeItem('datos');

            await setSpinner(false);
            await setFormDisabled(false);
            await history.push('/projects/projects');
            
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    useEffect(() => {
        if(JSON.stringify(proyectoData) !== JSON.stringify(proyectoDataInit)){
            localStorage.setItem('datos', JSON.stringify(proyectoData));
        }
    }, [proyectoData]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {
        if(proyectos.proyecto_coincidencia !== null){
            if(proyectos.proyecto_coincidencia !== ''){
                setCoincidenciaProyecto({
                    ...coincidenciaProyecto,
                    direccion: proyectos.proyecto_coincidencia?.direccion,
                    proyecto: proyectos.proyecto_coincidencia?.proyecto,
                    construccion: proyectos.proyecto_coincidencia?.construccion
                });
            }else{
                handleSave();
            }
        }
    }, [proyectos.proyecto_coincidencia]);

    useEffect(() => {
        if(proyectoData.tipo_proyecto === null){
            dispatch(buscarTiposProyectos(0));
        }else{
            dispatch(buscarTiposProyectos(proyectoData.tipo_proyecto));
        }
    }, [proyectoData.tipo_proyecto]);

    useEffect(() => {
        if(proyectoData.id_usuario_responsable === null){
            setUserResponsableSelectData(null);
        }else{
            if(usersFilter.length > 0){

                let usuario_asignado = usersFilter.filter( usuario => String(usuario.value) === String(proyectoData.id_usuario_responsable) );
                
                if(usuario_asignado.length > 0){
                    setUserResponsableSelectData(usuario_asignado);
                }
            }
        }
    }, [usersFilter]);

    useEffect(() => {
        if(coincidenciaProyecto.direccion !== '' && coincidenciaProyecto.proyecto !== '' && coincidenciaProyecto.construccion !== ''){
            handleCoincidenciaModal();
        }
    }, [coincidenciaProyecto]);

    useEffect(() => {
        dispatch(buscarTiposProyectos(0));
        dispatch(buscarUsuarios());

        if(localStorage.getItem('datos')){
            setProyectoData(JSON.parse(localStorage.getItem('datos')));
        }else{
            setProyectoData({
                ...proyectoData,
                _id: new ObjectID().toString()
            });
        }

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {        
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    return (
        <Fragment>
            {/* Modal de coincidencia */}
            <Modal backdrop="static" show={coincidenciaModal} centered onHide={() => {
                setCoincidenciaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Similarity in projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>The project with the address "<b>{coincidenciaProyecto.direccion}</b>" (<b>{coincidenciaProyecto.proyecto}</b> - <b>{coincidenciaProyecto.construccion}</b>) has some similarity. Do you want to continue saving anyway?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseCoincidenciaModal()}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => {setCoincidenciaModal(false); handleSave();}}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal para agregar Screenshot File */}
            <Modal backdrop="static" show={screenshotModal} centered onHide={() => {
                setScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Pasting Screenshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12" onPaste={(e)=> onPasteScreenShotFile(e)} style={{height: '100%', width: '100%', 'border-style': 'dashed', 'border-color': '#6c757d' }}>
                            {
                                screenshotFile.url !== '' ?
                                <>
                                    <button className="btn btn-small btn-danger py-1 px-2" onClick={() => onDeleteScreenshot()}><i className='fas fa-times'></i></button>
                                    <img id='pasteScreenshot' src={screenshotFile.url} style={{height: '100%', width: '100%', padding: '8px'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}>Paste Here</h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalScreenShoot()}>Cancel</button>
                    <button className="btn btn-success" onClick={() => setScreenshotModal(false)}>Save</button>
                </Modal.Footer>
            </Modal>
            
            {/* Modal para ver Screenshot File */}
            <Modal backdrop="static" show={viewScreenshotModal} size="lg" centered onHide={() => {
                setViewScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>View Screenshot file</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="row">
                        <div className="col-12">
                            {
                                screenshotFile.file !== null ?
                                <>
                                    <img id='screenshotFile' src={screenshotFile.url} style={{width: '100%'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}> No image Found </h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => setViewScreenshotModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <form id="contender_form" className="col-md-12" autoComplete="off" onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}}>
                    <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />

                    <div className="row d-flex">
                        <h3 className="col-md mb-3 mt-5">New side project</h3>

                        <div className="col-md d-flex justify-content-end mb-3 mt-5">

                            <button type="button" className="btn btn-primary mr-2" onClick={() => setScreenshotModal(true)}><i className="fas fa-file-medical fa-sm"></i> {screenshotFile.file !== null ? 'Update' : 'Add'} Screenshot file</button>
                            {
                                screenshotFile.file !== null ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => setViewScreenshotModal(true)}><i className="fas fa-eye fa-sm"></i> View Screenshoot file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setCollapse(!collapse)} data-toggle="collapse" data-target="#contenedor_mapa" aria-expanded="true" aria-controls="contenedor_mapa"><i className="fas fa-map"></i> {collapse ? 'Hide Map' : 'View Map'}</button>
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleCancel()} disabled={formDisabled ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                            <button type="button" className="btn btn-success" onClick={() => handleValidate()} disabled={formDisabled ? true : false}><i className="fas fa-check fa-sm"></i> Save and continue</button>
                        </div>
                    </div>

                    {spinner ?  
                            <div className="d-flex justify-content-center">
                                <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="row mt-5">    
                        <div className="col-md-12 row">

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_proyecto !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Project Type')}>Project Type: *</label> <br></br>
                                <select name="tipo_proyecto" id="p-proyecto" className="form-control" onChange={e => {handleTypeProject(e)}} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.proyectos?.length > 0 ?
                                            tipos.proyectos?.map(tipo => {
                                                return(
                                                    <option key={tipo._id} value={ tipo._id } selected={(proyectoData.tipo_proyecto === tipo._id) ? true : null}>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_construccion !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Property Type')}>Property Type: *</label> <br></br>
                                <select name="tipo_construccion" id="t-construccion" className="form-control" onChange={e => {handleTypeConstruction(e)}} disabled={formDisabled ? true : false} >
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.construcciones?.length > 0 ?
                                            tipos.construcciones?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={ tipo._id } selected={(proyectoData.tipo_construccion === tipo._id) ? true : null}>
                                                        { tipo.nombre }
                                                    </option>
                                                )
                                            })
                                        :
                                            ''
                                    }
                                </select>
                            </div>

                            <PlacesAutocomplete
                                value={proyectoData.direccion}
                                onChange={handleChangeAddress}
                                onSelect={handleSelectAddress}
                            >

                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className={'col-md form-group' + (proyectoData.direccion !== null && proyectoData.direccion !== '' ? '' : ' campo-recordatorio')}>
                                        <label className="font-weight-bold" onClick={() => newNote('General address')}>General address: *</label>
                                        <input
                                            {...getInputProps({
                                                className: 'location-search-input form-control',
                                                id: 'i-direccion'
                                            })}
                                            disabled={formDisabled ? true : false}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const id = uuid();
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                        })}
                                                        key={id}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.id_usuario_responsable !== null && proyectoData.id_usuario_responsable !== '' ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Who will be the responsible user?')}>Who will be the responsible user?:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_usuario_responsable"
                                        className="select-group"
                                        isClearable={true}
                                        options={usersFilter}
                                        value={userResponsableSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_usuario_responsable', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="contenedor_mapa" className="row mt-4 collapse" aria-labelledby="headingOne" data-parent="#contender_form">
                        <div className="col-md-8 offset-md-2 form-group">
                            <div id="mapa" className="mapa">
                                <GoogleMap
                                    mapContainerStyle={ContainerStyle}
                                    center={coord}
                                    zoom={16}
                                    onRightClick={e => { handleMapClick(e) }}
                                >
                                    <Marker key={coord.lat} position={coord} />
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default NuevoProyecto;