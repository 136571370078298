import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';

import { buscarAnalisis, eliminarAnalisis } from '../../actions/deal_analyzer';

const Analisis = ({ history }) => {

    const dispatch = useDispatch();

    const { analisis } = useSelector(state => state.deal_analyzer);

    const [dataTabla, setDataTabla] = useState({
        analisisTabla: null
    });

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columns = [
        {
            name: '',
            button: true,
            cell: item =>
                <button className="btn btn-link text-danger" onClick={() => handleDelete(item._id)}>
                    Delete
                </button>,
        }, {
            name: 'Address',
            selector: 'direccion',
            sortable: true,
            cell: item => `${item.direccion || 'Not defined'}`,
            wrap: false,
            grow: 4
        }, {
            name: 'Purchase price',
            selector: 'purchase_price',
            sortable: true,
            cell: item => `${item.purchase_price !== null ? numberToDecimal(item.purchase_price) : '-'}`,
        }, {
            name: 'Market value (ARV)',
            selector: 'market_value_arv',
            sortable: true,
            cell: item => `${item.market_value_arv !== null ? numberToDecimal(item.market_value_arv) : '-'}`,
        }, {
            name: 'Flipping profit',
            selector: 'flipping_profit',
            sortable: true,
            cell: item => `${item.flipping_profit !== null ? numberToDecimal(item.flipping_profit) : '-'}`,
        }, {
            name: 'Max purchase price',
            selector: 'max_purchase_price',
            sortable: true,
            cell: item => `${item.max_purchase_price !== null ? numberToDecimal(item.max_purchase_price) : '-'}`,
        }, {
            name: 'Funds need to close',
            selector: 'funds_need_to_close',
            sortable: true,
            cell: item => `${item.funds_need_to_close !== null ? numberToDecimal(item.funds_need_to_close) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }
    ];

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const handleDelete = async (id) => {
        await dispatch(eliminarAnalisis(id));
    }

    const handleOpenDetalles = row => {
        history.push('/deal/edit/' + row._id);
    }

    useEffect(() => {
        dispatch(buscarAnalisis());        
    }, []);

    useEffect(() => {
        setDataTabla({
            analisisTabla: analisis
        });
    }, [analisis]);

    return (
        <Fragment>
            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-12 text-left">
                        <h3>Deals</h3>
                        <p className="text-muted col-12 mb-0 px-0"> {analisis?.length} {analisis?.length === 1 ? 'generated deal' : 'generated deals'} </p>
                    </div>
                </div>

                <div className="table-responsive pl-2">
                    {
                        analisis ?
                            <DataTable
                                columns={columns}
                                data={dataTabla.analisisTabla || []}
                                noHeader={true}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                                onRowDoubleClicked={handleOpenDetalles}
                            />
                        :
                            <div className="col-md-10 offset-md-1 text-center">
                                <h3>LOADING DATA.</h3>
                                <i className="fas fa-spinner fa-pulse"></i>
                            </div>
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default Analisis;