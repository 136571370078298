import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

//Actions
import { agregarFileProyecto, externoArchivoProyecto } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const ModalFileProject = () => {

    const dispatch = useDispatch();

    const { externo_archivo_proyecto, detalles } = useSelector(state => state.proyectos);

    const [sectionsFilter, setSectionsFilter] = useState([]);
    const [sectionAddInformationData, setSectionAddInformationData] = useState(null);

    //Nuevo
    let nuevo_ref = createRef();

    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        id_status: null,
        id_folder: null,
        nombre: null,
        archivo: null,
        extension: null
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const handleOpenNuevoArchivo = () => {

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            id_folder: null,
            nombre: null,
            archivo: null,
            extension: null
        });

        if(detalles?.proyecto?.tipo_status !== null){
            setSectionAddInformationData({value: detalles?.proyecto?.tipo_status, label: detalles?.proyecto?.nombre_status})
        }

        let options = [];

        for (var i = 0; i < detalles?.proyecto?.lista_status_proyecto?.length; i++) {
            options.push({ value: detalles?.proyecto?.lista_status_proyecto[i]?.id_status, label: detalles.proyecto?.lista_status_proyecto[i]?.nombre });
        }

        setSectionsFilter(options);
    }

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const handleSelect = (option) => {
        setSectionAddInformationData(option);

        setDatosNuevo({
            ...datosNuevo,
            id_status: option !== null ? option.value : null
        });
    };

    const onChangeFileNuevo = () => {
        let nombre_separado = nuevo_ref.current.files[0].name.split('.');

        setDatosNuevo({
            ...datosNuevo,
            archivo: nuevo_ref.current.files[0],
            nombre: nombre_separado[0],
            extension: nombre_separado[nombre_separado.length - 1].toLowerCase()
        });
    }

    const handleNuevoArchivo = async () => {

        if(datosNuevo.id_status !== null){
            if(datosNuevo.nombre !== null && datosNuevo.nombre !== ''){
                if(datosNuevo.archivo !== null){
                    await setBotonNuevo(true);

                    let formData = new FormData();
                    formData.append('id_proyecto', datosNuevo.id_proyecto);
                    formData.append('id_status', datosNuevo.id_status);
                    formData.append('id_folder', datosNuevo.id_folder);
                    formData.append('nombre', datosNuevo.nombre);
                    formData.append('archivo', datosNuevo.archivo);
                    formData.append('extension', datosNuevo.extension);

                    await dispatch(agregarFileProyecto(formData));

                    await handleCloseNuevoArchivo();
                }else{
                    dispatch(setAlert('First load a valid file', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid name', 'danger'));
            }
        }else{
            dispatch(setAlert('Select a valid Section', 'danger'));
        }

        await setBotonNuevo(false);
    }

    const handleCloseNuevoArchivo = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            id_status: null,
            id_folder: null,
            nombre: null,
            archivo: null,
            extension: null
        });

        dispatch(externoArchivoProyecto(false));

        setSectionAddInformationData(null);
    }

    const handleViewFile = (archivo) => {
        if(archivo !== null){
            const url = URL.createObjectURL(archivo);
            window.open(url);
        }else{
            dispatch(setAlert('File not found', 'danger'));
        }
    }

    useEffect(() => {

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: detalles?.proyecto?._id,
            id_status: detalles?.proyecto?.tipo_status,
            id_folder: null,
            nombre: null,
            archivo: null,
            extension: null
        });

        if(detalles?.proyecto?.tipo_status !== null){
            setSectionAddInformationData({value: detalles?.proyecto?.tipo_status, label: detalles?.proyecto?.nombre_status})
        }

        let options = [];

        for (var i = 0; i < detalles?.proyecto?.lista_status_proyecto?.length; i++) {
            options.push({ value: detalles?.proyecto?.lista_status_proyecto[i]?.id_status, label: detalles.proyecto?.lista_status_proyecto[i]?.nombre });
        }

        setSectionsFilter(options);
    }, [detalles]);

    return (
        <Modal show={externo_archivo_proyecto} onEntered={() => { handleOpenNuevoArchivo(); }} onHide={() => { handleCloseNuevoArchivo(); }}>
            <Modal.Header closeButton>
                <Modal.Title>New file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Section:</label>
                        <Select name="id_status" isClearable={true} options={sectionsFilter} value={sectionAddInformationData} onChange={(value) => handleSelect(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Name of file:</label>
                        <input type="text" className="form-control" value={datosNuevo.nombre || ''} name="nombre" onChange={e => onChangeNuevo(e)} />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">File:</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input form-control" name="file" id="file" ref={nuevo_ref} onChange={onChangeFileNuevo} aria-describedby="view_file_file"/>
                                <label className="custom-file-label">{datosNuevo.archivo ? 'File Selected' : 'Choose file'}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevo.archivo)}><i class="fas fa-eye"></i> View File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevoArchivo()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={handleNuevoArchivo} disabled={botonNuevo}><i className="fas fa-check fa-sm"></i> Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFileProject;