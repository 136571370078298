import { BUSCAR_CATEGORIAS_INSPECCION, AGREGAR_CATEGORIA_INSPECCION, EDITAR_CATEGORIA_INSPECCION, ELIMINAR_CATEGORIA_INSPECCION } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarCategorias = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/inspection_categories`, config);
        
        dispatch({
            type: BUSCAR_CATEGORIAS_INSPECCION,
            payload: res.data.categorias
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarCategoria = (categoria) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(categoria);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/inspection_categories/nueva`, body, config);
        
        dispatch({
            type: AGREGAR_CATEGORIA_INSPECCION,
            payload: res.data.categoria
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarCategoria = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(categoria);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/inspection_categories/editar`, body, config);
        
        dispatch({
            type: EDITAR_CATEGORIA_INSPECCION,
            payload: res.data.categoria
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarCategoria = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/inspection_categories/eliminar`, body, config);
        
        dispatch({
            type: ELIMINAR_CATEGORIA_INSPECCION,
            payload:{
                id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}