import { BUSCAR_ANALISIS, DETALLES_ANALISIS, ELIMINAR_ANALISIS, BUSCAR_VALORES_ANALISIS } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarAnalisis = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer`, config);
        dispatch({
            type: BUSCAR_ANALISIS,
            payload: res.data.analisis
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAnalisis = (analisis) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(analisis);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/nuevo/`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAnalisis = (analisis) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(analisis);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/editar`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesAnalisis = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/detalles`, body, config);

        dispatch({
            type: DETALLES_ANALISIS,
            payload: res.data.analisis
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const reporteAnalisis = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(id);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/reporte`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAnalisis = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_ANALISIS,
            payload:{
                id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarValoresAnalisis = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/obtener_valores`, config);
        dispatch({
            type: BUSCAR_VALORES_ANALISIS,
            payload: res.data.valores
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarValoresAnalisis = (valores) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(valores);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/deal_analyzer/editar_valores`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}