import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

//Actions
import { setAlert } from '../../actions/alert';
import { agregarTarea, externaTarea } from '../../actions/todolist';

const ModalTask = () => {

    const dispatch = useDispatch();

    const usuarios = useSelector(state => state.usuarios);
    const { externo } = useSelector(state => state.todolist);
    const proyectos = useSelector(state => state.proyectos);
    const { secciones: listado_secciones } = useSelector(state => state.secciones_todolist);

    const [projectAddInformationData, setProjectAddInformationData] = useState(null);
    const [sectionAddInformationData, setSectionAddInformationData] = useState(null);
    const [priorityAddInformationData, setPriorityAddInformationData] = useState(null);
    const [responsableAddInformationData, setResponsableAddInformationData] = useState(null);
    const [colaboradoresAddInformationData, setColaboradoresAddInformationData] = useState(null);

    const [projectsFilter, setProjectsFilter] = useState([]);
    const [sectionsFilter, setSectionsFilter] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);

    let priorityFilter = [
        {value: 0, label: 'Low'},
        {value: 1, label: 'Medium'},
        {value: 2, label: 'High'},
        {value: 3, label: 'Urgent'}
    ];

    //Nuevo
    const [taskAddData, setTaskAddData] = useState({
        id_proyecto: null,
        id_seccion: null,
        nombre_seccion: null,
        id_responsable: null,
        ids_colaboradores: [],
        nombre: null,
        descripcion: null,
        fecha_entrega: null,
        prioridad: false,
        orden: 0,
        nota: null,
        archivos: null
    });    

    let files_ref = createRef();

    const handleOpenNewTaskModal = () => {
        if(proyectos.id_proyecto_dashboard === null){
            setProjectAddInformationData(null);
        }else{
            if(projectsFilter.length > 0){

                let proyecto_elegido = projectsFilter.filter( tipo => String(tipo.value) === String(proyectos.id_proyecto_dashboard) );

                if(proyecto_elegido){
                    setProjectAddInformationData(proyecto_elegido);
                }
            }
        }

        setTaskAddData({
            ...taskAddData,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }

    const handleSelectAddTask = (option, tipo) => {
        if(tipo === 1 || tipo === '1'){
            setProjectAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                id_proyecto: option !== null ? option.value : null
            });
        }

        if(tipo === 2 || tipo === '2'){
            setSectionAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                id_seccion: option !== null ? option.value : null
            });
        }

        if(tipo === 3 || tipo === '3'){
            setPriorityAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                prioridad: option !== null ? option.value : null
            });
        }

        if(tipo === 4 || tipo === '4'){
            setResponsableAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                id_responsable: option !== null ? option.value : null
            });
        }

        if(tipo === 5 || tipo === '5'){
            let ids_colaboradores = [];

            for (var i = 0; i < option?.length; i++) {
                ids_colaboradores.push(option[i].value);
            }

            setColaboradoresAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                ids_colaboradores: ids_colaboradores
            });
        }
    }

    const onChangeFiles = () => {
        setTaskAddData({
            ...taskAddData,
            archivos: files_ref.current.files
        });
    }

    const handleAgregarArchivosTask = () => {
        files_ref.current.click();
    }

    const handleAddTask = (method) => {

        if(taskAddData.nombre !== null && taskAddData.nombre !== ''){
            if(taskAddData.fecha_entrega !== null){
                if(taskAddData.id_responsable !== null){
                    let formData = new FormData();

                    formData.append('id_proyecto', taskAddData.id_proyecto);
                    formData.append('id_seccion', taskAddData.id_seccion);
                    formData.append('nombre_seccion', taskAddData.nombre_seccion);
                    formData.append('prioridad', taskAddData.prioridad);
                    formData.append('id_responsable', taskAddData.id_responsable);
                    formData.append('ids_colaboradores', JSON.stringify(taskAddData.ids_colaboradores));
                    formData.append('nombre', taskAddData.nombre);
                    formData.append('descripcion', taskAddData.descripcion);
                    formData.append('fecha_entrega', taskAddData.fecha_entrega);
                    formData.append('orden', taskAddData.orden);
                    formData.append('nota', taskAddData.nota);
        
                    if(taskAddData.archivos !== null){
                        for (var i = 0; i < taskAddData.archivos.length; i++) {
                            formData.append('archivos', taskAddData.archivos[i]);
                        }   
                    }
        
                    dispatch(agregarTarea(formData));
        
                    setPriorityAddInformationData(null);
                    setResponsableAddInformationData(null);
                    setColaboradoresAddInformationData(null);
        
                    if(method === 'new'){
        
                        handleClearAddTaskModal();
        
                    }else if(method === 'close'){
        
                        handleClearAddTaskModal();
                        handleCloseAddTaskModal();
        
                    }
                }else{
                    dispatch(setAlert('First assign a responsible for the task', 'danger'));
                }
            }else{
                dispatch(setAlert('First select a valid deadline', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid name for the task', 'danger'));
        }
    }

    const handleClearAddTaskModal = () => {
        setTaskAddData({
            ...taskAddData,
            id_proyecto: null,
            id_seccion: null,
            nombre_seccion: null,
            id_responsable: null,
            ids_colaboradores: [],
            nombre: null,
            descripcion: null,
            fecha_entrega: null,
            prioridad: false,
            orden: 0,
            nota: null,
            archivos: null
        });

        setProjectAddInformationData(null);
        setSectionAddInformationData(null);
        setPriorityAddInformationData(null);
        setResponsableAddInformationData(null);
        setColaboradoresAddInformationData(null);
    }

    const handleCloseAddTaskModal = () => {
        dispatch(externaTarea(false));
    }

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < listado_secciones?.length; i++) {
            options.push({ value: listado_secciones[i]._id, label: listado_secciones[i].nombre });
        }

        setSectionsFilter(options);
    }, [listado_secciones]);

    useEffect(() => {
        let options = [];

        options.push({ value: '0', label: 'Without project' });

        for (var i = 0; i < proyectos.listado?.length; i++) {
            options.push({ value: proyectos.listado[i]._id, label: proyectos.listado[i].direccion });
        }

        setProjectsFilter(options);

        if(proyectos.id_proyecto_dashboard === null){
            setProjectAddInformationData(null);
        }else{
            if(options.length > 0){

                let proyecto_elegido = options.filter( tipo => String(tipo.value) === String(proyectos.id_proyecto_dashboard) );

                if(proyecto_elegido){
                    setProjectAddInformationData(proyecto_elegido);
                }
            }
        }

        setTaskAddData({
            ...taskAddData,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }, [proyectos]);

    return (
        <Modal backdrop="static" show={externo} size="xl" onEntered={() => handleOpenNewTaskModal()} onHide={() => {
            handleClearAddTaskModal();
            handleCloseAddTaskModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>New task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Name:</label>
                        <input className="form-control" type="text" name="nombre" value={taskAddData.nombre || ''} onChange={(e) => {setTaskAddData({...taskAddData, nombre: e.target.value})}} />
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Project:</label>
                        <Select name="id_proyecto" isClearable={true} options={projectsFilter} value={projectAddInformationData} onChange={(value) => handleSelectAddTask(value, 1)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Section:</label>
                        <Select name="id_seccion" isClearable={true} options={sectionsFilter} value={sectionAddInformationData} onChange={(value) => handleSelectAddTask(value, 2)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Deadline:</label>
                        <div className="contenedor_datepicker">
                            <div className="d-flex">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-calendar-alt"></i>
                                </span>
                                <DatePicker
                                    name="fecha_entrega"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    selected={ taskAddData.fecha_entrega !== null ? new Date(taskAddData.fecha_entrega) : '' }
                                    // minDate={new Date()}
                                    onChange={ date => setTaskAddData({ ...taskAddData, fecha_entrega: date }) }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Priority:</label>
                        <Select name="prioridad" isClearable={true} options={priorityFilter} value={priorityAddInformationData} onChange={(value) => handleSelectAddTask(value, 3)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Assigned to:</label>
                        <Select name="id_responsable" isClearable={true} options={usersFilter} value={responsableAddInformationData} onChange={(value) => handleSelectAddTask(value, 4)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Collaboratos:</label>
                        <Select name="ids_colaboradores" isClearable={true} isMulti={true} options={usersFilter} value={colaboradoresAddInformationData} onChange={(value) => handleSelectAddTask(value, 5)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Observations:</label>
                        <textarea className="form-control" name="descripcion" value={taskAddData.descripcion || ''} onChange={(e) => {setTaskAddData({...taskAddData, descripcion: e.target.value})}}></textarea>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">First note:</label>
                        <div className="input-group">
                            <textarea className="form-control" name="nota" value={taskAddData.nota || ''} onChange={(e) => {setTaskAddData({...taskAddData, nota: e.target.value})}}></textarea>
                            <div className="input-group-append">
                                <button className="btn ml-2 btn-primary" type="button" onClick={() => handleAgregarArchivosTask()}><i className="fas fa-file-medical fa-sm"></i> {taskAddData.archivos !== null ? 'Change' : 'Add'} files</button>
                                <input type="file" className="d-none" name="files" id="files" ref={files_ref} onChange={onChangeFiles} multiple/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-danger" onClick={() => {handleClearAddTaskModal(); handleCloseAddTaskModal(); }}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-success" onClick={() => handleAddTask('new')}><i className="fas fa-solid fa-plus fa-sm"></i> Add task and new</button>
                <button className="btn ml-2 btn-success" onClick={() => {
                    handleAddTask('close');
                }}><i className="fas fa-solid fa-check fa-sm"></i> Add task and close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalTask;