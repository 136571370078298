import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { agregarBuyer } from '../../actions/buyers';

const NuevoBuyer = ({ history }) => {

    const dispatch = useDispatch();

    const [buyerData, setBuyerData] = useState({
        nombre: null
    });

    const [spinner, setSpinner] = useState(false);

    const onChange = (e) => {
        console.log(e.target);

        setBuyerData({
            ...buyerData, [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinner(true);
        setTimeout(async() => {
            await dispatch(agregarBuyer(buyerData));
            setSpinner(false);
            history.push('/configuration/buyers');
        }, 0);
    };

    return (
        <form className="px-3">
            <div className="row pb-5">
                <div className="col-md-3">
                    <h3 className="mb-3 mt-5">Create new buyer</h3>
                </div>

                <div className="col-md-9 mt-5">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Create</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/buyers')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </div>

            {spinner ? 
                <div className="col-12 mt-3 text-center d-flex justify-content-center">
                    <div className="sk-chase my-5">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                </div>
            :
                null
            }
            
            <div className="row">
                <div className="col-md-3 form-group">
                    <label className="font-weight-bold">Name:</label>
                    <input type="text" className="form-control" name="nombre" onChange={e => onChange(e)} value={buyerData.nombre}></input>
                </div>
            </div>
        </form>
    )
}


export default NuevoBuyer;