import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';

const SoldPropertyFlipping = ({ statusClick }) => {

    const proyectos = useSelector(state => state.proyectos);

    const [proyectoData, setProyectoData] = useState({
        id: null,
        observations_completed: null
    });

    useEffect(() => {
        setProyectoData({
            ...proyectoData,
            id: proyectos.detalles?.proyecto?._id,
            observations_completed: proyectos.masterlist?.observations_completed
        });
    }, [proyectos]);

    return (
        <Fragment>
            <div className='proyectos-content-center'>
                <div className="col-12 text-center btn-group-actions mb-3">
                    <div className="btn btn-group" role="group">
                        <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                            <button className="dropdown-item" onClick={() => statusClick()}>
                                Status list
                            </button>
                        </div>
                    </div>
                </div>

                <form className="col-md-12 pb-3" autoComplete="off">
                    <div className="col-md-12 text-center mb-3">
                        <h4>This property has already been sold.</h4>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default SoldPropertyFlipping;