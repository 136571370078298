import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { buscarUsuarios } from '../../actions/usuarios';
import { actualizarDetalles, checklistProyecto, detallesProyecto, generarReporteChecklist } from '../../actions/proyectos';

const styles = {
    planning_li: {
        display: 'grid',
        backgroundColor: '#F3F2F2'
    },
    planning_zone: {
        display: 'flex',
        alignItems: 'center'
    },
    pCard_Header: {
        backgroundColor: '#C3B392',
        fontWeight: '700',
        textTransform: 'uppercase'
    },
    sub_Utilities: {
        paddingBottom: '0',
        paddingTop: '10px'
    },
    red_zones: {
        color: 'red'
    }
}

const DueDiligenceChecklist = ({ history }) => {

    const { id } = useParams();
    
    const dispatch = useDispatch();

    const detalles = useSelector(state => state.proyectos?.detalles);
    const checklist = useSelector(state => state.proyectos?.checklist);
    const usuarios = useSelector(state => state.usuarios);
    const usuario = useSelector(state => state.auth.user);

    const zoningOptions = ["Residential", "Commercial", "Historic", "Agriculture", "Industrial", "Other"];

    const [measuringOptions, setMeasuringOptions] = useState([
        { value: 1, label: 'Inch', abbreviation: 'in' },
        { value: 2, label: 'Foot', abbreviation: 'ft' },
        { value: 3, label: 'Yard', abbreviation: 'yd' },
        { value: 4, label: 'Mile', abbreviation: 'mi' },
        { value: 5, label: 'League', abbreviation: 'le' }
    ]);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [checklistData, setChecklistData] = useState({
        back: null,
        back_unit: null,
        distance_fire_hydrant: null,
        distance_fire_hydrant_unit: null,
        distance_power_location: null,
        distance_power_location_unit: null,
        fire_improvements_required: null,
        fire_zone: null,
        flood_zone: null,
        flow_test: null,
        front: null,
        front_unit: null,
        gas_line: null,
        gas_line_location: null,
        gas_line_size: null,
        gas_line_size_unit: null,
        habitat_conservation: null,
        left: null,
        left_unit: null,
        maximum_covered_area: null,
        maximum_covered_area_unit: null,
        maximum_distance_fire_allow: null,
        maximum_height: null,
        maximum_height_unit: null,
        notas_building: null,
        notas_electric: null,
        notas_setbacks: null,
        notas_zone: null,
        notas_zoning: null,
        order_date_test: null,
        order_request_test: null,
        power_line_location: null,
        received_date_test: null,
        registro: null,
        right: null,
        right_unit: null,
        service_type_electric: null,
        water_improvements: null,
        water_improvements_required: null,
        water_line: null,
        water_line_sincrease: null,
        water_line_sincrease_unit: null,
        water_line_size: null,
        water_line_size_unit: null,
        water_meter: null,
        water_meter_size: null,
        water_meter_size_unit: null,
        zoning: null,
        water_meter_size_required: null,
        water_meter_size_required_unit: null,
        notas_fire: null,
        notas_water: null,
        notas_gas: null,
        specific_gas_location: null,
        user_order_test: null,
        digger_alert: null,
        digger_call_date: null,
        digger_scheduled_date: null,
        notas_digger: null,
        specific_water_location: null,
        scheduled_date_test: null
    });

    const [measuringData, setMeasuringData] = useState({
        front_unit: null, 
        left_unit: null, 
        back_unit: null, 
        right_unit: null, 
        maximum_height_unit: null, 
        maximum_covered_area_unit: null, 
        distance_power_location_unit: null, 
        distance_fire_hydrant_unit: null, 
        water_meter_size_required_unit: null, 
        water_meter_size_unit: null, 
        water_line_size_unit: null, 
        water_line_sincrease_unit: null, 
        gas_line_size_unit: null
    });

    const { loadingData, dataLoaded } = dataStatus;

    const handleInputs = ({ target }) => {
        setChecklistData({
            ...checklistData,
            [target.name]: target.value
        });
    }

    const handleCheckbox = ({ target }) => {
        const txt2 = target.id.split('k');

        if (parseInt(txt2[1]) % 2 === 0) {
            setChecklistData({
                ...checklistData,
                [target.name]: false
            });
        } else {
            setChecklistData({
                ...checklistData,
                [target.name]: true
            });
        }
    }

    const handleCheckboxNumber = ({ target }) => {
        const txt2 = target.id.split('k');

        if (parseInt(txt2[1]) % 2 === 0) {
            setChecklistData({
                ...checklistData,
                [target.name]: 2
            });
        } else {
            setChecklistData({
                ...checklistData,
                [target.name]: 1
            });
        }
    }

    const handleSelect = ({ target }) => {
        if (target.value !== null) {

            if (target.name === 'zoning' && target.value !== checklistData.zoning) {
                setChecklistData({
                    ...checklistData,
                    [target.name]: target.value,
                    notas_zoning: ''
                });
            } else {
                setChecklistData({
                    ...checklistData,
                    [target.name]: target.value
                });
            }
        }
    }

    const handleSelectMeasuring = (field, option) => {
        setChecklistData({
            ...checklistData,
            [field]: option !== null ? option.value : null
        });

        setMeasuringData({
            ...measuringData,
            [field]: option
        });
    }

    /* Volver */
    const handleGoBack = () => {
        history.goBack();
    }

    /* Reporte */
    const handleGenerateReporte = () => {
        dispatch(generarReporteChecklist(id));
    }

    /* Guardar */
    const handleSubmit = async () => {
        await dispatch(actualizarDetalles(checklistData));
        //await history.push('/projects/buySale/' + id);
    }

    useEffect(() => {
        dispatch(buscarUsuarios());
        dispatch(detallesProyecto(id));
        dispatch(checklistProyecto(id));

        if (loadingData === false) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if(checklist){
            setChecklistData({
                back: checklist?.back,
                back_unit: checklist?.back_unit,
                check_verify_zone: checklist?.check_verify_zone,
                distance_fire_hydrant: checklist?.distance_fire_hydrant,
                distance_fire_hydrant_unit: checklist?.distance_fire_hydrant_unit,
                distance_power_location: checklist?.distance_power_location,
                distance_power_location_unit: checklist?.distance_power_location_unit,
                fire_improvements_required: checklist?.fire_improvements_required,
                fire_zone: checklist?.fire_zone,
                flood_zone: checklist?.flood_zone,
                flow_test: checklist?.flow_test,
                front: checklist?.front,
                front_unit: checklist?.front_unit,
                gas_line: checklist?.gas_line,
                gas_line_location: checklist?.gas_line_location,
                gas_line_size: checklist?.gas_line_size,
                gas_line_size_unit: checklist?.gas_line_size_unit,
                habitat_conservation: checklist?.habitat_conservation,
                left: checklist?.left,
                left_unit: checklist?.left_unit,
                maximum_covered_area: checklist?.maximum_covered_area,
                maximum_covered_area_unit: checklist?.maximum_covered_area_unit,
                maximum_distance_fire_allow: checklist?.maximum_distance_fire_allow,
                maximum_height: checklist?.maximum_height,
                maximum_height_unit: checklist?.maximum_height_unit,
                notas_building: checklist?.notas_building,
                notas_electric: checklist?.notas_electric,
                notas_setbacks: checklist?.notas_setbacks,
                notas_zone: checklist?.notas_zone,
                notas_zoning: checklist?.notas_zoning,
                order_date_test: checklist?.order_date_test,
                order_request_test: checklist?.order_request_test,
                power_line_location: checklist?.power_line_location,
                received_date_test: checklist?.received_date_test,
                registro: checklist?.registro,
                right: checklist?.right,
                right_unit: checklist?.right_unit,
                service_type_electric: checklist?.service_type_electric,
                water_improvements: checklist?.water_improvements,
                water_improvements_required: checklist?.water_improvements_required,
                water_line: checklist?.water_line,
                water_line_sincrease: checklist?.water_line_sincrease,
                water_line_sincrease_unit: checklist?.water_line_sincrease_unit,
                water_line_size: checklist?.water_line_size,
                water_line_size_unit: checklist?.water_line_size_unit,
                water_meter: checklist?.water_meter,
                water_meter_size: checklist?.water_meter_size,
                water_meter_size_unit: checklist?.water_meter_size_unit,
                zoning: checklist?.zoning,
                water_meter_size_required: checklist?.water_meter_size_required,
                water_meter_size_required_unit: checklist?.water_meter_size_required_unit,
                notas_fire: checklist?.notas_fire,
                notas_water: checklist?.notas_water,
                notas_gas: checklist?.notas_gas,
                specific_gas_location: checklist?.specific_gas_location,
                id_proyecto: checklist?.id_proyecto,
                user_order_test: checklist?.user_order_test,
                digger_alert: checklist?.digger_alert,
                digger_call_date: checklist?.digger_call_date,
                digger_scheduled_date: checklist?.digger_scheduled_date,
                notas_digger: checklist?.notas_digger,
                specific_water_location: checklist?.specific_water_location,
                scheduled_date_test: checklist?.scheduled_date_test
            });

            setMeasuringData({
                ...measuringData, 
                front_unit: checklist?.front_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.front_unit) ) : null,
                left_unit: checklist?.left_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.left_unit) ) : null,
                back_unit: checklist?.back_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.back_unit) ) : null,
                right_unit: checklist?.right_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.right_unit) ) : null, 
                maximum_height_unit: checklist?.maximum_height_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.maximum_height_unit) ) : null, 
                maximum_covered_area_unit: checklist?.maximum_covered_area_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.maximum_covered_area_unit) ) : null, 
                distance_power_location_unit: checklist?.distance_power_location_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.distance_power_location_unit) ) : null, 
                distance_fire_hydrant_unit: checklist?.distance_fire_hydrant_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.distance_fire_hydrant_unit) ) : null, 
                water_meter_size_required_unit: checklist?.water_meter_size_required_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.water_meter_size_required_unit) ) : null, 
                water_meter_size_unit: checklist?.water_meter_size_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.water_meter_size_unit) ) : null, 
                water_line_size_unit: checklist?.water_line_size_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.water_line_size_unit) ) : null, 
                water_line_sincrease_unit: checklist?.water_line_sincrease_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.water_line_sincrease_unit) ) : null, 
                gas_line_size_unit: checklist?.gas_line_size_unit !== null ? measuringOptions.filter( medida => String(medida.value) === String(checklist?.gas_line_size_unit) ) : null
            });

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [checklist]);

    return (
        
        <div className="row">
            <div className="col-md-8 offset-md-2 row pt-5 px-0">
                <div className="col-md-5">
                    <h3 className="mb-1">Due Diligence Checklist</h3>
                </div>
                
                <div className="col-md-7 btn-project-information mb-3 mt-5">
                    <button type="button" className="btn btn-secondary float-right ml-2" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={() => handleGenerateReporte()}><i className="fas fa-chart-bar fa-sm"></i> Generate report</button>
                    <button type="button" className="btn btn-success float-right" onClick={handleSubmit}><i className="fas fa-check fa-sm"></i> Save</button>
                </div>
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <form className="col-md-8 offset-md-2">

                            <div className="row my-4 form-group">
                                <label className="col-lg-4 col-sm-12">
                                    <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                </label>

                                <label className="col-lg-4 col-sm-12">
                                    <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                </label>

                                <div className="col-lg-4 col-sm-12">
                                    <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                </div>

                                <label className="col-lg-8 col-sm-12">
                                    <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + detalles?.proyecto?.latitud + '%2C' + detalles?.proyecto?.longitud} target="_blank">{detalles?.proyecto?.direccion}</a>
                                </label>
                            </div>

                            <div id="accordion_planning" className="card form-group" style={{ width: '100%' }}>
                                <div className="card-header" style={styles.pCard_Header} data-toggle="collapse" data-target="#collapsePlanning" aria-expanded="true" aria-controls="collapsePlanning">
                                    Planning
                                </div>

                                <ul id="collapsePlanning" className="list-group list-group-flush collapse show" aria-labelledby="headingOne" data-parent="#accordion_planning">
                                    <li className="list-group-item" style={styles.planning_li}>
                                        <label className="font-weight-bold">Zoning:</label>
                                        <select name="zoning" id="s-zoning" className="form-control" onChange={handleSelect} value={checklistData.zoning || 0}>
                                            <option key={0} value={null}>
                                                Choose the zoning
                                            </option>
                                            {
                                                zoningOptions.map((zone, i) => {
                                                    return (
                                                        <option key={i} name={zone} value={i + 1}>
                                                            {zone}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </li>

                                    {
                                        (checklistData.zoning !== null && checklistData.zoning === '6') ?
                                            <li className="list-group-item" style={styles.planning_li}>
                                                <div id="accordion">
                                                    <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseZoning" aria-expanded="true" aria-controls="collapseZoning">
                                                        Explain in the notes:
                                                    </button>

                                                    <div id="collapseZoning" className={checklistData.notas_zoning !== '' && checklistData.notas_zoning !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                                                        <textarea className='form-control' name="notas_zoning" onChange={e => handleInputs(e)} value={checklistData.notas_zoning || ''}></textarea>
                                                    </div>
                                                </div>
                                            </li>
                                            :
                                            <li className="list-group-item" style={styles.planning_li}>
                                                <div id="accordion">
                                                    <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseZoning" aria-expanded="true" aria-controls="collapseZoning">
                                                        Subtypes and notes:
                                                    </button>

                                                    <div id="collapseZoning" className={checklistData.notas_zoning !== '' && checklistData.notas_zoning !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                                                        <textarea className='form-control' name="notas_zoning" onChange={e => handleInputs(e)} value={checklistData.notas_zoning || ''}></textarea>
                                                    </div>
                                                </div>
                                            </li>
                                    }

                                    <li className="list-group-item" style={styles.planning_li}>
                                        <label className="font-weight-bold" style={{ textTransform: 'uppercase' }}>Setbacks</label>

                                        <div className="row form-group mt-2">
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Front:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="front" onChange={e => handleInputs(e)} value={checklistData.front || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="front_unit" options={measuringOptions} value={measuringData.front_unit} onChange={(value) => handleSelectMeasuring('front_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Back:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="back" onChange={e => handleInputs(e)} value={checklistData.back || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="back_unit" options={measuringOptions} value={measuringData.back_unit} onChange={(value) => handleSelectMeasuring('back_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Right:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="right" onChange={e => handleInputs(e)} value={checklistData.right || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="right_unit" options={measuringOptions} value={measuringData.right_unit} onChange={(value) => handleSelectMeasuring('right_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Left:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="left" onChange={e => handleInputs(e)} value={checklistData.left || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="left_unit" options={measuringOptions} value={measuringData.left_unit} onChange={(value) => handleSelectMeasuring('left_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Max. Height:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="maximum_height" onChange={e => handleInputs(e)} value={checklistData.maximum_height || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="maximum_height_unit" options={measuringOptions} value={measuringData.maximum_height_unit} onChange={(value) => handleSelectMeasuring('maximum_height_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 form-group">
                                                <label className="font-weight-bold">Max. Covered Area:</label>
                                                <div className="input-group">
                                                    <input autoComplete='off' type="text" className="form-control" name="maximum_covered_area" onChange={e => handleInputs(e)} value={checklistData.maximum_covered_area || ''}></input>
                                                    <div className="input-group-prepend">
                                                        <Select name="maximum_covered_area_unit" options={measuringOptions} value={measuringData.maximum_covered_area_unit} onChange={(value) => handleSelectMeasuring('maximum_covered_area_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div id="accordion2">
                                                    <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseSetbacks" aria-expanded="true" aria-controls="collapseSetbacks">
                                                        Notes:
                                                </button>

                                                    <div id="collapseSetbacks" className={checklistData.notas_setbacks !== '' && checklistData.notas_setbacks !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion2">
                                                        <textarea className='form-control' name="notas_setbacks" onChange={e => handleInputs(e)} value={checklistData.notas_setbacks || ''}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </li>
                                    <li className="list-group-item" style={styles.planning_li}>
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-12 form-group">
                                                <label className="font-weight-bold" style={checklistData.fire_zone === true ? styles.red_zones : {}}>Fire Zone:</label>
                                                <div style={styles.planning_zone}>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="check1" name="fire_zone" onChange={e => handleCheckbox(e)} checked={checklistData.fire_zone === true ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check1">Yes</label>
                                                    </div>
                                                    <div className="form-check ml-3">
                                                        <input type="checkbox" className="form-check-input" id="check2" name="fire_zone" onChange={e => handleCheckbox(e)} checked={checklistData.fire_zone === false ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 form-group">
                                                <label className="font-weight-bold" style={checklistData.flood_zone === true ? styles.red_zones : {}}>Flood Zone:</label>
                                                <div style={styles.planning_zone}>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="check3" name="flood_zone" onChange={e => handleCheckbox(e)} checked={checklistData.flood_zone === true ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check3">Yes</label>
                                                    </div>
                                                    <div className="form-check ml-3">
                                                        <input type="checkbox" className="form-check-input" id="check4" name="flood_zone" onChange={e => handleCheckbox(e)} checked={checklistData.flood_zone === false ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check4">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 form-group">
                                                <label className="font-weight-bold" style={checklistData.habitat_conservation === true ? styles.red_zones : {}}>Habitat conservation:</label>
                                                <div style={styles.planning_zone}>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="check5" name="habitat_conservation" onChange={e => handleCheckbox(e)} checked={checklistData.habitat_conservation === true ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check5">Yes</label>
                                                    </div>
                                                    <div className="form-check ml-3">
                                                        <input type="checkbox" className="form-check-input" id="check6" name="habitat_conservation" onChange={e => handleCheckbox(e)} checked={checklistData.habitat_conservation === false ? true : false}></input>
                                                        <label className="form-check-label" htmlFor="check6">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div id="accordion8">
                                                    <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseZ" aria-expanded="true" aria-controls="collapseZ">
                                                        Notes:
                                                </button>

                                                    <div id="collapseZ" className={checklistData.notas_zone !== '' && checklistData.notas_zone !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion8">
                                                        <textarea className='form-control' name="notas_zone" onChange={handleInputs} value={checklistData.notas_zone || ''}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item" style={styles.planning_li}>
                                        <label className="font-weight-bold" style={{ textTransform: 'uppercase' }}>Building requirements</label>

                                        <div id="accordion3">
                                            <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseBuilding" aria-expanded="true" aria-controls="collapseBuilding">
                                                Notes:
                                        </button>

                                            <div id="collapseBuilding" className={checklistData.notas_building !== '' && checklistData.notas_building !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion3">
                                                <textarea className='form-control' name="notas_building" onChange={handleInputs} value={checklistData.notas_building || ''}></textarea>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item" style={styles.planning_li}>
                                        <label><span className="font-weight-bold mr-3">Checked by:</span> {checklistData.registro.length > 0 ? checklistData.registro[checklistData.registro.length - 1].id_usuario?.nombre : usuario?.nombre} </label>
                                    </li>
                                </ul>
                            </div>

                            <div id="accordion_utilities" className="card form-group mt-2 w-100">
                                <div className="card-header" style={styles.pCard_Header} data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                                    Utilities
                                </div>

                                <ul id="collapseUtilities" className="list-group list-group-flush collapse" aria-labelledby="headingOne" data-parent="#accordion_utilities">
                                    
                                    <li data-toggle="collapse" data-target="#collapseDigger" aria-expanded="true" aria-controls="collapseDigger" className="list-group-item text-center" style={Object.assign({}, styles.sub_Utilities, { backgroundColor: '#795548' })}>
                                        <label className="font-weight-bold" style={{ color: 'white' }}>Digger Alert</label>
                                    </li>
                                    <div id="collapseDigger" className="collapse" aria-labelledby="headingOne" data-parent="#collapseUtilities">
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Has it been scheduled?</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check25" name="digger_alert" onChange={e => handleCheckbox(e)} checked={checklistData.digger_alert === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check25">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check26" name="digger_alert" onChange={e => handleCheckbox(e)} checked={checklistData.digger_alert === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check26">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    checklistData.digger_alert === true ?
                                                        <>
                                                            <div className="col-lg-4 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Call date:</label>
                                                                <div style={styles.planning_zone}>
                                                                    <DatePicker className="form-control text-center" selected={checklistData.digger_call_date ? new Date(checklistData.digger_call_date) : ''} onChange={date => setChecklistData({ ...checklistData, digger_call_date: date })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Scheduled date:</label>
                                                                <div style={styles.planning_zone}>
                                                                    <DatePicker className="form-control text-center" selected={checklistData.digger_scheduled_date ? new Date(checklistData.digger_scheduled_date) : ''} onChange={date => setChecklistData({ ...checklistData, digger_scheduled_date: date })} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </li>
                                        
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div id="accordion9">
                                                <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseDiggerNotes" aria-expanded="true" aria-controls="collapseDiggerNotes">
                                                    Notes:
                                                </button>

                                                <div id="collapseDiggerNotes" className={checklistData.notas_digger !== '' && checklistData.notas_digger !== null && checklistData.notas_digger !== undefined ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#collapseDigger">
                                                    <textarea className='form-control' name="notas_digger" onChange={handleInputs} value={checklistData.notas_digger || ''}></textarea>
                                                </div>
                                            </div>
                                        </li>
                                    </div>

                                    <li data-toggle="collapse" data-target="#collapseElectric" aria-expanded="true" aria-controls="collapseElectric" className="list-group-item text-center" style={Object.assign({}, styles.sub_Utilities, { backgroundColor: '#FFD966' })}>
                                        <label className="font-weight-bold">Electric</label>
                                    </li>
                                    <div id="collapseElectric" className="collapse" aria-labelledby="headingOne" data-parent="#collapseUtilities">
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Power line location:</label>
                                                    <select name="power_line_location" id="s-powerLine" className="form-control" onChange={e => handleSelect(e)} value={checklistData.power_line_location || 0}>
                                                        <option key={0} value={null}>
                                                            Choose one option
                                                    </option>
                                                        <option key={1} value={1}>
                                                            Front
                                                    </option>
                                                        <option key={2} value={2}>
                                                            Back
                                                    </option>
                                                        <option key={4} value={4}>
                                                            Right
                                                    </option>
                                                        <option key={3} value={3}>
                                                            Left
                                                    </option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Distance:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" name="distance_power_location" onChange={handleInputs} value={checklistData.distance_power_location || ''}></input>
                                                        <div className="input-group-prepend">
                                                            <Select name="distance_power_location_unit" options={measuringOptions} value={measuringData.distance_power_location_unit} onChange={(value) => handleSelectMeasuring('distance_power_location_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Service type:</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="overhead_k1" name="service_type_electric" onChange={e => handleCheckboxNumber(e)} checked={checklistData.service_type_electric === 1 ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="overhead_k1">Overhead</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="overhead_k2" name="service_type_electric" onChange={e => handleCheckboxNumber(e)} checked={checklistData.service_type_electric === 2 ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="overhead_k2">Underground</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div id="accordion4">
                                                <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseElectricNotes" aria-expanded="true" aria-controls="collapseElectricNotes">
                                                    Notes:
                                                </button>

                                                <div id="collapseElectricNotes" className={checklistData.notas_electric !== '' && checklistData.notas_electric !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#collapseElectric">
                                                    <textarea className="form-control" name="notas_electric" onChange={handleInputs} value={checklistData.notas_electric || ''}></textarea>
                                                </div>
                                            </div>
                                        </li>
                                    </div>

                                    <li data-toggle="collapse" data-target="#collapseWater" aria-expanded="true" aria-controls="collapseWater" className="list-group-item text-center" style={Object.assign({}, styles.sub_Utilities, { backgroundColor: '#99C0E4' })}>
                                        <label className="font-weight-bold">Water Department</label>
                                    </li>
                                    <div id="collapseWater" className="collapse" aria-labelledby="headingOne" data-parent="#collapseUtilities">
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div className="row mt-3">
                                                <div className="col-lg-6 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Existing water meter?</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check13" name="water_meter" onChange={e => handleCheckbox(e)} checked={checklistData.water_meter === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check13">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check14" name="water_meter" onChange={e => handleCheckbox(e)} checked={checklistData.water_meter === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check14">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    checklistData.water_meter === true ?
                                                        <div className="col-md-6 form-group">
                                                            <label className="font-weight-bold">Size:</label>
                                                            <div className="input-group">
                                                                <input autoComplete='off' type="text" className="form-control" name="water_meter_size" onChange={handleInputs} value={checklistData.water_meter_size || ''}></input>
                                                                <div className="input-group-prepend">
                                                                    <Select name="water_meter_size_unit" options={measuringOptions} value={measuringData.water_meter_size_unit} onChange={(value) => handleSelectMeasuring('water_meter_size_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                                <div className="col-lg-6 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Any improvements required?:</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check15" name="water_improvements_required" onChange={e => handleCheckbox(e)} checked={checklistData.water_improvements_required === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check15">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check16" name="water_improvements_required" onChange={e => handleCheckbox(e)} checked={checklistData.water_improvements_required === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check16">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    checklistData.water_improvements_required === true ?
                                                        <>
                                                            <div className="col-lg-6 col-sm-12 form-group">
                                                                <label className="font-weight-bold mr-3">Type of improvements:</label>
                                                                <input autoComplete='off' type="text" className="form-control" name="water_improvements" onChange={handleInputs} value={checklistData.water_improvements || ''}></input>
                                                            </div>
                                                            <div className="col-lg-3 col-sm-12 form-group">
                                                                <label className="font-weight-bold mr-3" style={{ width: '170px' }}>Street water line?</label>
                                                                <div style={styles.planning_zone}>
                                                                    <div className="form-check">
                                                                        <input type="checkbox" className="form-check-input" id="check17" name="water_line" onChange={e => handleCheckbox(e)} checked={checklistData.water_line === true ? true : false}></input>
                                                                        <label className="form-check-label" htmlFor="check17">Yes</label>
                                                                    </div>
                                                                    <div className="form-check ml-3">
                                                                        <input type="checkbox" className="form-check-input" id="check18" name="water_line" onChange={e => handleCheckbox(e)} checked={checklistData.water_line === false ? true : false}></input>
                                                                        <label className="form-check-label" htmlFor="check18">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    checklistData.water_improvements_required === true && checklistData.water_line === true ?
                                                        <div className="col-lg-3 col-sm-12 form-group">
                                                            <label className="font-weight-bold mr-3" style={{ width: '170px' }}>Specific water line location?</label>
                                                            <div style={styles.planning_zone}>
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="check27" name="specific_water_location" onChange={e => handleCheckbox(e)} checked={checklistData.specific_water_location === true ? true : false}></input>
                                                                    <label className="form-check-label" htmlFor="check27">On side</label>
                                                                </div>
                                                                <div className="form-check ml-3">
                                                                    <input type="checkbox" className="form-check-input" id="check28" name="specific_water_location" onChange={e => handleCheckbox(e)} checked={checklistData.specific_water_location === false ? true : false}></input>
                                                                    <label className="form-check-label" htmlFor="check28">Across the street</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    checklistData.water_line === true && checklistData.water_improvements_required === true ?
                                                        <>
                                                            <div className="col-md-3 form-group">
                                                                <label className="font-weight-bold mr-3">Size:</label>
                                                                <div className="input-group">
                                                                    <input autoComplete='off' type="text" className="form-control" name="water_line_size" onChange={handleInputs} value={checklistData.water_line_size || ''}></input>
                                                                    <div className="input-group-prepend">
                                                                        <Select name="water_line_size_unit" options={measuringOptions} value={measuringData.water_line_size_unit} onChange={(value) => handleSelectMeasuring('water_line_size_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 form-group">
                                                                <label className="font-weight-bold mr-3">Increase to:</label>
                                                                <div className="input-group">
                                                                    <input autoComplete='off' type="text" className="form-control" name="water_line_sincrease" onChange={handleInputs} value={checklistData.water_line_sincrease || ''}></input>
                                                                    <div className="input-group-prepend">
                                                                        <Select name="water_line_sincrease_unit" options={measuringOptions} value={measuringData.water_line_sincrease_unit} onChange={(value) => handleSelectMeasuring('water_line_sincrease_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </li>
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div id="accordion6">
                                                <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseWaterNotes" aria-expanded="true" aria-controls="collapseWaterNotes">
                                                    Notes:
                                                </button>

                                                <div id="collapseWaterNotes" className={checklistData.notas_water !== '' && checklistData.notas_water !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#collapseWater">
                                                    <textarea className="form-control" name="notas_water" onChange={handleInputs} value={checklistData.notas_water || ''}></textarea>
                                                </div>
                                            </div>
                                        </li>
                                    </div>

                                    <li data-toggle="collapse" data-target="#collapseFire" aria-expanded="true" aria-controls="collapseFire" className="list-group-item text-center" style={Object.assign({}, styles.sub_Utilities, { backgroundColor: '#FF6969' })}>
                                        <label className="font-weight-bold">Fire Department</label>
                                    </li>
                                    <div id="collapseFire" className="collapse" aria-labelledby="headingOne" data-parent="#collapseUtilities">
                                        <li className="list-group-item" style={styles.planning_li}>

                                            <div className="row">
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Fire hydrant distance:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" name="distance_fire_hydrant" onChange={handleInputs} value={checklistData.distance_fire_hydrant || ''}></input>
                                                        <div className="input-group-prepend">
                                                            <Select name="distance_fire_hydrant_unit" options={measuringOptions} value={measuringData.distance_fire_hydrant_unit} onChange={(value) => handleSelectMeasuring('distance_fire_hydrant_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Flow test/pressure records if any:</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check9" name="flow_test" onChange={e => handleCheckbox(e)} checked={checklistData.flow_test === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check9">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check10" name="flow_test" onChange={e => handleCheckbox(e)} checked={checklistData.flow_test === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check10">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Maximum distance allow:</label>
                                                    <input autoComplete='off' type="text" className="form-control" value={checklistData.maximum_distance_fire_allow || ''} disabled></input>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                {
                                                    checklistData.flow_test === true ?
                                                        <div className="col-lg-4 col-sm-12 form-group">
                                                            <label className="font-weight-bold">Received date:</label>
                                                            <div style={styles.planning_zone}>
                                                                <DatePicker className="form-control text-center" selected={checklistData.received_date_test ? new Date(checklistData.received_date_test) : ''} onChange={date => setChecklistData({ ...checklistData, received_date_test: date })} />
                                                            </div>
                                                        </div>
                                                    :
                                                        checklistData.flow_test === false ?
                                                            <div className="col-lg-3 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Order request:</label>
                                                                <div style={styles.planning_zone}>
                                                                    <div className="form-check">
                                                                        <input type="checkbox" className="form-check-input" id="check21" name="order_request_test" onChange={e => handleCheckbox(e)} checked={checklistData.order_request_test === true ? true : false}></input>
                                                                        <label className="form-check-label" htmlFor="check21">Yes</label>
                                                                    </div>
                                                                    <div className="form-check ml-3">
                                                                        <input type="checkbox" className="form-check-input" id="check22" name="order_request_test" onChange={e => handleCheckbox(e)} checked={checklistData.order_request_test === false ? true : false}></input>
                                                                        <label className="form-check-label" htmlFor="check22">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                }
                                                {
                                                    checklistData.order_request_test === true && checklistData.order_request_test !== null && checklistData.flow_test === false ?
                                                        <>
                                                            <div className="col-lg-3 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Order date:</label>
                                                                <div style={styles.planning_zone}>
                                                                    <DatePicker className="form-control text-center" selected={checklistData.order_date_test ? new Date(checklistData.order_date_test) : ''} onChange={date => setChecklistData({ ...checklistData, order_date_test: date })} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Scheduled date:</label>
                                                                <div style={styles.planning_zone}>
                                                                    <DatePicker className="form-control text-center" selected={checklistData.scheduled_date_test ? new Date(checklistData.scheduled_date_test) : ''} onChange={date => setChecklistData({ ...checklistData, scheduled_date_test: date })} />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-sm-12 form-group">
                                                                <label className="font-weight-bold">Who ordered?</label>
                                                                <select name="user_order_test" id="s-userOrderTest" className="form-control" onChange={e => handleSelect(e)} value={checklistData.user_order_test || 0}>
                                                                    <option key={0} value={null}>
                                                                        Choose one option
                                                                </option>
                                                                    {
                                                                        usuarios ? usuarios.map((usuario, i) => {
                                                                            return (
                                                                                <option key={i + 1} value={usuario._id}>
                                                                                    { usuario.nombre}
                                                                                </option>
                                                                            )
                                                                        }) : <></>
                                                                    }


                                                                </select>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </li>

                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div className="row mt-3">
                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold mr-3">Water meter size required:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" name="water_meter_size_required" onChange={handleInputs} value={checklistData.water_meter_size_required || ''}></input>
                                                        <div className="input-group-prepend">
                                                            <Select name="water_meter_size_required_unit" options={measuringOptions} value={measuringData.water_meter_size_required_unit} onChange={(value) => handleSelectMeasuring('water_meter_size_required_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold">Any improvements required:</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check11" name="fire_improvements_required" onChange={e => handleCheckbox(e)} checked={checklistData.fire_improvements_required === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check11">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check12" name="fire_improvements_required" onChange={e => handleCheckbox(e)} checked={checklistData.fire_improvements_required === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check12">No</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-12 form-group">
                                                    <label className="font-weight-bold mr-3">Water meter size:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" disabled="disabled" value={checklistData.water_meter_size || ''}></input>
                                                        <div className="input-group-prepend">
                                                            <Select name="water_meter_size_unit" options={measuringOptions} value={measuringData.water_meter_size_unit} onChange={(value) => handleSelectMeasuring('water_meter_size_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 form-group mt-3">
                                                    <div id="accordion5">
                                                        <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseFireNotes" aria-expanded="true" aria-controls="collapseFireNotes">
                                                            Notes:
                                                        </button>

                                                        <div id="collapseFireNotes" className={checklistData.notas_fire !== '' && checklistData.notas_fire !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#collapseFire">
                                                            <textarea className='form-control' name="notas_fire" onChange={handleInputs} value={checklistData.notas_fire || ''}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </div>

                                    <li data-toggle="collapse" data-target="#collapseGas" aria-expanded="false" aria-controls="collapseGas" className="list-group-item text-center" style={Object.assign({}, styles.sub_Utilities, { backgroundColor: '#A9D08E' })}>
                                        <label className="font-weight-bold">Gas</label>
                                    </li>
                                    <div id="collapseGas" className="collapse" aria-labelledby="headingOne" data-parent="#collapseUtilities">
                                        <li className="list-group-item" style={styles.planning_li}>
                                            <div className="row mt-3">
                                                <div className="col-lg-3 col-sm-12 form-group">
                                                    <label className="font-weight-bold mr-3">Existing gas line?</label>
                                                    <div style={styles.planning_zone}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="check19" name="gas_line" onChange={e => handleCheckbox(e)} checked={checklistData.gas_line === true ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check19">Yes</label>
                                                        </div>
                                                        <div className="form-check ml-3">
                                                            <input type="checkbox" className="form-check-input" id="check20" name="gas_line" onChange={e => handleCheckbox(e)} checked={checklistData.gas_line === false ? true : false}></input>
                                                            <label className="form-check-label" htmlFor="check20">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    checklistData.gas_line === true ?
                                                        <div className="col-md-3 form-group">
                                                            <label className="font-weight-bold mr-3">Gas line location:</label>
                                                            <select id="s-gasLine" className="form-control" name="gas_line_location" onChange={handleSelect} value={checklistData.gas_line_location || 0}>
                                                                <option key="0" value={null}>Select one option</option>
                                                                <option key="1" value={1}>Front</option>
                                                                <option key="2" value={2}>Back</option>
                                                                <option key="4" value={3}>Right</option>
                                                                <option key="3" value={4}>Left</option>
                                                            </select>
                                                        </div>
                                                    :
                                                        <></>
                                                }

                                                {
                                                    checklistData.gas_line === true && parseInt(checklistData.gas_line_location) === 1 ?
                                                        <div className="col-lg-3 col-sm-12 form-group" >
                                                            <label className="font-weight-bold mr-3">Specific gas location:</label>
                                                            <div style={styles.planning_zone}>
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="check23" name="specific_gas_location" onChange={e => handleCheckbox(e)} checked={checklistData.specific_gas_location === true ? true : false}></input>
                                                                    <label className="form-check-label" htmlFor="check23">On side</label>
                                                                </div>
                                                                <div className="form-check ml-3">
                                                                    <input type="checkbox" className="form-check-input" id="check24" name="specific_gas_location" onChange={e => handleCheckbox(e)} checked={checklistData.specific_gas_location === false ? true : false}></input>
                                                                    <label className="form-check-label" htmlFor="check24">Across the street</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                }

                                                {
                                                    checklistData.gas_line === true ?
                                                        <div className="col-md-3 form-group">
                                                            <label className="font-weight-bold mr-3">Distance:</label>
                                                            <div className="input-group">
                                                                <input autoComplete='off' type="text" className="form-control" name="gas_line_size" onChange={handleInputs} value={checklistData.gas_line_size || ''}></input>
                                                                <div className="input-group-prepend">
                                                                    <Select name="gas_line_size_unit" options={measuringOptions} value={measuringData.gas_line_size_unit} onChange={(value) => handleSelectMeasuring('gas_line_size_unit', value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </li>
                                        <li className="list-group-item " style={styles.planning_li}>
                                            <div id="accordion7">
                                                <button type="button" className="btn btn-light form-control text-left font-weight-bold" data-toggle="collapse" data-target="#collapseGasNotes" aria-expanded="true" aria-controls="collapseGasNotes">
                                                    Notes:
                                                </button>

                                                <div id="collapseGasNotes" className={checklistData.notas_gas !== '' && checklistData.notas_gas !== null ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#collapseGas">
                                                    <textarea className="form-control" name="notas_gas" onChange={handleInputs} value={checklistData.notas_gas || ''}></textarea>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </form>
                    :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center">
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default DueDiligenceChecklist;