import { OBTENER_DATOS_DEUDAS, OBTENER_PROVEEDORES, DETALLES_PROVEEDOR, ELIMINAR_PROVEEDOR, AGREGAR_PAGO_PROVEEDOR, EDITAR_PAGO_PROVEEDOR, DETALLES_PAGO_PROVEEDOR, DETALLES_ARCHIVO_PAGO_PROVEEDOR, ELIMINAR_PAGO_PROVEEDOR, AGREGAR_DEUDA_PROVEEDOR, EDITAR_DEUDA_PROVEEDOR, DETALLES_DEUDA_PROVEEDOR, DETALLES_ARCHIVO_DEUDA_PROVEEDOR, ELIMINAR_DEUDA_PROVEEDOR } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const obtenerDatos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/bills`, config);

        dispatch({
            type: OBTENER_DATOS_DEUDAS,
            payload: res.data.datos
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerDeudasProveedor = (id, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/deudas-proveedor`, body, config);

        dispatch({
            type: OBTENER_DATOS_DEUDAS,
            payload: {
                ...datos, 
                deudas_proveedor: res.data.deudas_proveedor
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerBalancesProveedor = (periodo, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ periodo });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/balances-proveedor`, body, config);

        dispatch({
            type: OBTENER_DATOS_DEUDAS,
            payload: {
                ...datos, 
                balances_proveedor: res.data.proveedores
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const obtenerPeriodoMovimientosProveedor = (periodo, datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = JSON.stringify({ periodo });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/periodo-movimientos-proveedor`, body, config);

        dispatch({
            type: OBTENER_DATOS_DEUDAS,
            payload: {
                ...datos, 
                periodo_movimientos_proveedor: res.data.periodos
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarProveedores = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/bills/proveedores`, config);

        dispatch({
            type: OBTENER_PROVEEDORES,
            payload: {
                proveedores_deuda: res.data.proveedores_deuda, 
                proveedores_libres: res.data.proveedores_libres
            }
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarProveedor = (proveedor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proveedor);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/nuevo-proveedor`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesProveedor = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ id });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/detalles-proveedor`, body, config);

            dispatch({
                type: DETALLES_PROVEEDOR,
                payload: res.data.proveedor
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_PROVEEDOR,
            payload: null
        });
    }
}

export const editarProveedor = (proveedor) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    console.log(proveedor);

    const body = JSON.stringify(proveedor);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/editar-proveedor`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarProveedor = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/eliminar-proveedor`, body, config);
        dispatch({
            type: ELIMINAR_PROVEEDOR,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Deudas del proveedor */

export const agregarDeudaProveedor = (deuda) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/nueva-deuda`, deuda, config);

        dispatch({
            type: AGREGAR_DEUDA_PROVEEDOR,
            payload: res.data.deuda
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesDeudaProveedor = (deuda) => async dispatch => {
    if(deuda){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(deuda);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/detalles-deuda`, body, config);

            dispatch({
                type: DETALLES_DEUDA_PROVEEDOR,
                payload: res.data.deuda
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_DEUDA_PROVEEDOR,
            payload: null
        });
    }
}

export const detallesFileDeudaProveedor = (id_proveedor, id_deuda) => async dispatch => {
    if(id_proveedor){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id_proveedor, 
            id_deuda
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/detalles-archivo-deuda`, body, config);

            dispatch({
                type: DETALLES_ARCHIVO_DEUDA_PROVEEDOR,
                payload: res.data.archivo
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_ARCHIVO_DEUDA_PROVEEDOR,
            payload: null
        });
    }
}

export const editarDeudaProveedor = (deuda) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/editar-deuda`, deuda, config);

        dispatch({
            type: EDITAR_DEUDA_PROVEEDOR,
            payload: res.data.deuda
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarDeudaProveedor = (deuda) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(deuda);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/eliminar-deuda`, body, config);

        dispatch({
            type: ELIMINAR_DEUDA_PROVEEDOR,
            payload:{
                id: deuda.id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

/* Pagos de proveedor */

export const agregarPagoProveedor = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/nuevo-pago`, body, config);

        dispatch({
            type: AGREGAR_PAGO_PROVEEDOR,
            payload: res.data.pago
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesPagoProveedor = (pago) => async dispatch => {
    if(pago){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify(pago);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/detalles-pago`, body, config);

            dispatch({
                type: DETALLES_PAGO_PROVEEDOR,
                payload: res.data.pago
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_PAGO_PROVEEDOR,
            payload: null
        });
    }
}

export const detallesFilePagoProveedor = (id_proveedor, id_pago) => async dispatch => {
    if(id_proveedor){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = {
            id_proveedor, 
            id_pago
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/detalles-archivo-pago`, body, config);

            dispatch({
                type: DETALLES_ARCHIVO_PAGO_PROVEEDOR,
                payload: res.data.archivo
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_ARCHIVO_PAGO_PROVEEDOR,
            payload: null
        });
    }
}

export const editarPagoProveedor = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/editar-pago`, body, config);

        dispatch({
            type: EDITAR_PAGO_PROVEEDOR,
            payload: res.data.pago
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarPagoProveedor = (pago) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(pago);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/bills/eliminar-pago`, body, config);

        dispatch({
            type: ELIMINAR_PAGO_PROVEEDOR,
            payload:{
                id: pago.id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}