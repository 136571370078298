import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { v4 as uuid } from 'uuid';

import { buscarBancos } from '../../actions/bancos';
import { agregarProveedor } from '../../actions/deudas';

const NuevoProveedor = ({ history }) => {
    
    const dispatch = useDispatch();

    const { bancos } = useSelector(state => state.bancos);

    const [proveedor, setProveedor] = useState({
        nombre: '',
        contacto: '',
        telefono: '',
        celular: '',
        email: '',
        direccion: '',
        id_banco: null,
        cuenta: '', 
        notas: ''
    });

    const [bancoData, setBancoData] = useState(null);

    const [bancosFilter, setBancosFilter] = useState([]);

    const handleOnChange = e => {
        setProveedor({
            ...proveedor,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectBanco = (option) => {
        setBancoData(option);

        setProveedor({
            ...proveedor,
            id_banco: option !== null ? option.value : null
        });
    }

    const handleChangeAddress = direccion => {
        setProveedor({
            ...proveedor, direccion
        });
    }

    const handleSelectAddress = (direccion) => {
        geocodeByAddress(direccion)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setProveedor({
                    ...proveedor, direccion: direccion
                })
            ))
            .catch(error => console.error('Error', error));
    }

    const handleOnSubmit = () => {
        setTimeout(async() => {
            await dispatch(agregarProveedor(proveedor));
            await history.push('/bills/providers');
        }, 0);
    }

    useEffect(() => {
        dispatch(buscarBancos());
    }, []);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < bancos.length; i++) {
            options.push({ value: bancos[i]._id, label: bancos[i].nombre });
        }

        setBancosFilter(options);

    }, [bancos]);

    return ( 
        <div className="row pb-5">
            <div className="col-md-10 offset-md-1">
                <h3 className="my-5">Create provider</h3>
                <div className="row">                    
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Name of provider:</label>
                        <input type="text" className="form-control" name="nombre" onChange={handleOnChange} value={proveedor.nombre}></input>
                    </div>
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Name of contact:</label>
                        <input type="text" className="form-control" name="contacto" onChange={handleOnChange} value={proveedor.contacto}></input>
                    </div>
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput name="telefono" className="form-control" country={'us'} value={proveedor.telefono} onChange={phone => {setProveedor({...proveedor, telefono: phone}); }} />
                        </div>
                    </div>
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Cell phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput name="celular" className="form-control" country={'us'} value={proveedor.celular} onChange={phone => {setProveedor({...proveedor, celular: phone}); }} />
                        </div>
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Email:</label>
                        <input type="text" className="form-control" name="email" onChange={handleOnChange} value={proveedor.email}></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Bank:</label>
                        <Select name="id_banco" isClearable={true} options={bancosFilter} value={bancoData} onChange={(value) => handleSelectBanco(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Account:</label>
                        <input type="text" className="form-control" name="cuenta" onChange={handleOnChange} value={proveedor.cuenta}></input>
                    </div>

                    <PlacesAutocomplete
                        value={proveedor.direccion}
                        onChange={handleChangeAddress}
                        onSelect={handleSelectAddress}
                    >

                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className='col-md-7 form-group'>
                                <label className="font-weight-bold">Address:</label>
                                <input
                                    {...getInputProps({
                                        className: 'location-search-input form-control',
                                        id: 'i-direccion'
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {

                                        const id = uuid();
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };

                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style
                                                })}
                                                key={id}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Notes:</label>
                        <textarea className="form-control" name="notas" value={proveedor.notas || ''} onChange={handleOnChange}></textarea>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-success float-right mt-3" onClick={() => handleOnSubmit()}><i className="fas fa-check fa-sm"></i> Create</button>
                        <button type="button" className="btn btn-danger float-right mt-3 mr-3" onClick={() => {history.push('/bills/providers')}}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NuevoProveedor;