import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ReactChipInput from "react-chip-input";
import DatePicker from 'react-datepicker';
import "react-image-lightbox/style.css";

import { setAlert } from '../../actions/alert';
import { detallesProyecto, publicTokensProyecto, reenviarFilesProyecto, eliminarTokenArchivos } from '../../actions/proyectos';

const styles = {
    panel_tab: {
        backgroundColor: '#F3F2F2'
    },
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    tableHeader: {
        display: 'flex',
        backgroundColor: '#343A40',
        color: 'white',
    },
    textTableHeader: {
        padding: '2% 2%',
        marginBottom: '0'
    },
    buttonIcon: {
        fontSize: 18,
    },
    btnSnapShot: {
        cursor: 'pointer'
    },
}

const Files = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [shareFilesData, setShareFilesData] = useState({
        id: null,
        tipo: null,
        emails: [],
        vigencia: null,
        ruta: null
    });

    const { loadingData, dataLoaded } = dataStatus;

    const [actionsTokenModal, setActionsTokenModal] = useState(false);

    const goBackProject = () => {
        history.goBack();
    }

    // Acciones de token
    const handleAccionesToken = async (token) => {
        await setShareFilesData({
            ...shareFilesData,
            id: token._id,
            tipo: token.type,
            emails: token.emails,
            vigencia: new Date(token.date),
            ruta: process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id
        });

        await setActionsTokenModal(true);
    }

    const handleResendToken = async () => {
        if(shareFilesData.vigencia !== null){
            if(shareFilesData.emails.length > 0){
                await dispatch(reenviarFilesProyecto(shareFilesData));

                await handleCloseAccionesToken();
            }else{
                await dispatch(setAlert('First enter at least one email', 'danger'));
            }
        }else{
            await dispatch(setAlert('First enter a valid duration date', 'danger'));
        }
    }

    const handleAgregarEmail = email => {
        setShareFilesData({
            ...shareFilesData,
            emails: [...shareFilesData.emails, email]
        });
    }

    const handleEliminarEmail = posicion => {
        setShareFilesData({
            ...shareFilesData,
            emails: shareFilesData.emails.filter( (email, index) => index !== posicion )
        });
    }

    const handleCopyToken = async () => {
        if(shareFilesData.vigencia !== null){
            await navigator.clipboard.writeText(process.env.REACT_APP_DOMAIN_ROUTE + '/projects/public_files/' + id + '/' + shareFilesData.id);
            await dispatch(setAlert('Share link coppied', 'primary'));

            await handleCloseAccionesToken();
        }else{
            dispatch(setAlert('First enter a valid duration date', 'danger'));
        }
    }

    const handleDeleteToken = async () => {
        await dispatch(eliminarTokenArchivos(shareFilesData.id));

        await handleCloseAccionesToken();
    }

    const handleCloseAccionesToken = () => {
        setActionsTokenModal(false);
    }

    const tableRow = (items) => {        
        return (
            <tbody>
                {
                    items.map( (item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>{item.type === 0 || item.type === '0' ? 'Share link' : 'Email sended'}</td>
                                    <td>{item.emails.length > 0 ? item.emails.join(', ') : 'No emails selected'}</td>
                                    <td>{item.add ? 'Yes' : 'No'}</td>
                                    <td>{item.edit ? 'Yes' : 'No'}</td>
                                    <td>{item.delete ? 'Yes' : 'No'}</td>
                                    <td>{new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>{item.files.length}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => handleAccionesToken(item)}>Options</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(detallesProyecto(id));
        
        dispatch(publicTokensProyecto(id));

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null && proyectos.tokens !== undefined) {

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [proyectos]);

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Public tokens (files)</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => { goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>                
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>

                            {/* Modal de acciones de token */}
                            <Modal backdrop="static" show={actionsTokenModal} onHide={() => {
                                handleCloseAccionesToken();
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Actions of the token</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p style={styles.info}>Select one of the following options.</p>
                                        </div>  

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Duration Date:</label>
                                            <div className='d-flex'>
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </span>
                                                <DatePicker 
                                                    name="files_duration_date" 
                                                    dateFormat="MM/dd/yyyy"
                                                    className="form-control text-center" 
                                                    selected={shareFilesData.vigencia !== null ? shareFilesData.vigencia : null} 
                                                    onChange={date => {setShareFilesData({...shareFilesData, vigencia: date})}} 
                                                />
                                            </div>
                                        </div>  

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Emails:</label>
                                            <div className="contenedor_datepicker">
                                                <ReactChipInput
                                                    chips={shareFilesData.emails}
                                                    onSubmit={value => handleAgregarEmail(value)}
                                                    onRemove={index => handleEliminarEmail(index)}
                                                />
                                            </div>
                                        </div>                                   
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAccionesToken()}><i className="fas fa-times fa-sm"></i> Close</button>
                                    <button className="btn btn-primary" onClick={() => handleResendToken()}><i className="fas fa-paper-plane fa-sm"></i> {shareFilesData.tipo === 0 || shareFilesData.tipo === '0' ? 'Send' : 'Resend'}</button>
                                    <button className="btn btn-primary" onClick={() => handleCopyToken()}><i className="fas fa-copy fa-sm"></i> Copy</button>
                                    <button className="btn btn-warning" onClick={() => handleDeleteToken()}><i className="fas fa-eraser fa-sm"></i> Delete</button>
                                </Modal.Footer>
                            </Modal>

                            <div className="col-md-12  my-4">
                                <div className="row form-group">
                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> {detalles?.proyecto?.direccion}
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12 row">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Emails</th>
                                                <th scope="col">Add</th>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Delete</th>
                                                <th scope="col">Expiration date</th>
                                                <th scope="col">Files</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {tableRow(proyectos.tokens)}
                                    </table>
                                </div>
                            </div>
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default Files;