import { BUSCAR_GASTOS, NUEVO_GASTO, EDITAR_GASTO, DETALLES_GASTO, ELIMINAR_GASTO, EXTERNO_GASTO } from '../actions/types';

const initialState = {
    gastos: [],
    detalles: null,
    externo: false
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {
        case BUSCAR_GASTOS: 
            return {
                ...state,
                gastos: payload
            }
        case NUEVO_GASTO:
            return {
                ...state,
                gastos: [
                    payload, ...state.gastos
                ]
            };
        case EDITAR_GASTO:
            return {
                ...state,
                gastos: state.gastos.map(gasto => String(gasto._id) === String(payload._id) ? payload : gasto)
            }
        case DETALLES_GASTO: 
            return {
                ...state,
                detalles: payload
            }  
        case ELIMINAR_GASTO: 
            return {
                ...state,
                gastos: state.gastos.filter( gasto => gasto._id !== payload.id )
            }

        case EXTERNO_GASTO: 
            return {
                ...state,
                externo: payload
            }
        
        default: 
            return state;
    }

}