import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';

import { editarVesting, detallesVesting } from '../../actions/vestings';

const EditarVesting = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const vestings = useSelector(state => state.vestings);

    const [vestingData, setVestingData] = useState({
        id: null,
        nombre: null,
        celular: null,
        telefono: null,
        email: null,
        direccion: null
    });

    const [spinner, setSpinner] = useState(false);

    const onChange = (e) => {
        setVestingData({
            ...vestingData, [e.target.name]: e.target.value
        });
    };

    const handleChange = direccion => {
        setVestingData({
            ...vestingData, direccion
        });
    };

    const handleSelect = (direccion) => {
        geocodeByAddress(direccion)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setVestingData({
                    ...vestingData, direccion: direccion, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            dispatch(editarVesting(vestingData));
            setSpinner(false);
            history.push('/configuration/vestings');
        }, 0);
    };

    useEffect(() => {
        dispatch(detallesVesting(id));
    }, []);

    useEffect(() => {

        setVestingData({
            ...vestingData,
            id: vestings.detalles?._id,
            nombre: vestings.detalles?.nombre,
            celular: vestings.detalles?.celular,
            telefono: vestings.detalles?.telefono,
            email: vestings.detalles?.email,
            direccion: vestings.detalles?.direccion
        });
    }, [vestings]);

    return (
        <form className="px-3">
            <div className="row pb-5">
                <div className="col-md-3">
                    <h3 className="mb-3 mt-5">Edit vesting</h3>
                </div>

                <div className="col-md-9 mt-5">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Save</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/vestings')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </div>

            {
                spinner ? 
                    <div className="col-12 mt-3 text-center d-flex justify-content-center">
                        <div className="sk-chase my-5">
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                            <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                :
                    null
            }

            <div className="row">
                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Name:</label>
                    <input type="text" className="form-control" name="nombre" onChange={e => onChange(e)} value={vestingData.nombre}></input>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Cell phone:</label>
                    <div className="contenedor_datepicker">
                        <PhoneInput name="celular" className="form-control" country={'us'} value={vestingData.celular || ''} onChange={phone => {setVestingData({...vestingData, celular: phone}); }} />
                    </div>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Phone:</label>
                    <div className="contenedor_datepicker">
                        <PhoneInput name="telefono" className="form-control" country={'us'} value={vestingData.telefono || ''} onChange={phone => {setVestingData({...vestingData, telefono: phone}); }} />
                    </div>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Email:</label>
                    <div className="contenedor_datepicker">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input name="email" className="form-control" type="text" value={vestingData.email || ''} onChange={e => onChange(e)} />
                        </div>
                    </div>
                </div>

                <PlacesAutocomplete
                    value={vestingData.direccion}
                    onChange={handleChange}
                    onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className='col-md-4 form-group'>
                            <label className="font-weight-bold">Address:</label>
                            <input
                                {...getInputProps({
                                    className: 'location-search-input form-control',
                                    id: 'i-direccion'
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {

                                    const id = uuid();
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            key={id}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        </form>
    )
}


export default EditarVesting;