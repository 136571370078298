import { BUSCAR_FINANCING_TYPES, AGREGAR_FINANCING_TYPE, EDITAR_FINANCING_TYPE, DETALLES_FINANCING_TYPE, ELIMINAR_FINANCING_TYPE } from '../actions/types';

const initialState = {
    financing_types: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case BUSCAR_FINANCING_TYPES:
            return {
                ...state,
                financing_types: payload
            };
        case AGREGAR_FINANCING_TYPE:
            return {
                ...state,
                financing_types: [
                    ...state.financing_types, payload
                ]
            };
        case EDITAR_FINANCING_TYPE:
            return {
                ...state,
                financing_types: state.financing_types.map(
                    financing_type => financing_type._id === payload._id ? payload : financing_type
                )
            };
        case DETALLES_FINANCING_TYPE:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_FINANCING_TYPE:
            return {
                ...state,
                financing_types: state.financing_types.filter( financing_type => financing_type._id !== payload.id )
            };        
        default:
            return state;
    }
}