import { AGREGAR_EMPLEADO, EDITANDO_EMPLEADO, ELIMINAR_EMPLEADO, BUSCAR_EMPLEADOS } from '../actions/types';

const initialState = {
    empleados: [],
    empleadoEditando: null
}

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch(type) {
        case BUSCAR_EMPLEADOS: 
            return {
                ...state,
                empleados: payload
            }
        case AGREGAR_EMPLEADO: 
            return {
                ...state
            }
        case ELIMINAR_EMPLEADO: 
            return {
                ...state,
                empleados: state.empleados.filter( empleado => empleado._id !== payload.id )
            }
        case EDITANDO_EMPLEADO:
            return {
                ...state,
                empleadoEditando: state.empleados.find( empleado => empleado._id === payload )
            }
        default: 
            return state;
    }

}