import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';

import { agregarVesting } from '../../actions/vestings';

const NuevoVesting = ({ history }) => {

    const dispatch = useDispatch();

    const [vestingData, setVestingData] = useState({
        nombre: null,
        celular: null,
        telefono: null,
        email: null,
        direccion: null
    });

    const [spinner, setSpinner] = useState(false);

    const onChange = (e) => {
        console.log(e.target);

        setVestingData({
            ...vestingData, [e.target.name]: e.target.value
        });
    };

    const handleChange = direccion => {
        setVestingData({
            ...vestingData, direccion
        });
    };

    const handleSelect = (direccion) => {
        geocodeByAddress(direccion)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setVestingData({
                    ...vestingData, direccion: direccion, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSpinner(true);
        setTimeout(async() => {
            await dispatch(agregarVesting(vestingData));
            setSpinner(false);
            history.push('/configuration/vestings');
        }, 0);
    };

    return (
        <form className="px-3">
            <div className="row pb-5">
                <div className="col-md-3">
                    <h3 className="mb-3 mt-5">Create new vesting</h3>
                </div>

                <div className="col-md-9 mt-5">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Create</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/vestings')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </div>

            {spinner ? 
                <div className="col-12 mt-3 text-center d-flex justify-content-center">
                    <div className="sk-chase my-5">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                </div>
            :
                null
            }
            
            <div className="row">
                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Name:</label>
                    <input type="text" className="form-control" name="nombre" onChange={e => onChange(e)} value={vestingData.nombre}></input>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Representant:</label>
                    <input type="text" className="form-control" name="representante" onChange={e => onChange(e)} value={vestingData.representante}></input>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Cell phone:</label>
                    <div className="contenedor_datepicker">
                        <PhoneInput name="celular" className="form-control" country={'us'} value={vestingData.celular || ''} onChange={phone => {setVestingData({...vestingData, celular: phone}); }} />
                    </div>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Phone:</label>
                    <div className="contenedor_datepicker">
                        <PhoneInput name="telefono" className="form-control" country={'us'} value={vestingData.telefono || ''} onChange={phone => {setVestingData({...vestingData, telefono: phone}); }} />
                    </div>
                </div>

                <div className="col-md-2 form-group">
                    <label className="font-weight-bold">Email:</label>
                    <div className="contenedor_datepicker">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input name="email" className="form-control" type="text" value={vestingData.email || ''} onChange={e => onChange(e)} />
                        </div>
                    </div>
                </div>

                <PlacesAutocomplete
                    value={vestingData.direccion}
                    onChange={handleChange}
                    onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className='col-md-4 form-group'>
                            <label className="font-weight-bold">Address:</label>
                            <input
                                {...getInputProps({
                                    className: 'location-search-input form-control',
                                    id: 'i-direccion'
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {

                                    const id = uuid();
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            key={id}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        </form>
    )
}


export default NuevoVesting;