import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { parse, v4 as uuid } from 'uuid';
import CurrencyInput from 'react-currency-input-field';

import { setAlert } from '../../actions/alert';
import { agregarOferta } from '../../actions/proyectos';
import { filterProyectos } from '../../actions/dashboard';
import { editarAnalisis, detallesAnalisis, reporteAnalisis, buscarValoresAnalisis } from '../../actions/deal_analyzer';

const styles = {
    titulo: {
        color: '#ff0000'
    }, 
    containerStyle: {
        width: '100%',
        height: '50vh'
    }
}

const EditarAnalisis = ({ history }) => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, valores } = useSelector(state => state.deal_analyzer);

    const proyectoDataInit = {
        id: null, 
        direccion: null,
        latitud: null,
        longitud: null,

        funds_need_to_close: null,
        purchase_price: null,
        purchase_price_percent: null,
        purchase_closing_cost: null,
        cash_for_keys_relocation: null,
        acquisition_cost_ac: null,

        market_value_arv: null,
        market_value_high: null,
        market_value_low: null,
        market_value_average: null,
        months_project: null,
        market_value_monthly_hoa: null,
        office_fees: null,

        rehab_cost_no_lic_fee: null,
        rehab_mana_lic_fee_percent: null,
        city_permits: null,
        plans_engineering: null,

        sales_commission_percent: null,
        sellers_closing_cost: null,
        buyers_assistance: null,
        certifications_inspections: null,

        loan_amount_ltv: null,
        loan_amount_percent: null,
        yrly_rate: null,
        loan_amount_origination_points: null,
        other_lenders_fees: null,
        other_lenders_fees_percent: null,

        sell_price: null,
        total_acquisition_with_fc_cost_all_in: null,
        sell_total_monthly_carrying_cost: null,
        sell_total_rehab_cost: null,
        total_investment: null,
        investment_need_from_buyer: null,

        profit: null,
        profit_high: null,
        profit_low: null,
        flipping_all_office_income: null,
        all_office_income_high: null,
        all_office_income_low: null,
        all_office_income_average: null,

        rent_amount: null,
        rent_interest: null,
        monthly_expense: null,
        anual_expense: null,
        max_purchase_price: null,

        rehab_management: null,
        incomes_office_fees: null,
        real_state: null,
        all_office_income: null,

        uberous_investment_return: null,
        uberous_capital_cost: null,
        net_uberous_cpr: null,
        gross_total_income: null,

        origination_points: null,
        other_fees: null,
        total_origination_cost: null,
        total_monthly_paid: null,
        total_financing_cost_fc: null,

        monthly_interest_i_o: null,
        property_tax: null,
        utilities_gewsi: null,
        insurance: null,
        maintenance: null,
        security: null,
        monthly_hoa: null,
        monthly_carrying_cost: null,
        total_monthly_carrying_cost: null,

        material_labor_cost: null,
        rehab_mana_lic_fee: null,
        rehab_city_permits: null,
        rehab_plans_engineering: null,
        total_rehab_cost: null,

        commission: null,
        selling_sellers_closing_cost: null,
        selling_buyers_assistance: null,
        transfer_taxes: null,
        selling_certifications_inspections: null,
        total_sells_cost: null,

        utilities_gewsi_amount: null,
        utilities_gewsi_calculated: null,

        insurance_amount: null,
        insurance_calculated: null,

        maintenance_amount: null,
        maintenance_calculated: null,

        security_amount: null,
        security_calculated: null,

        monthly_hoa_maintenance: null,
        monthly_hoa_security: null,

        uberous_capital_rate_interest: null,
        uberous_capital_rate_amount: null
    };

    const valoresDataInit = {
        rehab_mana_lic_fee_percent: null, 
        sales_commission_percent: null, 
        monthly_expense_rate: null, 
        real_state_rate: null, 
        uberous_capital_cost_rate: null, 
        property_tax_rate: null, 
        utilities_gewsi_rate: null, 
        insurance_rate: null, 
        maintenance_rate: null, 
        security_rate: null, 
        transfer_taxes_rate: null, 
        utilities_gewsi_base: null, 
        insurance_base: null, 
        maintenance_base: null, 
        security_base: null, 
        monthly_hoa_maintenance_factor: null, 
        monthly_hoa_security_factor: null, 
        uberous_capital_rate: null
    };

    const [proyectoData, setProyectoData] = useState(proyectoDataInit);
    const [valoresData, setValoresData] = useState(valoresDataInit);

    const [isBackButtonClicked, setBackbuttonPress] = useState(false);    
    const [spinner, setSpinner] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [moreOptions, setMoreOptions] = useState(false);
    const [mapModal, setMapModal] = useState(false);
    const [coord, setCoord] = useState({
        lat: 21.879529,
        lng: -102.303249
    });

    /* Funciones generales */
    const numberToDecimal = (prefix, number, suffix) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = prefix + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix;
        return finalFormated;
    };

    const roundToDecimal = (numero) => {
        let redondeado = Math.round(numero * 100) / 100;
        return redondeado;
    };

    const pointToDecimal = (numero) => {
        if(numero){
            let ultimo = numero.charAt(numero.length - 1);

            if(ultimo == '.'){
                return numero;
            }else{
                return parseFloat(numero);
            }
        }else{
            return numero;
        }
    };

    const onNumberChange = (name, value) => {
        setProyectoData({
            ...proyectoData, [name]: value || ''
        });

        handleCalculate(name, value);
    };

    const handleSelectAddress = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setCoord({
                    lat: latLng.lat, lng: latLng.lng
                }),
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChangeAddress = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });
    };

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    };

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    };

    const handleCalculate = async (name, value) => {
        let datos_proyecto = {
            ...proyectoData, 
            [name]: value || 0
        };

        let funds_need_to_close = name == 'funds_need_to_close' ? pointToDecimal(value) : (datos_proyecto.funds_need_to_close ? parseFloat(datos_proyecto.funds_need_to_close) : 0.0);
        let purchase_price = name == 'purchase_price' ? pointToDecimal(value) : (datos_proyecto.purchase_price ? parseFloat(datos_proyecto.purchase_price) : 0.0);
        let purchase_price_percent = name == 'purchase_price_percent' ? pointToDecimal(value) : (datos_proyecto.purchase_price_percent ? parseFloat(datos_proyecto.purchase_price_percent) : 0.0);
        let purchase_closing_cost = name == 'purchase_closing_cost' ? pointToDecimal(value) : (datos_proyecto.purchase_closing_cost ? parseFloat(datos_proyecto.purchase_closing_cost) : 0.0);
        let cash_for_keys_relocation = name == 'cash_for_keys_relocation' ? pointToDecimal(value) : (datos_proyecto.cash_for_keys_relocation ? parseFloat(datos_proyecto.cash_for_keys_relocation) : 0.0);
        let acquisition_cost_ac = name == 'acquisition_cost_ac' ? pointToDecimal(value) : (datos_proyecto.acquisition_cost_ac ? parseFloat(datos_proyecto.acquisition_cost_ac) : 0.0);
        let market_value_arv = name == 'market_value_arv' ? pointToDecimal(value) : (datos_proyecto.market_value_arv ? parseFloat(datos_proyecto.market_value_arv) : 0.0);
        let market_value_high = name == 'market_value_high' ? pointToDecimal(value) : (datos_proyecto.market_value_high ? parseFloat(datos_proyecto.market_value_high) : 0.0);
        let market_value_low = name == 'market_value_low' ? pointToDecimal(value) : (datos_proyecto.market_value_low ? parseFloat(datos_proyecto.market_value_low) : 0.0);
        let market_value_average = name == 'market_value_average' ? pointToDecimal(value) : (datos_proyecto.market_value_average ? parseFloat(datos_proyecto.market_value_average) : 0.0);
        let months_project = name == 'months_project' ? pointToDecimal(value) : (datos_proyecto.months_project ? parseFloat(datos_proyecto.months_project) : 0.0);
        let market_value_monthly_hoa = name == 'market_value_monthly_hoa' ? pointToDecimal(value) : (datos_proyecto.market_value_monthly_hoa ? parseFloat(datos_proyecto.market_value_monthly_hoa) : 0.0);
        let office_fees = name == 'office_fees' ? pointToDecimal(value) : (datos_proyecto.office_fees ? parseFloat(datos_proyecto.office_fees) : 0.0);
        let rehab_cost_no_lic_fee = name == 'rehab_cost_no_lic_fee' ? pointToDecimal(value) : (datos_proyecto.rehab_cost_no_lic_fee ? parseFloat(datos_proyecto.rehab_cost_no_lic_fee) : 0.0);
        let rehab_mana_lic_fee_percent = name == 'rehab_mana_lic_fee_percent' ? pointToDecimal(value) : (datos_proyecto.rehab_mana_lic_fee_percent ? parseFloat(datos_proyecto.rehab_mana_lic_fee_percent) : 0.0);
        let city_permits = name == 'city_permits' ? pointToDecimal(value) : (datos_proyecto.city_permits ? parseFloat(datos_proyecto.city_permits) : 0.0);
        let plans_engineering = name == 'plans_engineering' ? pointToDecimal(value) : (datos_proyecto.plans_engineering ? parseFloat(datos_proyecto.plans_engineering) : 0.0);
        let sales_commission_percent = name == 'sales_commission_percent' ? pointToDecimal(value) : (datos_proyecto.sales_commission_percent ? parseFloat(datos_proyecto.sales_commission_percent) : 0.0);
        let sellers_closing_cost = name == 'sellers_closing_cost' ? pointToDecimal(value) : (datos_proyecto.sellers_closing_cost ? parseFloat(datos_proyecto.sellers_closing_cost) : 0.0);
        let buyers_assistance = name == 'buyers_assistance' ? pointToDecimal(value) : (datos_proyecto.buyers_assistance ? parseFloat(datos_proyecto.buyers_assistance) : 0.0);
        let certifications_inspections = name == 'certifications_inspections' ? pointToDecimal(value) : (datos_proyecto.certifications_inspections ? parseFloat(datos_proyecto.certifications_inspections) : 0.0);
        let loan_amount_ltv = name == 'loan_amount_ltv' ? pointToDecimal(value) : (datos_proyecto.loan_amount_ltv ? parseFloat(datos_proyecto.loan_amount_ltv) : 0.0);
        let loan_amount_percent = name == 'loan_amount_percent' ? pointToDecimal(value) : (datos_proyecto.loan_amount_percent ? parseFloat(datos_proyecto.loan_amount_percent) : 0.0);
        let yrly_rate = name == 'yrly_rate' ? pointToDecimal(value) : (datos_proyecto.yrly_rate ? parseFloat(datos_proyecto.yrly_rate) : 0.0);
        let loan_amount_origination_points = name == 'loan_amount_origination_points' ? pointToDecimal(value) : (datos_proyecto.loan_amount_origination_points ? parseFloat(datos_proyecto.loan_amount_origination_points) : 0.0);
        let other_lenders_fees = name == 'other_lenders_fees' ? pointToDecimal(value) : (datos_proyecto.other_lenders_fees ? parseFloat(datos_proyecto.other_lenders_fees) : 0.0);
        let other_lenders_fees_percent = name == 'other_lenders_fees_percent' ? pointToDecimal(value) : (datos_proyecto.other_lenders_fees_percent ? parseFloat(datos_proyecto.other_lenders_fees_percent) : 0.0);
        let sell_price = name == 'sell_price' ? pointToDecimal(value) : (datos_proyecto.sell_price ? parseFloat(datos_proyecto.sell_price) : 0.0);
        let total_acquisition_with_fc_cost_all_in = name == 'total_acquisition_with_fc_cost_all_in' ? pointToDecimal(value) : (datos_proyecto.total_acquisition_with_fc_cost_all_in ? parseFloat(datos_proyecto.total_acquisition_with_fc_cost_all_in) : 0.0);
        let sell_total_monthly_carrying_cost = name == 'sell_total_monthly_carrying_cost' ? pointToDecimal(value) : (datos_proyecto.sell_total_monthly_carrying_cost ? parseFloat(datos_proyecto.sell_total_monthly_carrying_cost) : 0.0);
        let sell_total_rehab_cost = name == 'sell_total_rehab_cost' ? pointToDecimal(value) : (datos_proyecto.sell_total_rehab_cost ? parseFloat(datos_proyecto.sell_total_rehab_cost) : 0.0);
        let total_investment = name == 'total_investment' ? pointToDecimal(value) : (datos_proyecto.total_investment ? parseFloat(datos_proyecto.total_investment) : 0.0);
        let investment_need_from_buyer = name == 'investment_need_from_buyer' ? pointToDecimal(value) : (datos_proyecto.investment_need_from_buyer ? parseFloat(datos_proyecto.investment_need_from_buyer) : 0.0);
        let profit = name == 'profit' ? pointToDecimal(value) : (datos_proyecto.profit ? parseFloat(datos_proyecto.profit) : 0.0);
        let profit_high = name == 'profit_high' ? pointToDecimal(value) : (datos_proyecto.profit_high ? parseFloat(datos_proyecto.profit_high) : 0.0);
        let profit_low = name == 'profit_low' ? pointToDecimal(value) : (datos_proyecto.profit_low ? parseFloat(datos_proyecto.profit_low) : 0.0);
        let flipping_all_office_income = name == 'flipping_all_office_income' ? pointToDecimal(value) : (datos_proyecto.flipping_all_office_income ? parseFloat(datos_proyecto.flipping_all_office_income) : 0.0);
        let all_office_income_high = name == 'all_office_income_high' ? pointToDecimal(value) : (datos_proyecto.all_office_income_high ? parseFloat(datos_proyecto.all_office_income_high) : 0.0);
        let all_office_income_low = name == 'all_office_income_low' ? pointToDecimal(value) : (datos_proyecto.all_office_income_low ? parseFloat(datos_proyecto.all_office_income_low) : 0.0);
        let all_office_income_average = name == 'all_office_income_average' ? pointToDecimal(value) : (datos_proyecto.all_office_income_average ? parseFloat(datos_proyecto.all_office_income_average) : 0.0);
        let rent_amount = name == 'rent_amount' ? pointToDecimal(value) : (datos_proyecto.rent_amount ? parseFloat(datos_proyecto.rent_amount) : 0.0);
        let rent_interest = name == 'rent_interest' ? pointToDecimal(value) : (datos_proyecto.rent_interest ? parseFloat(datos_proyecto.rent_interest) : 0.0);
        let monthly_expense = name == 'monthly_expense' ? pointToDecimal(value) : (datos_proyecto.monthly_expense ? parseFloat(datos_proyecto.monthly_expense) : 0.0);
        let anual_expense = name == 'anual_expense' ? pointToDecimal(value) : (datos_proyecto.anual_expense ? parseFloat(datos_proyecto.anual_expense) : 0.0);
        let max_purchase_price = name == 'max_purchase_price' ? pointToDecimal(value) : (datos_proyecto.max_purchase_price ? parseFloat(datos_proyecto.max_purchase_price) : 0.0);
        let rehab_management = name == 'rehab_management' ? pointToDecimal(value) : (datos_proyecto.rehab_management ? parseFloat(datos_proyecto.rehab_management) : 0.0);
        let incomes_office_fees = name == 'incomes_office_fees' ? pointToDecimal(value) : (datos_proyecto.incomes_office_fees ? parseFloat(datos_proyecto.incomes_office_fees) : 0.0);
        let real_state = name == 'real_state' ? pointToDecimal(value) : (datos_proyecto.real_state ? parseFloat(datos_proyecto.real_state) : 0.0);
        let all_office_income = name == 'all_office_income' ? pointToDecimal(value) : (datos_proyecto.all_office_income ? parseFloat(datos_proyecto.all_office_income) : 0.0);
        let uberous_investment_return = name == 'uberous_investment_return' ? pointToDecimal(value) : (datos_proyecto.uberous_investment_return ? parseFloat(datos_proyecto.uberous_investment_return) : 0.0);
        let uberous_capital_cost = name == 'uberous_capital_cost' ? pointToDecimal(value) : (datos_proyecto.uberous_capital_cost ? parseFloat(datos_proyecto.uberous_capital_cost) : 0.0);
        let net_uberous_cpr = name == 'net_uberous_cpr' ? pointToDecimal(value) : (datos_proyecto.net_uberous_cpr ? parseFloat(datos_proyecto.net_uberous_cpr) : 0.0);
        let gross_total_income = name == 'gross_total_income' ? pointToDecimal(value) : (datos_proyecto.gross_total_income ? parseFloat(datos_proyecto.gross_total_income) : 0.0);
        let origination_points = name == 'origination_points' ? pointToDecimal(value) : (datos_proyecto.origination_points ? parseFloat(datos_proyecto.origination_points) : 0.0);
        let other_fees = name == 'other_fees' ? pointToDecimal(value) : (datos_proyecto.other_fees ? parseFloat(datos_proyecto.other_fees) : 0.0);
        let total_origination_cost = name == 'total_origination_cost' ? pointToDecimal(value) : (datos_proyecto.total_origination_cost ? parseFloat(datos_proyecto.total_origination_cost) : 0.0);
        let total_monthly_paid = name == 'total_monthly_paid' ? pointToDecimal(value) : (datos_proyecto.total_monthly_paid ? parseFloat(datos_proyecto.total_monthly_paid) : 0.0);
        let total_financing_cost_fc = name == 'total_financing_cost_fc' ? pointToDecimal(value) : (datos_proyecto.total_financing_cost_fc ? parseFloat(datos_proyecto.total_financing_cost_fc) : 0.0);
        let monthly_interest_i_o = name == 'monthly_interest_i_o' ? pointToDecimal(value) : (datos_proyecto.monthly_interest_i_o ? parseFloat(datos_proyecto.monthly_interest_i_o) : 0.0);
        let property_tax = name == 'property_tax' ? pointToDecimal(value) : (datos_proyecto.property_tax ? parseFloat(datos_proyecto.property_tax) : 0.0);
        let utilities_gewsi = name == 'utilities_gewsi' ? pointToDecimal(value) : (datos_proyecto.utilities_gewsi ? parseFloat(datos_proyecto.utilities_gewsi) : 0.0);
        let insurance = name == 'insurance' ? pointToDecimal(value) : (datos_proyecto.insurance ? parseFloat(datos_proyecto.insurance) : 0.0);
        let maintenance = name == 'maintenance' ? pointToDecimal(value) : (datos_proyecto.maintenance ? parseFloat(datos_proyecto.maintenance) : 0.0);
        let security = name == 'security' ? pointToDecimal(value) : (datos_proyecto.security ? parseFloat(datos_proyecto.security) : 0.0);
        let monthly_hoa = name == 'monthly_hoa' ? pointToDecimal(value) : (datos_proyecto.monthly_hoa ? parseFloat(datos_proyecto.monthly_hoa) : 0.0);
        let monthly_carrying_cost = name == 'monthly_carrying_cost' ? pointToDecimal(value) : (datos_proyecto.monthly_carrying_cost ? parseFloat(datos_proyecto.monthly_carrying_cost) : 0.0);
        let total_monthly_carrying_cost = name == 'total_monthly_carrying_cost' ? pointToDecimal(value) : (datos_proyecto.total_monthly_carrying_cost ? parseFloat(datos_proyecto.total_monthly_carrying_cost) : 0.0);
        let material_labor_cost = name == 'material_labor_cost' ? pointToDecimal(value) : (datos_proyecto.material_labor_cost ? parseFloat(datos_proyecto.material_labor_cost) : 0.0);
        let rehab_mana_lic_fee = name == 'rehab_mana_lic_fee' ? pointToDecimal(value) : (datos_proyecto.rehab_mana_lic_fee ? parseFloat(datos_proyecto.rehab_mana_lic_fee) : 0.0);
        let rehab_city_permits = name == 'rehab_city_permits' ? pointToDecimal(value) : (datos_proyecto.rehab_city_permits ? parseFloat(datos_proyecto.rehab_city_permits) : 0.0);
        let rehab_plans_engineering = name == 'rehab_plans_engineering' ? pointToDecimal(value) : (datos_proyecto.rehab_plans_engineering ? parseFloat(datos_proyecto.rehab_plans_engineering) : 0.0);
        let total_rehab_cost = name == 'total_rehab_cost' ? pointToDecimal(value) : (datos_proyecto.total_rehab_cost ? parseFloat(datos_proyecto.total_rehab_cost) : 0.0);
        let commission = name == 'commission' ? pointToDecimal(value) : (datos_proyecto.commission ? parseFloat(datos_proyecto.commission) : 0.0);
        let selling_sellers_closing_cost = name == 'selling_sellers_closing_cost' ? pointToDecimal(value) : (datos_proyecto.selling_sellers_closing_cost ? parseFloat(datos_proyecto.selling_sellers_closing_cost) : 0.0);
        let selling_buyers_assistance = name == 'selling_buyers_assistance' ? pointToDecimal(value) : (datos_proyecto.selling_buyers_assistance ? parseFloat(datos_proyecto.selling_buyers_assistance) : 0.0);
        let transfer_taxes = name == 'transfer_taxes' ? pointToDecimal(value) : (datos_proyecto.transfer_taxes ? parseFloat(datos_proyecto.transfer_taxes) : 0.0);
        let selling_certifications_inspections = name == 'selling_certifications_inspections' ? pointToDecimal(value) : (datos_proyecto.selling_certifications_inspections ? parseFloat(datos_proyecto.selling_certifications_inspections) : 0.0);
        let total_sells_cost = name == 'total_sells_cost' ? pointToDecimal(value) : (datos_proyecto.total_sells_cost ? parseFloat(datos_proyecto.total_sells_cost) : 0.0);
        let utilities_gewsi_amount = name == 'utilities_gewsi_amount' ? pointToDecimal(value) : (datos_proyecto.utilities_gewsi_amount ? parseFloat(datos_proyecto.utilities_gewsi_amount) : 0.0);
        let utilities_gewsi_calculated = name == 'utilities_gewsi_calculated' ? pointToDecimal(value) : (datos_proyecto.utilities_gewsi_calculated ? parseFloat(datos_proyecto.utilities_gewsi_calculated) : 0.0);
        let insurance_amount = name == 'insurance_amount' ? pointToDecimal(value) : (datos_proyecto.insurance_amount ? parseFloat(datos_proyecto.insurance_amount) : 0.0);
        let insurance_calculated = name == 'insurance_calculated' ? pointToDecimal(value) : (datos_proyecto.insurance_calculated ? parseFloat(datos_proyecto.insurance_calculated) : 0.0);
        let maintenance_amount = name == 'maintenance_amount' ? pointToDecimal(value) : (datos_proyecto.maintenance_amount ? parseFloat(datos_proyecto.maintenance_amount) : 0.0);
        let maintenance_calculated = name == 'maintenance_calculated' ? pointToDecimal(value) : (datos_proyecto.maintenance_calculated ? parseFloat(datos_proyecto.maintenance_calculated) : 0.0);
        let security_amount = name == 'security_amount' ? pointToDecimal(value) : (datos_proyecto.security_amount ? parseFloat(datos_proyecto.security_amount) : 0.0);
        let security_calculated = name == 'security_calculated' ? pointToDecimal(value) : (datos_proyecto.security_calculated ? parseFloat(datos_proyecto.security_calculated) : 0.0);
        let monthly_hoa_maintenance = name == 'monthly_hoa_maintenance' ? pointToDecimal(value) : (datos_proyecto.monthly_hoa_maintenance ? parseFloat(datos_proyecto.monthly_hoa_maintenance) : 0.0);
        let monthly_hoa_security = name == 'monthly_hoa_security' ? pointToDecimal(value) : (datos_proyecto.monthly_hoa_security ? parseFloat(datos_proyecto.monthly_hoa_security) : 0.0);
        let uberous_capital_rate_interest = name == 'uberous_capital_rate_interest' ? pointToDecimal(value) : (datos_proyecto.uberous_capital_rate_interest ? parseFloat(datos_proyecto.uberous_capital_rate_interest) : 0.0);
        let uberous_capital_rate_amount = name == 'uberous_capital_rate_amount' ? pointToDecimal(value) : (datos_proyecto.uberous_capital_rate_amount ? parseFloat(datos_proyecto.uberous_capital_rate_amount) : 0.0);

        purchase_price_percent = roundToDecimal(market_value_arv ? ((purchase_price / market_value_arv) * 100) : 0.0);
        acquisition_cost_ac = roundToDecimal(purchase_price + purchase_closing_cost + cash_for_keys_relocation);
        market_value_average = roundToDecimal((market_value_arv + market_value_high + market_value_low) / 3);
        loan_amount_percent = roundToDecimal(purchase_price ? ((loan_amount_ltv / purchase_price) * 100) : 0.0);
        other_lenders_fees_percent = roundToDecimal(loan_amount_ltv ? ((other_lenders_fees / loan_amount_ltv) * 100) : 0.0);
        sell_price = roundToDecimal(market_value_arv);
        monthly_expense = roundToDecimal(rent_amount * (valoresData.monthly_expense_rate / 100));
        anual_expense = roundToDecimal(monthly_expense * 12);
        origination_points = roundToDecimal(loan_amount_ltv * (loan_amount_origination_points / 100));
        other_fees = roundToDecimal(other_lenders_fees);
        total_origination_cost = roundToDecimal(origination_points + other_fees);
        monthly_interest_i_o = roundToDecimal((loan_amount_ltv * (yrly_rate / 100)) / 12);
        property_tax = roundToDecimal((market_value_arv * (valoresData.property_tax_rate / 100)) / 12);
        material_labor_cost = roundToDecimal(rehab_cost_no_lic_fee);
        rehab_mana_lic_fee = roundToDecimal(rehab_cost_no_lic_fee * (rehab_mana_lic_fee_percent / 100));
        rehab_city_permits = roundToDecimal(city_permits);
        rehab_plans_engineering = roundToDecimal(plans_engineering);
        total_rehab_cost = roundToDecimal(material_labor_cost + rehab_mana_lic_fee + rehab_city_permits + rehab_plans_engineering);
        commission = roundToDecimal(market_value_arv * (sales_commission_percent / 100));
        selling_sellers_closing_cost = roundToDecimal(sellers_closing_cost);
        selling_buyers_assistance = roundToDecimal(buyers_assistance);
        transfer_taxes = roundToDecimal(market_value_arv * (valoresData.transfer_taxes_rate / 100));
        selling_certifications_inspections = roundToDecimal(certifications_inspections);
        total_sells_cost = roundToDecimal(commission + selling_sellers_closing_cost + selling_buyers_assistance + transfer_taxes + selling_certifications_inspections);
        utilities_gewsi_calculated = roundToDecimal(market_value_arv * (valoresData.utilities_gewsi_rate / 100));
        insurance_calculated = roundToDecimal(market_value_arv * (valoresData.insurance_rate / 100));
        maintenance_calculated = roundToDecimal((market_value_arv * (valoresData.maintenance_rate / 100)) / 12);
        security_calculated = roundToDecimal((market_value_arv * (valoresData.security_rate / 100)) / 12);
        monthly_hoa_maintenance = roundToDecimal(market_value_monthly_hoa / valoresData.monthly_hoa_maintenance_factor);
        monthly_hoa_security = roundToDecimal(market_value_monthly_hoa / valoresData.monthly_hoa_security_factor);        

        //Dependientes
        utilities_gewsi = roundToDecimal(utilities_gewsi_amount + utilities_gewsi_calculated);
        insurance = roundToDecimal((insurance_amount + insurance_calculated) / 12);
        maintenance = roundToDecimal(maintenance_amount + maintenance_calculated - monthly_hoa_maintenance);
        security = roundToDecimal(security_amount - security_calculated - monthly_hoa_security);
        monthly_hoa = roundToDecimal(market_value_monthly_hoa);
        monthly_carrying_cost = roundToDecimal(monthly_interest_i_o + property_tax + utilities_gewsi + insurance + maintenance + security + monthly_hoa);
        total_monthly_carrying_cost = roundToDecimal(monthly_carrying_cost * months_project);
        total_monthly_paid = roundToDecimal(monthly_interest_i_o * months_project);
        total_financing_cost_fc = roundToDecimal(total_origination_cost+ total_monthly_paid);
        total_acquisition_with_fc_cost_all_in = roundToDecimal(acquisition_cost_ac + total_origination_cost);
        sell_total_monthly_carrying_cost = roundToDecimal(total_monthly_carrying_cost);
        sell_total_rehab_cost = roundToDecimal(total_rehab_cost);
        total_investment = roundToDecimal(total_acquisition_with_fc_cost_all_in + sell_total_monthly_carrying_cost + sell_total_rehab_cost);
        investment_need_from_buyer = roundToDecimal(total_investment - loan_amount_ltv);
        uberous_capital_rate_amount = roundToDecimal(((investment_need_from_buyer * (uberous_capital_rate_interest / 100)) / 12) * months_project);
        rehab_management = roundToDecimal(rehab_mana_lic_fee / 2);
        incomes_office_fees = roundToDecimal(office_fees);
        real_state = roundToDecimal(commission * (valoresData.real_state_rate / 100));
        uberous_investment_return = roundToDecimal(uberous_capital_rate_amount);
        all_office_income = roundToDecimal(rehab_management + incomes_office_fees + real_state);
        profit = roundToDecimal(market_value_arv - office_fees - total_investment - total_sells_cost - uberous_investment_return);
        profit_high = roundToDecimal(profit + market_value_high - market_value_arv);
        profit_low = roundToDecimal(profit + market_value_low - market_value_arv);        
        uberous_capital_cost = roundToDecimal(uberous_investment_return * (valoresData.uberous_capital_cost_rate / 100));
        net_uberous_cpr = roundToDecimal(uberous_investment_return - uberous_capital_cost);
        gross_total_income = roundToDecimal(profit + all_office_income + uberous_investment_return);
        funds_need_to_close = roundToDecimal(total_acquisition_with_fc_cost_all_in - loan_amount_ltv);
        flipping_all_office_income = roundToDecimal(profit + all_office_income + uberous_investment_return);
        all_office_income_high = roundToDecimal(profit_high + all_office_income + uberous_investment_return);
        all_office_income_low = roundToDecimal(profit_low + all_office_income + uberous_investment_return);
        all_office_income_average = roundToDecimal((flipping_all_office_income + all_office_income_high + all_office_income_low) / 3);
        max_purchase_price = roundToDecimal(rent_interest ? (anual_expense / (rent_interest / 100)) - (sell_total_monthly_carrying_cost + sell_total_rehab_cost) : 0.0);

        setProyectoData({
            ...proyectoData, 
            funds_need_to_close, 
            purchase_price, 
            purchase_price_percent, 
            purchase_closing_cost, 
            cash_for_keys_relocation, 
            acquisition_cost_ac, 

            market_value_arv, 
            market_value_high, 
            market_value_low, 
            market_value_average, 
            months_project, 
            market_value_monthly_hoa, 
            office_fees, 

            rehab_cost_no_lic_fee, 
            rehab_mana_lic_fee_percent, 
            city_permits, 
            plans_engineering, 

            sales_commission_percent, 
            sellers_closing_cost, 
            buyers_assistance, 
            certifications_inspections, 

            loan_amount_ltv, 
            loan_amount_percent, 
            yrly_rate, 
            loan_amount_origination_points, 
            other_lenders_fees, 
            other_lenders_fees_percent, 

            sell_price, 
            total_acquisition_with_fc_cost_all_in, 
            sell_total_monthly_carrying_cost, 
            sell_total_rehab_cost, 
            total_investment, 
            investment_need_from_buyer, 

            profit, 
            profit_high, 
            profit_low, 
            flipping_all_office_income, 
            all_office_income_high, 
            all_office_income_low, 
            all_office_income_average, 

            rent_amount, 
            rent_interest, 
            monthly_expense, 
            anual_expense, 
            max_purchase_price, 

            rehab_management, 
            incomes_office_fees, 
            real_state, 
            all_office_income, 

            uberous_investment_return, 
            uberous_capital_cost, 
            net_uberous_cpr, 
            gross_total_income, 

            origination_points, 
            other_fees, 
            total_origination_cost, 
            total_monthly_paid, 
            total_financing_cost_fc, 

            monthly_interest_i_o, 
            property_tax, 
            utilities_gewsi, 
            insurance, 
            maintenance, 
            security, 
            monthly_hoa, 
            monthly_carrying_cost, 
            total_monthly_carrying_cost, 

            material_labor_cost, 
            rehab_mana_lic_fee, 
            rehab_city_permits, 
            rehab_plans_engineering, 
            total_rehab_cost, 

            commission, 
            selling_sellers_closing_cost, 
            selling_buyers_assistance, 
            transfer_taxes, 
            selling_certifications_inspections, 
            total_sells_cost, 

            utilities_gewsi_amount, 
            utilities_gewsi_calculated, 

            insurance_amount, 
            insurance_calculated, 

            maintenance_amount, 
            maintenance_calculated, 

            security_amount, 
            security_calculated, 

            monthly_hoa_maintenance, 
            monthly_hoa_security, 

            uberous_capital_rate_interest, 
            uberous_capital_rate_amount
        });
    };

    const handleGenerarReporte = async() => {
        await dispatch(reporteAnalisis({ id }));
    }

    /* Funciones de guardado */
    const handleCancel = async () => {
        await history.push('/deal/list');
    }

    const handleSave = async () => {
        if (proyectoData.direccion && proyectoData.latitud && proyectoData.longitud) {

            let datos = {
                id: proyectoData.id, 
                direccion: proyectoData.direccion, 
                latitud: proyectoData.latitud, 
                longitud: proyectoData.longitud, 
                purchase_price: proyectoData.purchase_price, 
                purchase_closing_cost: proyectoData.purchase_closing_cost, 
                cash_for_keys_relocation: proyectoData.cash_for_keys_relocation, 
                market_value_arv: proyectoData.market_value_arv, 
                market_value_high: proyectoData.market_value_high, 
                market_value_low: proyectoData.market_value_low, 
                months_project: proyectoData.months_project, 
                market_value_monthly_hoa: proyectoData.market_value_monthly_hoa, 
                office_fees: proyectoData.office_fees, 
                rehab_cost_no_lic_fee: proyectoData.rehab_cost_no_lic_fee, 
                rehab_mana_lic_fee_percent: proyectoData.rehab_mana_lic_fee_percent, 
                city_permits: proyectoData.city_permits, 
                plans_engineering: proyectoData.plans_engineering, 
                sales_commission_percent: proyectoData.sales_commission_percent, 
                sellers_closing_cost: proyectoData.sellers_closing_cost, 
                buyers_assistance: proyectoData.buyers_assistance, 
                certifications_inspections: proyectoData.certifications_inspections, 
                loan_amount_ltv: proyectoData.loan_amount_ltv, 
                yrly_rate: proyectoData.yrly_rate, 
                loan_amount_origination_points: proyectoData.loan_amount_origination_points, 
                other_lenders_fees: proyectoData.other_lenders_fees, 
                rent_amount: proyectoData.rent_amount, 
                rent_interest: proyectoData.rent_interest, 
                utilities_gewsi_amount: proyectoData.utilities_gewsi_amount, 
                insurance_amount: proyectoData.insurance_amount, 
                maintenance_amount: proyectoData.maintenance_amount, 
                security_amount: proyectoData.security_amount, 
                uberous_capital_rate_interest: proyectoData.uberous_capital_rate_interest
            };
            
            setSpinner(true);
            setFormDisabled(true);
            setTimeout(async () => {
                await dispatch(editarAnalisis(datos));

                setSpinner(false);
                setFormDisabled(false);
                history.push('/deal/list');
            }, 0);
            
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    const handleConvertOffer = async () => {
        if (proyectoData.direccion && proyectoData.latitud && proyectoData.longitud) {

            let formData = new FormData();
            formData.append('id_buyer_company', null);
            formData.append('analisis', proyectoData.id);
            formData.append('tipo_proyecto', null);
            formData.append('tipo_construccion', null);
            formData.append('direccion', proyectoData.direccion);
            formData.append('latitud', proyectoData.latitud);
            formData.append('longitud', proyectoData.longitud);
            formData.append('apn', JSON.stringify([]));
            formData.append('terreno', JSON.stringify([]));
            formData.append('terreno_unidad', JSON.stringify([]));
            formData.append('direcciones', JSON.stringify([]));
            formData.append('purchase_price', proyectoData.purchase_price);
            formData.append('asking_price', null);
            formData.append('listing_id', null);
            formData.append('id_tipo_financiamiento', null);
            formData.append('down_payment', null);
            formData.append('tipo_down_payment', null);
            formData.append('id_usuario_responsable', null);
            
            setSpinner(true);
            setFormDisabled(true);
            setTimeout(async () => {
                await dispatch(agregarOferta(formData));

                /* await dispatch(filterProyectos({
                    project_id: proyectoData.tipo_proyecto,
                    construction_id: proyectoData.tipo_construccion,
                    status_id: [null]
                })); */

                await localStorage.removeItem('datos');

                setSpinner(false);
                setFormDisabled(false);
                history.push('/projects/offers');
            }, 0);



            let datos = {
                id: proyectoData.id, 
                direccion: proyectoData.direccion, 
                latitud: proyectoData.latitud, 
                longitud: proyectoData.longitud, 
                purchase_price: proyectoData.purchase_price, 
                purchase_closing_cost: proyectoData.purchase_closing_cost, 
                cash_for_keys_relocation: proyectoData.cash_for_keys_relocation, 
                market_value_arv: proyectoData.market_value_arv, 
                market_value_high: proyectoData.market_value_high, 
                market_value_low: proyectoData.market_value_low, 
                months_project: proyectoData.months_project, 
                market_value_monthly_hoa: proyectoData.market_value_monthly_hoa, 
                office_fees: proyectoData.office_fees, 
                rehab_cost_no_lic_fee: proyectoData.rehab_cost_no_lic_fee, 
                rehab_mana_lic_fee_percent: proyectoData.rehab_mana_lic_fee_percent, 
                city_permits: proyectoData.city_permits, 
                plans_engineering: proyectoData.plans_engineering, 
                sales_commission_percent: proyectoData.sales_commission_percent, 
                sellers_closing_cost: proyectoData.sellers_closing_cost, 
                buyers_assistance: proyectoData.buyers_assistance, 
                certifications_inspections: proyectoData.certifications_inspections, 
                loan_amount_ltv: proyectoData.loan_amount_ltv, 
                yrly_rate: proyectoData.yrly_rate, 
                loan_amount_origination_points: proyectoData.loan_amount_origination_points, 
                other_lenders_fees: proyectoData.other_lenders_fees, 
                rent_amount: proyectoData.rent_amount, 
                rent_interest: proyectoData.rent_interest, 
                utilities_gewsi_amount: proyectoData.utilities_gewsi_amount, 
                insurance_amount: proyectoData.insurance_amount, 
                maintenance_amount: proyectoData.maintenance_amount, 
                security_amount: proyectoData.security_amount, 
                uberous_capital_rate_interest: proyectoData.uberous_capital_rate_interest
            };
            
            setSpinner(true);
            setFormDisabled(true);
            setTimeout(async () => {
                await dispatch(editarAnalisis(datos));

                setSpinner(false);
                setFormDisabled(false);
                history.push('/projects/offers');
            }, 0);
            
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(buscarValoresAnalisis());

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {        
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    useEffect(() => {
        dispatch(detallesAnalisis(id));
    }, [id]);

    useEffect(() => {
        if(valores){
            setValoresData({
                monthly_expense_rate: valores.monthly_expense_rate, 
                real_state_rate: valores.real_state_rate, 
                uberous_capital_cost_rate: valores.uberous_capital_cost_rate, 
                property_tax_rate: valores.property_tax_rate, 
                utilities_gewsi_rate: valores.utilities_gewsi_rate, 
                insurance_rate: valores.insurance_rate, 
                maintenance_rate: valores.maintenance_rate, 
                security_rate: valores.security_rate, 
                transfer_taxes_rate: valores.transfer_taxes_rate, 
                utilities_gewsi_base: valores.utilities_gewsi_base, 
                insurance_base: valores.insurance_base, 
                maintenance_base: valores.maintenance_base, 
                security_base: valores.security_base, 
                monthly_hoa_maintenance_factor: valores.monthly_hoa_maintenance_factor, 
                monthly_hoa_security_factor: valores.monthly_hoa_security_factor, 
                uberous_capital_rate: valores.uberous_capital_rate
            });

            setProyectoData({
                ...proyectoData, 
                utilities_gewsi_amount: valores.utilities_gewsi_base, 
                insurance_amount: valores.insurance_base, 
                maintenance_amount: valores.maintenance_base, 
                security_amount: valores.security_base, 
                uberous_capital_rate_interest: valores.uberous_capital_rate
            });
        }
    }, [valores]);

    useEffect(() => {
        if(detalles){
            let purchase_price = detalles.purchase_price ? parseFloat(detalles.purchase_price) : 0.0;
            let purchase_closing_cost = detalles.purchase_closing_cost ? parseFloat(detalles.purchase_closing_cost) : 0.0;
            let cash_for_keys_relocation = detalles.cash_for_keys_relocation ? parseFloat(detalles.cash_for_keys_relocation) : 0.0;
            let market_value_arv = detalles.market_value_arv ? parseFloat(detalles.market_value_arv) : 0.0;
            let market_value_high = detalles.market_value_high ? parseFloat(detalles.market_value_high) : 0.0;
            let market_value_low = detalles.market_value_low ? parseFloat(detalles.market_value_low) : 0.0;
            let months_project = detalles.months_project ? parseFloat(detalles.months_project) : 0.0;
            let market_value_monthly_hoa = detalles.market_value_monthly_hoa ? parseFloat(detalles.market_value_monthly_hoa) : 0.0;
            let office_fees = detalles.office_fees ? parseFloat(detalles.office_fees) : 0.0;
            let rehab_cost_no_lic_fee = detalles.rehab_cost_no_lic_fee ? parseFloat(detalles.rehab_cost_no_lic_fee) : 0.0;
            let rehab_mana_lic_fee_percent = detalles.rehab_mana_lic_fee_percent ? parseFloat(detalles.rehab_mana_lic_fee_percent) : 0.0;
            let city_permits = detalles.city_permits ? parseFloat(detalles.city_permits) : 0.0;
            let plans_engineering = detalles.plans_engineering ? parseFloat(detalles.plans_engineering) : 0.0;
            let sales_commission_percent = detalles.sales_commission_percent ? parseFloat(detalles.sales_commission_percent) : 0.0;
            let sellers_closing_cost = detalles.sellers_closing_cost ? parseFloat(detalles.sellers_closing_cost) : 0.0;
            let buyers_assistance = detalles.buyers_assistance ? parseFloat(detalles.buyers_assistance) : 0.0;
            let certifications_inspections = detalles.certifications_inspections ? parseFloat(detalles.certifications_inspections) : 0.0;
            let loan_amount_ltv = detalles.loan_amount_ltv ? parseFloat(detalles.loan_amount_ltv) : 0.0;
            let yrly_rate = detalles.yrly_rate ? parseFloat(detalles.yrly_rate) : 0.0;
            let loan_amount_origination_points = detalles.loan_amount_origination_points ? parseFloat(detalles.loan_amount_origination_points) : 0.0;
            let other_lenders_fees = detalles.other_lenders_fees ? parseFloat(detalles.other_lenders_fees) : 0.0;
            let rent_amount = detalles.rent_amount ? parseFloat(detalles.rent_amount) : 0.0;
            let rent_interest = detalles.rent_interest ? parseFloat(detalles.rent_interest) : 0.0;
            let utilities_gewsi_amount = valoresData.utilities_gewsi_base ? parseFloat(valoresData.utilities_gewsi_base) : 0.0;
            let insurance_amount = valoresData.insurance_base ? parseFloat(valoresData.insurance_base) : 0.0;
            let maintenance_amount = valoresData.maintenance_base ? parseFloat(valoresData.maintenance_base) : 0.0;
            let security_amount = valoresData.security_base ? parseFloat(valoresData.security_base) : 0.0;
            let uberous_capital_rate_interest = valoresData.uberous_capital_rate ? parseFloat(valoresData.uberous_capital_rate) : 0.0;

            let purchase_price_percent = roundToDecimal(market_value_arv ? ((purchase_price / market_value_arv) * 100) : 0.0);
            let acquisition_cost_ac = roundToDecimal(purchase_price + purchase_closing_cost + cash_for_keys_relocation);
            let market_value_average = roundToDecimal((market_value_arv + market_value_high + market_value_low) / 3);
            let loan_amount_percent = roundToDecimal(purchase_price ? ((loan_amount_ltv / purchase_price) * 100) : 0.0);
            let other_lenders_fees_percent = roundToDecimal(loan_amount_ltv ? ((other_lenders_fees / loan_amount_ltv) * 100) : 0.0);
            let sell_price = roundToDecimal(market_value_arv);
            let monthly_expense = roundToDecimal(rent_amount * (valoresData.monthly_expense_rate / 100));
            let anual_expense = roundToDecimal(monthly_expense * 12);
            let origination_points = roundToDecimal(loan_amount_ltv * (loan_amount_origination_points / 100));
            let other_fees = roundToDecimal(other_lenders_fees);
            let total_origination_cost = roundToDecimal(origination_points + other_fees);
            let monthly_interest_i_o = roundToDecimal((loan_amount_ltv * (yrly_rate / 100)) / 12);
            let property_tax = roundToDecimal((market_value_arv * (valoresData.property_tax_rate / 100)) / 12);
            let material_labor_cost = roundToDecimal(rehab_cost_no_lic_fee);
            let rehab_mana_lic_fee = roundToDecimal(rehab_cost_no_lic_fee * (rehab_mana_lic_fee_percent / 100));
            let rehab_city_permits = roundToDecimal(city_permits);
            let rehab_plans_engineering = roundToDecimal(plans_engineering);
            let total_rehab_cost = roundToDecimal(material_labor_cost + rehab_mana_lic_fee + rehab_city_permits + rehab_plans_engineering);
            let commission = roundToDecimal(market_value_arv * (sales_commission_percent / 100));
            let selling_sellers_closing_cost = roundToDecimal(sellers_closing_cost);
            let selling_buyers_assistance = roundToDecimal(buyers_assistance);
            let transfer_taxes = roundToDecimal(market_value_arv * (valoresData.transfer_taxes_rate / 100));
            let selling_certifications_inspections = roundToDecimal(certifications_inspections);
            let total_sells_cost = roundToDecimal(commission + selling_sellers_closing_cost + selling_buyers_assistance + transfer_taxes + selling_certifications_inspections);
            let utilities_gewsi_calculated = roundToDecimal(market_value_arv * (valoresData.utilities_gewsi_rate / 100));
            let insurance_calculated = roundToDecimal(market_value_arv * (valoresData.insurance_rate / 100));
            let maintenance_calculated = roundToDecimal((market_value_arv * (valoresData.maintenance_rate / 100)) / 12);
            let security_calculated = roundToDecimal((market_value_arv * (valoresData.security_rate / 100)) / 12);
            let monthly_hoa_maintenance = roundToDecimal(market_value_monthly_hoa / valoresData.monthly_hoa_maintenance_factor);
            let monthly_hoa_security = roundToDecimal(market_value_monthly_hoa / valoresData.monthly_hoa_security_factor);        

            //Dependientes
            let utilities_gewsi = roundToDecimal(utilities_gewsi_amount + utilities_gewsi_calculated);
            let insurance = roundToDecimal((insurance_amount + insurance_calculated) / 12);
            let maintenance = roundToDecimal(maintenance_amount + maintenance_calculated - monthly_hoa_maintenance);
            let security = roundToDecimal(security_amount - security_calculated - monthly_hoa_security);
            let monthly_hoa = roundToDecimal(market_value_monthly_hoa);
            let monthly_carrying_cost = roundToDecimal(monthly_interest_i_o + property_tax + utilities_gewsi + insurance + maintenance + security + monthly_hoa);
            let total_monthly_carrying_cost = roundToDecimal(monthly_carrying_cost * months_project);
            let total_monthly_paid = roundToDecimal(monthly_interest_i_o * months_project);
            let total_financing_cost_fc = roundToDecimal(total_origination_cost+ total_monthly_paid);
            let total_acquisition_with_fc_cost_all_in = roundToDecimal(acquisition_cost_ac + total_origination_cost);
            let sell_total_monthly_carrying_cost = roundToDecimal(total_monthly_carrying_cost);
            let sell_total_rehab_cost = roundToDecimal(total_rehab_cost);
            let total_investment = roundToDecimal(total_acquisition_with_fc_cost_all_in + sell_total_monthly_carrying_cost + sell_total_rehab_cost);
            let investment_need_from_buyer = roundToDecimal(total_investment - loan_amount_ltv);
            let uberous_capital_rate_amount = roundToDecimal(((investment_need_from_buyer * (uberous_capital_rate_interest / 100)) / 12) * months_project);
            let rehab_management = roundToDecimal(rehab_mana_lic_fee / 2);
            let incomes_office_fees = roundToDecimal(office_fees);
            let real_state = roundToDecimal(commission * (valoresData.real_state_rate / 100));
            let uberous_investment_return = roundToDecimal(uberous_capital_rate_amount);
            let all_office_income = roundToDecimal(rehab_management + incomes_office_fees + real_state);
            let profit = roundToDecimal(market_value_arv - office_fees - total_investment - total_sells_cost - uberous_investment_return);
            let profit_high = roundToDecimal(profit + market_value_high - market_value_arv);
            let profit_low = roundToDecimal(profit + market_value_low - market_value_arv);        
            let uberous_capital_cost = roundToDecimal(uberous_investment_return * (valoresData.uberous_capital_cost_rate / 100));
            let net_uberous_cpr = roundToDecimal(uberous_investment_return - uberous_capital_cost);
            let gross_total_income = roundToDecimal(profit + all_office_income + uberous_investment_return);
            let funds_need_to_close = roundToDecimal(total_acquisition_with_fc_cost_all_in - loan_amount_ltv);
            let flipping_all_office_income = roundToDecimal(profit + all_office_income + uberous_investment_return);
            let all_office_income_high = roundToDecimal(profit_high + all_office_income + uberous_investment_return);
            let all_office_income_low = roundToDecimal(profit_low + all_office_income + uberous_investment_return);
            let all_office_income_average = roundToDecimal((flipping_all_office_income + all_office_income_high + all_office_income_low) / 3);
            let max_purchase_price = roundToDecimal(rent_interest ? (anual_expense / (rent_interest / 100)) - (sell_total_monthly_carrying_cost + sell_total_rehab_cost) : 0.0);

            let datos_ingresar = {
                ...proyectoData,
                id: detalles?._id || null, 
                direccion: detalles?.direccion || null,
                latitud: detalles?.latitud || null,
                longitud: detalles?.longitud || null,
                purchase_price, 
                purchase_closing_cost, 
                cash_for_keys_relocation, 
                market_value_arv, 
                market_value_high, 
                market_value_low, 
                months_project, 
                market_value_monthly_hoa, 
                office_fees, 
                rehab_cost_no_lic_fee, 
                rehab_mana_lic_fee_percent, 
                city_permits, 
                plans_engineering, 
                sales_commission_percent, 
                sellers_closing_cost, 
                buyers_assistance, 
                certifications_inspections, 
                loan_amount_ltv, 
                yrly_rate, 
                loan_amount_origination_points, 
                other_lenders_fees, 
                rent_amount, 
                rent_interest, 
                utilities_gewsi_amount, 
                insurance_amount, 
                maintenance_amount, 
                security_amount, 
                uberous_capital_rate_interest, 
                purchase_price_percent, 
                acquisition_cost_ac, 
                market_value_average, 
                loan_amount_percent, 
                other_lenders_fees_percent, 
                sell_price, 
                monthly_expense, 
                anual_expense, 
                max_purchase_price, 
                origination_points, 
                other_fees, 
                total_origination_cost, 
                monthly_interest_i_o, 
                property_tax, 
                material_labor_cost, 
                rehab_mana_lic_fee, 
                rehab_city_permits, 
                rehab_plans_engineering, 
                total_rehab_cost, 
                commission, 
                selling_sellers_closing_cost, 
                selling_buyers_assistance, 
                transfer_taxes, 
                selling_certifications_inspections, 
                total_sells_cost, 
                utilities_gewsi_calculated, 
                insurance_calculated, 
                maintenance_calculated, 
                security_calculated, 
                monthly_hoa_maintenance, 
                monthly_hoa_security, 
                utilities_gewsi, 
                insurance, 
                maintenance, 
                security, 
                monthly_hoa, 
                monthly_carrying_cost, 
                total_monthly_carrying_cost, 
                total_monthly_paid, 
                total_financing_cost_fc, 
                total_acquisition_with_fc_cost_all_in, 
                sell_total_monthly_carrying_cost, 
                sell_total_rehab_cost, 
                total_investment, 
                investment_need_from_buyer, 
                uberous_capital_rate_amount, 
                rehab_management, 
                incomes_office_fees, 
                real_state, 
                uberous_investment_return, 
                all_office_income, 
                profit, 
                profit_high, 
                profit_low, 
                uberous_capital_cost, 
                net_uberous_cpr, 
                gross_total_income, 
                funds_need_to_close, 
                flipping_all_office_income, 
                all_office_income_high, 
                all_office_income_low, 
                all_office_income_average
            };

            setProyectoData(datos_ingresar);

            setCoord({
                lat: parseFloat(detalles?.latitud),
                lng: parseFloat(detalles?.longitud)
            });
        }
    }, [detalles, valoresData]);

    return (
        <Fragment>
            {/* Modal de mapa */}
            <Modal backdrop="static" size="xl" show={mapModal} centered onHide={() => {
                setMapModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Map address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="mapa" className="mapa">
                        <GoogleMap
                            mapContainerStyle={styles.containerStyle}
                            center={coord}
                            zoom={16}
                            onRightClick={e => { handleMapClick(e) }}
                        >
                            <Marker key={coord.lat} position={coord} />
                        </GoogleMap>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={() => setMapModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <form id="contender_form" className="col-md-12" autoComplete="off" onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}}>
                    <input autoComplete="false" name="hidden" type="text" style={{display:'none'}} />

                    {spinner ?  
                            <div className="d-flex justify-content-center">
                                <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="row my-3">
                        <h3 className="col-6">Edit deal</h3>

                        <div className="col-6 btn-group-buttons text-right">
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setMapModal(true)}><i className="fas fa-map"></i> View Map</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => handleGenerarReporte()} disabled={formDisabled ? true : false}><i className="fa fa-file-alt fa-sm"></i> Report</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setMoreOptions(!moreOptions)}><i className={"fas fa-" + ( moreOptions ? 'minus' : 'plus' )}></i> { moreOptions ? 'Fewer' : 'More'} options</button>
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleCancel()} disabled={formDisabled ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                            <button type="button" className="btn btn-success mr-2" onClick={() => handleSave()} disabled={formDisabled ?  true  : false}><i className="fas fa-check fa-sm"></i> Save</button>
                            <button type="button" className="btn btn-success" onClick={() => handleConvertOffer()} disabled={formDisabled ?  true  : false}><i className="fas fa-dollar-sign fa-sm"></i> Convert to offer</button>
                        </div>

                        <div className="col-6 text-right btn-group-actions">
                            <div className="btn btn-group" role="group">
                                <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Actions
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupTools">
                                    <button type="button" className="dropdown-item" onClick={() => setMapModal(true)}>View Map</button>
                                    <button type="button" className="dropdown-item" onClick={() => handleGenerarReporte()} disabled={formDisabled ? true : false}>Report</button>
                                    <button type="button" className="dropdown-item" onClick={() => setMoreOptions(!moreOptions)}>{ moreOptions ? 'Fewer' : 'More'} options</button>
                                    <button type="button" className="dropdown-item" onClick={() => handleCancel()} disabled={formDisabled ? true : false}>Cancel</button>
                                    <button type="button" className="dropdown-item" onClick={() => handleSave()} disabled={formDisabled ?  true  : false}>Save</button>
                                    <button type="button" className="dropdown-item" onClick={() => handleConvertOffer()} disabled={formDisabled ?  true  : false}>Convert to offer</button>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className="row mt-md-5">
                        <div className="offset-xl-2 col-xl-4 offset-lg-1 col-lg-5 mt-3">
                            <div className="card bg-light">
                                <div className="card-header bg-secondary" id="headingTwo">
                                    <h5 className="mb-0 text-white text-left">Deal information</h5>
                                </div>
                                <div className="card-body">

                                    <PlacesAutocomplete
                                        value={proyectoData.direccion}
                                        onChange={handleChangeAddress}
                                        onSelect={handleSelectAddress}
                                    >

                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="form-group row mb-2 mx-0">
                                                <label className="col-md-3 col-form-label px-0"><b>General address:</b></label>
                                                <div className="col-md-9 px-0">
                                                    <input
                                                        {...getInputProps({
                                                            className: 'location-search-input form-control',
                                                            id: 'i-direccion'
                                                        })}
                                                        disabled={formDisabled ? true : false}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const id = uuid();
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style
                                                                    })}
                                                                    key={id}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>

                                    <div className="card-title mt-2">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Purchase</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Price</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="purchase_price"
                                                    className="form-control text-center"
                                                    value={proyectoData.purchase_price || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Closing cost</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="purchase_closing_cost"
                                                    className="form-control text-center"
                                                    value={proyectoData.purchase_closing_cost || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Cash for keys / Relocation</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="cash_for_keys_relocation"
                                                    className="form-control text-center"
                                                    value={proyectoData.cash_for_keys_relocation || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-title mt-2">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Market</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Value (ARV)</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="market_value_arv"
                                                    className="form-control text-center"
                                                    value={proyectoData.market_value_arv || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Expected time project</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="months_project"
                                                    className="form-control text-center"
                                                    value={proyectoData.months_project || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>
                                        
                                        {
                                            moreOptions ?
                                                <Fragment>
                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>High</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="market_value_high"
                                                                className="form-control text-center"
                                                                value={proyectoData.market_value_high || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Low</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="market_value_low"
                                                                className="form-control text-center"
                                                                value={proyectoData.market_value_low || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Monthly HOA</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="market_value_monthly_hoa"
                                                                className="form-control text-center"
                                                                value={proyectoData.market_value_monthly_hoa || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Office fees</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="office_fees"
                                                                className="form-control text-center"
                                                                value={proyectoData.office_fees || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            :
                                                null
                                        }
                                    </div>

                                    <div className="card-title mt-2">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Financial information</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Loan amount</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="loan_amount_ltv"
                                                    className="form-control text-center"
                                                    value={proyectoData.loan_amount_ltv || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>YRLY Rate</b></label>
                                            <div className="col-md-6 pl-0">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-percent"></i>
                                                        </span>
                                                    </div>
                                                    <CurrencyInput
                                                        name="yrly_rate"
                                                        className="form-control text-center"
                                                        value={proyectoData.yrly_rate || ''}
                                                        decimalsLimit={2}
                                                        groupSeparator=","
                                                        decimalSeparator="."
                                                        onValueChange={(value, name) => onNumberChange(name, value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Origination points</b></label>
                                            <div className="col-md-6 pl-0">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-percent"></i>
                                                        </span>
                                                    </div>
                                                    <CurrencyInput
                                                        name="loan_amount_origination_points"
                                                        className="form-control text-center"
                                                        value={proyectoData.loan_amount_origination_points || ''}
                                                        decimalsLimit={2}
                                                        groupSeparator=","
                                                        decimalSeparator="."
                                                        onValueChange={(value, name) => onNumberChange(name, value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Other lender's fees</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="other_lenders_fees"
                                                    className="form-control text-center"
                                                    value={proyectoData.other_lenders_fees || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="card-title mt-2">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Rehab</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Rehab cost</b></label>
                                            <div className="col-md-6 pl-0">
                                                <CurrencyInput
                                                    name="rehab_cost_no_lic_fee"
                                                    className="form-control text-center"
                                                    value={proyectoData.rehab_cost_no_lic_fee || ''}
                                                    decimalsLimit={2}
                                                    groupSeparator=","
                                                    decimalSeparator="."
                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                />
                                            </div>
                                        </div>
                                        
                                        {
                                            moreOptions ?
                                                <Fragment>
                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Mana. / Lic. fee</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-percent"></i>
                                                                    </span>
                                                                </div>
                                                                <CurrencyInput
                                                                    name="rehab_mana_lic_fee_percent"
                                                                    className="form-control text-center"
                                                                    value={proyectoData.rehab_mana_lic_fee_percent || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>City permits</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="city_permits"
                                                                className="form-control text-center"
                                                                value={proyectoData.city_permits || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Plans & engineering</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="plans_engineering"
                                                                className="form-control text-center"
                                                                value={proyectoData.plans_engineering || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            :
                                                null
                                        }
                                    </div>
                        
                                    <div className="card-title mt-2">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Sales cost</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Commission</b></label>
                                            <div className="col-md-6 pl-0">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <i className="fas fa-percent"></i>
                                                        </span>
                                                    </div>
                                                    <CurrencyInput
                                                        name="sales_commission_percent"
                                                        className="form-control text-center"
                                                        value={proyectoData.sales_commission_percent || ''}
                                                        decimalsLimit={2}
                                                        groupSeparator=","
                                                        decimalSeparator="."
                                                        onValueChange={(value, name) => onNumberChange(name, value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            moreOptions ?
                                                <Fragment>
                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Seller's closing cost</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="sellers_closing_cost"
                                                                className="form-control text-center"
                                                                value={proyectoData.sellers_closing_cost || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Buyer's assistance</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="buyers_assistance"
                                                                className="form-control text-center"
                                                                value={proyectoData.buyers_assistance || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Certifications & inspections</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="certifications_inspections"
                                                                className="form-control text-center"
                                                                value={proyectoData.certifications_inspections || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            :
                                                null
                                        }
                                    </div>
                                    
                                    {
                                        moreOptions ?
                                            <Fragment>
                                                <div className="card-title mt-2">
                                                    <div className="text-start">
                                                        <h6 style={styles.titulo}>Rent market</h6>
                                                    </div>
                                                </div>
                                                <div className="row mx-0 mt-2">
                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Amount</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <CurrencyInput
                                                                name="rent_amount"
                                                                className="form-control text-center"
                                                                value={proyectoData.rent_amount || ''}
                                                                decimalsLimit={2}
                                                                groupSeparator=","
                                                                decimalSeparator="."
                                                                onValueChange={(value, name) => onNumberChange(name, value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                                        <label className="col-md-6 col-form-label pl-0"><b>Cap rate target</b></label>
                                                        <div className="col-md-6 pl-0">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" id="basic-addon1">
                                                                        <i className="fas fa-percent"></i>
                                                                    </span>
                                                                </div>
                                                                <CurrencyInput
                                                                    name="rent_interest"
                                                                    className="form-control text-center"
                                                                    value={proyectoData.rent_interest || ''}
                                                                    decimalsLimit={2}
                                                                    groupSeparator=","
                                                                    decimalSeparator="."
                                                                    onValueChange={(value, name) => onNumberChange(name, value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        :
                                            null
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-5 mt-3">
                            <div className="card bg-light">
                                <div className="card-header bg-secondary" id="headingTwo">
                                    <h5 className="mb-0 text-white text-left">Calculated results</h5>
                                </div>
                                <div className="card-body">
                                    <div className="card-title">
                                        <div className="text-start">
                                            <h6 style={styles.titulo}>Summary</h6>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mt-2">
                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Funds need to close</b></label>
                                            <label className="col-md-6 col-form-label px-0">{proyectoData.funds_need_to_close != null ? numberToDecimal('$ ', proyectoData.funds_need_to_close, '') : '$ 0.00'}</label>
                                        </div>                                        

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Total investment</b></label>
                                            <label className="col-md-6 col-form-label px-0">{proyectoData.total_investment != null ? numberToDecimal('$ ', proyectoData.total_investment, '') : '$ 0.00'}</label>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Uberous investment</b></label>
                                            <label className="col-md-6 col-form-label px-0">{proyectoData.investment_need_from_buyer != null ? numberToDecimal('$ ', proyectoData.investment_need_from_buyer, '') : '$ 0.00'}</label>
                                        </div>

                                        <div className="col-md-6 col-6 form-group row mb-2 mx-0 px-0">
                                            <label className="col-md-6 col-form-label pl-0"><b>Flipping profit</b></label>
                                            <label className="col-md-6 col-form-label px-0">{proyectoData.profit != null ? numberToDecimal('$ ', proyectoData.profit, '') : '$ 0.00'}</label>
                                        </div>

                                        {
                                            proyectoData.max_purchase_price ?
                                                <div className="col-md-6 form-group row mb-2 mx-0 px-0">
                                                    <label className="col-md-6 col-form-label pl-0"><b>Max purchase price</b></label>
                                                    <label className="col-md-6 col-form-label px-0">{proyectoData.max_purchase_price != null ? numberToDecimal('$ ', proyectoData.max_purchase_price, '') : '$ 0.00'}</label>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default EditarAnalisis;