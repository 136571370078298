import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Modal, Badge } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

//Actions
import { buscarProveedores, eliminarProveedor } from '../../actions/deudas';
import { setAlert } from '../../actions/alert';

//Components
import ModalColumnas from '../../components/extras/ModalColumnas';

const Proveedores = ({ history }) => {

    const dispatch = useDispatch();

    const { permisos_bills } = useSelector(state => state.auth.user);
    const { proveedores_deuda, proveedores_libres } = useSelector(state => state.deudas);

    const [tarjetasDeudaTabla, setTarjetasDeudaTabla] = useState([]);
    const [tarjetasLibresTabla, setTarjetasLibresTabla] = useState([]);

    //Calculadora
    const [datosCalculatePagos, setDatosCalculatePagos] = useState({
        filtro: false,
        fecha_inicio: new Date(),
        fecha_fin: new Date(),
        monto_minimo: 0,
        monto_total: 0
    });
    const [calculateModal, setCalculateModal] = useState(false);

    //Eliminar
    const [datosDeleteTarjeta, setDatosDeleteTarjeta] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    const [tablaModificar, setTablaModificar] = useState(null);
    const [columnasModificar, setColumnasModificar] = useState([]);

    //Funciones generales
    const numberToDecimal = (number, prefijo, sufijo) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = prefijo + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufijo;
        return finalFormated;
    }

    //Nuevo
    const handleNewTarjeta = () => {
        history.push('/bills/new_provider');
    }

    //Detalles
    const handleDetailsTarjeta = row => {
        history.push('/bills/details_provider/' + row._id);
    }

    //Editar
    const handleEditTarjeta = (id) => {
        history.push('/bills/edit_provider/' + id);
    }

    //Eliminar
    const handleDeleteTarjeta = (tarjeta) => {
        setDatosDeleteTarjeta(tarjeta);
    }

    const handleOpenDeleteTarjeta = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteTarjeta = () => {
        setDatosDeleteTarjeta(null);
        setDeleteModal(false);
    }

    const deleteTarjeta = async () => {
        await dispatch(eliminarProveedor(datosDeleteTarjeta._id));
        await handleCloseDeleteTarjeta();
    }

    //Calcular
    const handleOpenCalculatePagos = () => {
        setCalculateModal(true);
    }

    const calculateTotalPagos = async () => {
        let monto_minimo = 0;
        let monto_total = 0;
        let tarjetas_filtradas = [];
        let fechas_ordenadas = [];

        for (var i = 0; i < proveedores_deuda.length; i++) {
            tarjetas_filtradas.push(proveedores_deuda[i]);
            
            monto_minimo += proveedores_deuda[i].pago_minimo ? parseFloat(proveedores_deuda[i].pago_minimo) : 0;
            monto_total += proveedores_deuda[i].balance ? parseFloat(proveedores_deuda[i].balance) : 0;

            if(proveedores_deuda[i].fecha_pago){
                fechas_ordenadas.push(new Date(proveedores_deuda[i].fecha_pago));
            }
        }

        fechas_ordenadas.sort((date1, date2) => date1 - date2);

        setTarjetasDeudaTabla(tarjetas_filtradas);

        setDatosCalculatePagos({
            ...datosCalculatePagos, 
            filtro: true, 
            fecha_inicio: fechas_ordenadas.length > 0 ? fechas_ordenadas[0] : new Date(),
            fecha_fin: fechas_ordenadas.length > 0 ? fechas_ordenadas[fechas_ordenadas.length - 1] : new Date(),
            monto_minimo,
            monto_total
        });
    }

    const calculatePagos = async () => {
        let monto_minimo = 0;
        let monto_total = 0;

        if(datosCalculatePagos.fecha_inicio && datosCalculatePagos.fecha_fin){
            if(new Date(datosCalculatePagos.fecha_inicio).getTime() <= new Date(datosCalculatePagos.fecha_fin).getTime()){
                let tarjetas_filtradas = [];

                for (var i = 0; i < proveedores_deuda.length; i++) {
                    if(new Date(proveedores_deuda[i].fecha_pago).getTime() >= new Date(datosCalculatePagos.fecha_inicio).getTime() && new Date(proveedores_deuda[i].fecha_pago).getTime() <= new Date(datosCalculatePagos.fecha_fin).getTime()){
                        tarjetas_filtradas.push(proveedores_deuda[i]);
                        
                        monto_minimo += proveedores_deuda[i].pago_minimo ? parseFloat(proveedores_deuda[i].pago_minimo) : 0;
                        monto_total += proveedores_deuda[i].balance ? parseFloat(proveedores_deuda[i].balance) : 0;
                    }
                }

                setTarjetasDeudaTabla(tarjetas_filtradas);

                setDatosCalculatePagos({
                    ...datosCalculatePagos, 
                    filtro: true, 
                    monto_minimo,
                    monto_total
                });
            }else{
                dispatch(setAlert('First enter a valid period', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid perioda', 'danger'));
        }
    }

    const clearFiltros = async () => {
        setTarjetasDeudaTabla(proveedores_deuda);

        setDatosCalculatePagos({
            filtro: false,
            fecha_inicio: new Date(),
            fecha_fin: new Date(),
            monto_minimo: 0,
            monto_total: 0
        });

        setCalculateModal(false);
    }

    const handleCloseCalculatePagos = () => {
        setCalculateModal(false);
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const conditionalRowStyles = [
        {
            when: row => row.tasa_credito <= 5,
            style: {
                backgroundColor: "#5fff5f"
            }
        },
        {
            when: row => row.tasa_credito > 5 && row.tasa_credito <= 10,
            style: {
                backgroundColor: "#c8f7b0"
            }
        }, {
            when: row => row.tasa_credito > 10 && row.tasa_credito <= 15,
            style: {
                backgroundColor: "#ecf7b0"
            }
        }, {
            when: row => row.tasa_credito > 15 && row.tasa_credito <= 20,
            style: {
                backgroundColor: "#f5da8f"
            }
        }, {
            when: row => row.tasa_credito > 20,
            style: {
                backgroundColor: "#f5ab8f"
            }
        }
    ];

    const columnsDeuda = [
        {
            name: 'Name',
            selector: 'nombre',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 0)?.valor 
                : false,
            cell: item => `${item.nombre}`,
        },
        {
            name: 'Contact',
            selector: 'contacto',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 1)?.valor 
                : false,
            cell: item => `${item.contacto}`,
        }, 
        {
            name: 'Phone',
            selector: 'telefono',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 2)?.valor 
                : false,
            cell: item => `${item.telefono}`,
        }, 
        {
            name: 'Cell phone',
            selector: 'celular',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 3)?.valor 
                : false,
            cell: item => `${item.celular}`,
        }, 
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 4)?.valor 
                : false,
            cell: item => `${item.email}`,
        }, 
        {
            name: 'Address',
            selector: 'direccion',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 5)?.valor 
                : false,
            cell: item => `${item.direccion}`,
        }, 
        {
            name: 'Bank',
            selector: 'banco',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 6)?.valor 
                : false,
            cell: item => `${item.banco}`,
        }, 
        {
            name: 'Bank account',
            selector: 'cuenta',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 7)?.valor 
                : false,
            cell: item => `${item.cuenta}`,
        }, {
            name: 'Debt',
            selector: 'deuda_total',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 8)?.valor 
                : false,
            cell: item => `${item.deuda_total !== null ? numberToDecimal(item.deuda_total, '$ ', '') : '-'}`,
        }, {
            name: 'Amount paid',
            selector: 'pago_total',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 9)?.valor 
                : false,
            cell: item => `${item.pago_total !== null ? numberToDecimal(item.pago_total, '$ ', '') : '-'}`,
        }, {
            name: 'Balance',
            selector: 'balance',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 10)?.valor 
                : false,
            cell: item => `${item.balance !== null ? numberToDecimal(item.balance, '$ ', '') : '-'}`,
        }, {
            name: 'Paid',
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresDeuda') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresDeuda')).find(columna => columna.posicion === 11)?.valor 
                : false,
            cell: item => {
                if(item.pagado === true){
                    return (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    if(item.pagado === false){
                        return (
                            <h6><Badge variant="danger">No</Badge></h6>
                        );
                    }else{
                        return (
                            <h6><Badge variant="secondary">Not defined</Badge></h6>
                        );
                    }
                }
            }
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_bills?.edit_provider === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleEditTarjeta(item._id)}>
                            <i className="fa fa-edit" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_bills?.delete_provider === true){
                    return (
                        <button className="btn btn-link text-danger" onClick={() => handleDeleteTarjeta(item)}>
                            <i className="fa fa-times" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
        }
    ];

    const columnsLibre = [
        {
            name: 'Name',
            selector: 'nombre',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 0)?.valor 
                : false,
            cell: item => `${item.nombre}`,
        },
        {
            name: 'Contact',
            selector: 'contacto',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 1)?.valor 
                : false,
            cell: item => `${item.contacto}`,
        }, 
        {
            name: 'Phone',
            selector: 'telefono',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 2)?.valor 
                : false,
            cell: item => `${item.telefono}`,
        }, 
        {
            name: 'Cell phone',
            selector: 'celular',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 3)?.valor 
                : false,
            cell: item => `${item.celular}`,
        }, 
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 4)?.valor 
                : false,
            cell: item => `${item.email}`,
        }, 
        {
            name: 'Address',
            selector: 'direccion',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 5)?.valor 
                : false,
            cell: item => `${item.direccion}`,
        }, 
        {
            name: 'Bank',
            selector: 'banco',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 6)?.valor 
                : false,
            cell: item => `${item.banco}`,
        }, 
        {
            name: 'Bank account',
            selector: 'cuenta',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 7)?.valor 
                : false,
            cell: item => `${item.cuenta}`,
        }, {
            name: 'Debt',
            selector: 'deuda_total',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 8)?.valor 
                : false,
            cell: item => `${item.deuda_total !== null ? numberToDecimal(item.deuda_total, '$ ', '') : '-'}`,
        }, {
            name: 'Amount paid',
            selector: 'pago_total',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 9)?.valor 
                : false,
            cell: item => `${item.pago_total !== null ? numberToDecimal(item.pago_total, '$ ', '') : '-'}`,
        }, {
            name: 'Balance',
            selector: 'balance',
            sortable: true,
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 10)?.valor 
                : false,
            cell: item => `${item.balance !== null ? numberToDecimal(item.balance, '$ ', '') : '-'}`,
        }, {
            name: 'Paid',
            omit: localStorage.getItem('views_deudas_proveedores_proveedoresLibre') ? 
                JSON.parse(localStorage.getItem('views_deudas_proveedores_proveedoresLibre')).find(columna => columna.posicion === 11)?.valor 
                : false,
            cell: item => {
                if(item.pagado === true){
                    return (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    if(item.pagado === false){
                        return (
                            <h6><Badge variant="danger">No</Badge></h6>
                        );
                    }else{
                        return (
                            <h6><Badge variant="secondary">Not defined</Badge></h6>
                        );
                    }
                }
            }
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_bills?.edit_provider === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleEditTarjeta(item._id)}>
                            <i className="fa fa-edit" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_bills?.delete_provider === true){
                    return (
                        <button className="btn btn-link text-danger" onClick={() => handleDeleteTarjeta(item)}>
                            <i className="fa fa-times" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
        }
    ];

    useEffect(() => {
        dispatch(buscarProveedores());
    }, []);

    useEffect(() => {
        if(datosDeleteTarjeta !== null){
            handleOpenDeleteTarjeta();
        }
    }, [datosDeleteTarjeta]);

    useEffect(() => {
        setTarjetasDeudaTabla(proveedores_deuda);
    }, [proveedores_deuda]);

    useEffect(() => {
        setTarjetasLibresTabla(proveedores_libres);
    }, [proveedores_libres]);

    return ( 
            <Fragment>

                <ModalColumnas table={tablaModificar} columns={columnasModificar} reestablecerColumns={() => {setColumnasModificar([]); setTablaModificar(null);}}></ModalColumnas>

                {/* Modal de calculadora */}
                <Modal show={calculateModal} onHide={() => {
                    handleCloseCalculatePagos();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Calculate payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Initial date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker
                                        name="fecha_inicio"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        selected={ datosCalculatePagos.fecha_inicio ? new Date(datosCalculatePagos.fecha_inicio) : new Date() }
                                        onChange={ date => setDatosCalculatePagos({ ...datosCalculatePagos, fecha_inicio: date }) } 
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Final date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker
                                        name="fecha_fin"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        autoComplete="off"
                                        selected={ datosCalculatePagos.fecha_fin ? new Date(datosCalculatePagos.fecha_fin) : new Date() }
                                        onChange={ date => setDatosCalculatePagos({ ...datosCalculatePagos, fecha_fin: date }) } 
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Minimum payment:</label>
                                <p>{ numberToDecimal(datosCalculatePagos.monto_minimo, '$ ', '') }</p>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Total payment:</label>
                                <p>{ numberToDecimal(datosCalculatePagos.monto_total, '$ ', '') }</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseCalculatePagos()}><i className="fas fa-times fa-sm"></i> Close</button>
                        {
                            datosCalculatePagos.filtro ?
                                <button className="btn btn-warning mr-3" onClick={clearFiltros}><i className="fas fa-filter fa-sm"></i> Clear</button>
                            :
                                <></>
                        }
                        <button className="btn btn-primary mr-3" onClick={calculatePagos}><i className="fas fa-check fa-sm"></i> Calculate</button>
                        <button className="btn btn-success" onClick={calculateTotalPagos}><i className="fas fa-check-circle fa-sm"></i> Calculate all</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de eliminacion */}
                <Modal show={deleteModal} onHide={() => {
                    handleCloseDeleteTarjeta();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Credit Card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to delete the card ending <b>{datosDeleteTarjeta?.digitos || ''}</b>?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseDeleteTarjeta()}><i className="fas fa-times fa-sm"></i> No</button>
                        <button className="btn btn-success" onClick={deleteTarjeta}><i className="fas fa-check fa-sm"></i> Yes</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-4 text-left">
                            <h3>Suggested providers to pay</h3>
                            <p className="text-muted">{proveedores_deuda ? proveedores_deuda.length : null} registered providers</p>
                        </div>

                        <div className="col-md-8 text-right">
                            {
                                !permisos_bills?.new_provider ?
                                    null
                                :
                                    <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleNewTarjeta()}><i className="fas fa-truck fa-sm mr-1"></i>  Add provider</button>
                            }
                            <button type="button" className={'btn float-right mb-3 ml-3' + (datosCalculatePagos.filtro ? ' btn-warning' : ' btn-primary')} onClick={() => handleOpenCalculatePagos()}>{datosCalculatePagos.filtro ? <i className="fas fa-filter fa-sm mr-1"></i> : <i className="fas fa-money-bill fa-sm mr-1"></i>}  Calculate payments</button>
                        </div>
                    </div>
                </div>
                
                <div className="row mx-0">
                    <div className="table-responsive pl-2">
                        <div className="text-right">
                            <button type="button" className="btn btn-link float-right" onClick={() => {setColumnasModificar(columnsDeuda); setTablaModificar('views_deudas_proveedores_proveedoresDeuda')}}><i className="fas fa-table fa-sm"></i></button>
                        </div>
                        <DataTable
                            columns={columnsDeuda}
                            data={tarjetasDeudaTabla}
                            noHeader={true}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover={true}
                            onRowDoubleClicked={handleDetailsTarjeta}
                        />
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-4 text-left">
                            <h3>Debt free providers</h3>
                            <p className="text-muted">{proveedores_libres ? proveedores_libres.length : null} registered providers</p>
                        </div>
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="table-responsive pl-2">
                        <div className="text-right">
                            <button type="button" className="btn btn-link float-right" onClick={() => {setColumnasModificar(columnsLibre); setTablaModificar('views_deudas_proveedores_proveedoresLibre')}}><i className="fas fa-table fa-sm"></i></button>
                        </div>
                        <DataTable
                            columns={columnsLibre}
                            data={tarjetasLibresTabla}
                            noHeader={true}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover={true}
                            onRowDoubleClicked={handleDetailsTarjeta}
                        />
                    </div>
                </div>
            </Fragment>
    );
}
 
export default Proveedores;