import { BUSCAR_TERMITES, AGREGAR_TERMITE, SELECCIONAR_TERMITE_BUY, SELECCIONAR_TERMITE_SALE, EDITAR_TERMITE, DETALLES_TERMITE, ELIMINAR_TERMITE, BUSCAR_TERMITES_AGENTES, AGREGAR_TERMITE_AGENTE, AGREGAR_TERMITE_AGENTE_DIRECTO, SELECCIONAR_TERMITE_AGENTE_BUY, SELECCIONAR_TERMITE_AGENTE_SALE, EDITAR_TERMITE_AGENTE, ELIMINAR_TERMITE_AGENTE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarTermites = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/termites`, config);
        dispatch({
            type: BUSCAR_TERMITES,
            payload: res.data.termites
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTermite = (termite) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(termite);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TERMITE,
            payload: res.data.termite
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTermiteMasterlistBuy = (termite) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(termite);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TERMITE,
            payload: res.data.termite
        });

        dispatch({
            type: SELECCIONAR_TERMITE_BUY,
            payload: res.data.termite
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTermiteMasterlistSale = (termite) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(termite);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_TERMITE,
            payload: res.data.termite
        });

        dispatch({
            type: SELECCIONAR_TERMITE_SALE,
            payload: res.data.termite
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarTermite = (termite) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(termite);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/editar`, body, config);
        dispatch({
            type: EDITAR_TERMITE,
            payload: res.data.termite
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesTermite = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/detalles`, body, config);

        dispatch({
            type: DETALLES_TERMITE,
            payload: res.data.termite
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTermite = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_TERMITE,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarAgents = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/agentes`, body, config);
        dispatch({
            type: BUSCAR_TERMITES_AGENTES,
            payload: res.data.agentes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/nuevo-agente`, body, config);
        dispatch({
            type: AGREGAR_TERMITE_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarTermiteAgentMasterlist = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/nuevo-agente/`, body, config);

        dispatch({
            type: AGREGAR_TERMITE,
            payload: {
                _id: res.data.agente.id_termite,
                compania: 'Without name',
                direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_TERMITE_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_TERMITE_AGENTE_BUY,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_TERMITE_AGENTE_SALE,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/editar-agente`, body, config);
        dispatch({
            type: EDITAR_TERMITE_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAgent = (id_termite, id_agente) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id_termite, id_agente });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/termites/eliminar-agente`, body, config);
        dispatch({
            type: ELIMINAR_TERMITE_AGENTE,
            payload:{
                id: id_agente
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}