import { BUSCAR_VESTINGS, AGREGAR_VESTING, EDITAR_VESTING, DETALLES_VESTING, ELIMINAR_VESTING } from '../actions/types';

const initialState = {
    vestings: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case BUSCAR_VESTINGS:
            return {
                ...state,
                vestings: payload
            };
        case AGREGAR_VESTING:
            return {
                ...state,
                vestings: [
                    ...state.vestings, payload
                ]
            };
        case EDITAR_VESTING:
            return {
                ...state,
                vestings: state.vestings.map(
                    vesting => vesting._id === payload._id ? payload : vesting
                )
            };
        case DETALLES_VESTING:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_VESTING:
            return {
                ...state,
                vestings: state.vestings.filter( vesting => vesting._id !== payload.id )
            };        
        default:
            return state;
    }
}