import { BUSCAR_CATEGORIAS_INSPECCION, AGREGAR_CATEGORIA_INSPECCION, EDITAR_CATEGORIA_INSPECCION, ELIMINAR_CATEGORIA_INSPECCION } from '../actions/types';

const initialState = {
    categorias: []
}

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        case BUSCAR_CATEGORIAS_INSPECCION:
            return {
                ...state,
                categorias: payload
            };

        case AGREGAR_CATEGORIA_INSPECCION:
            return {
                ...state,
                categorias: [
                    ...state.categorias, payload
                ]
            };

        case EDITAR_CATEGORIA_INSPECCION:
            return {
                ...state,
                categorias: state.categorias.map(categoria => String(categoria._id) === String(payload._id) ? payload : categoria)
            };

        case ELIMINAR_CATEGORIA_INSPECCION: 
            return {
                ...state,
                categorias: state.categorias.filter(categoria => categoria._id !== payload.id)
            };

        default:
            return state;
    }
}