import { BUSCAR_BUYERS, AGREGAR_BUYER, EDITAR_BUYER, DETALLES_BUYER, ELIMINAR_BUYER } from '../actions/types';

const initialState = {
    buyers: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case BUSCAR_BUYERS:
            return {
                ...state,
                buyers: payload
            };
        case AGREGAR_BUYER:
            return {
                ...state,
                buyers: [
                    ...state.buyers, payload
                ]
            };
        case EDITAR_BUYER:
            return {
                ...state,
                buyers: state.buyers.map(
                    buyer => buyer._id === payload._id ? payload : buyer
                )
            };
        case DETALLES_BUYER:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_BUYER:
            return {
                ...state,
                buyers: state.buyers.filter( buyer => buyer._id !== payload.id )
            };        
        default:
            return state;
    }
}