import { AGREGAR_EMPLEADO, EDITANDO_EMPLEADO, ELIMINAR_EMPLEADO, BUSCAR_EMPLEADOS } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarEmpleados = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/employees`, config);
        dispatch({
            type: BUSCAR_EMPLEADOS,
            payload: res.data.employees
        })
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarEmpleado = (empleado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/employees/nuevo`, empleado, config);
        
        dispatch({
            type: AGREGAR_EMPLEADO,
            payload: res.data.user
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarEmpleado = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/employees/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_EMPLEADO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editandoEmpleado = (id) => dispatch => {
    dispatch({
        type: EDITANDO_EMPLEADO,
        payload: id
    });
}

export const editarEmpleado = (empleado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(empleado);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/employees/editar`, body, config);

        // dispatch({
        //     type: EDITAR_USUARIO,
        //     payload: res.data.user
        // });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}