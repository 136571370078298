import { BUSCAR_RECORDATORIOS, AGREGAR_RECORDATORIO, EDITAR_RECORDATORIO, DETALLES_RECORDATORIO, STATUS_RECORDATORIO, ELIMINAR_RECORDATORIO, EXTERNA_RECORDATORIO } from './types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarRecordatorios = (id) => async dispatch => {
    if(id != ''){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ id });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders`, body, config);
            dispatch({
                type: BUSCAR_RECORDATORIOS,
                payload: {
                    recordatorios: res.data.recordatorios
                }
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: BUSCAR_RECORDATORIOS,
            payload: {
                recordatorios: []
            }
        });
    }
}

export const buscarFechas = (id) => async dispatch => {
    if(id != ''){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ id });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/fechas`, body, config);
            dispatch({
                type: BUSCAR_RECORDATORIOS,
                payload: {
                    recordatorios: res.data.recordatorios
                }
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: BUSCAR_RECORDATORIOS,
            payload: {
                recordatorios: []
            }
        });
    }
}

export const agregarRecordatorio = (recordatorio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(recordatorio);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/nuevo`, body, config);
        dispatch({
            type: AGREGAR_RECORDATORIO,
            payload: res.data.reminder
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const modificarRecordatorio = (recordatorio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(recordatorio);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/editar`, body, config);
        dispatch({
            type: EDITAR_RECORDATORIO,
            payload: res.data.reminder
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesRecordatorio = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/detalles`, body, config);
        dispatch({
            type: DETALLES_RECORDATORIO,
            payload: res.data.reminder
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const statusRecordatorio = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/status`, body, config);
        dispatch({
            type: STATUS_RECORDATORIO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarRecordatorio = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reminders/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_RECORDATORIO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externaRecordatorio = (valor) => async dispatch => {
    dispatch({
        type: EXTERNA_RECORDATORIO,
        payload: valor
    });
}