import { BUSCAR_TERMITES, AGREGAR_TERMITE, EDITAR_TERMITE, DETALLES_TERMITE, ELIMINAR_TERMITE, BUSCAR_TERMITES_AGENTES, AGREGAR_TERMITE_AGENTE, AGREGAR_TERMITE_AGENTE_DIRECTO, EDITAR_TERMITE_AGENTE, ELIMINAR_TERMITE_AGENTE } from '../actions/types';

const initialState = {
    termites: [],
    agents: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case AGREGAR_TERMITE:
            return {
                ...state,
                termites: [
                    ...state.termites, payload
                ]
            };
        case EDITAR_TERMITE:
            return {
                ...state,
                termites: state.termites.map(
                    termite => termite._id === payload._id ? payload : termite
                )
            };
        case DETALLES_TERMITE:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_TERMITE:
            return {
                ...state,
                termites: state.termites.filter( termite => termite._id !== payload.id )
            };
        case BUSCAR_TERMITES:
            return {
                ...state,
                termites: payload
            };
        case BUSCAR_TERMITES_AGENTES:
            return {
                ...state,
                agents: payload
            };
        case AGREGAR_TERMITE_AGENTE:
            return {
                ...state,
                agents: [
                    ...state.agents, payload
                ]
            };
        case AGREGAR_TERMITE_AGENTE_DIRECTO:

            const termite_elegido = state.termites.find(termite => termite._id === payload.id_termite);
            
            if(termite_elegido){
                termite_elegido.agentes = [...termite_elegido.agentes, payload];
            }

            return {
                ...state,
                termites: state.termites.map( 
                    termite => termite._id === payload.id_termite ? termite_elegido : termite
                )
            };
        case EDITAR_TERMITE_AGENTE:
            return {
                ...state,
                agents: state.agents.map(
                    agent => agent._id === payload._id ? payload : agent
                )
            };
        case ELIMINAR_TERMITE_AGENTE:
            return {
                ...state,
                agents: state.agents.filter( agent => agent._id !== payload.id )
            };
        default:
            return state;
    }
}