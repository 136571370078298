import { BUSCAR_ACTIVIDADES, AGREGAR_ACTIVIDAD, EDITAR_ACTIVIDAD, DETALLES_ACTIVIDAD, ELIMINAR_ACTIVIDAD, EXTERNA_ACTIVIDAD, OBTENER_REPORTE_ACTIVIDADES } from '../actions/types';

const initialState = {
    actividades: [],
    detalles: null,
    detalles_reporte: null, 
    externo: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;
 
    switch ( type ) {
        case BUSCAR_ACTIVIDADES:
            return {
                ...state,
                actividades: payload.actividades
            };

        case AGREGAR_ACTIVIDAD:
            return {
                ...state,
                actividades: [
                    payload, ...state.actividades
                ]
            };

        case EDITAR_ACTIVIDAD:
            return {
                ...state,
                actividades: state.actividades.map(actividad => String(actividad._id) === String(payload._id) ? payload : actividad)
            };

        case DETALLES_ACTIVIDAD: 
            return {
                ...state,
                detalles: payload
            };

        case ELIMINAR_ACTIVIDAD: 
            return {
                ...state,
                actividades: state.actividades.filter( actividad => actividad._id !== payload.id )
            }

        case EXTERNA_ACTIVIDAD: 
            return {
                ...state,
                externo: payload
            }

        case OBTENER_REPORTE_ACTIVIDADES: 
            return {
                ...state,
                detalles_reporte: payload
            };
            
        default:
            return state;
    }
}