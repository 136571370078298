import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ModalColumnas = ({ table, columns, reestablecerColumns }) => {

    const [columnasModal, setColumnasModal] = useState(false);
    const [botonEliminar, setBotonEliminar] = useState(false);

    //Nuevo
    const [columnasModificar, setColumnasModificar] = useState([]);

    const handleOpen = () => {
        let columnas_modificar = [];
        //let configuracion = localStorage.getItem(table) || [];

        for (var i = 0; i < columns?.length; i++) {
            if(columns[i].name){
                columnas_modificar.push({
                    nombre: columns[i].name, 
                    posicion: i, 
                    valor: columns[i].omit
                });
            }
        }

        setColumnasModificar(columnas_modificar);
    }

    const onChangeCheckbox = (e) => {
        let columnas_modificar = columnasModificar;
        columnas_modificar.find( columna => String(columna.posicion) === String(e.target.dataset.posicion) ).valor = !e.target.checked;
        setColumnasModificar([...columnas_modificar]);
    }

    const handleSave = async () => {
        await setBotonEliminar(true);
        await localStorage.setItem(table, JSON.stringify(columnasModificar));
        await handleClose();
        await setBotonEliminar(false);
    }

    const handleClose = () => {
        reestablecerColumns();
        setColumnasModal(false);
    }

    useEffect(() => {
        if(columns.length > 0){
            setColumnasModal(true);
        }
    }, [columns]);

    return (
        <Modal show={columnasModal} size="sm" onEntered={() => { handleOpen(); }} onHide={() => { handleClose(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Set columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* To do list */}
                <div className="row">
                    <div className="col-md-12">
                        {
                            columnasModificar?.length > 0 ?
                                columnasModificar?.map(columna => {
                                    return(
                                        <div key={columna.nombre} className="form-check ml-3">
                                            <input className="form-check-input configuration" type="checkbox" checked={!columna.valor} id={"columna_" + columna.posicion} data-posicion={columna.posicion} onChange={onChangeCheckbox}></input>
                                            <label className="form-check-label" htmlFor={"columna_" + columna.posicion}>
                                                {columna.nombre}
                                            </label>
                                        </div>
                                    )
                                })
                            : 
                                ''
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger mr-3" onClick={() => handleClose()} disabled={botonEliminar}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn btn-success" onClick={() => handleSave()} disabled={botonEliminar}><i className="fas fa-check fa-sm"></i> Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalColumnas;