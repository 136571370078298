import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Badge, Modal } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';

import { buscarUsuarios } from '../../actions/usuarios';
import { buscarActividades, eliminarActividad } from '../../actions/actividades';

const Actividades = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const usuarios = useSelector(state => state.usuarios);
    const { user } = useSelector(state => state.auth);
    const { permisos_todolist } = useSelector(state => state.auth.user);
    const { actividades } = useSelector(state => state.actividades);

    //Actividades
    const [deleteTaskModal, setDeleteTaskModal] = useState(false);
    const [filtersModal, setFiltersModal] = useState(false);
    
    const [responsableFilterInformationData, setResponsableFilterInformationData] = useState(null);
    const [usersFilter, setUsersFilter] = useState([]);

    //Filtros
    const [filtersData, setFiltersData] = useState({
        id_responsable: null,
        fecha_inicio: null,
        fecha_fin: null
    });

    const [taskDeleteData, setTaskDeleteData] = useState({
        id: null,
        id_seccion: null,
        nombre_seccion: null,
        nombre: null
    });

    //Eliminar tarea
    const handleDeleteTaskModal = (tarea, nombre_seccion) => {
        setTaskDeleteData({
            ...taskDeleteData,
            id: tarea._id,
            id_seccion: tarea.id_seccion,
            nombre: tarea.nombre,
            nombre_seccion
        });

        setDeleteTaskModal(true);
    }

    const handleDeleteTask = () => {
        if(taskDeleteData.id !== null && taskDeleteData.id !== ''){
            dispatch(eliminarActividad(taskDeleteData.id_seccion, taskDeleteData.id));

            setTaskDeleteData({
                ...taskDeleteData,
                id: null,
                id_seccion: null,
                nombre: null,
                nombre_seccion: null
            });

            handleCloseDeleteTaskModal();
        }
    }

    const handleCloseDeleteTaskModal = () => {
        setDeleteTaskModal(false);

        setTaskDeleteData({
            ...taskDeleteData,
            id: null,
            id_seccion: null,
            nombre: null,
            nombre_seccion: null
        });
    }

    //Filtros
    const handleFiltersModal = () => {
        setFiltersModal(true);

        setFiltersData({
            ...filtersData
        });
    }

    const handleSelectFilterTask = (option) => {
        setResponsableFilterInformationData(option);
        setFiltersData({
            ...filtersData,
            id_responsable: option !== null ? option.value : null
        });
    }

    const handleFilter = () => {
        dispatch(buscarActividades(filtersData));

        handleCloseFiltersModal();
    }

    const handleClearFiltersModal = () => {
        setFiltersData({
            id_responsable: null,
            fecha_inicio: null,
            fecha_fin: null
        });

        setResponsableFilterInformationData(null);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    // Funciones generales
    const timeConvert = (segundos) => {
        let mensaje = 'Without time';

        if(segundos > 0){
            let cantidad_minuto = 1000 * 60;

            let minutos = Math.floor(segundos / cantidad_minuto);
            let horas = 0;
            let dias = 0;
            let semanas = 0;

            if(minutos >= 10080){
                semanas = Math.floor(minutos / 10080);
                minutos = minutos - (semanas * 10080);
            }

            if(minutos >= 1440){
                dias = Math.floor(minutos / 1440);
                minutos = minutos - (dias * 1440);
            }

            if(minutos >= 60){
                horas = Math.floor(minutos / 60);
                minutos = minutos - (horas * 60);
            }

            if(minutos || horas || dias || semanas){
                let texto_minutos = minutos > 0 ? minutos + ' ' + (minutos == 1 ? 'minute' : 'minutes') : '';
                let texto_horas = horas > 0 ? horas + ' ' + (horas == 1 ? 'hour' : 'hours') : '';
                let texto_dias = dias > 0 ? dias + ' ' + (dias == 1 ? 'day' : 'days') : '';
                let texto_semanas = semanas > 0 ? semanas + ' ' + (semanas == 1 ? 'week' : 'weeks') : '';

                mensaje = texto_semanas + ' ' + texto_dias + ' ' + texto_horas + ' ' + texto_minutos;
            }else{
                mensaje = 'Less than minute';
            }
        }

        return mensaje;
    }

    const handleReporte = () => {
        history.push('/actividades/reporte');
    }

    const handleGoBack = () => {
        if(id){
            history.push('/projects/buySale/' + (id || ''));
        }else{
            history.goBack();
        }
    }

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columnsActividad = [
        {
            name: 'Description',
            selector: 'descripcion',
            cell: item => `${item.descripcion}`,
            grow: 2
        },
        {
            name: 'Initial date',
            selector: 'fecha_inicio',
            sortable: true,
            cell: item => `${ item.fecha_inicio ? new Date(item.fecha_inicio).toLocaleDateString('en-US') + ' ' + new Date(item.fecha_inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined' }`,
        }, 
        {
            name: 'Final date',
            selector: 'fecha_fin',
            sortable: true,
            cell: item => `${ item.fecha_fin ? new Date(item.fecha_fin).toLocaleDateString('en-US') + ' ' + new Date(item.fecha_fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined' }`,
        }, 
        {
            name: 'Total time',
            selector: 'descripcion',
            cell: item => `${ timeConvert(item.tiempo_total) }`,
        },
        {
            name: 'Status',
            cell: item => {
                if(item.finalizada){
                    return (
                        <h6><Badge variant="success">Finished</Badge></h6>
                    );
                }else{
                    return (
                        <h6><Badge variant="warning">In process</Badge></h6>
                    );
                }
            }
        }
    ];

    useEffect(() => {
        dispatch(buscarUsuarios());

        return () => {
            dispatch(buscarActividades(null));
        }
    }, []);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    /* useEffect(() => {

        let options = [];

        for (var i = 0; i < actividades?.length; i++) {
            options.push({ value: actividades[i]._id, label: actividades[i].nombre });
        }

        setSectionsFilter(options);
    }, [actividades]); */

    useEffect(() => {
        if('_id' in user){                
            setResponsableFilterInformationData({value: user._id, label: user.nombre});

            setFiltersData({
                ...filtersData,
                id_responsable: user._id, 
            });

            dispatch(buscarActividades({
                ...filtersData, 
                id_responsable: user._id
            }));
        }
    }, [user]);

    return (
        <Fragment>
            {/* Modal de eliminar tarea */}
            <Modal backdrop="static" show={deleteTaskModal} onHide={() => {
                handleCloseDeleteTaskModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Do you want to delete the task "<b>{taskDeleteData.nombre}</b>" of the section "<b>{taskDeleteData.nombre_seccion}</b>"?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseDeleteTaskModal()}><i className="fas fa-solid fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => handleDeleteTask()}><i className="fas fa-solid fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de filtros */}
            <Modal backdrop="static" show={filtersModal} size="md" onHide={() => {
                handleCloseFiltersModal();
            }}>
                <Modal.Header>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Responsible:</label>
                            <Select name="id_responsable" options={usersFilter} value={responsableFilterInformationData} onChange={(value) => handleSelectFilterTask(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>              

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Start date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_inicio" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_inicio !== null ? new Date(filtersData.fecha_inicio) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_inicio: date }) } />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">End date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_fin" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_fin !== null ? new Date(filtersData.fecha_fin) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_fin: date }) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => { handleClearFiltersModal(); handleCloseFiltersModal(); }}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={() => handleFilter()}><i className="fas fa-solid fa-search fa-sm"></i> Find</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-md-6 text-left">
                        
                        {
                            user && responsableFilterInformationData ?
                                user._id != responsableFilterInformationData.value ?
                                    <>
                                        <h3>Activities of</h3>
                                        <h6 className="mb-1">{ responsableFilterInformationData ? responsableFilterInformationData.label : 'Not defined'}</h6>
                                    </>
                                :
                                    <h3>My activities</h3>
                            :
                                <h3>My activities</h3>

                        }
                    </div>

                    <div className="col-md-6 form-group text-right px-0">
                        {
                            !permisos_todolist?.filters ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleFiltersModal()}><i className="fas fa-solid fa-filter fa-sm"></i> Filters</button>
                            
                        }
                        {
                            !permisos_todolist?.calendar ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleReporte()}><i className="fas fa-file-alt fa-sm"></i> View report</button>
                        }
                        <button type="button" className="btn btn-secondary mt-2" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                    </div>
                    <hr/>
                </div>
            </div>

            <div className="row mx-0">
                <div className="table-responsive pl-2">
                    <DataTable
                        columns={columnsActividad}
                        data={actividades}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default Actividades;