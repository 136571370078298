import { BUSCAR_BROKERS, AGREGAR_BROKER, SELECCIONAR_BROKER_BUYER_BUY, SELECCIONAR_BROKER_SELLER_BUY, SELECCIONAR_BROKER_BUYER_SALE, SELECCIONAR_BROKER_SELLER_SALE, EDITAR_BROKER, DETALLES_BROKER, ELIMINAR_BROKER, BUSCAR_BROKERS_AGENTES, AGREGAR_BROKER_AGENTE, AGREGAR_BROKER_AGENTE_DIRECTO, SELECCIONAR_BROKER_AGENTE_BUYER_BUY, SELECCIONAR_BROKER_AGENTE_SELLER_BUY, SELECCIONAR_BROKER_AGENTE_BUYER_SALE, SELECCIONAR_BROKER_AGENTE_SELLER_SALE, EDITAR_BROKER_AGENTE, ELIMINAR_BROKER_AGENTE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarBrokers = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/brokers`, config);
        dispatch({
            type: BUSCAR_BROKERS,
            payload: res.data.brokers
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBroker = (broker) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(broker);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_BROKER,
            payload: res.data.broker
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBrokerMasterlistBuy = (broker, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(broker);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_BROKER,
            payload: res.data.broker
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_BROKER_BUYER_BUY,
                payload: res.data.broker
            });
        }else{
            dispatch({
                type: SELECCIONAR_BROKER_SELLER_BUY,
                payload: res.data.broker
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBrokerMasterlistSale = (broker, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(broker);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_BROKER,
            payload: res.data.broker
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_BROKER_BUYER_SALE,
                payload: res.data.broker
            });
        }else{
            dispatch({
                type: SELECCIONAR_BROKER_SELLER_SALE,
                payload: res.data.broker
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarBroker = (broker) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(broker);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/editar`, body, config);
        dispatch({
            type: EDITAR_BROKER,
            payload: res.data.broker
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesBroker = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/detalles`, body, config);

        dispatch({
            type: DETALLES_BROKER,
            payload: res.data.broker
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarBroker = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_BROKER,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarAgents = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/agentes`, body, config);
        dispatch({
            type: BUSCAR_BROKERS_AGENTES,
            payload: res.data.agentes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo-agente`, body, config);
        dispatch({
            type: AGREGAR_BROKER_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBrokerAgentMasterlistBuy = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo-agente`, body, config);

        dispatch({
            type: AGREGAR_BROKER,
            payload: {
                _id: res.data.agente.id_broker,
                compania: 'Without name',
                direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_BROKER_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_BROKER_AGENTE_BUYER_BUY,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_BROKER_AGENTE_SELLER_BUY,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBrokerAgentMasterlistSale = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/nuevo-agente`, body, config);

        dispatch({
            type: AGREGAR_BROKER,
            payload: {
                _id: res.data.agente.id_broker,
                compania: 'Without name',
                direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_BROKER_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_BROKER_AGENTE_BUYER_SALE,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_BROKER_AGENTE_SELLER_SALE,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/editar-agente`, body, config);
        dispatch({
            type: EDITAR_BROKER_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAgent = (id_broker, id_agente) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id_broker, id_agente });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/brokers/eliminar-agente`, body, config);
        dispatch({
            type: ELIMINAR_BROKER_AGENTE,
            payload:{
                id: id_agente
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}