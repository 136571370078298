import { BUSCAR_SELLERS, AGREGAR_SELLER, EDITAR_SELLER, DETALLES_SELLER, ELIMINAR_SELLER } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarSellers = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/sellers`, config);
        dispatch({
            type: BUSCAR_SELLERS,
            payload: res.data.sellers
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSeller = (seller) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seller);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/sellers/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_SELLER,
            payload: res.data.seller
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarSeller = (seller) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seller);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/sellers/editar`, body, config);
        dispatch({
            type: EDITAR_SELLER,
            payload: res.data.seller
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesSeller = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/sellers/detalles`, body, config);

        dispatch({
            type: DETALLES_SELLER,
            payload: res.data.seller
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarSeller = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/sellers/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_SELLER,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}