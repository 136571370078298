import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editarHolding } from '../../../actions/new_development';
import { detallesProyecto, masterlistProyecto, seleccionarSeccionMasterList } from '../../../actions/proyectos';

const HoldingNewDevelopment = ({ statusClick }) => {

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);

    const [proyectoData, setProyectoData] = useState({
        id: null
    });

    const handleSubmitRent = async (e) => {
        e.preventDefault();

        await dispatch(editarHolding({
            ...proyectoData,
            tipo: 1
        }));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        await dispatch(seleccionarSeccionMasterList('Listing'));
    };

    const handleSubmitCancelRent = async (e) => {
        e.preventDefault();

        await dispatch(editarHolding({
            ...proyectoData,
            tipo: 0
        }));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        await dispatch(seleccionarSeccionMasterList('Holding'));
    };

    const handleSubmitSell = async (e) => {
        e.preventDefault();

        await dispatch(editarHolding({
            ...proyectoData,
            tipo: 2
        }));
        await dispatch(masterlistProyecto(proyectoData.id));
        await dispatch(detallesProyecto(proyectoData.id));
        await dispatch(seleccionarSeccionMasterList('Listing'));
    };

    useEffect(() => {

        if(!proyectos.guardar_cambios){
            setProyectoData({
                ...proyectoData,
                id: proyectos.detalles?.proyecto?._id
            });
        }
    }, [proyectos]);

    return (
        <Fragment>

            <div className="col-md-12 mb-4 text-right btn-group-buttons">
                {
                    proyectos.detalles?.proyecto?.identificador_status < 6 ? 
                        <>
                            <button type="submit" className="btn btn-warning ml-2 btn-sm" onClick={handleSubmitRent}>Move to Rent</button>
                            <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleSubmitSell}>Move to Listing</button>
                        </>
                    :   
                        <>
                            <button type="submit" className="btn btn-warning ml-2 btn-sm" onClick={handleSubmitCancelRent}>Move to Holding</button>
                            <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleSubmitSell}>Move to Listing</button>
                        </>
                }

                {
                    proyectos.detalles?.proyecto?.identificador_status < 6 ? 
                        <button type="submit" className="btn btn-success ml-2 btn-sm" onClick={handleSubmitSell}>Construct it</button>
                    :   null
                }
            </div>

            <div className="col-12 text-center btn-group-actions mb-3">
                <div className="btn btn-group" role="group">
                    <button id="btnGroupTools" type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                        <button className="dropdown-item" onClick={() => statusClick()}>
                            Status list
                        </button>
                        <button className="dropdown-item" onClick={handleSubmitRent}>
                            Rent it
                        </button>
                        <button className="dropdown-item" onClick={handleSubmitSell}>
                            Sell it
                        </button>
                    </div>
                </div>
            </div>

            <div className="col-md-12 text-center mb-3">
                <h4>What would you like to do with this property?</h4>
            </div>

        </Fragment>
    )
}

export default HoldingNewDevelopment;