import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { detallesProyecto } from '../../actions/proyectos';

const HistorialProyecto = ({ history }) => {

    const { id } = useParams();
    const { goBack } = useHistory();

    const dispatch = useDispatch();
    
    const { detalles } = useSelector(state => state.proyectos);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const { loadingData, dataLoaded } = dataStatus;

    const goBackProject = () => {
        history.goBack();
    }

    const tableRow = (items) => {      
        return (
            <tbody>
                {
                    items?.map( (item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>{item.nombre_status}</td>
                                    <td>{item.nombre_usuario}</td>
                                    <td>{new Date(item.fecha_inicio).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>{new Date(item.fecha_fin).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>{item.duracion}</td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(detallesProyecto(id));

        if (loadingData === false && detalles) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (detalles) {
            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [detalles]);

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Status history</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => {goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>

                            <div className="col-md-12 my-4">
                                <div className="row form-group">
                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + detalles?.proyecto?.latitud + '%2C' + detalles?.proyecto?.longitud} target="_blank">{detalles?.proyecto?.direccion}</a>
                                    </label>
                                </div>
                            </div>

                            {
                                detalles.proyecto.registro?.length > 0 ?
                                <div className="col-md-12 row">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Responsable user</th>
                                                    <th scope="col">Start date</th>
                                                    <th scope="col">End date</th>
                                                    <th scope="col">Duration days</th>
                                                </tr>
                                            </thead>
                                            { tableRow(detalles?.proyecto?.registro) }
                                        </table>
                                    </div>
                                </div>
                                :
                                <div className="col-md-10 offset-md-1 text-center mt-4">
                                    <h3>No status history founded.</h3>
                                    <button className="btn btn-primary mt-2" onClick={goBack}>
                                        <i className="fas fa-arrow-left fa-sm"></i> Go back
                                    </button>
                                </div>
                            }
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center">
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center">
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default HistorialProyecto;