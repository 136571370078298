import { AGREGAR_USUARIO, EDITAR_USUARIO, EDITANDO_USUARIO, ELIMINAR_USUARIO, BUSCAR_USUARIOS } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';


export const agregarUsuario = (usuario) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(usuario);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_USUARIO,
            payload: res.data.user
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarUsuarios = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/users`, config);
        dispatch({
            type: BUSCAR_USUARIOS,
            payload: res.data.users
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editandoUsuario = (id) => dispatch => {
    dispatch({
        type: EDITANDO_USUARIO,
        payload: id
    });
}

export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(usuario);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/editar`, body, config);
        await dispatch({
            type: EDITAR_USUARIO,
            payload: res.data.user
        });
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_USUARIO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const validarUsuario = (id, status) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id, status });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/validar/`, body, config);
        dispatch({
            type: EDITAR_USUARIO,
            payload: res.data.user
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}