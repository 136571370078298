import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editarBroker, detallesBroker } from '../../actions/brokers';

const EditarBroker = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const brokers = useSelector(state => state.brokers);

    const [brokerData, setBrokerData] = useState({
        id: '',
        company: '',
        address: ''
    });

    const [spinner, setSpinner] = useState(false);

    const { company, address } = brokerData;

    const onChange = (e) => {
        setBrokerData({
            ...brokerData, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            dispatch(editarBroker(brokerData));
            setSpinner(false);
            history.push('/configuration/brokers');
        }, 0);
    }

    useEffect(() => {
        dispatch(detallesBroker(id));
    }, []);

    useEffect(() => {

        setBrokerData({
            ...brokerData,
            id: brokers.detalles?._id,
            company: brokers.detalles?.compania,
            address: brokers.detalles?.direccion
        });
    }, [brokers]);


    return (
        <div className="row pb-5">
            <form className="col-md-10 offset-md-1">
                <h3 className="mb-3 mt-5">Edit broker</h3>
                <div className="form-group">
                    <label className="font-weight-bold">Name</label>
                    <input type="text" className="form-control" name="company" onChange={e => onChange(e)} value={company}></input>
                </div>
                
                <div className="form-group">
                    <label className="font-weight-bold">Address</label>
                    <input type="text" className="form-control" name="address" onChange={e => onChange(e)} value={address}></input>
                </div>

                {spinner ? 
                    <div className="col-12 mt-3 text-center d-flex justify-content-center">
                        <div className="sk-chase my-5">
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                :
                    null
                }
                
                <div className="mt-3">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Save</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/brokers')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </form>
        </div>
    )
}


export default EditarBroker;