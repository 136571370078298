import { BUSCAR_ESCROWS, AGREGAR_ESCROW, SELECCIONAR_ESCROW_BUY, SELECCIONAR_ESCROW_SALE, EDITAR_ESCROW, DETALLES_ESCROW, ELIMINAR_ESCROW, BUSCAR_ESCROWS_AGENTES, AGREGAR_ESCROW_AGENTE, AGREGAR_ESCROW_AGENTE_DIRECTO, SELECCIONAR_ESCROW_AGENTE_BUY, SELECCIONAR_ESCROW_AGENTE_SALE, EDITAR_ESCROW_AGENTE, ELIMINAR_ESCROW_AGENTE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarEscrows = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/escrows`, config);
        dispatch({
            type: BUSCAR_ESCROWS,
            payload: res.data.escrows
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarEscrow = (escrow) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(escrow);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_ESCROW,
            payload: res.data.escrow
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarEscrowMasterlist = (escrow, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(escrow);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_ESCROW,
            payload: res.data.escrow
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_ESCROW_BUY,
                payload: res.data.escrow
            });
        }else{
            dispatch({
                type: SELECCIONAR_ESCROW_SALE,
                payload: res.data.escrow
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarEscrow = (escrow) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(escrow);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/editar`, body, config);
        dispatch({
            type: EDITAR_ESCROW,
            payload: res.data.escrow
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesEscrow = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/detalles`, body, config);

        dispatch({
            type: DETALLES_ESCROW,
            payload: res.data.escrow
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarEscrow = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_ESCROW,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarAgents = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/agentes`, body, config);
        dispatch({
            type: BUSCAR_ESCROWS_AGENTES,
            payload: res.data.agentes
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/nuevo-agente`, body, config);
        dispatch({
            type: AGREGAR_ESCROW_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarEscrowAgentMasterlist = (agent, tipo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/nuevo-agente/`, body, config);

        dispatch({
            type: AGREGAR_ESCROW,
            payload: {
                _id: res.data.agente.id_escrow,
                compania: 'Without name',
                direccion: '',
                agentes: []
            }
        });

        dispatch({
            type: AGREGAR_ESCROW_AGENTE_DIRECTO,
            payload: res.data.agente
        });

        if(tipo === 1 || tipo === '1'){
            dispatch({
                type: SELECCIONAR_ESCROW_AGENTE_BUY,
                payload: res.data.agente
            });
        }else{
            dispatch({
                type: SELECCIONAR_ESCROW_AGENTE_SALE,
                payload: res.data.agente
            });
        }

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarAgent = (agent) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(agent);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/editar-agente`, body, config);
        dispatch({
            type: EDITAR_ESCROW_AGENTE,
            payload: res.data.agente
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarAgent = (id_escrow, id_agente) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id_escrow, id_agente });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/escrows/eliminar-agente`, body, config);
        dispatch({
            type: ELIMINAR_ESCROW_AGENTE,
            payload:{
                id: id_agente
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}