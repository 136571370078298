import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { agregarTitle } from '../../actions/titles';
import PhoneInput from 'react-phone-input-2';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { v4 as uuid } from 'uuid';

const NuevoTitle = ({ history }) => {

    const dispatch = useDispatch();

    const [titleData, setTitleData] = useState({
        company: '',
        address: '',
        latitud: '',
        longitud: '',
        agents: [{
            officer_name: null,
            email: null,
            cell_phone: null,
            phone: null
        }]
    });

    const [spinner, setSpinner] = useState(false);

    const { company, address, agents } = titleData;

    const onChange = (e) => {
        setTitleData({
            ...titleData, [e.target.name]: e.target.value
        });
    }

    const handleAddAgent = () => {
        let agentesAux = agents;

        let valores = {
            officer_name: null,
            email: null,
            cell_phone: null,
            phone: null
        }

        agentesAux.push(valores);

        setTitleData({
            ...titleData,
            agents: agentesAux
        })
    };

    const handleInputsAgregar = ({ target }) => {
        let id_separado = target.id.split('_');

        let campo = '';
        let indice = '';

        let agentesAux = agents;

        if(id_separado.length < 3){
            campo = id_separado[0];
            indice = id_separado[1];
        }else{
            campo = id_separado[0] + '_' + id_separado[1];
            indice = id_separado[2];
        }

        let value = target.value;

        if(campo === 'phone' || campo === 'cell_phone'){
            value = ('' + value).replace(/\D/g, '');
        }

        agentesAux[indice][[campo]] = value;

        setTitleData({
            ...titleData,
            agents: agentesAux
        })
    }

    console.log(titleData)

    const generateAgentRow = () => {
        let agentes = [];

        for (var x = 0; x < agents.length; x++) {
            agentes.push(
                <div className='row'>
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Name:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                <input name="officer_name" id={'officer_name_' + x} className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={titleData.agents[x].officer_name || ''} />
                            </div>
                        </div>
                    </div>
    
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Email:</label>
                        <div className="contenedor_datepicker">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                                <input name="email" id={'email_' + x} className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={titleData.agents[x].email || ''} />
                            </div>
                        </div>
                    </div>
    
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Cell phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput name="cell_phone" className="form-control" country={'us'} value={titleData.agents[x].cell_phone || ''} inputProps={{ id: 'cell_phone_' + x, onChange: e => handleInputsAgregar(e)}} />
                        </div>
                    </div>
    
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Phone:</label>
                        <div className="contenedor_datepicker">
                            <PhoneInput name="phone" className="form-control" country={'us'} value={titleData.agents[x].phone || ''} inputProps={{ id: 'phone_' + x, onChange: e => handleInputsAgregar(e)}} />
                        </div>
                    </div>
    
                </div>
            )
        }
        
        return agentes;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            await dispatch(agregarTitle(titleData));
            setSpinner(false);
            history.push('/configuration/titles');
        }, 0);
    }

    const handleChange = address => {
        setTitleData({
            ...titleData, address
        });
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (
                setTitleData({
                    ...titleData, address: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };



    return (
        <form className="px-3">
            <div className="row pb-5">
                <div className="col-md-3">
                    <h3 className="mb-3 mt-5">Create new title</h3>
                </div>

                <div className="col-md-9 mt-5">
                        <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Create</button>
                        <button type="button" className="btn btn-primary float-right ml-3" onClick={() => handleAddAgent()} disabled={spinner ? true : false}><i className="fas fa-plus fa-sm"></i> Add new agent</button>
                        <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/titles')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </div>

            {spinner ? 
                <div className="col-12 mt-3 text-center d-flex justify-content-center">
                    <div className="sk-chase my-5">
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                    </div>
                </div>
            :
                null
            }

            <div className="row">
                <div className="col-md-4 form-group">
                    <label className="font-weight-bold">Company</label>
                    <input type="text" className="form-control" name="company" onChange={e => onChange(e)} value={company}></input>
                </div>

                <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className='col-md-8 form-group'>
                            <label className="font-weight-bold">Address:</label>
                            <input
                                {...getInputProps({
                                    className: 'location-search-input form-control',
                                    id: 'i-direccion'
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const id = uuid();
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            key={id}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <h3 className="mb-3 mt-5">Agents of new title</h3>
                </div>
            </div>

            { generateAgentRow(agents) }
        </form>
    )
}


export default NuevoTitle;