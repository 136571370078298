import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Actions
import { setAlert } from '../../actions/alert';
import { agregarActividad, detallesActividad, editarActividad, finalizarActividad, externaActividad } from '../../actions/actividades';

const ModalTask = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const { detalles, externo } = useSelector(state => state.actividades);

    //Nuevo
    const [actividadData, setActividadData] = useState({
        id: null,
        descripcion: null
    });

    const handleOpenNewActivityModal = () => {
        dispatch(detallesActividad());
    }

    const handleViewActivities = () => {
        history.push('/actividades/listado');

        handleCloseNewActivityModal();
    }

    const handleAddActivity = () => {
        if(actividadData.descripcion){
            if(actividadData.id){
                dispatch(editarActividad(actividadData));
            }else{
                dispatch(agregarActividad(actividadData));
            }

            handleCloseNewActivityModal();
        }else{
            dispatch(setAlert('Enter a valid description', 'danger'));
        }
    }

    const handleFinishActivity = () => {
        dispatch(finalizarActividad(actividadData.id));

        handleCloseNewActivityModal();
    }

    const handleClearAddActivityModal = () => {
        setActividadData({
            ...actividadData,
            id: null,
            descripcion: null
        });
    }

    const handleCloseNewActivityModal = () => {
        handleClearAddActivityModal();
        
        dispatch(externaActividad(false));
    }

    useEffect(() => {
        setActividadData({
            ...actividadData,
            id: detalles?._id || null,
            descripcion: detalles?.descripcion || null
        });
    }, [detalles]);

    return (
        <Modal backdrop="static" show={externo} size="lg" onEntered={() => handleOpenNewActivityModal()} onHide={() => handleCloseNewActivityModal()}>
            <Modal.Header closeButton>
                <Modal.Title>{detalles ? 'Active' : 'Create'} activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Description:</label>
                        <textarea className="form-control" name="descripcion" value={actividadData.descripcion || ''} onChange={(e) => {setActividadData({...actividadData, descripcion: e.target.value})}}></textarea>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    detalles ? 
                        <button className="btn btn-danger mr-2" onClick={() => handleFinishActivity()}><i className="fas fa-solid fa-times fa-sm"></i> Finish</button>
                    :
                        <></>
                }
                <button className="btn btn-success mr-2" onClick={() => handleAddActivity()}><i className={"fas fa-sm fa-solid fa-" + (detalles ? 'save' : 'plus')}></i> {detalles ? 'Update' : 'Create'}</button>
                <button className="btn btn-primary" onClick={() => handleViewActivities()}><i className="fas fa-sm fa-solid fa-list"></i> View activities</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalTask;