import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.css';

import Navbar from './Navbar';

const Layout = () => {

    const { loading, isAuthenticated, isPublic } = useSelector(state => state.auth);

    const layoutAdmin = (
        <Fragment>
            <Navbar></Navbar>
        </Fragment>
    );

    const layoutGuest = (<Fragment></Fragment>);

    if(!loading && isAuthenticated && !isPublic){
        return layoutAdmin;
    } else{
        return layoutGuest;
    }
}

export default Layout;