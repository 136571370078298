import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { setAlert } from '../../actions/alert';
import { buscarSecciones, agregarSeccion, editarSeccion, eliminarSeccion } from '../../actions/secciones_todolist';

const TodolistSections = ({ history }) => {

    const secciones_todolist = useSelector(state => state.secciones_todolist);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    const [newSectionData, setNewSectionData] = useState({
        nombre: null,
        descripcion: null
    });

    const [editSectionData, setEditSectionData] = useState({
        id: null,
        nombre: null,
        descripcion: null
    });

    const [deleteSectionData, setDeleteSectionData] = useState({
        id: null,
        nombre: null
    });

    const [newSectionModal, setNewSectionModal] = useState(false);
    const [editSectionModal, setEditSectionModal] = useState(false);
    const [deleteSectionModal, setDeleteSectionModal] = useState(false);

    //Nuevo
    const handleAgregarSeccion = id => {
        setNewSectionModal(true);
    }

    const handleInputsAgregar = ({ target }) => {
        setNewSectionData({
            ...newSectionData,
            [target.name]: target.value
        });
    }

    const handleCloseAgregarSeccion = () => {
        setNewSectionData({
            ...newSectionData,
            nombre: null,
            descripcion: null
        });

        setNewSectionModal(false);
    }

    const handleSubmitAgregar = async (e) => {
        e.preventDefault();

        try {
            if(newSectionData.nombre !== '' && newSectionData.nombre !== null){
                if(newSectionData.descripcion !== '' && newSectionData.descripcion !== null){
                    await dispatch(agregarSeccion(newSectionData));

                    setNewSectionModal(false);

                    setNewSectionData({
                        ...newSectionData,
                        nombre: null,
                        descripcion: null
                    });
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('Section name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Editar
    const handleEditarSeccion = seccion => {
        setEditSectionModal(true);

        setEditSectionData({
            ...editSectionData,
            id: seccion._id,
            nombre: seccion.nombre,
            descripcion: seccion.descripcion
        });
    }

    const handleInputsEditar = ({ target }) => {
        setEditSectionData({
            ...editSectionData,
            [target.name]: target.value
        });
    }

    const handleCloseEditarSeccion = () => {
        setEditSectionData({
            ...editSectionData,
            id: null,
            nombre: null,
            descripcion: null
        });

        setEditSectionModal(false);
    }

    const handleSubmitEditar = async (e) => {
        e.preventDefault();

        try {
            if(editSectionData.nombre !== '' && editSectionData.nombre !== null){
                if(editSectionData.descripcion !== '' && editSectionData.descripcion !== null){
                    await dispatch(editarSeccion(editSectionData));

                    await setEditSectionModal(false);

                    await setEditSectionData({
                        ...editSectionData,
                        id: null,
                        nombre: null,
                        descripcion: null
                    });
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                await dispatch(setAlert('Section name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Eliminar
    const handleEliminarSeccion = seccion => {
        setDeleteSectionModal(true);

        setDeleteSectionData({
            ...deleteSectionData,
            id: seccion._id,
            nombre: seccion.nombre
        });
    }

    const handleCloseEliminarSeccion = () => {
        setDeleteSectionData({
            ...deleteSectionData,
            id: null,
            nombre: null
        });

        setDeleteSectionModal(false);
    }

    const handleSubmitEliminar = async (e) => {
        e.preventDefault();

        try {
            await dispatch(eliminarSeccion(deleteSectionData.id));

            setDeleteSectionModal(false);

            setDeleteSectionData({
                ...deleteSectionData,
                id: null,
                nombre: null
            });
        } catch (error) {
            return false;
        }
    }

    const tableRow = (items) => {
        if(items){
            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td> {item.nombre || '-'} </td>
                                            <td> {item.descripcion || '-'} </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.edit_section_todolist ? 
                                                    null 
                                                    :
                                                    <button className="btn btn-link text-danger" onClick={() => handleEditarSeccion(item)}>Edit</button>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.delete_section_todolist ?
                                                    null
                                                    :
                                                    <button className="btn btn-link text-danger" onClick={() => handleEliminarSeccion(item)}>Delete</button>
                                                }
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">No sections registered.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">No sections registered.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    useEffect(() => {
        dispatch(buscarSecciones());
    }, []);

    return (
        <Fragment>
            {/* Modal de nueva seccion */}
            <Modal backdrop="static" show={newSectionModal} onHide={() => {
                setNewSectionModal(false);
                setNewSectionData({
                    ...newSectionData,
                    nombre: null,
                    descripcion: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New to do list section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input name="nombre" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newSectionData.nombre || ''} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea name="descripcion" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newSectionData.descripcion || ''}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarSeccion()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitAgregar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar seccion */}
            <Modal backdrop="static" show={editSectionModal} onHide={() => {
                setEditSectionModal(false);
                setEditSectionData({
                    ...editSectionData,
                    id: null,
                    nombre: null,
                    descripcion: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit to do list section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input name="nombre" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editSectionData.nombre || ''} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea name="descripcion" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editSectionData.descripcion || ''}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarSeccion()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEditar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminar seccion */}
            <Modal backdrop="static" show={deleteSectionModal} onHide={() => {
                setDeleteSectionModal(false);
                setDeleteSectionData({
                    ...deleteSectionData,
                    id: null,
                    nombre: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete to do list section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Do you want to delete the {deleteSectionData.nombre || ''} section?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEliminarSeccion()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEliminar}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Sections of to do list</h3>
                            <p className="text-muted"> {secciones_todolist?.secciones?.length || 0} registered sections</p>
                        </div>
                        <div className="col-md-4 text-right">
                            {
                                !permisos_configuration?.add_section_todolist ? 
                                null 
                                : 
                                <button type="button" className="btn btn-success text-right" onClick={() => handleAgregarSeccion()}><i className="fas fa-plus fa-sm"></i> Add section</button>
                            }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                tableRow(secciones_todolist?.secciones)
                            }
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );    
}

export default TodolistSections;