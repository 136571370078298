import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { access_public } from '../../actions/auth';
import { detallesProyectoPublico, filesProyectoPublico, detallesFileProyectoPublico } from '../../actions/proyectos';

const styles = {
    panel_tab: {
        backgroundColor: '#F3F2F2'
    },
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    tableHeader: {
        display: 'flex',
        backgroundColor: '#343A40',
        color: 'white',
    },
    textTableHeader: {
        padding: '2% 2%',
        marginBottom: '0'
    },
    buttonIcon: {
        fontSize: 18,
    },
    btnSnapShot: {
        cursor: 'pointer'
    },
}

const PublicFiles = ({ history }) => {

    const { id, token } = useParams();

    const dispatch = useDispatch();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);
    const detalles_archivo = useSelector(state => state.proyectos?.detalles_file);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [actionsFileModal, setActionsFileModal] = useState(false);
    const [flag, setFlag] = useState(false);

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const { loadingData, dataLoaded } = dataStatus;

    const handleIconoArchivo = async (id) => {
        await setFlag(true);
        await dispatch(detallesFileProyectoPublico(id));
    }

    const handleAccionesArchivo = async (id) => {
        await dispatch(detallesFileProyectoPublico(id));
        await setActionsFileModal(true);
    }

    const handleCloseAccionesArchivo = () => {
        setActionsFileModal(false);
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setActionsFileModal(false);
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            setActionsFileModal(false);
            window.open(detalles_archivo.url);
        }
    }

    const tableRow = (items) => {

        let extension = 'file';
        
        return (
            <tbody>
                {
                    items.map( (item) => {
                        switch(item.extension){
                            case 'pdf':
                                extension = 'file-pdf';
                                break;

                            case 'jpeg':
                            case 'jpg':
                            case 'png':
                            case 'gif':
                                extension = 'file-image';
                                break;

                            case 'mp4':
                            case 'wmv':
                            case 'mov':
                                extension = 'file-video';
                                break;

                            case 'mp3':
                            case 'm4a':
                            case 'aac':
                                extension = 'file-audio';
                                break;

                            case 'doc':
                            case 'docx':
                                extension = 'file-word';
                                break;

                            case 'xls':
                            case 'xlsx':
                                extension = 'file-excel';
                                break;

                            default:
                                extension = 'file';
                                break;
                        }

                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>{item.nombre}</td>
                                    <td>{item.seccion}</td>
                                    <td>
                                        <button className="btn" onClick={() => {handleIconoArchivo(item._id)}}>
                                            <i className={'fa fa-' + extension} style={styles.buttonIcon} /> {item.extension} 
                                        </button>
                                    </td>
                                    <td>{new Date(item.fecha).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            setFlag(false);
                                            handleAccionesArchivo(item._id);
                                        }}>Options</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    useEffect(() => {
        dispatch(access_public());

        dispatch(detallesProyectoPublico(id));

        dispatch(filesProyectoPublico(id, token));

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [proyectos]);

    useEffect(() => {        
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    return (
        <div className="row pb-5">

            <div className="col-md-12">
                <div className="d-flex justify-content-between">
                    <h3 className="mb-3 mt-5">Shared folder</h3>
                </div>
                <hr/>
            </div>
            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>

                            {/* Modal de acciones de archivo */}
                            <Modal backdrop="static" show={actionsFileModal} onHide={() => {
                                setActionsFileModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Actions of the file</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p style={styles.info}>Select one of the following options.</p>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAccionesArchivo()}><i className="fas fa-times fa-sm"></i> Close</button>
                                    <button className="btn btn-primary" onClick={() => handleOpenFile()}><i className="fas fa-file fa-sm"></i> Open</button>
                                </Modal.Footer>
                            </Modal>

                            {imageData.modal && (
                                <Lightbox
                                    mainSrc={imageData.src}
                                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                                />
                            )}

                            {
                                proyectos.files?.length > 0 ?
                                    <Fragment>
                                        <div className="col-md-12 mb-3">
                                            <div className="row form-group">
                                                <label className="col-lg-4 col-sm-12">
                                                    <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                                </label>

                                                <label className="col-lg-4 col-sm-12">
                                                    <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                                </label>

                                                <div className="col-lg-4 col-sm-12">
                                                    <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                                </div>

                                                <label className="col-lg-8 col-sm-12">
                                                    <span className="font-weight-bold mr-2">Address:</span> {detalles?.proyecto?.direccion}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 row">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Section</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Upload date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {tableRow(proyectos.files)}
                                                </table>
                                            </div>
                                        </div>
                                    </Fragment>
                                :
                                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                                        <h3>No share files founded, please contact Uberous team.</h3>
                                        <button className="btn btn-primary mt-2" onClick={() => history.push('/')}>
                                            <i className="fas fa-home fa-sm"></i> Go to login
                                        </button>
                                    </div>
                            }
                                
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No project was selected, please contact Uberous team.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/')}>
                                <i className="fas fa-home fa-sm"></i>Go to login
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default PublicFiles;