import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import { Modal } from 'react-bootstrap';

//Actions
import { agregarGasto, externoGasto } from '../../actions/gastos';
import { setAlert } from '../../actions/alert';

const ModalExpense = () => {

    const dispatch = useDispatch();

    const { externo } = useSelector(state => state.gastos);
    const proyectos = useSelector(state => state.proyectos);

    const [dataTabla, setDataTabla] = useState({
        proyectosTabla: null
    });

    const { proyectosTabla } = dataTabla;

    //Nuevo
    const [nuevoRef, setNuevoRef] = useState([createRef()]);

    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        descripcion: null,
        monto: null,
        files: [null],
        extensiones: [null]
    });

    const [botonNuevo, setBotonNuevo] = useState(false);

    const handleAddFile = () => {
        let nueva_referencia = createRef();

        setNuevoRef([...nuevoRef, nueva_referencia]);

        let files = datosNuevo.files;
        let extensiones = datosNuevo.extensiones;

        files.push(null);
        extensiones.push(null);

        setDatosNuevo({
            ...datosNuevo,
            files: files,
            extensiones: extensiones
        });
    }

    const handleViewFile = (index) => {
        if(index !== null){
            const url = URL.createObjectURL(index);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    const onNumberChangeNuevo = (name, value) => {
        setDatosNuevo({
            ...datosNuevo, [name]: value
        });
    };

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const onChangeFileNuevo = (index) => {
        let files = datosNuevo.files;
        let extensiones = datosNuevo.extensiones;

        if(nuevoRef[index]?.current?.files?.length > 0){
            let nombre_separado = nuevoRef[index]?.current?.files[0]?.name?.split('.');

            files[index] = nuevoRef[index].current.files[0];
            extensiones[index] = nombre_separado[nombre_separado.length - 1].toLowerCase();
        }else{
            files[index] = null;
            extensiones[index] = null;
        }

        setDatosNuevo({
            ...datosNuevo,
            files: files,
            extensiones: extensiones
        });
    }

    const nuevoGasto = async (method) => {

        if(datosNuevo.descripcion){
            if(datosNuevo.monto !== null && datosNuevo.monto > 0){

                await setBotonNuevo(true);
                let formData = new FormData();
                formData.append('id_proyecto', datosNuevo.id_proyecto);
                formData.append('descripcion', datosNuevo.descripcion);
                formData.append('monto', datosNuevo.monto);
                
                for (var i = 0; i < datosNuevo.files.length; i++) {
                    formData.append('files', datosNuevo.files[i]);
                    formData.append('extensiones', datosNuevo.extensiones[i]);
                }

                await dispatch(agregarGasto(formData));

                await handleClearNuevoGasto();

                if(method === 'close'){
                    handleCloseNuevoGasto();
                }
            }else{
                dispatch(setAlert('First enter a valid amount', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a description', 'danger'));
        }

        await setBotonNuevo(false);
    }

    const handleCloseNuevoGasto = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            descripcion: null,
            monto: null,
            files: [null],
            extensiones: [null]
        });

        setNuevoRef([createRef()]);

        dispatch(externoGasto(false));
    }

    const handleClearNuevoGasto = () => {
        setDatosNuevo({
            ...datosNuevo,
            descripcion: null,
            monto: null,
            files: [null],
            extensiones: [null]
        });

        setNuevoRef([createRef()]);
    }

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosTabla: proyectos.listado
        });

        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: proyectos.id_proyecto_dashboard
        });
    }, [proyectos]);

    return (
        <Modal show={externo} onHide={() => {
            handleCloseNuevoGasto();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>New expense</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Project:</label>
                        <select className="form-control" value={datosNuevo.id_proyecto || ''} name="id_proyecto" onChange={(e) => onChangeNuevo(e)}>
                            <option value={null}>General</option>
                            {
                                proyectosTabla?.length > 0 ?
                                    proyectosTabla?.map(proyecto => {

                                        let selected = false;

                                        if(String(proyectos.id_proyecto_dashboard) === String(proyecto._id)){
                                            selected = true;
                                        }

                                        return(
                                            <option key={proyecto._id} value={ proyecto._id } selected={ selected }>
                                                { proyecto.direccion }
                                            </option>
                                        )
                                    })
                                : ''
                            } 
                        </select>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Description:</label>
                        <textarea className="form-control" value={datosNuevo.descripcion || ''} name="descripcion" onChange={e => onChangeNuevo(e)}></textarea>
                    </div>

                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Amount:</label>
                        <CurrencyInput
                          name="monto"
                          className="form-control text-center"
                          value={datosNuevo.monto || ''}
                          decimalsLimit={2}
                          groupSeparator=","
                          decimalSeparator="."
                          onValueChange={(value, name) => onNumberChangeNuevo(name, value)}
                        />
                    </div>
                    
                    {
                        nuevoRef.map((referencia, index, array) => {
                            return (
                                <div key={'input_file_' + index} className="col-md-12 form-group">
                                    <label className="font-weight-bold">File {index + 1}:</label>
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={referencia} onChange={() => onChangeFileNuevo(index)} aria-describedby="view_file_newFile"/>
                                            <label className="custom-file-label">{datosNuevo.files[index] ? 'File Selected' : 'Choose file'}</label>
                                        </div>
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevo.files[index])}><i class="fas fa-eye"></i> View File</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn ml-2 btn-primary mr-3" onClick={() => handleAddFile()} disabled={botonNuevo}><i className="fas fa-file-medical fa-sm"></i> New file</button>
                <button className="btn ml-2 btn-danger mr-3" onClick={() => handleCloseNuevoGasto()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                <button className="btn ml-2 btn-info" onClick={() => {nuevoGasto('new')}} disabled={botonNuevo}><i className="fas fa-plus fa-sm"></i> Save and New</button>
                <button className="btn ml-2 btn-success" onClick={() => {nuevoGasto('close')}} disabled={botonNuevo} ><i className="fas fa-check fa-sm"></i> Save and Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalExpense;