import React, { useState, useEffect, Fragment, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { detallesProyecto, inspectionsProyecto, agregarInspectionProyecto, editarInspectionProyecto, detallesInspectionProyecto, eliminarInspectionProyecto, reportInspecciones, repairsProyecto } from '../../actions/proyectos';

const styles = {
    panel_tab: {
        backgroundColor: '#F3F2F2'
    },
    hr_divide: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    tableHeader: {
        display: 'flex',
        backgroundColor: '#343A40',
        color: 'white',
    },
    textTableHeader: {
        padding: '2% 2%',
        marginBottom: '0'
    },
    buttonIcon: {
        fontSize: 18,
    },
    btnSnapShot: {
        cursor: 'pointer'
    },
}

const Inspections = ({ history }) => {

    const { id } = useParams();
    const { goBack } = useHistory();

    const dispatch = useDispatch();
    
    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);
    const categorias_inspeccion = useSelector(state => state.categorias_inspeccion);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [newInspectionData, setNewInspectionData] = useState({
        id_proyecto: null,
        id_categoria: null,
        nombre: '',
        descripcion: '',
        archivos: null
    });

    const [editInspectionData, setEditInspectionData] = useState({
        id: '',
        id_categoria: null,
        nombre: '',
        descripcion: '',
        archivos: null
    });

    const [deleteInspectionData, setDeleteInspectionData] = useState({
        id: '',
        nombre: ''
    });

    const [detailsInspectionData, setDetailsInspectionData] = useState({
        id: '',
        nombre: '',
        creador: '',
        descripcion: '',
        fecha_creacion: null,
        total_reparaciones: null,
        archivos: null,
        status: null
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [statusSaveInspection, setStatusSaveInspection] = useState(true);
    const [newInspectionModal, setNewInspectionModal] = useState(false);
    const [editInspectionModal, setEditInspectionModal] = useState(false);
    const [deleteInspectionModal, setDeleteInspectionModal] = useState(false);
    const [detailsInspectionModal, setDetailsInspectionModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [flagDetails, setFlagDetails] = useState(false);

    let files_ref = createRef();
    let files_edit_ref = createRef();

    const { loadingData, dataLoaded } = dataStatus;

    const handleAgregarInspeccion = id => {
        setNewInspectionModal(true);
    }

    const handleCloseAgregarInspeccion = () => {
        setNewInspectionData({
            ...newInspectionData,
            id_categoria: null,
            nombre: '',
            descripcion: '',
            archivos: null
        });

        setNewInspectionModal(false);
    }

    const handleInputs = ({ target }) => {
        setNewInspectionData({
            ...newInspectionData,
            [target.name]: target.value
        });
    }

    const handleInputsEditar = ({ target }) => {
        setEditInspectionData({
            ...editInspectionData,
            [target.name]: target.value
        });
    }

    const onChangeFiles = () => {
        setNewInspectionData({
            ...newInspectionData,
            archivos: files_ref.current.files
        });
    }

    const onChangeFilesEditar = () => {
        setEditInspectionData({
            ...editInspectionData,
            archivos: files_edit_ref.current.files
        });
    }

    const handleSubmitCrear = async (e) => {
        e.preventDefault();
        try {
            if(newInspectionData.nombre !== ''){
                let formData = new FormData();
                formData.append('id_proyecto', newInspectionData.id_proyecto);
                formData.append('id_categoria', newInspectionData.id_categoria);
                formData.append('nombre', newInspectionData.nombre);
                formData.append('descripcion', newInspectionData.descripcion);

                if(newInspectionData.archivos !== null){
                    for (var i = 0; i < newInspectionData.archivos.length; i++) {
                        formData.append('archivos', newInspectionData.archivos[i]);
                    }
                }

                setStatusSaveInspection(false);
                await dispatch(agregarInspectionProyecto(formData));
                setStatusSaveInspection(true);

                setNewInspectionModal(false);

                setNewInspectionData({
                    ...newInspectionData,
                    id_categoria: null,
                    nombre: '',
                    descripcion: '',
                    archivos: null
                });
            }else{
                dispatch(setAlert('File name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleEditarInspeccion = async (id) => {
        await setFlag(true);
        await dispatch(detallesInspectionProyecto(id));
    }

    const handleCloseEditarInspeccion = () => {
        setEditInspectionData({
            ...editInspectionData,
            id: '',
            id_categoria: null,
            nombre: '',
            descripcion: '',
            archivos: null
        });

        setFlag(false);
        setEditInspectionModal(false);
    }

    const handleSubmitEditar = async (e) => {
        e.preventDefault();
        try {
            if(editInspectionData.nombre !== ''){
                let formData = new FormData();
                formData.append('id', editInspectionData.id);
                formData.append('id_categoria', editInspectionData.id_categoria);
                formData.append('nombre', editInspectionData.nombre);
                formData.append('descripcion', editInspectionData.descripcion);

                if(editInspectionData.archivos !== null){
                    for (var i = 0; i < editInspectionData.archivos.length; i++) {
                        formData.append('archivos', editInspectionData.archivos[i]);
                    }
                }

                setStatusSaveInspection(false);
                await dispatch(editarInspectionProyecto(formData));
                setStatusSaveInspection(true);

                setEditInspectionModal(false);

                setFlag(false);

                setEditInspectionData({
                    ...editInspectionData,
                    id: '',
                    id_categoria: null,
                    nombre: '',
                    descripcion: '',
                    archivos: null
                });
            }else{
                dispatch(setAlert('File name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    const handleEliminarInspeccion = async (fila) => {
        await setDeleteInspectionData({
            ...deleteInspectionData,
            id: fila._id,
            nombre: fila.nombre
        });
        await setDeleteInspectionModal(true);
    }

    const handleCloseEliminarInspeccion = () => {
        setDeleteInspectionModal(false);
    }

    const handleSubmitEliminar = async () => {
        try {
            setStatusSaveInspection(false);
            await dispatch(eliminarInspectionProyecto(deleteInspectionData.id));
            setStatusSaveInspection(true);

            setDeleteInspectionModal(false);
        } catch (error) {
            return false;
        }
    }

    const handleDetallesInspeccion = async (id) => {
        await setFlagDetails(true);
        await dispatch(detallesInspectionProyecto(id));
    }

    const handleCloseDetallesInspeccion = () => {
        setDetailsInspectionData({
            ...detailsInspectionData,
            id: '',
            nombre: '',
            creador: '',
            descripcion: '',
            fecha_creacion: null,
            total_reparaciones: null,
            archivos: null,
            status: null
        });

        setFlagDetails(false);
        setDetailsInspectionModal(false);
    }

    const handleOpenFile = async () => {
        let boton_archivos = document.getElementById('id_archivo');

        let archivo_elegido = detailsInspectionData.archivos?.filter(archivo => {
            return String(archivo._id) === String(boton_archivos.value)
        });

        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];
        if(images_extensions.indexOf(archivo_elegido[0].extension.toLowerCase()) !== -1){
            setDetailsInspectionModal(false);
            setImageData({ ...imageData, modal: true, src: archivo_elegido[0].ruta });
        }else{
            setDetailsInspectionModal(false);
            window.open(archivo_elegido[0].ruta);
        }        
    }

    const handleRepairsList = id => {
        dispatch(repairsProyecto(id));
        history.push('/projects/repairs/' + id);
    }

    const handleCreateReport = (id) => {
        dispatch(reportInspecciones(id));
    }

    const handleViewFiles = (files) => {
        if(files.length > 0){
            for(let i=0; i<files.length; i++){
                if(files[i]){
                    const url = URL.createObjectURL(files[i]);
                    window.open(url);
                }
            }
        }else{
            dispatch(setAlert('No files selected', 'danger'));
        }
    }

    const tableRow = (items) => {        
        return (
            <tbody>
                {
                    items.map( (item) => {
                        return (
                            <Fragment key={item._id}>
                                <tr>
                                    <td>{item.nombre}</td>
                                    <td>{item.categoria !== null ? item.categoria : 'General'}</td>
                                    <td>{item.creador !== null ? item.creador : 'Not assigned'}</td>
                                    <td>{item.fecha_creacion !== null ? new Date(item.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</td>
                                    <td>{item.total_reparaciones}</td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleDetallesInspeccion(item._id);
                                        }}>Details</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleRepairsList(item._id);
                                        }}>Repairs</button>
                                    </td>
                                    
                                    <td>
                                        <button className="btn btn-link text-primary" onClick={() => {
                                            handleEditarInspeccion(item._id);
                                        }}>Edit</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-link text-danger" onClick={() => {
                                            handleEliminarInspeccion(item);
                                        }}>Delete</button>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })
                }
            </tbody>
        );
    }

    const goBackProject = () => {
        history.goBack();
    }

    useEffect(() => {
        dispatch(detallesProyecto(id));

        dispatch(inspectionsProyecto(id));

        dispatch(buscarUsuarios());

        setNewInspectionData({
            ...newInspectionData,
            id_proyecto: id
        });

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }

        if(proyectos.detalles_inspection !== null && flag === true){
            setEditInspectionData({
                ...editInspectionData,
                id: proyectos.detalles_inspection?._id,
                id_categoria: proyectos.detalles_inspection?.id_categoria,
                nombre: proyectos.detalles_inspection?.nombre,
                descripcion: proyectos.detalles_inspection?.descripcion,
            });
        }

        if(proyectos.detalles_inspection !== null && flagDetails === true){
            setDetailsInspectionData({
                ...detailsInspectionData,
                id: proyectos.detalles_inspection?._id,
                nombre: proyectos.detalles_inspection?.nombre,
                creador: proyectos.detalles_inspection?.creador,
                descripcion: proyectos.detalles_inspection?.descripcion,
                fecha_creacion: proyectos.detalles_inspection?.fecha_creacion,
                total_reparaciones: proyectos.detalles_inspection?.total_reparaciones,
                archivos: proyectos.detalles_inspection?.archivos,
                status: proyectos.detalles_inspection?.status
            });
        }
    }, [proyectos]);

    useEffect(() => {
        if(editInspectionData.id !== ''){
            setEditInspectionModal(true);
        }
    }, [editInspectionData]);

    useEffect(() => {
        if(detailsInspectionData.id !== ''){
            setDetailsInspectionModal(true);
        }
    }, [detailsInspectionData]);

    return (
        <div className="row pb-5">

            <div className="col-md-3 mt-5">
                <h3 className="mb-3">Inspection list</h3>
            </div>

            <div className="col-md-9 mt-5">
                <button type="button" className="btn btn-secondary btn-project-goback float-right mb-3 ml-3" onClick={() => { goBackProject()}}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => {handleAgregarInspeccion(id)}}><i className="fas fa-plus fa-sm"></i> Create new inspection</button>
                <button type="button" className="btn btn-primary float-right mb-3" onClick={() => {handleCreateReport(id)}}><i className="fas fa-chart-bar fa-sm"></i> Generate report</button>
            </div>

            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>

                            {/* Modal de nueva inspeccion */}
                            <Modal backdrop="static" show={newInspectionModal} size="lg" onHide={() => {
                                setNewInspectionModal(false);
                                setNewInspectionData({
                                    ...newInspectionData,
                                    id_categoria: null,
                                    nombre: '',
                                    descripcion: '',
                                    archivos: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>New inspection</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Categorie:</label>
                                            <select className="form-control" value={newInspectionData.id_categoria || null} name="id_categoria" onChange={(e) => handleInputs(e)} disabled={statusSaveInspection ? false : true}>
                                                <option value={null}>General</option>
                                                {
                                                    categorias_inspeccion.categorias?.length > 0 ?
                                                        categorias_inspeccion.categorias?.map(categoria => {
                                                            return(
                                                                <option key={categoria._id} value={ categoria._id }>
                                                                    { categoria.nombre }
                                                                </option>
                                                            )
                                                        })
                                                    : ''
                                                } 
                                            </select>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of inspection:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputs(e)} value={newInspectionData.nombre || ''} disabled={statusSaveInspection ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea className='form-control' name="descripcion" onChange={e => handleInputs(e)} value={newInspectionData.descripcion || ''} disabled={statusSaveInspection ? false : true}></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Files:</label> <br></br>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="files" id="files" ref={files_ref} onChange={onChangeFiles} aria-describedby="view_files" multiple/>
                                                    <label className="custom-file-label">{newInspectionData.archivos ? 'Files Selected' : 'Choose files'}</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(newInspectionData.archivos)}><i class="fas fa-eye"></i> View Files</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseAgregarInspeccion()} disabled={statusSaveInspection ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleSubmitCrear} disabled={statusSaveInspection ? false : true}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de editar inspeccion */}
                            <Modal backdrop="static" show={editInspectionModal} size="lg" onHide={() => {
                                setFlag(false);
                                setEditInspectionModal(false);
                                setEditInspectionData({
                                    ...editInspectionData,
                                    id: '',
                                    id_categoria: null,
                                    nombre: '',
                                    descripcion: '',
                                    archivos: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit inspection</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Categorie:</label>
                                            <select className="form-control" value={editInspectionData.id_categoria || null} name="id_categoria" onChange={(e) => handleInputsEditar(e)} disabled={statusSaveInspection ? false : true}>
                                                <option value={null}>General</option>
                                                {
                                                    categorias_inspeccion.categorias?.length > 0 ?
                                                        categorias_inspeccion.categorias?.map(categoria => {
                                                            return(
                                                                <option key={categoria._id} value={ categoria._id }>
                                                                    { categoria.nombre }
                                                                </option>
                                                            )
                                                        })
                                                    : ''
                                                } 
                                            </select>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of inspection:</label>
                                            <div className="contenedor_datepicker">
                                                <input autoComplete='off' name="nombre" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editInspectionData.nombre || ''} disabled={statusSaveInspection ? false : true}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <div className="contenedor_datepicker">
                                                <textarea className='form-control' name="descripcion" onChange={e => handleInputsEditar(e)} value={editInspectionData.descripcion || ''} disabled={statusSaveInspection ? false : true}></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Files:</label> <br></br>
                                            {/* <input type="file" className="form-control" name="files" id="files" ref={files_edit_ref} onChange={onChangeFilesEditar} multiple/> */}
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="editFiles" id="editFiles" ref={files_edit_ref} onChange={onChangeFilesEditar} aria-describedby="view_files_editFiles" multiple/>
                                                    <label className="custom-file-label">{editInspectionData.archivos ? 'Files Selected' : 'Choose files'}</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFiles(editInspectionData.archivos)}><i class="fas fa-eye"></i> View Files</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEditarInspeccion()} disabled={statusSaveInspection ? false : true}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn btn-success" onClick={handleSubmitEditar} disabled={statusSaveInspection ? false : true}><i className="fas fa-check fa-sm"></i> Save</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de eliminacion */}
                            <Modal backdrop="static" show={deleteInspectionModal} onHide={() => {
                                setDeleteInspectionModal(false);
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete the activity</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12">
                                            <p style={styles.info}>Do you want to delete the selected activity?</p>
                                        </div>                                       
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger" onClick={() => handleCloseEliminarInspeccion()} disabled={statusSaveInspection ? false : true}><i className="fas fa-times fa-sm"></i> No</button>
                                    <button className="btn btn-warning" onClick={() => handleSubmitEliminar()} disabled={statusSaveInspection ? false : true}><i className="fas fa-check fa-sm"></i> Yes</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de detalles de inspeccion */}
                            <Modal backdrop="static" show={detailsInspectionModal} size="lg" onHide={() => {
                                setFlagDetails(false);
                                setDetailsInspectionModal(false);
                                setDetailsInspectionData({
                                    ...detailsInspectionData,
                                    id: '',
                                    id_categoria: null,
                                    nombre: '',
                                    descripcion: '',
                                    fecha_creacion: null,
                                    total_reparaciones: null,
                                    archivos: null,
                                    status: null
                                });
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Inspection details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row"> 
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Name of inspection:</label>
                                            <p>{detailsInspectionData.nombre || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">Description:</label>
                                            <p>{detailsInspectionData.descripcion || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Assigned user:</label>
                                            <p>{detailsInspectionData.creador || 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Creation date:</label>
                                            <p>{detailsInspectionData.fecha_creacion !== null ? new Date(detailsInspectionData.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                                        </div>

                                        <div className="col-md-3 form-group">
                                            <label className="font-weight-bold">Asigned repairs:</label>
                                            <p>{detailsInspectionData.total_reparaciones}</p>
                                        </div>

                                        {
                                            detailsInspectionData.archivos?.length > 0 ?
                                                <div className="col-md-12 form-group">
                                                    <label className="font-weight-bold">Files:</label> <br></br>
                                                    <div className="input-group">
                                                        <select className="custom-select" name="id_archivo" id="id_archivo">
                                                            {detailsInspectionData.archivos ? detailsInspectionData.archivos?.map(archivo => (
                                                                <option key={archivo._id} value={archivo._id}>{archivo.nombre + archivo.extension}</option>
                                                            )) : null}
                                                        </select>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="button" id="button-open-file" onClick={() => handleOpenFile()}><i className="fas fa-file fa-sm"></i> Open</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={() => handleCloseDetallesInspeccion()}><i className="fas fa-check fa-sm"></i> Ok</button>
                                </Modal.Footer>
                            </Modal>

                            {imageData.modal && (
                                <Lightbox
                                    mainSrc={imageData.src}
                                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                                />
                            )}

                            <div className="col-md-12 my-4">
                                <div className="row form-group">
                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {detalles?.proyecto?.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {detalles?.proyecto?.nombre_construccion}
                                    </label>

                                    <div className="col-lg-4 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {detalles?.proyecto?.apn?.join(', ')}
                                    </div>

                                    <label className="col-lg-8 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + detalles?.proyecto?.latitud + '%2C' + detalles?.proyecto?.longitud} target="_blank">{detalles?.proyecto?.direccion}</a>
                                    </label>
                                </div>
                            </div>
                            
                            {
                                proyectos.inspections?.length > 0 ?
                                <div className="col-md-12 row">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Categorie</th>
                                                    <th scope="col">Creator user</th>
                                                    <th scope="col">Creation date</th>
                                                    <th scope="col">Repairs</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {tableRow(proyectos.inspections)}
                                        </table>
                                    </div>
                                </div>
                                :
                                <div className="col-md-10 offset-md-1 text-center mt-4">
                                    <h3>No inspections founded.</h3>
                                    <button className="btn btn-primary mt-2" onClick={goBack}>
                                        <i className="fas fa-arrow-left fa-sm"></i> Go back
                                    </button>
                                </div>
                            }
                        </Fragment>
                    :
                        <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className="col-md-10 offset-md-1 text-center" style={{ marginTop: '10%', marginLeft: '4%' }}>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default Inspections;