import React, { useState, useEffect, createRef, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Badge, Modal } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { listarProyectos } from '../../actions/proyectos';
import { buscarTareas, agregarTarea, editarTarea, tiemposTarea, finalizarTarea, notaTarea, detallesTarea, detallesArchivo, eliminarTarea, reordenarTarea, elegirFiltros, reordenarSecciones } from '../../actions/todolist';
import { buscarSecciones } from '../../actions/secciones_todolist';
import { REORDENAR_TAREA_TODOLIST } from '../../actions/types';

const styles = {
    buttonIcon: {
        fontSize: 18,
    }
}

const ToDoList = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const usuarios = useSelector(state => state.usuarios);
    const { listado } = useSelector(state => state.proyectos);
    const { user } = useSelector(state => state.auth);
    const { permisos_reminders, permisos_todolist } = useSelector(state => state.auth.user);
    const { filtros, secciones, detalles_tarea, detalles_archivo } = useSelector(state => state.todolist);
    const { secciones: listado_secciones } = useSelector(state => state.secciones_todolist);

    const [idProyecto, setIdProyecto] = useState(null);

    //Extras
    let files_ref = createRef();
    const [flag, setFlag] = useState(false);
    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [filtersData, setFiltersData] = useState({
        id_proyecto: null,
        id_seccion: null,
        prioridad: null,
        status: null,
        finalizada: false,
        creada: true,
        id_responsable: null,
        ids_colaboradores: [],
        fecha_inicial: null,
        fecha_final: null
    });

    //Secciones
    const [sectionAddData, setSectionAddData] = useState({
        id_proyecto: null,
        nombre: null,
        orden: 0
    });

    //Tareas
    const [addTaskModal, setAddTaskModal] = useState(false);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [detailsTaskModal, setDetailsTaskModal] = useState(false);
    const [deleteTaskModal, setDeleteTaskModal] = useState(false);
    const [filtersModal, setFiltersModal] = useState(false);

    const [projectAddInformationData, setProjectAddInformationData] = useState(null);
    const [priorityAddInformationData, setPriorityAddInformationData] = useState(null);
    const [responsableAddInformationData, setResponsableAddInformationData] = useState(null);
    const [colaboradoresAddInformationData, setColaboradoresAddInformationData] = useState(null);

    const [projectEditInformationData, setProjectEditInformationData] = useState(null);
    const [sectionEditInformationData, setSectionEditInformationData] = useState(null);
    const [responsableEditInformationData, setResponsableEditInformationData] = useState(null);
    const [priorityEditInformationData, setPriorityEditInformationData] = useState(null);
    const [statusEditInformationData, setStatusEditInformationData] = useState(null);
    const [colaboradoresEditInformationData, setColaboradoresEditInformationData] = useState(null);

    const [projectFilterInformationData, setProjectFilterInformationData] = useState(null);
    const [sectionFilterInformationData, setSectionFilterInformationData] = useState(null);
    const [statusFilterInformationData, setStatusFilterInformationData] = useState(null);
    const [priorityFilterInformationData, setPriorityFilterInformationData] = useState(null);
    const [finalizedFilterInformationData, setFinalizedFilterInformationData] = useState({value: false, label: 'No'});
    const [createdFilterInformationData, setCreatedFilterInformationData] = useState({value: true, label: 'Yes'});
    const [responsableFilterInformationData, setResponsableFilterInformationData] = useState(null);
    const [colaboradoresFilterInformationData, setColaboradoresFilterInformationData] = useState(null);

    const [projectsFilter, setProjectsFilter] = useState([]);
    const [sectionsFilter, setSectionsFilter] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);

    const myRef = useRef(null);
    const scrollBottom = () => myRef.current.scrollIntoView({behavior: 'smooth'});

    let statusFilter = [
        {value: 0, label: 'Need attention'},
        {value: 1, label: 'In process'},
        {value: 2, label: 'Waiting'},
        {value: 3, label: 'Finished'},
        {value: 4, label: 'Cancelled'},
    ];

    let priorityFilter = [
        {value: 0, label: 'Low'},
        {value: 1, label: 'Medium'},
        {value: 2, label: 'High'},
        {value: 3, label: 'Urgent'}
    ];

    let finalizedFilter = [
        {value: true, label: 'Yes'},
        {value: false, label: 'No'}
    ];

    let createdFilter = [
        {value: true, label: 'Yes'},
        {value: false, label: 'No'}
    ];

    const [taskAddData, setTaskAddData] = useState({
        id_proyecto: null,
        id_seccion: null,
        nombre_seccion: null,
        id_responsable: null,
        ids_colaboradores: [],
        nombre: null,
        descripcion: null,
        fecha_entrega: null,
        prioridad: null,
        orden: 0
    });

    const [taskEditData, setTaskEditData] = useState({
        id: null, 
        id_proyecto: null, 
        id_seccion: null,
        nombre_seccion: null,
        id_responsable: null,
        ids_colaboradores: [],
        nombre: null,
        descripcion: null,
        fecha_entrega: null,
        prioridad: null,
        status: null,
        orden: 0
    });

    const [taskDetailsData, setTaskDetailsData] = useState({
        id: null,
        nombre_proyecto: null,
        nombre_seccion: null,
        nombre_creador: null,
        nombre_responsable: null,
        colaboradores: [],
        nombre: null,
        descripcion: null,
        fecha_entrega: null,
        tipo_prioridad: null,
        tiempo_total: null, 
        status: [],
        notas: []
    });

    const [taskNoteData, setTaskNoteData] = useState({
        id: null,
        nota: null,
        archivos: null
    });

    const [taskDeleteData, setTaskDeleteData] = useState({
        id: null,
        id_seccion: null,
        nombre_seccion: null,
        nombre: null
    });

    const tiempo = Date.now();
    const hoy = new Date(tiempo);

    //Agregar tarea
    const handleAddTaskModal = (id_seccion, nombre_seccion) => {
        setProjectAddInformationData(filtros !== null ? { value: filtros.id_proyecto, label: filtros.nombre_proyecto} : null);

        setAddTaskModal(true);

        setTaskAddData({
            ...taskAddData,
            id_seccion,
            nombre_seccion,
            orden: secciones?.find( seccion => seccion._id === id_seccion ).tareas?.length + 1
        });
    }

    const handleSelectAddTask = (option, tipo) => {
        if(tipo === 1 || tipo === '1'){
            setProjectAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                id_proyecto: option !== null ? option.value : null
            });
        }

        if(tipo === 2 || tipo === '2'){
            setPriorityAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                prioridad: option !== null ? option.value : null
            });
        }

        if(tipo === 3 || tipo === '3'){
            setResponsableAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                id_responsable: option !== null ? option.value : null
            });
        }

        if(tipo === 4 || tipo === '4'){
            let ids_colaboradores = [];

            for (var i = 0; i < option?.length; i++) {
                ids_colaboradores.push(option[i].value);
            }

            setColaboradoresAddInformationData(option);
            setTaskAddData({
                ...taskAddData,
                ids_colaboradores: ids_colaboradores
            });
        }
    }

    const handleAddTask = (method) => {
        if(taskAddData.nombre !== null && taskAddData.nombre !== ''){
            if(taskAddData.fecha_entrega !== null){
                if(taskAddData.id_responsable !== null){
                    let formData = new FormData();

                    formData.append('id_proyecto', taskAddData.id_proyecto);
                    formData.append('id_seccion', taskAddData.id_seccion);
                    formData.append('nombre_seccion', taskAddData.nombre_seccion);
                    formData.append('id_responsable', taskAddData.id_responsable);
                    formData.append('ids_colaboradores', JSON.stringify(taskAddData.ids_colaboradores));
                    formData.append('nombre', taskAddData.nombre);
                    formData.append('descripcion', taskAddData.descripcion);
                    formData.append('fecha_entrega', taskAddData.fecha_entrega);
                    formData.append('prioridad', taskAddData.prioridad);
                    formData.append('orden', taskAddData.orden);

                    if(taskNoteData.nota !== null && taskNoteData.nota !== ''){
                        formData.append('nota', taskNoteData.nota);
                    }

                    if(taskNoteData.archivos !== null){
                        for (var i = 0; i < taskNoteData.archivos.length; i++) {
                            formData.append('archivos', taskNoteData.archivos[i]);
                        }   
                    }

                    dispatch(agregarTarea(formData));

                    setPriorityAddInformationData(null);
                    setResponsableAddInformationData(null);
                    setColaboradoresAddInformationData(null);

                    setTaskNoteData({
                        ...taskNoteData,
                        nota: null,
                        archivos: null
                    });

                    if(method === 'new'){

                        setTaskAddData({
                            ...taskAddData,
                            id_responsable: null,
                            ids_colaboradores: [],
                            nombre: null,
                            descripcion: null,
                            fecha_entrega: null,
                            prioridad: false,
                            orden: 0
                        });

                    }else if(method === 'close'){

                        setTaskAddData({
                            ...taskAddData,
                            id_seccion: null,
                            nombre_seccion: null,
                            id_responsable: null,
                            ids_colaboradores: [],
                            nombre: null,
                            descripcion: null,
                            fecha_entrega: null,
                            prioridad: false,
                            orden: 0
                        });

                        handleCloseAddTaskModal();

                    }
                }else{
                    dispatch(setAlert('First assign a responsible for the task', 'danger'));
                }
            }else{
                dispatch(setAlert('First select a valid deadline', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid name for the task', 'danger'));
        }
    }

    const handleCloseAddTaskModal = () => {
        setAddTaskModal(false);

        setProjectAddInformationData(null);
        setResponsableAddInformationData(null);
        setColaboradoresAddInformationData(null);

        setTaskAddData({
            ...taskAddData,
            id_proyecto: null,
            id_seccion: null,
            nombre_seccion: null,
            id_responsable: null,
            ids_colaboradores: [],
            nombre: null,
            descripcion: null,
            fecha_entrega: null,
            prioridad: false,
            orden: 0
        });

        setTaskNoteData({
            ...taskNoteData,
            nota: null,
            archivos: null
        });
    }

    //Editar tarea
    const handleEditTaskModal = (tarea, nombre_seccion) => {
        setTaskEditData({
            ...taskEditData,
            id: tarea._id,
            id_proyecto: tarea.id_proyecto,
            id_seccion: tarea.id_seccion,
            nombre_seccion: nombre_seccion,
            id_responsable: tarea.id_responsable,
            ids_colaboradores: tarea.ids_colaboradores,
            nombre: tarea.nombre,
            descripcion: tarea.descripcion,
            fecha_entrega: tarea.fecha_entrega,
            prioridad: tarea.tipo_prioridad,
            status: tarea.status[tarea.status.length - 1].tipo_status,
            orden: tarea.orden
        });

        setProjectEditInformationData({value: tarea.id_proyecto, label: tarea.nombre_proyecto});
        
        setSectionEditInformationData({value: tarea.id_seccion, label: nombre_seccion});

        setPriorityEditInformationData({value: tarea.tipo_prioridad, label: prioridadNameTask(tarea.tipo_prioridad)});

        setStatusEditInformationData({value: tarea.status[tarea.status.length - 1].tipo_status, label: statusNameTask(tarea.status[tarea.status.length - 1].tipo_status)});

        setResponsableEditInformationData({value: tarea.id_responsable, label: tarea.nombre_responsable});

        let opciones_colaboradores = [];

        for (var i = 0; i < tarea.ids_colaboradores?.length; i++) {
            let usuario_encontrado = usuarios.find( usuario => usuario._id === tarea.ids_colaboradores[i] );
            if(usuario_encontrado){
                opciones_colaboradores.push({ value: usuario_encontrado._id, label: usuario_encontrado.nombre });
            }
        }

        setColaboradoresEditInformationData(opciones_colaboradores);

        setEditTaskModal(true);
    }

    const handleSelectEditTask = (option, tipo) => {
        if(tipo === 1 || tipo === '1'){
            setProjectEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                id_proyecto: option !== null ? option.value : null
            });
        }

        if(tipo === 2 || tipo === '2'){
            setSectionEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                id_seccion: option !== null ? option.value : null
            });
        }

        if(tipo === 3 || tipo === '3'){
            setPriorityEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                prioridad: option !== null ? option.value : null
            });
        }
        
        if(tipo === 4 || tipo === '4'){
            setStatusEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                status: option !== null ? option.value : null
            });
        }

        if(tipo === 5 || tipo === '5'){
            setResponsableEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                id_responsable: option !== null ? option.value : null
            });
        }
        
        if(tipo === 6 || tipo === '6'){
            let ids_colaboradores = [];

            for (var i = 0; i < option?.length; i++) {
                ids_colaboradores.push(option[i].value);
            }

            setColaboradoresEditInformationData(option);
            setTaskEditData({
                ...taskEditData,
                ids_colaboradores: ids_colaboradores
            });
        }
    }

    const handleEditTask = () => {
        if(taskEditData.nombre !== null && taskEditData.nombre !== ''){
            if(taskEditData.fecha_entrega !== null){
                if(taskEditData.id_responsable !== null){
                    dispatch(editarTarea(taskEditData));
                    
                    setTaskEditData({
                        ...taskEditData,
                        id: null,
                        id_seccion: null,
                        nombre_seccion: null,
                        id_responsable: null,
                        ids_colaboradores: [],
                        nombre: null,
                        descripcion: null,
                        fecha_entrega: null,
                        prioridad: null,
                        status: null,
                        orden: 0
                    });

                    handleCloseEditTaskModal();
                }else{
                    dispatch(setAlert('First assign a responsible for the task', 'danger'));
                }
            }else{
                dispatch(setAlert('First select a valid deadline', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid name for the task', 'danger'));
        }
    }

    const handleFinalizeTask = (id, id_seccion, finalizada) => {
        
        dispatch(finalizarTarea(id, id_seccion, finalizada));
    }

    const handleCloseEditTaskModal = () => {
        setEditTaskModal(false);

        setResponsableEditInformationData(null);
        setColaboradoresEditInformationData(null);

        setTaskEditData({
            ...taskEditData,
            id: null,
            id_seccion: null,
            nombre_seccion: null,
            id_responsable: null,
            ids_colaboradores: [],
            nombre: null,
            descripcion: null,
            fecha_entrega: null,
            prioridad: null,
            status: null,
            orden: 0
        });
    }

    //Tiempos tarea
    const handleTimeTask = (id) => {
        dispatch(tiemposTarea(id));
    }

    //Notas y detalles
    const handleDetailsTask = (id) => {
        dispatch(detallesTarea(id));
    }

    const handleDetailsTaskModal = (tarea) => {
        setTaskDetailsData({
            ...taskDetailsData,
            id: tarea._id,
            nombre_proyecto: tarea.nombre_proyecto,
            nombre_seccion: tarea.nombre_seccion,
            nombre_creador: tarea.nombre_creador,
            nombre_responsable: tarea.nombre_responsable,
            colaboradores: tarea.colaboradores,
            nombre: tarea.nombre,
            descripcion: tarea.descripcion,
            fecha_entrega: tarea.fecha_entrega,
            notas: tarea.notas,
            tipo_prioridad: tarea.tipo_prioridad,
            tiempo_total: tarea.tiempo_total,
            status: tarea.status
        });

        setTaskNoteData({
            ...taskNoteData,
            id: tarea._id,
        });

        setDetailsTaskModal(true);
    }

    const onChangeFiles = () => {
        setTaskNoteData({
            ...taskNoteData,
            archivos: files_ref.current.files
        });
    }

    const handleAgregarArchivosTask = () => {
        files_ref.current.click();
    }

    const handleNoteTask = () => {
        if(taskNoteData.nota !== null && taskNoteData.nota !== ''){

            let formData = new FormData();
            formData.append('id', taskNoteData.id);
            formData.append('nota', taskNoteData.nota);

            if(taskNoteData.archivos !== null){
                for (var i = 0; i < taskNoteData.archivos.length; i++) {
                    formData.append('archivos', taskNoteData.archivos[i]);
                }
            }

            dispatch(notaTarea(formData, scrollBottom));

            setTaskNoteData({
                ...taskNoteData,
                nota: null,
                archivos: null
            });
        }else{
            dispatch(setAlert('Add text to the note', 'danger'));
        }
    }

    const handleIconoArchivo = async (datos) => {
        let {id_nota, id_archivo} = datos;

        await setFlag(true);
        await dispatch(detallesArchivo(taskDetailsData.id, id_nota, id_archivo));
    }

    const handleOpenFile = () => {
        let images_extensions = ['.jpg', '.png', '.jiff', '.jpeg', '.webp', '.gif', '.eps', '.tiff', '.jfif'];

        if(images_extensions.indexOf(detalles_archivo.extension.toLowerCase()) !== -1){
            setImageData({ ...imageData, modal: true, src: detalles_archivo.url });
        }else{
            window.open(detalles_archivo.url);
        }
    }

    const handleCloseDetailsTaskModal = () => {
        setDetailsTaskModal(false);

        setTaskNoteData({
            ...taskNoteData,
            id: null,
            nota: null,
            archivos: null
        });

        setTaskDetailsData({
            ...taskDetailsData,
            id: null,
            nombre_proyecto: null,
            nombre_seccion: null,
            nombre_creador: null,
            nombre_responsable: null,
            colaboradores: [],
            nombre: null,
            descripcion: null,
            fecha_entrega: null,
            tipo_prioridad: null,
            tiempo_total: null,
            notas: [],
            status: []
        });

        dispatch(detallesTarea(null));
    }

    //Eliminar tarea
    const handleDeleteTaskModal = (tarea, nombre_seccion) => {
        setTaskDeleteData({
            ...taskDeleteData,
            id: tarea._id,
            id_seccion: tarea.id_seccion,
            nombre: tarea.nombre,
            nombre_seccion
        });

        setDeleteTaskModal(true);
    }

    const handleDeleteTask = () => {
        if(taskDeleteData.id !== null && taskDeleteData.id !== ''){
            dispatch(eliminarTarea(taskDeleteData.id_seccion, taskDeleteData.id));

            setTaskDeleteData({
                ...taskDeleteData,
                id: null,
                id_seccion: null,
                nombre: null,
                nombre_seccion: null
            });

            handleCloseDeleteTaskModal();
        }
    }

    const handleCloseDeleteTaskModal = () => {
        setDeleteTaskModal(false);

        setTaskDeleteData({
            ...taskDeleteData,
            id: null,
            id_seccion: null,
            nombre: null,
            nombre_seccion: null
        });
    }

    //Tabla
    const prioridadTask = (prioridad) => {

        switch(prioridad){
            case 0:
                return <h5><Badge variant="success">Low</Badge></h5>;
                break;

            case 1:
                return <h5><Badge variant="primary">Medium</Badge></h5>;
                break;

            case 2:
                return <h5><Badge variant="warning">High</Badge></h5>;
                break;

            case 3:
                return <h5><Badge variant="danger">Urgent</Badge></h5>;
                break;

            default:
                return <h5><Badge variant="secondary">Not defined</Badge></h5>;
                break;
        }
    }

    const prioridadNameTask = (status) => {
        switch(status){
            case 0:
                return 'Low';
                break;

            case 1:
                return 'Medium';
                break;

            case 2:
                return 'High';
                break;

            case 3:
                return 'Urgent';
                break;

            default:
                break;
        }
    }

    const statusTask = (status) => {

        switch(status){
            case 0:
                return  <h5><span className="badge badge_new_task">Need attention</span></h5>;
                break;

            case 1:
                return  <h5><span className="badge badge_in_progress">In process</span></h5>;
                break;

            case 2:
                return <h5><Badge variant="warning">Waiting</Badge></h5>;
                break;

            case 3:
                return <h5><Badge variant="success">Finished</Badge></h5>;
                break;

            case 4:
                return <h5><Badge variant="danger">Cancelled</Badge></h5>;
                break;

            default:
                break;
        }
    }

    const statusTaskLg = (status) => {

        switch(status){
            case 0:
                return  <h3><span className="badge badge_new_task">Need attention</span></h3>;
                break;

            case 1:
                return  <h3><span className="badge badge_in_progress">In process</span></h3>;
                break;

            case 2:
                return <h3><Badge variant="warning">Waiting</Badge></h3>;
                break;

            case 3:
                return <h3><Badge variant="success">Finished</Badge></h3>;
                break;

            case 4:
                return <h3><Badge variant="danger">Cancelled</Badge></h3>;
                break;

            default:
                break;
        }
    }

    const statusNameTask = (status) => {
        switch(status){
            case 0:
                return 'Need attention';
                break;

            case 1:
                return 'In process';
                break;

            case 2:
                return 'Waiting';
                break;

            case 3:
                return 'Finished';
                break;

            case 4:
                return 'Cancelada';
                break;

            default:
                break;
        }
    }

    const timeConvert = (segundos) => {
        let mensaje = 'Without time';

        if(segundos > 0){
            let cantidad_minuto = 1000 * 60;

            let minutos = Math.floor(segundos / cantidad_minuto);
            let horas = 0;
            let dias = 0;
            let semanas = 0;

            if(minutos >= 10080){
                semanas = Math.floor(minutos / 10080);
                minutos = minutos - (semanas * 10080);
            }

            if(minutos >= 1440){
                dias = Math.floor(minutos / 1440);
                minutos = minutos - (dias * 1440);
            }

            if(minutos >= 60){
                horas = Math.floor(minutos / 60);
                minutos = minutos - (horas * 60);
            }

            if(minutos || horas || dias || semanas){
                let texto_minutos = minutos > 0 ? minutos + ' ' + (minutos == 1 ? 'minute' : 'minutes') : '';
                let texto_horas = horas > 0 ? horas + ' ' + (horas == 1 ? 'hour' : 'hours') : '';
                let texto_dias = dias > 0 ? dias + ' ' + (dias == 1 ? 'day' : 'days') : '';
                let texto_semanas = semanas > 0 ? semanas + ' ' + (semanas == 1 ? 'week' : 'weeks') : '';

                mensaje = texto_semanas + ' ' + texto_dias + ' ' + texto_horas + ' ' + texto_minutos;
            }else{
                mensaje = 'Less than minute';
            }
        }

        return mensaje;
    }

    const botonesArchivos = (id_nota, archivos) => {

        let botones_archivos = [];

        for (var i = 0; i < archivos.length; i++) {

            let extension = 'file';

            switch(archivos[i].extension){
                case '.pdf':
                    extension = 'file-pdf';
                    break;
    
                case '.jpeg':
                case '.jpg':
                case '.png':
                case '.gif':
                    extension = 'file-image';
                    break;
    
                case '.mp4':
                case '.wmv':
                case '.mov':
                    extension = 'file-video';
                    break;
    
                case '.mp3':
                case '.m4a':
                case '.aac':
                    extension = 'file-audio';
                    break;
    
                case '.doc':
                case '.docx':
                    extension = 'file-word';
                    break;
    
                case '.xls':
                case '.xlsx':
                    extension = 'file-excel';
                    break;
    
                default:
                    extension = 'file';
                    break;
            }

            let datos = {
                id_nota,
                id_archivo: archivos[i]._id
            }

            botones_archivos.push(
                <button className="btn btn_link_color" onClick={() => handleIconoArchivo(datos)}>
                    <i className={'fa fa-' + extension} style={styles.buttonIcon} /> {archivos[i].nombre + archivos[i].extension} 
                </button>
            );
        }

        return botones_archivos;
    }

    const handleCheckboxTask = ({ target }, id_seccion) => {
        let id_sep = target.id.split('_');

        if(target.checked){
            handleFinalizeTask(id_sep[1], id_seccion, true);
        }else{
            handleFinalizeTask(id_sep[1], id_seccion, false);         
        }
    }

    const handleOnDragEnd = (results) => {

        if(results.type === 'column'){

            if(results.destination !== null && results.source.index !== results.destination.index){
    
                let tempSecciones = [...secciones];
    
                let [seccionSelec] = tempSecciones.splice(results.source.index, 1);
                tempSecciones.splice(results.destination.index, 0, seccionSelec);

                dispatch({
                    type: REORDENAR_TAREA_TODOLIST,
                    payload: tempSecciones
                });

                dispatch(reordenarSecciones(results.draggableId, results.destination.index, secciones));
    
            }else{
                return;
            }

        }else if(results.type === 'task'){
            
            if(results.destination !== null){

                let seccionIndice = secciones.findIndex(seccion => String(seccion._id) === String(results.source.droppableId));
                let seccionNuevoIndice = secciones.findIndex(seccion => String(seccion._id) === String(results.destination.droppableId));
    
                let tempSecciones = [...secciones];
    
                let tempTareas = secciones[seccionIndice].tareas;
    
                if(seccionIndice === seccionNuevoIndice){
                    if(results.source.index !== results.destination.index){

                        let tareas_actualizar = [];
    
                        let [tareaSelec] = tempTareas.splice(results.source.index, 1);
                        tempTareas.splice(results.destination.index, 0, tareaSelec);
                        tempSecciones[seccionIndice].tareas = tempTareas;

                        for (var i = 0; i < tempTareas.length; i++) {
                            tareas_actualizar.push({
                                id: tempTareas[i]._id,
                                orden: i + 1
                            });
                        }
    
                        dispatch({
                            type: REORDENAR_TAREA_TODOLIST,
                            payload: tempSecciones
                        });
    
                        dispatch(reordenarTarea(tareas_actualizar, secciones));
    
                    }else{
                        return;
                    }
                }else{
                    let tareas_actualizar = [];
                    let tempNuevoTareas = secciones[seccionNuevoIndice].tareas;
    
                    let [tareaSelec] = tempTareas.splice(results.source.index, 1);
                    tempNuevoTareas.splice(results.destination.index, 0, tareaSelec);
                    
                    tempSecciones[seccionIndice].tareas = tempTareas;
                    tempSecciones[seccionNuevoIndice].tareas = tempNuevoTareas;

                    for (var i = 0; i < tempTareas.length; i++) {
                        tareas_actualizar.push({
                            id: tempTareas[i]._id,
                            orden: i + 1
                        });
                    }

                    for (var i = 0; i < tempNuevoTareas.length; i++) {
                        tareas_actualizar.push({
                            id: tempNuevoTareas[i]._id,
                            orden: i + 1
                        });
                    }
    
                    dispatch({
                        type: REORDENAR_TAREA_TODOLIST,
                        payload: tempSecciones
                    });

                    dispatch(reordenarTarea(tareas_actualizar, secciones));
    
                }
            }else{
                return;
            }

        }

    }

    const handleClick = event => {
        switch (event.detail) {
            case 2: {
                handleDetailsTask(event.target.id);
                break;
            }
            default: {
                break;
            }
        }
    }

    const tableRow = (items) => {
        if(items !== null){
            if(items.length > 0){
                let secciones = [];

                items.map((seccion, key_seccion) => {

                    let tareas = [];

                    seccion.tareas.map((tarea, index) => {
                        tareas.push(
                            <Draggable className='todo-draggable-row' key={tarea._id} draggableId={tarea._id} index={index}>
                                {(draggableProvided) => (
                                    <tr
                                        {...draggableProvided.draggableProps}
                                        ref={draggableProvided.innerRef}
                                        onClick={handleClick}
                                        id={tarea._id}
                                        key={tarea._id}
                                    >
                                        <td id={tarea._id} className='todo-scroll-row' {...draggableProvided.dragHandleProps}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                            </svg>
                                        </td>
                                        <td id={tarea._id} className='todo-task-row'>{tarea.nombre}</td>
                                        {
                                            filtros !== null ?
                                                null
                                            :
                                                <td id={tarea._id} className='todo-project-row'>{tarea.nombre_proyecto}</td>
                                        }
                                        <td id={tarea._id} className='todo-asignedto-row'>{tarea.nombre_responsable}</td>
                                        <td id={tarea._id} className={((Math.floor((new Date(tarea.fecha_entrega) - hoy)/86400000)) <= 3 ) ? "todo-deadline-warning" : ""}>{tarea.fecha_entrega !== null ? new Date(tarea.fecha_entrega).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</td>
                                        <td id={tarea._id} className='todo-priority-row'>{prioridadTask(tarea.tipo_prioridad)}</td>
                                        <td id={tarea._id} className='todo-status-row' onClick={() => handleDetailsTask(tarea._id)}>{statusTask(tarea.status[tarea.status.length - 1].tipo_status)}</td>
                                        <td id={tarea._id} className='todo-options-row'>
                                            <input className="checktask flotat-right" type="checkbox" id={'checktask_' + tarea._id} onChange={(e) => handleCheckboxTask(e, seccion._id)}></input>

                                            <button className="btn float-right px-1" type="button" onClick={() => handleDeleteTaskModal(tarea, seccion.nombre)}>
                                                <i className="fa fa-trash"/>   
                                            </button>

                                            <button className="btn float-right px-1" type="button" onClick={() => handleEditTaskModal(tarea, seccion.nombre)}>
                                                <i className="fa fa-edit"/>   
                                            </button>

                                            <button className="btn float-right px-1" type="button" onClick={() => handleDetailsTask(tarea._id)}>
                                                <i className="fa fa-comments"/>   
                                            </button>

                                            <button className={'btn float-right px-2 text-' + (tarea.tiempo_status ? 'warning' : 'success')} type="button" onClick={() => handleTimeTask(tarea._id)}>
                                                <i className={'fa fa-' + (tarea.tiempo_status ? 'pause' : 'play')}/>   
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </Draggable>
                        );
                    });

                    if(tareas.length === 0){
                        tareas.push(
                            <tr>
                                <td colSpan="6" className="form-group text-center">
                                    <label>This section no have tasks.</label>
                                </td>
                            </tr>
                        );
                    }

                    secciones.push(
                        <Draggable draggableId={seccion._id} index={key_seccion} key={seccion._id}>
                            {(draggableProvided) => (
                                <div
                                    {...draggableProvided.draggableProps}
                                    ref={draggableProvided.innerRef}
                                    className="card col-md-12 px-0"
                                    key={seccion._id}
                                >
                                    <div {...draggableProvided.dragHandleProps} className="card-header font-weight-bold">
                                        <div className="row mx-0">
                                            <div className="col-md-10 mb-0 form-group" data-toggle="collapse" data-target={'#filaSeccion' + key_seccion} aria-expanded="true" aria-controls={'#filaSeccion' + key_seccion}>
                                                <label className="font-weight-bold">{seccion.nombre}</label>
                                            </div>

                                            {
                                                (!permisos_todolist?.new_task) ?
                                                null
                                                :
                                                <div className="col-md-2 mb-0 form-group">
                                                    <button className="btn float-right" type="button" onClick={() => handleAddTaskModal(seccion._id, seccion.nombre)}>
                                                        <i className="fa fa-plus"/>   
                                                    </button>
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
        
                                    <div id={'filaSeccion' + key_seccion} className="collapse show p-3" aria-labelledby="headingOne" data-parent={'seccion_' + key_seccion}>
                                        <div className="table-responsive">
                                            <table className="table tableTasksToDo">
                                                <thead cla  ssName="thead-light">
                                                    <tr>
                                                        <th style={{width: '4%'}}></th>
                                                        <th style={{width: '25%'}}>Task</th>
                                                        {
                                                            filtros !== null ?
                                                                null
                                                            :
                                                                <th style={{width: '20%'}}>Project</th>
                                                        }
                                                        <th style={{width: '13%'}}>Asigned to</th>
                                                        <th style={{width: '10%'}}>Deadline</th>
                                                        <th style={{width: '8%', textAlign: 'right'}}>Priority</th>
                                                        <th style={{width: '9%', textAlign: 'right'}}>Status</th>
                                                        <th style={{width: '11%'}}></th>
                                                    </tr>
                                                </thead>
                                                
                                                    <Droppable droppableId={seccion._id} type='task'>
                                                        {(droppableProvided) => (
                                                            <tbody
                                                                {...droppableProvided.droppableProps}
                                                                ref={droppableProvided.innerRef}
                                                            >
                                                                {tareas}
                                                                {droppableProvided.placeholder}
                                                            </tbody>
                                                        )}
                                                    </Droppable>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    );
                });

                return secciones;
            }else{
                return (
                    <div className="col-md-10 offset-md-1 text-center contenido_vacio">
                        <h3>There is no tasks to do.<br/> Use the filters or add sections and tasks.</h3>
                        <i className="fas fa-list mt-3 icono-contenedor_centrado"/>
                    </div>
                );
            }
        }else{
            return (
                <div className="col-md-10 offset-md-1 text-center contenido_vacio">
                    <h3>There is no tasks to do.</h3>
                    <button className="btn btn-primary mt-2" onClick={() => history.push('dashboard')}>
                        <i className="fas fa-home fa-sm"></i> Dashboard
                    </button>
                </div>
            );
        }
    }

    //Notas de tarea
    const notasRow = (items) => {
        if(items.length > 0){

            let notas = [];

            items.map((nota, key_nota) => {
                if('tipo_status' in nota){
                    notas.push(
                        <div id={'nota_' + key_nota} className="col-md-12 card contenedor_nota_individual px-0" key={'nota_' + key_nota}>
                            <div className="card-body p-2 px-3">
                                <h5 className="card-title">{nota.creador}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{nota.fecha_creacion !== null ? new Date(nota.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(nota.fecha_creacion).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</h6>
                                <p>Change the status to <b>{nota.nombre_status}</b></p>
                            </div>
                        </div>
                    );
                }else{
                    notas.push(
                        <div id={'nota_' + key_nota} className="col-md-12 card contenedor_nota_individual px-0" key={'nota_' + key_nota}>
                            <div className="card-body py-2 px-3">
                                <h5 className="card-title">{nota.creador}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{nota.fecha_creacion !== null ? new Date(nota.fecha_creacion).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(nota.fecha_creacion).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'}</h6>
                                <p>{nota.nota}</p>
                                {
                                    nota.archivos.length > 0 ?
                                        <p>{botonesArchivos(nota._id, nota.archivos)}</p>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    );
                }
                
            });

            return notas;
        }else{
            return (
                <div className="col-md-10 offset-md-1 text-center">
                    <h4 className="mensaje_contenedor_notas">There are no notes or comments yet.</h4>
                </div>
            );
        }
    }

    //Filtros
    const handleFiltersModal = () => {
        setProjectFilterInformationData(filtros !== null ? ( filtros.id_proyecto !== null ? { value: filtros.id_proyecto, label: filtros.nombre_proyecto} : null) : null);

        setFiltersModal(true);

        setFiltersData({
            ...filtersData
        });
    }

    const handleSelectFilterTask = (option, tipo) => {
        if(tipo === 1 || tipo === '1'){
            setProjectFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                id_proyecto: option !== null ? option.value : null
            });

            setSectionAddData({
                sectionAddData,
                id_proyecto: option !== null ? option.value : null
            });
        }

        if(tipo === 2 || tipo === '2'){
            setSectionFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                id_seccion: option !== null ? option.value : null
            });
        }

        if(tipo === 3 || tipo === '3'){
            setPriorityFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                prioridad: option !== null ? option.value : null
            });
        }  
        
        if(tipo === 4 || tipo === '4'){
            setStatusFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                status: option !== null ? option.value : null
            });
        }        

        if(tipo === 5 || tipo === '5'){
            setResponsableFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                id_responsable: option !== null ? option.value : null
            });
        }

        if(tipo === 6 || tipo === '6'){
            setFinalizedFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                finalizada: option !== null ? option.value : null
            });
        }

        if(tipo === 7 || tipo === '7'){
            setCreatedFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                creada: option !== null ? option.value : null
            });
        }

        if(tipo === 8 || tipo === '8'){
            let ids_colaboradores = [];

            for (var i = 0; i < option?.length; i++) {
                ids_colaboradores.push(option[i].value);
            }

            setColaboradoresFilterInformationData(option);
            setFiltersData({
                ...filtersData,
                ids_colaboradores: ids_colaboradores
            });
        }
    }

    const handleFilter = () => {
        if(filtersData.id_proyecto !== null){
            if(filtersData.id_proyecto !== 0 && filtersData.id_proyecto !== '0'){
                dispatch(elegirFiltros({
                    id_proyecto: filtersData.id_proyecto || null,
                    nombre_proyecto: listado.filter(proyecto => String(proyecto._id) === String(filtersData.id_proyecto))[0]?.direccion || null
                }));
            }else{
                dispatch(elegirFiltros({
                    id_proyecto: filtersData.id_proyecto || null,
                    nombre_proyecto: 'Without project'
                }));
            }
        }else{
            dispatch(elegirFiltros(null));

            if(filtros === null){
                dispatch(buscarTareas(filtersData));
            }
        }

        setFiltersModal(false);
    }

    const handleCloseFiltersModal = () => {
        setFiltersModal(false);
    }

    const handleAgenda = () => {
        history.push('/agenda/' + (projectFilterInformationData?.value || ''));
    }

    const handleRecordatorios = () => {
        history.push('/reminders/' + (id || ''));
    }

    const handleReporte = () => {
        history.push('/todolist/reporte/' + (projectFilterInformationData?.value || ''));
    }

    const handleGoBack = () => {
        if(id){
            history.push('/projects/buySale/' + (id || ''));
        }else{
            history.goBack();
        }
    }

    useEffect(() => {
        dispatch(buscarSecciones());
        dispatch(buscarUsuarios());
        dispatch(listarProyectos());

        return () => {
            dispatch(detallesTarea(null));
            dispatch(buscarTareas(null));
        }
    }, []);

    useEffect(() => {
        let options = [];

        options.push({ value: '0', label: 'Without project' });

        for (var i = 0; i < listado?.length; i++) {
            options.push({ value: listado[i]._id, label: listado[i].direccion });
        }

        setProjectsFilter(options);
    }, [listado]);

    useEffect(() => {
        setSectionAddData({
            ...sectionAddData,
            orden: secciones?.length + 1
        });
    }, [secciones]);

    useEffect(() => {
        if(detalles_tarea !== null){
            handleDetailsTaskModal(detalles_tarea);
        }
    }, [detalles_tarea]);

    useEffect(() => {
        if(flag === true && detalles_archivo !== null && detalles_archivo.extension !== null && detalles_archivo.url !== null){
            handleOpenFile();
        }
    }, [detalles_archivo, flag]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < usuarios?.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {

        let options = [];

        for (var i = 0; i < listado_secciones?.length; i++) {
            options.push({ value: listado_secciones[i]._id, label: listado_secciones[i].nombre });
        }

        setSectionsFilter(options);
    }, [listado_secciones]);

    useEffect(() => {
        if(filtros !== null){
            let busqueda_filtros = {
                ...filtersData
            };

            let agregar_tarea = {
                ...taskAddData
            };

            if('id_proyecto' in filtros){
                if(filtros.id_proyecto !== null){
                    busqueda_filtros.id_proyecto = filtros.id_proyecto;
                    agregar_tarea.id_proyecto = filtros.id_proyecto;

                    setProjectFilterInformationData(filtros !== null ? { value: filtros.id_proyecto, label: filtros.nombre_proyecto} : null);
                    setProjectAddInformationData(filtros !== null ? { value: filtros.id_proyecto, label: filtros.nombre_proyecto} : null);
                }
            }

            if('id_responsable' in filtros){
                if(filtros.id_responsable !== null){
                    busqueda_filtros.id_responsable = filtros.id_responsable;
                }
            }

            if('ids_colaboradores' in filtros){
                if(filtros.ids_colaboradores.length > 0){
                    busqueda_filtros.ids_colaboradores = filtros.ids_colaboradores;
                }
            }

            setFiltersData(busqueda_filtros);

            dispatch(buscarTareas(busqueda_filtros));

            setTaskAddData(agregar_tarea);
        }else{
            if('_id' in user){                
                setResponsableFilterInformationData({value: user._id, label: user.nombre});
                setColaboradoresFilterInformationData([{value: user._id, label: user.nombre}]);
                setFiltersData({
                    ...filtersData,
                    id_responsable: user._id, 
                    ids_colaboradores: [user._id]
                });

                dispatch(buscarTareas({
                    ...filtersData, 
                    id_responsable: user._id,
                    ids_colaboradores: [user._id]
                }));
            }
        }
    }, [filtros, user]);

    useEffect(() => {
        if(id){
            setIdProyecto(id);
            handleSelectFilterTask({value: id}, 1);
        }else{
            setIdProyecto(null);
        }
    }, [id]);

    useEffect(() => {
        if(idProyecto !== null && idProyecto !== undefined){
            handleFilter();
        }
    }, [idProyecto, listado]);

    return (
        <Fragment>

            {imageData.modal && (
                <Lightbox
                    mainSrc={imageData.src}
                    onCloseRequest={() => setImageData({ ...imageData, modal: false })}
                />
            )}

            {/* Modal de agregar tarea */}
            <Modal backdrop="static" show={addTaskModal} size="xl" onHide={() => {
                handleCloseAddTaskModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New task ({taskAddData.nombre_seccion})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-7 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input className="form-control" type="text" name="nombre" value={taskAddData.nombre || ''} onChange={(e) => {setTaskAddData({...taskAddData, nombre: e.target.value})}} />
                        </div>

                        <div className="col-md-5 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <Select name="id_proyecto" isClearable={true} options={projectsFilter} value={projectAddInformationData} onChange={(value) => handleSelectAddTask(value, 1)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Deadline:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker
                                        name="fecha_entrega"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        selected={ taskAddData.fecha_entrega !== null ? new Date(taskAddData.fecha_entrega) : '' }
                                        minDate={new Date()}
                                        onChange={ date => setTaskAddData({ ...taskAddData, fecha_entrega: date }) }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Priority:</label>
                            <Select name="prioridad" isClearable={true} options={priorityFilter} value={priorityAddInformationData} onChange={(value) => handleSelectAddTask(value, 2)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Assigned to:</label>
                            <Select name="id_responsable" isClearable={true} options={usersFilter} value={responsableAddInformationData} onChange={(value) => handleSelectAddTask(value, 3)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Collaboratos:</label>
                            <Select name="ids_colaboradores" isClearable={true} isMulti={true} options={usersFilter} value={colaboradoresAddInformationData} onChange={(value) => handleSelectAddTask(value, 4)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" name="descripcion" value={taskAddData.descripcion || ''} onChange={(e) => {setTaskAddData({...taskAddData, descripcion: e.target.value})}}></textarea>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">First note:</label>
                            <div className="input-group">
                                <textarea className="form-control" name="nota" value={taskNoteData.nota || ''} onChange={(e) => {setTaskNoteData({...taskNoteData, nota: e.target.value})}}></textarea>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => handleAgregarArchivosTask()}><i className="fas fa-file-medical fa-sm"></i> {taskNoteData.archivos !== null ? 'Change' : 'Add'} files</button>
                                    <input type="file" className="d-none" name="files" id="files" ref={files_ref} onChange={onChangeFiles} multiple/>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAddTaskModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={() => handleAddTask('new')}><i className="fas fa-solid fa-plus fa-sm"></i> Add task and new</button>
                    <button className="btn btn-success" onClick={() => {
                        handleAddTask('close');
                    }}><i className="fas fa-solid fa-check fa-sm"></i> Add task and close</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar tarea */}
            <Modal backdrop="static" show={editTaskModal} size="xl" onHide={() => {
                handleCloseEditTaskModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit task</Modal.Title>
                    <div style={{paddingLeft: '10px'}}>{statusTaskLg(taskEditData.status)}</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input className="form-control" type="text" name="nombre" value={taskEditData.nombre || ''} onChange={(e) => {setTaskEditData({...taskEditData, nombre: e.target.value})}} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <Select name="id_proyecto" isClearable={true} options={projectsFilter} value={projectEditInformationData} onChange={(value) => handleSelectEditTask(value, 1)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Section:</label>
                            <Select name="id_seccion" isClearable={true} options={sectionsFilter} value={sectionEditInformationData} onChange={(value) => handleSelectEditTask(value, 2)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Deadline:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker
                                        name="fecha_entrega"
                                        className="form-control text-center"
                                        dateFormat="MM/dd/yyyy"
                                        selected={ taskEditData.fecha_entrega !== null ? new Date(taskEditData.fecha_entrega) : '' }
                                        minDate={new Date()}
                                        onChange={ date => setTaskEditData({ ...taskEditData, fecha_entrega: date }) }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Priority:</label>
                            <Select name="prioridad" isClearable={true} options={priorityFilter} value={priorityEditInformationData} onChange={(value) => handleSelectEditTask(value, 3)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Status:</label>
                            <Select name="status" isClearable={true} options={statusFilter} value={statusEditInformationData} onChange={(value) => handleSelectEditTask(value, 4)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Assigned to:</label>
                            <Select name="id_responsable" isClearable={true} options={usersFilter} value={responsableEditInformationData} onChange={(value) => handleSelectEditTask(value, 5)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Collaboratos:</label>
                            <Select name="ids_colaboradores" isClearable={true} isMulti={true} options={usersFilter} value={colaboradoresEditInformationData} onChange={(value) => handleSelectEditTask(value, 6)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Notes:</label>
                            <textarea className="form-control" name="descripcion" value={taskEditData.descripcion || ''} onChange={(e) => {setTaskEditData({...taskEditData, descripcion: e.target.value})}}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditTaskModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={() => handleEditTask()}><i className="fas fa-solid fa-check fa-sm"></i> Edit task</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de detalles tarea */}
            <Modal backdrop="static" show={detailsTaskModal} size="xl" dialogClassName="full_height" onHide={() => {
                handleCloseDetailsTaskModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Details and notes of task ({taskDetailsData.nombre_seccion})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <h4>{taskDetailsData.nombre || 'Task without name'}</h4>
                            <p>{taskDetailsData.descripcion}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Deadline:</label>
                            <p className={((Math.floor((new Date(taskDetailsData.fecha_entrega) - hoy)/86400000)) <= 3 ) ? "todo-deadline-warning" : ""}>{taskDetailsData.fecha_entrega !== null ? new Date(taskDetailsData.fecha_entrega).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Assigned to:</label>
                            <p>{taskDetailsData.nombre_responsable !== null ? taskDetailsData.nombre_responsable : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Created by:</label>
                            <p>{taskDetailsData.nombre_creador !== null ? taskDetailsData.nombre_creador : 'Not defined'}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Priority:</label>
                            <p>{prioridadTask(taskDetailsData.tipo_prioridad)}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Status:</label>
                            <p>{statusTask(taskDetailsData.status[taskDetailsData.status.length - 1]?.tipo_status)}</p>
                        </div>

                        <div className="col-md-2 form-group mb-0">
                            <label className="font-weight-bold">Time:</label>
                            <p>{timeConvert(taskDetailsData.tiempo_total)}</p>
                        </div>

                        <div className="col-md-12 form-group mb-0">
                            <label className="font-weight-bold">Collaboratos:</label>
                            <p>{taskDetailsData.colaboradores.length > 0 ? taskDetailsData.colaboradores.join(', ') : 'Not defined'}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <p>{taskDetailsData.nombre_proyecto !== null ? taskDetailsData.nombre_proyecto : 'Not defined'}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Add note:</label>
                            <div className="input-group">
                                <textarea className="form-control" name="nota" value={taskNoteData.nota || ''} onChange={(e) => {setTaskNoteData({...taskNoteData, nota: e.target.value})}}></textarea>
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => handleAgregarArchivosTask()}><i className="fas fa-file-medical fa-sm"></i> {taskNoteData.archivos !== null ? 'Change' : 'Add'} files</button>
                                    <input type="file" className="d-none" name="files" id="files" ref={files_ref} onChange={onChangeFiles} multiple/>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-success" type="button" onClick={() => handleNoteTask()}><i className="fas fa-solid fa-check fa-sm"></i> Submit</button>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div className="row contenedor_notas px-3" id="contenedor_notas">
                        {
                            taskDetailsData.notas !== undefined ?
                                notasRow(detalles_tarea?.notas || [])
                            :
                                <div className="col-md-10 offset-md-1 text-center">
                                    <h4 className="mensaje_contenedor_notas">There are no notes or comments yet.</h4>
                                </div>
                        }
                        <div ref={myRef} />
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal de eliminar tarea */}
            <Modal backdrop="static" show={deleteTaskModal} onHide={() => {
                handleCloseDeleteTaskModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Do you want to delete the task "<b>{taskDeleteData.nombre}</b>" of the section "<b>{taskDeleteData.nombre_seccion}</b>"?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseDeleteTaskModal()}><i className="fas fa-solid fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => handleDeleteTask()}><i className="fas fa-solid fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de filtros */}
            <Modal backdrop="static" show={filtersModal} size="xl" onHide={() => {
                handleCloseFiltersModal();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8 form-group">
                            <label className="font-weight-bold">Project:</label>
                            <Select name="id_proyecto" isClearable={true} options={projectsFilter} value={projectFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 1)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Section:</label>
                            <Select name="id_seccion" isClearable={true} options={sectionsFilter} value={sectionFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 2)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Priority:</label>
                            <Select name="prioridad" isClearable={true} options={priorityFilter} value={priorityFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 3)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Status:</label>
                            <Select name="status" isClearable={true} options={statusFilter} value={statusFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 4)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Assigned to:</label>
                            <Select name="id_responsable" isClearable={true} options={usersFilter} value={responsableFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 5)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Finished:</label>
                            <Select name="finalizada" isClearable={true} options={finalizedFilter} value={finalizedFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 6)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-3 form-group">
                            <label className="font-weight-bold">Created by me:</label>
                            <Select name="creada" options={createdFilter} value={createdFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 7)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>

                        <div className="col-md-9 form-group">
                            <label className="font-weight-bold">Collaboratos:</label>
                            <Select name="ids_colaboradores" isClearable={true} isMulti={true} options={usersFilter} value={colaboradoresFilterInformationData} onChange={(value) => handleSelectFilterTask(value, 8)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                        </div>                        

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Start date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_inicial" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_inicial !== null ? new Date(filtersData.fecha_inicial) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_inicial: date }) } />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">End date:</label>
                            <div className="contenedor_datepicker">
                                <div className="d-flex">
                                    <span className="input-group-text" id="basic-addon1">
                                        <i className="fas fa-calendar-alt"></i>
                                    </span>
                                    <DatePicker name="fecha_final" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ filtersData.fecha_final !== null ? new Date(filtersData.fecha_final) : '' } onChange={ date => setFiltersData({ ...filtersData, fecha_final: date }) } />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseFiltersModal()}><i className="fas fa-solid fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={() => handleFilter()}><i className="fas fa-solid fa-search fa-sm"></i> Find</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-md-6 text-left">
                        <h3>To do list</h3>
                        <h6 className="mb-1">{(filtros !== null && filtros.nombre_proyecto) ? filtros.nombre_proyecto : 'No project selected'}</h6>
                    </div>

                    <div className="col-md-6 form-group text-right px-0">
                        {
                            !permisos_todolist?.filters ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleFiltersModal()}><i className="fas fa-solid fa-filter fa-sm"></i> Filters</button>
                            
                        }
                        {
                            !permisos_todolist?.calendar ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleAgenda()}><i className="fas fa-calendar-day fa-sm"></i> View calendar</button>
                        }
                        {
                            !permisos_reminders?.view ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleRecordatorios()}><i className="fas fa-bell fa-sm"></i> View reminders</button>
                        }
                        {
                            !permisos_todolist?.calendar ?
                                null
                            :
                                <button type="button" className="btn btn-primary mt-2 mr-2" onClick={() => handleReporte()}><i className="fas fa-file-alt fa-sm"></i> View report</button>
                        }
                        <button type="button" className="btn btn-secondary mt-2" onClick={() => handleGoBack()}><i className="fas fa-arrow-left fa-sm"></i> Back Previous</button>
                    </div>
                    
                    <hr/>
                    
                </div>

                <div className='col-md-12'>
                    <p></p>
                </div>
            </div>

            <div className="row mx-0">
                {
                    (secciones !== undefined) ?
                        <DragDropContext onDragEnd={(results) => handleOnDragEnd(results)}>
                            <Droppable
                                droppableId='all_columns'
                                type='column'
                                direction='vertical'
                            >
                                {(droppableProvided) => (
                                    <div
                                        {...droppableProvided.droppableProps}
                                        ref={droppableProvided.innerRef}
                                        style={{width: '100%', paddingBottom: '40px'}}
                                    >
                                        {tableRow(secciones)}
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    :
                        <div className="col-md-10 offset-md-1 text-center contenido_vacio">
                            <h3>There is no tasks to do.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('dashboard')}>
                                <i className="fas fa-home fa-sm"></i>Dashboard
                            </button>
                        </div>
                }
            </div>
        </Fragment>
    );
}

export default ToDoList;