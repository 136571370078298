import axios from 'axios';

import { OBTENER_BANCOS, OBTENER_TIPOS_TARJETAS } from '../actions/types';

import { setAlert } from './alert';

export const buscarBancos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/banks/bancos`, config);

        dispatch({
            type: OBTENER_BANCOS,
            payload: res.data.bancos
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const buscarTiposTarjetas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/banks/tipos-tarjetas`, config);

        dispatch({
            type: OBTENER_TIPOS_TARJETAS,
            payload: res.data.tipos_tarjetas
        });
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}