import { BUSCAR_GASTOS, NUEVO_GASTO, EDITAR_GASTO, DETALLES_GASTO, ELIMINAR_GASTO, EXTERNO_GASTO } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarGastos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/expenses`, config);
        dispatch({
            type: BUSCAR_GASTOS,
            payload: res.data.gastos
        })
    } catch(error){
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarGasto = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/expenses/nuevo`, gasto, config);
        dispatch({
            type: NUEVO_GASTO,
            payload: res.data.gasto
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const modificarGasto = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/expenses/editar`, gasto, config);
        dispatch({
            type: EDITAR_GASTO,
            payload: res.data.gasto
        })
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesGasto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/expenses/detalles`, body, config);
        dispatch({
            type: DETALLES_GASTO,
            payload: res.data.gasto
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarGasto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/expenses/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_GASTO,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const externoGasto = (valor) => async dispatch => {
    dispatch({
        type: EXTERNO_GASTO,
        payload: valor
    });
}

export const reporteGastos = (datos) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/expenses/reporte`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}