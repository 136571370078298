import React, { Fragment, useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';

//Actions
import { buscarGastos, agregarGasto, modificarGasto, detallesGasto, eliminarGasto, reporteGastos } from '../../actions/gastos';
import { listarProyectos } from '../../actions/proyectos';
import { setAlert } from '../../actions/alert';

const Gastos = ({history}) => {

    const dispatch = useDispatch();

    const { gastos, detalles } = useSelector(state => state.gastos);
    const proyectos = useSelector(state => state.proyectos);
    const { permisos_expenses } = useSelector(state => state.auth.user);

    const [dataTabla, setDataTabla] = useState({
        gastosTabla: null,
        proyectosTabla: null
    });

    //Nuevo
    const [nuevoRef, setNuevoRef] = useState([createRef()]);
    const [datosNuevo, setDatosNuevo] = useState({
        id_proyecto: null,
        descripcion: null,
        monto: null,
        files: [null],
        extensiones: [null]
    });
    const [nuevoModal, setNuevoModal] = useState(false);
    const [botonNuevo, setBotonNuevo] = useState(false);

    //Editar
    const [editarRef, setEditarRef] = useState([createRef()]);
    const [datosEditar, setDatosEditar] = useState({
        id: null,
        id_proyecto: null,
        descripcion: null,
        monto: null,
        files: [null],
        extensiones: [null]
    });
    const [editarModal, setEditarModal] = useState(false);
    const [botonEditar, setBotonEditar] = useState(false);

    //Detalles
    const [datosDetalles, setDatosDetalles] = useState({
        proyecto: null,
        usuario: null,
        descripcion: null,
        monto: null,
        urls: [],
        url_elgida: null
    });
    const [detailsModal, setDetailsModal] = useState(false);

    //Eliminar
    const [idDeleteGasto, setIdDeleteGasto] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    //Reporte
    const [datosReporteNomina, setDatosReporteNomina] = useState({
        id_proyecto: null,
        fecha_inicio: null,
        fecha_fin: null
    });
    const [reporteModal, setReporteModal] = useState(false);

    //Otros
    const { gastosTabla, proyectosTabla } = dataTabla;

    //Nuevo
    const handleOpenNuevoGasto = () => {
        setNuevoModal(true);
    }

    const handleAddFileNuevo = () => {
        let nueva_referencia = createRef();

        setNuevoRef([...nuevoRef, nueva_referencia]);

        let files = datosNuevo.files;
        let extensiones = datosNuevo.extensiones;

        files.push(null);
        extensiones.push(null);

        setDatosNuevo({
            ...datosNuevo,
            files: files,
            extensiones: extensiones
        });
    }

    const handleCloseNuevoGasto = () => {
        setDatosNuevo({
            ...datosNuevo,
            id_proyecto: null,
            descripcion: null,
            monto: null,
            files: [null],
            extensiones: [null]
        });

        setNuevoRef([createRef()]);

        setNuevoModal(false);
    }

    const onNumberChangeNuevo = (name, value) => {
        setDatosNuevo({
            ...datosNuevo, [name]: value
        });
    };

    const onChangeNuevo = ({ target }) => {
        setDatosNuevo({
            ...datosNuevo,
            [target.name]: target.value
        });
    }

    const onChangeFileNuevo = (index) => {

        let files = datosNuevo.files;
        let extensiones = datosNuevo.extensiones;

        if(nuevoRef[index]?.current?.files?.length > 0){
            let nombre_separado = nuevoRef[index]?.current?.files[0]?.name?.split('.');

            files[index] = nuevoRef[index].current.files[0];
            extensiones[index] = nombre_separado[nombre_separado.length - 1].toLowerCase();
        }else{
            files[index] = null;
            extensiones[index] = null;
        }

        setDatosNuevo({
            ...datosNuevo,
            files: files,
            extensiones: extensiones
        });
    }

    const nuevoGasto = async () => {
        if(datosNuevo.descripcion !== null && datosNuevo.descripcion !== ''){
            if(datosNuevo.monto !== null && datosNuevo.monto !== '' && datosNuevo.monto > 0){
                await setBotonNuevo(true);

                let formData = new FormData();
                formData.append('id_proyecto', datosNuevo.id_proyecto);
                formData.append('descripcion', datosNuevo.descripcion);
                formData.append('monto', datosNuevo.monto);

                for (var i = 0; i < datosNuevo.files.length; i++) {
                    formData.append('files', datosNuevo.files[i]);
                    formData.append('extensiones', datosNuevo.extensiones[i]);
                }

                await dispatch(agregarGasto(formData));

                await handleCloseNuevoGasto();

                await setBotonNuevo(false);
            }else{
                dispatch(setAlert('First enter a valid amount', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a description', 'danger'));
        }
    }

    //Editar
    const handleEditGasto = gasto => {
        console.log(gasto);
        setDatosEditar({
            ...datosEditar,
            id: gasto._id,
            id_proyecto: gasto.id_proyecto?._id || null,
            descripcion: gasto.descripcion,
            monto: gasto.monto
        });
        handleOpenEditGasto();
    }    

    const handleOpenEditGasto = () => {
        setEditarModal(true);
    }

    const handleAddFileEditar = () => {
        let nueva_referencia = createRef();

        setEditarRef([...editarRef, nueva_referencia]);

        let files = datosEditar.files;
        let extensiones = datosEditar.extensiones;

        files.push(null);
        extensiones.push(null);

        setDatosEditar({
            ...datosEditar,
            files: files,
            extensiones: extensiones
        });
    }

    const handleCloseEditGasto = () => {
        setDatosEditar({
            ...datosEditar,
            id_proyecto: null,
            descripcion: null,
            monto: null,
            files: [null],
            extensiones: [null]
        });

        setEditarRef([createRef()]);

        setEditarModal(false);
    }

    const onNumberChangeEditar = (name, value) => {
        setDatosEditar({
            ...datosEditar, [name]: value
        });
    };

    const onChangeEditar = ({ target }) => {
        setDatosEditar({
            ...datosEditar,
            [target.name]: target.value
        });
    }

    const onChangeFileEditar = (index) => {

        let files = datosEditar.files;
        let extensiones = datosEditar.extensiones;

        if(editarRef[index]?.current?.files?.length > 0){
            let nombre_separado = editarRef[index]?.current?.files[0]?.name?.split('.');

            files[index] = editarRef[index].current.files[0];
            extensiones[index] = nombre_separado[nombre_separado.length - 1].toLowerCase();
        }else{
            files[index] = null;
            extensiones[index] = null;
        }

        setDatosEditar({
            ...datosEditar,
            files: files,
            extensiones: extensiones
        });
    }

    const editarGasto = async () => {
        if(datosEditar.descripcion !== '' && datosEditar.descripcion !== null){
            if(datosEditar.monto !== '' && datosEditar.monto !== null && datosEditar.monto > 0){
                await setBotonEditar(true);

                let formData = new FormData();
                formData.append('id', datosEditar.id);
                formData.append('id_proyecto', datosEditar.id_proyecto);
                formData.append('descripcion', datosEditar.descripcion);
                formData.append('monto', datosEditar.monto);

                for (var i = 0; i < datosEditar.files.length; i++) {
                    formData.append('files', datosEditar.files[i]);
                    formData.append('extensiones', datosEditar.extensiones[i]);
                }

                await dispatch(modificarGasto(formData));

                await handleCloseEditGasto();

                await setBotonEditar(false);
            }else{
                dispatch(setAlert('First enter a valid amount', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a description', 'danger'));
        }
    }

    //Detalles
    const handleDetailsGasto = id => {
        dispatch(detallesGasto(id));
    }

    const handleOpenDetailsGasto = () => {
        setDetailsModal(true);
    }

    const handleOpenFilesGasto = () => {
        if(datosDetalles.url_elgida !== null){
            window.open(datosDetalles.url_elgida);
        }
    }

    const handleCloseDetailsGasto = () => {
        setDatosDetalles({
            ...datosDetalles,
            proyecto: null,
            usuario: null,
            descripcion: null,
            monto: null,
            urls: [],
            url_elgida: null
        });
        setDetailsModal(false);
    }

    //Eliminar
    const handleDeleteGasto = id => {
        setIdDeleteGasto(id);
    }

    const handleOpenDeleteGasto = () => {
        setDeleteModal(true);
    }

    const handleCloseDeleteGasto = () => {
        setIdDeleteGasto(null);
        setDeleteModal(false);
    }

    const deleteGasto = async () => {
        await dispatch(eliminarGasto(idDeleteGasto));
        await handleCloseDeleteGasto();
    }

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    // Reporte
    const handleOpenReporte = () => {
        setReporteModal(true);
    }

    const handleOnChangeReporte = e => {
        setDatosReporteNomina({
            ...datosReporteNomina,
            [e.target.name]: e.target.value
        })
    }

    const handleGenerarReporte = async () => {
        if(datosReporteNomina.fecha_inicio !== null){
            if(datosReporteNomina.fecha_fin !== null){
                await dispatch(reporteGastos(datosReporteNomina));
                await handleCloseReporteModal();
            }else{
                dispatch(setAlert('First select a valid end date', 'danger'));
            }
        }else{
            dispatch(setAlert('First select a valid start date', 'danger'));
        }
    }

    const handleCloseReporteModal = () => {
        setReporteModal(false);
    }

    const handleViewFile = (index) => {
        if(index !== null){
            const url = URL.createObjectURL(index);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    const columns = [
        {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Description',
            selector: 'descripcion',
            sortable: true,
            cell: item => `${item.descripcion}`,
        }, {
            name: 'Project',
            selector: 'proyecto',
            sortable: true,
            cell: item => `${item.id_proyecto?.direccion || 'For all projects'}`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: '',
            button: true,
            cell: item => {
                if(permisos_expenses?.detail === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleDetailsGasto(item._id)}>
                            <i className="fa fa-info-circle" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_expenses?.edit === true){
                    return (
                        <button className="btn btn-link text-info" onClick={() => handleEditGasto(item)}>
                            <i className="fa fa-edit" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(permisos_expenses?.delete === true){
                    return (
                        <button className="btn btn-link text-danger" onClick={() => handleDeleteGasto(item._id)}>
                            <i className="fa fa-times" />
                        </button>
                    );
                }else{
                    return <>-</>
                }
            }
        }
    ];

    useEffect(() => {
        dispatch(buscarGastos());
        dispatch(listarProyectos());
    }, []);

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            gastosTabla: gastos
        });
    }, [gastos]);

    useEffect(() => {
        if(detalles !== null){
            setDatosDetalles({
                ...datosDetalles,
                proyecto: detalles?.proyecto,
                usuario: detalles?.usuario,
                descripcion: detalles?.descripcion,
                monto: detalles?.monto,
                urls: detalles?.urls_archivos
            });

            handleOpenDetailsGasto();
        }
    }, [detalles]);

    useEffect(() => {
        setDataTabla({
            ...dataTabla,
            proyectosTabla: proyectos.listado
        });
    }, [proyectos]);

    useEffect(() => {
        if(idDeleteGasto !== null){
            handleOpenDeleteGasto();
        }
    }, [idDeleteGasto]);

    return ( 
            <Fragment>

                {/* Modal de nuevo */}
                <Modal show={nuevoModal} onHide={() => {
                    handleCloseNuevoGasto();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>New expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <select className="form-control" value={datosNuevo.id_proyecto || null} name="id_proyecto" onChange={(e) => onChangeNuevo(e)}>
                                    <option value={null}>General</option>
                                    {
                                        proyectosTabla?.length > 0 ?
                                            proyectosTabla?.map(proyecto => {
                                                return(
                                                    <option key={proyecto._id} value={ proyecto._id }>
                                                        { proyecto.direccion }
                                                    </option>
                                                )
                                            })
                                        : ''
                                    } 
                                </select>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Description:</label>
                                <textarea className="form-control" value={datosNuevo.descripcion || ''} name="descripcion" onChange={e => onChangeNuevo(e)}></textarea>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Amount:</label>
                                <CurrencyInput
                                  name="monto"
                                  className="form-control text-center"
                                  value={datosNuevo.monto || ''}
                                  decimalsLimit={2}
                                  groupSeparator=","
                                  decimalSeparator="."
                                  onValueChange={(value, name) => onNumberChangeNuevo(name, value)}
                                />
                            </div>

                            {
                                nuevoRef.map((referencia, index, array) => {
                                    return (
                                        <div className="col-md-12 form-group" key={'archivo_' + index}>
                                            <label className="font-weight-bold">File {index + 1}:</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={referencia} onChange={() => onChangeFileNuevo(index)} aria-describedby="view_file_newFile"/>
                                                    <label className="custom-file-label">{datosNuevo.files[index] ? 'File Selected' : 'Choose file'}</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevo.files[index])}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary mr-3" onClick={() => handleAddFileNuevo()} disabled={botonNuevo}><i className="fas fa-file-medical fa-sm"></i> New file</button>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevoGasto()} disabled={botonNuevo}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={nuevoGasto} disabled={botonNuevo}><i className="fas fa-check fa-sm"></i> Save</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de editar */}
                <Modal show={editarModal} onHide={() => {
                    handleCloseEditGasto();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <select className="form-control" value={datosEditar.id_proyecto || null} name="id_proyecto" onChange={(e) => onChangeEditar(e)}>
                                    <option value={null}>General</option>
                                    {
                                        proyectosTabla?.length > 0 ?
                                            proyectosTabla?.map(proyecto => {
                                                return(
                                                    <option key={proyecto._id} value={ proyecto._id }>
                                                        { proyecto.direccion }
                                                    </option>
                                                )
                                            })
                                        : ''
                                    } 
                                </select>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Description:</label>
                                <textarea className="form-control" value={datosEditar.descripcion || ''} name="descripcion" onChange={e => onChangeEditar(e)}></textarea>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Amount:</label>
                                <CurrencyInput
                                  name="monto"
                                  className="form-control text-center"
                                  value={datosEditar.monto || ''}
                                  decimalsLimit={2}
                                  groupSeparator=","
                                  decimalSeparator="."
                                  onValueChange={(value, name) => onNumberChangeEditar(name, value)}
                                />
                            </div>

                            {
                                editarRef.map((referencia, index, array) => {
                                    return (
                                        <div className="col-md-12 form-group">
                                            <label className="font-weight-bold">File {index + 1}:</label>
                                            {/* <input type="file" className="form-control" ref={referencia} onChange={() => onChangeFileEditar(index)}/> */}
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input form-control" name="file" id="editFile" ref={referencia} onChange={() => onChangeFileEditar(index)} aria-describedby="view_file_editFile"/>
                                                    <label className="custom-file-label">Choose file</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosEditar.files[index])}><i class="fas fa-eye"></i> View File</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary mr-3" onClick={() => handleAddFileEditar()} disabled={botonEditar}><i className="fas fa-file-medical fa-sm"></i> New file</button>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseEditGasto()} disabled={botonEditar}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={editarGasto} disabled={botonEditar}><i className="fas fa-check fa-sm"></i> Save</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de detalles */}
                <Modal show={detailsModal} onHide={() => {
                    handleCloseDetailsGasto();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Details of expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold mr-2">Project:</label>
                                <label>{datosDetalles.proyecto}</label>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold mr-2">User:</label>
                                <label>{datosDetalles.usuario}</label>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold mr-2">Description:</label>
                                <label>{datosDetalles.descripcion}</label>
                            </div>

                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold mr-2">Amount:</label>
                                <label>{datosDetalles.monto !== null ? numberToDecimal(datosDetalles.monto) : '-'}</label>
                            </div>

                            {
                                datosDetalles.urls?.length > 0 ?
                                    <div className="col-md-12 form-group">
                                        <label className="font-weight-bold mr-2">Files:</label>
                                        <select className="form-control" onChange={(e) => setDatosDetalles({ ...datosDetalles, url_elgida: e.target.value })}>
                                            <option value={null}>Select a file</option>
                                            {
                                                datosDetalles.urls?.length > 0 ?
                                                    datosDetalles.urls?.map((url, index) => {
                                                        return(
                                                            <option key={'url_' + index} value={ url }>
                                                                { 'Archivo ' + (index + 1) }
                                                            </option>
                                                        )
                                                    })
                                                : ''
                                            } 
                                        </select>
                                    </div>
                                :
                                    <></>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseDetailsGasto()} disabled={botonEditar}><i className="fas fa-times fa-sm"></i> Close</button>
                        {
                            datosDetalles.urls?.length > 0 ?
                                <button type="button" className="btn btn-info mr-3" onClick={() => handleOpenFilesGasto()} target="_blank"><i className="fas fa-file fa-sm"></i> View file</button>
                            :
                                <></>
                        }
                    </Modal.Footer>
                </Modal>

                {/* Modal de eliminacion */}
                <Modal show={deleteModal} onHide={() => {
                    handleCloseDeleteGasto();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Payroll</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to delete this expense?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseDeleteGasto()}><i className="fas fa-times fa-sm"></i> No</button>
                        <button className="btn btn-success" onClick={deleteGasto}><i className="fas fa-check fa-sm"></i> Yes</button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de reporte */}
                <Modal show={reporteModal} size="lg" onHide={() => {
                    handleCloseReporteModal();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Report generation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-4">
                            <div className="col-md-12 form-group">
                                <label className="font-weight-bold">Project:</label>
                                <select className="form-control" value={datosReporteNomina.id_proyecto || null} name="id_proyecto" onChange={(e) => handleOnChangeReporte(e)}>
                                    <option value={null}>General</option>
                                    {
                                        proyectosTabla?.length > 0 ?
                                            proyectosTabla?.map(proyecto => {
                                                return(
                                                    <option key={proyecto._id} value={ proyecto._id }>
                                                        { proyecto.direccion }
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">Start date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker name="fecha_inicio" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ datosReporteNomina.fecha_inicio !== null ? new Date(datosReporteNomina.fecha_inicio) : '' } onChange={ date => setDatosReporteNomina({ ...datosReporteNomina, fecha_inicio: date }) } />
                                </div>
                            </div>

                            <div className="col-md-6 form-group">
                                <label className="font-weight-bold">End date:</label>
                                <div className="contenedor_datepicker">
                                    <DatePicker name="fecha_fin" className="form-control text-center" dateFormat="MM/dd/yyyy" selected={ datosReporteNomina.fecha_fin !== null ? new Date(datosReporteNomina.fecha_fin) : '' } onChange={ date => setDatosReporteNomina({ ...datosReporteNomina, fecha_fin: date }) } />
                                </div>
                            </div>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger mr-3" onClick={() => handleCloseReporteModal()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                        <button className="btn btn-success" onClick={handleGenerarReporte}><i className="fas fa-check fa-sm"></i> Generate</button>
                    </Modal.Footer>
                </Modal>

                <div className="row mx-0">
                    <div className="col-md-12 mb-3 mt-5 row">
                        <div className="col-md-4 text-left">
                            <h3>Expenses</h3>
                            <p className="text-muted">{gastos ? gastos.length : null} registered expenses</p>
                        </div>
                        <div className="col-md-8 text-right">
                            {
                                !permisos_expenses?.new ?
                                null
                                :
                                <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevoGasto()}><i className="fas fa-dollar-sign fa-sm"></i> Add expense</button>
                            }
                            {
                                !permisos_expenses?.report ?
                                null
                                :
                                <button type="button" className="btn btn-primary float-right mb-3" onClick={() => {handleOpenReporte()}}><i className="fas fa-chart-bar fa-sm"></i> Generate report </button>
                            }
                        </div>
                    </div>


                    <div className="table-responsive pl-2">
                        {
                            gastos !== undefined ?
                                <DataTable
                                    columns={columns}
                                    data={gastosTabla || []}
                                    noHeader={true}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                                :
                                <></>
                        }
                    </div>
                </div>
            </Fragment>
    );
}
 
export default Gastos;
