import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { GoogleMap, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { v4 as uuid } from 'uuid';

import { setAlert } from '../../actions/alert';
import { buscarUsuarios } from '../../actions/usuarios';
import { detallesProyecto, masterlistProyecto, editarProyecto, validarProyecto, buscarTiposProyectos, externoEliminar, externoNota, notesProyecto } from '../../actions/proyectos';

const ContainerStyle = {
    width: '100%',
    height: '50vh'
};

const EditarProyecto = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles, masterlist, proyecto_coincidencia } = useSelector(state => state.proyectos);
    const usuarios = useSelector(state => state.usuarios);
    const tipos = useSelector(state => state.tipos);

    const [proyectoData, setProyectoData] = useState({
        id: null,
        tipo_proyecto: null,
        tipo_construccion: null,
        direccion: null,
        latitud: null,
        longitud: null,
        id_usuario_responsable: null,
        url_offer_image: null
    });

    const [screenshotFile, setScreenshotFile] = useState({
        file: undefined,
        url: ''
    });

    const [coincidenciaProyecto, setCoincidenciaProyecto] = useState({
        direccion: '',
        proyecto: '',
        construccion: ''
    });

    const [isBackButtonClicked, setBackbuttonPress] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [coord, setCoord] = useState({
        lat: 38.9071923,
        lng: -77.0368707
    });

    const [coincidenciaModal, setCoincidenciaModal] = useState(false);
    const [screenshotModal, setScreenshotModal] = useState(false);
    const [viewScreenshotModal, setViewScreenshotModal] = useState(false);

    /* User Assigned */
    const [userResponsableSelectData, setUserResponsableSelectData] = useState(null);

    const [usersFilter, setUsersFilter] = useState([]);

    /* Funciones generales */
    const onPasteScreenShotFile = (e) => {
        if(!e?.clipboardData?.files[0]){
            setScreenshotFile({
                ...screenshotFile,
                file: undefined,
                archivo: ''
            });
            
            alert('No file selected, try again');
            setScreenshotModal(false);
            
            
        }else{
            let file = e.clipboardData.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setScreenshotFile({
                    ...screenshotFile,
                    file: file,
                    url: reader.result
                });
            }
        }
    }

    const onDeleteScreenshot = () => {
        setScreenshotFile({
            file: undefined,
            url: ''
        });
    };

    const handleCloseModalScreenShoot = () => {
        setScreenshotModal(false);

        setScreenshotFile({
            file: undefined,
            url: ''
        });
    }

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => (setCoord({
                lat: latLng.lat, lng: latLng.lng
            }),
                setProyectoData({
                    ...proyectoData, direccion: address, latitud: latLng.lat, longitud: latLng.lng
                })
            ))
            .catch(error => console.error('Error', error));
    };

    const handleChange = address => {
        setProyectoData({
            ...proyectoData, direccion: address
        });
    };

    const handleMapClick = ({latLng}) => { 
        setCoord({
            lat: latLng.lat(),
            lng: latLng.lng()
        });
        
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage('en');
        Geocode.fromLatLng(latLng.lat(), latLng.lng())
            .then(
                response => {
                    const address = response.results[0].formatted_address;
                    setProyectoData({
                        ...proyectoData,
                        direccion: address,
                        latitud: latLng.lat(),
                        longitud: latLng.lng()
                    });
                }
            );
    }

    const handleTypeProject = (e) => {
        let valor = document.getElementById('p-proyecto');

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: valor.value,
                tipo_construccion: null
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_proyecto: null,
                tipo_construccion: null
            });
        }
    }    

    const handleTypeConstruction = (e) => {
        let valor = document.getElementById('t-construccion');

        if (valor.selectedIndex !== 0) {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: valor.value
            });
        } else {
            setProyectoData({
                ...proyectoData,
                tipo_construccion: null
            });
        }
    }

    const onChangeSelectSearch = async (option, origin, name_origin) => {
        if(option !== null){
            let {value, label} = option;

            switch(origin){
                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(option);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: value
                });
            }            
        }else{
            switch(origin){
                case 'id_usuario_responsable':
                    await setUserResponsableSelectData(null);
                    break;

                default:
                    break;
            }

            if(name_origin !== ''){
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null,
                    [name_origin]: null
                });
            }else{
                await setProyectoData({
                    ...proyectoData,
                    [origin]: null
                });
            }
        }
    };

    const handleCoincidenciaModal = () => {
        setCoincidenciaModal(true);
    }

    const handleCloseCoincidenciaModal = () => {
        setCoincidenciaModal(false);
    }

    const newNote = async (campo) => {
        await dispatch(externoNota(true, campo));
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!isBackButtonClicked) {
            if (window.confirm("Do you want to exit the page? Data will be lost")) {
                setBackbuttonPress(true);
                history.go(-1);
                // handleCloseNoGuardarCambiosModal();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false)
            }
        }
    }

    const handleNotes = async () => {
        await dispatch(notesProyecto(id));
        
        await history.push('/projects/notes/' + id);
    }

    /* Funciones de guardado */
    const handleDelete = async () => {
        await dispatch(externoEliminar(null, true));
    }

    const handleCancelEdit = async () => {
        history.push('/projects/projects');
    }

    const handleValidate = async () => {
        if (proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.id_usuario_responsable !== null) {
            await dispatch(validarProyecto(proyectoData.id, proyectoData.direccion));
        } else{
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    const handleSave = async () => {
        console.log(proyectoData);
        if (proyectoData.direccion !== null && proyectoData.tipo_proyecto !== null && proyectoData.tipo_construccion !== null && proyectoData.id_usuario_responsable !== null) {
            
            let formData = new FormData();
            formData.append('id', proyectoData.id);
            formData.append('tipo_proyecto', proyectoData.tipo_proyecto);
            formData.append('tipo_construccion', proyectoData.tipo_construccion);
            formData.append('direccion', proyectoData.direccion);
            formData.append('latitud', proyectoData.latitud);
            formData.append('longitud', proyectoData.longitud);
            formData.append('id_usuario_responsable', proyectoData.id_usuario_responsable);
            formData.append('screenshot_file', screenshotFile.file);

            await setSpinner(true);
            await setFormDisabled(true);

            await dispatch(editarProyecto(formData));

            await dispatch(validarProyecto(null, null));
                
            await setSpinner(false);
            await setFormDisabled(false);

            await history.push('/projects/projects');
        } else {
            await dispatch(setAlert('Missing information', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(buscarTiposProyectos(0));
        dispatch(detallesProyecto(id));
        dispatch(masterlistProyecto(id));
        dispatch(buscarUsuarios());

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        window.onbeforeunload = function () {
            return "Data will be lost if you leave the page, are you sure?";
        };

        return () => {
            dispatch(validarProyecto(null, null));
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    useEffect(() => {

        let options = [];

        for (let i = 0; i < usuarios.length; i++) {
            options.push({ value: usuarios[i]._id, label: usuarios[i].nombre });
        }

        setUsersFilter(options);
    }, [usuarios]);

    useEffect(() => {
        if(proyectoData.tipo_proyecto === null){
            dispatch(buscarTiposProyectos(0));
        }else{
            dispatch(buscarTiposProyectos(proyectoData.tipo_proyecto));
        }
    }, [proyectoData.tipo_proyecto]);

    useEffect(() => {
        if(coincidenciaProyecto.direccion !== '' && coincidenciaProyecto.proyecto !== '' && coincidenciaProyecto.construccion !== ''){
            handleCoincidenciaModal();
        }
    }, [coincidenciaProyecto]);

    useEffect(() => {
        console.log(usersFilter);
        if(proyectoData.id_usuario_responsable === null){
            setUserResponsableSelectData(null);
        }else{
            if(usersFilter.length > 0){
                let usuario_asignado = usersFilter.filter( usuario => String(usuario.value) === String(proyectoData.id_usuario_responsable) );

                if(usuario_asignado){
                    setUserResponsableSelectData(usuario_asignado);
                }
            }
        }
    }, [usersFilter, proyectoData.id_usuario_responsable]);

    useEffect(() => {
        let datos_ingresar = {
            ...proyectoData,
            id: detalles?.proyecto?._id || null,
            tipo_proyecto: detalles?.proyecto?.tipo_proyecto || null,
            tipo_construccion: detalles?.proyecto?.tipo_construccion || null,
            direccion: detalles?.proyecto?.direccion || null,
            latitud: detalles?.proyecto?.latitud || null,
            longitud: detalles?.proyecto?.longitud || null,
            id_usuario_responsable: masterlist?.id_usuario_responsable || null,
            url_offer_image: detalles?.proyecto?.url_offer_image
        };

        setProyectoData(datos_ingresar);

        setCoord({
            lat: parseFloat(detalles?.proyecto?.latitud),
            lng: parseFloat(detalles?.proyecto?.longitud)
        });
    }, [detalles, masterlist]);

    useEffect(() => {
        if(proyecto_coincidencia !== null){
            if(proyecto_coincidencia !== ''){
                setCoincidenciaProyecto({
                    ...coincidenciaProyecto,
                    direccion: proyecto_coincidencia?.direccion,
                    proyecto: proyecto_coincidencia?.proyecto,
                    construccion: proyecto_coincidencia?.construccion
                });
            }else{
                handleSave();
            }
        }
    }, [proyecto_coincidencia]);

    return (
        <Fragment>
            {/* Modal de coincidencia */}
            <Modal backdrop="static" show={coincidenciaModal} centered onHide={() => {
                setCoincidenciaModal(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Similarity in projects</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>The project with the address "<b>{coincidenciaProyecto.direccion}</b>" (<b>{coincidenciaProyecto.proyecto}</b> - <b>{coincidenciaProyecto.construccion}</b>) has some similarity. Do you want to continue saving anyway?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseCoincidenciaModal()}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={() => {setCoincidenciaModal(false); handleSave();}}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal para agregar Screenshot File */}
            <Modal backdrop="static" show={screenshotModal} centered onHide={() => {
                setScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Pasting Screenshot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12" onPaste={(e)=> onPasteScreenShotFile(e)} style={{height: '100%', width: '100%', 'border-style': 'dashed', 'border-color': '#6c757d' }}>
                            {
                                screenshotFile.url !== '' ?
                                <>
                                    <button className="btn btn-small btn-danger py-1 px-2" onClick={() => onDeleteScreenshot()}><i className='fas fa-times'></i></button>
                                    <img id='pasteScreenshot' src={screenshotFile.url} style={{height: '100%', width: '100%', padding: '8px'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}>Paste Here</h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseModalScreenShoot()}>Cancel</button>
                    <button className="btn btn-success" onClick={() => setScreenshotModal(false)}>Save</button>
                </Modal.Footer>
            </Modal>
            
            {/* Modal para ver Screenshot File */}
            <Modal backdrop="static" show={viewScreenshotModal} size="lg" centered onHide={() => {
                setViewScreenshotModal(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>View Screenshot file</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="row">
                        <div className="col-12">
                            {
                                screenshotFile.file !== null || proyectoData.url_offer_image !== null ?
                                <>
                                    <img id='screenshotFile' src={screenshotFile.file ? screenshotFile.url : proyectoData.url_offer_image} style={{width: '100%'}}></img>
                                </>
                                :
                                <h5 style={{display: 'flex', justifyContent: 'center', 'paddingTop': '8px'}}> No image Found </h5>
                            }

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => setViewScreenshotModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className="row pb-5">
                <form className="col-md-12" autoComplete="off" onKeyPress={e => {e.key === 'Enter' && e.preventDefault()}}>
                    <input autoComplete='off' name="hidden" type="text" style={{display:'none'}} />
                    
                    <div className='row'>
                        <h3 className="col-md mb-3 mt-5">Edit side project</h3>

                        <div className="col-md d-flex justify-content-end mb-3 mt-5">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => handleCancelEdit()} disabled={formDisabled ? true : false}><i className="fas fa-arrow-left fa-sm"></i> Back previus</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setScreenshotModal(true)}><i className="fas fa-file-medical fa-sm"></i> {proyectoData.url_offer_image || screenshotFile.file ? 'Update' : 'Add'} Screenshot file</button>
                            {
                                proyectoData.url_offer_image || screenshotFile.file ?
                                    <button type="button" className="btn btn-primary mr-2" onClick={() => setViewScreenshotModal(true)}><i className="fas fa-file fa-sm"></i> View Screenshot file</button>
                                :
                                    null
                            }
                            <button type="button" className="btn btn-primary mr-2" onClick={() => setCollapse(!collapse)} data-toggle="collapse" data-target="#contenedor_mapa" aria-expanded="true" aria-controls="contenedor_mapa"><i className="fas fa-map"></i> {collapse ? 'Hide Map' : 'View Map'}</button>
                            <button type="button" className="btn btn-primary mr-2" onClick={() => handleNotes()}><i className="fas fa-sticky-note fa-sm"></i> View notes</button>
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleDelete()} disabled={formDisabled ? true : false}><i className="fas fa-trash fa-sm"></i> Delete</button>
                            <button type="button" className="btn btn-success" onClick={() => handleValidate()} disabled={formDisabled ? true : false}><i className="fas fa-save fa-sm"></i> Save</button>
                        </div>
                    </div>

                    {spinner ?  
                            <div className="d-flex justify-content-center">
                                <div className="sk-chase mt-5" style={{position: 'absolute', zIndex: 2000}}>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                    <div className="sk-chase-dot"></div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="row mt-5">
                        <div className="col-md-12 row">
                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_proyecto !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Project Type')}>Project Type: *</label> <br></br>
                                <select name="tipo_proyecto" id="p-proyecto" className="form-control" onChange={handleTypeProject} value={proyectoData.tipo_proyecto || null} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.proyectos?.length > 0 ?
                                            tipos.proyectos?.map(tipo => {
                                                return(
                                                    <option key={tipo._id} value={ tipo._id }>
                                                        {tipo.nombre}
                                                    </option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                            </div>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.tipo_construccion !== null ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Property Type')}>Property Type: *</label> <br></br>
                                <select name="tipo_construccion" id="t-construccion" className="form-control" onChange={e => {handleTypeConstruction(e)}} value={(proyectoData.tipo_construccion)} disabled={formDisabled ? true : false}>
                                    <option value={null}>Select a type</option>
                                    {
                                        tipos.construcciones?.length > 0 ?
                                            tipos.construcciones?.map(tipo => {
                                                return (
                                                    <option key={tipo._id} value={ tipo._id }>
                                                        { tipo.nombre }
                                                    </option>
                                                )
                                            })
                                        :
                                            ''
                                    }
                                </select>
                            </div>

                            <PlacesAutocomplete
                                value={proyectoData.direccion || ''}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >

                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div className={'col-md form-group' + (proyectoData.direccion !== null && proyectoData.direccion !== '' ? '' : ' campo-recordatorio')}>
                                        <label className="font-weight-bold" onClick={() => newNote('General address')}>General address: *</label>
                                        <input
                                            {...getInputProps({
                                                className: 'location-search-input form-control',
                                                id: 'i-direccion'
                                            })}
                                            disabled={formDisabled ? true : false}
                                            autoComplete='off'
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const id = uuid();
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        key={id}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            <div className={'col-md-2 form-group campo_select_lg' + (proyectoData.id_usuario_responsable !== null && proyectoData.id_usuario_responsable !== '' ? '' : ' campo-recordatorio')}>
                                <label className="font-weight-bold" onClick={() => newNote('Who will be the responsible user?')}>Who will be the responsible user?:</label>
                                <div className="input-group">
                                    <Select
                                        name="id_usuario_responsable"
                                        className="select-group"
                                        aria-describedby="button-broker-seller"
                                        isClearable={true}
                                        options={usersFilter}
                                        value={userResponsableSelectData}
                                        onChange={(value) => onChangeSelectSearch(value, 'id_usuario_responsable', '')}
                                        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isDisabled={formDisabled ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="contenedor_mapa" className="row collapse mt-3" aria-labelledby="headingOne" data-parent="#contender_form">
                        <div className="col-md-8 offset-md-2 form-group">
                            <div id="mapa" className="mapa">
                                <GoogleMap
                                    mapContainerStyle={ContainerStyle}
                                    center={coord}
                                    zoom={16}
                                    onRightClick={e => { handleMapClick(e) }}
                                >
                                    <Marker key={coord.lat} position={coord} />
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

export default EditarProyecto;