import React, { Fragment, useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import { Modal, Badge } from 'react-bootstrap';

//Actions
import { detallesTarjetaCredito, statusTarjetaCredito, agregarEstadoTarjetaCredito, detallesEstadoTarjetaCredito, detallesFileEstadoTarjetaCredito, editarEstadoTarjetaCredito, eliminarEstadoTarjetaCredito, agregarPagoTarjetaCredito, detallesPagoTarjetaCredito, editarPagoTarjetaCredito, eliminarPagoTarjetaCredito, agregarCreditoTarjetaCredito, detallesCreditoTarjetaCredito, editarCreditoTarjetaCredito, eliminarCreditoTarjetaCredito } from '../../actions/contabilidad';
import { setAlert } from '../../actions/alert';

const styles = {
    titulo: {
        color: '#2C2052'
    }, 
    titulo_restaltado: {
        color: '#FFFFFF'
    }, 
    infoText: {
        margin: 0
    },
    infoNumero: {
        fontSize: 20,
        marginLeft: 15,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    consultaRow: {
        paddingBottom: 5,
        paddingTop: 5,
        borderBottom: '0.8px solid rgba(0,0,0,0.2)'
    }
}

const EstadosTarjetaCredito = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles_tarjeta, detalles_estado_tarjeta, detalles_pago_tarjeta, detalles_credito_tarjeta, detalles_archivo } = useSelector(state => state.contabilidad);

    const [datosTarjeta, setDatosTarjeta] = useState({
        id_estado: null, 
        fecha_promocion: null,
        fecha_corte_inicial: null,
        fecha_corte_final: null,
        fecha_pago: null,
        credito_usado: 0,
        pago_anterior: 0,
        pago_minimo: 0,
        pago_total: 0,
        pagos_totales: 0,
        creditos_totales: 0,
        balance: 0, 
        status: false, 
        notas: null
    });

    const [dataTabla, setDataTabla] = useState({
        estadosTarjeta: [],
        pagosTarjeta: [], 
        creditosTarjeta: []
    });

    /* Eliminar tarjeta */
    const [datosDeleteTarjeta, setDatosDeleteTarjeta] = useState({
        id: null,
        digitos: null
    });
    const [deleteModal, setDeleteModal] = useState(false);

    /* Nuevo estado */
    const [datosNuevoEstado, setDatosNuevoEstado] = useState({
        id_tarjeta: null,
        observaciones: null,
        fecha_inicial: null,
        fecha_final: null,
        fecha_pago: null,
        deuda_anterior: null,
        deuda: null,
        pago_minimo: null, 
        archivo: null
    });

    let nuevo_ref = createRef();

    const [nuevoEstadoModal, setNuevoEstadooModal] = useState(false);
    const [botonNuevoEstado, setBotonNuevoEstado] = useState(false);

    /* Editar estado */
    const [datosEditarEstado, setDatosEditarEstado] = useState({
        id: null,
        id_tarjeta: null,
        observaciones: null,
        fecha_inicial: null,
        fecha_final: null,
        fecha_pago: null,
        deuda_anterior: null,
        deuda: null,
        pago_minimo: null, 
        archivo: null, 
        url: null
    });

    let editar_ref = createRef();

    const [editarEstadoModal, setEditarEstadoModal] = useState(false);
    const [botonEditarEstado, setBotonEditarEstado] = useState(false);

    /* Eliminar estado */
    const [datosEliminarEstado, setDatosEliminarEstado] = useState({
        id: null,
        id_tarjeta: null,
        deuda: null
    });

    const [eliminarEstadoModal, setEliminarEstadoModal] = useState(false);
    const [botonEliminarEstado, setBotonEliminarEstado] = useState(false);

    /* Nuevo pago */
    const [datosNuevoPago, setDatosNuevoPago] = useState({
        id_tarjeta: null,
        id_estado: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [nuevoPagoModal, setNuevoPagoModal] = useState(false);
    const [botonNuevoPago, setBotonNuevoPago] = useState(false);

    /* Editar pago */
    const [datosEditarPago, setDatosEditarPago] = useState({
        id: null,
        id_tarjeta: null,
        id_estado: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [editarPagoModal, setEditarPagoModal] = useState(false);
    const [botonEditarPago, setBotonEditarPago] = useState(false);

    /* Eliminar pago */
    const [datosEliminarPago, setDatosEliminarPago] = useState({
        id: null,
        id_tarjeta: null,
        id_estado: null,
        monto: null, 
        pagos: 0
    });

    const [eliminarPagoModal, setEliminarPagoModal] = useState(false);
    const [botonEliminarPago, setBotonEliminarPago] = useState(false);

    /* Nuevo credito */
    const [datosNuevoCredito, setDatosNuevoCredito] = useState({
        id_tarjeta: null,
        id_estado: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [nuevoCreditoModal, setNuevoCreditoModal] = useState(false);
    const [botonNuevoCredito, setBotonNuevoCredito] = useState(false);

    /* Editar credito */
    const [datosEditarCredito, setDatosEditarCredito] = useState({
        id: null,
        id_tarjeta: null,
        id_estado: null,
        observaciones: null,
        fecha: new Date(),
        monto: null
    });

    const [editarCreditoModal, setEditarCreditoModal] = useState(false);
    const [botonEditarCredito, setBotonEditarCredito] = useState(false);

    /* Eliminar credito */
    const [datosEliminarCredito, setDatosEliminarCredito] = useState({
        id: null,
        id_tarjeta: null,
        id_estado: null,
        monto: null, 
        creditos: 0
    });

    const [eliminarCreditoModal, setEliminarCreditoModal] = useState(false);
    const [botonEliminarCredito, setBotonEliminarCredito] = useState(false);

    //Status
    const handleStatusTarjeta = (tarjeta) => {
        dispatch(statusTarjetaCredito(tarjeta._id));   
    }

    //Nuevo estado
    const handleOpenNuevoEstado = () => {
        let nueva_fecha_corte_inicial = datosTarjeta.fecha_corte_final;

        if(nueva_fecha_corte_inicial){

            nueva_fecha_corte_inicial = new Date(nueva_fecha_corte_inicial);
            nueva_fecha_corte_inicial.setDate(nueva_fecha_corte_inicial.getDate() + 1);

            setDatosNuevoEstado({
                ...datosNuevoEstado, 
                fecha_inicial: nueva_fecha_corte_inicial
            });
        }
        
        setNuevoEstadooModal(true);
    }

    const onNumberChangeNuevoEstado = (name, value) => {
        setDatosNuevoEstado({
            ...datosNuevoEstado, [name]: value
        });
    }

    const onChangeFileNuevo = () => {
        setDatosNuevoEstado({
            ...datosNuevoEstado,
            archivo: nuevo_ref.current.files[0]
        });
    }

    const onChangeNuevoEstado = ({ target }) => {
        setDatosNuevoEstado({
            ...datosNuevoEstado,
            [target.name]: target.value
        });
    }

    const handleSaveNuevoEstado = async () => {
        if(datosNuevoEstado.deuda !== null && datosNuevoEstado.deuda !== '' && datosNuevoEstado.deuda_anterior !== null && datosNuevoEstado.deuda_anterior !== ''){
            if(datosNuevoEstado.fecha_inicial !== null && datosNuevoEstado.fecha_inicial !== '' && datosNuevoEstado.fecha_final !== null && datosNuevoEstado.fecha_final !== '' && datosNuevoEstado.fecha_pago !== null && datosNuevoEstado.fecha_pago !== ''){
                if(datosNuevoEstado.pago_minimo !== null && datosNuevoEstado.pago_minimo !== ''){
                    await setBotonNuevoEstado(true);

                    let formData = new FormData();
                    formData.append('id_tarjeta', datosNuevoEstado.id_tarjeta);
                    formData.append('observaciones', datosNuevoEstado.observaciones);
                    formData.append('fecha_inicial', datosNuevoEstado.fecha_inicial);
                    formData.append('fecha_final', datosNuevoEstado.fecha_final);
                    formData.append('fecha_pago', datosNuevoEstado.fecha_pago);
                    formData.append('deuda_anterior', datosNuevoEstado.deuda_anterior);
                    formData.append('deuda', datosNuevoEstado.deuda);
                    formData.append('pago_minimo', datosNuevoEstado.pago_minimo);
                    formData.append('archivo', datosNuevoEstado.archivo);

                    await dispatch(agregarEstadoTarjetaCredito(formData));

                    await handleCloseNuevoEstado();

                    await setBotonNuevoEstado(false);
                }else{
                    dispatch(setAlert('First enter a valid minimum payment', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid statement period', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid balance', 'danger'));
        }
    }

    const handleCloseNuevoEstado = () => {
        setDatosNuevoEstado({
            ...datosNuevoEstado,
            observaciones: null,
            fecha_inicial: null,
            fecha_final: null,
            fecha_pago: null,
            deuda_anterior: null,
            deuda: null,
            pago_minimo: null, 
            archivo: null
        });

        setNuevoEstadooModal(false);
    }

    //Editar estado
    const handleSetEditarEstado = (id_estado) => {
        let estado = {
            id_tarjeta: id,
            id: id_estado
        };

        dispatch(detallesEstadoTarjetaCredito(estado));
    }    

    const handleOpenEditarEstado = () => {
        setEditarEstadoModal(true);
    }

    const onNumberChangeEditarEstado = (name, value) => {
        setDatosEditarEstado({
            ...datosEditarEstado, [name]: value
        });
    }

    const onChangeFileEditar = () => {
        setDatosEditarEstado({
            ...datosEditarEstado,
            archivo: editar_ref.current.files[0]
        });
    }

    const onChangeEditarEstado = ({ target }) => {
        setDatosEditarEstado({
            ...datosEditarEstado,
            [target.name]: target.value
        });
    }

    const handleSaveEditarEstado = async () => {
        if(datosEditarEstado.deuda !== null && datosEditarEstado.deuda !== '' && datosEditarEstado.deuda_anterior !== null && datosEditarEstado.deuda_anterior !== ''){
            if(datosEditarEstado.fecha_inicial !== null && datosEditarEstado.fecha_inicial !== '' && datosEditarEstado.fecha_final !== null && datosEditarEstado.fecha_final !== '' && datosEditarEstado.fecha_pago !== null && datosEditarEstado.fecha_pago !== ''){
                if(datosEditarEstado.pago_minimo !== null && datosEditarEstado.pago_minimo !== ''){
                    await setBotonEditarEstado(true);

                    let formData = new FormData();
                    formData.append('id', datosEditarEstado.id);
                    formData.append('id_tarjeta', datosEditarEstado.id_tarjeta);
                    formData.append('observaciones', datosEditarEstado.observaciones);
                    formData.append('fecha_inicial', datosEditarEstado.fecha_inicial);
                    formData.append('fecha_final', datosEditarEstado.fecha_final);
                    formData.append('fecha_pago', datosEditarEstado.fecha_pago);
                    formData.append('deuda_anterior', datosEditarEstado.deuda_anterior);
                    formData.append('deuda', datosEditarEstado.deuda);
                    formData.append('pago_minimo', datosEditarEstado.pago_minimo);
                    formData.append('archivo', datosEditarEstado.archivo);

                    await dispatch(editarEstadoTarjetaCredito(formData));

                    await handleCloseEditarEstado();

                    await setBotonEditarEstado(false);
                }else{
                    dispatch(setAlert('First enter a valid minimum payment', 'danger'));
                }
            }else{
                dispatch(setAlert('First enter a valid statement period', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid balance', 'danger'));
        }
    }

    const handleCloseEditarEstado = () => {
        setDatosEditarEstado({
            ...datosEditarEstado,
            id: null,
            observaciones: null,
            fecha_inicial: null,
            fecha_final: null,
            fecha_pago: null,
            deuda_anterior: null,
            deuda: null,
            pago_minimo: null, 
            archivo: null, 
            url: null
        });

        setEditarEstadoModal(false);
    }

    //Eliminar estado
    const handleSetEliminarEstado = (estado) => {
        setDatosEliminarEstado({
            ...datosEliminarEstado,
            id: estado._id,
            deuda: estado.deuda
        });

        handleOpenEliminarEstado();
    }    

    const handleOpenEliminarEstado = () => {
        setEliminarEstadoModal(true);
    }

    const handleSaveEliminarEstado = async () => {
        await setBotonEliminarEstado(true);
    
        await dispatch(eliminarEstadoTarjetaCredito(datosEliminarEstado));

        await handleCloseEliminarEstado();

        await setBotonEliminarEstado(false);
    }

    const handleCloseEliminarEstado = () => {
        setDatosEliminarEstado({
            ...datosEliminarEstado,
            id: null,
            deuda: null
        });

        setEliminarEstadoModal(false);
    }

    //Nuevo pago
    const handleOpenNuevoPago = (monto) => {
        if(dataTabla.estadosTarjeta.length > 0){
            if(datosTarjeta.balance > 0){
                setDatosNuevoPago({
                    ...datosNuevoPago, monto
                });

                setNuevoPagoModal(true);
            }else{
                dispatch(setAlert('The current balance is 0', 'danger'));
            }
        }else{
            dispatch(setAlert('First add a statement', 'danger'));
        }
    }

    const onNumberChangeNuevoPago = (name, value) => {
        setDatosNuevoPago({
            ...datosNuevoPago, [name]: value
        });
    }

    const onChangeNuevoPago = ({ target }) => {
        setDatosNuevoPago({
            ...datosNuevoPago,
            [target.name]: target.value
        });
    }

    const handleSaveNuevoPago = async () => {
        if(datosNuevoPago.monto !== null && datosNuevoPago.monto !== '' && datosNuevoPago.monto > 0){
            if(datosNuevoPago.fecha !== null && datosNuevoPago.fecha !== ''){
                await setBotonNuevoPago(true);

                await dispatch(agregarPagoTarjetaCredito(datosNuevoPago));

                await handleCloseNuevoPago();

                await setBotonNuevoPago(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseNuevoPago = () => {
        setDatosNuevoPago({
            ...datosNuevoPago,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setNuevoPagoModal(false);
    }

    //Editar pago
    const handleSetEditarPago = (id_pago) => {
        let pago = {
            id_tarjeta: id, 
            id_estado: datosTarjeta.id_estado, 
            id: id_pago
        };

        dispatch(detallesPagoTarjetaCredito(pago));
    }    

    const handleOpenEditarPago = () => {
        setEditarPagoModal(true);
    }

    const onNumberChangeEditarPago = (name, value) => {
        setDatosEditarPago({
            ...datosEditarPago, [name]: value
        });
    }

    const onChangeEditarPago = ({ target }) => {
        setDatosEditarPago({
            ...datosEditarPago,
            [target.name]: target.value
        });
    }

    const handleSaveEditarPago = async () => {
        if(datosEditarPago.monto !== null && datosEditarPago.monto !== '' && datosEditarPago.monto > 0){
            if(datosEditarPago.fecha !== null && datosEditarPago.fecha !== ''){
                await setBotonEditarPago(true);

                await dispatch(editarPagoTarjetaCredito(datosEditarPago));

                await handleCloseEditarPago();

                await setBotonEditarPago(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseEditarPago = () => {
        setDatosEditarPago({
            ...datosEditarPago,
            id: null,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setEditarPagoModal(false);
    }

    //Eliminar pago
    const handleSetEliminarPago = (pago) => {
        setDatosEliminarPago({
            ...datosEliminarPago,
            id: pago._id,
            monto: pago.monto, 
            pagos: dataTabla.pagosTarjeta.length 
        });

        handleOpenEliminarPago();
    }    

    const handleOpenEliminarPago = () => {
        setEliminarPagoModal(true);
    }

    const handleSaveEliminarPago = async () => {
        await setBotonEliminarPago(true);
    
        await dispatch(eliminarPagoTarjetaCredito(datosEliminarPago));

        await handleCloseEliminarPago();

        await setBotonEliminarPago(false);
    }

    const handleCloseEliminarPago = () => {
        setDatosEliminarPago({
            ...datosEliminarPago,
            id: null,
            monto: null, 
            pagos: 0
        });

        setEliminarPagoModal(false);
    }

    //Nuevo credito
    const handleOpenNuevoCredito = (monto) => {
        if(dataTabla.estadosTarjeta.length > 0){
            if(datosTarjeta.balance > 0){
                setDatosNuevoCredito({
                    ...datosNuevoCredito, monto
                });

                setNuevoCreditoModal(true);
            }else{
                dispatch(setAlert('The current balance is 0', 'danger'));
            }
        }else{
            dispatch(setAlert('First add a statement', 'danger'));
        }
    }

    const onNumberChangeNuevoCredito = (name, value) => {
        setDatosNuevoCredito({
            ...datosNuevoCredito, [name]: value
        });
    }

    const onChangeNuevoCredito = ({ target }) => {
        setDatosNuevoCredito({
            ...datosNuevoCredito,
            [target.name]: target.value
        });
    }

    const handleSaveNuevoCredito = async () => {
        if(datosNuevoCredito.monto !== null && datosNuevoCredito.monto !== '' && datosNuevoCredito.monto > 0){
            if(datosNuevoCredito.fecha !== null && datosNuevoCredito.fecha !== ''){
                await setBotonNuevoCredito(true);

                await dispatch(agregarCreditoTarjetaCredito(datosNuevoCredito));

                await handleCloseNuevoCredito();

                await setBotonNuevoCredito(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseNuevoCredito = () => {
        setDatosNuevoCredito({
            ...datosNuevoCredito,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setNuevoCreditoModal(false);
    }

    //Editar credito
    const handleSetEditarCredito = (id_credito) => {
        let credito = {
            id_tarjeta: id, 
            id_estado: datosTarjeta.id_estado, 
            id: id_credito
        };

        dispatch(detallesCreditoTarjetaCredito(credito));
    }    

    const handleOpenEditarCredito = () => {
        setEditarCreditoModal(true);
    }

    const onNumberChangeEditarCredito = (name, value) => {
        setDatosEditarCredito({
            ...datosEditarCredito, [name]: value
        });
    }

    const onChangeEditarCredito = ({ target }) => {
        setDatosEditarCredito({
            ...datosEditarCredito,
            [target.name]: target.value
        });
    }

    const handleSaveEditarCredito = async () => {
        if(datosEditarCredito.monto !== null && datosEditarCredito.monto !== '' && datosEditarCredito.monto > 0){
            if(datosEditarCredito.fecha !== null && datosEditarCredito.fecha !== ''){
                await setBotonEditarCredito(true);

                await dispatch(editarCreditoTarjetaCredito(datosEditarCredito));

                await handleCloseEditarCredito();

                await setBotonEditarCredito(false);
            }else{
                dispatch(setAlert('First enter a valid date', 'danger'));
            }
        }else{
            dispatch(setAlert('First enter a valid amount', 'danger'));
        }
    }

    const handleCloseEditarCredito = () => {
        setDatosEditarCredito({
            ...datosEditarCredito,
            id: null,
            observaciones: null,
            fecha: new Date(),
            monto: null
        });

        setEditarCreditoModal(false);
    }

    //Eliminar credito
    const handleSetEliminarCredito = (credito) => {
        setDatosEliminarCredito({
            ...datosEliminarCredito,
            id: credito._id,
            monto: credito.monto, 
            creditos: dataTabla.creditosTarjeta.length 
        });

        handleOpenEliminarCredito();
    }    

    const handleOpenEliminarCredito = () => {
        setEliminarCreditoModal(true);
    }

    const handleSaveEliminarCredito = async () => {
        await setBotonEliminarCredito(true);
    
        await dispatch(eliminarCreditoTarjetaCredito(datosEliminarCredito));

        await handleCloseEliminarCredito();

        await setBotonEliminarCredito(false);
    }

    const handleCloseEliminarCredito = () => {
        setDatosEliminarCredito({
            ...datosEliminarCredito,
            id: null,
            monto: null, 
            creditos: 0
        });

        setEliminarCreditoModal(false);
    }

    //Funciones
    const getSecurityData = (mes, ano, cvv) => {
        let security = 'Not defined';
        if(mes && ano){
            security = String(mes).padStart(2, '0') + '/' + ano.substr(2, 2);
            
            if(cvv){
                security += ' - ' + cvv;
            }
        }else{
            if(cvv){
                security = 'CVV: ' + cvv;
            }
        }

        return security;
    }

    const getDaysPeriod = (fecha_inicial, fecha_final) => {
        if(fecha_inicial && fecha_final){
            let fecha_inicial_corte = new Date(fecha_inicial);
            let fecha_final_corte = new Date(fecha_final);

            let diferencia_segundos = fecha_final_corte.getTime() - fecha_inicial_corte.getTime();
            let dias_periodo = Math.ceil(diferencia_segundos / (1000 * 3600 * 24));

            return '(' + dias_periodo + ' days)';
        }else{
            return 0;
        }
    }

    const creditCardIcon = (tarjeta) => {
        if(tarjeta?.nombre){
            let tarjeta_texto = 'cc ' + tarjeta?.nombre;
            tarjeta_texto = tarjeta_texto.toLowerCase();

            tarjeta_texto = tarjeta_texto.split(' ');
            tarjeta_texto = tarjeta_texto.join('-');

            return 'fab fa-' + tarjeta_texto;
        }else{
            return 'fas fa-credit-card';
        }
    }

    const handleViewFile = (archivo, url = null) => {
        if(archivo){
            const url_open = URL.createObjectURL(archivo);
            window.open(url_open);
        }else{
            if(url){
                window.open(url);
            }else{
                dispatch(setAlert('File not found', 'danger'));
            }
        }
    }

    const numberToDecimal = (number) => {
        number = (Math.round(number * 100) / 100).toFixed(2);
        const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: 2 });
        const finalFormated = '$' + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return finalFormated;
    }

    const handleIconoArchivo = async (id_estado) => {
        await dispatch(detallesFileEstadoTarjetaCredito(id, id_estado));
    }

    const handleOpenFile = () => {
        window.open(detalles_archivo.url);
    }

    // Tabla
    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };
    
    const columnsStatements = [
        {
            name: 'Creator',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Initial date',
            selector: 'fecha_inicial',
            sortable: true,
            cell: item => `${ item.fecha_inicial !== null ? new Date(item.fecha_inicial).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Close date',
            selector: 'fecha_final',
            sortable: true,
            cell: item => `${ item.fecha_final !== null ? new Date(item.fecha_final).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Payment due date',
            selector: 'fecha_pago',
            sortable: true,
            cell: item => `${ item.fecha_pago !== null ? new Date(item.fecha_pago).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Previous balance',
            selector: 'deuda_anterior',
            sortable: true,
            cell: item => `${item.deuda_anterior !== null ? numberToDecimal(item.deuda_anterior) : '-'}`,
        },  {
            name: 'New balance',
            selector: 'deuda',
            sortable: true,
            cell: item => `${item.deuda !== null ? numberToDecimal(item.deuda) : '-'}`,
        },  {
            name: 'Minimum payment',
            selector: 'pago_minimo',
            sortable: true,
            cell: item => `${item.pago_minimo !== null ? numberToDecimal(item.pago_minimo) : '-'}`,
        }, {
            name: 'Amount paid',
            selector: 'monto_pagado',
            sortable: true,
            cell: item => `${item.monto_pagado !== null ? numberToDecimal(item.monto_pagado) : '-'}`,
        }, {
            name: 'Amount credit',
            selector: 'monto_credito',
            sortable: true,
            cell: item => `${item.monto_credito !== null ? numberToDecimal(item.monto_credito) : '-'}`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones }`,
        }, {
            name: 'Paid',
            button: true,
            cell: item =>{
                if(item.pagado){
                    return (
                        <h6><Badge variant="success">Yes</Badge></h6>
                    );
                }else{
                    return (
                        <h6><Badge variant="danger">No</Badge></h6>
                    );
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                if(item.archivo){
                    return (
                        <button className="btn btn-link text-primary" onClick={() => handleIconoArchivo(item._id)}>
                            <i className="fas fa-file-invoice-dollar"></i>
                        </button>
                    );
                }else{
                    return (
                        <></>
                    );
                }
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-info" onClick={() => handleSetEditarEstado(item._id)}>
                        <i className="fa fa-edit" />
                    </button>
                );
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-danger" onClick={() => handleSetEliminarEstado(item)}>
                        <i className="fa fa-times" />
                    </button>
                );
            }
        }
    ];

    const columnsPayments = [
        {
            name: 'Creator',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones }`,
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-info" onClick={() => handleSetEditarPago(item._id)}>
                        <i className="fa fa-edit" />
                    </button>
                );
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-danger" onClick={() => handleSetEliminarPago(item)}>
                        <i className="fa fa-times" />
                    </button>
                );
            }
        }
    ];

    const columnsCredits = [
        {
            name: 'Creator',
            selector: 'usuario',
            sortable: true,
            cell: item => `${ item.usuario }`,
        }, {
            name: 'Amount',
            selector: 'monto',
            sortable: true,
            cell: item => `${item.monto !== null ? numberToDecimal(item.monto) : '-'}`,
        }, {
            name: 'Date',
            selector: 'fecha',
            sortable: true,
            cell: item => `${ item.fecha !== null ? new Date(item.fecha).toLocaleDateString('en-US') : 'Not defined' }`,
        }, {
            name: 'Observations',
            selector: 'observaciones',
            sortable: true,
            cell: item => `${ item.observaciones }`,
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-info" onClick={() => handleSetEditarCredito(item._id)}>
                        <i className="fa fa-edit" />
                    </button>
                );
            }
                
        }, {
            name: '',
            button: true,
            cell: item =>{
                return (
                    <button className="btn btn-link text-danger" onClick={() => handleSetEliminarCredito(item)}>
                        <i className="fa fa-times" />
                    </button>
                );
            }
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(detallesTarjetaCredito(null));
            dispatch(detallesEstadoTarjetaCredito(null));
            dispatch(detallesPagoTarjetaCredito(null));
            dispatch(detallesCreditoTarjetaCredito(null));
            dispatch(detallesFileEstadoTarjetaCredito(null, null));
        }
    }, []);

    useEffect(() => {
        dispatch(detallesTarjetaCredito(id));
    }, [id]);

    useEffect(() => {
        if(detalles_tarjeta){
            setDataTabla({
                ...dataTabla,
                estadosTarjeta: detalles_tarjeta.estados
            });

            setDatosNuevoEstado({
                ...datosNuevoEstado,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEditarEstado({
                ...datosEditarEstado,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEliminarEstado({
                ...datosEliminarEstado,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosNuevoPago({
                ...datosNuevoPago,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEditarPago({
                ...datosEditarPago,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEliminarPago({
                ...datosEliminarPago,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosNuevoCredito({
                ...datosNuevoCredito,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEditarCredito({
                ...datosEditarCredito,
                id_tarjeta: detalles_tarjeta._id
            });

            setDatosEliminarCredito({
                ...datosEliminarCredito,
                id_tarjeta: detalles_tarjeta._id
            });
        }
    }, [detalles_tarjeta, detalles_tarjeta?.estados]);

    useEffect(() => {
        if(detalles_estado_tarjeta){
            setDatosEditarEstado({
                ...datosEditarEstado,
                id: detalles_estado_tarjeta._id,
                observaciones: detalles_estado_tarjeta.observaciones,
                fecha_inicial: detalles_estado_tarjeta.fecha_inicial,
                fecha_final: detalles_estado_tarjeta.fecha_final,
                fecha_pago: detalles_estado_tarjeta.fecha_pago,
                deuda_anterior: detalles_estado_tarjeta.deuda_anterior, 
                deuda: detalles_estado_tarjeta.deuda, 
                pago_minimo: detalles_estado_tarjeta.pago_minimo, 
                url: detalles_estado_tarjeta.url
            });

            handleOpenEditarEstado();
        }
    }, [detalles_estado_tarjeta]);

    useEffect(() => {
        if(detalles_pago_tarjeta){
            setDatosEditarPago({
                ...datosEditarPago,
                id: detalles_pago_tarjeta._id,
                observaciones: detalles_pago_tarjeta.observaciones,
                fecha: detalles_pago_tarjeta.fecha,
                monto: detalles_pago_tarjeta.monto
            });

            handleOpenEditarPago();
        }
    }, [detalles_pago_tarjeta]);

    useEffect(() => {
        if(detalles_credito_tarjeta){
            setDatosEditarCredito({
                ...datosEditarCredito,
                id: detalles_credito_tarjeta._id,
                observaciones: detalles_credito_tarjeta.observaciones,
                fecha: detalles_credito_tarjeta.fecha,
                monto: detalles_credito_tarjeta.monto
            });

            handleOpenEditarCredito();
        }
    }, [detalles_credito_tarjeta]);

    useEffect(() => {
        if(detalles_tarjeta){
            let id_estado = null;
            let fecha_corte_inicial = null;
            let fecha_corte_final = null;
            let fecha_pago = null;
            let pago_anterior = 0;
            let pago_minimo = 0;
            let pago_total = 0;
            let pagos_totales = 0;
            let creditos_totales = 0;
            let balance = 0;

            if(dataTabla.estadosTarjeta.length > 0){
                for (var i = 0; i < dataTabla.estadosTarjeta.length; i++) {
                    if(i == 0){
                        id_estado = dataTabla.estadosTarjeta[0]._id;
                        fecha_corte_inicial = dataTabla.estadosTarjeta[0].fecha_inicial;
                        fecha_corte_final = dataTabla.estadosTarjeta[0].fecha_final;
                        fecha_pago = dataTabla.estadosTarjeta[0].fecha_pago;
                        pago_anterior = parseFloat(dataTabla.estadosTarjeta[0].deuda_anterior);
                        pago_minimo = parseFloat(dataTabla.estadosTarjeta[0].pago_minimo);
                        pago_total = parseFloat(dataTabla.estadosTarjeta[0].deuda);

                        for (var x = 0; x < dataTabla.estadosTarjeta[0].pagos.length; x++) {
                            pagos_totales += parseFloat(dataTabla.estadosTarjeta[0].pagos[x].monto);
                        }

                        for (var x = 0; x < dataTabla.estadosTarjeta[0].creditos.length; x++) {
                            creditos_totales += parseFloat(dataTabla.estadosTarjeta[0].creditos[x].monto);
                        }

                        setDataTabla({
                            ...dataTabla,
                            pagosTarjeta: dataTabla.estadosTarjeta[0].pagos, 
                            creditosTarjeta: dataTabla.estadosTarjeta[0].creditos
                        });

                        setDatosNuevoPago({
                            ...datosNuevoPago,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });

                        setDatosEditarPago({
                            ...datosNuevoPago,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });

                        setDatosEliminarPago({
                            ...datosNuevoPago,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });

                        setDatosNuevoCredito({
                            ...datosNuevoCredito,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });

                        setDatosEditarCredito({
                            ...datosNuevoCredito,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });

                        setDatosEliminarCredito({
                            ...datosNuevoCredito,
                            id_estado: dataTabla.estadosTarjeta[0]._id
                        });
                    }
                }
            }

            balance = pago_total - pagos_totales - creditos_totales;

            setDatosTarjeta({
                ...datosTarjeta,
                id_estado: id_estado, 
                fecha_promocion: detalles_tarjeta.fecha_promocion || null,
                fecha_corte_inicial: fecha_corte_inicial,
                fecha_corte_final: fecha_corte_final,
                fecha_pago: fecha_pago,
                pago_anterior: pago_anterior,
                pago_minimo: pago_minimo,
                pago_total: pago_total,
                pagos_totales: pagos_totales,
                creditos_totales: creditos_totales,
                balance: balance, 
                status: detalles_tarjeta.status, 
                notas: detalles_tarjeta.notas || null
            });
        }
    }, [detalles_tarjeta, dataTabla.estadosTarjeta]);

    useEffect(() => {        
        if(detalles_archivo && detalles_archivo?.url){
            handleOpenFile();
        }
    }, [detalles_archivo]);

    return (
        <Fragment>

            {/* Modal de eliminacion de tarjeta */}
            {/* <Modal show={deleteModal} onHide={() => {
                handleCloseDeleteTarjeta();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Credit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the card ending <b>{datosDeleteTarjeta?.digitos || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseDeleteTarjeta()}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={deleteTarjeta}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal> */}

            {/* Modal de nuevo estado */}
            <Modal show={nuevoEstadoModal} size="lg" onHide={() => {
                handleCloseNuevoEstado();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New statement ({ detalles_tarjeta?.digitos })</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Previous closing date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_inicial"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosNuevoEstado.fecha_inicial !== null ? new Date(datosNuevoEstado.fecha_inicial) : '' }
                                    onChange={ date => setDatosNuevoEstado({ ...datosNuevoEstado, fecha_inicial: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Closing date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_final"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosNuevoEstado.fecha_final !== null ? new Date(datosNuevoEstado.fecha_final) : '' }
                                    onChange={ date => setDatosNuevoEstado({ ...datosNuevoEstado, fecha_final: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Payment due date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_pago"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosNuevoEstado.fecha_pago !== null ? new Date(datosNuevoEstado.fecha_pago) : '' }
                                    onChange={ date => setDatosNuevoEstado({ ...datosNuevoEstado, fecha_pago: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Previous balance:</label>
                            <CurrencyInput
                                name="deuda_anterior"
                                className="form-control text-center"
                                value={datosNuevoEstado.deuda_anterior || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">New balance:</label>
                            <CurrencyInput
                                name="deuda"
                                className="form-control text-center"
                                value={datosNuevoEstado.deuda || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Minimum payment due:</label>
                            <CurrencyInput
                                name="pago_minimo"
                                className="form-control text-center"
                                value={datosNuevoEstado.pago_minimo || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={nuevo_ref} onChange={onChangeFileNuevo} aria-describedby="viewfile_file"/>
                                    <label className="custom-file-label">{datosNuevoEstado.archivo ? 'File Selected' : 'Choose file'}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosNuevoEstado.archivo)}><i className="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosNuevoEstado.observaciones || ''} name="observaciones" onChange={e => onChangeNuevoEstado(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevoEstado()} disabled={botonNuevoEstado}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveNuevoEstado} disabled={botonNuevoEstado}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar estado */}
            <Modal show={editarEstadoModal} size="lg" onHide={() => {
                handleCloseEditarEstado();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit statement ({ detalles_tarjeta?.digitos })</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Previous closing date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_inicial"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosEditarEstado.fecha_inicial !== null ? new Date(datosEditarEstado.fecha_inicial) : '' }
                                    onChange={ date => setDatosEditarEstado({ ...datosEditarEstado, fecha_inicial: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Closing date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_final"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosEditarEstado.fecha_final !== null ? new Date(datosEditarEstado.fecha_final) : '' }
                                    onChange={ date => setDatosEditarEstado({ ...datosEditarEstado, fecha_final: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Payment due date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha_pago"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    //maxDate={ new Date() } 
                                    selected={ datosEditarEstado.fecha_pago !== null ? new Date(datosEditarEstado.fecha_pago) : '' }
                                    onChange={ date => setDatosEditarEstado({ ...datosEditarEstado, fecha_pago: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Previous balance:</label>
                            <CurrencyInput
                                name="deuda_anterior"
                                className="form-control text-center"
                                value={datosEditarEstado.deuda_anterior || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">New balance:</label>
                            <CurrencyInput
                                name="deuda"
                                className="form-control text-center"
                                value={datosEditarEstado.deuda || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label className="font-weight-bold">Minimum payment due:</label>
                            <CurrencyInput
                                name="pago_minimo"
                                className="form-control text-center"
                                value={datosEditarEstado.pago_minimo || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarEstado(name, value)}
                            />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">File:</label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input form-control" name="file" id="newFile" ref={editar_ref} onChange={onChangeFileEditar} aria-describedby="viewfile_file"/>
                                    <label className="custom-file-label">{datosEditarEstado.archivo ? 'File Selected' : (datosEditarEstado.url ? 'File Saved' : 'Choose file')}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" id="viewfile_file" onClick={() => handleViewFile(datosEditarEstado.archivo, datosEditarEstado.url)}><i className="fas fa-eye"></i> View File</button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosEditarEstado.observaciones || ''} name="observaciones" onChange={e => onChangeEditarEstado(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEditarEstado()} disabled={botonEditarEstado}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveEditarEstado} disabled={botonEditarEstado}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminacion de estado */}
            <Modal show={eliminarEstadoModal} onHide={() => {
                handleCloseEliminarEstado();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete statement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the statement of <b>{numberToDecimal(datosEliminarEstado?.deuda) || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEliminarEstado()} disabled={botonEliminarEstado}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={handleSaveEliminarEstado} disabled={botonEliminarEstado}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo pago */}
            <Modal show={nuevoPagoModal} onHide={() => {
                handleCloseNuevoPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosNuevoPago.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoPago(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_pago, 1) } 
                                    selected={ datosNuevoPago.fecha !== null ? new Date(datosNuevoPago.fecha) : null }
                                    onChange={ date => setDatosNuevoPago({ ...datosNuevoPago, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosNuevoPago.observaciones || ''} name="observaciones" onChange={e => onChangeNuevoPago(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevoPago()} disabled={botonNuevoPago}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveNuevoPago} disabled={botonNuevoPago}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar pago */}
            <Modal show={editarPagoModal} onHide={() => {
                handleCloseEditarPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosEditarPago.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarPago(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    selected={ datosEditarPago.fecha !== null ? new Date(datosEditarPago.fecha) : null }
                                    onChange={ date => setDatosEditarPago({ ...datosEditarPago, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosEditarPago.observaciones || ''} name="observaciones" onChange={e => onChangeEditarPago(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEditarPago()} disabled={botonEditarPago}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveEditarPago} disabled={botonEditarPago}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminacion de pago */}
            <Modal show={eliminarPagoModal} onHide={() => {
                handleCloseEliminarPago();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the payment of <b>{numberToDecimal(datosEliminarPago?.monto) || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEliminarPago()} disabled={botonEliminarPago}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={handleSaveEliminarPago} disabled={botonEliminarPago}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de nuevo credito */}
            <Modal show={nuevoCreditoModal} onHide={() => {
                handleCloseNuevoCredito();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New credit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosNuevoCredito.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeNuevoCredito(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_pago, 1) } 
                                    selected={ datosNuevoCredito.fecha !== null ? new Date(datosNuevoCredito.fecha) : null }
                                    onChange={ date => setDatosNuevoCredito({ ...datosNuevoCredito, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosNuevoCredito.observaciones || ''} name="observaciones" onChange={e => onChangeNuevoCredito(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseNuevoCredito()} disabled={botonNuevoCredito}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveNuevoCredito} disabled={botonNuevoCredito}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar credito */}
            <Modal show={editarCreditoModal} onHide={() => {
                handleCloseEditarCredito();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit credit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Amount:</label>
                            <CurrencyInput
                                name="monto"
                                className="form-control text-center"
                                value={datosEditarCredito.monto || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChangeEditarCredito(name, value)}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Date:</label>
                            <div className="contenedor_datepicker">
                                <DatePicker
                                    name="fecha"
                                    className="form-control text-center"
                                    dateFormat="MM/dd/yyyy"
                                    autoComplete="off"
                                    //minDate={ getPreviousDate(detalles_tarjeta?.fecha_corte, 1) } 
                                    selected={ datosEditarCredito.fecha !== null ? new Date(datosEditarCredito.fecha) : null }
                                    onChange={ date => setDatosEditarCredito({ ...datosEditarCredito, fecha: date }) } 
                                />
                            </div>
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Observations:</label>
                            <textarea className="form-control" value={datosEditarCredito.observaciones || ''} name="observaciones" onChange={e => onChangeEditarCredito(e)}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEditarCredito()} disabled={botonEditarCredito}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSaveEditarCredito} disabled={botonEditarCredito}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminacion de credito */}
            <Modal show={eliminarCreditoModal} onHide={() => {
                handleCloseEliminarCredito();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete credit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to delete the credit of <b>{numberToDecimal(datosEliminarCredito?.monto) || ''}</b>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger mr-3" onClick={() => handleCloseEliminarCredito()} disabled={botonEliminarCredito}><i className="fas fa-times fa-sm"></i> No</button>
                    <button className="btn btn-success" onClick={handleSaveEliminarCredito} disabled={botonEliminarCredito}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="row mx-0">
                <div className="col-md-12 mb-3 mt-5 row">
                    <div className="col-md-6 text-left">
                        <h3>Credit card details ({ detalles_tarjeta?.nombre + ' - ' + detalles_tarjeta?.digitos })</h3>
                    </div>
                    <div className="col-md-6 text-right">
                        <Link to="/accounting/credit_cards" className="btn btn-secondary text-right">Go back</Link>
                        <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevoEstado()}><i className="fas fa-file-invoice-dollar"></i> Add statement</button>
                        <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevoPago(null)}><i className="fas fa-dollar-sign fa-sm"></i> Add a payment</button>
                        <button type="button" className="btn btn-success float-right mb-3 ml-3" onClick={() => handleOpenNuevoCredito(null)}><i className="fas fa-dollar-sign fa-sm"></i> Add a credit</button>
                        <button type="button" className={'btn float-right mb-3 ml-3 ' + (datosTarjeta.status ? 'btn-danger' : ' btn-success')} onClick={() => handleStatusTarjeta(detalles_tarjeta)}><i className="fas fa-power-off"></i> { datosTarjeta.status ? 'Deactivate' : 'Activate'}</button>
                    </div>
                </div>

                <div className="col-md-12 mb-3 mt-4 row">
                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Type</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}><i className={`${ creditCardIcon(detalles_tarjeta?.tipo) }`} /> {detalles_tarjeta?.tipo?.nombre || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Bank</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{detalles_tarjeta?.banco?.nombre || 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Security</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{getSecurityData(detalles_tarjeta?.mes, detalles_tarjeta?.ano, detalles_tarjeta?.cvv)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Credit limit / interest</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{ numberToDecimal(detalles_tarjeta?.limite_credito) } / { detalles_tarjeta?.tasa_credito ? detalles_tarjeta?.tasa_credito + ' %' : 'Not defined' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Cash limit / interest</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{ numberToDecimal(detalles_tarjeta?.limite_efectivo) } / { detalles_tarjeta?.tasa_efectivo ? detalles_tarjeta?.tasa_efectivo + ' %' : 'Not defined' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Promotion date</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{ datosTarjeta.fecha_promocion ? new Date(datosTarjeta.fecha_promocion).toLocaleDateString('en-US') : 'Not defined' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Statement period { datosTarjeta.fecha_corte_inicial && datosTarjeta.fecha_corte_final ? getDaysPeriod(datosTarjeta.fecha_corte_inicial, datosTarjeta.fecha_corte_final) : '' }</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{ datosTarjeta.fecha_corte_inicial && datosTarjeta.fecha_corte_final ? (new Date(datosTarjeta.fecha_corte_inicial).toLocaleDateString('en-US') + ' - ' + new Date(datosTarjeta.fecha_corte_final).toLocaleDateString('en-US')) : 'Not defined' }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mb-3 mt-4 row">                    
                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Due payment date</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{datosTarjeta.fecha_pago ? new Date(datosTarjeta.fecha_pago).toLocaleDateString('en-US') : 'Not defined'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Previous balance</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{numberToDecimal(datosTarjeta.pago_anterior)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>Minimum payment 
                                        {
                                            datosTarjeta.pago_total !== datosTarjeta.balance ?
                                                <></>
                                            :
                                                <button type="button" className="btn btn-success float-right" onClick={() => handleOpenNuevoPago(datosTarjeta.pago_minimo)}><i className="fas fa-dollar-sign fa-sm"></i></button>
                                        }
                                    </h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{numberToDecimal(datosTarjeta.pago_minimo)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={styles.titulo}>New balance 
                                        {
                                            datosTarjeta.pago_total !== datosTarjeta.balance ?
                                                <></>
                                            :
                                                <button type="button" className="btn btn-success float-right" onClick={() => handleOpenNuevoPago(datosTarjeta.pago_total)}><i className="fas fa-dollar-sign fa-sm"></i></button>
                                        }
                                    </h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={styles.infoText}>{numberToDecimal(datosTarjeta.pago_total)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md">
                        <div className={"card " + ((datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) < datosTarjeta.pago_minimo ? 'bg-danger' : ((datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) < datosTarjeta.pago_total ? 'bg-warning' : 'bg-success'))}>
                            <div className="card-body">
                                <div className="card-title mb-3">
                                    <h5 style={(datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) < datosTarjeta.pago_minimo || (datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) >= datosTarjeta.pago_total ? styles.titulo_restaltado : null}>Carry balance</h5>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <p style={(datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) < datosTarjeta.pago_minimo || (datosTarjeta.pagos_totales + datosTarjeta.creditos_totales) >= datosTarjeta.pago_total ? {...styles.infoText, ...styles.titulo_restaltado} : {...styles.infoText}}>{numberToDecimal(datosTarjeta.balance)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    datosTarjeta.notas ?
                        <div className="col-md-12 mb-3 mt-4 row">                    
                            <div className="col-md-12">
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className="card-title mb-3">
                                            <h5 style={styles.titulo}>Notes</h5>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <p style={styles.infoText}>{datosTarjeta.notas}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                }

                <div className="table-responsive mt-5 pl-2">
                    <h6 className="my-2 ml-3">Statements</h6>
                    <DataTable
                        columns={columnsStatements}
                        data={dataTabla.estadosTarjeta || []}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>

                <div className="table-responsive mt-5 pl-2">
                    <h6 className="my-2 ml-3">Payments of current statement</h6>
                    <DataTable
                        columns={columnsPayments}
                        data={dataTabla.pagosTarjeta || []}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>

                <div className="table-responsive mt-5 pl-2">
                    <h6 className="my-2 ml-3">Credits of current statement</h6>
                    <DataTable
                        columns={columnsCredits}
                        data={dataTabla.creditosTarjeta || []}
                        noHeader={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[5, 10, 20, 50]}
                        paginationComponentOptions={paginationOptions}
                        highlightOnHover={true}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default EstadosTarjetaCredito;