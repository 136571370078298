import { BUSCAR_SELLERS, AGREGAR_SELLER, EDITAR_SELLER, DETALLES_SELLER, ELIMINAR_SELLER } from '../actions/types';

const initialState = {
    sellers: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case BUSCAR_SELLERS:
            return {
                ...state,
                sellers: payload
            };
        case AGREGAR_SELLER:
            return {
                ...state,
                sellers: [
                    ...state.sellers, payload
                ]
            };
        case EDITAR_SELLER:
            return {
                ...state,
                sellers: state.sellers.map(
                    seller => seller._id === payload._id ? payload : seller
                )
            };
        case DETALLES_SELLER:
            return {
                ...state,
                detalles: payload
            };
        case ELIMINAR_SELLER:
            return {
                ...state,
                sellers: state.sellers.filter( seller => seller._id !== payload.id )
            };        
        default:
            return state;
    }
}