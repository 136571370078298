import { BUSCAR_FINANCING_TYPES, AGREGAR_FINANCING_TYPE, EDITAR_FINANCING_TYPE, DETALLES_FINANCING_TYPE, ELIMINAR_FINANCING_TYPE } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarFinancingTypes = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/financing_types`, config);
        dispatch({
            type: BUSCAR_FINANCING_TYPES,
            payload: res.data.financing_types
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarFinancingType = (financing_type) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(financing_type);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/financing_types/nuevo`, body, config);
        dispatch({
            type: AGREGAR_FINANCING_TYPE,
            payload: res.data.financing_type
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarFinancingType = (financing_type) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(financing_type);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/financing_types/editar`, body, config);
        dispatch({
            type: EDITAR_FINANCING_TYPE,
            payload: res.data.financing_type
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesFinancingType = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/financing_types/detalles`, body, config);

        dispatch({
            type: DETALLES_FINANCING_TYPE,
            payload: res.data.financing_type
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarFinancingType = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/financing_types/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_FINANCING_TYPE,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}