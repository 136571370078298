import { BUSCAR_SECCIONES_TODOLIST, AGREGAR_SECCION_TODOLIST, EDITAR_SECCION_TODOLIST, ELIMINAR_SECCION_TODOLIST } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarSecciones = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/todolist_sections`, config);
        
        dispatch({
            type: BUSCAR_SECCIONES_TODOLIST,
            payload: res.data.secciones
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarSeccion = (seccion) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seccion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist_sections/nueva`, body, config);
        
        dispatch({
            type: AGREGAR_SECCION_TODOLIST,
            payload: res.data.seccion
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarSeccion = (seccion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(seccion);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist_sections/editar`, body, config);
        
        dispatch({
            type: EDITAR_SECCION_TODOLIST,
            payload: res.data.seccion
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarSeccion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ id });
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist_sections/eliminar`, body, config);
        
        dispatch({
            type: ELIMINAR_SECCION_TODOLIST,
            payload:{
                id
            }
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}