import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { setAlert } from '../../actions/alert';
import { buscarCategorias, agregarCategoria, editarCategoria, eliminarCategoria } from '../../actions/categorias_inspeccion';

const InspectionCategories = ({ history }) => {

    const categorias_inspeccion = useSelector(state => state.categorias_inspeccion);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();

    const [newCategorieData, setNewCategorieData] = useState({
        nombre: null,
        descripcion: null
    });

    const [editCategorieData, setEditCategorieData] = useState({
        id: null,
        nombre: null,
        descripcion: null
    });

    const [deleteCategorieData, setDeleteCategorieData] = useState({
        id: null,
        nombre: null
    });

    const [newCategorieModal, setNewCategorieModal] = useState(false);
    const [editCategorieModal, setEditCategorieModal] = useState(false);
    const [deleteCategorieModal, setDeleteCategorieModal] = useState(false);

    //Nuevo
    const handleAgregarCategoria = id => {
        setNewCategorieModal(true);
    }

    const handleInputsAgregar = ({ target }) => {
        setNewCategorieData({
            ...newCategorieData,
            [target.name]: target.value
        });
    }

    const handleCloseAgregarCategoria = () => {
        setNewCategorieData({
            ...newCategorieData,
            nombre: null,
            descripcion: null
        });

        setNewCategorieModal(false);
    }

    const handleSubmitAgregar = async (e) => {
        e.preventDefault();

        try {
            if(newCategorieData.nombre !== '' && newCategorieData.nombre !== null){
                if(newCategorieData.descripcion !== '' && newCategorieData.descripcion !== null){
                    await dispatch(agregarCategoria(newCategorieData));

                    setNewCategorieModal(false);

                    setNewCategorieData({
                        ...newCategorieData,
                        nombre: null,
                        descripcion: null
                    });
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('Category name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Editar
    const handleEditarCategoria = categoria => {
        setEditCategorieModal(true);

        setEditCategorieData({
            ...editCategorieData,
            id: categoria._id,
            nombre: categoria.nombre,
            descripcion: categoria.descripcion
        });
    }

    const handleInputsEditar = ({ target }) => {
        setEditCategorieData({
            ...editCategorieData,
            [target.name]: target.value
        });
    }

    const handleCloseEditarCategoria = () => {
        setEditCategorieData({
            ...editCategorieData,
            id: null,
            nombre: null,
            descripcion: null
        });

        setEditCategorieModal(false);
    }

    const handleSubmitEditar = async (e) => {
        e.preventDefault();

        try {
            if(editCategorieData.nombre !== '' && editCategorieData.nombre !== null){
                if(editCategorieData.descripcion !== '' && editCategorieData.descripcion !== null){
                    await dispatch(editarCategoria(editCategorieData));

                    setEditCategorieModal(false);

                    setEditCategorieData({
                        ...editCategorieData,
                        id: null,
                        nombre: null,
                        descripcion: null
                    });
                }else{
                    dispatch(setAlert('First enter a valid description', 'danger'));
                }
            }else{
                dispatch(setAlert('Category name missing', 'danger'));
            }
        } catch (error) {
            return false;
        }
    }

    //Eliminar
    const handleEliminarCategoria = categoria => {
        setDeleteCategorieModal(true);

        setDeleteCategorieData({
            ...deleteCategorieData,
            id: categoria._id,
            nombre: categoria.nombre
        });
    }

    const handleCloseEliminarCategoria = () => {
        setDeleteCategorieData({
            ...deleteCategorieData,
            id: null,
            nombre: null
        });

        setDeleteCategorieModal(false);
    }

    const handleSubmitEliminar = async (e) => {
        e.preventDefault();

        try {
            await dispatch(eliminarCategoria(deleteCategorieData.id));

            setDeleteCategorieModal(false);

            setDeleteCategorieData({
                ...deleteCategorieData,
                id: null,
                nombre: null
            });
        } catch (error) {
            return false;
        }
    }

    const tableRow = (items) => {
        if(items){
            if(items.length > 0){
                return (
                    <tbody>
                        {
                            items.map((item) => {
                                return (
                                    <Fragment key={item._id}>
                                        <tr>
                                            <td> {item.nombre || '-'} </td>
                                            <td> {item.descripcion || '-'} </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.edit_categorie_inspection ? 
                                                    null 
                                                    :
                                                    <button className="btn btn-link text-danger" onClick={() => handleEditarCategoria(item)}>Edit</button>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    !permisos_configuration?.delete_categorie_inspection ?
                                                    null
                                                    :
                                                    <button className="btn btn-link text-danger" onClick={() => handleEliminarCategoria(item)}>Delete</button>
                                                }
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                );
            }else{
                return (
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center">No categories registered.</td>                                        
                        </tr>
                    </tbody>
                );
            }
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">No categories registered.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    useEffect(() => {
        dispatch(buscarCategorias());
    }, []);

    return (
        <Fragment>
            {/* Modal de nuevo categoria */}
            <Modal backdrop="static" show={newCategorieModal} onHide={() => {
                setNewCategorieModal(false);
                setNewCategorieData({
                    ...newCategorieData,
                    nombre: null,
                    descripcion: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>New inspection categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input name="nombre" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newCategorieData.nombre || ''} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea name="descripcion" className="form-control" type="text" onChange={e => handleInputsAgregar(e)} value={newCategorieData.descripcion || ''}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseAgregarCategoria()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitAgregar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de editar categoria */}
            <Modal backdrop="static" show={editCategorieModal} onHide={() => {
                setEditCategorieModal(false);
                setEditCategorieData({
                    ...editCategorieData,
                    id: null,
                    nombre: null,
                    descripcion: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit inspection categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Name:</label>
                            <input name="nombre" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editCategorieData.nombre || ''} />
                        </div>

                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Description:</label>
                            <textarea name="descripcion" className="form-control" type="text" onChange={e => handleInputsEditar(e)} value={editCategorieData.descripcion || ''}></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEditarCategoria()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEditar}><i className="fas fa-check fa-sm"></i> Save</button>
                </Modal.Footer>
            </Modal>

            {/* Modal de eliminar categoria */}
            <Modal backdrop="static" show={deleteCategorieModal} onHide={() => {
                setDeleteCategorieModal(false);
                setDeleteCategorieData({
                    ...deleteCategorieData,
                    id: null,
                    nombre: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete inspection categorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row"> 
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Do you want to delete the {deleteCategorieData.nombre || ''} categorie?</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={() => handleCloseEliminarCategoria()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    <button className="btn btn-success" onClick={handleSubmitEliminar}><i className="fas fa-check fa-sm"></i> Yes</button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <div className="row d-flex align-items-center mb-3 mt-5">
                        <div className="col-md-8">
                            <h3>Categories of inspection</h3>
                            <p className="text-muted"> {categorias_inspeccion?.categorias?.length || 0} registered categories</p>
                        </div>
                        <div className="col-md-4 text-right">
                            {
                                !permisos_configuration?.add_categorie_inspection ? 
                                null 
                                :
                                <button type="button" className="btn btn-success text-right" onClick={() => handleAgregarCategoria()}><i className="fas fa-plus fa-sm"></i> Add categorie</button>
                            }
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                tableRow(categorias_inspeccion?.categorias)
                            }
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    );    
}

export default InspectionCategories;