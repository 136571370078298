import { 
    RENEGOCIAR_OFFER, 
    RENEGOCIAR_IN_ESCROW, 
    GUARDAR_CAMBIOS_FLIPPING, 
    DESPLEGAR_GUARDADO_FLIPPING, 
    BUYSALE_PAGE,
    PAGINA_REDIRECCIONAR_FLIPPING,
    REDIRECCIONAR_FLIPPING,
    ID_REDIRECCIONAR_FLIPPING,
    AGREGAR_COUNTER_LISTING_PROYECTO
} from './types';
import { setAlert } from './alert';
import axios from 'axios';

/* Generales */

//Función para detectar que nos encontramos en buySale
export const buySalePage = (status) => async dispatch => {
    dispatch({
        type: BUYSALE_PAGE,
        payload: status
    })
}

export const guardarCambios = (status) => async dispatch => {
    dispatch({
        type: GUARDAR_CAMBIOS_FLIPPING,
        payload: status
    });
}

export const desplegarModalCambios = (status) => async dispatch => {
    dispatch({
        type: DESPLEGAR_GUARDADO_FLIPPING,
        payload: status
    });
}

export const paginaRedireccion = (page) => async dispatch => {
    dispatch({
        type:PAGINA_REDIRECCIONAR_FLIPPING,
        payload: page
    })
}

export const idRedireccion = (id) => async dispatch => {
    dispatch({
        type: ID_REDIRECCIONAR_FLIPPING,
        payload: id
    })
}

export const redireccionar = (page) => async dispatch => {
    dispatch({
        type: REDIRECCIONAR_FLIPPING,
        payload: page
    });
}

/* Proceso */
export const editarOffer = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/offer`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const renegotiateOffer = (proyecto) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/offer-renegotiate`, proyecto, config);
        dispatch(setAlert(res.data.msg, 'success'));

        let purchase_price = proyecto.get('purchase_price');
        
        dispatch({
            type: RENEGOCIAR_OFFER,
            payload: {
                purchase_price: purchase_price,
                renegotiation: res.data.renegotiation,
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const continuarOffer = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/offer-continue`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/in-escrow`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const renegotiateInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/in-escrow-renegotiate`, proyecto, config);
        dispatch(setAlert(res.data.msg, 'success'));

        let purchase_price = proyecto.get('purchase_price');
        let due_dilligence_end = proyecto.get('due_dilligence_end');
        let escrow_deposit_amount = proyecto.get('escrow_deposit_amount');
        
        dispatch({
            type: RENEGOCIAR_IN_ESCROW,
            payload: {
                purchase_price: purchase_price,
                due_dilligence_end: due_dilligence_end,
                escrow_deposit_amount: escrow_deposit_amount
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const reactivarInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/in-escrow-reactivate`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const continuarInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/in-escrow-continue`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const closeInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/in-escrow-close`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarWaitingForRepairs = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/waiting-for-repairs`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarRehabOnProgress = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/rehab-in-progress`, body, config);

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const continuarRehabOnProgress = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/rehab-in-progress-continue`, body, config);

        dispatch({
            type: GUARDAR_CAMBIOS_FLIPPING,
            payload: false
        });

        dispatch({
            type: DESPLEGAR_GUARDADO_FLIPPING,
            payload: false
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarHolding = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/holding`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarListing = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/listing`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const continuarListing = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/listing-continue`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarCounterListing = (counter) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/listing-agregar-counter`, counter, config);

        dispatch(setAlert(res.data.msg, 'success'));

        dispatch({
            type: AGREGAR_COUNTER_LISTING_PROYECTO,
            payload: {
                counter: res.data.counter
            }
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarSellsInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/sells-in-escrow`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const renegotiateSellsInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/sells-in-escrow-renegotiate`, proyecto, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const continuarSellsInEscrow = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/sells-in-escrow-continue`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarCompleted = (proyecto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(proyecto);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/flipping/completed`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}