import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editarTermite, detallesTermite } from '../../actions/termites';

const EditarTermite = ({ history }) => {

    const dispatch = useDispatch();

    const { id } = useParams();

    const termites = useSelector(state => state.termites);

    const [termiteData, setTermiteData] = useState({
        id: '',
        company: '',
        address: ''
    });

    const [spinner, setSpinner] = useState(false);

    const { company, address } = termiteData;

    const onChange = (e) => {
        setTermiteData({
            ...termiteData, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinner(true);
        setTimeout(async() => {
            await dispatch(editarTermite(termiteData));
            setSpinner(false);
            history.push('/configuration/termites');
        }, 0);
    }

    useEffect(() => {
        dispatch(detallesTermite(id));
    }, []);

    useEffect(() => {

        setTermiteData({
            ...termiteData,
            id: termites.detalles?._id,
            company: termites.detalles?.compania,
            address: termites.detalles?.direccion
        });
    }, [termites]);


    return (
        <div className="row pb-5">
            <form className="col-md-10 offset-md-1">
                <h3 className="mb-3 mt-5">Edit termite</h3>
                <div className="form-group">
                    <label className="font-weight-bold">Compamy</label>
                    <input type="text" className="form-control" name="company" onChange={e => onChange(e)} value={company}></input>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Address</label>
                    <input type="text" className="form-control" name="address" onChange={e => onChange(e)} value={address}></input>
                </div>
                {spinner ? 
                    <div className="col-12 mt-3 text-center d-flex justify-content-center">
                        <div className="sk-chase my-5">
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                        </div>
                    </div>
                :
                    null
                }
                <div className="mt-3">
                    <button type="submit" className="btn btn-success float-right ml-3" onClick={handleSubmit} disabled={spinner ? true : false}><i className="fas fa-check fa-sm"></i> Save</button>
                    <button type="button" className="btn btn-danger float-right" onClick={() => {history.push('/configuration/termites')}} disabled={spinner ? true : false}><i className="fas fa-times fa-sm"></i> Cancel</button>
                </div>
            </form>
        </div>
    )
}


export default EditarTermite;