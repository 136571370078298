import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ReactChipInput from "react-chip-input";
import Lightbox from 'react-image-lightbox';
import DatePicker from 'react-datepicker';
import 'react-image-lightbox/style.css';

import { setAlert } from '../../actions/alert';
import { buscarBrokers } from '../../actions/brokers';
import { buscarEscrows } from '../../actions/escrows';
import { buscarLenders } from '../../actions/lenders';
import { buscarTitles } from '../../actions/titles';
import { buscarTermites } from '../../actions/termites';
import { buscarSeptics } from '../../actions/septics';
import { buscarUsuarios } from '../../actions/usuarios';
import { buscarEmpleados } from '../../actions/empleados';
import { elegirFiltros } from '../../actions/todolist';

import {
    desplegarModalCambios,
    buySalePage,
    paginaRedireccion,
    redireccionar,
    idRedireccion
} from '../../actions/flipping';

import {
    detallesProyecto,
    editProject,
    externoEliminar, 
    filesFoldersProyecto,
    externoArchivoProyecto,
    notesProyecto,
    externoNota, 
    inspectionsProyecto,
    masterlistProyecto,
    seleccionarSeccionMasterList,
    filesProyectoSeccion,
    verReportProyecto,
    compartirReportProyecto,
    enviarReportProyecto,
    limpiarLinkReportProyecto
} from '../../actions/proyectos';

/* Flippging */
import OfferFlipping from '../../components/buySale/flipping/OfferFlipping';
import BuyingEscrowFlipping from '../../components/buySale/flipping/BuyingEscrowFlipping';
import WaitingForRepairsFlipping from '../../components/buySale/flipping/WaitingForRepairsFlipping';
import RehabOnProgressFlipping from '../../components/buySale/flipping/RehabOnProgressFlipping';
import HoldingFlipping from '../../components/buySale/flipping/HoldingFlipping';
import ListingFlipping from '../../components/buySale/flipping/ListingFlipping';
import SellsInEscrowFlipping from '../../components/buySale/flipping/SellsInEscrowFlipping';
import SoldPropertyFlipping from '../../components/buySale/flipping/SoldPropertyFlipping';

/* New Development */
import OfferNewDevelopment from '../../components/buySale/new_development/OfferNewDevelopment';
import BuyingEscrowNewDevelopment from '../../components/buySale/new_development/BuyingEscrowNewDevelopment';
import HoldingNewDevelopment from '../../components/buySale/new_development/HoldingNewDevelopment';
import NewDevelopmentNewDevelopment from '../../components/buySale/new_development/NewDevelopmentNewDevelopment';
import EntitlementsNewDevelopment from '../../components/buySale/new_development/EntitlementsNewDevelopment';
import ConstructionNewDevelopment from '../../components/buySale/new_development/ConstructionNewDevelopment';
import ListingNewDevelopment from '../../components/buySale/new_development/ListingNewDevelopment';
import SellsInEscrowNewDevelopment from '../../components/buySale/new_development/SellsInEscrowNewDevelopment';
import SoldPropertyNewDevelopment from '../../components/buySale/new_development/SoldPropertyNewDevelopment';

/* Assigned */
import OfferAssigned from '../../components/buySale/assigned/OfferAssigned';
import BuyingEscrowAssigned from '../../components/buySale/assigned/BuyingEscrowAssigned';
import ListingAssigned from '../../components/buySale/assigned/ListingAssigned';
import SellsInEscrowAssigned from '../../components/buySale/assigned/SellsInEscrowAssigned';
import SoldPropertyAssigned from '../../components/buySale/assigned/SoldPropertyAssigned';

const styles = {
    buttonIcon: {
        fontSize: 18
    },
    inputFile: {
        display: 'none'
    },     
}

const BuySale = ({ history }) => {

    const { id } = useParams();

    const dispatch = useDispatch();

    const proyectos = useSelector(state => state.proyectos);
    const detalles = useSelector(state => state.proyectos?.detalles);

    let dia_minimo_reporte = new Date();
    let dia_maximo_reporte = new Date();
    dia_maximo_reporte.setDate(dia_maximo_reporte.getDate() + 6);

    const [dataStatus, setDataStatus] = useState({
        loadingData: false,
        dataLoaded: false
    });

    const [proyectoData, setProyectoData] = useState({
        id: null,
        tipo_status: null,
        nombre_status: null,
        identificador_status: null,
        tipo_proyecto: null,
        nombre_proyecto: null,
        lista_status_proyecto: [],
        tipo_construccion: null,
        nombre_construccion: null,
        direccion: null,
        latitud: null,
        longitud: null,
        apn: null,
        edificios: [],
        unidades: null,
        construccion: null,
        terreno: null,
        terreno_unidad: null,
        listing_id: null
    });

    const [statusModal, setStatusModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);

    const [noteData, setNoteData] = useState({
        id_proyecto: null,
        id_status: null,
        nota: ''
    });

    const [imageData, setImageData] = useState({
        modal: false,
        src: ''
    });

    const [shareReportData, setShareReportData] = useState({
        proyecto: null,
        seccion: null,
        emails: [],
        vigencia: null
    });

    const { loadingData, dataLoaded } = dataStatus;

    /* Funciones de secciones */
    const openSection = (active, id_seccion, section) => {
        if(active){
            if(proyectos.guardar_cambios === false){
                dispatch(seleccionarSeccionMasterList(section));
                dispatch(filesProyectoSeccion(id, id_seccion));
            }else{
                dispatch(desplegarModalCambios(true));
                dispatch(paginaRedireccion(section));
                dispatch(idRedireccion('1')); //Asignamos el 1 para comparar y abrir la seccion
            }
        }
    }

    /* Desbloquear*/
    const unlock = () => {
        dispatch(editProject());
    }

    /* Recordatorios */
    const reminders = id => {
        if(proyectos.guardar_cambios === false){
            history.push('/reminders/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion('/projects/reminders/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Notas */
    const newNote = id => {
        dispatch(externoNota(true, null));
    }

    const notes = id => {
        if(proyectos.guardar_cambios === false){
            dispatch(notesProyecto(id));
            history.push('/projects/notes/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(notesProyecto(id));
            dispatch(paginaRedireccion('/projects/notes/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Archivos */
    const newFile = id => {
        dispatch(externoArchivoProyecto(true));
    }

    const files = id => {
        if(proyectos.guardar_cambios === false){
            dispatch(filesFoldersProyecto({
                id_proyecto: id,
                id_folder: null
            }));
            history.push('/projects/files/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(filesFoldersProyecto({
                id_proyecto: id,
                id_folder: null
            }));
            dispatch(paginaRedireccion('/projects/files/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Historial de acciones */
    const historial = id => {
        if(proyectos.guardar_cambios === false){
            history.push('/projects/history/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion('/projects/history/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Inspecciones */
    const inspection = id => {
        if(proyectos.guardar_cambios === false){
            dispatch(inspectionsProyecto(id));
            history.push('/projects/inspections/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(inspectionsProyecto(id));
            dispatch(paginaRedireccion('/projects/inspections/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Due Dilligence Checklist */
    const dilligence = id => {
        if(proyectos.guardar_cambios === false){
            history.push('/projects/dueDiligence/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion('/projects/dueDiligence/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Agenda */
    const agenda = id => {
        if(proyectos.guardar_cambios === false){
            history.push('/agenda/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion('/agenda/'));
            dispatch(idRedireccion(id));
        }
    }

    /* To do list */
    const todolist = id => {
        if(proyectos.guardar_cambios === false){
            dispatch(elegirFiltros({
                id_proyecto: id,
                nombre_proyecto: detalles?.proyecto?.direccion
            }));
            history.push('/todolist/listado/' + id);
        }else{
            dispatch(desplegarModalCambios(true));
            dispatch(paginaRedireccion('/todolist/listado/'));
            dispatch(idRedireccion(id));
        }
    }

    /* Opciones */
    const editar = id => {
        history.push('/projects/edit_offer/' + id);
    }

    const eliminar = id => {
        dispatch(externoEliminar(null, true));
    }

    /* Funciones de datos */
    const handleStatusModal = () => {
        setStatusModal(true);
    }

    const handleCloseStatusModal = () => {
        setStatusModal(false);
    }

    const handleReportModal = () => {
        setShareReportData({
            ...shareReportData,
            seccion: proyectos.masterlist_section
        });

        setReportModal(true);
    }

    const handleAgregarEmail = email => {
        setShareReportData({
            ...shareReportData,
            emails: [...shareReportData.emails, email]
        });
    }

    const handleEliminarEmail = posicion => {
        setShareReportData({
            ...shareReportData,
            emails: shareReportData.emails.filter( (email, index) => index !== posicion )
        });
    }

    const handleCopiarLink = () => {
        navigator.clipboard.writeText(proyectos.link_reporte);
        dispatch(setAlert('Share link coppied', 'primary'));
    }

    const handleObtenerLink = () => {
        if(shareReportData.vigencia !== null){
            dispatch(compartirReportProyecto(shareReportData));
        }else{
            dispatch(setAlert('First enter a valid duration date', 'danger'));
        }
    }

    const handleEnviarLink = () => {
        if(shareReportData.emails.length > 0){
            if(shareReportData.vigencia !== null){
                dispatch(enviarReportProyecto(shareReportData));
            }else{
                dispatch(setAlert('First enter a valid duration date', 'danger'));
            }
        }else{
            dispatch(setAlert('First add emails', 'danger'));
        }
    }

    const handleAbrirReporte = async() => {
        await dispatch(verReportProyecto(shareReportData));
        await setReportModal(false);
    }

    const handleCloseReportModal = () => {
        setReportModal(false);
        setShareReportData({
            ...shareReportData,
            emails: []
        });
    }

    useEffect(() => {
        dispatch(detallesProyecto(id));

        dispatch(masterlistProyecto(id));

        dispatch(buscarBrokers());

        dispatch(buscarEscrows());
        
        dispatch(buscarLenders());

        dispatch(buscarTitles());

        dispatch(buscarTermites());

        dispatch(buscarSeptics());

        dispatch(buscarUsuarios());

        dispatch(buscarEmpleados());

        dispatch(notesProyecto(id));
        
        dispatch(buySalePage(true));

        dispatch(limpiarLinkReportProyecto());

        setNoteData({
            ...noteData,
            id_proyecto: id
        });

        if (loadingData === false && proyectos.masterlist !== null) {
            setDataStatus({
                loadingData: true,
                dataLoaded: false
            });
        }
    }, []);

    useEffect(() => {
        setShareReportData({
            ...shareReportData,
            proyecto: id,
            seccion: proyectos.masterlist_section
        });

        setNoteData({
            ...noteData,
            id_proyecto: id,
            id_status: proyectos.masterlist_section
        });
    }, [proyectos]);

    useEffect(() => {
        if(proyectos.redireccion !== null){
            if(proyectos.idRedireccion !== null){
                if(proyectos.idRedireccion === '1'){
                    dispatch(seleccionarSeccionMasterList(proyectos.redireccion));
                }else{
                    history.push(proyectos.redireccion + proyectos.idRedireccion);
                }
            }else{
                history.push(proyectos.redireccion);
            }
            dispatch(redireccionar(null));
            dispatch(paginaRedireccion(null));
            dispatch(idRedireccion(null));
        }
    }, [proyectos.redireccion]);

    useEffect(() => {
        if (detalles !== undefined && detalles !== null) {

            setProyectoData({
                ...proyectoData,
                id: detalles?.proyecto?._id,
                tipo_status: detalles?.proyecto?.tipo_status,
                nombre_status: detalles?.proyecto?.nombre_status,
                identificador_status: detalles?.proyecto?.identificador_status,
                tipo_proyecto: detalles?.proyecto?.tipo_proyecto,
                nombre_proyecto: detalles?.proyecto?.nombre_proyecto,
                lista_status_proyecto: detalles?.proyecto?.lista_status_proyecto,
                tipo_construccion: detalles?.proyecto?.tipo_construccion,
                nombre_construccion: detalles?.proyecto?.nombre_construccion,
                direccion: detalles?.proyecto?.direccion,
                latitud: detalles?.proyecto?.latitud,
                longitud: detalles?.proyecto?.longitud,
                apn: detalles?.proyecto?.apn,
                edificios: detalles?.proyecto?.edificios,
                unidades: detalles?.proyecto?.unidades_general,
                construccion: detalles?.proyecto?.construccion_general,
                terreno: detalles?.proyecto?.terreno,
                terreno_unidad: detalles?.proyecto?.terreno_unidad,
                listing_id: detalles?.proyecto?.listing_id
            });

            openSection(true, detalles?.proyecto?.tipo_status, detalles?.proyecto?.nombre_status);

            setNoteData({
                ...noteData,
                id_status: detalles?.proyecto?.tipo_status
            });

            setDataStatus({
                loadingData: false,
                dataLoaded: true
            });
        }
    }, [detalles]);

    return (
        <div className="row px-4 proyectos-container">
            {
                loadingData !== true ?
                    dataLoaded === true ?
                        <Fragment>
                            {imageData.modal && (
                                <Lightbox
                                    mainSrc={imageData.src}
                                    onCloseRequest={() => {setImageData({ ...imageData, modal: false });}}
                                  />
                            )}

                            {/* Modal de status */}
                            <Modal backdrop="static" show={statusModal} centered onHide={() => {
                                handleCloseStatusModal();
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Status list</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <table className="table table-bordered track_tbl">
                                        <tbody>

                                            {
                                                proyectoData.lista_status_proyecto?.map((status, index) => {
                                                    
                                                    let color_status = '';
                                                    let primary_status = status.primary;
                                                    let funcion_status = true;

                                                    if(status.orden <= proyectoData.identificador_status){
                                                        color_status = ' active';
                                                    }

                                                    if(String(proyectos.masterlist_section) === String(status.nombre)){
                                                        primary_status = true;
                                                        color_status = ' actual';
                                                    }

                                                    if(primary_status){
                                                        return(
                                                            <tr key={'status_' + index}>
                                                                <td className={'track_dot' + color_status} onClick={() => openSection(funcion_status, status.id_status, status.nombre)}><span className="track_line"></span></td>
                                                                <td onClick={() => openSection(funcion_status, status.id_status, status.nombre)}><strong>{ status.nombre }</strong></td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary mr-3" onClick={() => handleCloseStatusModal()}><i className="fas fa-check fa-sm"></i> Ok</button>
                                </Modal.Footer>
                            </Modal>

                            {/* Modal de reporte */}
                            <Modal backdrop="static" show={reportModal} size="lg" onHide={() => {
                                handleCloseReportModal();
                            }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Generate report</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="row"> 
                                        <div className="col-md-12">
                                            <p style={styles.info}>Share the access <b>link</b> or send it to the <b>emails</b> you want:</p>
                                        </div>

                                        <div className="col-md-4 form-group">
                                            <label className="font-weight-bold">Duration Date:</label>
                                            <div className='d-flex'>
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </span>
                                                <div style={{width: '100%'}}>
                                                    <DatePicker 
                                                        name="report_duration_date" 
                                                        dateFormat="MM/dd/yyyy" 
                                                        minDate={dia_minimo_reporte}
                                                        maxDate={dia_maximo_reporte}
                                                        className="form-control text-center" 
                                                        selected={shareReportData.vigencia !== null ? shareReportData.vigencia : null} 
                                                        onChange={date => {setShareReportData({...shareReportData, vigencia: date})}} 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 form-group">
                                            <label className="font-weight-bold">Emails:</label>
                                            <div className="contenedor_datepicker">
                                                <ReactChipInput
                                                    chips={shareReportData.emails}
                                                    onSubmit={value => handleAgregarEmail(value)}
                                                    onRemove={index => handleEliminarEmail(index)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            proyectos.link_reporte !== null ?
                                                <div className="col-md-12">
                                                    <label className="font-weight-bold">Share link:</label>
                                                    <div className="input-group">
                                                        <input autoComplete='off' type="text" className="form-control" value={proyectos.link_reporte} disabled></input>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text" id="basic-addon1" onClick={() => handleCopiarLink()}>
                                                                <i className="fas fa-copy"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-danger ml-2" onClick={() => handleCloseReportModal()}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                    <button className="btn warning ml-2" onClick={() => handleObtenerLink()}><i className="fas fa-link fa-sm"></i> Get share link</button>
                                    <button className="btn warning ml-2" onClick={() => handleEnviarLink()}><i className="fas fa-paper-plane fa-sm"></i> Send share link</button>
                                    <button className="btn btn-success ml-2" onClick={() => handleAbrirReporte()}><i className="fas fa-eye fa-sm"></i> Report </button>
                                </Modal.Footer>
                            </Modal>

                            <div className='row'>
                                <div className="col-md-3 pt-5">
                                    <h3 className="mb-1">{proyectos.masterlist_section} information</h3>
                                    <h6 className="mb-1 campo-recordatorio">Detailed notes are requiered</h6>
                                </div>
                                
                                <div className="col-md-9 pt-5 btn-project-information">
                                    <button type="button" className={proyectos.edicion_proyecto === false ? 'btn btn-small btn-secondary float-right mb-3 ml-3' : 'btn btn-small btn-warning float-right mb-3 ml-3' } onClick={() => unlock()}><i className={proyectos.edicion_proyecto === false ? 'fa fa-lock' : 'fa fa-unlock' }></i></button>
                                    {
                                        detalles?.proyecto?.folio !== undefined ? 
                                            <button type="button" className="btn btn-small btn-danger float-right mb-3 ml-3" onClick={() => reminders(id)}><i className="fas fa-bell"></i></button>
                                        :
                                            <></>
                                    }
                                    <div className="btn btn-group dropdown float-right mb-3 ml-3 p-0" role="group">
                                        <button id="btnGroupTools" type="button" className="btn btn-small btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-sticky-note"></i> Notes
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                                            <button className="dropdown-item text-success" onClick={() => newNote(id)}>
                                                Add note
                                            </button>
                                            <button className="dropdown-item text-primary" onClick={() => notes(id)}>
                                                View notes
                                            </button>
                                        </div>
                                    </div>

                                    <div className="btn btn-group dropdown float-right mb-3 ml-3 p-0" role="group">
                                        <button id="btnGroupTools" type="button" className="btn btn-small btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-folder-open"></i> Files
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                                            <button className="dropdown-item text-success" onClick={() => newFile(id)}>
                                                Add file
                                            </button>
                                            <button className="dropdown-item text-primary" onClick={() => files(id)}>
                                                View files
                                            </button>
                                        </div>
                                    </div>

                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => historial(id)}><i className="fas fa-history"></i> History</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => handleReportModal()}><i className="fas fa-file-alt"></i> Report</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => inspection(id)}><i className="fas fa-tools"></i> Inspection list</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => dilligence(id)}><i className="fas fa-clipboard-list"></i> Due Diligence Checklist</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => { }}><i className="fas fa-bell"></i> Deal Analizer</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => agenda(id)}><i className="fas fa-calendar-day"></i> Calendar</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => todolist(id)}><i className="fas fa-check-square"></i> To do list</button>
                                    <button type="button" className="btn btn-small btn-secondary float-right mb-3 ml-3" onClick={() => handleStatusModal()}><i className="fas fa-check-circle"></i> Status</button>

                                    <div className="btn btn-group dropdown float-right mb-3 p-0 tools-button-list-left" role="group">
                                        <button id="btnGroupTools" type="button" className="btn btn-small btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Options
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="btnGroupTools">
                                            <button className="dropdown-item text-primary" onClick={() => editar(id)}>
                                                Edit project
                                            </button>
                                            <button className="dropdown-item text-danger" onClick={() => eliminar(id)}>
                                                Delete project
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p data-toggle="collapse" data-target="#collapseDetailsProject" aria-expanded="true" aria-controls="collapseDetailsProject">{proyectoData.direccion}</p>
                            </div>

                            <div id="accordion" className="col-md-12 mb-4">
                                <div id="collapseDetailsProject" className="col-lg-12 row collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <label className="col-lg-5 col-sm-12">
                                        <span className="font-weight-bold mr-2">Address:</span> <a href={'https://www.google.com/maps/search/?api=1&query=' + proyectoData.latitud + '%2C' + proyectoData.longitud} target="_blank">{proyectoData.direccion}</a>
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">Project type:</span> {proyectoData.nombre_proyecto}
                                    </label>

                                    <label className="col-lg-3 col-sm-12">
                                        <span className="font-weight-bold mr-2">Property type:</span> {proyectoData.nombre_construccion}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">APN:</span> {proyectoData.apn?.join(', ')}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">Listing ID:</span> {proyectoData.listing_id || '-'}
                                    </label>
                                    
                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">ID:</span> {proyectoData.folio || '-'}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">Buidings:</span> {proyectoData.edificios?.length || '-'}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">Units:</span> {proyectoData.unidades || '-'}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">SQFT:</span> {proyectoData.construccion || '-'}
                                    </label>

                                    <label className="col-lg-2 col-sm-12">
                                        <span className="font-weight-bold mr-2">LOT:</span> {proyectoData.terreno || '-'} {proyectoData.terreno_unidad === 0 || proyectoData.terreno_unidad === '0' ? 'FT' : (proyectoData.terreno_unidad === 1 || proyectoData.terreno_unidad === '1' ? 'AC' : '-')}
                                    </label>
                                </div>
                            </div>                            

                            {/* Flipping */}
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Offer') ?
                                    <OfferFlipping statusClick={handleStatusModal}></OfferFlipping>
                                :
                                    null
                            }
                            
                            {
                                ((proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Buying escrow') || (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'On hold') || (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Cancelled')) ?
                                    <BuyingEscrowFlipping statusClick={handleStatusModal}></BuyingEscrowFlipping>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Waiting for repairs') ?
                                    <WaitingForRepairsFlipping statusClick={handleStatusModal}></WaitingForRepairsFlipping>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Rehab on progress') ?
                                    <RehabOnProgressFlipping statusClick={handleStatusModal}></RehabOnProgressFlipping>
                                :
                                    null
                            }

                            {
                                ((proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Holding') || (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Rented property')) ?
                                    <HoldingFlipping statusClick={handleStatusModal}></HoldingFlipping>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Listing') ?
                                    <ListingFlipping statusClick={handleStatusModal}></ListingFlipping>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Sells in escrow') ?
                                    <SellsInEscrowFlipping statusClick={handleStatusModal}></SellsInEscrowFlipping>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Flipping' && proyectos.masterlist_section === 'Sold property') ?
                                    <SoldPropertyFlipping statusClick={handleStatusModal}></SoldPropertyFlipping>
                                :
                                    null
                            }

                            {/* New development */}
                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Offer') ?
                                    <OfferNewDevelopment statusClick={handleStatusModal}></OfferNewDevelopment>
                                :
                                    null
                            }
                            
                            {
                                ((proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Buying escrow') || (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'On hold') || (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Cancelled')) ?
                                    <BuyingEscrowNewDevelopment statusClick={handleStatusModal}></BuyingEscrowNewDevelopment>
                                :
                                    null
                            }
                            
                            {
                                ((proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Holding') || (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Rented property')) ?
                                    <HoldingNewDevelopment statusClick={handleStatusModal}></HoldingNewDevelopment>
                                :
                                    null
                            }

                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'New development') ?
                                    <NewDevelopmentNewDevelopment statusClick={handleStatusModal}></NewDevelopmentNewDevelopment>
                                :
                                    null
                            }

                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Entitlements') ?
                                    <EntitlementsNewDevelopment statusClick={handleStatusModal}></EntitlementsNewDevelopment>
                                :
                                    null
                            }

                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Construction') ?
                                    <ConstructionNewDevelopment statusClick={handleStatusModal}></ConstructionNewDevelopment>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Listing') ?
                                    <ListingNewDevelopment statusClick={handleStatusModal}></ListingNewDevelopment>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Sells in escrow') ?
                                    <SellsInEscrowNewDevelopment statusClick={handleStatusModal}></SellsInEscrowNewDevelopment>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'New development' && proyectos.masterlist_section === 'Sold property') ?
                                    <SoldPropertyNewDevelopment statusClick={handleStatusModal}></SoldPropertyNewDevelopment>
                                :
                                    null
                            }

                            {/* Assigned */}
                            {
                                (proyectoData.nombre_proyecto === 'Assigned' && proyectos.masterlist_section === 'Offer') ?
                                    <OfferAssigned statusClick={handleStatusModal}></OfferAssigned>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Assigned' && proyectos.masterlist_section === 'Buying escrow') ?
                                    <BuyingEscrowAssigned statusClick={handleStatusModal}></BuyingEscrowAssigned>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Assigned' && proyectos.masterlist_section === 'Listing') ?
                                    <ListingAssigned statusClick={handleStatusModal}></ListingAssigned>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Assigned' && proyectos.masterlist_section === 'Sells in escrow') ?
                                    <SellsInEscrowAssigned statusClick={handleStatusModal}></SellsInEscrowAssigned>
                                :
                                    null
                            }
                            
                            {
                                (proyectoData.nombre_proyecto === 'Assigned' && proyectos.masterlist_section === 'Sold property') ?
                                    <SoldPropertyAssigned statusClick={handleStatusModal}></SoldPropertyAssigned>
                                :
                                    null
                            }
                        </Fragment>                        
                    :
                        <div className="msg-center">
                            <h3>No project was selected, please go back to projects selection.</h3>
                            <button className="btn btn-primary mt-2" onClick={() => history.push('/projects/projects')}>
                                <i className="fas fa-bars fa-sm"></i> Projects
                            </button>
                        </div>
                :
                    <div className='msg-center'>
                        <h3>LOADING DATA.</h3>
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
            }
        </div>
    );
}

export default BuySale;