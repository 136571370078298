import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';

import { buscarBancos, buscarTiposTarjetas } from '../../actions/bancos';
import { detallesTarjetaCredito, editarTarjetaCredito } from '../../actions/contabilidad';

const EditarTarjetaCredito = ({ history }) => {
    
    const dispatch = useDispatch();

    const { id } = useParams();

    const { bancos, tipos_tarjetas } = useSelector(state => state.bancos);
    const { detalles_tarjeta } = useSelector(state => state.contabilidad);

    const [tarjeta, setTarjeta] = useState({
        id: null,
        id_banco: null,
        id_tipo: null,
        nombre: '',
        empresa: '',
        digitos: '',
        cvv: '',
        mes: null, 
        ano: null, 
        limite_credito: '',
        tasa_credito: '',
        limite_efectivo: '',
        tasa_efectivo: '', 
        fecha_promocion: null,
        notas: ''
    });

    const [bancoData, setBancoData] = useState(null);
    const [tipoTarjetaData, setTipoTarjetaData] = useState(null);
    const [mesData, setMesData] = useState(null);
    const [anoData, setAnoData] = useState(null);

    const [bancosFilter, setBancosFilter] = useState([]);
    const [tiposTarjetasFilter, setTiposTarjetasFilter] = useState([]);
    const [mesesFilter, setMesesFilter] = useState([
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ]);
    const [anosFilter, setAnosFilter] = useState([
        { value: 2023, label: '2023' },
        { value: 2024, label: '2024' },
        { value: 2025, label: '2025' },
        { value: 2026, label: '2026' },
        { value: 2027, label: '2027' },
        { value: 2028, label: '2028' },
        { value: 2029, label: '2029' },
        { value: 2030, label: '2030' },
        { value: 2031, label: '2031' },
        { value: 2031, label: '2031' }
    ]);

    const handleOnChange = e => {
        setTarjeta({
            ...tarjeta,
            [e.target.name]: e.target.value
        })
    }    

    const handleInputsNumber = (name, value) => {
        setTarjeta({
            ...tarjeta,
            [name]: value
        });
    }

    const handleSelectBanco = (option) => {
        setBancoData(option);

        setTarjeta({
            ...tarjeta,
            id_banco: option !== null ? option.value : null
        });
    }

    const handleSelectTipoTarjeta = (option) => {
        setTipoTarjetaData(option);

        setTarjeta({
            ...tarjeta,
            id_tipo: option !== null ? option.value : null
        });
    }

    const handleSelectExpiracion = (field, option) => {
        if(field == 1){
            setMesData(option);

            setTarjeta({
                ...tarjeta,
                mes: option !== null ? option.value : null
            });
        }else{
            setAnoData(option);

            setTarjeta({
                ...tarjeta,
                ano: option !== null ? option.value : null
            });
        }
    }

    const handleOnSubmit = () => {
        setTimeout(async() => {
            await dispatch(editarTarjetaCredito(tarjeta));
            await history.push('/accounting/credit_cards');
        }, 0);
    }

    useEffect(() => {
        dispatch(buscarBancos());
        dispatch(buscarTiposTarjetas());
    }, []);

    useEffect(() => {
        dispatch(detallesTarjetaCredito(id));
    }, [id]);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < bancos.length; i++) {
            options.push({ value: bancos[i]._id, label: bancos[i].nombre });
        }

        setBancosFilter(options);

    }, [bancos]);

    useEffect(() => {
        
        let options = [];

        for (var i = 0; i < tipos_tarjetas.length; i++) {
            options.push({ value: tipos_tarjetas[i]._id, label: tipos_tarjetas[i].nombre });
        }

        setTiposTarjetasFilter(options);

    }, [tipos_tarjetas]);

    useEffect(() => {
        if(detalles_tarjeta){
            setTarjeta({
                ...tarjeta,
                id: detalles_tarjeta._id ? detalles_tarjeta._id : null,
                id_banco: detalles_tarjeta.banco.id ? detalles_tarjeta.banco.id : null,
                id_tipo: detalles_tarjeta.tipo.id ? detalles_tarjeta.tipo.id : null,
                nombre: detalles_tarjeta.nombre ? detalles_tarjeta.nombre : '',
                empresa: detalles_tarjeta.empresa ? detalles_tarjeta.empresa : '',
                digitos: detalles_tarjeta.digitos ? detalles_tarjeta.digitos : '',
                cvv: detalles_tarjeta.cvv ? detalles_tarjeta.cvv : '',
                mes: detalles_tarjeta.mes ? detalles_tarjeta.mes : null, 
                ano: detalles_tarjeta.ano ? detalles_tarjeta.ano : null, 
                limite_credito: detalles_tarjeta.limite_credito ? detalles_tarjeta.limite_credito : '',
                tasa_credito: detalles_tarjeta.tasa_credito ? detalles_tarjeta.tasa_credito : '',
                limite_efectivo: detalles_tarjeta.limite_efectivo ? detalles_tarjeta.limite_efectivo : '',
                tasa_efectivo: detalles_tarjeta.tasa_efectivo ? detalles_tarjeta.tasa_efectivo : '', 
                fecha_promocion: detalles_tarjeta.fecha_promocion ? detalles_tarjeta.fecha_promocion : null, 
                notas: detalles_tarjeta.notas ? detalles_tarjeta.notas : ''
            });

            if(detalles_tarjeta.banco.id === null){
                setBancoData(null);
            }else{
                if(bancosFilter.length > 0){
    
                    let banco_asignado = bancosFilter.filter( banco => String(banco.value) === String(detalles_tarjeta.banco.id) );
    
                    if(banco_asignado){
                        setBancoData(banco_asignado);
                    }
                }
            }

            if(detalles_tarjeta.tipo.id === null){
                setTipoTarjetaData(null);
            }else{
                if(tiposTarjetasFilter.length > 0){
    
                    let tipo_asignado = tiposTarjetasFilter.filter( tipo => String(tipo.value) === String(detalles_tarjeta.tipo.id) );
    
                    if(tipo_asignado){
                        setTipoTarjetaData(tipo_asignado);
                    }
                }
            }

            if(detalles_tarjeta.mes === null){
                setMesData(null);
            }else{
                if(mesesFilter.length > 0){
    
                    let mes_asignado = mesesFilter.filter( mes => String(mes.value) === String(detalles_tarjeta.mes) );
    
                    if(mes_asignado){
                        setMesData(mes_asignado);
                    }
                }
            }

            if(detalles_tarjeta.ano === null){
                setAnoData(null);
            }else{
                if(anosFilter.length > 0){
    
                    let ano_asignado = anosFilter.filter( ano => String(ano.value) === String(detalles_tarjeta.ano) );
    
                    if(ano_asignado){
                        setAnoData(ano_asignado);
                    }
                }
            }
        }
    }, [detalles_tarjeta, bancosFilter, tiposTarjetasFilter, mesesFilter, anosFilter]);

    return ( 
        <div className="row pb-5">
            <div className="col-md-10 offset-md-1">
                <h3 className="mb-3 mt-5">Edit credit card</h3>
                <div className="row">
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Bank:</label>
                        <Select name="id_banco" isClearable={true} options={bancosFilter} value={bancoData} onChange={(value) => handleSelectBanco(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Type:</label>
                        <Select name="id_tipo" isClearable={true} options={tiposTarjetasFilter} value={tipoTarjetaData} onChange={(value) => handleSelectTipoTarjeta(value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Name of card:</label>
                        <input type="text" className="form-control" name="nombre" onChange={handleOnChange} value={tarjeta.nombre}></input>
                    </div>
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Card holder:</label>
                        <input type="text" className="form-control" name="empresa" onChange={handleOnChange} value={tarjeta.empresa}></input>
                    </div>
                    <div className="col-md-1 form-group">
                        <label className="font-weight-bold">Last digits:</label>
                        <input type="text" className="form-control" name="digitos" onChange={handleOnChange} value={tarjeta.digitos}></input>
                    </div>
                    <div className="col-md-1 form-group">
                        <label className="font-weight-bold">CVV:</label>
                        <input type="text" className="form-control" name="cvv" onChange={handleOnChange} value={tarjeta.cvv}></input>
                    </div>
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Expiration month:</label>
                        <Select name="mes" isClearable={true} options={mesesFilter} value={mesData} onChange={(value) => handleSelectExpiracion(1, value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>
                    <div className="col-md form-group">
                        <label className="font-weight-bold">Expiration year:</label>
                        <Select name="ano" isClearable={true} options={anosFilter} value={anoData} onChange={(value) => handleSelectExpiracion(2, value)} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Credit line:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                value={tarjeta.limite_credito || ''}
                                name="limite_credito"
                                className="form-control text-center"
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => handleInputsNumber(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Credit line rate:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                value={tarjeta.tasa_credito || ''}
                                name="tasa_credito"
                                className="form-control text-center"
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => handleInputsNumber(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Cash advantage:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                value={tarjeta.limite_efectivo || ''}
                                name="limite_efectivo"
                                className="form-control text-center"
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => handleInputsNumber(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Cash advantage rate:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                value={tarjeta.tasa_efectivo || ''}
                                name="tasa_efectivo"
                                className="form-control text-center"
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => handleInputsNumber(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md form-group">
                        <label className="font-weight-bold">Promotion date:</label>
                        <div className="contenedor_datepicker">
                            <DatePicker
                                name="fecha_promocion"
                                className="form-control text-center"
                                dateFormat="MM/dd/yyyy"
                                autoComplete="off"
                                selected={ tarjeta.fecha_promocion ? new Date(tarjeta.fecha_promocion) : null }
                                onChange={ date => setTarjeta({ ...tarjeta, fecha_promocion: date }) } 
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Notes:</label>
                        <textarea className="form-control" name="notas" value={tarjeta.notas || ''} onChange={handleOnChange}></textarea>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-success float-right mt-3" onClick={() => handleOnSubmit()}><i className="fas fa-check fa-sm"></i> Edit</button>
                        <button type="button" className="btn btn-danger float-right mt-3 mr-3" onClick={() => {history.push('/accounting/credit_cards')}}><i className="fas fa-times fa-sm"></i> Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarTarjetaCredito;