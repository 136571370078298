import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { buscarVestings, eliminarVesting } from '../../actions/vestings';
import { Link } from 'react-router-dom';

const Vestings = ({ history }) => {

    const vestings = useSelector(state => state.vestings);
    const { permisos_configuration } = useSelector(state => state.auth.user);

    const dispatch = useDispatch();
    
    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    const tableRow = (items) => {
        if(vestings.vestings?.length > 0){

            return (
                <tbody>
                    {
                        items.map((item) => {
                            return (
                                <Fragment key={item._id}>
                                    <tr>
                                        <td> {item.nombre} </td>
                                        <td> {item.representante || '-'} </td>
                                        <td> {formatPhoneNumber(item.celular) || '-'} </td>
                                        <td> {formatPhoneNumber(item.telefono) || '-'} </td>
                                        <td> {item.email || '-'} </td>
                                        <td> {item.direccion || '-'} </td>
                                        <td>
                                            {
                                                !permisos_configuration?.edit_vesting ?
                                                null
                                                :
                                                <button className="btn btn-link text-primary" onClick={() => editar(item._id)}>Edit</button>
                                            }
                                        </td>
                                        <td>
                                            {
                                                !permisos_configuration?.delete_vesting ?
                                                null
                                                :
                                                <button id={'eliminar_'+item._id} className="btn btn-link text-danger" onClick={() => confirmar(item._id, 1)}>Delete</button>
                                            }
                                            
                                            <div id={'confirmar_'+item._id} className="text-center" style={{display: 'none'}}>
                                                <label>Continue?</label>
                                                <div style={{display: 'flex'}}>
                                                    <button className="btn btn-link text-danger" onClick={() => eliminar(item._id)}><i className="fas fa-check fa-sm"></i> Yes</button>
                                                    <button className="btn btn-link text-danger" onClick={() => confirmar(item._id, 2)}><i className="fas fa-times fa-sm"></i> Cancel</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    }
                </tbody>
            );
        }else{
            return (
                <tbody>
                    <tr>
                        <td colSpan="4" className="text-center">There are no records to display.</td>                                        
                    </tr>
                </tbody>
            );
        }
    }

    const editar = id => {
        history.push('/configuration/edit-vesting/' + id);
    }

    const confirmar = (id, type) => {
        if (type === 1) {
            document.getElementById('eliminar_'+id).style.display = 'none';
            document.getElementById('confirmar_'+id).style.display = 'unset';
        } else {
            document.getElementById('eliminar_'+id).style.display = 'unset';
            document.getElementById('confirmar_'+id).style.display = 'none';
        }
    }

    const eliminar = id => {
        dispatch(eliminarVesting(id));
    }

    useEffect(() => {
        dispatch(buscarVestings());
    }, []);

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center mb-3 mt-5">
                    <div className="col-md-8">
                        <h3>Vestings</h3>
                        <p className="text-muted"> {vestings.vestings?.length} registered vestings</p>
                    </div>
                    <div className="col-md-4 text-right">
                        {
                            !permisos_configuration?.add_vesting ?
                            null
                            :
                            <Link to="/configuration/new-vesting" className="btn btn-success text-right"><i className="fas fa-plus fa-sm"></i> Add vesting</Link>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Rrepresentant</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Cell phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">Address</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        {tableRow(vestings.vestings)}
                    </table>
                </div>
            </div>
        </div>
    );    
}

export default Vestings;