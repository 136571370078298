import {
    ELEGIR_FILTROS_TODOLIST,
    BUSCAR_TAREAS_TODOLIST,
    AGREGAR_TAREA_TODOLIST,
    EDITAR_TAREA_TODOLIST,
    NOTA_TAREA_TODOLIST,
    ARCHIVO_NOTA_TODOLIST,
    DETALLES_TAREA_TODOLIST,
    ELIMINAR_TAREA_TODOLIST,
    REORDENAR_TAREA_TODOLIST,
    EXTERNA_TAREA, 
    OBTENER_REPORTE_TODOLIST
} from '../actions/types';

import { setAlert } from './alert';
import axios from 'axios';


export const elegirFiltros = (filtros) => async dispatch => {
    dispatch({
        type: ELEGIR_FILTROS_TODOLIST,
        payload: filtros
    });
}

export const buscarTareas = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist`, body, config);
            dispatch({
                type: BUSCAR_TAREAS_TODOLIST,
                payload: res.data.secciones
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: BUSCAR_TAREAS_TODOLIST,
            payload: []
        });
    }
}

export const agregarTarea = (tarea) => async dispatch => {
    
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        console.log(tarea);
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/nueva_tarea`, tarea, config);
        dispatch({
            type: AGREGAR_TAREA_TODOLIST,
            payload: res.data.tarea
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarTarea = (tarea) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(tarea);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/editar_tarea`, body, config);
        dispatch({
            type: EDITAR_TAREA_TODOLIST,
            payload: res.data.tarea
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const tiemposTarea = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/tiempos_tarea`, body, config);
        dispatch({
            type: EDITAR_TAREA_TODOLIST,
            payload: res.data.tarea
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const finalizarTarea = (id, id_seccion, finalizada) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id, id_seccion, finalizada };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/finalizar_tarea`, body, config);
        dispatch({
            type: ELIMINAR_TAREA_TODOLIST,
            payload:{
                id_seccion,
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesTarea = (id) => async dispatch => {
    if(id){
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const body = { id };
        
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/detalles_tarea`, body, config);

            dispatch({
                type: DETALLES_TAREA_TODOLIST,
                payload: res.data.tarea
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: DETALLES_TAREA_TODOLIST,
            payload: null
        });
    }
}

export const notaTarea = (nota, scrollBottom) => async dispatch => {
    
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
        }
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/nota_tarea`, nota, config);
        dispatch({
            type: NOTA_TAREA_TODOLIST,
            payload: res.data
        });
        dispatch(setAlert(res.data.msg, 'success'));
        scrollBottom();
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesArchivo = (id_tarea, id_nota, id_archivo) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id_tarea, id_nota, id_archivo };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/archivo_nota`, body, config);
        dispatch({
            type: ARCHIVO_NOTA_TODOLIST,
            payload: res.data.archivo
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarTarea = (id_seccion, id) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/eliminar_tarea`, body, config);
        dispatch({
            type: ELIMINAR_TAREA_TODOLIST,
            payload:{
                id_seccion,
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const reordenarTarea = ( tareas, secciones) => async dispatch => {

    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { tareas };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/orden_tarea`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: REORDENAR_TAREA_TODOLIST,
            payload: secciones
        });
    }
}

export const reordenarSecciones = ( id, orden, secciones) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id, orden };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/orden_seccion`, body, config);
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: REORDENAR_TAREA_TODOLIST,
            payload: secciones
        });
    }
}

export const externaTarea = (valor) => async dispatch => {
    dispatch({
        type: EXTERNA_TAREA,
        payload: valor
    });
}

export const obtenerReporte = (filtros) => async dispatch => {
    if(filtros){
        const config = {
            headers: {
                'Content-Type' : 'application/json'
            }
        }

        const body = JSON.stringify(filtros);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/obtener_reporte`, body, config);

            dispatch({
                type: OBTENER_REPORTE_TODOLIST,
                payload: res.data.informacion
            });
        } catch (error) {
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        }
    }else{
        dispatch({
            type: OBTENER_REPORTE_TODOLIST,
            payload: null
        });
    }
}

export const generarReporte = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(filtros);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/todolist/generar_reporte`, body, config);

        const file = new Blob([res.data], {
            type: "application/pdf"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}