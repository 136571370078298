import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';

import { setAlert } from '../../actions/alert';
import { buscarValoresAnalisis, editarValoresAnalisis } from '../../actions/deal_analyzer';

const DealAnalyzer = () => {

    const dispatch = useDispatch();
    
    const { valores } = useSelector(state => state.deal_analyzer);

    const valoresDataInit = {
        rehab_mana_lic_fee_percent: null, 
        sales_commission_percent: null, 
        monthly_expense_rate: null, 
        real_state_rate: null, 
        uberous_capital_cost_rate: null, 
        property_tax_rate: null, 
        utilities_gewsi_rate: null, 
        insurance_rate: null, 
        maintenance_rate: null, 
        security_rate: null, 
        transfer_taxes_rate: null, 
        utilities_gewsi_base: null, 
        insurance_base: null, 
        maintenance_base: null, 
        security_base: null, 
        monthly_hoa_maintenance_factor: null, 
        monthly_hoa_security_factor: null, 
        uberous_capital_rate: null
    };

    const [valoresData, setValoresData] = useState(valoresDataInit);

    /* Guardar */
    const onNumberChange = (name, value) => {
        setValoresData({
            ...valoresData, [name]: value || ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await dispatch(editarValoresAnalisis(valoresData));
    }

    useEffect(() => {
        dispatch(buscarValoresAnalisis());
    }, []);

    useEffect(() => {
        if(valores){
            setValoresData({
                rehab_mana_lic_fee_percent: valores.rehab_mana_lic_fee_percent, 
                sales_commission_percent: valores.sales_commission_percent, 
                monthly_expense_rate: valores.monthly_expense_rate, 
                real_state_rate: valores.real_state_rate, 
                uberous_capital_cost_rate: valores.uberous_capital_cost_rate, 
                property_tax_rate: valores.property_tax_rate, 
                utilities_gewsi_rate: valores.utilities_gewsi_rate, 
                insurance_rate: valores.insurance_rate, 
                maintenance_rate: valores.maintenance_rate, 
                security_rate: valores.security_rate, 
                transfer_taxes_rate: valores.transfer_taxes_rate, 
                utilities_gewsi_base: valores.utilities_gewsi_base, 
                insurance_base: valores.insurance_base, 
                maintenance_base: valores.maintenance_base, 
                security_base: valores.security_base, 
                monthly_hoa_maintenance_factor: valores.monthly_hoa_maintenance_factor, 
                monthly_hoa_security_factor: valores.monthly_hoa_security_factor, 
                uberous_capital_rate: valores.uberous_capital_rate
            });
        }
    }, [valores]);

    return (
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h3 className="mb-3 mt-5">Deal analyzer</h3>

                <div className="row mb-4 text-right">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-success float-right mt-3 ml-3" onClick={handleSubmit}><i className="fas fa-check fa-sm"></i> Save</button>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Rehab Mana. / Lic. fee</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="rehab_mana_lic_fee_percent"
                                className="form-control text-center"
                                value={valoresData.rehab_mana_lic_fee_percent || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Sales commission</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="sales_commission_percent"
                                className="form-control text-center"
                                value={valoresData.sales_commission_percent || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Monthly expense rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="monthly_expense_rate"
                                className="form-control text-center"
                                value={valoresData.monthly_expense_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Real state rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="real_state_rate"
                                className="form-control text-center"
                                value={valoresData.real_state_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Uberous capital cost rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="uberous_capital_cost_rate"
                                className="form-control text-center"
                                value={valoresData.uberous_capital_cost_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Property tax rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="property_tax_rate"
                                className="form-control text-center"
                                value={valoresData.property_tax_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Utilities, GEWSI rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="utilities_gewsi_rate"
                                className="form-control text-center"
                                value={valoresData.utilities_gewsi_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Insurance rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="insurance_rate"
                                className="form-control text-center"
                                value={valoresData.insurance_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>
                
                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Maintenance rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="maintenance_rate"
                                className="form-control text-center"
                                value={valoresData.maintenance_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Security rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="security_rate"
                                className="form-control text-center"
                                value={valoresData.security_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Transfer taxes rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="transfer_taxes_rate"
                                className="form-control text-center"
                                value={valoresData.transfer_taxes_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Utilities, GEWSI base</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="utilities_gewsi_base"
                                className="form-control text-center"
                                value={valoresData.utilities_gewsi_base || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Insurance base</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="insurance_base"
                                className="form-control text-center"
                                value={valoresData.insurance_base || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Maintenance base</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="maintenance_base"
                                className="form-control text-center"
                                value={valoresData.maintenance_base || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>
                
                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Security base</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="security_base"
                                className="form-control text-center"
                                value={valoresData.security_base || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2 form-group campo_dinero">
                        <label className="font-weight-bold">Uberous capital rate</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-percent"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="uberous_capital_rate"
                                className="form-control text-center"
                                value={valoresData.uberous_capital_rate || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Monthly HOA Maintenance factor</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-calculator"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="monthly_hoa_maintenance_factor"
                                className="form-control text-center"
                                value={valoresData.monthly_hoa_maintenance_factor || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Monthly HOA Security factor</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="fas fa-calculator"></i>
                                </span>
                            </div>
                            <CurrencyInput
                                name="monthly_hoa_security_factor"
                                className="form-control text-center"
                                value={valoresData.monthly_hoa_security_factor || ''}
                                decimalsLimit={2}
                                groupSeparator=","
                                decimalSeparator="."
                                onValueChange={(value, name) => onNumberChange(name, value)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DealAnalyzer;