import { BUSCAR_BUYERS, AGREGAR_BUYER, EDITAR_BUYER, DETALLES_BUYER, ELIMINAR_BUYER } from '../actions/types';
import { setAlert } from './alert';
import axios from 'axios';

export const buscarBuyers = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type' : 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/buyers`, config);
        dispatch({
            type: BUSCAR_BUYERS,
            payload: res.data.buyers
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const agregarBuyer = (buyer) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(buyer);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/buyers/nuevo/`, body, config);
        dispatch({
            type: AGREGAR_BUYER,
            payload: res.data.buyer
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const editarBuyer = (buyer) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(buyer);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/buyers/editar`, body, config);
        dispatch({
            type: EDITAR_BUYER,
            payload: res.data.buyer
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const detallesBuyer = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/buyers/detalles`, body, config);

        dispatch({
            type: DETALLES_BUYER,
            payload: res.data.buyer
        });
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const eliminarBuyer = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = { id };
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/buyers/eliminar`, body, config);
        dispatch({
            type: ELIMINAR_BUYER,
            payload:{
                id
            }
        });
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}